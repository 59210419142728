import Icon from '@mdi/react'
import ProfileLogo from '../../../assets/images/profile.png'
import { mdiMapMarkerOutline, mdiStar } from '@mdi/js'
import { Button, DatePicker, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { countriesAtom, selectedCityAtom, selectedCountryAtom, selectedDateRangeAtom } from '@atoms'
import CountryEnum from '../../../helpers/countries'
import useFetch from '../../../hooks/useFetch'

dayjs.extend(customParseFormat) // Extend dayjs with custom parsing

const { RangePicker } = DatePicker

const UserCard = ({ listing, setVisible }) => {
	const navigate = useNavigate()
	const selectedDateRange = useRecoilValue(selectedDateRangeAtom)
	const [dateRange, setDateRange] = useState([null, null])
	const [countries, setCountries] = useRecoilState(countriesAtom)
	const [location, setLocation] = useState('')
	// Log the selectedDateRange to debug the issue
	console.log(selectedDateRange, 'selectedDateRange')
	const selectedCity = useRecoilValue(selectedCityAtom)
	const selectedCountry = useRecoilValue(selectedCountryAtom)
	// Use effect to set initial value of the RangePicker from the selectedDateRange
	useEffect(() => {
		if (selectedDateRange && selectedDateRange.length === 2) {
			setDateRange([
				dayjs(selectedDateRange[0], 'YYYY/MM/DD'), // Parsing using 'YYYY/MM/DD' format
				dayjs(selectedDateRange[1], 'YYYY/MM/DD'), // Parsing using 'YYYY/MM/DD' format
			])
		}
	}, [selectedDateRange])

	useEffect(() => {
		if (selectedCity && selectedCountry) {
			setLocation(`${selectedCity}, ${selectedCountry}`)
		}
	}, [selectedCity, selectedCountry])

	const user = listing?.user || listing?.property?.user
	const loggedInUser = JSON.parse(localStorage.getItem('user'))
	const subscribed = loggedInUser?.subscriptionId
	const freeUntil = loggedInUser ? dayjs(loggedInUser?.createdAt).add(30, 'days').diff(dayjs(), 'seconds') : 2595000
	const { data, loading: cardLoading, error, refetch } = useFetch(`stripe/list-cards/${loggedInUser?.customerId}`)

	const dateFormat = 'D MMM' // Custom date format: Day and abbreviated month (e.g., '9 Jun')

	const countryOptions = Object.keys(CountryEnum)
		.flatMap((country) => {
			return CountryEnum[country].cities.map((city) => ({
				label: `${city}, ${country}`, // Display format "city, country"
				value: `${city}, ${country}`,
				disabled: !countries.includes(country),
			}))
		})
		.sort((a, b) => a.disabled - b.disabled)

	return (
		<div className='basis-1/3 px-[27px] py-[22px] w-full rounded-lg border border-solid border-[#F2F4F7] mt-12'>
			<div className='flex flex-col justify-between sm:items-center max-md:items-start'>
				<div className='flex flex-row justify-start items-start'>
					<div className='h-16 w-16 rounded-full border border-solid border-black-75'>
						<img className='h-full w-full rounded-full bg-black-75' src={user?.profilePicture || ProfileLogo} alt='' />
					</div>
					<div className='flex flex-col pl-4'>
						<p className='text-[#333333] text-[20px] font-bold pb-[9px]'> {listing?.property?.owner || user?.name}</p>
						<div className='flex flex-row items-center'>
							<Icon path={mdiMapMarkerOutline} size={0.7} className='text-[#9B83CB]' />
							<p className='text-[#9191919c] text-lg pl-2 font-[600]'>{user?.location || 'None'}</p>
						</div>
						<div className='flex flex-row items-center'>
							{Array.from({ length: 5 }, (_, index) => (
								<Icon key={index} path={mdiStar} size={1} className='text-[#FFAC33]' />
							))}
							<p className='text-[#9191919c] text-sm md:pl-8 max-md:pl-2 font-[600]'>
								{'14'}&nbsp;<span className='max-md:hidden'>exchanges</span>
							</p>
						</div>
					</div>
				</div>

				{/* {user?.email === 'cityswapp@gmail.com' ? ( */}
				<div className='border-2 border-[#F2F4F7] rounded-lg mt-4 w-full'>
					<div className='p-4 flex flex-col'>
						<div className='flex justify-between'>
							<div className='flex flex-col mr-4'>
								<span className='font-light text-[#BFBFBF] mb-2'>Check In:</span>
							</div>
							<div className='flex flex-col'>
								<span className='font-light text-[#BFBFBF] mb-2'>Check Out:</span>
							</div>
						</div>

						{/* Date Range Picker */}
						<RangePicker
							value={dateRange} // Pass the date range state here
							onChange={(dates) => setDateRange(dates)} // Update the date range when user selects
							format={dateFormat} // Set the date format to 'D MMM' (e.g., '9 Jun')
							className='border border-gray-300 p-2 rounded-md'
							suffixIcon={null}
							variant='borderless'
							style={{ border: 'none' }}
						/>
					</div>

					<div className='border-t border-[#F2F4F7] p-4 flex flex-col hideBorder'>
						<span className='font-light text-[#BFBFBF] mb-2'>Location</span>
						<Select
							placeholder='Select Location'
							defaultValue={location}
							value={location}
							onChange={(value) => setLocation(value)}
							variant='borderless'
							options={countryOptions}
							suffixIcon={null}
							style={{ border: 'none' }}
						/>
					</div>
				</div>
				{/* ) : null} */}

				{/* Action Buttons */}
				<div className='flex flex-row justify-start items-center w-full space-x-4 pt-8'>
					<Button
						disabled={!data?.data?.length}
						className='btn-primary'
						onClick={() => {
							subscribed || freeUntil < 2592000 ? setVisible(true) : navigate(`/pricing`)
						}}
					>
						Request Owner
					</Button>
					<Button
						className='btn-primary !bg-[#F9F5FF] !text-[#6941C6] hover:!text-[#9374da] !border-none'
						onClick={() => {
							subscribed || freeUntil < 2592000 ? navigate(`/chat/${user?._id}`, { state: { user } }) : navigate(`/pricing`)
						}}
					>
						Message
					</Button>
				</div>
			</div>
		</div>
	)
}

export default UserCard
