import './App.css'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import {
	Home,
	Auth,
	NewListing,
	SearchPage,
	About,
	Listing,
	Profile,
	RequestsBookings,
	Chat,
	Pricing,
	PricingNew,
	TermsAndConditions,
	Privacy,
	Faqs,
	HowItWorks,
	HowItWorksNew,
	AboutUs,
	Contact,
	GenerateCoupon,
	Thankyou,
	VerificationDocumentUploadApprovalByAdmin,
	VerificationPending,
	AdministratorControl,
} from '@/pages/index'
import { PrivateRoute } from '@auth'
import CancelSubModal from './pages/profile/cancelSubModal'
import UpdateSubModal from './pages/profile/updateSubModal'
import ValidatedRoute from './auth/components/validateRoute'
import Posts from './blog/Posts'
import BlogView from './blog/BlogView'
import PostForm from './blog/PostForm'
import UpdatePaymentDetails from './pages/profile/updatePaymentDetails'
import AllRequests from './pages/requestsBookings/allRequests/allRequests'

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path='auth/:action' element={<Auth />} />
				<Route path='home' element={<Home />}>
					<Route path='about' element={<About />} />
					<Route path='search' element={<SearchPage />} />
				</Route>
				<Route
					path='requests-bookings/:initPage'
					element={
						<PrivateRoute redirect='/auth/login'>
							<ValidatedRoute redirect='/auth/signup'>
								<RequestsBookings />
							</ValidatedRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path='all-requests'
					element={
						<PrivateRoute redirect='/auth/login'>
							<ValidatedRoute redirect='/auth/signup'>
								<AllRequests />
							</ValidatedRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path='my-listings/:id/:action?'
					element={
						<PrivateRoute redirect='/auth/login'>
							<ValidatedRoute redirect='/auth/signup'>
								<Listing />
							</ValidatedRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path='chat/:id?/:goBackListing?'
					element={
						<PrivateRoute redirect='/auth/login'>
							<ValidatedRoute redirect='/auth/signup'>
								<Chat />
							</ValidatedRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path='profile'
					element={
						<PrivateRoute redirect='/auth/login'>
							<ValidatedRoute redirect='/auth/signup'>
								<Profile />
							</ValidatedRoute>
						</PrivateRoute>
					}
				>
					<Route path=':id/cancelSubscription' element={<CancelSubModal />} />
					<Route path=':id/updateSubscription' element={<UpdateSubModal />} />
					<Route path=':id/editPaymentDetails' element={<UpdatePaymentDetails />} />
				</Route>
				<Route
					path='generate-coupon'
					element={
						<PrivateRoute redirect='/auth/login'>
							<GenerateCoupon />
						</PrivateRoute>
					}
				/>
				<Route
					path='new-listing'
					element={
						<PrivateRoute redirect='/auth/login'>
							<ValidatedRoute redirect='/auth/signup'>
								<NewListing />
							</ValidatedRoute>
						</PrivateRoute>
					}
				/>
				<Route path='listing/:id' element={<Listing />}></Route>
				<Route path='pricing' element={<PricingNew />}></Route>
				<Route path='pricing-old' element={<Pricing />}></Route>
				<Route path='terms-and-conditions' element={<TermsAndConditions />}></Route>
				<Route path='about-us' element={<AboutUs />}></Route>
				<Route path='privacy' element={<Privacy />}></Route>
				<Route path='contact' element={<Contact />}></Route>
				<Route path='faqs' element={<Faqs />}></Route>
				<Route path='how-it-works' element={<HowItWorksNew />}></Route>
				<Route path='how-it-works-old' element={<HowItWorks />}></Route>
				<Route path='thankyou' element={<Thankyou />}></Route>
				<Route path='verificationDocumentUploadApprovalByAdmin' element={<VerificationDocumentUploadApprovalByAdmin />}></Route>
				<Route path='verificationPending' element={<VerificationPending />}></Route>
				<Route path='blogs' element={<Posts />}></Route>
				<Route path='blog/:id' element={<BlogView />}></Route>
				<Route path='new-blog' element={<PostForm />}></Route>
				<Route path='administratorControl' element={<AdministratorControl />}></Route>

				<Route path='/' element={<Navigate to='home/about' replace />} />
				<Route path='*' element={<Navigate to='home/about' replace />} />
			</Routes>
		</Router>
	)
}

export default App
