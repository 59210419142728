import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripeForm from './stripeForm'
const stripePromise = await loadStripe(process.env.REACT_APP_STRIP_PUBLISH_KEY)

const PaymentInfo = ({ success, setSuccess, userEmail, action, isCoupon = true }) => {
	return (
		<div className='relative flex flex-col items-center justify-center space-y-4 pt-2 pb-8 px-4 sm:w-[550px] max-md:w-full rounded-xl'>
			<Elements stripe={stripePromise}>
				<StripeForm success={success} setSuccess={setSuccess} userEmail={userEmail} action={action} isCoupon={isCoupon} />
			</Elements>
		</div>
	)
}

export default PaymentInfo
