import { PrimaryHeader } from '@components'
import React, { useCallback, useState, useEffect } from 'react'
import { endpoints } from '../../../helpers/enums'
import RequestCard from '../components/requestCard'
import { Card, Badge, Modal, Button, Input, message } from 'antd'

import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { HomeOutlined } from '@ant-design/icons'

const AllRequests = () => {
	const [loading, setLoading] = useState(false)
	const [requests, setRequests] = useState([])
	const loggedInUser = JSON.parse(localStorage.getItem('user'))
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedCard, setSelectedCard] = useState(null)
	const [amount, setAmount] = useState('')

	// Show the modal
	const showModal = (id) => {
		setIsModalVisible(true)
		setSelectedCard(id)
	}

	// Hide the modal
	const handleCancel = () => {
		setIsModalVisible(false)
		setAmount('')
	}

	// Handle the submit action
	const handleSubmit = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}request/requests/securityBond/${selectedCard}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ amount: amount }),
			})

			const result = await response.json()

			if (!response.ok) {
				throw new Error(result.message || 'Failed to add card')
			}

			// Handle success
			message.success('Amount added successfully!')
			setIsModalVisible(false)
			fetchData()
		} catch (error) {
			console.error('Error:', error)
			message.error(' Please try again.')
		}
	}

	const fetchData = useCallback(async () => {
		setLoading(true)
		const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}request/allRequests`, {
			headers: { 'Content-Type': 'application/json;charset=utf-8' },
		})
		if (response.status === 200) {
			const data = await response.json()
			// console.log({ data })
			setRequests(data)
			setLoading(false)
		} else {
			console.log(response)
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
		fetchData()
	}, [])

	return (
		<div>
			<div className=''>
				<PrimaryHeader />
			</div>
			<div className='max-md:pt-14 sm:pt-20 mx-auto px-10 mt-4 w-full fullWidth'>
				<h1 className='text-xl font-bold my-4 max-w-4xl mx-auto'>All requests</h1>
				{requests?.map((request, index) => (
					<>
						<Card className='flex items-center my-4 p-4 shadow-lg border border-gray-200 rounded-lg overflow-hidden max-w-4xl mx-auto w-full h-60'>
							{/* Image */}
							<div className='w-32 h-full flex-shrink-0 overflow-hidden rounded-lg'>
								<img src={request?.image || 'default-image-url.jpg'} alt='Room' className='w-full h-full object-cover' />
							</div>

							{/* Content */}
							<div className='flex-grow h-full ml-4 w-full flex flex-col justify-between'>
								<div className='flex justify-between items-center mb-2 w-full'>
									<h3 className='font-semibold text-gray-900 text-lg truncate'>{request?.title || 'Test Title'}</h3>
									<Badge color='orange' text={request?.status} className='text-xs font-semibold text-orange-600' />
								</div>

								<div className='flex items-center space-x-2 text-gray-600 text-sm mb-1 w-full'>
									<HomeOutlined />
									<span>1 Bedroom</span>
									<HomeOutlined />
									<span>1 Bedroom</span>
									<HomeOutlined />
									<span>1 Bedroom</span>
								</div>

								<div className='flex items-center text-gray-500 text-sm space-x-2 mb-1 w-full'>
									<CalendarOutlined />
									<span>{request?.requestData?.[0]?.startDate}</span>
								</div>

								<div className='flex items-center text-gray-500 text-sm space-x-2 w-full'>
									<EnvironmentOutlined />
									<span>{request?.location}</span>
								</div>
							</div>

							{/* Price and Button */}
							<div className='flex flex-col items-end ml-4 py-4'>
								<span className='text-lg font-semibold text-gray-900'>{request?.paymentStatus || '$99'}</span>
								{/* Button to open modal */}
								<Button type='' className='btn-primary my-2' onClick={() => showModal(request?._id)}>
									Add Amount
								</Button>
							</div>
						</Card>

						{/* Modal for entering amount */}
						<Modal
							title='Enter Amount'
							visible={isModalVisible}
							onCancel={handleCancel}
							onOk={handleSubmit}
							footer={[
								<Button key='cancel' className='btn-secondary' onClick={handleCancel}>
									Cancel
								</Button>,
								<Button disabled={!amount} key='submit' className='btn-primary' type='primary' onClick={handleSubmit}>
									Submit
								</Button>,
							]}
						>
							<Input placeholder='Enter amount' value={amount} onChange={(e) => setAmount(e.target.value)} type='number' className='mb-4' />
						</Modal>
					</>
				))}
			</div>
		</div>
	)
}

export default AllRequests
