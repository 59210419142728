const cityToState = {
	'les Escaldes': 'Escaldes-Engordany',
	'Andorra la Vella': 'Andorra la Vella',
	'Umm Al Quwain City': 'Imārat Umm al Qaywayn',
	'Ras Al Khaimah City': 'Raʼs al Khaymah',
	'Zayed City': 'Abu Dhabi',
	'Khawr Fakkān': 'Sharjah',
	Dubai: 'Dubai',
	'Dibba Al-Fujairah': 'Fujairah',
	'Dibba Al-Hisn': 'Fujairah',
	Sharjah: 'Sharjah',
	'Ar Ruways': 'Abu Dhabi',
	'Al Fujairah City': 'Fujairah',
	'Al Ain City': 'Abu Dhabi',
	'Ajman City': 'Ajman',
	'Adh Dhayd': 'Sharjah',
	'Abu Dhabi': 'Abu Dhabi',
	'Khalifah A City': 'Abu Dhabi',
	'Bani Yas City': 'Abu Dhabi',
	Musaffah: 'Abu Dhabi',
	'Al Shamkhah City': 'Abu Dhabi',
	'Reef Al Fujairah City': 'Fujairah',
	Zaranj: 'Nimroz',
	'Bāzār-e Yakāwlang': 'Bamyan',
	Taloqan: 'Takhar',
	Shīnḏanḏ: 'Herat',
	Shibirghān: 'Jowzjan',
	Shahrak: 'Ghowr',
	'Sar-e Pul': 'Sar-e Pol Province',
	'Sang-e Chārak': 'Sar-e Pol Province',
	Aībak: 'Samangan',
	Rustāq: 'Takhar',
	Qarqīn: 'Jowzjan',
	Qarāwul: 'Kunduz',
	'Pul-e Khumrī': 'Baghlan',
	Paghmān: 'Kabul',
	Nahrīn: 'Baghlan',
	Maymana: 'Faryab',
	'Mehtar Lām': 'Laghman',
	'Mazār-e Sharīf': 'Balkh',
	'Lashkar Gāh': 'Helmand',
	Kushk: 'Herat',
	Kunduz: 'Kunduz',
	Khōst: 'Khowst',
	Khulm: 'Balkh',
	Khāsh: 'Sistan and Baluchestan',
	Khanabad: 'Kunduz',
	Karukh: 'Herat',
	Kandahār: 'Kandahar',
	Kabul: 'Kabul',
	Jalālābād: 'Uttar Pradesh',
	'Jabal os Saraj': 'Parwan',
	Herāt: 'Herat',
	Ghormach: 'Badghis',
	Ghazni: 'Ghazni',
	Gereshk: 'Helmand',
	Gardez: 'Paktia',
	Fayzabad: 'Badakhshan',
	Farah: 'Farah',
	'Eslam Qaleh': 'Herat',
	Charikar: 'Parwan',
	'Baraki Barak': 'Logar',
	Bāmyān: 'Bamyan',
	Balkh: 'Balkh',
	Baghlān: 'Baghlan',
	'Ārt Khwājah': 'Takhar',
	Āsmār: 'Kunar',
	Asadābād: 'Hamadān',
	Andkhōy: 'Faryab',
	Bāzārak: 'Panjshir',
	'Markaz-e Woluswalī-ye Āchīn': 'Nangarhar',
	'Saint John’s': 'Saint John',
	'The Valley': 'The Valley',
	Sarandë: 'Vlorë County',
	Pogradec: 'Korçë County',
	Kukës: 'Kukës County',
	Korçë: 'Korçë County',
	Gjirokastër: 'Gjirokastër County',
	Elbasan: 'Elbasan County',
	Burrel: 'Dibër County',
	Vlorë: 'Vlorë County',
	Tirana: 'Tirana',
	Shkodër: 'Shkodër County',
	'Patos Fshat': 'Fier County',
	Patos: 'Paraíba',
	Lushnjë: 'Fier County',
	Lezhë: 'Lezhë County',
	Laç: 'Lezhë County',
	Krujë: 'Durrës County',
	Kavajë: 'Tirana',
	'Fier-Çifçi': 'Fier County',
	Fier: 'Fier County',
	Durrës: 'Durrës County',
	Berat: 'Berat County',
	Kapan: 'Syunik',
	Goris: 'Syunik',
	'Hats’avan': 'Syunik',
	Artashat: 'Ararat',
	Ararat: 'Ararat',
	Yerevan: 'Yerevan',
	Vagharshapat: 'Armavir',
	Stepanavan: 'Lori',
	Spitak: 'Lori',
	Sevan: 'Gegharkunik',
	Masis: 'Ararat',
	Vanadzor: 'Lori',
	Gavar: 'Gegharkunik',
	Hrazdan: 'Kotayk',
	Armavir: 'Krasnodar Krai',
	Gyumri: 'Shirak',
	'Ch’arents’avan': 'Kotayk',
	Ashtarak: 'Aragatsotn',
	Abovyan: 'Kotayk',
	Saurimo: 'Lunda Sul',
	Lucapa: 'Luanda Norte',
	Luau: 'Moxico',
	Uíge: 'Uíge',
	Soio: 'Zaire',
	"N'zeto": 'Zaire',
	'N’dalatando': 'Cuanza Norte',
	'Mbanza Kongo': 'Zaire',
	Malanje: 'Malanje',
	Luanda: 'Luanda',
	Caxito: 'Bengo',
	Cabinda: 'Cabinda',
	Sumbe: 'Kwanza Sul',
	Moçâmedes: 'Namibe',
	Menongue: 'Cuando Cobango',
	Luena: 'Moxico',
	Lubango: 'Huíla',
	Longonjo: 'Huambo',
	Lobito: 'Benguela',
	Cuito: 'Bíe',
	Huambo: 'Huambo',
	Catumbela: 'Benguela',
	Catabola: 'Bíe',
	Camacupa: 'Bíe',
	Caála: 'Huambo',
	Benguela: 'Benguela',
	Zárate: 'Buenos Aires',
	Wanda: 'Misiones',
	'Villa Ortúzar': 'Buenos Aires F.D.',
	'Villa Ocampo': 'Santa Fe',
	'Villa Lugano': 'Buenos Aires F.D.',
	Villaguay: 'Entre Rios',
	'Villa Gesell': 'Buenos Aires',
	'Villa de Mayo': 'Buenos Aires',
	Tortuguitas: 'Buenos Aires',
	Tigre: 'Buenos Aires',
	Tandil: 'Buenos Aires',
	'San Vicente': 'San Vicente',
	'Santo Tomé': 'Santa Fe',
	'Santos Lugares': 'Buenos Aires',
	'Santa Elena': 'Santa Elena',
	'San Pedro': 'California',
	'San Luis del Palmar': 'Corrientes',
	'San Lorenzo': 'California',
	'San Javier': 'Murcia',
	'San Isidro': 'Lima region',
	Saladas: 'Corrientes',
	Retiro: 'Madrid',
	Resistencia: 'Chaco',
	Reconquista: 'Santa Fe',
	Quilmes: 'Buenos Aires',
	'Puerto Rico': 'Caquetá',
	'Puerto Iguazú': 'Misiones',
	'Puerto Esperanza': 'Misiones',
	'Puerto Eldorado': 'Misiones',
	Posadas: 'Misiones',
	Pontevedra: 'Galicia',
	Pirané: 'Formosa',
	Pilar: 'Ñeembucú',
	'Paso de los Libres': 'Corrientes',
	Oberá: 'Misiones',
	Necochea: 'Buenos Aires',
	Muñiz: 'Buenos Aires',
	Morón: 'Carabobo',
	'Monte Caseros': 'Corrientes',
	Montecarlo: 'Misiones',
	Merlo: 'Buenos Aires',
	Mercedes: 'Soriano',
	'Mar del Plata': 'Buenos Aires',
	Luján: 'Buenos Aires',
	'Los Polvorines': 'Buenos Aires',
	'La Plata': 'Huila',
	'La Paz': 'Canelones',
	'Jardín América': 'Misiones',
	Hurlingham: 'Buenos Aires',
	Gualeguaychú: 'Entre Rios',
	Gualeguay: 'Entre Rios',
	Goya: 'Corrientes',
	'Gobernador Virasora': 'Corrientes',
	'General Pacheco': 'Buenos Aires',
	'General José de San Martín': 'Chaco',
	Garupá: 'Misiones',
	Formosa: 'Goiás',
	Fontana: 'California',
	Federal: 'Entre Rios',
	Esquina: 'Corrientes',
	'El Soberbio': 'Misiones',
	'Don Torcuato': 'Buenos Aires',
	Dolores: 'Soriano',
	'Curuzú Cuatiá': 'Corrientes',
	Corrientes: 'Corrientes',
	Concordia: 'Antioquia',
	'Concepción del Uruguay': 'Entre Rios',
	Colegiales: 'Buenos Aires F.D.',
	Chajarí: 'Entre Rios',
	Campana: 'Buenos Aires',
	'Buenos Aires': 'Buenos Aires F.D.',
	Boedo: 'Buenos Aires F.D.',
	Berazategui: 'Buenos Aires',
	'Bella Vista': 'Arkansas',
	Belgrano: 'Buenos Aires F.D.',
	Barranqueras: 'Chaco',
	Barracas: 'Buenos Aires F.D.',
	Balcarce: 'Buenos Aires',
	Azul: 'Buenos Aires',
	Avellaneda: 'Santa Fe',
	'Aristóbulo del Valle': 'Misiones',
	Zapala: 'Neuquen',
	'Yerba Buena': 'Tucuman',
	'Villa Regina': 'Rio Negro',
	'Villa Paula de Sarmiento': 'San Juan',
	'Villa Nueva': 'Guatemala',
	'Villa María': 'Cordoba',
	'Villa Dolores': 'Cordoba',
	'Villa Constitución': 'Santa Fe',
	'Villa Carlos Paz': 'Cordoba',
	'Villa Ángela': 'Chaco',
	'Villa Allende': 'Cordoba',
	Viedma: 'Rio Negro',
	Victoria: 'Texas',
	Vera: 'Santa Fe',
	'Venado Tuerto': 'Santa Fe',
	'Veinticinco de Mayo': 'Buenos Aires',
	Ushuaia: 'Tierra del Fuego',
	Unquillo: 'Cordoba',
	'Tres Isletas': 'Chaco',
	'Tres Arroyos': 'Buenos Aires',
	Trelew: 'Chubut',
	'Termas de Río Hondo': 'Santiago del Estero',
	Tartagal: 'Salta',
	'Tafí Viejo': 'Tucuman',
	Sunchales: 'Santa Fe',
	'Santiago del Estero': 'Santiago del Estero',
	'Santa Rosa': 'California',
	'Santa Lucía': 'Canelones',
	'Santa Fe': 'New Mexico',
	'San Salvador de Jujuy': 'Jujuy',
	'San Ramón de la Nueva Orán': 'Salta',
	'San Rafael': 'Zulia',
	'San Pedro de Jujuy': 'Jujuy',
	'San Nicolás de los Arroyos': 'Buenos Aires',
	'San Miguel de Tucumán': 'Tucuman',
	'San Martín de los Andes': 'Neuquen',
	'San Martín': 'Cuscatlán',
	'San Luis': 'Arizona',
	'San Justo': 'Santa Fe',
	'San Juan': 'Texas',
	'San José de Jáchal': 'San Juan',
	'San Jorge': 'Santa Fe',
	'San Francisco': 'California',
	Catamarca: 'Catamarca',
	'San Antonio Oeste': 'Rio Negro',
	Salta: 'Salta',
	Rufino: 'Santa Fe',
	Rosario: 'Santa Fe',
	'Río Tercero': 'Cordoba',
	'Río Segundo': 'Cordoba',
	'Río Grande': 'Zacatecas',
	'Río Gallegos': 'Santa Cruz',
	'Río Cuarto': 'Cordoba',
	'Río Ceballos': 'Cordoba',
	Rawson: 'Chubut',
	Rafaela: 'Santa Fe',
	Quitilipi: 'Chaco',
	'Punta Alta': 'Buenos Aires',
	'Puerto Madryn': 'Chubut',
	'Presidencia Roque Sáenz Peña': 'Chaco',
	Pocito: 'San Juan',
	Plottier: 'Neuquen',
	Pergamino: 'Buenos Aires',
	Pérez: 'Santa Fe',
	Paraná: 'Entre Rios',
	Palpalá: 'Jujuy',
	Olavarría: 'Buenos Aires',
	'Nueve de Julio': 'Buenos Aires',
	Neuquén: 'Neuquen',
	Morteros: 'Cordoba',
	Monteros: 'Tucuman',
	Mendoza: 'Mendoza',
	'Marcos Juárez': 'Cordoba',
	Machagai: 'Chaco',
	Lincoln: 'Rhode Island',
	'Libertador General San Martín': 'Jujuy',
	'Las Breñas': 'Chaco',
	'La Rioja': 'La Rioja',
	'La Falda': 'Cordoba',
	'La Calera': 'Cordoba',
	Laboulaye: 'Cordoba',
	Junín: 'Junin',
	'Joaquín V. González': 'Salta',
	'Jesús María': 'Aguascalientes',
	'Granadero Baigorria': 'Santa Fe',
	'Gobernador Gálvez': 'Santa Fe',
	'General Villegas': 'Buenos Aires',
	'General Roca': 'Rio Negro',
	'General Pinedo': 'Chaco',
	'General Pico': 'La Pampa',
	'General Mosconi': 'Salta',
	Gálvez: 'Santa Fe',
	Firmat: 'Santa Fe',
	Famaillá: 'Tucuman',
	Esquel: 'Chubut',
	Esperanza: 'Sonora',
	Embarcación: 'Salta',
	Embalse: 'Cordoba',
	'El Bolsón': 'Rio Negro',
	Diamante: 'Entre Rios',
	'Deán Funes': 'Cordoba',
	'Cutral-Có': 'Neuquen',
	'Cruz del Eje': 'Cordoba',
	Crespo: 'Entre Rios',
	Cosquín: 'Cordoba',
	'Coronel Suárez': 'Buenos Aires',
	Coronda: 'Santa Fe',
	Córdoba: 'Veracruz',
	'Comodoro Rivadavia': 'Chubut',
	Cipolletti: 'Rio Negro',
	'Cinco Saltos': 'Rio Negro',
	Chivilcoy: 'Buenos Aires',
	Chimbas: 'San Juan',
	Chilecito: 'La Rioja',
	Charata: 'Chaco',
	Chacabuco: 'Buenos Aires',
	Centenario: 'Ancash',
	Caucete: 'San Juan',
	Catriel: 'Rio Negro',
	Castelli: 'Chaco',
	Casilda: 'Santa Fe',
	Carcarañá: 'Santa Fe',
	'Capitán Bermúdez': 'Santa Fe',
	'Cañada de Gómez': 'Santa Fe',
	'Caleta Olivia': 'Santa Cruz',
	'Bell Ville': 'Cordoba',
	'Bahía Blanca': 'Buenos Aires',
	'Arroyo Seco': 'Santa Fe',
	Arroyito: 'Cordoba',
	Añatuya: 'Santiago del Estero',
	'Alta Gracia': 'Cordoba',
	Allen: 'Texas',
	Alderetes: 'Tucuman',
	Albardón: 'San Juan',
	Aguilares: 'San Salvador',
	Balvanera: 'Buenos Aires F.D.',
	'Villa Santa Rita': 'Buenos Aires F.D.',
	'Villa Mercedes': 'San Luis',
	'San Miguel': 'San Miguel',
	'San Carlos de Bariloche': 'Rio Negro',
	Adrogué: 'Buenos Aires',
	'Ingeniero Pablo Nogués': 'Buenos Aires',
	'José C. Paz': 'Buenos Aires',
	'Santa María': 'Buenos Aires',
	'Pago Pago': 'Eastern District',
	Wilten: 'Tyrol',
	'Wiener Neustadt': 'Lower Austria',
	Vienna: 'Virginia',
	Wetzelsdorf: 'Styria',
	Wels: 'Upper Austria',
	'Weinzierl bei Krems': 'Lower Austria',
	Villach: 'Carinthia',
	Telfs: 'Tyrol',
	Straßgang: 'Styria',
	Stockerau: 'Lower Austria',
	Steyr: 'Upper Austria',
	Simmering: 'Vienna',
	'Sankt Pölten': 'Lower Austria',
	'Sankt Peter': 'Carinthia',
	'Sankt Martin': 'Carinthia',
	Salzburg: 'Salzburg',
	'Saalfelden am Steinernen Meer': 'Salzburg',
	Pradl: 'Tyrol',
	Perchtoldsdorf: 'Lower Austria',
	Ottakring: 'Vienna',
	Mödling: 'Lower Austria',
	Meidling: 'Vienna',
	Lustenau: 'Vorarlberg',
	Linz: 'Upper Austria',
	Klosterneuburg: 'Lower Austria',
	'Klagenfurt am Wörthersee': 'Carinthia',
	Innsbruck: 'Tyrol',
	'Innere Stadt': 'Vienna',
	Hötting: 'Tyrol',
	Hohenems: 'Vorarlberg',
	Hietzing: 'Vienna',
	Hernals: 'Vienna',
	Graz: 'Styria',
	Floridsdorf: 'Vienna',
	Feldkirch: 'Vorarlberg',
	Eggenberg: 'Styria',
	Dornbirn: 'Vorarlberg',
	Bregenz: 'Vorarlberg',
	Baden: 'Aargau',
	Amstetten: 'Lower Austria',
	Favoriten: 'Vienna',
	Donaustadt: 'Vienna',
	Jakomini: 'Styria',
	Lend: 'Styria',
	Geidorf: 'Styria',
	'Sankt Leonhard': 'Styria',
	Andritz: 'Styria',
	Gries: 'Styria',
	Scarborough: 'Tobago',
	Rockingham: 'Western Australia',
	Perth: 'Scotland',
	'Mount Isa': 'Queensland',
	'Morphett Vale': 'South Australia',
	Mandurah: 'Western Australia',
	Kwinana: 'Western Australia',
	Gosnells: 'Western Australia',
	Geraldton: 'Western Australia',
	Gawler: 'South Australia',
	Darwin: 'Northern Territory',
	Byford: 'Western Australia',
	Busselton: 'Western Australia',
	Bunbury: 'Western Australia',
	Australind: 'Western Australia',
	'Alice Springs': 'Northern Territory',
	Albany: 'Oregon',
	Adelaide: 'South Australia',
	Yarraville: 'Victoria',
	Wollert: 'Victoria',
	Wodonga: 'Victoria',
	'Wheelers Hill': 'Victoria',
	Westmead: 'New South Wales',
	Werribee: 'Victoria',
	Warwick: 'Rhode Island',
	Warrnambool: 'Victoria',
	Warragul: 'Victoria',
	'Wantirna South': 'Victoria',
	Wangaratta: 'Victoria',
	Wahroonga: 'New South Wales',
	'Upper Coomera': 'Queensland',
	Truganina: 'Victoria',
	Traralgon: 'Victoria',
	Townsville: 'Queensland',
	Toowoomba: 'Queensland',
	Thornbury: 'Victoria',
	Thomastown: 'Victoria',
	Templestowe: 'Victoria',
	Tarneit: 'Victoria',
	Taree: 'New South Wales',
	Tamworth: 'England',
	Sydney: 'New South Wales',
	'Surry Hills': 'New South Wales',
	'Surfers Paradise': 'Queensland',
	Sunbury: 'Victoria',
	Strathfield: 'New South Wales',
	Springvale: 'Victoria',
	'South Yarra': 'Victoria',
	Southport: 'England',
	Shepparton: 'Victoria',
	'Seven Hills': 'New South Wales',
	Seaford: 'New York',
	'Saint Kilda': 'Victoria',
	'Saint Ives': 'New South Wales',
	'Saint Albans': 'Victoria',
	Ryde: 'England',
	Rowville: 'Victoria',
	Rockhampton: 'Queensland',
	Rockdale: 'New South Wales',
	Ringwood: 'Victoria',
	Richmond: 'KwaZulu-Natal',
	Reservoir: 'Victoria',
	'Quakers Hill': 'New South Wales',
	Preston: 'England',
	'Port Melbourne': 'Victoria',
	'Port Macquarie': 'New South Wales',
	'Point Cook': 'Victoria',
	'Pascoe Vale': 'Victoria',
	'Palm Beach': 'Queensland',
	Pakenham: 'Victoria',
	Orange: 'California',
	Officer: 'Victoria',
	'North Melbourne': 'Victoria',
	Northcote: 'Victoria',
	'Noble Park': 'Victoria',
	Newcastle: 'KwaZulu-Natal',
	Nerang: 'Queensland',
	'Narre Warren': 'Victoria',
	Narangba: 'Queensland',
	Mulgrave: 'Victoria',
	'Mount Waverley': 'Victoria',
	'Mount Martha': 'Victoria',
	'Mount Gambier': 'South Australia',
	'Mount Eliza': 'Victoria',
	'Mount Druitt': 'New South Wales',
	Mosman: 'New South Wales',
	Mornington: 'Victoria',
	Morayfield: 'Queensland',
	'South Morang': 'Victoria',
	Mooroolbark: 'Victoria',
	'Moonee Ponds': 'Victoria',
	Mitcham: 'England',
	Miranda: 'Carabobo',
	'Mill Park': 'Victoria',
	Mildura: 'Victoria',
	Merrylands: 'New South Wales',
	Mernda: 'Victoria',
	Melbourne: 'Florida',
	Mascot: 'New South Wales',
	Maryborough: 'Queensland',
	Marrickville: 'New South Wales',
	Maroubra: 'New South Wales',
	Manly: 'New South Wales',
	Maitland: 'Florida',
	Mackay: 'Queensland',
	Liverpool: 'England',
	Lilydale: 'Victoria',
	Lidcombe: 'New South Wales',
	Launceston: 'Tasmania',
	Lara: 'Victoria',
	Langwarrin: 'Victoria',
	Lalor: 'Victoria',
	Lakemba: 'New South Wales',
	Kogarah: 'New South Wales',
	Keysborough: 'Victoria',
	Kew: 'Victoria',
	Kensington: 'New York',
	Hornsby: 'New South Wales',
	Hobart: 'Indiana',
	'Hawthorn South': 'Victoria',
	Hawthorn: 'Victoria',
	'Hampton Park': 'Victoria',
	Griffith: 'Indiana',
	Greenvale: 'Victoria',
	Greensborough: 'Victoria',
	Granville: 'New South Wales',
	Goulburn: 'New South Wales',
	'Gold Coast': 'Queensland',
	'Glen Waverley': 'Victoria',
	Glenroy: 'Victoria',
	'Glen Iris': 'Victoria',
	Glenferrie: 'Victoria',
	Gladstone: 'Missouri',
	Geelong: 'Victoria',
	'Frankston East': 'Victoria',
	Frankston: 'Victoria',
	Footscray: 'Victoria',
	Fairfield: 'California',
	Essendon: 'Victoria',
	Epping: 'New South Wales',
	Engadine: 'New South Wales',
	Eltham: 'England',
	'Eight Mile Plains': 'Queensland',
	Eastwood: 'England',
	Earlwood: 'New South Wales',
	Dubbo: 'New South Wales',
	Doreen: 'Victoria',
	'Doncaster East': 'Victoria',
	Doncaster: 'England',
	'Deer Park': 'New York',
	'Deception Bay': 'Queensland',
	Dandenong: 'Victoria',
	Croydon: 'England',
	Cronulla: 'New South Wales',
	Cranbourne: 'Victoria',
	Craigieburn: 'Victoria',
	Corio: 'Victoria',
	Coorparoo: 'Queensland',
	Coogee: 'New South Wales',
	'Coffs Harbour': 'New South Wales',
	Coburg: 'Bavaria',
	Clayton: 'North Carolina',
	Wollongong: 'New South Wales',
	Chatswood: 'New South Wales',
	Cessnock: 'New South Wales',
	'Caulfield North': 'Victoria',
	'Castle Hill': 'New South Wales',
	'Carrum Downs': 'Victoria',
	Carnegie: 'Victoria',
	Carlton: 'Victoria',
	Carlingford: 'New South Wales',
	Capalaba: 'Queensland',
	Canberra: 'Australian Capital Territory',
	Camberwell: 'Victoria',
	Cairns: 'Queensland',
	Caboolture: 'Queensland',
	Burwood: 'New South Wales',
	Burnie: 'Tasmania',
	Bundaberg: 'Queensland',
	Buderim: 'Queensland',
	Brunswick: 'Ohio',
	'Broken Hill': 'New South Wales',
	Brisbane: 'Queensland',
	Brighton: 'Colorado',
	Boronia: 'Victoria',
	Blacktown: 'New South Wales',
	Bexley: 'England',
	Berwick: 'Victoria',
	'Bentleigh East': 'Victoria',
	Bentleigh: 'Victoria',
	Bendigo: 'Victoria',
	'Baulkham Hills': 'New South Wales',
	Bathurst: 'New South Wales',
	'Banora Point': 'New South Wales',
	Bankstown: 'New South Wales',
	Ballarat: 'Victoria',
	Bairnsdale: 'Victoria',
	Auburn: 'Washington',
	Ashfield: 'New South Wales',
	'Ascot Vale': 'Victoria',
	Armidale: 'New South Wales',
	'Albany Creek': 'Queensland',
	Maroochydore: 'Queensland',
	Cheltenham: 'England',
	Randwick: 'New South Wales',
	'Dee Why': 'New South Wales',
	'Umina Beach': 'New South Wales',
	Palmerston: 'Northern Territory',
	'Hervey Bay': 'Queensland',
	'Sydney Central Business District': 'New South Wales',
	Kingsford: 'New South Wales',
	'Springfield Lakes': 'Queensland',
	'Bracken Ridge': 'Queensland',
	Kallangur: 'Queensland',
	'North Lakes': 'Queensland',
	'The Gap': 'Queensland',
	'Caroline Springs': 'Victoria',
	'Hoppers Crossing': 'Victoria',
	'Logan City': 'Queensland',
	Carindale: 'Queensland',
	Parramatta: 'New South Wales',
	'Ferntree Gully': 'Victoria',
	'Adelaide Hills': 'South Australia',
	'Canning Vale': 'Western Australia',
	'Glenmore Park': 'New South Wales',
	Southbank: 'Victoria',
	Docklands: 'Victoria',
	Helensvale: 'Queensland',
	Elwood: 'Victoria',
	'Balwyn North': 'Victoria',
	'Malvern East': 'Victoria',
	'Brighton East': 'Victoria',
	'Redbank Plains': 'Queensland',
	Labrador: 'Queensland',
	'St Albans': 'England',
	'Endeavour Hills': 'Victoria',
	Kellyville: 'New South Wales',
	'Taylors Lakes': 'Victoria',
	Cherrybrook: 'New South Wales',
	'Roxburgh Park': 'Victoria',
	Prestons: 'New South Wales',
	'Wyndham Vale': 'Victoria',
	Ballajura: 'Western Australia',
	Willetton: 'Western Australia',
	Glenwood: 'New South Wales',
	Cabramatta: 'New South Wales',
	Campsie: 'New South Wales',
	Casula: 'New South Wales',
	Cranebrook: 'New South Wales',
	Greenacre: 'New South Wales',
	Greystanes: 'New South Wales',
	Ellenbrook: 'Western Australia',
	'Alexandra Hills': 'Queensland',
	Calamvale: 'Queensland',
	Yagoona: 'New South Wales',
	Highton: 'Victoria',
	'Pacific Pines': 'Queensland',
	Robina: 'Queensland',
	Paralowie: 'South Australia',
	Baldivis: 'Western Australia',
	Dianella: 'Western Australia',
	Duncraig: 'Western Australia',
	Morley: 'England',
	Thornlie: 'Western Australia',
	Landsdale: 'Western Australia',
	Punchbowl: 'New South Wales',
	'Parafield Gardens': 'South Australia',
	Hillside: 'New York',
	'St Clair': 'New South Wales',
	Kirwan: 'Queensland',
	Bundoora: 'Victoria',
	'Forest Lake': 'Minnesota',
	'Sunnybank Hills': 'Queensland',
	'Rochedale South': 'Queensland',
	'Keilor East': 'Victoria',
	'Cranbourne East': 'Victoria',
	'Cranbourne North': 'Victoria',
	'Cranbourne West': 'Victoria',
	'Narre Warren South': 'Victoria',
	'Dandenong North': 'Victoria',
	'Frankston South': 'Victoria',
	'Sunshine West': 'Victoria',
	'Altona Meadows': 'Victoria',
	'Taylors Hill': 'Victoria',
	Hurstville: 'New South Wales',
	'West Pennant Hills': 'New South Wales',
	'Melbourne City Centre': 'Victoria',
	'Adelaide city centre': 'South Australia',
	'Wollongong city centre': 'New South Wales',
	'Tanki Leendert': '',
	'San Nicolas': 'Ilocos',
	Oranjestad: '',
	Mariehamn: 'Mariehamns stad',
	Xankandi: 'Xankǝndi',
	Əhmədbəyli: 'Saatlı',
	Şuşa: 'Shusha',
	Salyan: 'Salyan',
	Saatlı: 'Saatlı',
	Neftçala: 'Neftçala',
	Nakhchivan: 'Nakhichevan',
	Lerik: 'Lerik',
	Lankaran: 'Lənkəran',
	Imishli: 'İmişli',
	Fizuli: 'Füzuli',
	Jalilabad: 'Jalilabad',
	Pushkino: 'Moscow Oblast',
	Beylagan: 'Beyləqan',
	Astara: 'Astara',
	Şirvan: 'Shirvan',
	Ağdam: 'Ağdam',
	Zaqatala: 'Zaqatala',
	Zabrat: 'Baki',
	Yevlakh: 'Yevlax City',
	Göygöl: 'Goygol Rayon',
	Xaçmaz: 'Xaçmaz',
	Ujar: 'Ucar',
	Tovuz: 'Tovuz',
	Terter: 'Tǝrtǝr',
	Sumqayıt: 'Sumqayit',
	Qaraçuxur: 'Baki',
	Shamkhor: 'Şǝmkir',
	Shamakhi: 'Şamaxı',
	Sheki: 'Shaki City',
	Sabunçu: 'Baki',
	Sabirabad: 'Sabirabad',
	Qusar: 'Qusar',
	Quba: 'Quba',
	Hacıqabul: 'Hacıqabul',
	Qazax: 'Qazax',
	'Hacı Zeynalabdin': 'Sumqayit',
	Mingelchaur: 'Mingǝcevir',
	Maştağa: 'Baki',
	Mardakan: 'Baki',
	Lokbatan: 'Baki',
	Kyurdarmir: 'Kürdǝmir',
	Khirdalan: 'Abşeron',
	İsmayıllı: 'İsmayıllı',
	'Yeni Suraxanı': 'Baki',
	Göyçay: 'Göyçay',
	Hövsan: 'Baki',
	Ganja: 'Gǝncǝ',
	Divichibazar: 'Shabran',
	Buzovna: 'Baki',
	'Biny Selo': 'Baki',
	Barda: 'Barda',
	Bilajari: 'Baki',
	Baku: 'Baki',
	Amirdzhan: 'Baki',
	Aghsu: 'Ağsu',
	Ağdaş: 'Ağdaş',
	Agdzhabedy: 'Ağcabǝdi',
	Bakıxanov: 'Baki',
	Zenica: 'Federation of B&H',
	Visoko: 'Federation of B&H',
	'Velika Kladuša': 'Federation of B&H',
	Tuzla: 'Federation of B&H',
	Trebinje: 'Srpska',
	Travnik: 'Federation of B&H',
	Sarajevo: 'Federation of B&H',
	Prijedor: 'Srpska',
	Mostar: 'Federation of B&H',
	Lukavac: 'Federation of B&H',
	Gradačac: 'Federation of B&H',
	Gračanica: 'Federation of B&H',
	Goražde: 'Federation of B&H',
	Doboj: 'Srpska',
	Cazin: 'Federation of B&H',
	Bugojno: 'Federation of B&H',
	Brčko: 'Brčko',
	'Bosanska Krupa': 'Federation of B&H',
	Bijeljina: 'Srpska',
	Bihać: 'Federation of B&H',
	'Banja Luka': 'Srpska',
	Konjic: 'Federation of B&H',
	Bridgetown: 'Saint Michael',
	Thākurgaon: 'Rangpur Division',
	Teknāf: 'Chittagong',
	Tungi: 'Dhaka',
	Sylhet: 'Sylhet',
	Dohār: 'Dhaka',
	Jamālpur: 'Bihar',
	Shibganj: 'Rajshahi Division',
	Sātkhira: 'Khulna',
	Sirajganj: 'Rajshahi Division',
	Netrakona: 'Mymensingh Division',
	Narsingdi: 'Dhaka',
	Sandwīp: 'Chittagong',
	Shāhzādpur: 'Rajshahi Division',
	Rāmganj: 'Chittagong',
	Rājshāhi: 'Rajshahi Division',
	Pirojpur: 'Barisāl',
	Panchagarh: 'Rangpur Division',
	Patiya: 'Chittagong',
	Parbatipur: 'Rangpur Division',
	Nārāyanganj: 'Dhaka',
	Nālchiti: 'Barisāl',
	Nāgarpur: 'Dhaka',
	Nageswari: 'Rangpur Division',
	Mymensingh: 'Mymensingh Division',
	Muktāgācha: 'Mymensingh Division',
	Mirzāpur: 'Uttar Pradesh',
	'Maulavi Bāzār': 'Sylhet',
	Morrelgonj: 'Khulna',
	Mehendiganj: 'Barisāl',
	Mathba: 'Barisāl',
	Lalmonirhat: 'Rangpur Division',
	Lākshām: 'Chittagong',
	Comilla: 'Chittagong',
	Rangpur: 'Rangpur Division',
	Kushtia: 'Khulna',
	Kālīganj: 'Khulna',
	Jhingergācha: 'Khulna',
	'Joypur Hāt': 'Rajshahi Division',
	Ishurdi: 'Rajshahi Division',
	Habiganj: 'Sylhet',
	Gaurnadi: 'Barisāl',
	Gafargaon: 'Mymensingh Division',
	Feni: 'Chittagong',
	Rāipur: 'Uttarakhand',
	Sarankhola: 'Khulna',
	Dhaka: 'Dhaka',
	Chilmāri: 'Rangpur Division',
	Chhāgalnāiya: 'Chittagong',
	Lālmohan: 'Barisāl',
	Khagrachhari: 'Chittagong',
	Chhātak: 'Sylhet',
	'Bhātpāra Abhaynagar': 'Khulna',
	Bherāmāra: 'Khulna',
	'Bhairab Bāzār': 'Dhaka',
	Bāndarban: 'Chittagong',
	Kālia: 'Khulna',
	Baniachang: 'Sylhet',
	Bājitpur: 'Dhaka',
	Bagerhat: 'Khulna',
	Badarganj: 'Rangpur Division',
	Narail: 'Khulna',
	Tungipāra: 'Dhaka',
	Sonārgaon: 'Dhaka',
	Sarishābāri: 'Mymensingh Division',
	Sakhipur: 'Dhaka',
	Raojān: 'Chittagong',
	Phultala: 'Khulna',
	Pālang: 'Dhaka',
	'Pār Naogaon': 'Rajshahi Division',
	Nabīnagar: 'Bihar',
	Lakshmīpur: 'Chittagong',
	Kesabpur: 'Khulna',
	Hājīganj: 'Chittagong',
	Farīdpur: 'Uttar Pradesh',
	Dinājpur: 'Rangpur Division',
	'Uttar Char Fasson': 'Khulna',
	Chattogram: 'Chittagong',
	'Char Bhadrāsan': 'Dhaka',
	Bera: 'Rajshahi Division',
	Burhānuddin: 'Barisāl',
	Sātkania: 'Chittagong',
	'Cox’s Bāzār': 'Chittagong',
	Khulna: 'Khulna',
	Bhola: 'Barisāl',
	Barishal: 'Barisāl',
	Jessore: 'Khulna',
	Pābna: 'Rajshahi Division',
	Tāngāil: 'Dhaka',
	Bogra: 'Rajshahi Division',
	Pīrgaaj: 'Rangpur Division',
	Nawābganj: 'Uttar Pradesh',
	Madaripur: 'Dhaka',
	Sherpur: 'Mymensingh Division',
	Kishorganj: 'Dhaka',
	Manikchari: 'Chittagong',
	Bhāndāria: 'Barisāl',
	'Bibir Hat': 'Chittagong',
	Saidpur: 'Uttar Pradesh',
	Puthia: 'Rajshahi Division',
	Natore: 'Rajshahi Division',
	Azimpur: 'Dhaka',
	'Paotana Hat': 'Rangpur Division',
	Paltan: 'Dhaka',
	Joymontop: 'Dhaka',
	Zwijndrecht: 'South Holland',
	Zwevegem: 'Flanders',
	Zottegem: 'Flanders',
	Zonhoven: 'Flanders',
	Zolder: 'Flanders',
	Zemst: 'Flanders',
	Zele: 'Flanders',
	Zedelgem: 'Flanders',
	Zaventem: 'Flanders',
	Wondelgem: 'Flanders',
	'Woluwe-Saint-Lambert': 'Brussels Capital',
	Wilrijk: 'Flanders',
	Willebroek: 'Flanders',
	Wevelgem: 'Flanders',
	Wetteren: 'Flanders',
	Westerlo: 'Flanders',
	Wervik: 'Flanders',
	Wavre: 'Wallonia',
	'Watermael-Boitsfort': 'Brussels Capital',
	Waterloo: 'Iowa',
	Waregem: 'Flanders',
	Walcourt: 'Wallonia',
	Visé: 'Wallonia',
	Vilvoorde: 'Flanders',
	Verviers: 'Wallonia',
	Uccle: 'Brussels Capital',
	Turnhout: 'Flanders',
	Tubize: 'Wallonia',
	Tournai: 'Wallonia',
	Torhout: 'Flanders',
	Tongeren: 'Flanders',
	Tienen: 'Flanders',
	Tielt: 'Flanders',
	Tessenderlo: 'Flanders',
	Tervuren: 'Flanders',
	Temse: 'Flanders',
	Stekene: 'Flanders',
	Stabroek: 'Flanders',
	Soumagne: 'Wallonia',
	Soignies: 'Wallonia',
	'Sint-Truiden': 'Flanders',
	'Sint-Pieters-Leeuw': 'Flanders',
	'Sint-Niklaas': 'Flanders',
	'Sint-Kruis': 'Flanders',
	'Sint-Katelijne-Waver': 'Flanders',
	'Sint-Gillis-Waas': 'Flanders',
	'Sint-Genesius-Rode': 'Flanders',
	'Sint-Andries': 'Flanders',
	'Sint-Amandsberg': 'Flanders',
	Seraing: 'Wallonia',
	Schoten: 'Flanders',
	Schilde: 'Flanders',
	Schaerbeek: 'Brussels Capital',
	'Saint-Nicolas': 'Wallonia',
	'Saint-Josse-ten-Noode': 'Brussels Capital',
	'Saint-Gilles': 'Brussels Capital',
	'Saint-Ghislain': 'Wallonia',
	Rotselaar: 'Flanders',
	Ronse: 'Flanders',
	Roeselare: 'Flanders',
	Rixensart: 'Wallonia',
	Riemst: 'Flanders',
	Ranst: 'Flanders',
	Quaregnon: 'Wallonia',
	Puurs: 'Flanders',
	Putte: 'Flanders',
	Poperinge: 'Flanders',
	'Pont-à-Celles': 'Wallonia',
	Péruwelz: 'Wallonia',
	Peer: 'Flanders',
	Overijse: 'Flanders',
	Oupeye: 'Wallonia',
	Oudenaarde: 'Flanders',
	Oostkamp: 'Flanders',
	Ostend: 'Flanders',
	Nivelles: 'Wallonia',
	Ninove: 'Flanders',
	Nijlen: 'Flanders',
	Neerpelt: 'Flanders',
	Namur: 'Wallonia',
	Mouscron: 'Wallonia',
	Mortsel: 'Flanders',
	'Morlanwelz-Mariemont': 'Wallonia',
	'Montignies-sur-Sambre': 'Wallonia',
	Mons: 'Wallonia',
	'Molenbeek-Saint-Jean': 'Brussels Capital',
	Mol: 'Flanders',
	Middelkerke: 'Flanders',
	Merksem: 'Flanders',
	Merelbeke: 'Flanders',
	Menen: 'Flanders',
	Meise: 'Flanders',
	'Mechelen-aan-de-Maas': 'Flanders',
	Mechelen: 'Flanders',
	Mariakerke: 'Flanders',
	Marcinelle: 'Wallonia',
	'Marchienne-au-Pont': 'Wallonia',
	'Marche-en-Famenne': 'Wallonia',
	Manage: 'Wallonia',
	Maldegem: 'Flanders',
	Maasmechelen: 'Flanders',
	Maaseik: 'Flanders',
	'Louvain-la-Neuve': 'Wallonia',
	Lommel: 'Flanders',
	Lokeren: 'Flanders',
	Lochristi: 'Flanders',
	Lille: 'Hauts-de-France',
	Lier: 'Flanders',
	Liège: 'Wallonia',
	Leuven: 'Flanders',
	Lessines: 'Wallonia',
	Lede: 'Flanders',
	Lebbeke: 'Flanders',
	Lanaken: 'Flanders',
	'La Louvière': 'Wallonia',
	Kortrijk: 'Flanders',
	Kortenberg: 'Flanders',
	Kontich: 'Flanders',
	Koksijde: 'Flanders',
	Koersel: 'Flanders',
	Koekelberg: 'Brussels Capital',
	'Knokke-Heist': 'Flanders',
	'Kessel-Lo': 'Flanders',
	Kasterlee: 'Flanders',
	Kapellen: 'Flanders',
	Kalmthout: 'Flanders',
	Jumet: 'Wallonia',
	Jette: 'Brussels Capital',
	Jambes: 'Wallonia',
	Izegem: 'Flanders',
	Ixelles: 'Brussels Capital',
	Ieper: 'Flanders',
	Huy: 'Wallonia',
	Houthalen: 'Flanders',
	Hoogstraten: 'Flanders',
	Hoboken: 'New Jersey',
	Heverlee: 'Flanders',
	Heusy: 'Wallonia',
	Heusden: 'Flanders',
	Herzele: 'Flanders',
	Herve: 'Wallonia',
	Herstal: 'Wallonia',
	Herentals: 'Flanders',
	Herent: 'Flanders',
	Helchteren: 'Flanders',
	'Heist-op-den-Berg': 'Flanders',
	Hasselt: 'Flanders',
	Harelbeke: 'Flanders',
	Hamme: 'Flanders',
	Halle: 'North Rhine-Westphalia',
	Haaltert: 'Flanders',
	Grivegnée: 'Wallonia',
	Grimbergen: 'Flanders',
	Gilly: 'Wallonia',
	Geraardsbergen: 'Flanders',
	Gentbrugge: 'Flanders',
	Gent: 'Flanders',
	Genk: 'Flanders',
	Gembloux: 'Wallonia',
	Geel: 'Flanders',
	Ganshoren: 'Brussels Capital',
	Frameries: 'Wallonia',
	Forest: 'Brussels Capital',
	Fleurus: 'Wallonia',
	Fléron: 'Wallonia',
	'Flémalle-Haute': 'Wallonia',
	Evergem: 'Flanders',
	Evere: 'Brussels Capital',
	Eupen: 'Wallonia',
	Etterbeek: 'Brussels Capital',
	Ekeren: 'Flanders',
	Eeklo: 'Flanders',
	Edegem: 'Flanders',
	Duffel: 'Flanders',
	Dour: 'Wallonia',
	Dilbeek: 'Flanders',
	Diksmuide: 'Flanders',
	Diest: 'Flanders',
	Diepenbeek: 'Flanders',
	Deurne: 'Flanders',
	Destelbergen: 'Flanders',
	Dendermonde: 'Flanders',
	Denderleeuw: 'Flanders',
	Deinze: 'Flanders',
	Courcelles: 'Wallonia',
	Colfontaine: 'Wallonia',
	Chaudfontaine: 'Wallonia',
	Châtelineau: 'Wallonia',
	Châtelet: 'Wallonia',
	'Chasse Royale': 'Wallonia',
	Charleroi: 'Wallonia',
	Brussels: 'Brussels Capital',
	Brugge: 'Flanders',
	Brasschaat: 'Flanders',
	'Braine-le-Comte': 'Wallonia',
	"Braine-l'Alleud": 'Wallonia',
	Boussu: 'Wallonia',
	Bornem: 'Flanders',
	Borgerhout: 'Flanders',
	Boom: 'Flanders',
	Blauwput: 'Flanders',
	Blankenberge: 'Flanders',
	Binche: 'Wallonia',
	Bilzen: 'Flanders',
	Beveren: 'Flanders',
	Bevere: 'Flanders',
	Beringen: 'Flanders',
	'Berchem-Sainte-Agathe': 'Brussels Capital',
	Berchem: 'Flanders',
	Beersel: 'Flanders',
	Beerse: 'Flanders',
	Balen: 'Flanders',
	Auderghem: 'Brussels Capital',
	Ath: 'Wallonia',
	Assebroek: 'Flanders',
	Asse: 'Flanders',
	Arlon: 'Wallonia',
	Antwerpen: 'Flanders',
	Ans: 'Wallonia',
	Anderlecht: 'Brussels Capital',
	Andenne: 'Wallonia',
	Aarschot: 'Flanders',
	Aalter: 'Flanders',
	Aalst: 'Flanders',
	Zorgo: 'Plateau-Central',
	Ziniaré: 'Plateau-Central',
	Yako: 'Nord',
	Tougan: 'Boucle du Mouhoun',
	Toma: 'Boucle du Mouhoun',
	Titao: 'Nord',
	Tenkodogo: 'Centre-Est',
	Salanso: 'Boucle du Mouhoun',
	Sapouy: 'Centre-Ouest',
	Réo: 'Centre-Ouest',
	Pô: 'Centre-Sud',
	Ouahigouya: 'Nord',
	Ouagadougou: 'Centre',
	Nouna: 'Boucle du Mouhoun',
	Manga: 'Centre-Sud',
	Léo: 'Centre-Ouest',
	Koupéla: 'Centre-Est',
	Koudougou: 'Centre-Ouest',
	Kongoussi: 'Centre-Nord',
	Kombissiri: 'Centre-Sud',
	Kokologo: 'Centre-Ouest',
	Kaya: 'Centre-Nord',
	Houndé: 'Hauts-Bassins',
	Gourcy: 'Nord',
	Gayéri: 'Est',
	Garango: 'Centre-Est',
	Gaoua: 'Sud-Ouest',
	"Fada N'gourma": 'Est',
	Dori: 'Sahel',
	Djibo: 'Sahel',
	Diébougou: 'Sud-Ouest',
	Diapaga: 'Est',
	Dédougou: 'Boucle du Mouhoun',
	Dano: 'Sud-Ouest',
	Boussé: 'Plateau-Central',
	Boulsa: 'Centre-Nord',
	Boromo: 'Boucle du Mouhoun',
	Bogandé: 'Est',
	'Bobo-Dioulasso': 'Hauts-Bassins',
	Batié: 'Sud-Ouest',
	Banfora: 'Cascades',
	Yambol: 'Yambol',
	Vratsa: 'Vratsa',
	Vidin: 'Vidin',
	Velingrad: 'Pazardzhik',
	'Veliko Tŭrnovo': 'Veliko Tarnovo',
	Varna: 'Varna',
	Targovishte: 'Targovishte',
	Troyan: 'Lovech',
	Dobrich: 'Dobrich',
	Svishtov: 'Veliko Tarnovo',
	Svilengrad: 'Haskovo',
	'Stara Zagora': 'Stara Zagora',
	Dupnitsa: 'Kyustendil',
	Sofia: 'Sofia-Capital',
	Smolyan: 'Smolyan',
	Sliven: 'Sliven',
	Silistra: 'Silistra',
	Shumen: 'Shumen',
	Sevlievo: 'Gabrovo',
	Sandanski: 'Blagoevgrad',
	Samokov: 'Sofia',
	Ruse: 'Ruse',
	Razgrad: 'Razgrad',
	Rakovski: 'Plovdiv',
	Parvomay: 'Plovdiv',
	Popovo: 'Targovishte',
	Plovdiv: 'Plovdiv',
	Pleven: 'Pleven',
	Petrich: 'Blagoevgrad',
	Peshtera: 'Pazardzhik',
	Pernik: 'Pernik',
	Pazardzhik: 'Pazardzhik',
	Panagyurishte: 'Pazardzhik',
	'Nova Zagora': 'Sliven',
	Montana: 'Montana',
	Lovech: 'Lovech',
	Lom: 'Montana',
	Kyustendil: 'Kyustendil',
	Kardzhali: 'Kardzhali',
	Haskovo: 'Haskovo',
	Harmanli: 'Haskovo',
	Kazanlak: 'Stara Zagora',
	Karnobat: 'Burgas',
	Karlovo: 'Plovdiv',
	'Gotse Delchev': 'Blagoevgrad',
	'Gorna Oryahovitsa': 'Veliko Tarnovo',
	Gabrovo: 'Gabrovo',
	Dimitrovgrad: 'Ulyanovsk',
	Chirpan: 'Stara Zagora',
	Burgas: 'Burgas',
	Botevgrad: 'Sofia',
	Blagoevgrad: 'Blagoevgrad',
	Berkovitsa: 'Montana',
	Aytos: 'Burgas',
	Asparuhovo: 'Varna',
	Asenovgrad: 'Plovdiv',
	Sitrah: 'Manama',
	'Madīnat ‘Īsá': 'Southern Governorate',
	'Jidd Ḩafş': 'Manama',
	'Madīnat Ḩamad': 'Northern',
	'Dār Kulayb': 'Southern Governorate',
	'Al Muharraq': 'Muharraq',
	Manama: 'Manama',
	'Ar Rifā‘': 'Southern Governorate',
	Makamba: 'Makamba',
	Bururi: 'Bururi',
	Bujumbura: 'Bujumbura Mairie',
	Muramvya: 'Muramvya',
	Gitega: 'Gitega',
	Ruyigi: 'Ruyigi',
	Ngozi: 'Ngozi',
	Kayanza: 'Kayanza',
	Muyinga: 'Muyinga',
	Rutana: 'Rutana',
	Tchaourou: 'Borgou',
	Tanguiéta: 'Atakora',
	Savé: 'Collines',
	Savalou: 'Collines',
	Sakété: 'Plateau',
	'Porto-Novo': 'Ouémé',
	Pobé: 'Plateau',
	Parakou: 'Borgou',
	Ouidah: 'Atlantique',
	Nikki: 'Borgou',
	Natitingou: 'Atakora',
	Malanville: 'Alibori',
	Lokossa: 'Mono',
	Kétou: 'Plateau',
	Kandi: 'Alibori',
	Dogbo: 'Kouffo',
	Djougou: 'Donga',
	'Dassa-Zoumé': 'Collines',
	Cové: 'Zou',
	Cotonou: 'Littoral',
	Comé: 'Collines',
	Bohicon: 'Zou',
	Bembèrèkè: 'Borgou',
	Bassila: 'Donga',
	Banikoara: 'Alibori',
	Aplahoué: 'Kouffo',
	Allada: 'Atlantique',
	'Abomey-Calavi': 'Atlantique',
	Abomey: 'Zou',
	Gustavia: '',
	Hamilton: 'Ohio',
	Tutong: 'Tutong',
	Seria: 'Belait',
	'Kuala Belait': 'Belait',
	'Bandar Seri Begawan': 'Brunei-Muara District',
	Yacuiba: 'Tarija Department',
	Warnes: 'Santa Cruz Department',
	Vinto: 'Cochabamba',
	Villazón: 'Potosí Department',
	'Villa Yapacaní': 'Santa Cruz Department',
	Villamontes: 'Tarija Department',
	Viacha: 'La Paz Department',
	Tupiza: 'Potosí Department',
	Trinidad: 'Flores Department',
	Tiquipaya: 'Cochabamba',
	Tarija: 'Tarija Department',
	Sucre: 'Manabí',
	'Sipe Sipe': 'Cochabamba',
	'El Torno': 'Santa Cruz Department',
	'Santa Cruz de la Sierra': 'Santa Cruz Department',
	'San Ignacio de Velasco': 'Santa Cruz Department',
	Sacaba: 'Cochabamba',
	Riberalta: 'Beni Department',
	Quillacollo: 'Cochabamba',
	Punata: 'Cochabamba',
	Potosí: 'Potosí Department',
	Oruro: 'Oruro',
	Montero: 'Santa Cruz Department',
	Mizque: 'Cochabamba',
	Llallagua: 'Potosí Department',
	Laja: 'Biobío',
	Huanuni: 'Oruro',
	Guayaramerín: 'Beni Department',
	Cotoca: 'Santa Cruz Department',
	Colcapirhua: 'Cochabamba',
	Cochabamba: 'Cochabamba',
	Cobija: 'Pando',
	Caranavi: 'La Paz Department',
	Camiri: 'Santa Cruz Department',
	Bermejo: 'Tarija Department',
	Achocalla: 'La Paz Department',
	'Ascención de Guarayos': 'Santa Cruz Department',
	'San Borja': 'Beni Department',
	Kralendijk: 'Bonaire',
	'Vitória do Mearim': 'Maranhão',
	'Vitória de Santo Antão': 'Pernambuco',
	Viseu: 'Viseu',
	Conde: 'Bahia',
	Vigia: 'Pará',
	'Viçosa do Ceará': 'Ceará',
	Viçosa: 'Minas Gerais',
	Viana: 'Espírito Santo',
	'Várzea Alegre': 'Ceará',
	Varjota: 'Ceará',
	'Vargem Grande': 'Maranhão',
	'Valença do Piauí': 'Piauí',
	'União dos Palmares': 'Alagoas',
	União: 'Piauí',
	Tuntum: 'Maranhão',
	Tucuruí: 'Pará',
	Tucumã: 'Pará',
	Trindade: 'Santa Catarina',
	Trairi: 'Ceará',
	Toritama: 'Pernambuco',
	'Tomé Açu': 'Pará',
	Timon: 'Maranhão',
	Timbiras: 'Maranhão',
	Timbaúba: 'Pernambuco',
	Tianguá: 'Ceará',
	Teresina: 'Piauí',
	'São João dos Inhamuns': 'Ceará',
	Tamandaré: 'Pernambuco',
	Tabira: 'Pernambuco',
	Surubim: 'Pernambuco',
	Sousa: 'Paraíba',
	Soure: 'Pará',
	Solânea: 'Paraíba',
	Sobral: 'Ceará',
	Sirinhaém: 'Pernambuco',
	Sertânia: 'Pernambuco',
	'Serra Talhada': 'Pernambuco',
	'Senador Pompeu': 'Ceará',
	Satuba: 'Alagoas',
	'São Raimundo Nonato': 'Piauí',
	'São Miguel dos Campos': 'Alagoas',
	'São Miguel do Guamá': 'Pará',
	'São Mateus do Maranhão': 'Maranhão',
	'São Luís do Quitunde': 'Alagoas',
	'São Luís': 'Maranhão',
	'São Lourenço da Mata': 'Pernambuco',
	'São José do Egito': 'Pernambuco',
	'São José de Ribamar': 'Maranhão',
	'São José de Mipibu': 'Rio Grande do Norte',
	'São João dos Patos': 'Maranhão',
	'São Gonçalo do Amarante': 'Ceará',
	'São Félix do Xingu': 'Pará',
	'São Domingos do Maranhão': 'Maranhão',
	'São Bento': 'Maranhão',
	'Santa Rita': 'Zulia',
	Santarém: 'Santarém',
	'Santa Quitéria do Maranhão': 'Maranhão',
	'Santa Quitéria': 'Ceará',
	'Santana do Ipanema': 'Alagoas',
	'Santa Luzia': 'Minas Gerais',
	'Santa Inês': 'Maranhão',
	'Santa Helena': 'Maranhão',
	'Santa Cruz do Capibaribe': 'Pernambuco',
	'Santa Cruz': 'California',
	Salinópolis: 'Pará',
	Salgueiro: 'Pernambuco',
	Russas: 'Ceará',
	'Rio Largo': 'Alagoas',
	'Rio Formoso': 'Pernambuco',
	Ribeirão: 'Pernambuco',
	Recife: 'Pernambuco',
	Quixeramobim: 'Ceará',
	Quixadá: 'Ceará',
	'Presidente Dutra': 'Maranhão',
	Santana: 'Amapá',
	'Porto Calvo': 'Alagoas',
	Portel: 'Pará',
	Pombos: 'Pernambuco',
	Pombal: 'Leiria',
	Piripiri: 'Piauí',
	Piracuruca: 'Piauí',
	Pinheiro: 'Maranhão',
	'Pindaré Mirim': 'Maranhão',
	Picos: 'Piauí',
	Petrolina: 'Pernambuco',
	Jatobá: 'Pernambuco',
	Pesqueira: 'Pernambuco',
	Pentecoste: 'Ceará',
	Penalva: 'Maranhão',
	'Pedro II': 'Piauí',
	'Pedra Branca': 'Ceará',
	'Paulo Afonso': 'Bahia',
	Paulista: 'Pernambuco',
	Parnamirim: 'Rio Grande do Norte',
	Parnaíba: 'Piauí',
	Parintins: 'Amazonas',
	Parelhas: 'Rio Grande do Norte',
	Paraipaba: 'Ceará',
	Paragominas: 'Pará',
	Paracuru: 'Ceará',
	Palmares: 'Pernambuco',
	Pacatuba: 'Ceará',
	Pacajus: 'Ceará',
	Ouricuri: 'Pernambuco',
	Orós: 'Ceará',
	Oriximiná: 'Pará',
	Olinda: 'Pernambuco',
	Oeiras: 'Lisbon',
	Óbidos: 'Pará',
	'Nova Russas': 'Ceará',
	'Nova Cruz': 'Rio Grande do Norte',
	'Nazaré da Mata': 'Pernambuco',
	Natal: 'Rio Grande do Norte',
	Murici: 'Alagoas',
	Moreno: 'Pernambuco',
	'Morada Nova': 'Ceará',
	Monteiro: 'Paraíba',
	'Monte Alegre': 'Pará',
	Mombaça: 'Ceará',
	Moju: 'Pará',
	Mossoró: 'Rio Grande do Norte',
	Mocajuba: 'Pará',
	'Miracema do Tocantins': 'Tocantins',
	Maués: 'Amazonas',
	'Matriz de Camaragibe': 'Alagoas',
	Mari: 'Paraíba',
	'Marechal Deodoro': 'Alagoas',
	Maragogi: 'Alagoas',
	Maracanaú: 'Ceará',
	Marabá: 'Pará',
	Mamanguape: 'Paraíba',
	Maceió: 'Alagoas',
	Macau: '',
	Macapá: 'Amapá',
	Macaíba: 'Rio Grande do Norte',
	'Limoeiro do Norte': 'Ceará',
	Limoeiro: 'Pernambuco',
	'Lavras da Mangabeira': 'Ceará',
	Lajedo: 'Pernambuco',
	'Lago da Pedra': 'Maranhão',
	'Lagoa do Itaenga': 'Pernambuco',
	'Juazeiro do Norte': 'Ceará',
	'José de Freitas': 'Piauí',
	'João Pessoa': 'Paraíba',
	'João Câmara': 'Rio Grande do Norte',
	Jaguaruana: 'Ceará',
	Jaguaribe: 'Ceará',
	Jaboatão: 'Pernambuco',
	Itupiranga: 'Pará',
	Itacoatiara: 'Amazonas',
	Itaporanga: 'Paraíba',
	Itapissuma: 'Pernambuco',
	Itapipoca: 'Ceará',
	'Itapecuru Mirim': 'Maranhão',
	Itapagé: 'Ceará',
	Itaituba: 'Pará',
	Itaitinga: 'Ceará',
	Itabaiana: 'Sergipe',
	Ipueiras: 'Piauí',
	Ipubi: 'Pernambuco',
	Ipu: 'Ceará',
	Ipojuca: 'Pernambuco',
	Imperatriz: 'Maranhão',
	Iguatu: 'Ceará',
	'Igarapé Miri': 'Pará',
	'Igarapé Açu': 'Pará',
	Igarassu: 'Pernambuco',
	Icó: 'Ceará',
	Horizonte: 'Ceará',
	'Guaraciaba do Norte': 'Ceará',
	Guarabira: 'Paraíba',
	Guaiúba: 'Ceará',
	Gravatá: 'Pernambuco',
	Granja: 'Ceará',
	Grajaú: 'Maranhão',
	Goiana: 'Pernambuco',
	'Glória do Goitá': 'Pernambuco',
	Garanhuns: 'Pernambuco',
	Gameleira: 'Pernambuco',
	Fortaleza: 'Ceará',
	Floriano: 'Piauí',
	Floresta: 'Pernambuco',
	Eusébio: 'Ceará',
	Extremoz: 'Rio Grande do Norte',
	Estreito: 'Maranhão',
	Esperantina: 'Piauí',
	Esperança: 'Paraíba',
	Escada: 'Pernambuco',
	'Dom Pedro': 'Maranhão',
	'Demerval Lobão': 'Piauí',
	'Delmiro Gouveia': 'Alagoas',
	Custódia: 'Pernambuco',
	Cururupu: 'Maranhão',
	'Currais Novos': 'Rio Grande do Norte',
	Cupira: 'Pernambuco',
	Crato: 'Ceará',
	Crateús: 'Ceará',
	Coroatá: 'Maranhão',
	Condado: 'Sancti Spíritus Province',
	'Conceição do Araguaia': 'Pará',
	Colinas: 'Maranhão',
	'Coelho Neto': 'Maranhão',
	Codó: 'Maranhão',
	Chapadinha: 'Maranhão',
	'Chã Grande': 'Pernambuco',
	'Ceará Mirim': 'Rio Grande do Norte',
	Caxias: 'Maranhão',
	Caucaia: 'Ceará',
	'Catolé do Rocha': 'Paraíba',
	Catende: 'Pernambuco',
	Castanhal: 'Pará',
	Cascavel: 'Paraná',
	Caruaru: 'Pernambuco',
	Carpina: 'Pernambuco',
	Carolina: 'Carolina',
	'Capitão Poço': 'Pará',
	Capanema: 'Pará',
	Canindé: 'Ceará',
	Canguaretama: 'Rio Grande do Norte',
	'Campos Sales': 'Ceará',
	'Campo Maior': 'Piauí',
	'Campo Alegre': 'Alagoas',
	'Campina Grande': 'Paraíba',
	Camocim: 'Ceará',
	Cametá: 'Pará',
	Cajueiro: 'Alagoas',
	Cajazeiras: 'Paraíba',
	Caicó: 'Rio Grande do Norte',
	Cabrobó: 'Pernambuco',
	Cabo: 'Pernambuco',
	Cabedelo: 'Paraíba',
	'Buriti Bravo': 'Maranhão',
	Buíque: 'Pernambuco',
	Breves: 'Pará',
	'Brejo Santo': 'Ceará',
	'Brejo da Madre de Deus': 'Pernambuco',
	Bragança: 'Bragança',
	'Bom Conselho': 'Pernambuco',
	'Boa Viagem': 'Ceará',
	Bezerros: 'Pernambuco',
	Benevides: 'Pará',
	'Belo Jardim': 'Pernambuco',
	Belém: 'Pará',
	Beberibe: 'Ceará',
	Bayeux: 'Normandy',
	Baturité: 'Ceará',
	Barreiros: 'Pernambuco',
	Barreirinhas: 'Maranhão',
	Barras: 'Piauí',
	'Barra do Corda': 'Maranhão',
	Barcarena: 'Pará',
	Barbalha: 'Ceará',
	Balsas: 'Maranhão',
	Bacabal: 'Maranhão',
	'Augusto Corrêa': 'Pará',
	Atalaia: 'Alagoas',
	'Areia Branca': 'Rio Grande do Norte',
	Arcoverde: 'Pernambuco',
	Araripina: 'Pernambuco',
	Arari: 'Maranhão',
	Arapiraca: 'Alagoas',
	Araguaína: 'Tocantins',
	Aracati: 'Ceará',
	Aquiraz: 'Ceará',
	Apodi: 'Rio Grande do Norte',
	Ananindeua: 'Pará',
	Amaraji: 'Pernambuco',
	Altos: 'Piauí',
	Altamira: 'Tamaulipas',
	Almeirim: 'Pará',
	Alenquer: 'Pará',
	'Alagoa Grande': 'Paraíba',
	'Águas Belas': 'Pernambuco',
	'Água Preta': 'Pernambuco',
	'Afogados da Ingazeira': 'Pernambuco',
	Açu: 'Rio Grande do Norte',
	Acopiara: 'Ceará',
	Acaraú: 'Ceará',
	'Abreu e Lima': 'Pernambuco',
	Abaetetuba: 'Pará',
	'Xique Xique': 'Bahia',
	Xanxerê: 'Santa Catarina',
	Votuporanga: 'São Paulo',
	Votorantim: 'São Paulo',
	'Volta Redonda': 'Rio de Janeiro',
	'Vitória da Conquista': 'Bahia',
	Vitória: 'Espírito Santo',
	'Visconde do Rio Branco': 'Minas Gerais',
	Viradouro: 'São Paulo',
	Vinhedo: 'São Paulo',
	'Vila Velha': 'Espírito Santo',
	Videira: 'Santa Catarina',
	Viamão: 'Rio Grande do Sul',
	Vespasiano: 'Minas Gerais',
	Veranópolis: 'Rio Grande do Sul',
	'Vera Cruz': 'Bahia',
	'Wenceslau Braz': 'Paraná',
	'Venâncio Aires': 'Rio Grande do Sul',
	Vazante: 'Minas Gerais',
	Vassouras: 'Rio de Janeiro',
	'Várzea Paulista': 'São Paulo',
	'Várzea Grande': 'Mato Grosso',
	'Várzea da Palma': 'Minas Gerais',
	Varginha: 'Minas Gerais',
	'Vargem Grande do Sul': 'São Paulo',
	Valparaíso: 'Valparaíso',
	Valinhos: 'São Paulo',
	Valença: 'Bahia',
	Vacaria: 'Rio Grande do Sul',
	Uruguaiana: 'Rio Grande do Sul',
	Uruçuca: 'Bahia',
	Uruaçu: 'Goiás',
	'União da Vitória': 'Paraná',
	Unaí: 'Minas Gerais',
	Una: 'Gujarat',
	Umuarama: 'Paraná',
	Uberlândia: 'Minas Gerais',
	Uberaba: 'Minas Gerais',
	Ubatuba: 'São Paulo',
	Ubatã: 'Bahia',
	Ubaitaba: 'Bahia',
	Ubá: 'Minas Gerais',
	Tupanciretã: 'Rio Grande do Sul',
	Tupaciguara: 'Minas Gerais',
	Tupã: 'São Paulo',
	Tucano: 'Bahia',
	Tubarão: 'Santa Catarina',
	'Três Rios': 'Rio de Janeiro',
	'Três Pontas': 'Minas Gerais',
	'Três Passos': 'Rio Grande do Sul',
	'Três Lagoas': 'Mato Grosso do Sul',
	'Três de Maio': 'Rio Grande do Sul',
	'Três Coroas': 'Rio Grande do Sul',
	'Três Corações': 'Minas Gerais',
	Tremembé: 'São Paulo',
	Tramandaí: 'Rio Grande do Sul',
	Torres: 'Rio Grande do Sul',
	Toledo: 'Ohio',
	'Tobias Barreto': 'Sergipe',
	Timóteo: 'Minas Gerais',
	Timbó: 'Santa Catarina',
	Tijucas: 'Santa Catarina',
	Tietê: 'São Paulo',
	Teutônia: 'Rio Grande do Sul',
	Teresópolis: 'Rio de Janeiro',
	'Teófilo Otoni': 'Minas Gerais',
	'Teodoro Sampaio': 'São Paulo',
	'Telêmaco Borba': 'Paraná',
	Taubaté: 'São Paulo',
	Tatuí: 'São Paulo',
	Taquarituba: 'São Paulo',
	Taquaritinga: 'São Paulo',
	Taquari: 'Rio Grande do Sul',
	Taquara: 'Rio Grande do Sul',
	Tapiramutá: 'Bahia',
	Tapes: 'Rio Grande do Sul',
	Tanguá: 'Rio de Janeiro',
	Tanabi: 'São Paulo',
	Tambaú: 'São Paulo',
	Taiobeiras: 'Minas Gerais',
	'Taboão da Serra': 'São Paulo',
	Suzano: 'São Paulo',
	Sumaré: 'São Paulo',
	Sorocaba: 'São Paulo',
	Soledade: 'Rio Grande do Sul',
	Socorro: 'Texas',
	Sobradinho: 'Bahia',
	'Simão Dias': 'Sergipe',
	'Silva Jardim': 'Rio de Janeiro',
	Sidrolândia: 'Mato Grosso do Sul',
	'Sete Lagoas': 'Minas Gerais',
	Sertãozinho: 'São Paulo',
	Serrinha: 'Bahia',
	'Serra Negra': 'São Paulo',
	Serrana: 'São Paulo',
	Serra: 'Espírito Santo',
	Seropédica: 'Rio de Janeiro',
	'Senhor do Bonfim': 'Bahia',
	'Senador Canedo': 'Goiás',
	Seabra: 'Bahia',
	Schroeder: 'Santa Catarina',
	Saubara: 'Bahia',
	Sarzedo: 'Minas Gerais',
	Sarandi: 'Paraná',
	Saquarema: 'Rio de Janeiro',
	Sapucaia: 'Rio Grande do Sul',
	Sapiranga: 'Rio Grande do Sul',
	'São Vicente': 'São Paulo',
	'São Sepé': 'Rio Grande do Sul',
	'São Sebastião do Passé': 'Bahia',
	'São Sebastião do Paraíso': 'Minas Gerais',
	'São Sebastião do Caí': 'Rio Grande do Sul',
	'São Sebastião': 'São Paulo',
	'São Roque': 'São Paulo',
	'São Pedro da Aldeia': 'Rio de Janeiro',
	'São Pedro': 'Rio de Janeiro',
	'São Paulo': 'São Paulo',
	'São Miguel do Iguaçu': 'Paraná',
	'São Miguel do Araguaia': 'Goiás',
	'São Mateus do Sul': 'Paraná',
	'São Mateus': 'Espírito Santo',
	'São Marcos': 'Rio Grande do Sul',
	'São Manuel': 'São Paulo',
	'São Luiz Gonzaga': 'Rio Grande do Sul',
	'São Luís de Montes Belos': 'Goiás',
	'São Lourenço do Sul': 'Rio Grande do Sul',
	'São Lourenço': 'Minas Gerais',
	'São Leopoldo': 'Rio Grande do Sul',
	'São José dos Pinhais': 'Paraná',
	'São José dos Campos': 'São Paulo',
	'São José do Rio Preto': 'São Paulo',
	'São José do Rio Pardo': 'São Paulo',
	'São José': 'Santa Catarina',
	'São Joaquim da Barra': 'São Paulo',
	'São Joaquim': 'Santa Catarina',
	'São João Nepomuceno': 'Minas Gerais',
	'São João de Meriti': 'Rio de Janeiro',
	'São João del Rei': 'Minas Gerais',
	'São João da Boa Vista': 'São Paulo',
	'São João da Barra': 'Rio de Janeiro',
	'São Jerônimo': 'Rio Grande do Sul',
	'São Gotardo': 'Minas Gerais',
	'São Gonçalo do Sapucaí': 'Minas Gerais',
	'São Gabriel': 'Rio Grande do Sul',
	'São Francisco do Sul': 'Santa Catarina',
	'São Francisco do Conde': 'Bahia',
	'São Francisco': 'Minas Gerais',
	'São Fidélis': 'Rio de Janeiro',
	'São Cristóvão': 'Sergipe',
	'São Carlos': 'São Paulo',
	'São Caetano do Sul': 'São Paulo',
	'São Borja': 'Rio Grande do Sul',
	'São Bernardo do Campo': 'São Paulo',
	'São Bento do Sul': 'Santa Catarina',
	'Santos Dumont': 'Minas Gerais',
	Santos: 'São Paulo',
	'Santo Estêvão': 'Bahia',
	'Santo Antônio do Monte': 'Minas Gerais',
	'Santo Antônio do Amparo': 'Minas Gerais',
	'Santo Antônio de Posse': 'São Paulo',
	'Santo Antônio de Pádua': 'Rio de Janeiro',
	'Santo Antônio de Jesus': 'Bahia',
	'Santo Antônio da Platina': 'Paraná',
	'Santo Ângelo': 'Rio Grande do Sul',
	'Santo André': 'São Paulo',
	'Santo Anastácio': 'São Paulo',
	'Santo Amaro da Imperatriz': 'Santa Catarina',
	'Santo Amaro': 'Bahia',
	Santiago: 'Cagayan Valley',
	'Santa Vitória do Palmar': 'Rio Grande do Sul',
	'Santa Rosa de Viterbo': 'São Paulo',
	'Santa Rita do Sapucaí': 'Minas Gerais',
	'Santa Rita do Passa Quatro': 'São Paulo',
	'Santana do Paraíso': 'Minas Gerais',
	'Santana do Livramento': 'Rio Grande do Sul',
	'Santana de Parnaíba': 'São Paulo',
	'Santa Maria da Vitória': 'Bahia',
	'Santa Maria': 'California',
	Santaluz: 'Bahia',
	'Santa Isabel': 'Baja California',
	'Santa Helena de Goiás': 'Goiás',
	'Santa Gertrudes': 'São Paulo',
	'Santa Fé do Sul': 'São Paulo',
	'Santa Cruz do Sul': 'Rio Grande do Sul',
	'Santa Cruz do Rio Pardo': 'São Paulo',
	'Santa Cruz das Palmeiras': 'São Paulo',
	'Santa Cruz Cabrália': 'Bahia',
	'Santa Cecília': 'Santa Catarina',
	"Santa Bárbara d'Oeste": 'São Paulo',
	Salvador: 'Bahia',
	'Salto de Pirapora': 'São Paulo',
	Salto: 'Salto Department',
	Salinas: 'California',
	Sacramento: 'California',
	'Ruy Barbosa': 'Bahia',
	Rubiataba: 'Goiás',
	'Rosário do Sul': 'Rio Grande do Sul',
	Rondonópolis: 'Mato Grosso',
	Rolante: 'Rio Grande do Sul',
	Rolândia: 'Paraná',
	'Rio Verde de Mato Grosso': 'Mato Grosso do Sul',
	'Rio Verde': 'Goiás',
	'Rio Real': 'Bahia',
	'Rio Pardo': 'Rio Grande do Sul',
	'Rio Negro': 'Paraná',
	'Rio Negrinho': 'Santa Catarina',
	'Rio Grande da Serra': 'São Paulo',
	'Rio Grande': 'Rio Grande do Sul',
	'Rio do Sul': 'Santa Catarina',
	'Rio de Janeiro': 'Rio de Janeiro',
	'Rio das Pedras': 'São Paulo',
	'Rio das Ostras': 'Rio de Janeiro',
	'Rio Claro': 'Mayaro',
	'Rio Brilhante': 'Mato Grosso do Sul',
	'Rio Branco do Sul': 'Paraná',
	'Rio Bonito': 'Rio de Janeiro',
	'Ribeirão Preto': 'São Paulo',
	'Ribeirão Pires': 'São Paulo',
	'Ribeirão das Neves': 'Minas Gerais',
	'Ribeirão da Ilha': 'Santa Catarina',
	'Ribeira do Pombal': 'Bahia',
	'Riachão do Jacuípe': 'Bahia',
	Resplendor: 'Minas Gerais',
	Resende: 'Rio de Janeiro',
	Registro: 'São Paulo',
	'Regente Feijó': 'São Paulo',
	Rancharia: 'São Paulo',
	Quirinópolis: 'Goiás',
	Queimados: 'Rio de Janeiro',
	'Quatro Barras': 'Paraná',
	Quaraí: 'Rio Grande do Sul',
	Prudentópolis: 'Paraná',
	Propriá: 'Sergipe',
	Promissão: 'São Paulo',
	'Presidente Venceslau': 'São Paulo',
	'Presidente Prudente': 'São Paulo',
	'Presidente Epitácio': 'São Paulo',
	Prata: 'Minas Gerais',
	'Praia Grande': 'São Paulo',
	Prado: 'Bahia',
	'Pouso Alegre': 'Minas Gerais',
	Posse: 'Goiás',
	'Porto União': 'Santa Catarina',
	'Porto Seguro': 'Bahia',
	'Porto Ferreira': 'São Paulo',
	'Porto Feliz': 'São Paulo',
	'Porto Alegre': 'Rio Grande do Sul',
	Portão: 'Rio Grande do Sul',
	Porangatu: 'Goiás',
	'Pontes e Lacerda': 'Mato Grosso',
	'Ponte Nova': 'Minas Gerais',
	'Ponta Porã': 'Mato Grosso do Sul',
	Pontal: 'São Paulo',
	'Ponta Grossa': 'Paraná',
	Pompéu: 'Minas Gerais',
	Pompéia: 'São Paulo',
	Pomerode: 'Santa Catarina',
	'Poços de Caldas': 'Minas Gerais',
	Poconé: 'Mato Grosso',
	Poções: 'Bahia',
	Poá: 'São Paulo',
	Planaltina: 'Federal District',
	Piúma: 'Espírito Santo',
	Piuí: 'Minas Gerais',
	Pitangui: 'Minas Gerais',
	Pitangueiras: 'São Paulo',
	Pitanga: 'Paraná',
	Piritiba: 'Bahia',
	'Pires do Rio': 'Goiás',
	Piraquara: 'Paraná',
	Pirapozinho: 'São Paulo',
	Pirapora: 'Minas Gerais',
	Pirajuí: 'São Paulo',
	Piraju: 'São Paulo',
	'Piraí do Sul': 'Paraná',
	Piraí: 'Rio de Janeiro',
	Pirassununga: 'São Paulo',
	Piracicaba: 'São Paulo',
	Piracanjuba: 'Goiás',
	Piracaia: 'São Paulo',
	Pinheiral: 'Rio de Janeiro',
	Pinhão: 'Paraná',
	'Espírito Santo do Pinhal': 'São Paulo',
	Pindobaçu: 'Bahia',
	Pindamonhangaba: 'São Paulo',
	'Pilar do Sul': 'São Paulo',
	Piedade: 'Setúbal',
	Petrópolis: 'Rio de Janeiro',
	Peruíbe: 'São Paulo',
	'Pereira Barreto': 'São Paulo',
	Perdões: 'Minas Gerais',
	Penha: 'Santa Catarina',
	Penedo: 'Alagoas',
	Penápolis: 'São Paulo',
	Pelotas: 'Rio Grande do Sul',
	'Pedro Leopoldo': 'Minas Gerais',
	Pedreira: 'São Paulo',
	'Pedra Azul': 'Minas Gerais',
	Pederneiras: 'São Paulo',
	Paulínia: 'São Paulo',
	Patrocínio: 'Minas Gerais',
	'Patos de Minas': 'Minas Gerais',
	'Pato Branco': 'Paraná',
	'Paty do Alferes': 'Rio de Janeiro',
	'Posto da Mata': 'Bahia',
	Passos: 'Minas Gerais',
	'Passo Fundo': 'Rio Grande do Sul',
	Parobé: 'Rio Grande do Sul',
	Paraty: 'Rio de Janeiro',
	Paranavaí: 'Paraná',
	Paranapanema: 'São Paulo',
	Paranaíba: 'Mato Grosso do Sul',
	Paranaguá: 'Paraná',
	'Paraíba do Sul': 'Rio de Janeiro',
	'Paraguaçu Paulista': 'São Paulo',
	Paraguaçu: 'Minas Gerais',
	'Pará de Minas': 'Minas Gerais',
	Paracatu: 'Minas Gerais',
	Paracambi: 'Rio de Janeiro',
	Panambi: 'Rio Grande do Sul',
	Palotina: 'Paraná',
	Palmital: 'São Paulo',
	'Palmeira das Missões': 'Rio Grande do Sul',
	Palmeira: 'Paraná',
	Palmas: 'Tocantins',
	Palhoça: 'Santa Catarina',
	Paiçandu: 'Paraná',
	'Padre Bernardo': 'Goiás',
	'Ouro Preto': 'Minas Gerais',
	'Ouro Branco': 'Minas Gerais',
	Ourinhos: 'São Paulo',
	'Osvaldo Cruz': 'São Paulo',
	Osório: 'Rio Grande do Sul',
	Osasco: 'São Paulo',
	Orleans: 'Santa Catarina',
	Orlândia: 'São Paulo',
	Oliveira: 'Minas Gerais',
	Olímpia: 'São Paulo',
	'Novo Horizonte': 'São Paulo',
	'Novo Hamburgo': 'Rio Grande do Sul',
	'Nova Viçosa': 'Bahia',
	'Nova Venécia': 'Espírito Santo',
	'Nova Prata': 'Rio Grande do Sul',
	'Nova Petrópolis': 'Rio Grande do Sul',
	'Nova Olímpia': 'Mato Grosso',
	'Nova Odessa': 'São Paulo',
	'Nova Lima': 'Minas Gerais',
	'Nova Iguaçu': 'Rio de Janeiro',
	'Nova Granada': 'São Paulo',
	'Nova Friburgo': 'Rio de Janeiro',
	'Nova Era': 'Minas Gerais',
	'Nossa Senhora do Socorro': 'Sergipe',
	'Nossa Senhora da Glória': 'Sergipe',
	Niterói: 'Rio de Janeiro',
	Niquelândia: 'Goiás',
	Nilópolis: 'Rio de Janeiro',
	Nerópolis: 'Goiás',
	Nepomuceno: 'Minas Gerais',
	Nazaré: 'Bahia',
	Naviraí: 'Mato Grosso do Sul',
	Navegantes: 'Santa Catarina',
	Nanuque: 'Minas Gerais',
	Muzambinho: 'Minas Gerais',
	Muritiba: 'Bahia',
	Muriaé: 'Minas Gerais',
	Mucuri: 'Bahia',
	'Morro do Chapéu': 'Bahia',
	'Morro Agudo': 'São Paulo',
	Morrinhos: 'Goiás',
	'Montes Claros': 'Minas Gerais',
	'Monte Santo de Minas': 'Minas Gerais',
	Montenegro: 'Quindío',
	'Monte Mor': 'São Paulo',
	'Monte Carmelo': 'Minas Gerais',
	'Monte Azul Paulista': 'São Paulo',
	'Monte Aprazível': 'São Paulo',
	'Monte Alto': 'São Paulo',
	Mongaguá: 'São Paulo',
	'Mogi Mirim': 'São Paulo',
	'Mogi Guaçu': 'São Paulo',
	'Mogi das Cruzes': 'São Paulo',
	Mococa: 'São Paulo',
	Mirandopólis: 'São Paulo',
	Miracema: 'Rio de Janeiro',
	Miracatu: 'São Paulo',
	Mineiros: 'Goiás',
	'Miguel Pereira': 'Rio de Janeiro',
	Miguelópolis: 'São Paulo',
	Mendes: 'Rio de Janeiro',
	Medianeira: 'Paraná',
	'Medeiros Neto': 'Bahia',
	Mauá: 'São Paulo',
	Matozinhos: 'Minas Gerais',
	'Mateus Leme': 'Minas Gerais',
	Matão: 'São Paulo',
	'Mata de São João': 'Bahia',
	Mascote: 'Bahia',
	Martinópolis: 'São Paulo',
	Maringá: 'Paraná',
	Marília: 'São Paulo',
	Maricá: 'Rio de Janeiro',
	Mariana: 'Minas Gerais',
	Marialva: 'Paraná',
	'Marechal Cândido Rondon': 'Paraná',
	Marau: 'Rio Grande do Sul',
	Marataizes: 'Espírito Santo',
	Maragogipe: 'Bahia',
	Maracás: 'Bahia',
	Maracaju: 'Mato Grosso do Sul',
	Manhumirim: 'Minas Gerais',
	Manhuaçu: 'Minas Gerais',
	Mangaratiba: 'Rio de Janeiro',
	Mandaguari: 'Paraná',
	Mairiporã: 'São Paulo',
	Mairinque: 'São Paulo',
	Mafra: 'Santa Catarina',
	Machado: 'Minas Gerais',
	Macatuba: 'São Paulo',
	Macaé: 'Rio de Janeiro',
	Luziânia: 'Goiás',
	Lucas: 'Mato Grosso',
	Louveira: 'São Paulo',
	Lorena: 'São Paulo',
	Londrina: 'Paraná',
	Loanda: 'Paraná',
	'Livramento do Brumado': 'Bahia',
	Lins: 'São Paulo',
	Linhares: 'Espírito Santo',
	Limeira: 'São Paulo',
	Leopoldina: 'Minas Gerais',
	'Lençóis Paulista': 'São Paulo',
	Leme: 'São Paulo',
	Lavras: 'Minas Gerais',
	'Laranjeiras do Sul': 'Paraná',
	Laranjeiras: 'Sergipe',
	'Laranjal Paulista': 'São Paulo',
	Lapa: 'Paraná',
	Lajinha: 'Minas Gerais',
	Lages: 'Santa Catarina',
	Lajeado: 'Rio Grande do Sul',
	Laguna: 'California',
	'Lagoa Vermelha': 'Rio Grande do Sul',
	'Lagoa Santa': 'Minas Gerais',
	'Lagoa da Prata': 'Minas Gerais',
	Lagarto: 'Sergipe',
	Ladário: 'Mato Grosso do Sul',
	Jundiaí: 'São Paulo',
	'Júlio de Castilhos': 'Rio Grande do Sul',
	'Juiz de Fora': 'Minas Gerais',
	Juatuba: 'Minas Gerais',
	'José Bonifácio': 'São Paulo',
	Joinville: 'Santa Catarina',
	'João Pinheiro': 'Minas Gerais',
	'João Monlevade': 'Minas Gerais',
	Joaçaba: 'Santa Catarina',
	Jeremoabo: 'Bahia',
	Jequitinhonha: 'Minas Gerais',
	Jequié: 'Bahia',
	Jaú: 'São Paulo',
	Jataí: 'Goiás',
	Jarinu: 'São Paulo',
	Jardinópolis: 'São Paulo',
	Jardim: 'Mato Grosso do Sul',
	'Jaraguá do Sul': 'Santa Catarina',
	Jaraguá: 'Goiás',
	Japeri: 'Rio de Janeiro',
	Januária: 'Minas Gerais',
	Jandira: 'São Paulo',
	'Jandaia do Sul': 'Paraná',
	Janaúba: 'Minas Gerais',
	Jales: 'São Paulo',
	Jaguariúna: 'São Paulo',
	Jaguariaíva: 'Paraná',
	Jaguarari: 'Bahia',
	Jaguarão: 'Rio Grande do Sul',
	Jaguaquara: 'Bahia',
	Jacutinga: 'Minas Gerais',
	Jacobina: 'Bahia',
	Jaciara: 'Mato Grosso',
	Jacarezinho: 'Paraná',
	Jacareí: 'São Paulo',
	Jaboticabal: 'São Paulo',
	Ivoti: 'Rio Grande do Sul',
	Ituverava: 'São Paulo',
	Iturama: 'Minas Gerais',
	Itupeva: 'São Paulo',
	Itumbiara: 'Goiás',
	Ituiutaba: 'Minas Gerais',
	Ituberá: 'Bahia',
	Itu: 'Akwa Ibom',
	Itororó: 'Bahia',
	Itaúna: 'Minas Gerais',
	Itatinga: 'São Paulo',
	Itatiba: 'São Paulo',
	Itararé: 'São Paulo',
	Itaqui: 'Rio Grande do Sul',
	Itaquaquecetuba: 'São Paulo',
	Itapuranga: 'Goiás',
	Itápolis: 'São Paulo',
	Itapira: 'São Paulo',
	Itapevi: 'São Paulo',
	Itapeva: 'São Paulo',
	Itapetininga: 'São Paulo',
	Itapetinga: 'Bahia',
	Itaperuna: 'Rio de Janeiro',
	Itaperuçu: 'Paraná',
	Itapemirim: 'Espírito Santo',
	Itapema: 'Santa Catarina',
	'Itapecerica da Serra': 'São Paulo',
	Itapecerica: 'Minas Gerais',
	Itaparica: 'Bahia',
	Itapaci: 'Goiás',
	Itaocara: 'Rio de Janeiro',
	Itanhaém: 'São Paulo',
	Itambé: 'Bahia',
	Itamarandiba: 'Minas Gerais',
	Itamaraju: 'Bahia',
	Itajuípe: 'Bahia',
	Itajubá: 'Minas Gerais',
	Itajaí: 'Santa Catarina',
	Itaí: 'São Paulo',
	Itaguaí: 'Rio de Janeiro',
	Itabuna: 'Bahia',
	Itaboraí: 'Rio de Janeiro',
	Itabirito: 'Minas Gerais',
	Itabira: 'Minas Gerais',
	Itaberaí: 'Goiás',
	Itaberaba: 'Bahia',
	Itabaianinha: 'Sergipe',
	Irecê: 'Bahia',
	Irati: 'Paraná',
	Iracemápolis: 'São Paulo',
	Iporá: 'Goiás',
	Ipirá: 'Bahia',
	Ipiaú: 'Bahia',
	Iperó: 'São Paulo',
	Ipatinga: 'Minas Gerais',
	Ipameri: 'Goiás',
	Ipaba: 'Minas Gerais',
	Inhumas: 'Goiás',
	Indaiatuba: 'São Paulo',
	Indaial: 'Santa Catarina',
	Imbituva: 'Paraná',
	Imbituba: 'Santa Catarina',
	Ilhéus: 'Bahia',
	'Ilha Solteira': 'São Paulo',
	Ilhabela: 'São Paulo',
	Ijuí: 'Rio Grande do Sul',
	Iguape: 'São Paulo',
	Igrejinha: 'Rio Grande do Sul',
	Igarapé: 'Minas Gerais',
	Igarapava: 'São Paulo',
	'Igaraçu do Tietê': 'São Paulo',
	Içara: 'Santa Catarina',
	Ibotirama: 'Bahia',
	Ibiúna: 'São Paulo',
	Ibitinga: 'São Paulo',
	Ibirité: 'Minas Gerais',
	Ibirataia: 'Bahia',
	Ibirama: 'Santa Catarina',
	Ibiporã: 'Paraná',
	Ibicaraí: 'Bahia',
	Ibiá: 'Minas Gerais',
	Ibaté: 'São Paulo',
	Ibaiti: 'Paraná',
	Iaçu: 'Bahia',
	Hortolândia: 'São Paulo',
	Herval: 'Rio Grande do Sul',
	Gurupi: 'Tocantins',
	Guaxupé: 'Minas Gerais',
	Guarulhos: 'São Paulo',
	Guarujá: 'São Paulo',
	Guariba: 'São Paulo',
	Guaratuba: 'Paraná',
	Guaratinguetá: 'São Paulo',
	Guararema: 'São Paulo',
	Guararapes: 'São Paulo',
	Guarapuava: 'Paraná',
	Guarapari: 'Espírito Santo',
	Guaranésia: 'Minas Gerais',
	Guaramirim: 'Santa Catarina',
	Guará: 'São Paulo',
	Guaporé: 'Rio Grande do Sul',
	Guapimirim: 'Rio de Janeiro',
	Guanhães: 'Minas Gerais',
	Guanambi: 'Bahia',
	Guaíra: 'São Paulo',
	Guaçuí: 'Espírito Santo',
	Gravataí: 'Rio Grande do Sul',
	'Governador Valadares': 'Minas Gerais',
	Goiatuba: 'Goiás',
	Goiás: 'Goiás',
	Goianira: 'Goiás',
	Goiânia: 'Goiás',
	Goianésia: 'Goiás',
	Gaspar: 'Santa Catarina',
	Garibaldi: 'Rio Grande do Sul',
	Garça: 'São Paulo',
	Gandu: 'Bahia',
	Frutal: 'Minas Gerais',
	'Frederico Westphalen': 'Rio Grande do Sul',
	'Franco da Rocha': 'São Paulo',
	'Francisco Morato': 'São Paulo',
	'Francisco Beltrão': 'Paraná',
	Franca: 'São Paulo',
	'Foz do Iguaçu': 'Paraná',
	Forquilhinha: 'Santa Catarina',
	Formiga: 'Minas Gerais',
	Florianópolis: 'Santa Catarina',
	'Flores da Cunha': 'Rio Grande do Sul',
	'Ferraz de Vasconcelos': 'São Paulo',
	Fernandópolis: 'São Paulo',
	'Feira de Santana': 'Bahia',
	Farroupilha: 'Rio Grande do Sul',
	Eunápolis: 'Bahia',
	'Euclides da Cunha': 'Bahia',
	Estrela: 'Rio Grande do Sul',
	Esteio: 'Rio Grande do Sul',
	'Estância Velha': 'Rio Grande do Sul',
	Estância: 'Sergipe',
	Esplanada: 'Bahia',
	Espinosa: 'Minas Gerais',
	Esmeraldas: 'Esmeraldas',
	Erechim: 'Rio Grande do Sul',
	'Entre Rios': 'Bahia',
	'Encruzilhada do Sul': 'Rio Grande do Sul',
	Encantado: 'Rio Grande do Sul',
	'Embu Guaçu': 'São Paulo',
	Embu: 'Embu',
	'Elói Mendes': 'Minas Gerais',
	'Duque de Caxias': 'Rio de Janeiro',
	Dourados: 'Mato Grosso do Sul',
	'Dom Pedrito': 'Rio Grande do Sul',
	'Dois Vizinhos': 'Paraná',
	'Dois Córregos': 'São Paulo',
	Divinópolis: 'Minas Gerais',
	Diamantino: 'Mato Grosso',
	Diamantina: 'Minas Gerais',
	Diadema: 'São Paulo',
	Descalvado: 'São Paulo',
	Curvelo: 'Minas Gerais',
	Curitibanos: 'Santa Catarina',
	Curitiba: 'Paraná',
	Cuiabá: 'Mato Grosso',
	Cubatão: 'São Paulo',
	'Cruzeiro do Oeste': 'Paraná',
	Cruzeiro: 'São Paulo',
	'Cruz das Almas': 'Bahia',
	'Cruz Alta': 'Rio Grande do Sul',
	Cristalina: 'Goiás',
	Criciúma: 'Santa Catarina',
	Cravinhos: 'São Paulo',
	Coxim: 'Mato Grosso do Sul',
	Cotia: 'São Paulo',
	Cosmópolis: 'São Paulo',
	Coruripe: 'Alagoas',
	Corumbá: 'Mato Grosso do Sul',
	'Coronel Vivida': 'Paraná',
	'Coronel Fabriciano': 'Minas Gerais',
	Coromandel: 'Minas Gerais',
	'Cornélio Procópio': 'Paraná',
	Corinto: 'Chinandega',
	Cordeirópolis: 'São Paulo',
	Cordeiro: 'Rio de Janeiro',
	Contagem: 'Minas Gerais',
	'Conselheiro Lafaiete': 'Minas Gerais',
	Congonhas: 'Minas Gerais',
	Concórdia: 'Santa Catarina',
	Conchal: 'São Paulo',
	'Conceição do Coité': 'Bahia',
	'Conceição do Jacuípe': 'Bahia',
	'Conceição das Alagoas': 'Minas Gerais',
	'Conceição da Feira': 'Bahia',
	'Conceição da Barra': 'Espírito Santo',
	Colorado: 'Paraná',
	Colombo: 'Western',
	Colatina: 'Espírito Santo',
	Coaraci: 'Bahia',
	Cláudio: 'Minas Gerais',
	'Cícero Dantas': 'Bahia',
	Cianorte: 'Paraná',
	'Nova Xavantina': 'Mato Grosso',
	Charqueadas: 'Rio Grande do Sul',
	Chapecó: 'Santa Catarina',
	Cerquilho: 'São Paulo',
	Ceres: 'Western Cape',
	'Celso Ramos': 'Santa Catarina',
	'Caxias do Sul': 'Rio Grande do Sul',
	Caxambu: 'Minas Gerais',
	Catu: 'Bahia',
	Catanduva: 'São Paulo',
	Catalão: 'Goiás',
	Cataguases: 'Minas Gerais',
	Castro: 'Los Lagos Region',
	Castelo: 'Espírito Santo',
	Cassilândia: 'Mato Grosso do Sul',
	'Casimiro de Abreu': 'Rio de Janeiro',
	'Casa Branca': 'São Paulo',
	'Carmo do Paranaíba': 'Minas Gerais',
	'Carmo do Cajuru': 'Minas Gerais',
	'Carlos Barbosa': 'Rio Grande do Sul',
	Carazinho: 'Rio Grande do Sul',
	Caratinga: 'Minas Gerais',
	Carapicuíba: 'São Paulo',
	Carangola: 'Minas Gerais',
	Carandaí: 'Minas Gerais',
	Caraguatatuba: 'São Paulo',
	Capivari: 'São Paulo',
	Capinzal: 'Santa Catarina',
	'Capim Grosso': 'Bahia',
	Capelinha: 'Minas Gerais',
	Capela: 'Sergipe',
	'Capão da Canoa': 'Rio Grande do Sul',
	'Capâo Bonito': 'São Paulo',
	Canoinhas: 'Santa Catarina',
	Canoas: 'Rio Grande do Sul',
	Canguçu: 'Rio Grande do Sul',
	Canela: 'Rio Grande do Sul',
	'Cândido Mota': 'São Paulo',
	Candelária: 'Rio Grande do Sul',
	Canavieiras: 'Bahia',
	'Campo Verde': 'Mato Grosso do Sul',
	'Campos Novos': 'Santa Catarina',
	'Campos Gerais': 'Minas Gerais',
	'Campos do Jordão': 'São Paulo',
	'Campos Belos': 'Goiás',
	'Campos dos Goytacazes': 'Rio de Janeiro',
	'Campo Mourão': 'Paraná',
	'Campo Largo': 'Paraná',
	'Campo Grande': 'Mato Grosso do Sul',
	'Campo Formoso': 'Bahia',
	'Campo Belo': 'Minas Gerais',
	Campinas: 'Santa Catarina',
	'Campina Grande do Sul': 'Paraná',
	Cambuí: 'Minas Gerais',
	Cambé: 'Paraná',
	Cambará: 'Paraná',
	'Amaral Ferrador': 'Rio Grande do Sul',
	Camanducaia: 'Minas Gerais',
	Camaçari: 'Bahia',
	'Caldas Novas': 'Goiás',
	Cajuru: 'São Paulo',
	Cajati: 'São Paulo',
	Cajamar: 'São Paulo',
	Caieiras: 'São Paulo',
	Caetité: 'Bahia',
	Caeté: 'Minas Gerais',
	'Cachoeiro de Itapemirim': 'Espírito Santo',
	Cachoeirinha: 'Rio Grande do Sul',
	'Cachoeiras de Macacu': 'Rio de Janeiro',
	'Cachoeira do Sul': 'Rio Grande do Sul',
	Cachoeira: 'Bahia',
	'Caçapava do Sul': 'Rio Grande do Sul',
	Caçapava: 'São Paulo',
	Caçador: 'Santa Catarina',
	Cabreúva: 'São Paulo',
	'Cabo Frio': 'Rio de Janeiro',
	Butiá: 'Rio Grande do Sul',
	Buritizeiro: 'Minas Gerais',
	Buritis: 'Minas Gerais',
	Buri: 'São Paulo',
	Buerarema: 'Bahia',
	Brusque: 'Santa Catarina',
	Brumado: 'Bahia',
	Brumadinho: 'Minas Gerais',
	Brotas: 'São Paulo',
	Brodósqui: 'São Paulo',
	Brasília: 'Federal District',
	'Bragança Paulista': 'São Paulo',
	'Braço do Norte': 'Santa Catarina',
	Botucatu: 'São Paulo',
	'Bom Jesus do Itabapoana': 'Rio de Janeiro',
	'Bom Jesus da Lapa': 'Bahia',
	'Bom Despacho': 'Minas Gerais',
	Boituva: 'São Paulo',
	Bocaiúva: 'Minas Gerais',
	'Boa Esperança': 'Minas Gerais',
	Blumenau: 'Santa Catarina',
	'Biritiba Mirim': 'São Paulo',
	Birigui: 'São Paulo',
	Biguaçu: 'Santa Catarina',
	Betim: 'Minas Gerais',
	Bertioga: 'São Paulo',
	'Bento Gonçalves': 'Rio Grande do Sul',
	'Belo Oriente': 'Minas Gerais',
	'Belo Horizonte': 'Minas Gerais',
	'Belford Roxo': 'Rio de Janeiro',
	'Bela Vista': 'Mato Grosso do Sul',
	Bebedouro: 'São Paulo',
	Bauru: 'São Paulo',
	Batatais: 'São Paulo',
	Bastos: 'São Paulo',
	Barueri: 'São Paulo',
	Barroso: 'Minas Gerais',
	Barrinha: 'São Paulo',
	Barretos: 'São Paulo',
	'Barreiro do Jaíba': 'Minas Gerais',
	Barreiras: 'Bahia',
	'Barra Velha': 'Santa Catarina',
	'Barra Mansa': 'Rio de Janeiro',
	'Barra dos Coqueiros': 'Sergipe',
	'Barra do Piraí': 'Rio de Janeiro',
	'Barra do Garças': 'Mato Grosso',
	'Barra do Bugres': 'Mato Grosso',
	'Barra de São Francisco': 'Espírito Santo',
	'Barra Bonita': 'São Paulo',
	Barra: 'Campania',
	Bariri: 'São Paulo',
	Barbacena: 'Minas Gerais',
	'Barão de Cocais': 'Minas Gerais',
	Bandeirantes: 'Paraná',
	Bambuí: 'Minas Gerais',
	'Balneário Camboriú': 'Santa Catarina',
	'Baixo Guandu': 'Espírito Santo',
	Bagé: 'Rio Grande do Sul',
	Avaré: 'São Paulo',
	Atibaia: 'São Paulo',
	Astorga: 'Paraná',
	Assis: 'São Paulo',
	Arujá: 'São Paulo',
	'Artur Nogueira': 'São Paulo',
	'Arroio Grande': 'Rio Grande do Sul',
	'Arroio do Meio': 'Rio Grande do Sul',
	'Arraial do Cabo': 'Rio de Janeiro',
	'Armação de Búzios': 'Rio de Janeiro',
	Arcos: 'Minas Gerais',
	Araxá: 'Minas Gerais',
	Araucária: 'Paraná',
	Araruama: 'Rio de Janeiro',
	Araras: 'São Paulo',
	Araraquara: 'São Paulo',
	Araranguá: 'Santa Catarina',
	Arapongas: 'Paraná',
	Araguari: 'Minas Gerais',
	Aragarças: 'Goiás',
	Araçuaí: 'Minas Gerais',
	Aracruz: 'Espírito Santo',
	'Araçoiaba da Serra': 'São Paulo',
	Araci: 'Bahia',
	Araçatuba: 'São Paulo',
	Aracaju: 'Sergipe',
	Aquidauana: 'Mato Grosso do Sul',
	Apucarana: 'Paraná',
	Apiaí: 'São Paulo',
	'Aparecida do Taboado': 'Mato Grosso do Sul',
	Aparecida: 'São Paulo',
	Antonina: 'Paraná',
	Anicuns: 'Goiás',
	'Angra dos Reis': 'Rio de Janeiro',
	Andradina: 'São Paulo',
	Andradas: 'Minas Gerais',
	Anastácio: 'Mato Grosso do Sul',
	Anápolis: 'Goiás',
	Amparo: 'São Paulo',
	'Américo Brasiliense': 'São Paulo',
	Americana: 'São Paulo',
	Amargosa: 'Bahia',
	'Álvares Machado': 'São Paulo',
	'Almirante Tamandaré': 'Paraná',
	Almenara: 'Minas Gerais',
	Alfenas: 'Minas Gerais',
	'Além Paraíba': 'Minas Gerais',
	Alegrete: 'Rio Grande do Sul',
	Alegre: 'Espírito Santo',
	Alagoinhas: 'Bahia',
	Aimorés: 'Minas Gerais',
	Agudos: 'São Paulo',
	'Águas Vermelhas': 'Minas Gerais',
	'Águas de Lindóia': 'São Paulo',
	Aguaí: 'São Paulo',
	Adamantina: 'São Paulo',
	Abaeté: 'Minas Gerais',
	Guaíba: 'Rio Grande do Sul',
	Tefé: 'Amazonas',
	Tarauacá: 'Acre',
	Tabatinga: 'Amazonas',
	'Sena Madureira': 'Acre',
	'São Gabriel da Cachoeira': 'Amazonas',
	'Rio Branco': 'Acre',
	'Porto Velho': 'Rondônia',
	Manaus: 'Amazonas',
	Manacapuru: 'Amazonas',
	Humaitá: 'Amazonas',
	'Fonte Boa': 'Amazonas',
	Eirunepé: 'Amazonas',
	'Cruzeiro do Sul': 'Acre',
	Coari: 'Amazonas',
	Carauari: 'Amazonas',
	'Boa Vista': 'Roraima',
	Ariquemes: 'Rondônia',
	Aripuanã: 'Mato Grosso',
	Vilhena: 'Rondônia',
	'Senador Guiomard': 'Acre',
	'Pôsto Fiscal Rolim de Moura': 'Rondônia',
	'Pimenta Bueno': 'Rondônia',
	'Ouro Preto do Oeste': 'Rondônia',
	'Ji Paraná': 'Rondônia',
	Jaru: 'Rondônia',
	'Guajará Mirim': 'Rondônia',
	Cacoal: 'Rondônia',
	'Aparecida de Goiânia': 'Goiás',
	'Jaboatão dos Guararapes': 'Pernambuco',
	'Lauro de Freitas': 'Bahia',
	Minaçu: 'Goiás',
	'Ourilândia do Norte': 'Pará',
	Parauapebas: 'Pará',
	Pinhais: 'Paraná',
	Redenção: 'Pará',
	'Rio Preto da Eva': 'Amazonas',
	'Simões Filho': 'Bahia',
	Sinop: 'Sinop',
	Xinguara: 'Pará',
	Cambebba: 'Ceará',
	'Jardim Paulista': 'São Paulo',
	'Freguesia do Ribeirao da Ilha': 'Santa Catarina',
	Alvorada: 'Rio Grande do Sul',
	Nassau: 'New Providence',
	Lucaya: 'Freeport',
	Freeport: 'New York',
	Thimphu: 'Thimphu District',
	Punākha: 'Punakha',
	Phuntsholing: 'Chukha',
	Tsirang: 'Tsirang District',
	Tonota: 'Central',
	Thamaga: 'Kweneng',
	Serowe: 'Central',
	'Selebi-Phikwe': 'Selibe Phikwe',
	Ramotswa: 'South-East',
	Palapye: 'Central',
	Mosopa: 'Ngwaketsi',
	Molepolole: 'Kweneng',
	Mogoditshane: 'Kweneng',
	Mochudi: 'Kgatleng',
	Maun: 'North-West',
	Mahalapye: 'Central',
	Lobatse: 'Lobatse',
	Letlhakane: 'Central',
	Kanye: 'Ngwaketsi',
	Janeng: 'South-East',
	Gaborone: 'Gaborone',
	Francistown: 'City of Francistown',
	'Horad Zhodzina': 'Minsk',
	Zhlobin: 'Gomel Oblast',
	Vitebsk: 'Vitebsk',
	Vilyeyka: 'Minsk',
	Volkovysk: 'Grodnenskaya',
	Svyetlahorsk: 'Gomel Oblast',
	Stowbtsy: 'Minsk',
	Smarhoń: 'Grodnenskaya',
	Smaliavičy: 'Minsk',
	Slutsk: 'Minsk',
	Slonim: 'Grodnenskaya',
	Shchuchyn: 'Grodnenskaya',
	Salihorsk: 'Minsk',
	Rahachow: 'Gomel Oblast',
	Rechytsa: 'Gomel Oblast',
	Pruzhany: 'Brest',
	Polatsk: 'Vitebsk',
	Pinsk: 'Brest',
	Pastavy: 'Vitebsk',
	Asipovichy: 'Mogilev',
	Orsha: 'Vitebsk',
	Navapolatsk: 'Vitebsk',
	Novogrudok: 'Grodnenskaya',
	Minsk: 'Minsk City',
	Mazyr: 'Gomel Oblast',
	Mosty: 'Grodnenskaya',
	'Mar’’ina Horka': 'Minsk',
	Maladziečna: 'Minsk',
	Mahilyow: 'Mogilev',
	'Lyepyel’': 'Vitebsk',
	Luninyets: 'Brest',
	Lida: 'Grodnenskaya',
	Krychaw: 'Mogilev',
	Kalodzishchy: 'Minsk',
	Kobryn: 'Brest',
	Kalinkavichy: 'Gomel Oblast',
	Ivatsevichy: 'Brest',
	Hrodna: 'Grodnenskaya',
	Horki: 'Mogilev',
	"Homyel'": 'Gomel Oblast',
	Hlybokaye: 'Vitebsk',
	Dzyarzhynsk: 'Minsk',
	Dobrush: 'Gomel Oblast',
	Bykhaw: 'Mogilev',
	Byaroza: 'Brest',
	Brest: 'Brittany',
	Barysaw: 'Minsk',
	Baranovichi: 'Brest',
	Babruysk: 'Mogilev',
	'San Ignacio': 'Cayo',
	'Orange Walk': 'Orange Walk District',
	Belmopan: 'Cayo',
	'Belize City': 'Belize',
	Abbotsford: 'British Columbia',
	'Agincourt North': 'Ontario',
	Airdrie: 'Scotland',
	Ajax: 'Ontario',
	Alliston: 'Ontario',
	Alma: 'Quebec',
	Amos: 'Quebec',
	Aurora: 'Colorado',
	'Baie-Comeau': 'Quebec',
	Barrie: 'Ontario',
	'Bayview Village': 'Ontario',
	Beaconsfield: 'Quebec',
	Belleville: 'New Jersey',
	Beloeil: 'Quebec',
	Bendale: 'Ontario',
	Blainville: 'Quebec',
	Boisbriand: 'Quebec',
	Boucherville: 'Quebec',
	Bracebridge: 'Ontario',
	Brampton: 'Ontario',
	Brandon: 'Mississippi',
	Brant: 'Ontario',
	Brantford: 'Ontario',
	Brockville: 'Ontario',
	Brossard: 'Quebec',
	Burlington: 'Vermont',
	Burnaby: 'British Columbia',
	Caledon: 'Ontario',
	Calgary: 'Alberta',
	Cambridge: 'Massachusetts',
	'Campbell River': 'British Columbia',
	Camrose: 'Alberta',
	Candiac: 'Quebec',
	Chambly: 'Quebec',
	Charlottetown: 'Prince Edward Island',
	Châteauguay: 'Quebec',
	Chatham: 'Illinois',
	Chilliwack: 'British Columbia',
	'Clarence-Rockland': 'Ontario',
	Cliffcrest: 'Ontario',
	Cobourg: 'Ontario',
	Cochrane: 'Alberta',
	Collingwood: 'Ontario',
	Colwood: 'British Columbia',
	'Conception Bay South': 'Newfoundland and Labrador',
	Coquitlam: 'British Columbia',
	'Corner Brook': 'Newfoundland and Labrador',
	Cornwall: 'Ontario',
	'Côte-Saint-Luc': 'Quebec',
	Courtenay: 'British Columbia',
	Cranbrook: 'British Columbia',
	Dartmouth: 'Nova Scotia',
	Delta: 'British Columbia',
	'Deux-Montagnes': 'Quebec',
	Dieppe: 'Normandy',
	'Dollard-Des Ormeaux': 'Quebec',
	'Dorset Park': 'Ontario',
	Dorval: 'Quebec',
	Drummondville: 'Quebec',
	Duncan: 'Oklahoma',
	'East Gwillimbury': 'Ontario',
	Edmonton: 'England',
	Humbermede: 'Ontario',
	Esquimalt: 'British Columbia',
	'Flemingdon Park': 'Ontario',
	'Fort Erie': 'Ontario',
	'Fort McMurray': 'Alberta',
	'Fort St. John': 'British Columbia',
	Fredericton: 'New Brunswick',
	Gatineau: 'Quebec',
	'Glace Bay': 'Nova Scotia',
	Granby: 'Quebec',
	'Grande Prairie': 'Alberta',
	'Greater Sudbury': 'Ontario',
	'Greater Napanee': 'Ontario',
	Grimsby: 'England',
	Guelph: 'Ontario',
	'Henry Farm': 'Ontario',
	'Hillcrest Village': 'Ontario',
	Huntsville: 'Texas',
	Joliette: 'Quebec',
	Kamloops: 'British Columbia',
	Kanata: 'Ontario',
	Kelowna: 'British Columbia',
	'Kennedy Park': 'Ontario',
	Kenora: 'Ontario',
	Keswick: 'Ontario',
	Kingston: 'New York',
	Kirkland: 'Washington',
	Kitchener: 'Ontario',
	Langford: 'British Columbia',
	Langley: 'British Columbia',
	'La Prairie': 'Quebec',
	"L'Assomption": 'Quebec',
	Laval: 'Pays de la Loire',
	Leamington: 'Ontario',
	Leduc: 'Alberta',
	'Les Coteaux': 'Quebec',
	Lethbridge: 'Alberta',
	Lindsay: 'Ontario',
	Lloydminster: 'Alberta',
	London: 'England',
	Longueuil: 'Quebec',
	'Lutes Mountain': 'New Brunswick',
	Magog: 'Quebec',
	Malvern: 'Ontario',
	'Maple Ridge': 'British Columbia',
	Markham: 'Ontario',
	Mascouche: 'Quebec',
	'Medicine Hat': 'Alberta',
	Midland: 'Texas',
	Milliken: 'Ontario',
	Milton: 'Georgia',
	Mimico: 'Ontario',
	Mirabel: 'Quebec',
	Miramichi: 'New Brunswick',
	Mission: 'Texas',
	Mississauga: 'Ontario',
	Moncton: 'New Brunswick',
	Montréal: 'Quebec',
	'Mont-Royal': 'Quebec',
	'Mont-Saint-Hilaire': 'Quebec',
	'Moose Jaw': 'Saskatchewan',
	'Mount Pearl': 'Newfoundland and Labrador',
	Nanaimo: 'British Columbia',
	Nepean: 'Ontario',
	'New Glasgow': 'Nova Scotia',
	Newmarket: 'England',
	'New Westminster': 'British Columbia',
	'Niagara Falls': 'New York',
	'Niagara-on-the-Lake': 'Ontario',
	'Norfolk County': 'Ontario',
	'North Battleford': 'Saskatchewan',
	'North Bay': 'Ontario',
	'North Cowichan': 'British Columbia',
	'North Vancouver': 'British Columbia',
	'Notre-Dame-de-Grâce': 'Quebec',
	'Oak Bay': 'British Columbia',
	Oakville: 'Missouri',
	'Oakwood Village': 'Ontario',
	Orangeville: 'Ontario',
	Orillia: 'Ontario',
	Oshawa: 'Ontario',
	Ottawa: 'Illinois',
	'Owen Sound': 'Ontario',
	Penticton: 'British Columbia',
	Petawawa: 'Ontario',
	Peterborough: 'England',
	Pickering: 'Ontario',
	'Pitt Meadows': 'British Columbia',
	'Pointe-Claire': 'Quebec',
	'Port Alberni': 'British Columbia',
	'Port Colborne': 'Ontario',
	'Port Coquitlam': 'British Columbia',
	'Port Moody': 'British Columbia',
	'Prince Albert': 'Saskatchewan',
	'Prince Edward': 'Ontario',
	'Prince George': 'British Columbia',
	'Quinte West': 'Ontario',
	'Red Deer': 'Alberta',
	Regina: 'Saskatchewan',
	Repentigny: 'Quebec',
	'Richmond Hill': 'New York',
	'Rock Forest': 'Quebec',
	Rouge: 'Ontario',
	'Rouyn-Noranda': 'Quebec',
	Saguenay: 'Quebec',
	'Saint-Basile-le-Grand': 'Quebec',
	'Saint-Bruno-de-Montarville': 'Quebec',
	'Saint-Constant': 'Quebec',
	'Sainte-Catherine': 'Quebec',
	'Sainte-Julie': 'Quebec',
	'Sainte-Thérèse': 'Quebec',
	'Saint-Eustache': 'Quebec',
	'Saint-Hyacinthe': 'Quebec',
	'Saint-Jean-sur-Richelieu': 'Quebec',
	'Saint-Jérôme': 'Quebec',
	'Saint John': 'New Brunswick',
	'Saint-Laurent': 'Quebec',
	'Saint-Lazare': 'Quebec',
	'Saint-Léonard': 'Quebec',
	'Saint-Lin-Laurentides': 'Quebec',
	'Salaberry-de-Valleyfield': 'Quebec',
	Sarnia: 'Ontario',
	Saskatoon: 'Saskatchewan',
	'Sault Ste. Marie': 'Ontario',
	'Scarborough Village': 'Ontario',
	'Sept-Îles': 'Quebec',
	Shawinigan: 'Quebec',
	Sherbrooke: 'Quebec',
	'Sherwood Park': 'Alberta',
	'Sorel-Tracy': 'Quebec',
	'Spruce Grove': 'Alberta',
	Squamish: 'British Columbia',
	'St. Albert': 'Alberta',
	'St. Catharines': 'Ontario',
	Steeles: 'Ontario',
	Steinbach: 'Manitoba',
	Stratford: 'Connecticut',
	'St. Thomas': 'Ontario',
	Surrey: 'British Columbia',
	'Swift Current': 'Saskatchewan',
	"Tam O'Shanter-Sullivan": 'Ontario',
	Tecumseh: 'Ontario',
	Terrace: 'British Columbia',
	Terrebonne: 'Quebec',
	'The Beaches': 'Ontario',
	Thorold: 'Ontario',
	'Thunder Bay': 'Ontario',
	Timmins: 'Ontario',
	Toronto: 'Ontario',
	'Trois-Rivières': 'Quebec',
	Tsawwassen: 'British Columbia',
	Uxbridge: 'England',
	"Val-d'Or": 'Quebec',
	Vancouver: 'Washington',
	Vanier: 'Ontario',
	Varennes: 'Quebec',
	'Vaudreuil-Dorion': 'Quebec',
	Vaughan: 'Ontario',
	Vernon: 'Normandy',
	Victoriaville: 'Quebec',
	'Wasaga Beach': 'Ontario',
	Welland: 'Ontario',
	'West End': 'British Columbia',
	'West Hill': 'Ontario',
	Westmount: 'Quebec',
	Weston: 'Wisconsin',
	Whitehorse: 'Yukon',
	'White Rock': 'British Columbia',
	Windsor: 'Colorado',
	Winnipeg: 'Manitoba',
	Woburn: 'Massachusetts',
	Woodstock: 'Illinois',
	Yellowknife: 'Northwest Territories',
	Yorkton: 'Saskatchewan',
	Halifax: 'England',
	"St. John's": 'Newfoundland and Labrador',
	Québec: 'Quebec',
	Lévis: 'Quebec',
	Rimouski: 'Quebec',
	'Rivière-du-Loup': 'Quebec',
	"L'Ancienne-Lorette": 'Quebec',
	Edmundston: 'New Brunswick',
	'Saint-Georges': 'Quebec',
	Buckingham: 'Quebec',
	'Thetford-Mines': 'Quebec',
	'Cole Harbour': 'Nova Scotia',
	Okanagan: 'British Columbia',
	'West Kelowna': 'British Columbia',
	Jonquière: 'Quebec',
	'Saint-Augustin-de-Desmaures': 'Quebec',
	Ladner: 'British Columbia',
	'Walnut Grove': 'British Columbia',
	'Moss Park': 'Ontario',
	'High Park North': 'Ontario',
	'Little Portugal': 'Ontario',
	Niagara: 'Ontario',
	'Trinity-Bellwoods': 'Ontario',
	Morningside: 'Ontario',
	'Eglinton East': 'Ontario',
	'Pleasant View': 'Ontario',
	Fergus: 'Ontario',
	Ancaster: 'Ontario',
	'West Vancouver': 'British Columbia',
	Willowdale: 'Ontario',
	'Lower Sackville': 'Nova Scotia',
	'La Haute-Saint-Charles': 'Quebec',
	Fallingbrook: 'Ontario',
	'Valley East': 'Ontario',
	'York University Heights': 'Ontario',
	'Parkwoods-Donalda': 'Ontario',
	'South Parkdale': 'Ontario',
	'South Riverdale': 'Ontario',
	'North St.James Town': 'Ontario',
	'Waterfront Communities-The Island': 'Ontario',
	'West Humber-Clairville': 'Ontario',
	'Banbury-Don Mills': 'Ontario',
	'Birchcliffe-Cliffside': 'Ontario',
	'Islington-City Centre West': 'Ontario',
	'Mount Pleasant West': 'Ontario',
	'Eringate-Centennial-West Deane': 'Ontario',
	'Dovercourt-Wallace Emerson-Junction': 'Ontario',
	'Edenbridge-Humber Valley': 'Ontario',
	'Newtonbrook East': 'Ontario',
	'Newtonbrook West': 'Ontario',
	"L'Amoreaux": 'Ontario',
	'Taylor-Massey': 'Ontario',
	'Lansing-Westgate': 'Ontario',
	'Lawrence Park South': 'Ontario',
	'Leaside-Bennington': 'Ontario',
	'Mount Olive-Silverstone-Jamestown': 'Ontario',
	'Mount Pleasant East': 'Ontario',
	'Willowdale West': 'Ontario',
	'Black Creek': 'Ontario',
	'Rockcliffe-Smythe': 'Ontario',
	'Rosedale-Moore Park': 'Ontario',
	'Stonegate-Queensway': 'Ontario',
	'Danforth East York': 'Ontario',
	'St.Andrew-Windfields': 'Ontario',
	Annex: 'Ontario',
	'Thorncliffe Park': 'Ontario',
	'Victoria Village': 'Ontario',
	'Wexford/Maryvale': 'Ontario',
	'Westminster-Branson': 'Ontario',
	'Willowdale East': 'Ontario',
	'Willowridge-Martingrove-Richview': 'Ontario',
	'Church-Yonge Corridor': 'Ontario',
	'Brookhaven-Amesbury': 'Ontario',
	"O'Connor-Parkview": 'Ontario',
	'Kensington-Chinatown': 'Ontario',
	'Bay Street Corridor': 'Ontario',
	'Agincourt South-Malvern West': 'Ontario',
	'East End-Danforth': 'Ontario',
	'Englemount-Lawrence': 'Ontario',
	'Bedford Park-Nortown': 'Ontario',
	'Bathurst Manor': 'Ontario',
	'Clanton Park': 'Ontario',
	'Clairlea-Birchmount': 'Ontario',
	'Don Valley Village': 'Ontario',
	'Downsview-Roding-CFB': 'Ontario',
	'Glenfield-Jane Heights': 'Ontario',
	'High Park-Swansea': 'Ontario',
	'Kingsview Village-The Westway': 'Ontario',
	'West Island': '',
	Yangambi: 'Tshopo',
	Watsa: 'Haut-Uele',
	Wamba: 'Nassarawa',
	Uvira: 'South Kivu',
	Tshikapa: 'Kasai',
	Sake: 'Nord Kivu',
	'Mwene-Ditu': 'Lomami',
	Mweka: 'Kasai',
	'Mbuji-Mayi': 'Kasaï-Oriental',
	Lusambo: 'Sankuru',
	Luebo: 'Kasai',
	Lubao: 'Central Luzon',
	Lodja: 'Sankuru',
	Lisala: 'Mongala',
	Kongolo: 'Tanganyika',
	Kisangani: 'Tshopo',
	Kindu: 'Maniema',
	Kasongo: 'Maniema',
	Kananga: 'Kasai-Central',
	Kampene: 'Maniema',
	Kamina: 'Haut-Lomami',
	Kalemie: 'Tanganyika',
	Kabinda: 'Lomami',
	Kabare: 'South Kivu',
	Kabalo: 'Tanganyika',
	Isiro: 'Haut-Uele',
	Ilebo: 'Kasai',
	Goma: 'Nord Kivu',
	Gbadolite: 'Nord-Ubangi',
	Gandajika: 'Lomami',
	Demba: 'Kasai-Central',
	Butembo: 'Nord Kivu',
	Buta: 'Bas-Uele',
	Businga: 'Nord-Ubangi',
	Bunia: 'Ituri',
	Bumba: 'Mongala',
	Bukavu: 'South Kivu',
	Bukama: 'Haut-Lomami',
	Bondo: 'Bas-Uele',
	Boende: 'Tshuapa',
	Beni: 'Nord Kivu',
	Basoko: 'Tshopo',
	Aketi: 'Bas-Uele',
	Lubumbashi: 'Haut-Katanga',
	Likasi: 'Haut-Katanga',
	Kolwezi: 'Lualaba',
	Kipushi: 'Haut-Katanga',
	Kambove: 'Haut-Katanga',
	Tshela: 'Bas-Congo',
	Nioki: 'Mai-Ndombe',
	Mushie: 'Mai-Ndombe',
	Moanda: 'Haut-Ogooué',
	'Mbanza-Ngungu': 'Bas-Congo',
	Mbandaka: 'Équateur',
	Matadi: 'Bas-Congo',
	Mangai: 'Kwilu',
	Libenge: 'Sud-Ubangi',
	Kinshasa: 'Kinshasa',
	Kikwit: 'Kwilu',
	'Kasongo-Lunda': 'Kwango',
	Kasangulu: 'Bas-Congo',
	Inongo: 'Mai-Ndombe',
	Gemena: 'Sud-Ubangi',
	Bulungu: 'Kwilu',
	Boma: 'Bas-Congo',
	Bolobo: 'Mai-Ndombe',
	Bandundu: 'Kwilu',
	Masina: 'Kinshasa',
	Mobaye: 'Basse-Kotto',
	Ippy: 'Ouaka',
	Bria: 'Haute-Kotto',
	Bangassou: 'Mbomou',
	Bambari: 'Ouaka',
	Sibut: 'Kémo',
	Paoua: 'Ouham-Pendé',
	Nola: 'Campania',
	Mbaïki: 'Lobaye',
	'Kaga Bandoro': 'Nana-Grébizi',
	Damara: "Ombella-M'Poko",
	Carnot: 'Mambéré-Kadéï',
	Bozoum: 'Ouham-Pendé',
	Bouar: 'Nana-Mambéré',
	Bossangoa: 'Ouham',
	Boda: 'Lobaye',
	Bimbo: "Ombella-M'Poko",
	Berbérati: 'Mambéré-Kadéï',
	Batangafo: 'Ouham',
	Bangui: 'Bangui',
	Sibiti: 'Lékoumou',
	'Pointe-Noire': 'Pointe-Noire',
	Owando: 'Cuvette',
	Ouésso: 'Sangha',
	Mossendjo: 'Niari',
	Madingou: 'Bouenza',
	Dolisie: 'Niari',
	Loandjili: 'Pointe-Noire',
	Kayes: 'Kayes',
	Impfondo: 'Likouala',
	Gamboma: 'Plateaux',
	Brazzaville: 'Brazzaville',
	Zürich: 'Zurich',
	Zug: 'Zug',
	'Yverdon-les-Bains': 'Vaud',
	Winterthur: 'Zurich',
	Wil: 'Saint Gallen',
	Wetzikon: 'Zurich',
	Wettingen: 'Aargau',
	Vevey: 'Vaud',
	Vernier: 'Geneva',
	Uster: 'Zurich',
	Thun: 'Bern',
	Steffisburg: 'Bern',
	Sitten: 'Valais',
	Sierre: 'Valais',
	'Zürich (Kreis 11) / Seebach': 'Zurich',
	Schaffhausen: 'Schaffhausen',
	'Sankt Gallen': 'Saint Gallen',
	Renens: 'Vaud',
	Rapperswil: 'Saint Gallen',
	Pully: 'Vaud',
	Onex: 'Geneva',
	Olten: 'Solothurn',
	'Zürich (Kreis 11) / Oerlikon': 'Zurich',
	Nyon: 'Vaud',
	Neuchâtel: 'Neuchâtel',
	Muttenz: 'Basel-Landschaft',
	Montreux: 'Vaud',
	Monthey: 'Valais',
	Meyrin: 'Geneva',
	Luzern: 'Lucerne',
	Lugano: 'Ticino',
	Littau: 'Lucerne',
	Lausanne: 'Vaud',
	'La Chaux-de-Fonds': 'Neuchâtel',
	Kriens: 'Lucerne',
	Kreuzlingen: 'Thurgau',
	Köniz: 'Bern',
	Kloten: 'Zurich',
	Jona: 'Saint Gallen',
	Horgen: 'Zurich',
	'Zürich (Kreis 10) / Höngg': 'Zurich',
	Herisau: 'Appenzell Ausserrhoden',
	Grenchen: 'Solothurn',
	Gossau: 'Saint Gallen',
	Genève: 'Geneva',
	Fribourg: 'Fribourg',
	Frauenfeld: 'Thurgau',
	Emmen: 'Drenthe',
	Dübendorf: 'Zurich',
	Dietikon: 'Zurich',
	Chur: 'Grisons',
	Cham: 'Bavaria',
	Carouge: 'Geneva',
	Burgdorf: 'Lower Saxony',
	Bulle: 'Fribourg',
	'Biel/Bienne': 'Bern',
	Bern: 'Bern',
	Bellinzona: 'Ticino',
	Basel: 'Basel-City',
	Baar: 'Zug',
	'Zürich (Kreis 4) / Aussersihl': 'Zurich',
	Allschwil: 'Basel-Landschaft',
	Adliswil: 'Zurich',
	Aarau: 'Aargau',
	Riehen: 'Basel-City',
	'Zürich (Kreis 10) / Wipkingen': 'Zurich',
	'Zürich (Kreis 11) / Affoltern': 'Zurich',
	'Zürich (Kreis 2) / Wollishofen': 'Zurich',
	'Zürich (Kreis 3) / Sihlfeld': 'Zurich',
	'Zürich (Kreis 6) / Unterstrass': 'Zurich',
	'Zürich (Kreis 9) / Albisrieden': 'Zurich',
	'Zürich (Kreis 9) / Altstetten': 'Zurich',
	'Stadt Winterthur (Kreis 1)': 'Zurich',
	'Zürich (Kreis 12)': 'Zurich',
	'Seen (Kreis 3)': 'Zurich',
	'Zürich (Kreis 3)': 'Zurich',
	'Zürich (Kreis 11)': 'Zurich',
	'Zürich (Kreis 9)': 'Zurich',
	'Oberwinterthur (Kreis 2)': 'Zurich',
	'Zürich (Kreis 10)': 'Zurich',
	'Zürich (Kreis 2)': 'Zurich',
	'Zürich (Kreis 8)': 'Zurich',
	'Zürich (Kreis 7)': 'Zurich',
	'Zürich (Kreis 6)': 'Zurich',
	Lancy: 'Geneva',
	Zuénoula: 'Sassandra-Marahoué',
	Yamoussoukro: 'Lacs',
	Vavoua: 'Sassandra-Marahoué',
	Toumodi: 'Lacs',
	Touba: 'Diourbel',
	Tengréla: 'Savanes',
	Tiassalé: 'Lagunes',
	Tanda: 'Zanzan',
	Tabou: 'Bas-Sassandra',
	Sinfra: 'Zanzan',
	Sassandra: 'Bas-Sassandra',
	'San-Pédro': 'Bas-Sassandra',
	Sakassou: 'Vallée du Bandama',
	Oumé: 'Gôh-Djiboua',
	Odienné: 'Denguélé',
	Mankono: 'Woroba',
	Man: 'Montagnes',
	Lakota: 'Gôh-Djiboua',
	Korhogo: 'Savanes',
	Katiola: 'Vallée du Bandama',
	Issia: 'Sassandra-Marahoué',
	Guiglo: 'Montagnes',
	Guibéroua: 'Gôh-Djiboua',
	'Grand-Bassam': 'Comoé',
	Affery: 'Lagunes',
	Gagnoa: 'Gôh-Djiboua',
	Ferkessédougou: 'Savanes',
	Duekoué: 'Montagnes',
	Doropo: 'Zanzan',
	Divo: 'Gôh-Djiboua',
	Dimbokro: 'Lacs',
	Daoukro: 'Lacs',
	Danané: 'Montagnes',
	Daloa: 'Sassandra-Marahoué',
	Dabou: 'Lagunes',
	Boundiali: 'Savanes',
	Bouna: 'Zanzan',
	Bouaké: 'Vallée du Bandama',
	Bouaflé: 'Sassandra-Marahoué',
	Bonoua: 'Comoé',
	Bongouanou: 'Lacs',
	Bondoukou: 'Zanzan',
	Bingerville: 'Abidjan',
	Biankouma: 'Montagnes',
	Béoumi: 'Vallée du Bandama',
	Bangolo: 'Montagnes',
	Arrah: 'Bihar',
	Anyama: 'Abidjan',
	Akoupé: 'Lagunes',
	Agnibilékrou: 'Comoé',
	Agboville: 'Lagunes',
	Adzopé: 'Lagunes',
	Adiaké: 'Comoé',
	Aboisso: 'Comoé',
	Abobo: 'Abidjan',
	Abidjan: 'Abidjan',
	Abengourou: 'Comoé',
	Marcory: 'Abidjan',
	Ahouanou: 'Lagunes',
	Séguéla: 'Woroba',
	Avarua: 'Rarotonga',
	'Viña del Mar': 'Valparaíso',
	Villarrica: 'Guairá',
	'Villa Alemana': 'Valparaíso',
	Vallenar: 'Atacama',
	Valdivia: 'Los Ríos Region',
	Tomé: 'Biobío',
	Tocopilla: 'Antofagasta',
	Temuco: 'Araucanía',
	Talcahuano: 'Biobío',
	Talca: 'Maule Region',
	Talagante: 'Santiago Metropolitan',
	'San Vicente de Tagua Tagua': "O'Higgins Region",
	'San Felipe': 'Yaracuy',
	'San Carlos': 'Cojedes',
	'San Bernardo': 'Santiago Metropolitan',
	'San Antonio': 'Texas',
	'Río Bueno': 'Los Ríos Region',
	Rengo: "O'Higgins Region",
	Rancagua: "O'Higgins Region",
	Quilpué: 'Valparaíso',
	Quillota: 'Valparaíso',
	'Punta Arenas': 'Region of Magallanes',
	'Puerto Varas': 'Los Lagos Region',
	Quellón: 'Los Lagos Region',
	'Puerto Natales': 'Region of Magallanes',
	'Puerto Montt': 'Los Lagos Region',
	'Puerto Aysén': 'Aysén',
	'Puente Alto': 'Santiago Metropolitan',
	Pucón: 'Araucanía',
	'Pozo Almonte': 'Tarapacá',
	Penco: 'Biobío',
	Peñaflor: 'Santiago Metropolitan',
	Parral: 'Chihuahua',
	Panguipulli: 'Los Ríos Region',
	Paine: 'Santiago Metropolitan',
	Ovalle: 'Coquimbo Region',
	Osorno: 'Los Lagos Region',
	'Nueva Imperial': 'Araucanía',
	Nacimiento: 'Biobío',
	Mulchén: 'Biobío',
	Molina: 'Maule Region',
	Melipilla: 'Santiago Metropolitan',
	Machalí: "O'Higgins Region",
	Lota: 'Biobío',
	'Los Ángeles': 'Biobío',
	'Los Andes': 'Valparaíso',
	Loncoche: 'Araucanía',
	Llaillay: 'Valparaíso',
	Linares: 'Nuevo León',
	Limache: 'Valparaíso',
	Lebu: 'Biobío',
	Lautaro: 'Araucanía',
	'La Unión': 'La Unión',
	'La Serena': 'Coquimbo Region',
	Lampa: 'Santiago Metropolitan',
	'La Ligua': 'Valparaíso',
	Iquique: 'Tarapacá',
	Illapel: 'Coquimbo Region',
	'Hacienda La Calera': 'Valparaíso',
	Graneros: "O'Higgins Region",
	'El Monte': 'California',
	'Diego de Almagro': 'Atacama',
	Curicó: 'Maule Region',
	Curanilahue: 'Biobío',
	Coronel: 'Biobío',
	Coquimbo: 'Coquimbo Region',
	Copiapó: 'Atacama',
	Constitución: 'Maule Region',
	Concepción: 'Zulia',
	Collipulli: 'Araucanía',
	Coyhaique: 'Aysén',
	Chimbarongo: "O'Higgins Region",
	Chillán: 'Ñuble',
	Chiguayante: 'Biobío',
	Chicureo: 'Santiago Metropolitan',
	Cavancha: 'Tarapacá',
	Cauquenes: 'Maule Region',
	Cartagena: 'Murcia',
	Cañete: 'Biobío',
	Calama: 'Antofagasta',
	Cabrero: 'Biobío',
	Buin: 'Santiago Metropolitan',
	Arica: 'Arica y Parinacota',
	Arauco: 'Biobío',
	Antofagasta: 'Antofagasta',
	Angol: 'Araucanía',
	Ancud: 'Los Lagos Region',
	'Alto Hospicio': 'Tarapacá',
	'Las Animas': 'Los Ríos Region',
	'La Pintana': 'Santiago Metropolitan',
	'Lo Prado': 'Santiago Metropolitan',
	'Goméz Carreño': 'Tarapacá',
	'Playa Brava': 'Tarapacá',
	'La Tirana': 'Tarapacá',
	Caupolicán: 'Tarapacá',
	Yaoundé: 'Centre',
	Yagoua: 'Far North',
	Wum: 'North-West',
	Tonga: 'West',
	Tiko: 'South-West',
	Tibati: 'Adamaoua',
	Tcholliré: 'North',
	Sangmélima: 'South',
	Penja: 'Littoral',
	Obala: 'Centre',
	Nkoteng: 'Centre',
	Nkongsamba: 'Littoral',
	Ngaoundéré: 'Adamaoua',
	'Nanga Eboko': 'Centre',
	Muyuka: 'South-West',
	Mutengene: 'South-West',
	Mora: 'Far North',
	Mokolo: 'Far North',
	Melong: 'Littoral',
	Meïganga: 'Adamaoua',
	Mbouda: 'West',
	Mbanga: 'Littoral',
	Mbandjok: 'Centre',
	Mbalmayo: 'Centre',
	Maroua: 'Far North',
	Manjo: 'Littoral',
	Mamfe: 'South-West',
	Loum: 'Littoral',
	Lolodorf: 'South',
	Limbe: 'South-West',
	Lagdo: 'North',
	Kumbo: 'North-West',
	Kumba: 'South-West',
	Kribi: 'South',
	Kousséri: 'Far North',
	Kaélé: 'Far North',
	Guider: 'North',
	'Garoua Boulaï': 'East',
	Garoua: 'North',
	Fundong: 'North-West',
	Foumbot: 'West',
	Foumban: 'West',
	Fontem: 'South-West',
	Eséka: 'Centre',
	Edéa: 'Littoral',
	Ébolowa: 'South',
	Dschang: 'West',
	Douala: 'Littoral',
	Dizangué: 'Littoral',
	Buea: 'South-West',
	Bogo: 'Central Visayas',
	Bertoua: 'East',
	Bélabo: 'East',
	Batouri: 'East',
	Banyo: 'Adamaoua',
	Bangangté: 'West',
	Bamusso: 'South-West',
	Bamenda: 'North-West',
	Bali: 'North-West',
	Bafoussam: 'West',
	Bafia: 'Centre',
	Bafang: 'West',
	Akonolinga: 'Centre',
	Zhefang: 'Yunnan',
	Zhangfeng: 'Yunnan',
	Zepu: 'Xinjiang',
	Zibihu: 'Yunnan',
	Yingpan: 'Yunnan',
	Rikaze: 'Tibet',
	Tengyue: 'Yunnan',
	Jiuquan: 'Gansu',
	Shigu: 'Yunnan',
	Shangyun: 'Yunnan',
	Shangpa: 'Yunnan',
	Shache: 'Xinjiang',
	Ruidian: 'Yunnan',
	Qushi: 'Yunnan',
	Qiemo: 'Xinjiang',
	Qarek: 'Xinjiang',
	Qamdo: 'Tibet',
	Yingjiang: 'Yunnan',
	Oytograk: 'Xinjiang',
	Nagqu: 'Tibet',
	Qaraqash: 'Xinjiang',
	Mengmao: 'Yunnan',
	Menglang: 'Yunnan',
	Mangshi: 'Yunnan',
	Liuku: 'Yunnan',
	Lhasa: 'Tibet',
	Laojunmiao: 'Gansu',
	Bonan: 'Yunnan',
	Langru: 'Xinjiang',
	Kokyar: 'Xinjiang',
	Kashgar: 'Xinjiang',
	Kaqun: 'Xinjiang',
	Judian: 'Yunnan',
	Jinhua: 'Zhejiang',
	Jinding: 'Yunnan',
	Jietou: 'Yunnan',
	Jiayuguan: 'Gansu',
	Houqiao: 'Yunnan',
	Hotan: 'Xinjiang',
	Gudong: 'Yunnan',
	Elixku: 'Xinjiang',
	Dêqên: 'Tibet',
	Langgar: 'Xinjiang',
	Baoshan: 'Heilongjiang',
	Layka: 'Xinjiang',
	Saybag: 'Xinjiang',
	Arix: 'Xinjiang',
	Xambabazar: 'Xinjiang',
	Xinyuan: 'Xinjiang',
	Wusu: 'Xinjiang',
	Ürümqi: 'Xinjiang',
	Turpan: 'Xinjiang',
	Tacheng: 'Xinjiang',
	Shihezi: 'Xinjiang',
	Sandaohezi: 'Xinjiang',
	Qapqal: 'Xinjiang',
	Kuqa: 'Xinjiang',
	Korla: 'Xinjiang',
	Karamay: 'Xinjiang',
	Jelilyüzi: 'Xinjiang',
	Yili: 'Xinjiang',
	Hoxtolgay: 'Xinjiang',
	Hami: 'Xinjiang',
	Fuyun: 'Xinjiang',
	Emin: 'Xinjiang',
	Dushanzi: 'Xinjiang',
	Dure: 'Xinjiang',
	Dunhuang: 'Gansu',
	Changji: 'Xinjiang',
	Burqin: 'Xinjiang',
	Baijiantan: 'Xinjiang',
	Aral: 'Qyzylorda',
	Altay: 'East Kazakhstan',
	Aksu: 'South Kazakhstan',
	Oymak: 'Xinjiang',
	Kax: 'Xinjiang',
	Baytokay: 'Xinjiang',
	Yengitam: 'Xinjiang',
	Dadamtu: 'Xinjiang',
	Yuqunweng: 'Xinjiang',
	Panjim: 'Xinjiang',
	Liangji: 'Jiangsu',
	Lanshan: 'Jiangsu',
	'Qing’an': 'Jiangsu',
	Malingshan: 'Jiangsu',
	Chenlou: 'Jiangsu',
	Hegou: 'Jiangsu',
	Gangshang: 'Jiangsu',
	Zouzhuang: 'Jiangsu',
	Tangzhang: 'Jiangsu',
	Hanwang: 'Gansu',
	Yitang: 'Jiangsu',
	Xuzhuang: 'Jiangsu',
	Nianzhuang: 'Jiangsu',
	Tashan: 'Jiangsu',
	Zizhuang: 'Jiangsu',
	Liuji: 'Jiangsu',
	Jiangzhuang: 'Shandong',
	Daizhuang: 'Jiangsu',
	Zhangzhuang: 'Jiangsu',
	Xinglou: 'Jiangsu',
	Huzhai: 'Jiangsu',
	Heqiao: 'Jiangsu',
	Fanlou: 'Jiangsu',
	Hekou: 'Jiangsu',
	Songlou: 'Jiangsu',
	Sunlou: 'Jiangsu',
	Zhuzhai: 'Jiangsu',
	Zhaozhuang: 'Jiangsu',
	Anguo: 'Jiangsu',
	Shizhai: 'Jiangsu',
	Yaxing: 'Hainan',
	'Lintan Chengguanzhen': 'Gansu',
	Zunyi: 'Guizhou',
	Zuitai: 'Gansu',
	Zoucheng: 'Shandong',
	Nima: 'Gansu',
	'Ziyang Chengguanzhen': 'Shaanxi',
	Ziyang: 'Sichuan',
	Zitong: 'Chongqing',
	Zishui: 'Chongqing',
	Zigong: 'Sichuan',
	Zhuzhou: 'Hunan',
	Zhuyuan: 'Chongqing',
	Zhuyang: 'Chongqing',
	Zhuxi: 'Chongqing',
	Zhutuo: 'Chongqing',
	Zhuoshui: 'Chongqing',
	Zhumadian: 'Henan',
	Zhujiajiao: 'Shanghai',
	Shangqiu: 'Henan',
	Zhuji: 'Zhejiang',
	'Zhouqu Chengguanzhen': 'Gansu',
	Zhucheng: 'Shandong',
	Zhuanghe: 'Liaoning',
	Zhouzhuang: 'Jiangsu',
	Zhouxi: 'Guizhou',
	Zhoukou: 'Henan',
	Zhoujia: 'Chongqing',
	Zhoucun: 'Shandong',
	Yuxi: 'Chongqing',
	Zhongzhai: 'Gansu',
	Zhongxing: 'Jiangsu',
	Zhongxin: 'Yunnan',
	Zhongshu: 'Yunnan',
	Zhonghe: 'Yunnan',
	Zhongduo: 'Chongqing',
	Gusong: 'Sichuan',
	Zhongchao: 'Guizhou',
	'Zhong’ao': 'Chongqing',
	Zhigou: 'Shandong',
	Zhicheng: 'Hubei',
	Zhenzhou: 'Jiangsu',
	Zhenxi: 'Chongqing',
	'Zhenping Chengguanzhen': 'Henan',
	Zhenjiang: 'Jiangsu',
	Zhaobaoshan: 'Zhejiang',
	Zhengzhou: 'Henan',
	Zhengxing: 'Chongqing',
	Zhengji: 'Jiangsu',
	Zhengding: 'Hebei',
	Zhelou: 'Guizhou',
	Xinghua: 'Jiangsu',
	Zhaotong: 'Yunnan',
	Zhaoqing: 'Guangdong',
	Zhaogezhuang: 'Hebei',
	Zhaodun: 'Jiangsu',
	Zhaoyuan: 'Heilongjiang',
	Zhanjiang: 'Guangdong',
	Zhanhe: 'Yunnan',
	Zhangzhou: 'Fujian',
	Zhangzhai: 'Jiangsu',
	Zhangye: 'Gansu',
	Zhangjiachuan: 'Gansu',
	Zhangji: 'Jiangsu',
	Zibo: 'Shandong',
	Anyang: 'Henan',
	Zhancheng: 'Jiangsu',
	Zhabei: 'Shanghai',
	Zaozhuang: 'Shandong',
	Zaoyang: 'Hubei',
	Yuyao: 'Zhejiang',
	Yingchuan: 'Henan',
	Yuxia: 'Shaanxi',
	Yushan: 'Chongqing',
	Kunshan: 'Jiangsu',
	Yunyang: 'Henan',
	Yuntai: 'Chongqing',
	Yunshan: 'Shandong',
	Yunnanyi: 'Yunnan',
	'Yunmeng Chengguanzhen': 'Hubei',
	Yunmen: 'Chongqing',
	Jinghong: 'Yunnan',
	Yunjin: 'Sichuan',
	Pizhou: 'Jiangsu',
	Yunfu: 'Guangdong',
	Yuncheng: 'Shanxi',
	Yulinshi: 'Shaanxi',
	Yulin: 'Guangxi',
	Wulingyuan: 'Hunan',
	Yujia: 'Chongqing',
	Yuexi: 'Chongqing',
	Yudong: 'Chongqing',
	Yuci: 'Shanxi',
	Yucheng: 'Shandong',
	Yuanyang: 'Gansu',
	Huaiyang: 'Hebei',
	Yuanshang: 'Shandong',
	Yuanping: 'Shanxi',
	Qianjiang: 'Chongqing',
	Heyuan: 'Guangdong',
	Youxi: 'Chongqing',
	Youting: 'Chongqing',
	Youchou: 'Chongqing',
	Yongzhou: 'Hunan',
	Yongxing: 'Chongqing',
	Yongxin: 'Chongqing',
	Yongqing: 'Gansu',
	Yongning: 'Guangxi',
	Yongjia: 'Chongqing',
	Yongfeng: 'Hunan',
	Yongchuan: 'Chongqing',
	Yongbei: 'Yunnan',
	'Yong’an': 'Chongqing',
	Yizhuang: 'Jiangsu',
	Yishui: 'Shandong',
	Yinma: 'Shandong',
	Zhongxiang: 'Hubei',
	Yingtan: 'Jiangxi',
	'Yingshang Chengguanzhen': 'Anhui',
	Chengzhong: 'Hubei',
	Yinchuan: 'Ningxia Hui Autonomous Region',
	Yima: 'Henan',
	Yigou: 'Henan',
	Qingzhou: 'Shandong',
	Yichun: 'Heilongjiang',
	Yicheng: 'Hubei',
	Yixing: 'Jiangsu',
	Yichang: 'Hubei',
	Yibin: 'Sichuan',
	Yezhou: 'Hubei',
	Yeyuan: 'Shandong',
	Yatou: 'Shandong',
	Yashan: 'Guangxi',
	Yaowan: 'Jiangsu',
	Yaoji: 'Jiangsu',
	Yanzibu: 'Jiangsu',
	Yanzhou: 'Shandong',
	Yantai: 'Chongqing',
	Yanliang: 'Shaanxi',
	Yanjing: 'Chongqing',
	Yanjia: 'Chongqing',
	Yangzhou: 'Shaanxi',
	Yanguan: 'Gansu',
	Yangtun: 'Jiangsu',
	Yangshuo: 'Guangxi',
	Zhangjiagang: 'Jiangsu',
	Yangquan: 'Shanxi',
	Yangliuqing: 'Tianjin',
	Yanghe: 'Shandong',
	Yanggu: 'Gangwon-do',
	Yangcun: 'Tianjin',
	Yancheng: 'Jiangsu',
	'Yan’an': 'Shaanxi',
	Yajiang: 'Chongqing',
	"Ya'an": 'Sichuan',
	Tongshan: 'Jiangsu',
	Xuyong: 'Sichuan',
	Xucheng: 'Guangdong',
	Shangrao: 'Jiangxi',
	Xunyang: 'Shaanxi',
	Xunsi: 'Sichuan',
	Xunchang: 'Sichuan',
	Xujiang: 'Jiangxi',
	Xuchang: 'Henan',
	Xuantan: 'Sichuan',
	Xuanzhou: 'Anhui',
	Xixiang: 'Shaanxi',
	Xiuying: 'Hainan',
	Xiulin: 'Hubei',
	Xiugu: 'Jiangxi',
	Xituo: 'Chongqing',
	Xiongzhou: 'Guangdong',
	Guixi: 'Chongqing',
	Xinzhou: 'Hainan',
	Xinzhi: 'Shanxi',
	Xinzhai: 'Shandong',
	Xinyu: 'Jiangxi',
	Hancheng: 'Shaanxi',
	Xinyang: 'Henan',
	Xinxing: 'Gansu',
	Xinxiang: 'Henan',
	Xintian: 'Chongqing',
	Xintai: 'Shandong',
	Xinshi: 'Chongqing',
	Xinmin: 'Chongqing',
	Xinmiao: 'Chongqing',
	Xinji: 'Hebei',
	Xining: 'Qinghai',
	Shangmei: 'Hunan',
	Xinhe: 'Jiangsu',
	Xingtai: 'Hebei',
	Xingren: 'Guizhou',
	Xinglong: 'Chongqing',
	Xingguo: 'Gansu',
	Ankang: 'Shaanxi',
	Wenfeng: 'Chongqing',
	Xindian: 'Jiangsu',
	Xindi: 'Hubei',
	Feicheng: 'Shandong',
	Xincheng: 'Henan',
	Xinyi: 'Guangdong',
	Sanshui: 'Guangdong',
	Ximeicun: 'Fujian',
	'Wacheng Neighborhood': 'Henan',
	Xihe: 'Hubei',
	Xigang: 'Ningxia Hui Autonomous Region',
	Xiema: 'Chongqing',
	Xichang: 'Guangxi',
	Xiazhuang: 'Yunnan',
	Xiazhen: 'Shandong',
	Xiasi: 'Sichuan',
	Haining: 'Zhejiang',
	Gongyi: 'Henan',
	Xiaoweizhai: 'Guizhou',
	Xiaoshan: 'Zhejiang',
	Xiaomian: 'Chongqing',
	Xiaolingwei: 'Jiangsu',
	Xiaogang: 'Jiangxi',
	Xiaogan: 'Hubei',
	Xiaochuan: 'Gansu',
	Xianyang: 'Shaanxi',
	Xiantao: 'Hubei',
	Xianshuigu: 'Tianjin',
	Xiannü: 'Jiangsu',
	Xianning: 'Hubei',
	Xianju: 'Zhejiang',
	Xiangzhou: 'Shandong',
	Zhuhai: 'Chongqing',
	Xiangcheng: 'Yunnan',
	Wenxing: 'Hunan',
	Xiangxiang: 'Hunan',
	Xiangtan: 'Hunan',
	Xiangshui: 'Chongqing',
	Xiangyang: 'Hubei',
	'Xiangcheng Chengguanzhen': 'Henan',
	Xianfeng: 'Chongqing',
	'Xi’an': 'Shaanxi',
	Xiamen: 'Fujian',
	Xiagezhuang: 'Shandong',
	Wuzhou: 'Guangxi',
	Wuzhen: 'Zhejiang',
	Wuyang: 'Anhui',
	Wuxue: 'Hubei',
	Wushan: 'Shandong',
	Wuxi: 'Hunan',
	Wutongshu: 'Ningxia Hui Autonomous Region',
	Wuqiao: 'Chongqing',
	Dongyang: 'Chongqing',
	Wuling: 'Chongqing',
	Changde: 'Hunan',
	Wujing: 'Shandong',
	Wujia: 'Guangxi',
	Wuhu: 'Anhui',
	Wuhan: 'Hubei',
	Wuhai: 'Inner Mongolia',
	Wugang: 'Hunan',
	Wudong: 'Chongqing',
	Wuda: 'Inner Mongolia',
	Wucheng: 'Anhui',
	Wenzhou: 'Zhejiang',
	'Wenxian Chengguanzhen': 'Gansu',
	Wenshang: 'Shandong',
	Wenquan: 'Gansu',
	Wenling: 'Zhejiang',
	Wenjiang: 'Sichuan',
	Wenheng: 'Fujian',
	Tianfu: 'Chongqing',
	Wenchang: 'Hainan',
	Weituo: 'Chongqing',
	Weinan: 'Shaanxi',
	Weimiao: 'Jiangsu',
	Weiji: 'Jiangsu',
	Weihai: 'Shandong',
	Weifang: 'Shandong',
	Wayaobu: 'Shaanxi',
	Wayao: 'Jiangsu',
	Wanxian: 'Chongqing',
	Wanshun: 'Chongqing',
	Wanshui: 'Guizhou',
	Wansheng: 'Chongqing',
	Wanning: 'Hainan',
	Wangwu: 'Hainan',
	Wangu: 'Chongqing',
	Wangpu: 'Gansu',
	Wangji: 'Jiangsu',
	Yinzhu: 'Shandong',
	Wangfen: 'Shandong',
	Waina: 'Gansu',
	Wafangdian: 'Liaoning',
	Tuxiang: 'Chongqing',
	Tushan: 'Jiangsu',
	Huangshan: 'Yunnan',
	Loushanguan: 'Guizhou',
	Tongzhou: 'Beijing',
	Tongxi: 'Chongqing',
	Fuding: 'Fujian',
	Tongren: 'Guizhou',
	Tongling: 'Anhui',
	Tongjing: 'Chongqing',
	Tongchuan: 'Shaanxi',
	Tieqiao: 'Chongqing',
	Tiefu: 'Jiangsu',
	Tiaoshi: 'Chongqing',
	Tianzhuang: 'Shandong',
	Tianshui: 'Gansu',
	Tianpeng: 'Sichuan',
	Tianliu: 'Shandong',
	Tianjin: 'Tianjin',
	Chengtangcun: 'Shandong',
	Taozhuang: 'Shandong',
	Taoyuan: 'Yunnan',
	Taoluo: 'Shandong',
	Taolin: 'Shandong',
	Tantou: 'Fujian',
	Tanjia: 'Chongqing',
	Tangzhai: 'Anhui',
	Tangwu: 'Shandong',
	Tangshan: 'Hebei',
	Tangping: 'Guangdong',
	Tangjiazhuang: 'Hebei',
	Binhe: 'Henan',
	Tanggu: 'Tianjin',
	Tangba: 'Chongqing',
	Taizhou: 'Zhejiang',
	Taiyuan: 'Shanxi',
	Taixing: 'Jiangsu',
	Taiping: 'Perak',
	Shanghe: 'Chongqing',
	Taihe: 'Chongqing',
	Taishan: 'Guangdong',
	Taicang: 'Jiangsu',
	'Tai’an': 'Chongqing',
	Suzhou: 'Jiangsu',
	Suyangshan: 'Jiangsu',
	Suqian: 'Jiangsu',
	Suozhen: 'Shandong',
	Suizhou: 'Hubei',
	Suixi: 'Anhui',
	Suicheng: 'Jiangsu',
	Suining: 'Sichuan',
	Songjiang: 'Shanghai',
	Songcheng: 'Fujian',
	Songbai: 'Hubei',
	Shangsi: 'Guangxi',
	Sitou: 'Shandong',
	Sishui: 'Shandong',
	Simen: 'Gansu',
	Simao: 'Yunnan',
	'Dachang Shandao': 'Liaoning',
	Sihu: 'Jiangsu',
	Shunhe: 'Jiangsu',
	Shuizhai: 'Guangdong',
	Shuitu: 'Chongqing',
	Shuikou: 'Guangdong',
	Shuijiang: 'Chongqing',
	Laixi: 'Shandong',
	Shuangshi: 'Chongqing',
	Shuanglong: 'Sichuan',
	Shuangjiang: 'Guizhou',
	Shuanghe: 'Chongqing',
	Shuanggou: 'Jiangsu',
	Shouxian: 'Jiangsu',
	Shouguang: 'Shandong',
	Shizuishan: 'Ningxia Hui Autonomous Region',
	Shizilu: 'Shandong',
	Shiyan: 'Chongqing',
	Shiwan: 'Chongqing',
	Shituo: 'Chongqing',
	Shitanjing: 'Ningxia Hui Autonomous Region',
	Shiquan: 'Shaanxi',
	Shiqiaozi: 'Shandong',
	Shiqiao: 'Gansu',
	Shiqi: 'Guangdong',
	Shimen: 'Chongqing',
	Shima: 'Mie',
	Xianglong: 'Chongqing',
	Shilong: 'Chongqing',
	Tongchuanshi: 'Shaanxi',
	Shikang: 'Guangxi',
	Shijiazhuang: 'Hebei',
	Shijiao: 'Chongqing',
	Shiji: 'Jiangsu',
	Shihui: 'Chongqing',
	Shidui: 'Shandong',
	Shichuan: 'Chongqing',
	Shibuzi: 'Shandong',
	Shetan: 'Chongqing',
	Shenzhen: 'Guangdong',
	Yanta: 'Shandong',
	Chentuan: 'Shandong',
	Shenjiamen: 'Zhejiang',
	Yunlong: 'Jiangsu',
	Shawan: 'Gansu',
	Rensha: 'Chongqing',
	Shaping: 'Guangdong',
	Shaoxing: 'Zhejiang',
	Shaowu: 'Fujian',
	Shaoguan: 'Guangdong',
	Shaodian: 'Jiangsu',
	Shancheng: 'Shandong',
	Shanwei: 'Guangdong',
	Shantou: 'Guangdong',
	Shanting: 'Shandong',
	Shankou: 'Guangxi',
	Shanji: 'Jiangsu',
	Shangluo: 'Shaanxi',
	Shangkou: 'Shandong',
	Shanghuang: 'Chongqing',
	Shanghai: 'Shanghai',
	Shaji: 'Jiangsu',
	Shahecheng: 'Hebei',
	Shahe: 'Chongqing',
	Shagang: 'Guangxi',
	Sanzhuang: 'Shandong',
	Sanya: 'Hainan',
	Sanqu: 'Chongqing',
	Sanming: 'Fujian',
	Sanmenxia: 'Henan',
	Sanjiao: 'Chongqing',
	Sanjiang: 'Chongqing',
	Sanhui: 'Chongqing',
	Xiaodu: 'Chongqing',
	Sangzhe: 'Chongqing',
	Runing: 'Henan',
	'Rui’an': 'Zhejiang',
	Ronglong: 'Chongqing',
	Rongjiang: 'Yunnan',
	Fuqing: 'Fujian',
	Jieyang: 'Guangdong',
	Rizhuang: 'Shandong',
	Renzhao: 'Shandong',
	Renyi: 'Chongqing',
	Renxian: 'Chongqing',
	Renqiu: 'Hebei',
	Renhe: 'Yunnan',
	Quzhou: 'Zhejiang',
	Qujing: 'Yunnan',
	Qufu: 'Shandong',
	Quanzhou: 'Fujian',
	Zhuangyuan: 'Shandong',
	Qiuji: 'Jiangsu',
	Qishan: 'Anhui',
	Qiongshan: 'Hainan',
	Qionghu: 'Hunan',
	Qinzhou: 'Guangxi',
	Qinnan: 'Jiangsu',
	Qinhuangdao: 'Hebei',
	Yizhou: 'Guangxi',
	Jinjiang: 'Hainan',
	Qingshuping: 'Hunan',
	Qingshizui: 'Qinghai',
	Qingshanquan: 'Jiangsu',
	Qingquan: 'Hubei',
	Qingping: 'Chongqing',
	"Huai'an": 'Jiangsu',
	Qingfu: 'Sichuan',
	Qingdao: 'Shandong',
	Qingyuan: 'Guangdong',
	Qincheng: 'Jiangxi',
	Qina: 'Yunnan',
	Hongqiao: 'Yunnan',
	Qiaotou: 'Gansu',
	Qiaoguan: 'Shandong',
	Qianliu: 'Yunnan',
	Puzi: 'Chongqing',
	'Puyang Chengguanzhen': 'Henan',
	Puyang: 'Henan',
	Putian: 'Fujian',
	Puji: 'Shandong',
	Puqi: 'Hubei',
	Pulandian: 'Liaoning',
	'Pu’an': 'Sichuan',
	Poyang: 'Jiangxi',
	Pingyin: 'Shandong',
	Pingyi: 'Shandong',
	Gutao: 'Shanxi',
	Pingxiang: 'Jiangxi',
	Pingtan: 'Chongqing',
	Pingshan: 'Chongqing',
	Pingnan: 'Guangxi',
	Pingliang: 'Gansu',
	Pingli: 'Shaanxi',
	Pingjin: 'Chongqing',
	Pingdu: 'Shandong',
	Pingdingshan: 'Henan',
	Pianjiao: 'Yunnan',
	Pengcheng: 'Hebei',
	Peicheng: 'Jiangsu',
	Picheng: 'Jiangsu',
	Panlong: 'Chongqing',
	Panghai: 'Guizhou',
	'Pan’an': 'Gansu',
	Paipu: 'Hainan',
	'Guang’an': 'Sichuan',
	Ningyang: 'Shandong',
	Yutan: 'Hunan',
	Hanyuan: 'Gansu',
	Ninghai: 'Zhejiang',
	Ningbo: 'Zhejiang',
	Nianbo: 'Qinghai',
	Neijiang: 'Sichuan',
	Nanzhou: 'Hunan',
	'Nanzhang Chengguanzhen': 'Hubei',
	Nanyang: 'Henan',
	Nanxi: 'Sichuan',
	Nantong: 'Chongqing',
	Nanshu: 'Shandong',
	Pucheng: 'Fujian',
	Nanping: 'Fujian',
	Nanning: 'Guangxi',
	Nanma: 'Shandong',
	Nanlong: 'Sichuan',
	Nanjing: 'Jiangsu',
	Nanjian: 'Yunnan',
	Nanhu: 'Shandong',
	Nangong: 'Hebei',
	Nanfeng: 'Hainan',
	Nandu: 'Guangxi',
	Nanding: 'Shandong',
	Nancun: 'Shandong',
	Nanchong: 'Sichuan',
	Nanchang: 'Jiangxi',
	Nanbin: 'Chongqing',
	Naliang: 'Guangxi',
	Nada: 'Hainan',
	'Mu’er': 'Chongqing',
	Mudong: 'Chongqing',
	Miyang: 'Yunnan',
	Mingshui: 'Heilongjiang',
	Mingguang: 'Yunnan',
	Minggang: 'Henan',
	Mingcun: 'Shandong',
	Micheng: 'Yunnan',
	Miaoyu: 'Chongqing',
	Mianyang: 'Shaanxi',
	Mentougou: 'Beijing',
	Mengyin: 'Shandong',
	'Mengcheng Chengguanzhen': 'Anhui',
	Meizhou: 'Guangdong',
	Meishan: 'Sichuan',
	Wuchuan: 'Guangdong',
	Meijiang: 'Chongqing',
	Mawu: 'Chongqing',
	Mawang: 'Chongqing',
	Mapo: 'Jiangsu',
	Maoming: 'Guangdong',
	Maocun: 'Jiangsu',
	Malianzhuang: 'Shandong',
	Majie: 'Gansu',
	Zhijiang: 'Hubei',
	Macheng: 'Hubei',
	Maba: 'Guangdong',
	'Ma’anshan': 'Anhui',
	Luzhou: 'Sichuan',
	Lüshun: 'Liaoning',
	Lushar: 'Qinghai',
	Lushan: 'Guizhou',
	Luoyang: 'Guangdong',
	Luorong: 'Guangxi',
	Luoqi: 'Chongqing',
	Luoping: 'Chongqing',
	Luomen: 'Gansu',
	Luolong: 'Sichuan',
	Luojiang: 'Sichuan',
	Luohuang: 'Chongqing',
	Luohe: 'Henan',
	Luocheng: 'Sichuan',
	Lulou: 'Jiangsu',
	Lucheng: 'Anhui',
	'Lüeyang Chengguanzhen': 'Shaanxi',
	Kangding: 'Sichuan',
	Lubu: 'Guangdong',
	Luancheng: 'Hebei',
	'Lu’an': 'Anhui',
	Loudi: 'Hunan',
	Longyan: 'Fujian',
	Longxing: 'Chongqing',
	Longtan: 'Chongqing',
	Longshui: 'Chongqing',
	Longshi: 'Chongqing',
	Longshan: 'Gansu',
	Longquan: 'Yunnan',
	Longmen: 'Chongqing',
	Longhua: 'Chongqing',
	Longlin: 'Gansu',
	Longgang: 'Chongqing',
	Longju: 'Chongqing',
	Longgu: 'Jiangsu',
	Longfeng: 'Chongqing',
	'Long’e': 'Guizhou',
	Longchi: 'Shandong',
	Longcheng: 'Gansu',
	Longchang: 'Guizhou',
	Licheng: 'Guangdong',
	Lixin: 'Gansu',
	Lixian: 'Gansu',
	Liuyin: 'Chongqing',
	Guankou: 'Hunan',
	Liuxin: 'Jiangsu',
	Liutuan: 'Shandong',
	Puning: 'Guangdong',
	Liuquan: 'Jiangsu',
	Lishui: 'Zhejiang',
	Lishi: 'Chongqing',
	Lintong: 'Shaanxi',
	Liuzhou: 'Guangxi',
	Linyi: 'Shandong',
	'Linxia Chengguanzhen': 'Gansu',
	Linxi: 'Chongqing',
	Linshui: 'Hebei',
	Linshi: 'Chongqing',
	Linqu: 'Shandong',
	Linqiong: 'Sichuan',
	Qingnian: 'Chongqing',
	Linping: 'Zhejiang',
	Linjiang: 'Jilin',
	Linjiacun: 'Shandong',
	Dongta: 'Ningxia Hui Autonomous Region',
	Linghe: 'Shandong',
	Lingcheng: 'Jiangsu',
	Beiliu: 'Guangxi',
	Lingao: 'Hainan',
	Linfeng: 'Chongqing',
	Linfen: 'Shanxi',
	Lincang: 'Yunnan',
	Xishan: 'Hunan',
	Liji: 'Jiangsu',
	Ligezhuang: 'Shandong',
	Lidu: 'Chongqing',
	Lichuan: 'Hubei',
	Licha: 'Shandong',
	Liaolan: 'Shandong',
	Liaocheng: 'Shandong',
	Lianzhou: 'Guangdong',
	Hepu: 'Guangxi',
	Anning: 'Yunnan',
	Wuwei: 'Gansu',
	Liangzhai: 'Jiangsu',
	Liangxiang: 'Beijing',
	Liangshui: 'Gansu',
	Jiangbei: 'Chongqing',
	Liancheng: 'Fujian',
	Lianjiang: 'Guangdong',
	Leshan: 'Sichuan',
	Lengshuitan: 'Hunan',
	Lengshuijiang: 'Hunan',
	Leiyang: 'Hunan',
	Lecheng: 'Guangdong',
	Laohekou: 'Hubei',
	Lanzhou: 'Gansu',
	Lanxi: 'Heilongjiang',
	Lianyuan: 'Hunan',
	Langfang: 'Hebei',
	'Langao Chengguanzhen': 'Shaanxi',
	Laizhou: 'Shandong',
	Laiyang: 'Shandong',
	Laiwu: 'Shandong',
	Laisu: 'Chongqing',
	Laibin: 'Guangxi',
	Labuleng: 'Gansu',
	Kunyang: 'Zhejiang',
	Kunming: 'Yunnan',
	Kuangshi: 'Hebei',
	Kongtan: 'Sichuan',
	Kaiyuan: 'Liaoning',
	Kaili: 'Guizhou',
	Kaihua: 'Yunnan',
	Kaifeng: 'Henan',
	Juye: 'Shandong',
	Junlian: 'Sichuan',
	Juexi: 'Sichuan',
	Juegang: 'Jiangsu',
	Liulin: 'Gansu',
	Jiyuan: 'Henan',
	Jizhou: 'Hebei',
	Jiuxian: 'Chongqing',
	Jiushan: 'Shandong',
	Jiujiang: 'Jiangxi',
	Jiudian: 'Shandong',
	Jishui: 'Henan',
	Qianzhou: 'Hunan',
	Jinzhou: 'Liaoning',
	Jinxiang: 'Zhejiang',
	Jinshi: 'Hunan',
	Jinsha: 'Jiangsu',
	Jinji: 'Guangxi',
	Jining: 'Inner Mongolia',
	Jingzhou: 'Hubei',
	Jingzhi: 'Shandong',
	Tianchang: 'Hebei',
	Jingui: 'Ningxia Hui Autonomous Region',
	Jingmen: 'Hubei',
	Jingling: 'Hubei',
	Jingguan: 'Chongqing',
	Jingdezhen: 'Jiangxi',
	Jinchang: 'Gansu',
	Jincheng: 'Fukien',
	Jinan: 'Shandong',
	Jimo: 'Shandong',
	Jijiang: 'Chongqing',
	Jiexiu: 'Shanxi',
	Jieshou: 'Anhui',
	Jieshi: 'Chongqing',
	Jielong: 'Chongqing',
	Jiehu: 'Shandong',
	Jiazi: 'Guangdong',
	Jiayue: 'Shandong',
	Jiaxing: 'Zhejiang',
	Jiawang: 'Jiangsu',
	Jiasi: 'Chongqing',
	Jiaozuo: 'Henan',
	Jiaoshi: 'Chongqing',
	Jiaogong: 'Gansu',
	Jiaozhou: 'Shandong',
	Ningde: 'Fujian',
	Jianshan: 'Chongqing',
	'Jian’ou': 'Fujian',
	Jiangyan: 'Jiangsu',
	Jianguang: 'Jiangxi',
	Jiangshan: 'Shandong',
	Jiangping: 'Guangxi',
	Jiangmen: 'Guangdong',
	Jiangluo: 'Gansu',
	Jiangkou: 'Fujian',
	Jiangjia: 'Chongqing',
	Yangjiang: 'Guangdong',
	'Jiang’an': 'Sichuan',
	Jianchang: 'Jiangxi',
	'Ji’an': 'Chongqing',
	Guangyuan: 'Sichuan',
	Qionghai: 'Hainan',
	Jiachuan: 'Sichuan',
	Huzhou: 'Zhejiang',
	Hutang: 'Jiangsu',
	Cixi: 'Zhejiang',
	'Huoqiu Chengguanzhen': 'Anhui',
	Huolu: 'Chongqing',
	Humen: 'Guangdong',
	Huizhou: 'Guangdong',
	'Huixian Chengguanzhen': 'Gansu',
	Huilong: 'Chongqing',
	Huicheng: 'Guangdong',
	Huguo: 'Sichuan',
	Hufeng: 'Chongqing',
	Huazhou: 'Guangdong',
	Huayin: 'Shaanxi',
	Huashan: 'Jiangsu',
	Huankou: 'Jiangsu',
	Huangzhou: 'Hubei',
	Huangshi: 'Hubei',
	Dasha: 'Guangdong',
	Huangpi: 'Hubei',
	Huangmei: 'Hubei',
	Huangji: 'Jiangsu',
	Huanghua: 'Shandong',
	Huanggang: 'Hubei',
	Daxing: 'Chongqing',
	Dingcheng: 'Henan',
	Xingyi: 'Chongqing',
	'Huaiyuan Chengguanzhen': 'Anhui',
	Huainan: 'Anhui',
	Huaicheng: 'Guangdong',
	Huaihua: 'Hunan',
	Huaidian: 'Henan',
	Huaibei: 'Anhui',
	Huacheng: 'Guangdong',
	Houzhen: 'Shandong',
	Hongzhou: 'Guizhou',
	Honglu: 'Chongqing',
	Donghe: 'Sichuan',
	Hongjiang: 'Hunan',
	Honghe: 'Shandong',
	'Hong’an': 'Chongqing',
	Hezuo: 'Gansu',
	Heze: 'Shandong',
	Hechuan: 'Chongqing',
	Hetoudian: 'Shandong',
	Yiyang: 'Hunan',
	Hepo: 'Guangdong',
	Hengyang: 'Hunan',
	Hengshui: 'Hebei',
	Hengbei: 'Guangdong',
	Helin: 'Chongqing',
	Hejiang: 'Sichuan',
	Heishui: 'Chongqing',
	Hefei: 'Anhui',
	Hede: 'Jiangsu',
	Hecun: 'Hebei',
	Hebian: 'Chongqing',
	Hebi: 'Henan',
	Heba: 'Gansu',
	Haomen: 'Qinghai',
	Haojiaqiao: 'Ningxia Hui Autonomous Region',
	Hanzhong: 'Shaanxi',
	'Hanyin Chengguanzhen': 'Shaanxi',
	'Fu’an': 'Fujian',
	Hanting: 'Shandong',
	Hanjia: 'Chongqing',
	Hangzhou: 'Zhejiang',
	Hangu: 'Khyber Pakhtunkhwa',
	Hanfeng: 'Chongqing',
	Changzhi: 'Shanxi',
	Handan: 'Hebei',
	Hanchuan: 'Hubei',
	Haizhou: 'Jiangsu',
	Haiyang: 'Anhui',
	Haitou: 'Hainan',
	Jiaojiang: 'Zhejiang',
	Haimen: 'Guangdong',
	Haikou: 'Hainan',
	Guyuan: 'Ningxia Hui Autonomous Region',
	Guye: 'Hebei',
	Guxian: 'Shandong',
	Guxi: 'Chongqing',
	Gupi: 'Jiangsu',
	Guozhen: 'Shaanxi',
	Guofu: 'Chongqing',
	Gunan: 'Chongqing',
	Gulin: 'Sichuan',
	Guli: 'Zhejiang',
	Guiyang: 'Guizhou',
	Guiren: 'Jiangsu',
	Guiping: 'Guangxi',
	Guilin: 'Guangxi',
	Guigang: 'Guangxi',
	'Gucheng Chengguanzhen': 'Hubei',
	Guanzhuang: 'Chongqing',
	Guanyin: 'Sichuan',
	Guanhu: 'Jiangsu',
	Guangzhou: 'Guangdong',
	Guangshun: 'Chongqing',
	Guangshui: 'Hubei',
	Guandu: 'Chongqing',
	Gongtan: 'Chongqing',
	Gongping: 'Chongqing',
	Gongmen: 'Gansu',
	Gongguan: 'Guangxi',
	Gelan: 'Chongqing',
	Gejiu: 'Yunnan',
	Gecheng: 'Chongqing',
	Gaozuo: 'Jiangsu',
	Gaozhou: 'Guangdong',
	Gaoyou: 'Jiangsu',
	Gaoping: 'Sichuan',
	Gaomi: 'Shandong',
	Gaoliu: 'Jiangsu',
	Gaojia: 'Chongqing',
	Gaogu: 'Chongqing',
	Gaogou: 'Jiangsu',
	'Gao’an': 'Chongqing',
	Ganzhou: 'Jiangxi',
	Gantang: 'Hunan',
	Ganshui: 'Chongqing',
	'Gangu Chengguanzhen': 'Gansu',
	Gangtou: 'Jiangsu',
	Fuzhou: 'Fujian',
	Fujia: 'Gansu',
	Fuyang: 'Zhejiang',
	Wofo: 'Chongqing',
	Qingyang: 'Gansu',
	Fulu: 'Chongqing',
	Fuling: 'Chongqing',
	Fuji: 'Shizuoka',
	Foshan: 'Guangdong',
	Fenyi: 'Jiangxi',
	Fenshui: 'Chongqing',
	Fengyi: 'Yunnan',
	Fengcheng: 'Chongqing',
	Fengrun: 'Hebei',
	Fengkou: 'Hubei',
	Fengjia: 'Chongqing',
	Fenggao: 'Chongqing',
	Fenggang: 'Jiangxi',
	Fangshan: 'Beijing',
	Fangcun: 'Jiangsu',
	'Fangcheng Chengguanzhen': 'Henan',
	Ezhou: 'Hubei',
	Enshi: 'Hubei',
	Encheng: 'Guangdong',
	Eman: 'Hainan',
	'Ehen Hudag': 'Inner Mongolia',
	Duzhou: 'Chongqing',
	Duyun: 'Guizhou',
	Dushi: 'Chongqing',
	Dushan: 'Guizhou',
	Duobao: 'Hubei',
	Dunhou: 'Jiangxi',
	Ducheng: 'Guangdong',
	Duoba: 'Qinghai',
	Dongying: 'Shandong',
	Dongxing: 'Guangxi',
	Dongxi: 'Chongqing',
	Dongtai: 'Jiangsu',
	Dongsheng: 'Inner Mongolia',
	Dongkan: 'Jiangsu',
	Donghai: 'Guangdong',
	Dongguan: 'Guangdong',
	Dongdu: 'Shandong',
	Dongcun: 'Shandong',
	Dongchuan: 'Qinghai',
	Xinle: 'Hebei',
	Dingzhou: 'Hebei',
	Dingxi: 'Gansu',
	Dingtao: 'Shandong',
	Dingshi: 'Chongqing',
	Dingjia: 'Chongqing',
	Dezhou: 'Shandong',
	Deyang: 'Sichuan',
	Deqing: 'Zhejiang',
	Dengzhou: 'Henan',
	Songyang: 'Henan',
	Degan: 'Chongqing',
	Liping: 'Guizhou',
	Dazhong: 'Jiangsu',
	Zhangjiajie: 'Hunan',
	Daye: 'Hubei',
	Lijiang: 'Yunnan',
	Daxie: 'Chongqing',
	Dazhou: 'Sichuan',
	Dawukou: 'Ningxia Hui Autonomous Region',
	Datun: 'Jiangsu',
	Datong: 'Chongqing',
	Dashun: 'Chongqing',
	Dashan: 'Yunnan',
	Fenghua: 'Zhejiang',
	Daotian: 'Shandong',
	Daokou: 'Henan',
	Danshui: 'Guangdong',
	Danjiangkou: 'Hubei',
	Gushu: 'Anhui',
	Dangjiang: 'Guangxi',
	'Tanchang Chengguanzhen': 'Gansu',
	Damiao: 'Chongqing',
	Dalu: 'Chongqing',
	Daliang: 'Guangdong',
	Dalian: 'Liaoning',
	Dali: 'Yunnan',
	Dajin: 'Chongqing',
	Dahuangshan: 'Jiangsu',
	Daguan: 'Chongqing',
	Dafengdong: 'Guizhou',
	Dachang: 'Chongqing',
	Shuren: 'Chongqing',
	'Da’an': 'Chongqing',
	Cuijiaji: 'Shandong',
	Cizhu: 'Chongqing',
	Chuzhou: 'Anhui',
	Yangchun: 'Guangdong',
	Yiwu: 'Zhejiang',
	Chongxing: 'Ningxia Hui Autonomous Region',
	Chongqing: 'Chongqing',
	Chonglong: 'Sichuan',
	Chongkan: 'Chongqing',
	Chizhou: 'Anhui',
	Chenzhou: 'Hunan',
	Chengyang: 'Shandong',
	'Chengxian Chengguanzhen': 'Gansu',
	Chengxi: 'Chongqing',
	Chengjiang: 'Chongqing',
	Jiangyin: 'Jiangsu',
	Chenggu: 'Shaanxi',
	Chengdu: 'Sichuan',
	Chenghua: 'Guangdong',
	Chefushan: 'Jiangsu',
	Chaozhou: 'Guangdong',
	Chaotian: 'Sichuan',
	Chaohu: 'Anhui',
	Changzhou: 'Jiangsu',
	Changyuan: 'Chongqing',
	Changyi: 'Shandong',
	Changxin: 'Ningxia Hui Autonomous Region',
	Changsha: 'Chongqing',
	Changqing: 'Shandong',
	Changning: 'Sichuan',
	Changli: 'Hebei',
	Changleng: 'Jiangxi',
	Changle: 'Guangxi',
	Changdian: 'Jiangsu',
	Changcheng: 'Shandong',
	Changba: 'Chongqing',
	Chaigou: 'Shandong',
	Chahe: 'Jiangsu',
	Caoqiao: 'Jiangsu',
	Caohe: 'Hubei',
	Weining: 'Guizhou',
	Cangzhou: 'Hebei',
	Caijia: 'Chongqing',
	Caidian: 'Hubei',
	Buhe: 'Hubei',
	Bozhou: 'Anhui',
	Botou: 'Hebei',
	Boshan: 'Shandong',
	Baise: 'Guangxi',
	Biyang: 'Anhui',
	Bishan: 'Chongqing',
	Binzhou: 'Shandong',
	Bikou: 'Gansu',
	Bijie: 'Guizhou',
	Bianzhuang: 'Shandong',
	Biantang: 'Jiangsu',
	Bengbu: 'Anhui',
	Beiwangli: 'Hebei',
	Beisu: 'Hebei',
	Beimeng: 'Shandong',
	Beijing: 'Beijing',
	Beihai: 'Yunnan',
	Beidao: 'Gansu',
	Beidaihehaibin: 'Hebei',
	Beibei: 'Chongqing',
	Bayiji: 'Jiangsu',
	'Bayan Hot': 'Inner Mongolia',
	Baiyang: 'Chongqing',
	Batang: 'Central Java',
	Basuo: 'Hainan',
	Bashan: 'Jiangxi',
	Baoying: 'Jiangsu',
	Shaoyang: 'Hunan',
	Langzhong: 'Sichuan',
	Baoluan: 'Chongqing',
	Baojia: 'Chongqing',
	Baoding: 'Chongqing',
	Banqiao: 'Taipei',
	Balu: 'Jiangsu',
	Baizi: 'Chongqing',
	Baiyin: 'Gansu',
	Baixi: 'Sichuan',
	Baituo: 'Gansu',
	Baitu: 'Chongqing',
	Baitao: 'Chongqing',
	Baisha: 'Guangxi',
	Baimajing: 'Hainan',
	Baima: 'Chongqing',
	Bailin: 'Chongqing',
	Baihua: 'Sichuan',
	Baihecun: 'Guangxi',
	Shangyu: 'Zhejiang',
	Baichihe: 'Shandong',
	Bachuan: 'Chongqing',
	Babu: 'Guangxi',
	Qilian: 'Qinghai',
	Aoxi: 'Jiangxi',
	Anyuan: 'Gansu',
	Anxiang: 'Henan',
	Anshun: 'Guizhou',
	Anqiu: 'Shandong',
	Anqing: 'Anhui',
	Anping: 'Chongqing',
	Mabai: 'Yunnan',
	Anlu: 'Hubei',
	Anlong: 'Guizhou',
	Anliu: 'Guangdong',
	Anju: 'South Pyongan',
	Anjiang: 'Hunan',
	Anhua: 'Gansu',
	Anfu: 'Gansu',
	Naxi: 'Sichuan',
	Anbu: 'Guangdong',
	Ahu: 'Jiangsu',
	Jiangyou: 'Sichuan',
	Zhoushan: 'Zhejiang',
	Dacheng: 'Hainan',
	Wuduan: 'Jiangsu',
	Guanshan: 'Jiangsu',
	Mudu: 'Jiangsu',
	Songling: 'Hebei',
	Lüfeng: 'Chongqing',
	Xinsheng: 'Chongqing',
	Yongxi: 'Chongqing',
	Pulü: 'Chongqing',
	Caijiagang: 'Chongqing',
	Tieshan: 'Chongqing',
	Hebao: 'Chongqing',
	Baoxing: 'Chongqing',
	Jinlong: 'Chongqing',
	Guangpu: 'Chongqing',
	Chenshi: 'Chongqing',
	Qingfeng: 'Chongqing',
	Wutan: 'Chongqing',
	Wujian: 'Chongqing',
	Hegeng: 'Chongqing',
	Xianlong: 'Chongqing',
	Kaitang: 'Guizhou',
	Bibo: 'Guizhou',
	Jiuchao: 'Guizhou',
	Zhaoxing: 'Guizhou',
	Baijiawan: 'Gansu',
	Baliwan: 'Gansu',
	Dashi: 'Chongqing',
	Dazhuang: 'Gansu',
	Guochuan: 'Gansu',
	Jinshan: 'Gansu',
	Jutou: 'Gansu',
	Kuanchuan: 'Gansu',
	Liuping: 'Gansu',
	Qianhu: 'Gansu',
	Suhe: 'Gansu',
	Wangyao: 'Gansu',
	Wangyin: 'Gansu',
	Xingfeng: 'Gansu',
	Yebao: 'Gansu',
	Yongping: 'Gansu',
	Zhongshan: 'Chongqing',
	Banan: 'Chongqing',
	Dianbu: 'Shandong',
	Conglin: 'Chongqing',
	Ciyun: 'Chongqing',
	Xiaba: 'Chongqing',
	Anlan: 'Chongqing',
	Nanpeng: 'Chongqing',
	Huimin: 'Yunnan',
	Ersheng: 'Chongqing',
	Yufengshan: 'Chongqing',
	Tongjiaxi: 'Chongqing',
	Dasheng: 'Shandong',
	Tuchang: 'Chongqing',
	Honghu: 'Chongqing',
	Lanyang: 'Hainan',
	Guangcun: 'Hainan',
	Sandu: 'Hainan',
	Mutang: 'Hainan',
	Dongcheng: 'Hainan',
	Heqing: 'Hainan',
	Wuzhishan: 'Hainan',
	Lianghu: 'Zhejiang',
	Zhoucheng: 'Shandong',
	Dalianwan: 'Liaoning',
	Xiaochangshan: 'Liaoning',
	Wuyishan: 'Fujian',
	Yueyang: 'Hunan',
	Bojia: 'Hunan',
	Santangpu: 'Hunan',
	Zhaojia: 'Chongqing',
	Gulu: 'Northern Region',
	Zouma: 'Chongqing',
	Sanmiao: 'Chongqing',
	Rongxi: 'Chongqing',
	Longsheng: 'Chongqing',
	Lixi: 'Chongqing',
	Hedong: 'Guangdong',
	Zhenlai: 'Jilin',
	Shuangliao: 'Jilin',
	Zhaozhou: 'Heilongjiang',
	Zhaodong: 'Heilongjiang',
	Zhangjiakou: 'Hebei',
	Zhalantun: 'Inner Mongolia',
	Yushu: 'Jilin',
	Youhao: 'Heilongjiang',
	Yingkou: 'Liaoning',
	Yilan: 'Taiwan',
	Yebaishou: 'Liaoning',
	Yantongshan: 'Jilin',
	Yanji: 'Jilin',
	Yakeshi: 'Inner Mongolia',
	Xuanhua: 'Hebei',
	Xiuyan: 'Liaoning',
	Xinqing: 'Heilongjiang',
	Xinglongshan: 'Jilin',
	Xingcheng: 'Liaoning',
	'Xilin Hot': 'Inner Mongolia',
	Xifeng: 'Liaoning',
	Xiaoshi: 'Liaoning',
	Wuchang: 'Heilongjiang',
	Wangqing: 'Jilin',
	Hepingjie: 'Jilin',
	Wangkui: 'Heilongjiang',
	Ulanhot: 'Inner Mongolia',
	Tumen: 'Jilin',
	Tongliao: 'Inner Mongolia',
	Tonghua: 'Jilin',
	Tieling: 'Liaoning',
	Tieli: 'Heilongjiang',
	Taonan: 'Jilin',
	Tailai: 'Heilongjiang',
	Dorbod: 'Heilongjiang',
	Tahe: 'Heilongjiang',
	Sujiatun: 'Liaoning',
	Suiling: 'Heilongjiang',
	Suihua: 'Heilongjiang',
	Suifenhe: 'Heilongjiang',
	Songjianghe: 'Jilin',
	Siping: 'Jilin',
	Shunyi: 'Beijing',
	Shulan: 'Jilin',
	Shuangyashan: 'Heilongjiang',
	Shuangyang: 'Jilin',
	Shuangcheng: 'Heilongjiang',
	Shiguai: 'Inner Mongolia',
	Shenyang: 'Liaoning',
	Shanhecun: 'Heilongjiang',
	Shanhaiguan: 'Hebei',
	Shangzhi: 'Heilongjiang',
	Sanchazi: 'Jilin',
	Salaqi: 'Inner Mongolia',
	Fendou: 'Heilongjiang',
	Qitaihe: 'Heilongjiang',
	Qiqihar: 'Heilongjiang',
	Qinggang: 'Chongqing',
	Songyuan: 'Jilin',
	Pingzhuang: 'Inner Mongolia',
	Panshi: 'Jilin',
	Panshan: 'Liaoning',
	Nianzishan: 'Heilongjiang',
	Nenjiang: 'Heilongjiang',
	Nehe: 'Heilongjiang',
	Nantai: 'Liaoning',
	Nanpiao: 'Liaoning',
	Lianhe: 'Heilongjiang',
	Mujiayingzi: 'Inner Mongolia',
	Mudanjiang: 'Heilongjiang',
	Mishan: 'Heilongjiang',
	Antu: 'Jilin',
	Meihekou: 'Jilin',
	Manzhouli: 'Inner Mongolia',
	Longjing: 'Chongqing',
	Longjiang: 'Heilongjiang',
	Liuhe: 'Jilin',
	Lishu: 'Jilin',
	Linkou: 'Heilongjiang',
	Lingyuan: 'Liaoning',
	Lingdong: 'Heilongjiang',
	Liaozhong: 'Liaoning',
	Liaoyuan: 'Jilin',
	Liaoyang: 'Liaoning',
	Langxiang: 'Heilongjiang',
	Langtoucun: 'Liaoning',
	Kuandian: 'Liaoning',
	Kaitong: 'Jilin',
	Jixi: 'Heilongjiang',
	Jiutai: 'Jilin',
	Jiupu: 'Liaoning',
	Jishu: 'Jilin',
	Lianshan: 'Liaoning',
	Jilin: 'Jilin',
	Jidong: 'Heilongjiang',
	Jiaohe: 'Jilin',
	Jiamusi: 'Heilongjiang',
	'Jalai Nur': 'Inner Mongolia',
	Jiagedaqi: 'Inner Mongolia',
	Hushitai: 'Liaoning',
	Hunchun: 'Jilin',
	'Hulan Ergi': 'Heilongjiang',
	Hulan: 'Heilongjiang',
	Huinan: 'Jilin',
	Huanren: 'Liaoning',
	Huangnihe: 'Jilin',
	Huanan: 'Heilongjiang',
	Huadian: 'Jilin',
	Honggang: 'Heilongjiang',
	Hohhot: 'Inner Mongolia',
	Hengshan: 'Heilongjiang',
	Helong: 'Jilin',
	Heishan: 'Liaoning',
	Heihe: 'Heilongjiang',
	Hegang: 'Heilongjiang',
	Harbin: 'Heilongjiang',
	Hailun: 'Heilongjiang',
	Hailin: 'Heilongjiang',
	Hailar: 'Inner Mongolia',
	Haicheng: 'Liaoning',
	Gongzhuling: 'Jilin',
	Gongchangling: 'Liaoning',
	Genhe: 'Inner Mongolia',
	Gannan: 'Heilongjiang',
	Fuyuan: 'Heilongjiang',
	Fuyu: 'Jilin',
	Fuxin: 'Liaoning',
	Fushun: 'Liaoning',
	Jixian: 'Heilongjiang',
	Fujin: 'Heilongjiang',
	Beichengqu: 'Inner Mongolia',
	Luobei: 'Heilongjiang',
	Erenhot: 'Inner Mongolia',
	Erdaojiang: 'Jilin',
	Dunhua: 'Jilin',
	Dongning: 'Heilongjiang',
	Dongling: 'Liaoning',
	Dongfeng: 'Jilin',
	Didao: 'Heilongjiang',
	Dehui: 'Jilin',
	Dashitou: 'Jilin',
	Dashiqiao: 'Liaoning',
	Daqing: 'Heilongjiang',
	Dandong: 'Liaoning',
	Linghai: 'Liaoning',
	'Dalain Hob': 'Inner Mongolia',
	Dalai: 'Jilin',
	Chifeng: 'Inner Mongolia',
	Chengzihe: 'Heilongjiang',
	Chengde: 'Hebei',
	Chaoyang: 'Liaoning',
	Changtu: 'Liaoning',
	Changping: 'Beijing',
	Changling: 'Chongqing',
	Changchun: 'Jilin',
	Chaihe: 'Heilongjiang',
	Boli: 'Heilongjiang',
	'Bin Xian': 'Heilongjiang',
	Benxi: 'Liaoning',
	Beipiao: 'Liaoning',
	'Bei’an': 'Heilongjiang',
	Bayan: 'Heilongjiang',
	Baotou: 'Inner Mongolia',
	Baoqing: 'Heilongjiang',
	Muling: 'Heilongjiang',
	Baishishan: 'Jilin',
	Baiquan: 'Heilongjiang',
	Baicheng: 'Shandong',
	Baishan: 'Jilin',
	Anshan: 'Liaoning',
	Anda: 'Heilongjiang',
	'Oroqen Zizhiqi': 'Inner Mongolia',
	Acheng: 'Heilongjiang',
	Shigang: 'Chongqing',
	'E’zhou': 'Hubei',
	Jinniu: 'Yunnan',
	Xiangjiaba: 'Yunnan',
	Shilin: 'Yunnan',
	Bowangshan: 'Sichuan',
	Panzhihua: 'Sichuan',
	Taifu: 'Sichuan',
	Changshu: 'Jiangsu',
	Shixing: 'Guangdong',
	Jiashan: 'Zhejiang',
	Fenghuang: 'Hunan',
	Baiguan: 'Gansu',
	Zhongba: 'Gansu',
	Dengbu: 'Jiangxi',
	Baihe: 'Chongqing',
	Puchi: 'Gansu',
	Maying: 'Gansu',
	Dianga: 'Gansu',
	Dongjiang: 'Gansu',
	Chengjiao: 'Gansu',
	Wuying: 'Gansu',
	Lianhua: 'Gansu',
	Weidian: 'Gansu',
	Shibao: 'Gansu',
	Luotang: 'Gansu',
	Yulong: 'Chongqing',
	Paosha: 'Gansu',
	Xigaoshan: 'Gansu',
	Luoyu: 'Gansu',
	Shili: 'Gansu',
	Jiangxi: 'Gansu',
	Luhe: 'Gansu',
	Xiyu: 'Gansu',
	Shaoyu: 'Gansu',
	'Hadapu Zhen': 'Gansu',
	'Lichuan Zhen': 'Gansu',
	Tange: 'Gansu',
	Mali: 'Gansu',
	Shandan: 'Gansu',
	Gaolou: 'Gansu',
	Hualin: 'Gansu',
	Hezhou: 'Guangxi',
	'Zhu Cheng City': 'Shandong',
	'Shangri-La': 'Yunnan',
	Ordos: 'Inner Mongolia',
	Daocheng: 'Jiangsu',
	Chengxiang: 'Chongqing',
	Tangfang: 'Chongqing',
	Fengling: 'Chongqing',
	Baolong: 'Chongqing',
	Tonggu: 'Chongqing',
	Sanxi: 'Chongqing',
	Liangping: 'Chongqing',
	Longxi: 'Chongqing',
	Futian: 'Chongqing',
	Dashu: 'Chongqing',
	Kangle: 'Chongqing',
	Fenhe: 'Chongqing',
	Baidi: 'Chongqing',
	Caotang: 'Chongqing',
	Yongle: 'Chongqing',
	Jiagao: 'Chongqing',
	Qinglong: 'Chongqing',
	Wuma: 'Chongqing',
	Fengping: 'Yunnan',
	Qinglian: 'Chongqing',
	Heqian: 'Chongqing',
	Maliu: 'Chongqing',
	Heyan: 'Chongqing',
	Guojia: 'Gansu',
	Dunhao: 'Chongqing',
	Gaoqiao: 'Chongqing',
	Yihe: 'Chongqing',
	Jiulongshan: 'Chongqing',
	'Zhen’an': 'Chongqing',
	Dade: 'Chongqing',
	Fengle: 'Chongqing',
	Zhendong: 'Chongqing',
	Houba: 'Chongqing',
	Nanmen: 'Chongqing',
	Nanya: 'Chongqing',
	Houshan: 'Chongqing',
	Lihe: 'Chongqing',
	Xiongjia: 'Chongqing',
	Tiancheng: 'Chongqing',
	Zhoujiaba: 'Chongqing',
	Taibai: 'Chongqing',
	Gaoliang: 'Chongqing',
	Zhonggulou: 'Chongqing',
	Jiuchi: 'Chongqing',
	Pailou: 'Chongqing',
	Chenjiaba: 'Chongqing',
	'Bai’anba': 'Chongqing',
	Shuanghekou: 'Chongqing',
	Zhushan: 'Chongqing',
	Danzi: 'Chongqing',
	Changtan: 'Chongqing',
	Luotian: 'Chongqing',
	Guocun: 'Chongqing',
	Longsha: 'Chongqing',
	Ganning: 'Chongqing',
	Gaofeng: 'Chongqing',
	Xialu: 'Chongqing',
	Caohui: 'Chongqing',
	Changlong: 'Chongqing',
	Pushun: 'Chongqing',
	Gangjia: 'Chongqing',
	Baijia: 'Chongqing',
	Yinping: 'Chongqing',
	Jukui: 'Chongqing',
	Mingda: 'Chongqing',
	Lirang: 'Chongqing',
	Hucheng: 'Chongqing',
	Hexing: 'Chongqing',
	Baohe: 'Chongqing',
	Dongjia: 'Chongqing',
	Shizhi: 'Chongqing',
	Huwei: 'Chongqing',
	Sanhe: 'Chongqing',
	Longkong: 'Chongqing',
	Longhe: 'Chongqing',
	Qingxichang: 'Chongqing',
	Pingkai: 'Chongqing',
	Zhongling: 'Chongqing',
	Erong: 'Chongqing',
	Wanmu: 'Chongqing',
	Apengjiang: 'Chongqing',
	Zhoubai: 'Chongqing',
	Dianshui: 'Chongqing',
	Longshe: 'Chongqing',
	Lianhu: 'Chongqing',
	Tongle: 'Chongqing',
	Zengfu: 'Chongqing',
	Longqiao: 'Chongqing',
	Lizhi: 'Chongqing',
	Nantuo: 'Chongqing',
	Qingxi: 'Chongqing',
	Baisheng: 'Chongqing',
	Yunji: 'Chongqing',
	Changshouhu: 'Chongqing',
	Haitang: 'Chongqing',
	Jiangnan: 'Chongqing',
	Bake: 'Chongqing',
	Dawan: 'Chongqing',
	Lijia: 'Chongqing',
	Shuangfengqiao: 'Chongqing',
	Huixing: 'Chongqing',
	Cuiyun: 'Chongqing',
	Wangjia: 'Chongqing',
	Fuxing: 'Chongqing',
	Shuanghuai: 'Chongqing',
	Shuangfeng: 'Chongqing',
	Shitan: 'Chongqing',
	Xiaojia: 'Chongqing',
	Laitan: 'Chongqing',
	Qiantang: 'Chongqing',
	Gulou: 'Chongqing',
	Yanwo: 'Chongqing',
	Erlang: 'Chongqing',
	Yangcheng: 'Chongqing',
	Diaoyucheng: 'Chongqing',
	Nanjin: 'Chongqing',
	Zhifeng: 'Chongqing',
	Shuanglu: 'Chongqing',
	Tangxiang: 'Chongqing',
	Guchang: 'Chongqing',
	Shenglilu: 'Chongqing',
	Nandajie: 'Chongqing',
	Shaoyun: 'Chongqing',
	Jianlong: 'Chongqing',
	Shuangfu: 'Chongqing',
	Zhiping: 'Chongqing',
	'Wenshan City': 'Yunnan',
	Tuanbao: 'Hubei',
	Bole: 'Xinjiang',
	Liupanshui: 'Guizhou',
	Nanchuan: 'Chongqing',
	Anwen: 'Chongqing',
	Guanba: 'Chongqing',
	Maliuzui: 'Chongqing',
	Mugala: 'Xinjiang',
	Xingning: 'Guangdong',
	Damoujia: 'Shandong',
	Bageqi: 'Xinjiang',
	Tawakule: 'Xinjiang',
	'Han’airike': 'Xinjiang',
	'Ying’awati': 'Xinjiang',
	Rizhao: 'Shandong',
	Shanwang: 'Shandong',
	Taxkowrük: 'Xinjiang',
	Onyar: 'Xinjiang',
	Fudong: 'Yunnan',
	Jianshui: 'Yunnan',
	Tuantian: 'Yunnan',
	Liuzhi: 'Guizhou',
	Wanghong: 'Ningxia Hui Autonomous Region',
	'Hongguang Qidui': 'Ningxia Hui Autonomous Region',
	Linhe: 'Ningxia Hui Autonomous Region',
	Sansheng: 'Chongqing',
	Gongqingcheng: 'Jiangxi',
	Xiping: 'Gansu',
	Xuzhou: 'Jiangsu',
	Xichuan: 'Gansu',
	Lianyungang: 'Jiangsu',
	Rugao: 'Jiangsu',
	Jinzhong: 'Shanxi',
	Baoji: 'Shaanxi',
	Qipan: 'Jiangsu',
	Huocheng: 'Xinjiang',
	Longnan: 'Gansu',
	'Bayan Nur': 'Inner Mongolia',
	Huoshilafu: 'Xinjiang',
	Gulebage: 'Xinjiang',
	'Sandaoling Lutiankuang Wuqi Nongchang': 'Xinjiang',
	Siyeke: 'Xinjiang',
	Tumxuk: 'Xinjiang',
	Wulong: 'Chongqing',
	Chongzuo: 'Guangxi',
	Zhakou: 'Guangxi',
	Fangchenggang: 'Guangxi',
	'Dali Old Town': 'Yunnan',
	'Haidong Zhen': 'Yunnan',
	'Yinqiao Zhen': 'Yunnan',
	'Wanqiao Zhen': 'Yunnan',
	Shuangtang: 'Jiangsu',
	Dapeng: 'Jiangsu',
	Daxu: 'Jiangsu',
	Liguo: 'Shandong',
	'Jiawang Zhen': 'Jiangsu',
	Dashahe: 'Jiangsu',
	Wanggou: 'Jiangsu',
	"Jing'an": 'Jiangsu',
	Yingqiu: 'Shandong',
	Liushan: 'Shandong',
	Yishan: 'Shandong',
	Huiqu: 'Shandong',
	Jinzhongzi: 'Shandong',
	Zheshan: 'Shandong',
	Buzhuang: 'Shandong',
	Xiaying: 'Shandong',
	Jinggou: 'Shandong',
	Kanjia: 'Shandong',
	Huanglou: 'Shandong',
	Yidu: 'Shandong',
	Dongxia: 'Shandong',
	Heguan: 'Shandong',
	Miaozi: 'Shandong',
	Mihe: 'Shandong',
	Shaozhuang: 'Shandong',
	Tanfang: 'Shandong',
	Gucheng: 'Shandong',
	Hualong: 'Shandong',
	Jitai: 'Shandong',
	Taitou: 'Shandong',
	Yingli: 'Shandong',
	Siwei: 'Hebei',
	Ligang: 'Ningxia Hui Autonomous Region',
	Lingwu: 'Ningxia Hui Autonomous Region',
	Ningdong: 'Ningxia Hui Autonomous Region',
	Wangyuan: 'Ningxia Hui Autonomous Region',
	Minning: 'Ningxia Hui Autonomous Region',
	Lijun: 'Ningxia Hui Autonomous Region',
	Heshan: 'Shandong',
	Xihu: 'Chongqing',
	Shangchong: 'Guizhou',
	Sankeshu: 'Guizhou',
	Hechi: 'Guangxi',
	Xingdaohu: 'Guangxi',
	Donghui: 'Yunnan',
	Fazhanhe: 'Yunnan',
	Fubang: 'Yunnan',
	Mujia: 'Yunnan',
	Nanling: 'Yunnan',
	Nuofu: 'Yunnan',
	Nuozhadu: 'Yunnan',
	Zhutang: 'Yunnan',
	Menghuan: 'Yunnan',
	Nongzhang: 'Yunnan',
	Hehua: 'Yunnan',
	Mangbang: 'Yunnan',
	Mazhan: 'Yunnan',
	Puchuan: 'Yunnan',
	Wuhe: 'Yunnan',
	Xinhua: 'Yunnan',
	Miaojie: 'Yunnan',
	Yongjian: 'Yunnan',
	Yousuo: 'Yunnan',
	Sanchuan: 'Yunnan',
	Chenghai: 'Yunnan',
	Shunzhou: 'Yunnan',
	Jiuhe: 'Yunnan',
	Xinyingpan: 'Yunnan',
	Shilongba: 'Yunnan',
	Wanzhou: 'Chongqing',
	Zhuyi: 'Chongqing',
	Yintai: 'Shaanxi',
	Jifeng: 'Gansu',
	Changdao: 'Gansu',
	Liufeng: 'Gansu',
	Xiejiawan: 'Gansu',
	Hongbao: 'Gansu',
	Dayang: 'Gansu',
	Maguan: 'Gansu',
	Muhe: 'Gansu',
	Quankou: 'Qinghai',
	Alamaiti: 'Xinjiang',
	Arele: 'Xinjiang',
	Awati: 'Xinjiang',
	Azhatebage: 'Xinjiang',
	"Bage'awati": 'Xinjiang',
	Baishikante: 'Xinjiang',
	Dunbage: 'Xinjiang',
	Huangdi: 'Xinjiang',
	"Kuoshi'airike": 'Xinjiang',
	Mixia: 'Xinjiang',
	Paikeqi: 'Xinjiang',
	"Tage'erqi": 'Xinjiang',
	Tuomuwusitang: 'Xinjiang',
	Wudalike: 'Xinjiang',
	"Yigai'erqi": 'Xinjiang',
	Yishikuli: 'Xinjiang',
	Bayandai: 'Xinjiang',
	Hudiyuzi: 'Xinjiang',
	Arewusitang: 'Xinjiang',
	Awuliya: 'Xinjiang',
	Samuyuzi: 'Xinjiang',
	"Weiwu'eryuqiwen": 'Xinjiang',
	Jiayi: 'Xinjiang',
	Buzhake: 'Xinjiang',
	"Yisilamu'awati": 'Xinjiang',
	Akesalayi: 'Xinjiang',
	Jiahanbage: 'Xinjiang',
	"Ka'ersai": 'Xinjiang',
	Kuiya: 'Xinjiang',
	Kuoyiqi: 'Xinjiang',
	Manglai: 'Xinjiang',
	Puqiakeqi: 'Xinjiang',
	Tuohula: 'Xinjiang',
	Tuwaite: 'Xinjiang',
	"Wu'erqi": 'Xinjiang',
	Yawa: 'Xinjiang',
	Zhawa: 'Xinjiang',
	Dazeshan: 'Shandong',
	Dianzi: 'Shandong',
	Dongwenquan: 'Chongqing',
	Qitang: 'Chongqing',
	Shuanglonghu: 'Chongqing',
	Jiaping: 'Chongqing',
	Mingshan: 'Chongqing',
	Caojie: 'Chongqing',
	Fuhuan: 'Chongqing',
	Shihao: 'Chongqing',
	Yongcheng: 'Chongqing',
	Zhuantang: 'Chongqing',
	'Guiyang, Guizhou': 'Guizhou',
	'Longling County': 'Yunnan',
	'Mianzhu, Deyang, Sichuan': 'Sichuan',
	'Pingwu County': 'Sichuan',
	Tianjia: 'Chongqing',
	Weixinghu: 'Chongqing',
	Zipaquirá: 'Cundinamarca',
	Zarzal: 'Valle del Cauca',
	Zaragoza: 'Chimaltenango',
	Yumbo: 'Valle del Cauca',
	Yopal: 'Casanare Department',
	Yondó: 'Antioquia',
	Yarumal: 'Antioquia',
	Villeta: 'Cundinamarca',
	Villavicencio: 'Meta',
	'Villa del Rosario': 'Norte de Santander Department',
	Villanueva: 'Cortés Department',
	Villamaría: 'Caldas Department',
	Vélez: 'Santander',
	Valledupar: 'Cesar',
	Urrao: 'Antioquia',
	'Villa de San Diego de Ubaté': 'Cundinamarca',
	Turbo: 'Antioquia',
	Turbaco: 'Bolívar',
	Túquerres: 'Nariño',
	Tunja: 'Boyacá',
	Tumaco: 'Nariño',
	Tuluá: 'Valle del Cauca',
	'Tolú Viejo': 'Sucre',
	'Santiago de Tolú': 'Sucre',
	Timbiquí: 'Cauca',
	Tierralta: 'Córdoba',
	Tauramena: 'Casanare Department',
	Tarqui: 'Huila',
	Tame: 'Departamento de Arauca',
	Tadó: 'Chocó',
	Supía: 'Caldas Department',
	Suárez: 'Cauca',
	Sonsón: 'Antioquia',
	Soledad: 'Anzoátegui',
	Sogamoso: 'Boyacá',
	Soacha: 'Cundinamarca',
	Sitionuevo: 'Magdalena',
	Sincelejo: 'Sucre',
	'San Luis de Sincé': 'Sucre',
	Simití: 'Bolívar',
	Silvania: 'Cundinamarca',
	Sibaté: 'Cundinamarca',
	Sevilla: 'Andalusia',
	Segovia: 'Castille and León',
	Santuario: 'Antioquia',
	'Santo Tomás': 'Atlántico',
	'Santa Rosa de Osos': 'Antioquia',
	'Santa Rosa de Cabal': 'Risaralda',
	'Santander de Quilichao': 'Cauca',
	'Santa Marta': 'Magdalena',
	'San Pedro de Urabá': 'Antioquia',
	'San Pablo': 'California',
	'San Onofre': 'Sucre',
	'San Marcos': 'California',
	'San Juan Nepomuceno': 'Bolívar',
	'San Juan de Urabá': 'Antioquia',
	'San Juan del Cesar': 'La Guajira Department',
	'San Jacinto': 'California',
	'San Gil': 'Santander',
	'San Estanislao': 'Bolívar',
	'San Diego': 'California',
	'San Benito Abad': 'Sucre',
	'San Antero': 'Córdoba',
	'San Andrés': 'San Andres y Providencia',
	Sampués: 'Sucre',
	Samaniego: 'Nariño',
	Salgar: 'Antioquia',
	Salamina: 'Caldas Department',
	Sahagún: 'Córdoba',
	Sabaneta: 'Barinas',
	Sabanalarga: 'Atlántico',
	Sabanagrande: 'Atlántico',
	'Sabana de Torres': 'Santander',
	Rovira: 'Tolima',
	Roldanillo: 'Valle del Cauca',
	Rivera: 'Rivera Department',
	Riosucio: 'Caldas Department',
	Rionegro: 'Antioquia',
	Riohacha: 'La Guajira Department',
	Rioblanco: 'Tolima',
	Restrepo: 'Meta',
	Repelón: 'Atlántico',
	Quinchía: 'Risaralda',
	Quimbaya: 'Quindío',
	Quibdó: 'Chocó',
	Purificación: 'Tolima',
	Pupiales: 'Nariño',
	'Puerto Wilches': 'Santander',
	'Puerto Triunfo': 'Antioquia',
	'Puerto Tejada': 'Cauca',
	'Puerto Santander': 'Norte de Santander Department',
	'Puerto Salgar': 'Cundinamarca',
	'Puerto López': 'Meta',
	'Puerto Leguízamo': 'Putumayo',
	'Puerto Colombia': 'Atlántico',
	'Puerto Carreño': 'Vichada',
	'Puerto Boyacá': 'Boyacá',
	'Puerto Berrío': 'Antioquia',
	'Puerto Asís': 'Putumayo',
	Puebloviejo: 'Magdalena',
	Pradera: 'Valle del Cauca',
	Popayán: 'Cauca',
	Ponedera: 'Atlántico',
	Polonuevo: 'Atlántico',
	'Planeta Rica': 'Córdoba',
	Planadas: 'Tolima',
	Pizarro: 'Chocó',
	Pivijay: 'Magdalena',
	Pitalito: 'Huila',
	Pinillos: 'Bolívar',
	Piedecuesta: 'Santander',
	Pereira: 'Risaralda',
	Payandé: 'Tolima',
	Patía: 'Cauca',
	Pasto: 'Nariño',
	Pamplona: 'Navarre',
	Palmira: 'Táchira',
	'Palmar de Varela': 'Atlántico',
	Pailitas: 'Cesar',
	Belalcázar: 'Cauca',
	Padua: 'Tolima',
	Pacho: 'Cundinamarca',
	Ocaña: 'Norte de Santander Department',
	'Nueva Granada': 'Sucre',
	Neiva: 'Huila',
	Neira: 'Caldas Department',
	Nechí: 'Antioquia',
	Natagaima: 'Tolima',
	Mosquera: 'Cundinamarca',
	Morales: 'Izabal Department',
	Montería: 'Córdoba',
	Montelíbano: 'Córdoba',
	Moniquirá: 'Boyacá',
	Mompós: 'Bolívar',
	Momil: 'Córdoba',
	Mocoa: 'Putumayo',
	Mitú: 'Vaupés',
	Melgar: 'Tolima',
	Medellín: 'Antioquia',
	Marsella: 'Risaralda',
	'San Sebastián de Mariquita': 'Tolima',
	Marinilla: 'Antioquia',
	'María la Baja': 'Bolívar',
	Manzanares: 'Castille-La Mancha',
	Manizales: 'Caldas Department',
	Manatí: 'Manatí',
	Malambo: 'Atlántico',
	Málaga: 'Andalusia',
	Maicao: 'La Guajira Department',
	Mahates: 'Bolívar',
	Magangué: 'Bolívar',
	Madrid: 'Madrid',
	Luruaco: 'Atlántico',
	'Los Patios': 'Norte de Santander Department',
	Lorica: 'Córdoba',
	Líbano: 'Tolima',
	Leticia: 'Amazonas',
	Lérida: 'Tolima',
	'La Virginia': 'Risaralda',
	'La Tebaida': 'Quindío',
	'La Mesa': 'California',
	'La Jagua de Ibirico': 'Cesar',
	'La Estrella': 'Antioquia',
	'La Dorada': 'Caldas Department',
	'La Ceja': 'Antioquia',
	'Juan de Acosta': 'Atlántico',
	Jamundí: 'Valle del Cauca',
	Ituango: 'Antioquia',
	Itagüí: 'Antioquia',
	Ipiales: 'Nariño',
	Ibagué: 'Tolima',
	Honda: 'Tolima',
	Guamo: 'Tolima',
	Guamal: 'Magdalena',
	Guaduas: 'Cundinamarca',
	Guadalupe: 'La Libertad',
	Guacarí: 'Valle del Cauca',
	Granada: 'Granada Department',
	Girón: 'Santander',
	'Girardot City': 'Cundinamarca',
	Gigante: 'Huila',
	Garzón: 'Huila',
	Galapa: 'Atlántico',
	Fusagasugá: 'Cundinamarca',
	Funza: 'Cundinamarca',
	Fundación: 'Magdalena',
	Frontino: 'Antioquia',
	Fresno: 'California',
	Fonseca: 'La Guajira Department',
	Floridablanca: 'Santander',
	Florida: 'Florida',
	Florencia: 'Ciego de Ávila Province',
	Flandes: 'Tolima',
	Facatativá: 'Cundinamarca',
	Espinal: 'Tolima',
	Envigado: 'Antioquia',
	'El Zulia': 'Norte de Santander Department',
	'El Retén': 'Magdalena',
	'El Doncello': 'Caquetá',
	'El Copey': 'Cesar',
	'El Charco': 'Nariño',
	'El Cerrito': 'California',
	'El Carmen de Bolívar': 'Bolívar',
	'El Carmen de Chucurí': 'Santander',
	'El Banco': 'Magdalena',
	'El Bagre': 'Antioquia',
	Duitama: 'Boyacá',
	Dosquebradas: 'Risaralda',
	Darien: 'Illinois',
	Dabeiba: 'Antioquia',
	Curumaní: 'Cesar',
	Cumaribo: 'Vichada',
	Cúcuta: 'Norte de Santander Department',
	Cotorra: 'Córdoba',
	Corozal: 'Sucre',
	Copacabana: 'Antioquia',
	Circasia: 'Quindío',
	Cimitarra: 'Santander',
	'Ciénaga de Oro': 'Córdoba',
	Ciénaga: 'Magdalena',
	Chivolo: 'Magdalena',
	Chiriguaná: 'Cesar',
	Chiquinquirá: 'Zulia',
	Chinú: 'Córdoba',
	Chinchiná: 'Caldas Department',
	Chimichagua: 'Cesar',
	Chigorodó: 'Antioquia',
	Chía: 'Cundinamarca',
	Chaparral: 'Tolima',
	Cereté: 'Córdoba',
	Caucasia: 'Antioquia',
	Cartago: 'Cartago Province',
	'Carmen de Viboral': 'Antioquia',
	Candelaria: 'Toa Baja',
	'Campo de la Cruz': 'Atlántico',
	Campoalegre: 'Huila',
	Cali: 'Valle del Cauca',
	Caldas: 'Antioquia',
	Calarcá: 'Quindío',
	Cajicá: 'Cundinamarca',
	Caicedonia: 'Valle del Cauca',
	'Guadalajara de Buga': 'Valle del Cauca',
	Buesaco: 'Nariño',
	Buenaventura: 'Valle del Cauca',
	Bucaramanga: 'Santander',
	Bogotá: 'Bogota D.C.',
	Betulia: 'Antioquia',
	Bello: 'Antioquia',
	'Belén de Umbría': 'Risaralda',
	Becerril: 'Cesar',
	Barranquilla: 'Atlántico',
	Barrancas: 'Monagas',
	Barrancabermeja: 'Santander',
	Barbosa: 'Santander',
	Baranoa: 'Atlántico',
	Balboa: 'Cauca',
	Ayapel: 'Córdoba',
	Astrea: 'Cesar',
	Armenia: 'Quindío',
	Arjona: 'Bolívar',
	Ariguaní: 'Cesar',
	Arauca: 'Departamento de Arauca',
	Aracataca: 'Magdalena',
	Apartadó: 'Antioquia',
	'Santa Fe de Antioquia': 'Antioquia',
	Anserma: 'Risaralda',
	Andes: 'Antioquia',
	Andalucía: 'Valle del Cauca',
	Anaime: 'Tolima',
	Albania: 'La Guajira Department',
	Aguazul: 'Casanare Department',
	Aguadas: 'Caldas Department',
	Aguachica: 'Cesar',
	Acacías: 'Meta',
	Abejorral: 'Antioquia',
	'Villa Rica': 'Cauca',
	Fredonia: 'Antioquia',
	Carepa: 'Antioquia',
	'Ciudad Bolívar': 'Bolívar',
	Tiquisio: 'Bolívar',
	'Agustín Codazzi': 'Cesar',
	Isnos: 'Huila',
	Plato: 'Magdalena',
	'San José del Guaviare': 'Guaviare',
	Orito: 'Putumayo',
	Piendamo: 'Cauca',
	Turrialba: 'Cartago Province',
	Tejar: 'San José',
	Siquirres: 'Limón Province',
	'San Rafael Arriba': 'San José',
	'San Rafael Abajo': 'San José',
	'San Juan de Dios': 'San José',
	'San José': 'San José',
	Quesada: 'Alajuela Province',
	Purral: 'San José',
	Puntarenas: 'Puntarenas Province',
	Limón: 'Limón Province',
	Patarrá: 'San José',
	Paraíso: 'Cartago Province',
	Nicoya: 'Guanacaste Province',
	'Mercedes Norte': 'Heredia Province',
	Liberia: 'Guanacaste Province',
	Ipís: 'San José',
	Heredia: 'Heredia Province',
	Guápiles: 'Limón Province',
	Esparza: 'Puntarenas Province',
	Curridabat: 'San José',
	Colima: 'Colima',
	Chacarita: 'Puntarenas Province',
	Cañas: 'Guanacaste Province',
	'Calle Blancos': 'San José',
	Aserrí: 'San José',
	Alajuela: 'Alajuela Province',
	'San Vicente de Moravia': 'San José',
	Yara: 'Granma Province',
	Yaguajay: 'Sancti Spíritus Province',
	Viñales: 'Pinar del Río',
	Vertientes: 'Camagüey',
	Venezuela: 'Ciego de Ávila Province',
	Varadero: 'Matanzas Province',
	'San Germán': 'Holguín Province',
	'Unión de Reyes': 'Matanzas Province',
	Sibanicú: 'Camagüey',
	'Santo Domingo': 'Nacional',
	'Santiago de las Vegas': 'Havana',
	'Santiago de Cuba': 'Santiago de Cuba',
	'Santa Cruz del Sur': 'Camagüey',
	'Santa Cruz del Norte': 'Mayabeque',
	'Santa Clara': 'California',
	'San Miguel del Padrón': 'Havana',
	'San José de las Lajas': 'Mayabeque',
	'Sancti Spíritus': 'Sancti Spíritus Province',
	'San Cristobal': 'Artemisa',
	'San Antonio de los Baños': 'Artemisa',
	'Sagua la Grande': 'Villa Clara Province',
	'Sagua de Tánamo': 'Holguín Province',
	Rodas: 'Cienfuegos Province',
	'Río Guayabal de Yateras': 'Guantánamo Province',
	'Río Cauto': 'Granma Province',
	Remedios: 'Villa Clara Province',
	Regla: 'Havana',
	Ranchuelo: 'Villa Clara Province',
	'Puerto Padre': 'Las Tunas',
	'Primero de Enero': 'Ciego de Ávila Province',
	Placetas: 'Villa Clara Province',
	'Pinar del Río': 'Pinar del Río',
	Perico: 'Matanzas Province',
	'Pedro Betancourt': 'Matanzas Province',
	'Palma Soriano': 'Santiago de Cuba',
	Nuevitas: 'Camagüey',
	'Nueva Gerona': 'Isla de la Juventud',
	Niquero: 'Granma Province',
	Moa: 'Holguín Province',
	'Minas de Matahambre': 'Pinar del Río',
	Minas: 'Lavalleja',
	'Media Luna': 'Granma Province',
	Matanzas: 'Matanzas Province',
	Mariel: 'Artemisa',
	Manzanillo: 'Colima',
	Manicaragua: 'Villa Clara Province',
	Maisí: 'Guantánamo Province',
	Madruga: 'Mayabeque',
	'Los Palacios': 'Pinar del Río',
	Limonar: 'Matanzas Province',
	'Las Tunas': 'Las Tunas',
	'La Sierpe': 'Sancti Spíritus Province',
	'La Salud': 'Mayabeque',
	Lajas: 'Cienfuegos Province',
	Havana: 'Havana',
	Jovellanos: 'Matanzas Province',
	Jobabo: 'Las Tunas',
	Jiguaní: 'Granma Province',
	'Jesús Menéndez': 'Las Tunas',
	Jatibonico: 'Sancti Spíritus Province',
	Jaruco: 'Mayabeque',
	'Jagüey Grande': 'Matanzas Province',
	Holguín: 'Holguín Province',
	Guisa: 'Granma Province',
	'Güira de Melena': 'Artemisa',
	Güines: 'Mayabeque',
	Guantánamo: 'Guantánamo Province',
	Guane: 'Pinar del Río',
	Guanajay: 'Artemisa',
	Guanabacoa: 'Havana',
	Guáimaro: 'Camagüey',
	Gibara: 'Holguín Province',
	Fomento: 'Sancti Spíritus Province',
	Esmeralda: 'Camagüey',
	Encrucijada: 'Villa Clara Province',
	Cumanayagua: 'Cienfuegos Province',
	Cueto: 'Holguín Province',
	Cruces: 'Cienfuegos Province',
	Corralillo: 'Villa Clara Province',
	Contramaestre: 'Santiago de Cuba',
	'Consolación del Sur': 'Pinar del Río',
	Colón: 'Táchira',
	Colombia: 'Las Tunas',
	'Ciro Redondo': 'Ciego de Ávila Province',
	Cifuentes: 'Villa Clara Province',
	Cienfuegos: 'Cienfuegos Province',
	'Ciego de Ávila': 'Ciego de Ávila Province',
	Chambas: 'Ciego de Ávila Province',
	Cerro: 'Havana',
	'Cauto Cristo': 'Granma Province',
	Cárdenas: 'Tabasco',
	Campechuela: 'Granma Province',
	Camajuaní: 'Villa Clara Province',
	Camagüey: 'Camagüey',
	Calimete: 'Matanzas Province',
	Caibarién: 'Villa Clara Province',
	Cacocum: 'Holguín Province',
	Cabaiguán: 'Sancti Spíritus Province',
	Bejucal: 'Mayabeque',
	Bayamo: 'Granma Province',
	Bauta: 'Artemisa',
	'Bartolomé Masó': 'Granma Province',
	Baraguá: 'Ciego de Ávila Province',
	Baracoa: 'Guantánamo Province',
	Banes: 'Holguín Province',
	'Bahía Honda': 'Artemisa',
	Artemisa: 'Artemisa',
	'Arroyo Naranjo': 'Havana',
	Amancio: 'Las Tunas',
	Alquízar: 'Artemisa',
	Alamar: 'Havana',
	'Aguada de Pasajeros': 'Cienfuegos Province',
	Abreus: 'Cienfuegos Province',
	'Ciudad Camilo Cienfuegos': 'Havana',
	'Centro Habana': 'Havana',
	'La Habana Vieja': 'Havana',
	Jimaguayú: 'Camagüey',
	Boyeros: 'Havana',
	'Diez de Octubre': 'Havana',
	Praia: 'Praia',
	Mindelo: 'São Vicente',
	'Cova Figueira': 'Santa Catarina do Fogo',
	Willemstad: '',
	'Flying Fish Cove': '',
	Protaras: 'Ammochostos',
	Stróvolos: 'Nicosia',
	Paphos: 'Pafos',
	Nicosia: 'Nicosia',
	Mórfou: 'Nicosia',
	Limassol: 'Limassol',
	Larnaca: 'Larnaka',
	Kyrenia: 'Keryneia',
	Famagusta: 'Ammochostos',
	'Dvůr Králové nad Labem': 'Královéhradecký kraj',
	Znojmo: 'South Moravian',
	Zlín: 'Zlín',
	'Žďár nad Sázavou': 'Vysočina',
	Žatec: 'Ústecký kraj',
	Vyškov: 'South Moravian',
	Vsetín: 'Zlín',
	Varnsdorf: 'Ústecký kraj',
	'Valašské Meziříčí': 'Zlín',
	'Ústí nad Orlicí': 'Pardubický',
	'Ústí nad Labem': 'Ústecký kraj',
	'Uherský Brod': 'Zlín',
	'Uherské Hradiště': 'Zlín',
	Trutnov: 'Královéhradecký kraj',
	Třinec: 'Moravskoslezský',
	Třebíč: 'Vysočina',
	Teplice: 'Ústecký kraj',
	Tábor: 'Jihočeský kraj',
	Svitavy: 'Pardubický',
	Šumperk: 'Olomoucký',
	Strakonice: 'Jihočeský kraj',
	'Starý Bohumín': 'Moravskoslezský',
	Sokolov: 'Karlovarský kraj',
	Slaný: 'Central Bohemia',
	'Rožnov pod Radhoštěm': 'Zlín',
	Rakovník: 'Central Bohemia',
	Prostějov: 'Olomoucký',
	Prosek: 'Prague',
	Příbram: 'Central Bohemia',
	Přerov: 'Olomoucký',
	Prague: 'Prague',
	Pilsen: 'Plzeň Region',
	Písek: 'Jihočeský kraj',
	Pelhřimov: 'Vysočina',
	Pardubice: 'Pardubický',
	Otrokovice: 'Zlín',
	Ostrov: 'Pskov Oblast',
	Ostrava: 'Moravskoslezský',
	Orlová: 'Moravskoslezský',
	Opava: 'Moravskoslezský',
	Olomouc: 'Olomoucký',
	'Nový Jičín': 'Moravskoslezský',
	Neratovice: 'Central Bohemia',
	Náchod: 'Královéhradecký kraj',
	Most: 'Ústecký kraj',
	Modřany: 'Prague',
	'Mladá Boleslav': 'Central Bohemia',
	Mělník: 'Central Bohemia',
	Louny: 'Ústecký kraj',
	Litvínov: 'Ústecký kraj',
	Litoměřice: 'Ústecký kraj',
	Liberec: 'Liberecký kraj',
	Libeň: 'Prague',
	Letňany: 'Prague',
	'Kutná Hora': 'Central Bohemia',
	Kroměříž: 'Zlín',
	Krnov: 'Moravskoslezský',
	'Kralupy nad Vltavou': 'Central Bohemia',
	Kopřivnice: 'Moravskoslezský',
	Kolín: 'Central Bohemia',
	Klatovy: 'Plzeň Region',
	'Klášterec nad Ohří': 'Ústecký kraj',
	Kladno: 'Central Bohemia',
	Karviná: 'Moravskoslezský',
	'Karlovy Vary': 'Karlovarský kraj',
	Kadaň: 'Ústecký kraj',
	Jirkov: 'Ústecký kraj',
	'Jindřichův Hradec': 'Jihočeský kraj',
	Jihlava: 'Vysočina',
	Jičín: 'Královéhradecký kraj',
	'Jablonec nad Nisou': 'Liberecký kraj',
	Hranice: 'Olomoucký',
	'Hradec Králové': 'Královéhradecký kraj',
	Hodonín: 'South Moravian',
	'Havlíčkův Brod': 'Vysočina',
	Havířov: 'Moravskoslezský',
	'Frýdek-Místek': 'Moravskoslezský',
	Děčín: 'Ústecký kraj',
	Chrudim: 'Pardubický',
	Chomutov: 'Ústecký kraj',
	Cheb: 'Karlovarský kraj',
	'Český Těšín': 'Moravskoslezský',
	'České Budějovice': 'Jihočeský kraj',
	'Česká Třebová': 'Pardubický',
	'Česká Lípa': 'Liberecký kraj',
	Bruntál: 'Moravskoslezský',
	Brno: 'South Moravian',
	Břeclav: 'South Moravian',
	Braník: 'Prague',
	'Brandýs nad Labem-Stará Boleslav': 'Central Bohemia',
	Bohumín: 'Moravskoslezský',
	Blansko: 'South Moravian',
	Bílina: 'Ústecký kraj',
	Beroun: 'Central Bohemia',
	Benešov: 'Central Bohemia',
	'Černý Most': 'Prague',
	Zwickau: 'Saxony',
	Zweibrücken: 'Rheinland-Pfalz',
	Zülpich: 'North Rhine-Westphalia',
	Zossen: 'Brandenburg',
	Zittau: 'Saxony',
	Zirndorf: 'Bavaria',
	Zerbst: 'Saxony-Anhalt',
	Zeitz: 'Saxony-Anhalt',
	Zehlendorf: 'Berlin',
	Xanten: 'North Rhine-Westphalia',
	Wurzen: 'Saxony',
	Würzburg: 'Bavaria',
	Würselen: 'North Rhine-Westphalia',
	Wuppertal: 'North Rhine-Westphalia',
	Wunstorf: 'Lower Saxony',
	Wülfrath: 'North Rhine-Westphalia',
	'Wörth am Rhein': 'Rheinland-Pfalz',
	Worms: 'Rheinland-Pfalz',
	Wolfsburg: 'Lower Saxony',
	Wolfratshausen: 'Bavaria',
	Wolfenbüttel: 'Lower Saxony',
	Wolfen: 'Saxony-Anhalt',
	Witzenhausen: 'Hesse',
	Wittstock: 'Brandenburg',
	Wittmund: 'Lower Saxony',
	Wittlich: 'Rheinland-Pfalz',
	Wittenberge: 'Brandenburg',
	Wittenau: 'Berlin',
	Witten: 'North Rhine-Westphalia',
	Wismar: 'Mecklenburg-Vorpommern',
	Wipperfürth: 'North Rhine-Westphalia',
	Winterhude: 'Hamburg',
	Winsen: 'Lower Saxony',
	Winnenden: 'Baden-Wurttemberg',
	Wilnsdorf: 'North Rhine-Westphalia',
	Wilmersdorf: 'Berlin',
	Willich: 'North Rhine-Westphalia',
	Wilhelmstadt: 'Berlin',
	Wilhelmshaven: 'Lower Saxony',
	Wilhelmsburg: 'Hamburg',
	Wildeshausen: 'Lower Saxony',
	Wiesloch: 'Baden-Wurttemberg',
	Wiesbaden: 'Hesse',
	Wiehl: 'North Rhine-Westphalia',
	Wetzlar: 'Hesse',
	Wetter: 'North Rhine-Westphalia',
	Westerstede: 'Lower Saxony',
	Westend: 'Berlin',
	Wesseling: 'North Rhine-Westphalia',
	Wesel: 'North Rhine-Westphalia',
	Wertheim: 'Baden-Wurttemberg',
	Wersten: 'North Rhine-Westphalia',
	Wernigerode: 'Saxony-Anhalt',
	Werne: 'North Rhine-Westphalia',
	Wermelskirchen: 'North Rhine-Westphalia',
	Werl: 'North Rhine-Westphalia',
	Werdohl: 'North Rhine-Westphalia',
	Werder: 'Brandenburg',
	Werdau: 'Saxony',
	'Wendlingen am Neckar': 'Baden-Wurttemberg',
	Wenden: 'North Rhine-Westphalia',
	Wendelstein: 'Bavaria',
	Weiterstadt: 'Hesse',
	Weißwasser: 'Saxony',
	Weißenfels: 'Saxony-Anhalt',
	'Weißenburg in Bayern': 'Bavaria',
	'Weinstadt-Endersbach': 'Baden-Wurttemberg',
	Weinheim: 'Baden-Wurttemberg',
	Weingarten: 'Baden-Wurttemberg',
	Weimar: 'Thuringia',
	Weilheim: 'Bavaria',
	Weilerswist: 'North Rhine-Westphalia',
	'Weil der Stadt': 'Baden-Wurttemberg',
	'Weil am Rhein': 'Baden-Wurttemberg',
	Weiden: 'Bavaria',
	Weißensee: 'Berlin',
	Wegberg: 'North Rhine-Westphalia',
	Weener: 'Lower Saxony',
	Wedel: 'Schleswig-Holstein',
	Wedding: 'Berlin',
	Wassenberg: 'North Rhine-Westphalia',
	Warstein: 'North Rhine-Westphalia',
	Warendorf: 'North Rhine-Westphalia',
	Waren: 'Mecklenburg-Vorpommern',
	Wardenburg: 'Lower Saxony',
	Warburg: 'North Rhine-Westphalia',
	Wangen: 'Baden-Wurttemberg',
	Wandlitz: 'Brandenburg',
	Waltrop: 'North Rhine-Westphalia',
	Walsrode: 'Lower Saxony',
	Wallenhorst: 'Lower Saxony',
	'Waldshut-Tiengen': 'Baden-Wurttemberg',
	Waldkraiburg: 'Bavaria',
	Waldkirch: 'Baden-Wurttemberg',
	Waldbröl: 'North Rhine-Westphalia',
	Waiblingen: 'Baden-Wurttemberg',
	Waghäusel: 'Baden-Wurttemberg',
	Wadgassen: 'Saarland',
	Wadern: 'Saarland',
	Wachtberg: 'North Rhine-Westphalia',
	Vreden: 'North Rhine-Westphalia',
	Volksdorf: 'Hamburg',
	Völklingen: 'Saarland',
	Voerde: 'North Rhine-Westphalia',
	Vlotho: 'North Rhine-Westphalia',
	Vilshofen: 'Bavaria',
	'Villingen-Schwenningen': 'Baden-Wurttemberg',
	Viersen: 'North Rhine-Westphalia',
	Viernheim: 'Hesse',
	Versmold: 'North Rhine-Westphalia',
	Verl: 'North Rhine-Westphalia',
	Verden: 'Lower Saxony',
	Velbert: 'North Rhine-Westphalia',
	Vegesack: 'Bremen',
	Vechta: 'Lower Saxony',
	Vechelde: 'Lower Saxony',
	Vaterstetten: 'Bavaria',
	Varel: 'Lower Saxony',
	'Vaihingen an der Enz': 'Baden-Wurttemberg',
	Uetersen: 'Schleswig-Holstein',
	Uslar: 'Lower Saxony',
	Unterschleißheim: 'Bavaria',
	Unterhaching: 'Bavaria',
	Unterkrozingen: 'Baden-Wurttemberg',
	Unna: 'North Rhine-Westphalia',
	Ulm: 'Baden-Wurttemberg',
	Uerdingen: 'North Rhine-Westphalia',
	Uelzen: 'Lower Saxony',
	Überlingen: 'Baden-Wurttemberg',
	'Übach-Palenberg': 'North Rhine-Westphalia',
	Tuttlingen: 'Baden-Wurttemberg',
	Tübingen: 'Baden-Wurttemberg',
	Trossingen: 'Baden-Wurttemberg',
	Troisdorf: 'North Rhine-Westphalia',
	Trier: 'Rheinland-Pfalz',
	Traunstein: 'Bavaria',
	Traunreut: 'Bavaria',
	Torgau: 'Saxony',
	Tönisvorst: 'North Rhine-Westphalia',
	Tettnang: 'Baden-Wurttemberg',
	Templin: 'Brandenburg',
	Tempelhof: 'Berlin',
	Teltow: 'Brandenburg',
	Telgte: 'North Rhine-Westphalia',
	Tegel: 'Berlin',
	Taunusstein: 'Hesse',
	Taufkirchen: 'Bavaria',
	Syke: 'Lower Saxony',
	Sundern: 'North Rhine-Westphalia',
	'Sulzbach-Rosenberg': 'Bavaria',
	Sulzbach: 'Saarland',
	Suhl: 'Thuringia',
	Stuttgart: 'Baden-Wurttemberg',
	Stuhr: 'Lower Saxony',
	Strausberg: 'Brandenburg',
	Straubing: 'Bavaria',
	Stralsund: 'Mecklenburg-Vorpommern',
	Straelen: 'North Rhine-Westphalia',
	Stolberg: 'North Rhine-Westphalia',
	Stockelsdorf: 'Schleswig-Holstein',
	Stockach: 'Baden-Wurttemberg',
	Stendal: 'Saxony-Anhalt',
	Stellingen: 'Hamburg',
	Steinhagen: 'North Rhine-Westphalia',
	Steinfurt: 'North Rhine-Westphalia',
	Steilshoop: 'Hamburg',
	Steglitz: 'Berlin',
	Staßfurt: 'Saxony-Anhalt',
	Starnberg: 'Bavaria',
	Stadtlohn: 'North Rhine-Westphalia',
	Stadthagen: 'Lower Saxony',
	Stadtallendorf: 'Hesse',
	Stade: 'Lower Saxony',
	Staaken: 'Berlin',
	Sprockhövel: 'North Rhine-Westphalia',
	Springe: 'Lower Saxony',
	Speyer: 'Rheinland-Pfalz',
	Spenge: 'North Rhine-Westphalia',
	Sonthofen: 'Bavaria',
	Sonneberg: 'Thuringia',
	Sondershausen: 'Thuringia',
	Sömmerda: 'Thuringia',
	Soltau: 'Lower Saxony',
	Solingen: 'North Rhine-Westphalia',
	Soest: 'Utrecht',
	Sinzig: 'Rheinland-Pfalz',
	Sinsheim: 'Baden-Wurttemberg',
	Singen: 'Baden-Wurttemberg',
	Sindelfingen: 'Baden-Wurttemberg',
	Simmerath: 'North Rhine-Westphalia',
	Sigmaringen: 'Baden-Wurttemberg',
	Siegen: 'North Rhine-Westphalia',
	Siegburg: 'North Rhine-Westphalia',
	Langenhorn: 'Hamburg',
	Senftenberg: 'Brandenburg',
	Senden: 'Bavaria',
	Selm: 'North Rhine-Westphalia',
	Seligenstadt: 'Hesse',
	Selb: 'Bavaria',
	Sehnde: 'Lower Saxony',
	Seevetal: 'Lower Saxony',
	Seesen: 'Lower Saxony',
	Seelze: 'Lower Saxony',
	Schwetzingen: 'Baden-Wurttemberg',
	Schwerte: 'North Rhine-Westphalia',
	Schwerin: 'Mecklenburg-Vorpommern',
	Schwelm: 'North Rhine-Westphalia',
	Schweinfurt: 'Bavaria',
	'Schwedt (Oder)': 'Brandenburg',
	Schwarzenberg: 'Saxony',
	Schwanewede: 'Lower Saxony',
	'Schwandorf in Bayern': 'Bavaria',
	Schwalmtal: 'North Rhine-Westphalia',
	Schwalmstadt: 'Hesse',
	Schwalbach: 'Saarland',
	'Schwäbisch Hall': 'Baden-Wurttemberg',
	'Schwäbisch Gmünd': 'Baden-Wurttemberg',
	Schwabach: 'Bavaria',
	Schrobenhausen: 'Bavaria',
	Schramberg: 'Baden-Wurttemberg',
	Schortens: 'Lower Saxony',
	Schorndorf: 'Baden-Wurttemberg',
	Schopfheim: 'Baden-Wurttemberg',
	Schöneberg: 'Berlin',
	Schönebeck: 'Saxony-Anhalt',
	Schneverdingen: 'Lower Saxony',
	Schnelsen: 'Hamburg',
	Schneeberg: 'Saxony',
	Schmelz: 'Saarland',
	Schmargendorf: 'Berlin',
	Schmallenberg: 'North Rhine-Westphalia',
	Schmalkalden: 'Thuringia',
	Schlüchtern: 'Hesse',
	Schleswig: 'Schleswig-Holstein',
	Schkeuditz: 'Saxony',
	Schiffweiler: 'Saarland',
	Schifferstadt: 'Rheinland-Pfalz',
	'Bad Saulgau': 'Baden-Wurttemberg',
	Sasel: 'Hamburg',
	Sarstedt: 'Lower Saxony',
	'Sankt Wendel': 'Saarland',
	'Sankt Ingbert': 'Saarland',
	'Sankt Augustin': 'North Rhine-Westphalia',
	Sangerhausen: 'Saxony-Anhalt',
	Salzwedel: 'Saxony-Anhalt',
	Salzkotten: 'North Rhine-Westphalia',
	Salzgitter: 'Lower Saxony',
	Saarlouis: 'Saarland',
	Saarbrücken: 'Saarland',
	Saalfeld: 'Thuringia',
	Rüsselsheim: 'Hesse',
	Rummelsburg: 'Berlin',
	Rudow: 'Berlin',
	Rudolstadt: 'Thuringia',
	Rottweil: 'Baden-Wurttemberg',
	Rottenburg: 'Baden-Wurttemberg',
	Rotherbaum: 'Hamburg',
	Roth: 'Bavaria',
	Rotenburg: 'Lower Saxony',
	Rostock: 'Mecklenburg-Vorpommern',
	Rösrath: 'North Rhine-Westphalia',
	Rosenheim: 'Bavaria',
	Ronnenberg: 'Lower Saxony',
	Rissen: 'Hamburg',
	Rinteln: 'Lower Saxony',
	Rietberg: 'North Rhine-Westphalia',
	Riesa: 'Saxony',
	Riegelsberg: 'Saarland',
	'Ribnitz-Damgarten': 'Mecklenburg-Vorpommern',
	Rheinhausen: 'North Rhine-Westphalia',
	Rheinfelden: 'Baden-Wurttemberg',
	Rheine: 'North Rhine-Westphalia',
	Rheinberg: 'North Rhine-Westphalia',
	Rheinbach: 'North Rhine-Westphalia',
	Rhede: 'North Rhine-Westphalia',
	'Rheda-Wiedenbrück': 'North Rhine-Westphalia',
	Reutlingen: 'Baden-Wurttemberg',
	Renningen: 'Baden-Wurttemberg',
	Rendsburg: 'Schleswig-Holstein',
	Remscheid: 'North Rhine-Westphalia',
	Remagen: 'Rheinland-Pfalz',
	Reinickendorf: 'Berlin',
	Reinheim: 'Hesse',
	Reinbek: 'Schleswig-Holstein',
	'Reichenbach/Vogtland': 'Saxony',
	Regensburg: 'Bavaria',
	Rees: 'North Rhine-Westphalia',
	Recklinghausen: 'North Rhine-Westphalia',
	Ravensburg: 'Baden-Wurttemberg',
	Ratingen: 'North Rhine-Westphalia',
	Rathenow: 'Brandenburg',
	Ratekau: 'Schleswig-Holstein',
	Rastede: 'Lower Saxony',
	Rastatt: 'Baden-Wurttemberg',
	Rahlstedt: 'Hamburg',
	Rahden: 'North Rhine-Westphalia',
	Radolfzell: 'Baden-Wurttemberg',
	Radevormwald: 'North Rhine-Westphalia',
	Radebeul: 'Saxony',
	Radeberg: 'Saxony',
	Quickborn: 'Schleswig-Holstein',
	Quedlinburg: 'Saxony-Anhalt',
	Püttlingen: 'Saarland',
	Pulheim: 'North Rhine-Westphalia',
	Puchheim: 'Bavaria',
	'Prenzlauer Berg': 'Berlin',
	Prenzlau: 'Brandenburg',
	Preetz: 'Schleswig-Holstein',
	Potsdam: 'Brandenburg',
	'Porz am Rhein': 'North Rhine-Westphalia',
	'Porta Westfalica': 'North Rhine-Westphalia',
	Poppenbüttel: 'Hamburg',
	Plettenberg: 'North Rhine-Westphalia',
	Plauen: 'Saxony',
	Pirna: 'Saxony',
	Pirmasens: 'Rheinland-Pfalz',
	Pinneberg: 'Schleswig-Holstein',
	Pfungstadt: 'Hesse',
	Pfullingen: 'Baden-Wurttemberg',
	Pforzheim: 'Baden-Wurttemberg',
	'Pfaffenhofen an der Ilm': 'Bavaria',
	Petershagen: 'North Rhine-Westphalia',
	Penzberg: 'Bavaria',
	Peine: 'Lower Saxony',
	Passau: 'Bavaria',
	Pasing: 'Bavaria',
	Parchim: 'Mecklenburg-Vorpommern',
	Papenburg: 'Lower Saxony',
	Pankow: 'Berlin',
	Paderborn: 'North Rhine-Westphalia',
	Oyten: 'Lower Saxony',
	Overath: 'North Rhine-Westphalia',
	Ottweiler: 'Saarland',
	'Ottobrunn bei München': 'Bavaria',
	Ottensen: 'Hamburg',
	Othmarschen: 'Hamburg',
	'Osterholz-Scharmbeck': 'Lower Saxony',
	Osnabrück: 'Lower Saxony',
	Osdorf: 'Hamburg',
	Oschersleben: 'Saxony-Anhalt',
	Oschatz: 'Saxony',
	Oranienburg: 'Brandenburg',
	Opladen: 'North Rhine-Westphalia',
	Olsberg: 'North Rhine-Westphalia',
	Olpe: 'North Rhine-Westphalia',
	Oldenburg: 'Lower Saxony',
	Olching: 'Bavaria',
	Öhringen: 'Baden-Wurttemberg',
	Offenburg: 'Baden-Wurttemberg',
	Offenbach: 'Hesse',
	Oerlinghausen: 'North Rhine-Westphalia',
	'Oer-Erkenschwick': 'North Rhine-Westphalia',
	Oelde: 'North Rhine-Westphalia',
	Odenthal: 'North Rhine-Westphalia',
	Ochtrup: 'North Rhine-Westphalia',
	Oberursel: 'Hesse',
	Obertshausen: 'Hesse',
	Oberschöneweide: 'Berlin',
	'Ober-Ramstadt': 'Hesse',
	Oberkirch: 'Baden-Wurttemberg',
	Oberhausen: 'North Rhine-Westphalia',
	Oberasbach: 'Bavaria',
	Nürtingen: 'Baden-Wurttemberg',
	Nürnberg: 'Bavaria',
	Nümbrecht: 'North Rhine-Westphalia',
	Nottuln: 'North Rhine-Westphalia',
	Northeim: 'Lower Saxony',
	Nördlingen: 'Bavaria',
	Nordhorn: 'Lower Saxony',
	Nordhausen: 'Thuringia',
	Norderstedt: 'Schleswig-Holstein',
	Nordenham: 'Lower Saxony',
	Norden: 'Lower Saxony',
	Nippes: 'North Rhine-Westphalia',
	Niendorf: 'Hamburg',
	Nienburg: 'Lower Saxony',
	Niederschönhausen: 'Berlin',
	Niederkrüchten: 'North Rhine-Westphalia',
	Niederkassel: 'North Rhine-Westphalia',
	'Nieder-Ingelheim': 'Rheinland-Pfalz',
	Nidderau: 'Hesse',
	Nidda: 'Hesse',
	'Neu Wulmstorf': 'Lower Saxony',
	Neuwied: 'Rheinland-Pfalz',
	'Neu-Ulm': 'Bavaria',
	Neustrelitz: 'Mecklenburg-Vorpommern',
	'Neustadt in Holstein': 'Schleswig-Holstein',
	'Neustadt bei Coburg': 'Bavaria',
	'Neustadt an der Weinstraße': 'Rheinland-Pfalz',
	'Neustadt am Rübenberge': 'Lower Saxony',
	'Neue Neustadt': 'Saxony-Anhalt',
	Neuss: 'North Rhine-Westphalia',
	Neuruppin: 'Brandenburg',
	Neunkirchen: 'Saarland',
	Neumünster: 'Schleswig-Holstein',
	'Neumarkt in der Oberpfalz': 'Bavaria',
	Neukölln: 'Berlin',
	'Neu-Isenburg': 'Hesse',
	'Neugraben-Fischbek': 'Hamburg',
	'Neufahrn bei Freising': 'Bavaria',
	Neuenhagen: 'Brandenburg',
	'Neuburg an der Donau': 'Bavaria',
	Neubrück: 'North Rhine-Westphalia',
	Neubrandenburg: 'Mecklenburg-Vorpommern',
	'Neu-Anspach': 'Hesse',
	Nettetal: 'North Rhine-Westphalia',
	Netphen: 'North Rhine-Westphalia',
	Neckarsulm: 'Baden-Wurttemberg',
	Naumburg: 'Saxony-Anhalt',
	Nauen: 'Brandenburg',
	Nagold: 'Baden-Wurttemberg',
	Munster: 'Indiana',
	Münster: 'North Rhine-Westphalia',
	'Hannoversch Münden': 'Lower Saxony',
	Munich: 'Bavaria',
	Müllheim: 'Baden-Wurttemberg',
	Mülheim: 'North Rhine-Westphalia',
	'Mühlheim am Main': 'Hesse',
	'Stuttgart Mühlhausen': 'Baden-Wurttemberg',
	Mühlhausen: 'Thuringia',
	Mühldorf: 'Bavaria',
	Mühlacker: 'Baden-Wurttemberg',
	Much: 'North Rhine-Westphalia',
	Mössingen: 'Baden-Wurttemberg',
	Mosbach: 'Baden-Wurttemberg',
	Moosburg: 'Bavaria',
	'Monheim am Rhein': 'North Rhine-Westphalia',
	Mönchengladbach: 'North Rhine-Westphalia',
	Mölln: 'Schleswig-Holstein',
	Moers: 'North Rhine-Westphalia',
	Moabit: 'Berlin',
	Mittweida: 'Saxony',
	Minden: 'North Rhine-Westphalia',
	Michelstadt: 'Hesse',
	Metzingen: 'Baden-Wurttemberg',
	Mettmann: 'North Rhine-Westphalia',
	Meschede: 'North Rhine-Westphalia',
	Merzig: 'Saarland',
	Merseburg: 'Saxony-Anhalt',
	Meppen: 'Lower Saxony',
	Menden: 'North Rhine-Westphalia',
	Memmingen: 'Bavaria',
	Melle: 'Lower Saxony',
	Meissen: 'Saxony',
	Meiningen: 'Thuringia',
	Meinerzhagen: 'North Rhine-Westphalia',
	Meiderich: 'North Rhine-Westphalia',
	Meerbusch: 'North Rhine-Westphalia',
	Meerane: 'Saxony',
	Meckenheim: 'North Rhine-Westphalia',
	Mechernich: 'North Rhine-Westphalia',
	Mayen: 'Rheinland-Pfalz',
	Marzahn: 'Berlin',
	Marsberg: 'North Rhine-Westphalia',
	Marl: 'North Rhine-Westphalia',
	Marktredwitz: 'Bavaria',
	Marktoberdorf: 'Bavaria',
	Markkleeberg: 'Saxony',
	'Märkisches Viertel': 'Berlin',
	Marienfelde: 'Berlin',
	Mariendorf: 'Berlin',
	Marienberg: 'Saxony',
	'Marburg an der Lahn': 'Hesse',
	'Marbach am Neckar': 'Baden-Wurttemberg',
	Mannheim: 'Baden-Wurttemberg',
	Mainz: 'Rheinland-Pfalz',
	Maintal: 'Hesse',
	Mahlsdorf: 'Berlin',
	Magdeburg: 'Saxony-Anhalt',
	Lurup: 'Hamburg',
	Lünen: 'North Rhine-Westphalia',
	Lüneburg: 'Lower Saxony',
	'Ludwigshafen am Rhein': 'Rheinland-Pfalz',
	Ludwigsfelde: 'Brandenburg',
	Ludwigsburg: 'Baden-Wurttemberg',
	Lüdinghausen: 'North Rhine-Westphalia',
	Lüdenscheid: 'North Rhine-Westphalia',
	Luckenwalde: 'Brandenburg',
	Lübeck: 'Schleswig-Holstein',
	Lubnjow: 'Brandenburg',
	Lübbecke: 'North Rhine-Westphalia',
	Loxstedt: 'Lower Saxony',
	Losheim: 'Saarland',
	Loschwitz: 'Saxony',
	Lörrach: 'Baden-Wurttemberg',
	'Lohr am Main': 'Bavaria',
	Lohne: 'Lower Saxony',
	Löhne: 'North Rhine-Westphalia',
	Lohmar: 'North Rhine-Westphalia',
	Löbtau: 'Saxony',
	Löbau: 'Saxony',
	Lippstadt: 'North Rhine-Westphalia',
	Lingen: 'Lower Saxony',
	Lindlar: 'North Rhine-Westphalia',
	Lindau: 'Bavaria',
	'Limburg an der Lahn': 'Hesse',
	'Limbach-Oberfrohna': 'Saxony',
	Lilienthal: 'Lower Saxony',
	Lichterfelde: 'Berlin',
	Lichtenrade: 'Berlin',
	Lichtenfels: 'Bavaria',
	Lichtenberg: 'Berlin',
	Leverkusen: 'North Rhine-Westphalia',
	Leutkirch: 'Baden-Wurttemberg',
	Leuben: 'Saxony',
	Leopoldshöhe: 'North Rhine-Westphalia',
	Leonberg: 'Baden-Wurttemberg',
	Lennestadt: 'North Rhine-Westphalia',
	Lengerich: 'North Rhine-Westphalia',
	Lemgo: 'North Rhine-Westphalia',
	Leipzig: 'Saxony',
	'Leinfelden-Echterdingen': 'Baden-Wurttemberg',
	Leimen: 'Baden-Wurttemberg',
	Leichlingen: 'North Rhine-Westphalia',
	Lehrte: 'Lower Saxony',
	Leer: 'Unity',
	Lebach: 'Saarland',
	Laupheim: 'Baden-Wurttemberg',
	'Lauf an der Pegnitz': 'Bavaria',
	Lauchhammer: 'Brandenburg',
	Lankwitz: 'Berlin',
	Langenhagen: 'Lower Saxony',
	Langenfeld: 'North Rhine-Westphalia',
	Langen: 'Hesse',
	Landshut: 'Bavaria',
	'Landsberg am Lech': 'Bavaria',
	'Landau in der Pfalz': 'Rheinland-Pfalz',
	Lampertheim: 'Hesse',
	Lahr: 'Baden-Wurttemberg',
	Lahnstein: 'Rheinland-Pfalz',
	Lage: 'North Rhine-Westphalia',
	Laatzen: 'Lower Saxony',
	'Bad Laasphe': 'North Rhine-Westphalia',
	Kürten: 'North Rhine-Westphalia',
	Künzelsau: 'Baden-Wurttemberg',
	Künzell: 'Hesse',
	Kulmbach: 'Bavaria',
	Kronberg: 'Hesse',
	Kronach: 'Bavaria',
	Kreuztal: 'North Rhine-Westphalia',
	Kreuzberg: 'Berlin',
	Kreuzau: 'North Rhine-Westphalia',
	Krefeld: 'North Rhine-Westphalia',
	Köthen: 'Saxony-Anhalt',
	Korschenbroich: 'North Rhine-Westphalia',
	Kornwestheim: 'Baden-Wurttemberg',
	Korntal: 'Baden-Wurttemberg',
	Korbach: 'Hesse',
	Köpenick: 'Berlin',
	'Berlin Köpenick': 'Berlin',
	Konz: 'Rheinland-Pfalz',
	Konstanz: 'Baden-Wurttemberg',
	'Königs Wusterhausen': 'Brandenburg',
	Königswinter: 'North Rhine-Westphalia',
	'Königstein im Taunus': 'Hesse',
	'Königslutter am Elm': 'Lower Saxony',
	Königsbrunn: 'Bavaria',
	Köln: 'North Rhine-Westphalia',
	Kolbermoor: 'Bavaria',
	Koblenz: 'Rheinland-Pfalz',
	Klotzsche: 'Saxony',
	Klingenstein: 'Baden-Wurttemberg',
	Kleve: 'North Rhine-Westphalia',
	Kleinzschocher: 'Saxony',
	Kleinmachnow: 'Brandenburg',
	Kladow: 'Berlin',
	Kitzingen: 'Bavaria',
	Kirchlengern: 'North Rhine-Westphalia',
	'Kirchheim unter Teck': 'Baden-Wurttemberg',
	Kirchhain: 'Hesse',
	Kierspe: 'North Rhine-Westphalia',
	Kiel: 'Schleswig-Holstein',
	Kevelaer: 'North Rhine-Westphalia',
	Kerpen: 'North Rhine-Westphalia',
	'Kempten (Allgäu)': 'Bavaria',
	Kempen: 'North Rhine-Westphalia',
	Kelkheim: 'Hesse',
	Kelheim: 'Bavaria',
	Kehl: 'Baden-Wurttemberg',
	Kaulsdorf: 'Berlin',
	Kaufbeuren: 'Bavaria',
	Kassel: 'Hesse',
	Karow: 'Berlin',
	Karlstadt: 'Bavaria',
	Karlsruhe: 'Baden-Wurttemberg',
	Karlshorst: 'Berlin',
	Karlsfeld: 'Bavaria',
	Karben: 'Hesse',
	'Kamp-Lintfort': 'North Rhine-Westphalia',
	Kamenz: 'Saxony',
	Kamen: 'North Rhine-Westphalia',
	Kaltenkirchen: 'Schleswig-Holstein',
	Kaiserslautern: 'Rheinland-Pfalz',
	Kaarst: 'North Rhine-Westphalia',
	Jülich: 'North Rhine-Westphalia',
	Jüchen: 'North Rhine-Westphalia',
	Johannisthal: 'Berlin',
	Jena: 'Thuringia',
	Itzehoe: 'Schleswig-Holstein',
	'Isernhagen Farster Bauerschaft': 'Lower Saxony',
	Iserlohn: 'North Rhine-Westphalia',
	Ingolstadt: 'Bavaria',
	'Ingelheim am Rhein': 'Rheinland-Pfalz',
	Ilmenau: 'Thuringia',
	Illingen: 'Saarland',
	Illertissen: 'Bavaria',
	Idstein: 'Hesse',
	'Idar-Oberstein': 'Rheinland-Pfalz',
	Ibbenbüren: 'North Rhine-Westphalia',
	Husum: 'Schleswig-Holstein',
	Hürth: 'North Rhine-Westphalia',
	Hünfeld: 'Hesse',
	Hummelsbüttel: 'Hamburg',
	Humboldtkolonie: 'North Rhine-Westphalia',
	Hude: 'Lower Saxony',
	Hückeswagen: 'North Rhine-Westphalia',
	Hückelhoven: 'North Rhine-Westphalia',
	Hoyerswerda: 'Saxony',
	Höxter: 'North Rhine-Westphalia',
	Hövelhof: 'North Rhine-Westphalia',
	Hörstel: 'North Rhine-Westphalia',
	Horn: 'Hamburg',
	'Horb am Neckar': 'Baden-Wurttemberg',
	Homburg: 'Saarland',
	Holzwickede: 'North Rhine-Westphalia',
	Holzminden: 'Lower Saxony',
	Holzkirchen: 'Bavaria',
	'Hohenstein-Ernstthal': 'Saxony',
	'Hohen Neuendorf': 'Brandenburg',
	'Hofheim am Taunus': 'Hesse',
	Hofgeismar: 'Hesse',
	Hof: 'Bavaria',
	Hockenheim: 'Baden-Wurttemberg',
	'Hochheim am Main': 'Hesse',
	Hochfeld: 'North Rhine-Westphalia',
	Hille: 'North Rhine-Westphalia',
	Hildesheim: 'Lower Saxony',
	Hilden: 'North Rhine-Westphalia',
	Hilchenbach: 'North Rhine-Westphalia',
	Hiddenhausen: 'North Rhine-Westphalia',
	Heusweiler: 'Saarland',
	Heusenstamm: 'Hesse',
	Hettstedt: 'Saxony-Anhalt',
	'Hessisch Oldendorf': 'Lower Saxony',
	Herzogenrath: 'North Rhine-Westphalia',
	Herzogenaurach: 'Bavaria',
	Herten: 'North Rhine-Westphalia',
	Herrenberg: 'Baden-Wurttemberg',
	Herne: 'North Rhine-Westphalia',
	Hermsdorf: 'Berlin',
	Herford: 'North Rhine-Westphalia',
	Herdecke: 'North Rhine-Westphalia',
	Herborn: 'Hesse',
	'Heppenheim an der Bergstrasse': 'Hesse',
	Hennigsdorf: 'Brandenburg',
	'Hennef (Sieg)': 'North Rhine-Westphalia',
	Hemmingen: 'Lower Saxony',
	Hemer: 'North Rhine-Westphalia',
	Helmstedt: 'Lower Saxony',
	Hellersdorf: 'Berlin',
	Heinsberg: 'North Rhine-Westphalia',
	'Heilbad Heiligenstadt': 'Thuringia',
	Heiligensee: 'Berlin',
	Heiligenhaus: 'North Rhine-Westphalia',
	Heilbronn: 'Baden-Wurttemberg',
	'Heidenheim an der Brenz': 'Baden-Wurttemberg',
	Heidenau: 'Saxony',
	Heidelberg: 'Gauteng',
	Heide: 'Schleswig-Holstein',
	Hechingen: 'Baden-Wurttemberg',
	Hausbruch: 'Hamburg',
	Hattingen: 'North Rhine-Westphalia',
	Hattersheim: 'Hesse',
	Haßloch: 'Rheinland-Pfalz',
	Haselhorst: 'Berlin',
	Harvestehude: 'Hamburg',
	Harsewinkel: 'North Rhine-Westphalia',
	Haren: 'Lower Saxony',
	Harburg: 'Hamburg',
	Hannover: 'Lower Saxony',
	'Hanau am Main': 'Hesse',
	Hamminkeln: 'North Rhine-Westphalia',
	Hamm: 'North Rhine-Westphalia',
	Hameln: 'Lower Saxony',
	Wandsbek: 'Hamburg',
	Marienthal: 'Hamburg',
	'Hamburg-Mitte': 'Hamburg',
	Eimsbüttel: 'Hamburg',
	Altona: 'Hamburg',
	Hamburg: 'Hamburg',
	Halver: 'North Rhine-Westphalia',
	'Haltern am See': 'North Rhine-Westphalia',
	Halstenbek: 'Schleswig-Holstein',
	'Halle (Saale)': 'Saxony-Anhalt',
	'Haldensleben I': 'Saxony-Anhalt',
	Halberstadt: 'Saxony-Anhalt',
	Hakenfelde: 'Berlin',
	Haiger: 'Hesse',
	Hagen: 'North Rhine-Westphalia',
	Haar: 'Bavaria',
	Haan: 'North Rhine-Westphalia',
	Gütersloh: 'North Rhine-Westphalia',
	Güstrow: 'Mecklenburg-Vorpommern',
	Gunzenhausen: 'Bavaria',
	Günzburg: 'Bavaria',
	Gummersbach: 'North Rhine-Westphalia',
	Guben: 'Brandenburg',
	Großzschocher: 'Saxony',
	'Groß-Umstadt': 'Hesse',
	Großostheim: 'Bavaria',
	'Groß-Gerau': 'Hesse',
	Großenhain: 'Saxony',
	Gronau: 'North Rhine-Westphalia',
	Gröbenzell: 'Bavaria',
	Grimma: 'Saxony',
	Griesheim: 'Hesse',
	Grevenbroich: 'North Rhine-Westphalia',
	Greven: 'North Rhine-Westphalia',
	Greiz: 'Thuringia',
	Greifswald: 'Mecklenburg-Vorpommern',
	Grefrath: 'North Rhine-Westphalia',
	Göttingen: 'Lower Saxony',
	Gotha: 'Thuringia',
	Goslar: 'Lower Saxony',
	Görlitz: 'Saxony',
	Gorbitz: 'Saxony',
	Göppingen: 'Baden-Wurttemberg',
	Gohlis: 'Saxony',
	Goch: 'North Rhine-Westphalia',
	Glinde: 'Schleswig-Holstein',
	Glauchau: 'Saxony',
	Gladbeck: 'North Rhine-Westphalia',
	'Ginsheim-Gustavsburg': 'Hesse',
	Gilching: 'Bavaria',
	Gifhorn: 'Lower Saxony',
	Gießen: 'Hesse',
	'Giengen an der Brenz': 'Baden-Wurttemberg',
	Gevelsberg: 'North Rhine-Westphalia',
	Gesundbrunnen: 'Berlin',
	Geseke: 'North Rhine-Westphalia',
	Gescher: 'North Rhine-Westphalia',
	Gersthofen: 'Bavaria',
	Germersheim: 'Rheinland-Pfalz',
	Germering: 'Bavaria',
	Gerlingen: 'Baden-Wurttemberg',
	Geretsried: 'Bavaria',
	Gera: 'Thuringia',
	Georgsmarienhütte: 'Lower Saxony',
	Gelsenkirchen: 'North Rhine-Westphalia',
	Gelnhausen: 'Hesse',
	Geldern: 'North Rhine-Westphalia',
	'Geislingen an der Steige': 'Baden-Wurttemberg',
	Geilenkirchen: 'North Rhine-Westphalia',
	Geesthacht: 'Schleswig-Holstein',
	Gauting: 'Bavaria',
	'Garmisch-Partenkirchen': 'Bavaria',
	Garching: 'Bavaria',
	Garbsen: 'Lower Saxony',
	Ganderkesee: 'Lower Saxony',
	Gaggenau: 'Baden-Wurttemberg',
	Füssen: 'Bavaria',
	Fürth: 'Bavaria',
	Fürstenwalde: 'Brandenburg',
	Fürstenfeldbruck: 'Bavaria',
	Fulda: 'Hesse',
	Fröndenberg: 'North Rhine-Westphalia',
	Frohnau: 'Berlin',
	Friesoythe: 'Lower Saxony',
	Friedrichshain: 'Berlin',
	Friedrichshagen: 'Berlin',
	Friedrichshafen: 'Baden-Wurttemberg',
	Friedrichsfelde: 'Berlin',
	Friedrichsdorf: 'Hesse',
	Friedenau: 'Berlin',
	Friedberg: 'Bavaria',
	Freudenstadt: 'Baden-Wurttemberg',
	Freudenberg: 'North Rhine-Westphalia',
	Freital: 'Saxony',
	Freising: 'Bavaria',
	Freilassing: 'Bavaria',
	Freiburg: 'Baden-Wurttemberg',
	'Freiberg am Neckar': 'Baden-Wurttemberg',
	Freiberg: 'Saxony',
	Frechen: 'North Rhine-Westphalia',
	'Frankfurt am Main': 'Hesse',
	'Frankfurt (Oder)': 'Brandenburg',
	Frankenthal: 'Rheinland-Pfalz',
	Frankenberg: 'Saxony',
	Forst: 'Brandenburg',
	Forchheim: 'Bavaria',
	Flörsheim: 'Hesse',
	Flensburg: 'Schleswig-Holstein',
	Fischeln: 'North Rhine-Westphalia',
	Finsterwalde: 'Brandenburg',
	'Stuttgart Feuerbach': 'Baden-Wurttemberg',
	Fellbach: 'Baden-Wurttemberg',
	Falkensee: 'Brandenburg',
	Eving: 'North Rhine-Westphalia',
	Eutin: 'Schleswig-Holstein',
	Euskirchen: 'North Rhine-Westphalia',
	Ettlingen: 'Baden-Wurttemberg',
	Esslingen: 'Baden-Wurttemberg',
	Essen: 'North Rhine-Westphalia',
	Espelkamp: 'North Rhine-Westphalia',
	Eschweiler: 'North Rhine-Westphalia',
	Eschwege: 'Hesse',
	Eschborn: 'Hesse',
	Erwitte: 'North Rhine-Westphalia',
	Erlangen: 'Bavaria',
	Erkrath: 'North Rhine-Westphalia',
	Erkelenz: 'North Rhine-Westphalia',
	Erfurt: 'Thuringia',
	Erftstadt: 'North Rhine-Westphalia',
	Erding: 'Bavaria',
	Eppingen: 'Baden-Wurttemberg',
	Eppendorf: 'Hamburg',
	Eppelborn: 'Saarland',
	Ennigerloh: 'North Rhine-Westphalia',
	Ennepetal: 'North Rhine-Westphalia',
	Enger: 'North Rhine-Westphalia',
	Engelskirchen: 'North Rhine-Westphalia',
	Emsdetten: 'North Rhine-Westphalia',
	Emmerich: 'North Rhine-Westphalia',
	Emmendingen: 'Baden-Wurttemberg',
	Emden: 'Lower Saxony',
	Eltville: 'Hesse',
	Elsdorf: 'North Rhine-Westphalia',
	Elmshorn: 'Schleswig-Holstein',
	Ellwangen: 'Baden-Wurttemberg',
	Eitorf: 'North Rhine-Westphalia',
	Eißendorf: 'Hamburg',
	Eislingen: 'Baden-Wurttemberg',
	Eisenhüttenstadt: 'Brandenburg',
	Eisenach: 'Thuringia',
	Einbeck: 'Lower Saxony',
	Eilenburg: 'Saxony',
	Eilbek: 'Hamburg',
	Ehingen: 'Baden-Wurttemberg',
	'Eggenstein-Leopoldshafen': 'Baden-Wurttemberg',
	Edewecht: 'Lower Saxony',
	Eckernförde: 'Schleswig-Holstein',
	Eberswalde: 'Brandenburg',
	'Ebersbach an der Fils': 'Baden-Wurttemberg',
	Eberbach: 'Baden-Wurttemberg',
	Düsseldorf: 'North Rhine-Westphalia',
	Düren: 'North Rhine-Westphalia',
	Dülmen: 'North Rhine-Westphalia',
	Duisburg: 'North Rhine-Westphalia',
	Duderstadt: 'Lower Saxony',
	Dresden: 'Saxony',
	Drensteinfurt: 'North Rhine-Westphalia',
	Dreieich: 'Hesse',
	Dotzheim: 'Hesse',
	Dortmund: 'North Rhine-Westphalia',
	Dorsten: 'North Rhine-Westphalia',
	Dormagen: 'North Rhine-Westphalia',
	Donauwörth: 'Bavaria',
	Donaueschingen: 'Baden-Wurttemberg',
	Döbeln: 'Saxony',
	Ditzingen: 'Baden-Wurttemberg',
	Dinslaken: 'North Rhine-Westphalia',
	Dingolfing: 'Bavaria',
	'Dillingen an der Donau': 'Bavaria',
	Dillingen: 'Saarland',
	Dillenburg: 'Hesse',
	Dietzenbach: 'Hesse',
	Diepholz: 'Lower Saxony',
	Dieburg: 'Hesse',
	Deutz: 'North Rhine-Westphalia',
	Detmold: 'North Rhine-Westphalia',
	Dessau: 'Saxony-Anhalt',
	Delmenhorst: 'Lower Saxony',
	Delitzsch: 'Saxony',
	Delbrück: 'North Rhine-Westphalia',
	Deggendorf: 'Bavaria',
	Datteln: 'North Rhine-Westphalia',
	Darmstadt: 'Hesse',
	Damme: 'Lower Saxony',
	Dahlem: 'Berlin',
	Dachau: 'Bavaria',
	Cuxhaven: 'Lower Saxony',
	Crimmitschau: 'Saxony',
	Crailsheim: 'Baden-Wurttemberg',
	Cottbus: 'Brandenburg',
	Coswig: 'Saxony',
	Coesfeld: 'North Rhine-Westphalia',
	Cloppenburg: 'Lower Saxony',
	'Clausthal-Zellerfeld': 'Lower Saxony',
	Chemnitz: 'Saxony',
	Charlottenburg: 'Berlin',
	Celle: 'Lower Saxony',
	'Castrop-Rauxel': 'North Rhine-Westphalia',
	Calw: 'Baden-Wurttemberg',
	Buxtehude: 'Lower Saxony',
	Butzbach: 'Hesse',
	Bürstadt: 'Hesse',
	Burscheid: 'North Rhine-Westphalia',
	Burghausen: 'Bavaria',
	'Burg bei Magdeburg': 'Saxony-Anhalt',
	Büren: 'North Rhine-Westphalia',
	Bünde: 'North Rhine-Westphalia',
	Bühl: 'Baden-Wurttemberg',
	Büdingen: 'Hesse',
	Buckow: 'Berlin',
	Bückeburg: 'Lower Saxony',
	'Buchholz in der Nordheide': 'Lower Saxony',
	'Französisch Buchholz': 'Berlin',
	'Buchen in Odenwald': 'Baden-Wurttemberg',
	Brühl: 'North Rhine-Westphalia',
	Brüggen: 'North Rhine-Westphalia',
	Bruckmühl: 'Bavaria',
	Bruchsal: 'Baden-Wurttemberg',
	Bruchköbel: 'Hesse',
	Britz: 'Berlin',
	Brilon: 'North Rhine-Westphalia',
	Bretten: 'Baden-Wurttemberg',
	Bremervörde: 'Lower Saxony',
	Bremerhaven: 'Bremen',
	Bremen: 'Bremen',
	Braunschweig: 'Lower Saxony',
	'Brandenburg an der Havel': 'Brandenburg',
	Bramsche: 'Lower Saxony',
	Brakel: 'North Rhine-Westphalia',
	'Brake (Unterweser)': 'Lower Saxony',
	Brackenheim: 'Baden-Wurttemberg',
	Bottrop: 'North Rhine-Westphalia',
	Bornheim: 'North Rhine-Westphalia',
	Borna: 'Saxony',
	Borken: 'North Rhine-Westphalia',
	Boppard: 'Rheinland-Pfalz',
	Bonn: 'North Rhine-Westphalia',
	Bönen: 'North Rhine-Westphalia',
	Bogenhausen: 'Bavaria',
	Bockum: 'North Rhine-Westphalia',
	Bochum: 'North Rhine-Westphalia',
	Bocholt: 'North Rhine-Westphalia',
	Böblingen: 'Baden-Wurttemberg',
	Bobingen: 'Bavaria',
	Blomberg: 'North Rhine-Westphalia',
	Blieskastel: 'Saarland',
	Blasewitz: 'Saxony',
	Blankenburg: 'Saxony-Anhalt',
	'Bitterfeld-Wolfen': 'Saxony-Anhalt',
	'Bingen am Rhein': 'Rheinland-Pfalz',
	Billstedt: 'Hamburg',
	'Bietigheim-Bissingen': 'Baden-Wurttemberg',
	Biesdorf: 'Berlin',
	Bielefeld: 'North Rhine-Westphalia',
	Biebrich: 'Hesse',
	'Biberach an der Riß': 'Baden-Wurttemberg',
	Bexbach: 'Saarland',
	Beverungen: 'North Rhine-Westphalia',
	Bernburg: 'Saxony-Anhalt',
	'Bernau bei Berlin': 'Brandenburg',
	Berlin: 'Berlin',
	Bergneustadt: 'North Rhine-Westphalia',
	Bergkamen: 'North Rhine-Westphalia',
	'Bergisch Gladbach': 'North Rhine-Westphalia',
	Bergheim: 'North Rhine-Westphalia',
	Bensheim: 'Hesse',
	Bendorf: 'Rheinland-Pfalz',
	Bedburg: 'North Rhine-Westphalia',
	Beckum: 'North Rhine-Westphalia',
	Beckingen: 'Saarland',
	Bayreuth: 'Bavaria',
	Bautzen: 'Saxony',
	Baunatal: 'Hesse',
	Baumschulenweg: 'Berlin',
	Bassum: 'Lower Saxony',
	Bamberg: 'Bavaria',
	Balingen: 'Baden-Wurttemberg',
	Baiersbronn: 'Baden-Wurttemberg',
	Baesweiler: 'North Rhine-Westphalia',
	'Bad Zwischenahn': 'Lower Saxony',
	'Bad Wildungen': 'Hesse',
	'Bad Waldsee': 'Baden-Wurttemberg',
	'Bad Vilbel': 'Hesse',
	'Bad Tölz': 'Bavaria',
	'Bad Soden am Taunus': 'Hesse',
	'Bad Segeberg': 'Schleswig-Holstein',
	'Bad Schwartau': 'Schleswig-Holstein',
	'Bad Salzungen': 'Thuringia',
	'Bad Salzuflen': 'North Rhine-Westphalia',
	'Bad Säckingen': 'Baden-Wurttemberg',
	'Bad Reichenhall': 'Bavaria',
	'Bad Rappenau': 'Baden-Wurttemberg',
	'Bad Pyrmont': 'Lower Saxony',
	'Bad Oldesloe': 'Schleswig-Holstein',
	'Bad Oeynhausen': 'North Rhine-Westphalia',
	'Bad Neustadt an der Saale': 'Bavaria',
	'Bad Neuenahr-Ahrweiler': 'Rheinland-Pfalz',
	'Bad Nauheim': 'Hesse',
	'Bad Münstereifel': 'North Rhine-Westphalia',
	'Bad Münder am Deister': 'Lower Saxony',
	'Bad Mergentheim': 'Baden-Wurttemberg',
	'Bad Lippspringe': 'North Rhine-Westphalia',
	'Bad Langensalza': 'Thuringia',
	'Bad Kreuznach': 'Rheinland-Pfalz',
	'Bad Kissingen': 'Bavaria',
	'Bad Honnef': 'North Rhine-Westphalia',
	'Bad Homburg vor der Höhe': 'Hesse',
	'Bad Hersfeld': 'Hesse',
	'Bad Harzburg': 'Lower Saxony',
	'Bad Essen': 'Lower Saxony',
	'Baden-Baden': 'Baden-Wurttemberg',
	'Bad Dürkheim': 'Rheinland-Pfalz',
	'Bad Driburg': 'North Rhine-Westphalia',
	'Bad Berleburg': 'North Rhine-Westphalia',
	'Bad Bentheim': 'Lower Saxony',
	'Bad Aibling': 'Bavaria',
	Backnang: 'Baden-Wurttemberg',
	Babenhausen: 'Hesse',
	Aurich: 'Lower Saxony',
	Augsburg: 'Bavaria',
	Auerbach: 'Saxony',
	Aue: 'Saxony',
	Attendorn: 'North Rhine-Westphalia',
	Aschersleben: 'Saxony-Anhalt',
	Ascheberg: 'North Rhine-Westphalia',
	Aschaffenburg: 'Bavaria',
	'Bad Arolsen': 'Hesse',
	Arnstadt: 'Thuringia',
	Arnsberg: 'North Rhine-Westphalia',
	Apolda: 'Thuringia',
	Aplerbeck: 'North Rhine-Westphalia',
	Ansbach: 'Bavaria',
	'Annaberg-Buchholz': 'Saxony',
	Angermünde: 'Brandenburg',
	Andernach: 'Rheinland-Pfalz',
	Amberg: 'Bavaria',
	Alzey: 'Rheinland-Pfalz',
	'Alzenau in Unterfranken': 'Bavaria',
	Altglienicke: 'Berlin',
	Altenburg: 'Thuringia',
	Altena: 'North Rhine-Westphalia',
	'Altdorf bei Nurnberg': 'Bavaria',
	Alsterdorf: 'Hamburg',
	Alsfeld: 'Hesse',
	Alsdorf: 'North Rhine-Westphalia',
	Alfter: 'North Rhine-Westphalia',
	Alfeld: 'Lower Saxony',
	Albstadt: 'Baden-Wurttemberg',
	Aichach: 'Bavaria',
	Ahrensburg: 'Schleswig-Holstein',
	Ahlen: 'North Rhine-Westphalia',
	Ahaus: 'North Rhine-Westphalia',
	Adlershof: 'Berlin',
	Achim: 'Lower Saxony',
	Achern: 'Baden-Wurttemberg',
	Aalen: 'Baden-Wurttemberg',
	Vellmar: 'Hesse',
	Aachen: 'North Rhine-Westphalia',
	'Mörfelden-Walldorf': 'Hesse',
	Riedstadt: 'Hesse',
	'Lauda-Königshofen': 'Baden-Wurttemberg',
	Filderstadt: 'Baden-Wurttemberg',
	Ostfildern: 'Baden-Wurttemberg',
	Rodgau: 'Hesse',
	Gropiusstadt: 'Berlin',
	'Seeheim-Jugenheim': 'Hesse',
	'Charlottenburg-Nord': 'Berlin',
	Mitte: 'Berlin',
	Rheinstetten: 'Baden-Wurttemberg',
	'Altstadt Sud': 'North Rhine-Westphalia',
	'Altstadt Nord': 'North Rhine-Westphalia',
	Neuehrenfeld: 'North Rhine-Westphalia',
	Bilderstöckchen: 'North Rhine-Westphalia',
	'Stuttgart-Ost': 'Baden-Wurttemberg',
	'Bochum-Hordel': 'North Rhine-Westphalia',
	'St. Pauli': 'Hamburg',
	Gallus: 'Hesse',
	Eidelstedt: 'Hamburg',
	'Eisleben Lutherstadt': 'Saxony-Anhalt',
	'Halle Neustadt': 'Saxony-Anhalt',
	Bergedorf: 'Hamburg',
	Spandau: 'Berlin',
	'Berlin Treptow': 'Berlin',
	Niederrad: 'Hesse',
	Haselbachtal: 'Saxony',
	Wittenberg: 'Saxony-Anhalt',
	'Äußere Neustadt': 'Saxony',
	'Barmbek-Nord': 'Hamburg',
	'Barmbek-Süd': 'Hamburg',
	'Farmsen-Berne': 'Hamburg',
	'Falkenhagener Feld': 'Berlin',
	'Neu-Hohenschönhausen': 'Berlin',
	'Alt-Hohenschönhausen': 'Berlin',
	Fennpfuhl: 'Berlin',
	'Hamburg-Nord': 'Hamburg',
	'Burg Unter-Falkenstein': 'Bavaria',
	'Neustadt/Nord': 'North Rhine-Westphalia',
	'Neustadt/Süd': 'North Rhine-Westphalia',
	Kalk: 'North Rhine-Westphalia',
	Rodenkirchen: 'North Rhine-Westphalia',
	Gartenstadt: 'Rheinland-Pfalz',
	'Kröpeliner-Tor-Vorstadt': 'Mecklenburg-Vorpommern',
	'Düsseldorf-Pempelfort': 'North Rhine-Westphalia',
	Burglesum: 'Bremen',
	'Altona-Altstadt': 'Hamburg',
	'Altona-Nord': 'Hamburg',
	Dulsberg: 'Hamburg',
	'Hoheluft-Ost': 'Hamburg',
	Sachsenheim: 'Baden-Wurttemberg',
	'Petershausen-West': 'Baden-Wurttemberg',
	'Kronberg Tal': 'Hesse',
	'Remseck am Neckar': 'Baden-Wurttemberg',
	Altstadt: 'Rheinland-Pfalz',
	Tadjourah: 'Tadjourah',
	Obock: 'Obock',
	Djibouti: 'Djibouti',
	'Ali Sabieh': 'Ali Sabieh',
	Vordingborg: 'Zealand',
	Viborg: 'Central Jutland',
	Vejle: 'South Denmark',
	Vanløse: 'Capital Region',
	Vallensbæk: 'Capital Region',
	Valby: 'Capital Region',
	Tønder: 'South Denmark',
	Taastrup: 'Capital Region',
	Svendborg: 'South Denmark',
	Stenløse: 'Capital Region',
	Sønderborg: 'South Denmark',
	'Solrød Strand': 'Zealand',
	Slagelse: 'Zealand',
	Skive: 'Central Jutland',
	Silkeborg: 'Central Jutland',
	Roskilde: 'Zealand',
	Rødovre: 'Capital Region',
	Ringsted: 'Zealand',
	Randers: 'Central Jutland',
	Odense: 'South Denmark',
	'Nykøbing Falster': 'Zealand',
	Nyborg: 'South Denmark',
	Næstved: 'Zealand',
	Nørresundby: 'North Denmark',
	Middelfart: 'South Denmark',
	Lillerød: 'Capital Region',
	Køge: 'Zealand',
	Copenhagen: 'Capital Region',
	Kolding: 'South Denmark',
	Kalundborg: 'Zealand',
	Ishøj: 'Capital Region',
	Hvidovre: 'Capital Region',
	Horsens: 'Central Jutland',
	Hørsholm: 'Capital Region',
	Holstebro: 'Central Jutland',
	Holbæk: 'Zealand',
	Hjørring: 'North Denmark',
	Hillerød: 'Capital Region',
	Herning: 'Central Jutland',
	Helsingør: 'Capital Region',
	Hedensted: 'Central Jutland',
	Haderslev: 'South Denmark',
	Greve: 'Zealand',
	Glostrup: 'Capital Region',
	Frederikssund: 'Capital Region',
	Frederikshavn: 'North Denmark',
	Frederiksberg: 'Capital Region',
	Fredericia: 'South Denmark',
	Farum: 'Capital Region',
	Esbjerg: 'South Denmark',
	Charlottenlund: 'Capital Region',
	Brøndbyvester: 'Capital Region',
	Birkerød: 'Capital Region',
	Ballerup: 'Capital Region',
	Århus: 'Central Jutland',
	Allerød: 'Capital Region',
	Aalborg: 'North Denmark',
	Albertslund: 'Capital Region',
	Aabenraa: 'South Denmark',
	Roseau: 'Saint George',
	'Villa Francisca': 'Nacional',
	'Villa Consuelo': 'Nacional',
	'Villa Bisonó': 'Santiago',
	'Villa Altagracia': 'San Cristóbal',
	Tamboril: 'Santiago',
	'Santiago de los Caballeros': 'Santiago',
	'Santa Cruz de El Seibo': 'El Seíbo',
	'Santa Cruz de Barahona': 'Barahona',
	'San Pedro de Macorís': 'San Pedro de Macorís',
	'San Juan de la Maguana': 'San Juan',
	'San José de Ocoa': 'San José de Ocoa',
	'San Francisco de Macorís': 'Duarte',
	'San Fernando de Monte Cristi': 'Monte Cristi',
	'Puerto Plata': 'Puerto Plata',
	'Salvaleón de Higüey': 'La Altagracia',
	Salcedo: 'Hermanas Mirabal',
	'Sabana Grande de Boyá': 'Monte Plata',
	Quisqueya: 'San Pedro de Macorís',
	'Punta Cana': 'La Altagracia',
	Neiba: 'Baoruco',
	Nagua: 'María Trinidad Sánchez',
	'Monte Plata': 'Monte Plata',
	'Monte Llano': 'Puerto Plata',
	Moca: 'Espaillat',
	Mao: 'Kanem',
	'Las Matas de Farfán': 'San Juan',
	'La Romana': 'La Romana',
	Jarabacoa: 'La Vega',
	'Hato Mayor del Rey': 'Hato Mayor',
	Dajabón: 'Dajabón',
	Cotuí: 'Sánchez Ramírez',
	Constanza: 'La Vega',
	'Concepción de La Vega': 'La Vega',
	'Ciudad Nueva': 'Nacional',
	Bonao: 'Monseñor Nouel',
	'Boca Chica': 'Santo Domingo',
	'San Cristóbal': 'Táchira',
	Bayaguana: 'Monte Plata',
	Baní: 'Peravia',
	'Bajos de Haina': 'San Cristóbal',
	Azua: 'Azua',
	'Santo Domingo Oeste': 'Santo Domingo',
	'Santo Domingo Este': 'Santo Domingo',
	Boumerdas: 'Boumerdes',
	'Zeribet el Oued': 'Biskra',
	Zeralda: 'Algiers',
	Zemoura: 'Relizane',
	Touggourt: 'Touggourt',
	Tolga: 'Biskra',
	Tlemcen: 'Tlemcen',
	'Tizi Rached': 'Tizi Ouzou',
	'Tizi Ouzou': 'Tizi Ouzou',
	'Tizi-n-Tleta': 'Tizi Ouzou',
	'Tizi Gheniff': 'Tizi Ouzou',
	Tissemsilt: 'Tissemsilt',
	Tirmitine: 'Tizi Ouzou',
	Tipaza: 'Tipaza',
	Tindouf: 'Tindouf',
	Timizart: 'Tizi Ouzou',
	Timimoun: 'Timimoun',
	'el hed': 'Béjaïa',
	Tiaret: 'Tiaret',
	'Theniet el Had': 'Tissemsilt',
	Thenia: 'Boumerdes',
	Telerghma: 'Mila',
	Tébessa: 'Tébessa',
	Tebesbest: 'Touggourt',
	'Tazoult-Lambese': 'Batna',
	Tamanrasset: 'Tamanrasset',
	Tamalous: 'Skikda',
	Taïbet: 'Touggourt',
	Tadmaït: 'Tizi Ouzou',
	'Sour el Ghozlane': 'Bouira',
	Souma: 'Blida',
	Lardjem: 'Tissemsilt',
	'Souk Ahras': 'Souk Ahras',
	Sougueur: 'Tiaret',
	Skikda: 'Skikda',
	Sig: 'Mascara',
	'Sidi Okba': 'Biskra',
	'Sidi Moussa': 'Algiers',
	'Sidi Mérouane': 'Mila',
	'Sidi Khaled': 'Ouled Djellal',
	'Sidi ech Chahmi': 'Oran',
	'Sidi Bel Abbès': 'Sidi Bel Abbès',
	'Sidi Amrane': 'El Mghair',
	'Sidi Akkacha': 'Chlef',
	'Sidi Aïssa': "M'Sila",
	'Sidi Abdelli': 'Tlemcen',
	Sfizef: 'Sidi Bel Abbès',
	Sétif: 'Sétif',
	Sedrata: 'Souk Ahras',
	Seddouk: 'Béjaïa',
	Sebdou: 'Tlemcen',
	Saoula: 'Algiers',
	'Salah Bey': 'Sétif',
	Saïda: 'Saida',
	Rouissat: 'Ouargla',
	Rouiba: 'Algiers',
	Rouached: 'Mila',
	Robbah: 'El Oued',
	Remchi: 'Tlemcen',
	Relizane: 'Relizane',
	Reguiba: 'El Oued',
	Reghaïa: 'Algiers',
	Reggane: 'Adrar',
	'Râs el Oued': 'Bordj Bou Arréridj',
	'Râs el Aïoun': 'Batna',
	'Oum el Bouaghi': 'Oum el Bouaghi',
	'Ouled Mimoun': 'Tlemcen',
	'Oued Sly': 'Chlef',
	'Oued Rhiou': 'Relizane',
	'Oued Fodda': 'Chlef',
	'Oued el Alleug': 'Blida',
	'Oued el Abtal': 'Mascara',
	Ouargla: 'Ouargla',
	Oran: 'Oran',
	Nedroma: 'Tlemcen',
	Naciria: 'Boumerdes',
	'M’sila': "M'Sila",
	Mouzaïa: 'Blida',
	Mostaganem: 'Mostaganem',
	Mila: 'Mila',
	'Metlili Chaamba': 'Ghardaia',
	Messaad: 'Djelfa',
	Meskiana: 'Oum el Bouaghi',
	'Mers el Kebir': 'Oran',
	Merouana: 'Batna',
	Melouza: "M'Sila",
	Mekla: 'Tizi Ouzou',
	'Mehdia daira de meghila': 'Tiaret',
	Megarine: 'Touggourt',
	Meftah: 'Blida',
	Médéa: 'Medea',
	Mazouna: 'Relizane',
	Mascara: 'Mascara',
	Mansourah: 'Bordj Bou Arréridj',
	Mansoûra: 'Tlemcen',
	Makouda: 'Tizi Ouzou',
	'L’Arbaa Naït Irathen': 'Tizi Ouzou',
	Larbaâ: 'Blida',
	Lakhdaria: 'Bouira',
	Laghouat: 'Laghouat',
	'Ksar el Boukhari': 'Medea',
	'Ksar Chellala': 'Tiaret',
	Kolea: 'Tipaza',
	Khenchela: 'Khenchela',
	'Khemis Miliana': 'Aïn Defla',
	'Khemis el Khechna': 'Boumerdes',
	Kerkera: 'Skikda',
	Jijel: 'Jijel',
	Djidiouia: 'Relizane',
	Ighram: 'Béjaïa',
	Hennaya: 'Tlemcen',
	Héliopolis: 'Guelma',
	'Hassi Messaoud': 'Ouargla',
	Hammamet: 'Nabeul Governorate',
	'Hammam Bou Hadjar': 'Aïn Témouchent',
	'Hamma Bouziane': 'Constantine',
	Hadjout: 'Tipaza',
	Guelma: 'Guelma',
	Ghardaïa: 'Ghardaia',
	Frenda: 'Tiaret',
	Freha: 'Tizi Ouzou',
	Feraoun: 'Béjaïa',
	'Es Senia': 'Oran',
	'El Tarf': 'El Tarf',
	'El Oued': 'El Oued',
	'El Meghaier': 'El Mghair',
	'El Malah': 'Aïn Témouchent',
	'El Kseur': 'Béjaïa',
	'El Khroub': 'Constantine',
	'El Kala': 'El Tarf',
	'El Idrissia': 'Djelfa',
	'El Hadjira': 'Ouargla',
	'El Hadjar': 'Annaba',
	'El Meniaa': 'El Menia',
	'El Eulma': 'Sétif',
	'El Bayadh': 'El Bayadh',
	'El Attaf': 'Aïn Defla',
	Chlef: 'Chlef',
	'El Aouinet': 'Tébessa',
	'El Amria': 'Aïn Témouchent',
	'El Affroun': 'Blida',
	'El Achir': 'Bordj Bou Arréridj',
	'El Abiodh Sidi Cheikh': 'El Bayadh',
	'El Abadia': 'Aïn Defla',
	'Ech Chettia': 'Chlef',
	Drean: 'El Tarf',
	'Draa el Mizan': 'Tizi Ouzou',
	'Draa Ben Khedda': 'Tizi Ouzou',
	Douera: 'Algiers',
	Djelfa: 'Djelfa',
	Djamaa: 'El Mghair',
	'Didouche Mourad': 'Constantine',
	Dellys: 'Boumerdes',
	Debila: 'El Oued',
	'Dar el Beïda': 'Algiers',
	'Dar Chioukh': 'Djelfa',
	Constantine: 'Constantine',
	Chorfa: 'Bouira',
	Chiffa: 'Blida',
	Chetouane: 'Tlemcen',
	Cheria: 'Tébessa',
	Cheraga: 'Algiers',
	Chemini: 'Béjaïa',
	'Chelghoum el Aïd': 'Mila',
	Chebli: 'Blida',
	Charef: 'Djelfa',
	'Chabet el Ameur': 'Boumerdes',
	Brezina: 'El Bayadh',
	'Bou Tlelis': 'Oran',
	'Boumahra Ahmed': 'Guelma',
	Boukadir: 'Chlef',
	'Bou Ismaïl': 'Tipaza',
	Bouïra: 'Bouira',
	Bouinan: 'Blida',
	'Bou Hanifia el Hamamat': 'Mascara',
	Bougara: 'Blida',
	Bougaa: 'Sétif',
	Boufarik: 'Blida',
	Boudouaou: 'Boumerdes',
	Boudjima: 'Tizi Ouzou',
	'Boû Arfa': 'Blida',
	'Bordj Zemoura': 'Bordj Bou Arréridj',
	'Bordj Ghdir': 'Bordj Bou Arréridj',
	'Bordj el Kiffan': 'Algiers',
	'Bordj Bou Arréridj': 'Bordj Bou Arréridj',
	Boghni: 'Tizi Ouzou',
	Blida: 'Blida',
	Biskra: 'Biskra',
	Birkhadem: 'Algiers',
	Birine: 'Djelfa',
	'Bir el Djir': 'Oran',
	'Bir el Ater': 'Tébessa',
	Besbes: 'El Tarf',
	Berrouaghia: 'Medea',
	Berriane: 'Ghardaia',
	Berrahal: 'Annaba',
	Bensekrane: 'Tlemcen',
	'Ben Mehidi': 'El Tarf',
	'Beni Saf': 'Aïn Témouchent',
	'Beni Mester': 'Tlemcen',
	'Beni Mered': 'Blida',
	'Beni Douala': 'Tizi Ouzou',
	'Beni Amrane': 'Boumerdes',
	Bejaïa: 'Béjaïa',
	Béchar: 'Béchar',
	Batna: 'Batna',
	Barika: 'Batna',
	Barbacha: 'Béjaïa',
	Baraki: 'Algiers',
	'Bab Ezzouar': 'Algiers',
	Azzaba: 'Skikda',
	Azazga: 'Tizi Ouzou',
	Arris: 'Batna',
	Arhribs: 'Tizi Ouzou',
	Arbatache: 'Boumerdes',
	Aoulef: 'Adrar',
	Annaba: 'Annaba',
	'Ammi Moussa': 'Relizane',
	Amizour: 'Béjaïa',
	Algiers: 'Algiers',
	Akbou: 'Béjaïa',
	'Aïn Touta': 'Batna',
	'Aïn Temouchent': 'Aïn Témouchent',
	'Aïn Taya': 'Algiers',
	'Aïn Smara': 'Constantine',
	'Aïn Sefra': 'Naama',
	'Aïn Oussera': 'Djelfa',
	'’Aïn Merane': 'Chlef',
	'Aïn Kercha': 'Oum el Bouaghi',
	'Aïn Fakroun': 'Oum el Bouaghi',
	'’Aïn el Turk': 'Oran',
	'’Aïn el Melh': "M'Sila",
	'’Aïn el Hammam': 'Tizi Ouzou',
	'‘Aïn el Hadjel': "M'Sila",
	'Aïn el Bya': 'Oran',
	'’Aïn el Berd': 'Sidi Bel Abbès',
	'’Aïn el Bell': 'Djelfa',
	'’Aïn Deheb': 'Tiaret',
	'Aïn Defla': 'Aïn Defla',
	'Aïn Bessem': 'Bouira',
	'’Aïn Benian': 'Algiers',
	'Aïn Beïda': 'Oum el Bouaghi',
	'Aïn Arnat': 'Sétif',
	'’Aïn Abid': 'Constantine',
	Aflou: 'Laghouat',
	Adrar: 'Adrar',
	'Abou el Hassan': 'Chlef',
	'BABOR - VILLE': 'Sétif',
	'In Salah': 'In Salah',
	'District of Taher': 'Jijel',
	Zamora: 'Michoacán',
	'Yaguachi Nuevo': 'Guayas',
	Vinces: 'Los Ríos',
	Ventanas: 'Los Ríos',
	'Velasco Ibarra': 'Guayas',
	Tulcán: 'Carchi',
	Tena: 'Napo',
	'Santo Domingo de los Colorados': 'Santo Domingo de los Tsáchilas',
	'San Lorenzo de Esmeraldas': 'Esmeraldas',
	Samborondón: 'Guayas',
	'Rosa Zarate': 'Esmeraldas',
	Riobamba: 'Chimborazo',
	Quito: 'Pichincha',
	Quevedo: 'Los Ríos',
	Puyo: 'Pastaza',
	Pujilí: 'Cotopaxi',
	'Puerto Francisco de Orellana': 'Orellana',
	Portoviejo: 'Manabí',
	Playas: 'Guayas',
	Piñas: 'El Oro',
	Pelileo: 'Tungurahua',
	'Pedro Carbo': 'Guayas',
	Pasaje: 'El Oro',
	Otavalo: 'Imbabura',
	Naranjito: 'Guayas',
	Naranjal: 'Guayas',
	Montecristi: 'Manabí',
	Montalvo: 'Los Ríos',
	Milagro: 'Guayas',
	Manta: 'Manabí',
	Machala: 'El Oro',
	Machachi: 'Pichincha',
	Macas: 'Morona-Santiago',
	Loja: 'Andalusia',
	'La Troncal': 'Cañar',
	Latacunga: 'Cotopaxi',
	'La Maná': 'Cotopaxi',
	'La Libertad': 'La Libertad',
	'Nueva Loja': 'Sucumbios',
	Jipijapa: 'Manabí',
	Ibarra: 'Imbabura',
	Huaquillas: 'El Oro',
	Guayaquil: 'Guayas',
	Guaranda: 'Bolívar',
	Gualaceo: 'Azuay',
	'El Triunfo': 'Guayas',
	'Eloy Alfaro': 'Guayas',
	Daule: 'Guayas',
	Cuenca: 'Calabarzon',
	Chone: 'Manabí',
	Cayambe: 'Pichincha',
	Catamayo: 'Loja',
	Cariamanga: 'Loja',
	Calceta: 'Manabí',
	'Boca Suno': 'Orellana',
	Balzar: 'Guayas',
	'Bahía de Caráquez': 'Manabí',
	Babahoyo: 'Los Ríos',
	Azogues: 'Cañar',
	Atuntaqui: 'Imbabura',
	Ambato: 'Tungurahua',
	Viljandi: 'Viljandimaa',
	Tartu: 'Tartu',
	Tallinn: 'Harjumaa',
	Pärnu: 'Pärnumaa',
	Nõmme: 'Harjumaa',
	Narva: 'Ida-Virumaa',
	Maardu: 'Harjumaa',
	'Kohtla-Järve': 'Ida-Virumaa',
	Zefta: 'Gharbia',
	Ţūkh: 'Qalyubia',
	Ţimā: 'Sohag',
	Ţanţā: 'Gharbia',
	Ţāmiyah: 'Faiyum',
	Ţalkhā: 'Dakahlia',
	Talā: 'Monufia',
	Ţahţā: 'Sohag',
	'Sumusţā as Sulţānī': 'Beni Suweif',
	Sohag: 'Sohag',
	Sinnūris: 'Faiyum',
	'Sīdī Sālim': 'Kafr el-Sheikh',
	'Sīdī Barānī': 'Matruh',
	'Shubrā al Khaymah': 'Qalyubia',
	Shirbīn: 'Dakahlia',
	'Shibīn al Qanāṭir': 'Qalyubia',
	'Shibīn al Kawm': 'Monufia',
	Samannūd: 'Gharbia',
	Samālūţ: 'Minya',
	Rosetta: 'Beheira',
	'Ras Gharib': 'Red Sea',
	Quwaysinā: 'Monufia',
	Quţūr: 'Gharbia',
	Qūş: 'Qena',
	Qinā: 'Qena',
	Qalyūb: 'Qalyubia',
	"Naja' Ḥammādī": 'Qena',
	'Mīt Ghamr': 'Dakahlia',
	'Minyat an Naşr': 'Dakahlia',
	Munūf: 'Monufia',
	Maţāy: 'Minya',
	'Mashtūl as Sūq': 'Sharqia',
	'Mersa Matruh': 'Matruh',
	Manfalūţ: 'Asyut',
	Mallawī: 'Minya',
	Maghāghah: 'Minya',
	'Madīnat Sittah Uktūbar': 'Giza',
	'Madīnat an Naşr': 'Cairo',
	'Al ‘Āshir min Ramaḑān': 'Sharqia',
	Kirdāsah: 'Giza',
	'Kawm Umbū': 'Aswan',
	'Kawm Ḩamādah': 'Beheira',
	'Kafr Şaqr': 'Sharqia',
	'Kafr az Zayyāt': 'Gharbia',
	'Kafr ash Shaykh': 'Kafr el-Sheikh',
	'Kafr ad Dawwār': 'Beheira',
	Juhaynah: 'Sohag',
	Girga: 'Sohag',
	'‘Izbat al Burj': 'Dakahlia',
	Iţsā: 'Faiyum',
	Isnā: 'Qena',
	Idkū: 'Beheira',
	Idfū: 'Aswan',
	Ibshawāy: 'Faiyum',
	Ḩalwān: 'Cairo',
	Hihyā: 'Sharqia',
	'Ḩawsh ‘Īsá': 'Beheira',
	Fuwwah: 'Kafr el-Sheikh',
	Farshūţ: 'Qena',
	Fāraskūr: 'Damietta',
	Fāqūs: 'Sharqia',
	Damietta: 'Damietta',
	'Diyarb Najm': 'Sharqia',
	Disūq: 'Kafr el-Sheikh',
	Dishnā: 'Qena',
	Dikirnis: 'Dakahlia',
	Dayrūţ: 'Asyut',
	'Dayr Mawās': 'Minya',
	Damanhūr: 'Beheira',
	Būsh: 'Beni Suweif',
	'Port Said': 'Port Said',
	Safaga: 'Red Sea',
	Bilqās: 'Dakahlia',
	Bilbays: 'Sharqia',
	Basyūn: 'Gharbia',
	'Banī Suwayf': 'Beni Suweif',
	'Banī Mazār': 'Minya',
	Banhā: 'Qalyubia',
	Zagazig: 'Sharqia',
	Awsīm: 'Giza',
	'At Tall al Kabīr': 'Sharqia',
	Asyūţ: 'Asyut',
	Aswan: 'Aswan',
	Suez: 'Suez',
	'As Sinbillāwayn': 'Dakahlia',
	'Aş Şaff': 'Giza',
	'Ash Shuhadā’': 'Monufia',
	Ashmūn: 'Monufia',
	'Al Wāsiţah': 'Faiyum',
	Luxor: 'Luxor',
	'Al Qūşīyah': 'Asyut',
	'Al Quşayr': 'Homs',
	'Al Qurayn': 'Sharqia',
	'Al Qanāyāt': 'Sharqia',
	'Al Qanāţir al Khayrīyah': 'Qalyubia',
	Cairo: 'Cairo',
	'Al Minyā': 'Minya',
	'Al Maţarīyah': 'Dakahlia',
	'Al Manzalah': 'Dakahlia',
	'Al Manşūrah': 'Dakahlia',
	'Al Minshāh': 'Sohag',
	'Al Maḩallah al Kubrá': 'Gharbia',
	'Al Khuşūş': 'Qalyubia',
	'Al Khārjah': 'New Valley',
	'Al Khānkah': 'Qalyubia',
	Giza: 'Giza',
	'Al Jammālīyah': 'Dakahlia',
	Ismailia: 'Ismailia',
	Alexandria: 'Virginia',
	'Al Ibrāhīmīyah': 'Sharqia',
	'Al Ḩawāmidīyah': 'Giza',
	'Al Ḩāmūl': 'Kafr el-Sheikh',
	Hurghada: 'Red Sea',
	'Al Fayyūm': 'Faiyum',
	'Al Fashn': 'Beni Suweif',
	'Al Bawīţī': 'Giza',
	'Al Balyanā': 'Sohag',
	'Al Bājūr': 'Monufia',
	'Al Badārī': 'Asyut',
	'Al ‘Ayyāţ': 'Giza',
	Arish: 'North Sinai',
	Akhmīm: 'Sohag',
	Ajā: 'Dakahlia',
	'Ad Dilinjāt': 'Beheira',
	'Abū Tīj': 'Asyut',
	'Abū Qurqāş': 'Minya',
	'Abū Kabīr': 'Sharqia',
	'Abū al Maţāmīr': 'Beheira',
	Abnūb: 'Asyut',
	'Az Zarqā': 'Damietta',
	'Ain Sukhna': 'Suez',
	'New Cairo': 'Cairo',
	"Al-'Ubūr": 'Qalyubia',
	Laayoune: '',
	Boujdour: '',
	Dakhla: 'Souss-Massa',
	Massawa: 'Northern Red Sea',
	Keren: 'Anseba',
	Barentu: 'Gash-Barka',
	Asmara: 'Maekel',
	Assab: 'Southern Red Sea',
	Mendefera: 'Debub',
	Zubia: 'Andalusia',
	Zafra: 'Extremadura',
	Yecla: 'Murcia',
	Villena: 'Valencia',
	Villarrobledo: 'Castille-La Mancha',
	'Vila-real': 'Valencia',
	'Villanueva de la Serena': 'Extremadura',
	Villajoyosa: 'Valencia',
	Vícar: 'Andalusia',
	'Vélez-Málaga': 'Andalusia',
	Valencia: 'Carabobo',
	Valdepeñas: 'Castille-La Mancha',
	Utrera: 'Andalusia',
	Ubrique: 'Andalusia',
	Úbeda: 'Andalusia',
	Totana: 'Murcia',
	Torrox: 'Andalusia',
	Torrevieja: 'Valencia',
	'Torre-Pacheco': 'Murcia',
	Torrent: 'Valencia',
	Torremolinos: 'Andalusia',
	'Torre del Mar': 'Andalusia',
	Tomelloso: 'Castille-La Mancha',
	Tomares: 'Andalusia',
	Tías: 'Canary Islands',
	Telde: 'Canary Islands',
	Teguise: 'Canary Islands',
	Tarifa: 'Andalusia',
	'Talavera de la Reina': 'Castille-La Mancha',
	Tacoronte: 'Canary Islands',
	'Tavernes de la Valldigna': 'Valencia',
	Sueca: 'Valencia',
	Silla: 'Valencia',
	'San Vicent del Raspeig': 'Valencia',
	Santomera: 'Murcia',
	'Santa Pola': 'Valencia',
	Santafé: 'Andalusia',
	'Santa Eulària des Riu': 'Balearic Islands',
	'Santa Cruz de Tenerife': 'Canary Islands',
	'Santa Cruz de la Palma': 'Canary Islands',
	'Santa Brígida': 'Canary Islands',
	'San Roque': 'Andalusia',
	'San Pedro del Pinatar': 'Murcia',
	'San Pedro de Alcántara': 'Andalusia',
	'San Miguel De Abona': 'Canary Islands',
	'Sanlúcar de Barrameda': 'Andalusia',
	'San Juan de Aznalfarache': 'Andalusia',
	"Sant Joan d'Alacant": 'Valencia',
	'San Fernando': 'California',
	'La Laguna': 'Canary Islands',
	'San Bartolomé de Tirajana': 'Canary Islands',
	'San Bartolomé': 'Canary Islands',
	'Sant Antoni de Portmany': 'Balearic Islands',
	Sagunto: 'Valencia',
	Rota: 'Andalusia',
	'Roquetas de Mar': 'Andalusia',
	Ronda: 'Andalusia',
	Rojales: 'Valencia',
	'Rincón de la Victoria': 'Andalusia',
	'Ribarroja del Turia': 'Valencia',
	Requena: 'Valencia',
	'Realejo Alto': 'Canary Islands',
	Puçol: 'Valencia',
	'Puerto Real': 'Andalusia',
	Puertollano: 'Castille-La Mancha',
	'Puerto del Rosario': 'Canary Islands',
	'Puerto de la Cruz': 'Canary Islands',
	'Puente-Genil': 'Andalusia',
	'La Pobla de Vallbona': 'Valencia',
	'Priego de Córdoba': 'Andalusia',
	Pozoblanco: 'Andalusia',
	Pollença: 'Balearic Islands',
	'Pilar de la Horadada': 'Valencia',
	Picassent: 'Valencia',
	Paterna: 'Valencia',
	Palma: 'Balearic Islands',
	'Palma del Río': 'Andalusia',
	Pájara: 'Canary Islands',
	Paiporta: 'Valencia',
	Osuna: 'Andalusia',
	Orihuela: 'Valencia',
	Ontinyent: 'Valencia',
	Onda: 'Valencia',
	Oliva: 'Valencia',
	Novelda: 'Valencia',
	Níjar: 'Andalusia',
	Nerja: 'Andalusia',
	'Navalmoral de la Mata': 'Extremadura',
	Murcia: 'Western Visayas',
	Mula: 'Murcia',
	Mutxamel: 'Valencia',
	Motril: 'Andalusia',
	'Morón de la Frontera': 'Andalusia',
	Montilla: 'Andalusia',
	Montijo: 'Setúbal',
	Moncada: 'Valencia',
	'Molina de Segura': 'Murcia',
	Moguer: 'Andalusia',
	Mogán: 'Canary Islands',
	Mislata: 'Valencia',
	Mijas: 'Andalusia',
	Miguelturra: 'Castille-La Mancha',
	Mérida: 'Mérida',
	Melilla: 'Melilla',
	Mazarrón: 'Murcia',
	Maspalomas: 'Canary Islands',
	Massamagrell: 'Valencia',
	Martos: 'Andalusia',
	Marratxí: 'Balearic Islands',
	Marchena: 'Andalusia',
	Marbella: 'Andalusia',
	Maracena: 'Andalusia',
	Manises: 'Valencia',
	Manacor: 'Balearic Islands',
	'Mairena del Aljarafe': 'Andalusia',
	'Mairena del Alcor': 'Andalusia',
	Maó: 'Balearic Islands',
	Lucena: 'Calabarzon',
	'Los Palacios y Villafranca': 'Andalusia',
	'Los Llanos de Aridane': 'Canary Islands',
	'Los Barrios': 'Andalusia',
	'Los Alcázares': 'Murcia',
	Lorca: 'Murcia',
	'Lora del Río': 'Andalusia',
	Llucmajor: 'Balearic Islands',
	Llíria: 'Valencia',
	Lepe: 'Andalusia',
	Lebrija: 'Andalusia',
	'Las Torres de Cotillas': 'Murcia',
	'Las Palmas de Gran Canaria': 'Canary Islands',
	'La Solana': 'Castille-La Mancha',
	'Las Cabezas de San Juan': 'Andalusia',
	'La Roda': 'Castille-La Mancha',
	'La Rinconada': 'Puno',
	'La Orotava': 'Canary Islands',
	'La Oliva': 'Canary Islands',
	'la Nucia': 'Valencia',
	'La Línea de la Concepción': 'Andalusia',
	"L'Eliana": 'Valencia',
	'La Carolina': 'Andalusia',
	'La Algaba': 'Andalusia',
	Jumilla: 'Murcia',
	'Jerez de la Frontera': 'Andalusia',
	Javea: 'Valencia',
	Xàtiva: 'Valencia',
	Jaén: 'Cajamarca',
	'Isla Cristina': 'Andalusia',
	Ingenio: 'Canary Islands',
	Inca: 'Balearic Islands',
	'Icod de los Vinos': 'Canary Islands',
	Ibiza: 'Balearic Islands',
	Ibi: 'Taraba',
	'Huércal-Overa': 'Andalusia',
	Huelva: 'Andalusia',
	Hellín: 'Castille-La Mancha',
	Güimar: 'Canary Islands',
	'Guía de Isora': 'Canary Islands',
	'Guardamar del Segura': 'Valencia',
	Guadix: 'Andalusia',
	'Granadilla de Abona': 'Canary Islands',
	Gandia: 'Valencia',
	Gáldar: 'Canary Islands',
	Fuengirola: 'Andalusia',
	Felanitx: 'Balearic Islands',
	Estepona: 'Andalusia',
	'El Viso del Alcor': 'Andalusia',
	'El Puerto de Santa María': 'Andalusia',
	'El Grao': 'Valencia',
	'El Ejido': 'Andalusia',
	Elda: 'Valencia',
	Elche: 'Valencia',
	'El Arahal': 'Andalusia',
	Écija: 'Andalusia',
	'Dos Hermanas': 'Andalusia',
	'Don Benito': 'Extremadura',
	Denia: 'Valencia',
	Daimiel: 'Castille-La Mancha',
	Cullera: 'Valencia',
	'Quart de Poblet': 'Valencia',
	Crevillente: 'Valencia',
	'Coria del Río': 'Andalusia',
	'Conil de la Frontera': 'Andalusia',
	Coín: 'Andalusia',
	'Ciudad Real': 'Castille-La Mancha',
	Cieza: 'Murcia',
	Xirivella: 'Valencia',
	Chipiona: 'Andalusia',
	'Chiclana de la Frontera': 'Andalusia',
	Cehegín: 'Murcia',
	Catarroja: 'Valencia',
	'Castilleja de la Cuesta': 'Andalusia',
	'Castelló de la Plana': 'Valencia',
	Cartaya: 'Andalusia',
	Cártama: 'Andalusia',
	Carrizal: 'Miranda',
	Carmona: 'Calabarzon',
	Carlet: 'Valencia',
	Carcaixent: 'Valencia',
	Caravaca: 'Murcia',
	'Campo de Criptana': 'Castille-La Mancha',
	Campiña: 'Andalusia',
	'El Campello': 'Valencia',
	Camas: 'Washington',
	Calvià: 'Balearic Islands',
	Calp: 'Valencia',
	'Callosa de Segura': 'Valencia',
	Cadiz: 'Western Visayas',
	Cáceres: 'Extremadura',
	Cabra: 'Andalusia',
	Burriana: 'Valencia',
	Burjassot: 'Valencia',
	Bormujos: 'Andalusia',
	Bétera: 'Valencia',
	Berja: 'Andalusia',
	Benidorm: 'Valencia',
	Benetússer: 'Valencia',
	Benalmádena: 'Andalusia',
	Baza: 'Andalusia',
	Barbate: 'Andalusia',
	Bailén: 'Andalusia',
	Baeza: 'Andalusia',
	Baena: 'Andalusia',
	Badajoz: 'Extremadura',
	Ayamonte: 'Andalusia',
	Atarfe: 'Andalusia',
	Atamaría: 'Murcia',
	Aspe: 'Valencia',
	Arucas: 'Canary Islands',
	'Arroyo de la Miel': 'Andalusia',
	Arrecife: 'Canary Islands',
	Arona: 'Canary Islands',
	Armilla: 'Andalusia',
	'Arcos de la Frontera': 'Andalusia',
	Archena: 'Murcia',
	Antequera: 'Andalusia',
	Andújar: 'Andalusia',
	Altea: 'Valencia',
	Almuñécar: 'Andalusia',
	Almoradí: 'Valencia',
	Almonte: 'Andalusia',
	Almería: 'Andalusia',
	Almendralejo: 'Extremadura',
	Almassora: 'Valencia',
	Almansa: 'Castille-La Mancha',
	Aljaraque: 'Andalusia',
	Alicante: 'Valencia',
	'Alhaurín el Grande': 'Andalusia',
	'Alhaurín de la Torre': 'Andalusia',
	'Alhama de Murcia': 'Murcia',
	Algemesí: 'Valencia',
	Algeciras: 'Andalusia',
	Alfafar: 'Valencia',
	Aldaia: 'Valencia',
	Alcúdia: 'Balearic Islands',
	Alcoy: 'Valencia',
	Alzira: 'Valencia',
	'Alcázar de San Juan': 'Castille-La Mancha',
	Alcantarilla: 'Murcia',
	'Alcalá la Real': 'Andalusia',
	'Alcalá de Guadaira': 'Andalusia',
	Alboraya: 'Valencia',
	Albolote: 'Andalusia',
	Albal: 'Valencia',
	Albacete: 'Castille-La Mancha',
	Alaquàs: 'Valencia',
	Agüimes: 'Canary Islands',
	Águilas: 'Murcia',
	Adra: 'West Bengal',
	Adeje: 'Canary Islands',
	'Grao de Murviedro': 'Valencia',
	Zarautz: 'Basque Country',
	Viveiro: 'Galicia',
	'Gasteiz / Vitoria': 'Basque Country',
	Vinaròs: 'Valencia',
	'Villaviciosa de Odón': 'Madrid',
	Villaverde: 'Madrid',
	Villaquilambre: 'Castille and León',
	'Vilanova i la Geltrú': 'Catalonia',
	'Villanueva del Pardillo': 'Madrid',
	'Villanueva de la Cañada': 'Madrid',
	Vilalba: 'Galicia',
	'Vilagarcía de Arousa': 'Galicia',
	'Vilafranca del Penedès': 'Catalonia',
	'Vila-seca': 'Catalonia',
	Vilaseca: 'Catalonia',
	Viladecans: 'Catalonia',
	Vigo: 'Galicia',
	Vic: 'Catalonia',
	Vicálvaro: 'Madrid',
	'El Vendrell': 'Catalonia',
	Valls: 'Catalonia',
	Valladolid: 'Yucatán',
	Valdemoro: 'Madrid',
	'Rivas-Vaciamadrid': 'Madrid',
	Utebo: 'Aragon',
	Tui: 'Galicia',
	Tudela: 'Navarre',
	Tortosa: 'Catalonia',
	Torrelodones: 'Madrid',
	Torrelavega: 'Cantabria',
	'Torrejón de Ardoz': 'Madrid',
	Torredembarra: 'Catalonia',
	Tordera: 'Catalonia',
	Tolosa: 'Basque Country',
	'Tetuán de las Victorias': 'Madrid',
	Teruel: 'Aragon',
	Teo: 'Galicia',
	Tàrrega: 'Catalonia',
	Terrassa: 'Catalonia',
	Tarragona: 'Catalonia',
	Tarancón: 'Castille-La Mancha',
	Soria: 'Castille and León',
	Sitges: 'Catalonia',
	Sestao: 'Basque Country',
	Seseña: 'Castille-La Mancha',
	'Cerdanyola del Vallès': 'Catalonia',
	'Sant Vicenç dels Horts': 'Catalonia',
	Barakaldo: 'Basque Country',
	Santurtzi: 'Basque Country',
	Sants: 'Catalonia',
	'Sant Just Desvern': 'Catalonia',
	'Santiago de Compostela': 'Galicia',
	'Santa Perpètua de Mogoda': 'Catalonia',
	Santander: 'Cantabria',
	'Barberà del Vallès': 'Catalonia',
	'Santa Uxía de Ribeira': 'Galicia',
	'Santa Coloma de Gramenet': 'Catalonia',
	'San Sebastián de los Reyes': 'Madrid',
	'Donostia / San Sebastián': 'Basque Country',
	'Sant Quirze del Vallès': 'Catalonia',
	'Sant Pere de Ribes': 'Catalonia',
	'San Martín de la Vega': 'Madrid',
	'San Lorenzo de El Escorial': 'Madrid',
	'Vilassar de Mar': 'Catalonia',
	'Sant Joan Despí': 'Catalonia',
	Sanxenxo: 'Galicia',
	'San Fernando de Henares': 'Madrid',
	'Sant Feliu de Llobregat': 'Catalonia',
	'Sant Feliu de Guíxols': 'Catalonia',
	'Sant Cugat del Vallès': 'Catalonia',
	'Sant Celoni': 'Catalonia',
	'Sant Carles de la Ràpita': 'Catalonia',
	'Sant Boi de Llobregat': 'Catalonia',
	'Sant Andreu': 'Catalonia',
	'San Andrés del Rabanedo': 'Castille and León',
	'Sant Andreu de la Barca': 'Catalonia',
	'Sant Adrià de Besòs': 'Catalonia',
	Sama: 'Asturias',
	Salt: 'Catalonia',
	Salou: 'Catalonia',
	Salamanca: 'Guanajuato',
	Sabadell: 'Catalonia',
	Rubí: 'Catalonia',
	Roses: 'Catalonia',
	Ripollet: 'Catalonia',
	Ribeira: 'Galicia',
	Reus: 'Catalonia',
	Errenteria: 'Basque Country',
	Redondela: 'Galicia',
	'Puente de Vallecas': 'Madrid',
	Ponteareas: 'Galicia',
	'Premià de Mar': 'Catalonia',
	'Pozuelo de Alarcón': 'Madrid',
	Poio: 'Galicia',
	Portugalete: 'Basque Country',
	Porriño: 'Galicia',
	Ponferrada: 'Castille and León',
	Plasencia: 'Extremadura',
	Pinto: 'Madrid',
	'Pineda de Mar': 'Catalonia',
	Piera: 'Catalonia',
	Parla: 'Madrid',
	'Parets del Vallès': 'Catalonia',
	Palencia: 'Guatemala',
	Palamós: 'Catalonia',
	Palafrugell: 'Catalonia',
	Oviedo: 'Florida',
	Oria: 'Basque Country',
	Ourense: 'Galicia',
	Olot: 'Catalonia',
	'Olesa de Montserrat': 'Catalonia',
	Oleiros: 'Galicia',
	Nigrán: 'Galicia',
	Navalcarnero: 'Madrid',
	Narón: 'Galicia',
	Mungia: 'Basque Country',
	Móstoles: 'Madrid',
	Moratalaz: 'Madrid',
	Monzón: 'Aragon',
	'Montornès del Vallès': 'Catalonia',
	'Monforte de Lemos': 'Galicia',
	'Arrasate / Mondragón': 'Basque Country',
	'Montcada i Reixac': 'Catalonia',
	'Mollet del Vallès': 'Catalonia',
	'Molins de Rei': 'Catalonia',
	Moaña: 'Galicia',
	'Miranda de Ebro': 'Castille and León',
	Mieres: 'Asturias',
	'Mejorada del Campo': 'Madrid',
	'Medina del Campo': 'Castille and León',
	Mataró: 'Catalonia',
	'El Masnou': 'Catalonia',
	Martorell: 'Catalonia',
	Marín: 'Galicia',
	Manresa: 'Catalonia',
	Manlleu: 'Catalonia',
	'Malgrat de Mar': 'Catalonia',
	Majadahonda: 'Madrid',
	Lugo: 'Emilia-Romagna',
	Logroño: 'La Rioja',
	'Lloret de Mar': 'Catalonia',
	'Laudio / Llodio': 'Basque Country',
	Lleida: 'Catalonia',
	León: 'León Department',
	Leioa: 'Basque Country',
	Leganés: 'Madrid',
	'Las Rozas de Madrid': 'Madrid',
	Lasarte: 'Basque Country',
	'La Sagrera': 'Catalonia',
	'Sant Pere, Santa Caterina i La Ribera': 'Catalonia',
	'La Pineda': 'Catalonia',
	Lalín: 'Galicia',
	'Laguna de Duero': 'Castille and León',
	'A Estrada': 'Galicia',
	'A Coruña': 'Galicia',
	Irun: 'Basque Country',
	Illescas: 'Castille-La Mancha',
	Igualada: 'Catalonia',
	'Humanes de Madrid': 'Madrid',
	Huesca: 'Aragon',
	"L'Hospitalet de Llobregat": 'Catalonia',
	Hortaleza: 'Madrid',
	Horta: 'Catalonia',
	Hernani: 'Basque Country',
	'Gernika-Lumo': 'Basque Country',
	Getxo: 'Basque Country',
	Guadalajara: 'Jalisco',
	Granollers: 'Catalonia',
	Gràcia: 'Catalonia',
	Gijón: 'Asturias',
	Getafe: 'Madrid',
	Girona: 'Catalonia',
	Gavà: 'Catalonia',
	Gamonal: 'Castille and León',
	Galdakao: 'Basque Country',
	Galapagar: 'Madrid',
	Hondarribia: 'Basque Country',
	Fuenlabrada: 'Madrid',
	Figueres: 'Catalonia',
	'Esplugues de Llobregat': 'Catalonia',
	Esparreguera: 'Catalonia',
	Ermua: 'Basque Country',
	Erandio: 'Basque Country',
	'El Prat de Llobregat': 'Catalonia',
	Ferrol: 'Galicia',
	'El Escorial': 'Madrid',
	'El Astillero': 'Cantabria',
	'el Raval': 'Catalonia',
	'Ejea de los Caballeros': 'Aragon',
	Eibar: 'Basque Country',
	Durango: 'Colorado',
	Culleredo: 'Galicia',
	Coslada: 'Madrid',
	'Cornellà de Llobregat': 'Catalonia',
	'Colmenar Viejo': 'Madrid',
	'Collado-Villalba': 'Madrid',
	'el Clot': 'Catalonia',
	'Ciudad Lineal': 'Madrid',
	Ciutadella: 'Balearic Islands',
	Ciempozuelos: 'Madrid',
	Chamartín: 'Madrid',
	'Castro-Urdiales': 'Cantabria',
	Castelldefels: 'Catalonia',
	'Castellar del Vallès': 'Catalonia',
	Cardedeu: 'Catalonia',
	Carballo: 'Galicia',
	Canovelles: 'Catalonia',
	'Cangas do Morrazo': 'Galicia',
	Cambrils: 'Catalonia',
	Cambre: 'Galicia',
	Camargo: 'Chihuahua',
	Calella: 'Catalonia',
	'Caldes de Montbui': 'Catalonia',
	Calatayud: 'Aragon',
	Calahorra: 'La Rioja',
	Calafell: 'Catalonia',
	Burlata: 'Navarre',
	Burgos: 'Central Luzon',
	Boiro: 'Galicia',
	'Boadilla del Monte': 'Madrid',
	Blanes: 'Catalonia',
	Bilbao: 'Basque Country',
	Bermeo: 'Basque Country',
	Berga: 'Catalonia',
	Benicàssim: 'Valencia',
	Benicarló: 'Valencia',
	Benavente: 'Castille and León',
	Béjar: 'Castille and León',
	'la Barceloneta': 'Catalonia',
	Barcelona: 'Anzoátegui',
	Barbastro: 'Aragon',
	Barañáin: 'Navarre',
	'Barajas de Madrid': 'Madrid',
	Banyoles: 'Catalonia',
	Balaguer: 'Catalonia',
	Badalona: 'Catalonia',
	'Azuqueca de Henares': 'Castille-La Mancha',
	Avilés: 'Asturias',
	Ávila: 'Castille and León',
	Arteixo: 'Galicia',
	Arroyomolinos: 'Madrid',
	Arganda: 'Madrid',
	'Arenys de Mar': 'Catalonia',
	Aranjuez: 'Madrid',
	'Aranda de Duero': 'Castille and León',
	Amposta: 'Catalonia',
	Amorebieta: 'Basque Country',
	Algorta: 'Basque Country',
	Algete: 'Madrid',
	Alcorcón: 'Madrid',
	Alcobendas: 'Madrid',
	Alcañiz: 'Aragon',
	'Alcalá de Henares': 'Madrid',
	'Nou Barris': 'Catalonia',
	'Pinar de Chamartín': 'Madrid',
	'Playa del Ingles': 'Canary Islands',
	'Puerto del Carmen': 'Canary Islands',
	Ceuta: 'Ceuta',
	'Moncloa-Aravaca': 'Madrid',
	Eixample: 'Catalonia',
	'Les Corts': 'Catalonia',
	'Sarrià-Sant Gervasi': 'Catalonia',
	'Horta-Guinardó': 'Catalonia',
	'Sants-Montjuïc': 'Catalonia',
	'Sant Martí': 'Catalonia',
	'Ciutat Vella': 'Catalonia',
	Arganzuela: 'Madrid',
	'San Blas-Canillejas': 'Madrid',
	Latina: 'Lazio',
	Usera: 'Madrid',
	Chamberí: 'Madrid',
	Carabanchel: 'Madrid',
	'Madrid Centro': 'Madrid',
	'Barri Gòtic': 'Catalonia',
	'Poble Sec': 'Catalonia',
	Hostafrancs: 'Catalonia',
	'La Bordeta': 'Catalonia',
	'La Bonanova': 'Catalonia',
	'Las Tres Torres': 'Catalonia',
	'el Poblenou': 'Catalonia',
	'El Carmel': 'Catalonia',
	"l'Alfàs del Pi": 'Valencia',
	'Las Gabias': 'Andalusia',
	Delicias: 'Aragon',
	Almozara: 'Aragon',
	Montecanal: 'Aragon',
	'Oliver-Valdefierro': 'Aragon',
	Santutxu: 'Basque Country',
	'Fort Pienc': 'Catalonia',
	"Dreta de l'Eixample": 'Catalonia',
	Vallcarca: 'Catalonia',
	'Sagrada Família': 'Catalonia',
	'Sant Martí de Provençals': 'Catalonia',
	'Los Realejos': 'Canary Islands',
	Pasaia: 'Basque Country',
	Basauri: 'Basque Country',
	Llefià: 'Catalonia',
	'Corvera de Asturias': 'Asturias',
	'Tres Cantos': 'Madrid',
	Iturrama: 'Navarre',
	Ermitagaña: 'Navarre',
	'Primer Ensanche': 'Navarre',
	'Segundo Ensanche': 'Navarre',
	'Fuencarral-El Pardo': 'Madrid',
	'Villa de Vallecas': 'Madrid',
	Natahoyo: 'Asturias',
	Sarrià: 'Catalonia',
	"l'Antiga Esquerra de l'Eixample": 'Catalonia',
	'el Baix Guinardó': 'Catalonia',
	'la Prosperitat': 'Catalonia',
	'el Putxet i el Farró': 'Catalonia',
	'Provenals del Poblenou': 'Catalonia',
	'Sant Antoni': 'Catalonia',
	'la Vila de Gràcia': 'Catalonia',
	"la Nova Esquerra de l'Eixample": 'Catalonia',
	Porta: 'Catalonia',
	'Sants - Badal': 'Catalonia',
	'la Maternitat i Sant Ramon': 'Catalonia',
	'Sant Gervasi - Galvany': 'Catalonia',
	"el Camp d'en Grassot i Gràcia Nova": 'Catalonia',
	'la Marina de Port': 'Catalonia',
	'Vilapicina i la Torre Llobeta': 'Catalonia',
	"el Camp de l'Arpa del Clot": 'Catalonia',
	Navas: 'Catalonia',
	'la Guineueta': 'Catalonia',
	'les Roquetes': 'Catalonia',
	'la Verneda i la Pau': 'Catalonia',
	'el Turó de la Peira': 'Catalonia',
	'el Besòs i el Maresme': 'Catalonia',
	'el Guinardó': 'Catalonia',
	'Barri de Sant Andreu': 'Catalonia',
	'Barri de les Corts': 'Catalonia',
	Waal: 'Somali',
	Fadhigaradle: 'Somali',
	Ziway: 'Oromiya',
	'Yirga ‘Alem': 'SNNPR',
	Yabēlo: 'Oromiya',
	Werota: 'Amhara',
	Wenjī: 'Oromiya',
	Tēpī: 'SNNPR',
	Shashemenē: 'Oromiya',
	Shambu: 'Oromiya',
	Shakiso: 'Oromiya',
	Sebeta: 'Oromiya',
	Robīt: 'Amhara',
	Nejo: 'Oromiya',
	Nazrēt: 'Oromiya',
	Mojo: 'Oromiya',
	Metu: 'Oromiya',
	Metahāra: 'Oromiya',
	Mendī: 'Oromiya',
	"Mek'ele": 'Tigray',
	'Maych’ew': 'Tigray',
	Korem: 'Tigray',
	'K’olīto': 'SNNPR',
	'Kibre Mengist': 'Oromiya',
	Kemisē: 'Amhara',
	Kombolcha: 'Amhara',
	Jinka: 'SNNPR',
	Jimma: 'Oromiya',
	Jijiga: 'Somali',
	'Inda Silasē': 'Tigray',
	'Hosa’ina': 'SNNPR',
	Harar: 'Harari',
	'Hāgere Hiywet': 'Oromiya',
	'El Bahay': 'Somali',
	Gondar: 'Amhara',
	Goba: 'Oromiya',
	Waliso: 'Oromiya',
	Ginir: 'Oromiya',
	Gimbi: 'Oromiya',
	Raqo: 'Somali',
	Genet: 'Oromiya',
	Gelemso: 'Oromiya',
	'Gebre Guracha': 'Oromiya',
	Gambēla: 'Gambela',
	'Finote Selam': 'Amhara',
	Fichē: 'Oromiya',
	'Felege Neway': 'SNNPR',
	Golwayn: 'Somali',
	Dubti: 'Āfar',
	Dodola: 'Oromiya',
	'Dire Dawa': 'Dire Dawa',
	Dīla: 'SNNPR',
	Desē: 'Amhara',
	'Dembī Dolo': 'Oromiya',
	Bishoftu: 'Oromiya',
	'Debre Tabor': 'Amhara',
	'Debre Mark’os': 'Amhara',
	'Debre Birhan': 'Amhara',
	'Debark’': 'Amhara',
	Butajīra: 'SNNPR',
	Burē: 'Amhara',
	Bonga: 'SNNPR',
	Bodītī: 'SNNPR',
	Bichena: 'Amhara',
	Bedēsa: 'Oromiya',
	Bedelē: 'Oromiya',
	Batī: 'Amhara',
	Bako: 'SNNPR',
	'Bahir Dar': 'Amhara',
	Hawassa: 'SNNPR',
	Āsosa: 'Bīnshangul Gumuz',
	'Āsbe Teferī': 'Oromiya',
	Asaita: 'Āfar',
	Āsasa: 'Oromiya',
	Āreka: 'SNNPR',
	'Arba Minch': 'SNNPR',
	Axum: 'Tigray',
	'Hagere Maryam': 'Oromiya',
	Āgaro: 'Oromiya',
	'Ādīs Zemen': 'Amhara',
	'Addis Ababa': 'Addis Ababa',
	Ādīgrat: 'Tigray',
	Ādēt: 'Amhara',
	Abomsa: 'Amhara',
	Qorof: 'Somali',
	Kahandhale: 'Somali',
	Lasoano: 'Somali',
	Neefkuceliye: 'Somali',
	Yamarugley: 'Somali',
	Sodo: 'SNNPR',
	'Digih Habar Es': 'Somali',
	Ylöjärvi: 'Pirkanmaa',
	Vuosaari: 'Uusimaa',
	Vihti: 'Uusimaa',
	Varkaus: 'North Savo',
	Vantaa: 'Uusimaa',
	Valkeakoski: 'Pirkanmaa',
	Vaasa: 'Ostrobothnia',
	Uusikaupunki: 'Southwest Finland',
	Tuusula: 'Uusimaa',
	Turku: 'Southwest Finland',
	Tornio: 'Lapland',
	Tampere: 'Pirkanmaa',
	Siilinjärvi: 'North Savo',
	Sibbo: 'Uusimaa',
	Seinäjoki: 'South Ostrobothnia',
	Savonlinna: 'South Savo',
	Salo: 'Southwest Finland',
	Rovaniemi: 'Lapland',
	Riihimäki: 'Kanta-Häme',
	Rauma: 'Satakunta',
	Raisio: 'Southwest Finland',
	Raahe: 'North Ostrobothnia',
	Pori: 'Satakunta',
	Pirkkala: 'Pirkanmaa',
	Oulu: 'North Ostrobothnia',
	Nurmijärvi: 'Uusimaa',
	Nokia: 'Pirkanmaa',
	Munkkiniemi: 'Uusimaa',
	Mikkeli: 'South Savo',
	Mellunkylä: 'Uusimaa',
	Mäntsälä: 'Uusimaa',
	Lovisa: 'Uusimaa',
	Loimaa: 'Southwest Finland',
	Lohja: 'Uusimaa',
	Lieto: 'Southwest Finland',
	Lempäälä: 'Pirkanmaa',
	Lauttasaari: 'Uusimaa',
	Laukaa: 'Central Finland',
	Lappeenranta: 'South Karelia',
	Lahti: 'Paijat-Hame',
	Kirkkonummi: 'Uusimaa',
	Kuusamo: 'North Ostrobothnia',
	Kuopio: 'North Savo',
	Kouvola: 'Kymenlaakso',
	Kotka: 'Kymenlaakso',
	Korsholm: 'Ostrobothnia',
	Kokkola: 'Central Ostrobothnia',
	Klaukkala: 'Uusimaa',
	Kerava: 'Uusimaa',
	Kemi: 'Lapland',
	Karhula: 'Kymenlaakso',
	Kangasala: 'Pirkanmaa',
	Kallio: 'Uusimaa',
	Kajaani: 'Kainuu',
	Kaarina: 'Southwest Finland',
	Kaarela: 'Uusimaa',
	Jyväskylä: 'Central Finland',
	Joensuu: 'North Karelia',
	Järvenpää: 'Uusimaa',
	Janakkala: 'Kanta-Häme',
	Jämsä: 'Central Finland',
	Jakobstad: 'Ostrobothnia',
	Imatra: 'South Karelia',
	Iisalmi: 'North Savo',
	Hyvinge: 'Uusimaa',
	Hollola: 'Paijat-Hame',
	Helsinki: 'Uusimaa',
	Heinola: 'Paijat-Hame',
	Haukipudas: 'North Ostrobothnia',
	Hamina: 'Kymenlaakso',
	Hämeenlinna: 'Kanta-Häme',
	Forssa: 'Kanta-Häme',
	Espoo: 'Uusimaa',
	Porvoo: 'Uusimaa',
	Anjala: 'Kymenlaakso',
	Väståboland: 'Southwest Finland',
	Suva: 'Central',
	Sigatoka: 'Western',
	Nadi: 'Western',
	Lautoka: 'Western',
	Labasa: 'Northern',
	Nasinu: 'Central',
	Stanley: 'England',
	'Palikir - National Government Center': 'Pohnpei',
	Tórshavn: 'Streymoy',
	Yerres: 'Île-de-France',
	Wittenheim: 'Grand Est',
	Wattrelos: 'Hauts-de-France',
	Wasquehal: 'Hauts-de-France',
	Voiron: 'Auvergne-Rhône-Alpes',
	'Vitry-sur-Seine': 'Île-de-France',
	'Vitry-le-François': 'Grand Est',
	Vitrolles: "Provence-Alpes-Côte d'Azur",
	Vitré: 'Brittany',
	'Viry-Châtillon': 'Île-de-France',
	Viroflay: 'Île-de-France',
	Vincennes: 'Indiana',
	'Villiers-sur-Marne': 'Île-de-France',
	'Villiers-le-Bel': 'Île-de-France',
	Villeurbanne: 'Auvergne-Rhône-Alpes',
	'Villers-lès-Nancy': 'Grand Est',
	Villepinte: 'Île-de-France',
	Villeparisis: 'Île-de-France',
	'Villeneuve-sur-Lot': 'Nouvelle-Aquitaine',
	'Villeneuve-Saint-Georges': 'Île-de-France',
	'Villeneuve-le-Roi': 'Île-de-France',
	'Villeneuve-la-Garenne': 'Île-de-France',
	"Villenave-d'Ornon": 'Nouvelle-Aquitaine',
	Villemomble: 'Île-de-France',
	Villejuif: 'Île-de-France',
	'Villefranche-sur-Saône': 'Auvergne-Rhône-Alpes',
	Villefontaine: 'Auvergne-Rhône-Alpes',
	'Vigneux-sur-Seine': 'Île-de-France',
	Vierzon: 'Centre',
	Vienne: 'Auvergne-Rhône-Alpes',
	Vichy: 'Auvergne-Rhône-Alpes',
	Vesoul: 'Bourgogne-Franche-Comté',
	Vertou: 'Pays de la Loire',
	Versailles: 'Île-de-France',
	'Verrières-le-Buisson': 'Île-de-France',
	'Verneuil-sur-Seine': 'Île-de-France',
	Verdun: 'Grand Est',
	Vénissieux: 'Auvergne-Rhône-Alpes',
	Vendôme: 'Centre',
	Vence: "Provence-Alpes-Côte d'Azur",
	'Vélizy-Villacoublay': 'Île-de-France',
	Vauréal: 'Île-de-France',
	'Vaulx-en-Velin': 'Auvergne-Rhône-Alpes',
	Vanves: 'Île-de-France',
	Vannes: 'Brittany',
	'Vandœuvre-lès-Nancy': 'Grand Est',
	Vallauris: "Provence-Alpes-Côte d'Azur",
	Valenciennes: 'Hauts-de-France',
	Valence: 'Auvergne-Rhône-Alpes',
	Tulle: 'Nouvelle-Aquitaine',
	Troyes: 'Grand Est',
	'Tremblay-en-France': 'Île-de-France',
	Trappes: 'Île-de-France',
	Tours: 'Centre',
	Tournefeuille: 'Occitanie',
	Tourlaville: 'Normandy',
	Tourcoing: 'Hauts-de-France',
	Toulouse: 'Occitanie',
	Toulon: "Provence-Alpes-Côte d'Azur",
	Toul: 'Grand Est',
	Torcy: 'Île-de-France',
	'Thonon-les-Bains': 'Auvergne-Rhône-Alpes',
	Thionville: 'Grand Est',
	Thiais: 'Île-de-France',
	Tergnier: 'Hauts-de-France',
	Taverny: 'Île-de-France',
	'Tassin-la-Demi-Lune': 'Auvergne-Rhône-Alpes',
	Tarbes: 'Occitanie',
	Talence: 'Nouvelle-Aquitaine',
	Suresnes: 'Île-de-France',
	'Sucy-en-Brie': 'Île-de-France',
	Strasbourg: 'Grand Est',
	Stains: 'Île-de-France',
	'Sotteville-lès-Rouen': 'Normandy',
	Sorgues: "Provence-Alpes-Côte d'Azur",
	'Soisy-sous-Montmorency': 'Île-de-France',
	Soissons: 'Hauts-de-France',
	'Six-Fours-les-Plages': "Provence-Alpes-Côte d'Azur",
	'Sin-le-Noble': 'Hauts-de-France',
	Seynod: 'Auvergne-Rhône-Alpes',
	Sèvres: 'Île-de-France',
	Sevran: 'Île-de-France',
	Sète: 'Occitanie',
	Sens: 'Bourgogne-Franche-Comté',
	Senlis: 'Hauts-de-France',
	Sélestat: 'Grand Est',
	Sedan: 'Grand Est',
	Schiltigheim: 'Grand Est',
	Sceaux: 'Île-de-France',
	'Savigny-sur-Orge': 'Île-de-France',
	'Savigny-le-Temple': 'Île-de-France',
	Saumur: 'Pays de la Loire',
	Sartrouville: 'Île-de-France',
	Sarreguemines: 'Grand Est',
	Sarcelles: 'Île-de-France',
	Saran: 'Centre',
	Sannois: 'Île-de-France',
	'Sanary-sur-Mer': "Provence-Alpes-Côte d'Azur",
	'Salon-de-Provence': "Provence-Alpes-Côte d'Azur",
	Sallanches: 'Auvergne-Rhône-Alpes',
	'Saint-Sébastien-sur-Loire': 'Pays de la Loire',
	'Saint-Raphaël': 'Nord',
	'Saint-Quentin': 'Hauts-de-France',
	'Saint-Priest': 'Auvergne-Rhône-Alpes',
	'Saint-Pol-sur-Mer': 'Hauts-de-France',
	'Saint-Pierre-des-Corps': 'Centre',
	"Saint-Ouen-l'Aumône": 'Île-de-France',
	'Saint-Ouen': 'Île-de-France',
	'Saint-Omer': 'Hauts-de-France',
	'Saint-Nazaire': 'Pays de la Loire',
	'Saint-Michel-sur-Orge': 'Île-de-France',
	'Saint-Médard-en-Jalles': 'Nouvelle-Aquitaine',
	'Saint-Maximin-la-Sainte-Baume': "Provence-Alpes-Côte d'Azur",
	'Saint-Maur-des-Fossés': 'Île-de-France',
	"Saint-Martin-d'Hères": 'Auvergne-Rhône-Alpes',
	'Saint-Mandé': 'Île-de-France',
	'Saint-Malo': 'Brittany',
	'Saint-Loup': "Provence-Alpes-Côte d'Azur",
	'Saint-Louis': 'Saint-Louis',
	'Saint-Lô': 'Normandy',
	'Saint-Leu-la-Forêt': 'Île-de-France',
	'Saint-Leu': 'Réunion',
	'Saint-Laurent-du-Var': "Provence-Alpes-Côte d'Azur",
	'Saint-Jean-de-la-Ruelle': 'Centre',
	'Saint-Jean-de-Braye': 'Centre',
	'Saint-Herblain': 'Pays de la Loire',
	'Saint-Gratien': 'Île-de-France',
	'Saint-Germain-en-Laye': 'Île-de-France',
	'Saint-Genis-Laval': 'Auvergne-Rhône-Alpes',
	'Saint-Fons': 'Auvergne-Rhône-Alpes',
	'Saint-Étienne-du-Rouvray': 'Normandy',
	'Saint-Étienne': 'Auvergne-Rhône-Alpes',
	Saintes: 'Nouvelle-Aquitaine',
	'Sainte-Marguerite': "Provence-Alpes-Côte d'Azur",
	'Sainte-Geneviève-des-Bois': 'Île-de-France',
	'Sainte-Foy-lès-Lyon': 'Auvergne-Rhône-Alpes',
	'Saint-Égrève': 'Auvergne-Rhône-Alpes',
	'Saint-Dizier': 'Grand Est',
	'Saint-Dié-des-Vosges': 'Grand Est',
	'Saint-Denis': 'Réunion',
	'Saint-Cyr-sur-Loire': 'Centre',
	"Saint-Cyr-l'École": 'Île-de-France',
	'Saint-Cloud': 'Île-de-France',
	'Saint-Chamond': 'Auvergne-Rhône-Alpes',
	'Saint-Brieuc': 'Brittany',
	'Saint-Avold': 'Grand Est',
	'Saint-Avertin': 'Centre',
	'Saint-Amand-les-Eaux': 'Hauts-de-France',
	'Rueil-Malmaison': 'Île-de-France',
	Royan: 'Nouvelle-Aquitaine',
	Rouen: 'Normandy',
	Roubaix: 'Hauts-de-France',
	'Rosny-sous-Bois': 'Île-de-France',
	Ronchin: 'Hauts-de-France',
	'Romorantin-Lanthenay': 'Centre',
	'Romans-sur-Isère': 'Auvergne-Rhône-Alpes',
	Romainville: 'Île-de-France',
	'Roissy-en-Brie': 'Île-de-France',
	Rodez: 'Occitanie',
	Rochefort: 'Nouvelle-Aquitaine',
	Roanne: 'Auvergne-Rhône-Alpes',
	'Ris-Orangis': 'Île-de-France',
	Riom: 'Auvergne-Rhône-Alpes',
	'Rillieux-la-Pape': 'Auvergne-Rhône-Alpes',
	Rezé: 'Pays de la Loire',
	Rennes: 'Brittany',
	Reims: 'Grand Est',
	Rambouillet: 'Île-de-France',
	Quimper: 'Brittany',
	Puteaux: 'Île-de-France',
	'Port-de-Bouc': "Provence-Alpes-Côte d'Azur",
	Pontoise: 'Île-de-France',
	Pontivy: 'Brittany',
	Pontarlier: 'Bourgogne-Franche-Comté',
	'Pont-à-Mousson': 'Grand Est',
	Poitiers: 'Nouvelle-Aquitaine',
	Poissy: 'Île-de-France',
	Ploemeur: 'Brittany',
	Plaisir: 'Île-de-France',
	'Plaisance-du-Touch': 'Occitanie',
	'Pierrefitte-sur-Seine': 'Île-de-France',
	Pessac: 'Nouvelle-Aquitaine',
	Pertuis: "Provence-Alpes-Côte d'Azur",
	Perpignan: 'Occitanie',
	Périgueux: 'Nouvelle-Aquitaine',
	Pau: 'Nouvelle-Aquitaine',
	Paris: 'Texas',
	Pantin: 'Île-de-France',
	Pamiers: 'Occitanie',
	Palaiseau: 'Île-de-France',
	'Ozoir-la-Ferrière': 'Île-de-France',
	Oyonnax: 'Auvergne-Rhône-Alpes',
	Outreau: 'Hauts-de-France',
	Oullins: 'Auvergne-Rhône-Alpes',
	Osny: 'Île-de-France',
	Orvault: 'Pays de la Loire',
	Orsay: 'Île-de-France',
	Orly: 'Île-de-France',
	Orléans: 'Centre',
	Olivet: 'Centre',
	Octeville: 'Normandy',
	Noyon: 'Hauts-de-France',
	'Noisy-le-Sec': 'Île-de-France',
	'Noisy-le-Grand': 'Île-de-France',
	Noisiel: 'Île-de-France',
	'Nogent-sur-Oise': 'Hauts-de-France',
	'Nogent-sur-Marne': 'Île-de-France',
	Niort: 'Nouvelle-Aquitaine',
	Nîmes: 'Occitanie',
	Nice: "Provence-Alpes-Côte d'Azur",
	Nevers: 'Bourgogne-Franche-Comté',
	'Neuilly-sur-Seine': 'Île-de-France',
	'Neuilly-sur-Marne': 'Île-de-France',
	'Neuilly-Plaisance': 'Île-de-France',
	Narbonne: 'Occitanie',
	Nantes: 'Pays de la Loire',
	Nanterre: 'Île-de-France',
	Nancy: 'Grand Est',
	Muret: 'Occitanie',
	Mulhouse: 'Grand Est',
	Moulins: 'Auvergne-Rhône-Alpes',
	Mougins: "Provence-Alpes-Côte d'Azur",
	'Morsang-sur-Orge': 'Île-de-France',
	Morlaix: 'Brittany',
	'Mont-Saint-Aignan': 'Normandy',
	Montrouge: 'Île-de-France',
	Montreuil: 'Île-de-France',
	Montpellier: 'Occitanie',
	Montmorency: 'Île-de-France',
	Montluçon: 'Auvergne-Rhône-Alpes',
	Montivilliers: 'Normandy',
	'Montigny-lès-Metz': 'Grand Est',
	'Montigny-lès-Cormeilles': 'Île-de-France',
	'Montigny-le-Bretonneux': 'Île-de-France',
	Montgeron: 'Île-de-France',
	Montfermeil: 'Île-de-France',
	Montesson: 'Île-de-France',
	'Montereau-Fault-Yonne': 'Île-de-France',
	Montélimar: 'Auvergne-Rhône-Alpes',
	'Mont-de-Marsan': 'Nouvelle-Aquitaine',
	'Montceau-les-Mines': 'Bourgogne-Franche-Comté',
	Montbrison: 'Auvergne-Rhône-Alpes',
	Montbéliard: 'Bourgogne-Franche-Comté',
	Montauban: 'Occitanie',
	Montargis: 'Centre',
	'Mons-en-Barœul': 'Hauts-de-France',
	'Moissy-Cramayel': 'Île-de-France',
	'Mitry-Mory': 'Île-de-France',
	Miramas: "Provence-Alpes-Côte d'Azur",
	Millau: 'Occitanie',
	Meyzieu: 'Auvergne-Rhône-Alpes',
	Meylan: 'Auvergne-Rhône-Alpes',
	Meudon: 'Île-de-France',
	Metz: 'Grand Est',
	Mérignac: 'Nouvelle-Aquitaine',
	Menton: "Provence-Alpes-Côte d'Azur",
	Melun: 'Île-de-France',
	Meaux: 'Île-de-France',
	Mazargues: "Provence-Alpes-Côte d'Azur",
	Mayenne: 'Pays de la Loire',
	Maurepas: 'Île-de-France',
	Mauguio: 'Occitanie',
	Maubeuge: 'Hauts-de-France',
	Massy: 'Jalal-Abad',
	Martigues: "Provence-Alpes-Côte d'Azur",
	Marseille: "Provence-Alpes-Côte d'Azur",
	Marmande: 'Nouvelle-Aquitaine',
	'Marly-le-Roi': 'Île-de-France',
	Marignane: "Provence-Alpes-Côte d'Azur",
	'Marcq-en-Barœul': 'Hauts-de-France',
	'Mantes-la-Ville': 'Île-de-France',
	'Mantes-la-Jolie': 'Île-de-France',
	Manosque: "Provence-Alpes-Côte d'Azur",
	'Mandelieu-la-Napoule': "Provence-Alpes-Côte d'Azur",
	Malakoff: 'Île-de-France',
	'Maisons-Laffitte': 'Île-de-France',
	'Maisons-Alfort': 'Île-de-France',
	Mâcon: 'Bourgogne-Franche-Comté',
	Lyon: 'Auvergne-Rhône-Alpes',
	Lunéville: 'Grand Est',
	Lunel: 'Occitanie',
	Lucé: 'Centre',
	Louviers: 'Normandy',
	Lourdes: 'Occitanie',
	Lormont: 'Nouvelle-Aquitaine',
	Lorient: 'Brittany',
	Loos: 'Hauts-de-France',
	'Lons-le-Saunier': 'Bourgogne-Franche-Comté',
	Longjumeau: 'Île-de-France',
	Lomme: 'Hauts-de-France',
	Lognes: 'Île-de-France',
	'Livry-Gargan': 'Île-de-France',
	"L'Isle-sur-la-Sorgue": "Provence-Alpes-Côte d'Azur",
	Lisieux: 'Normandy',
	Lingolsheim: 'Grand Est',
	Limoges: 'Nouvelle-Aquitaine',
	'Limeil-Brévannes': 'Île-de-France',
	Limay: 'Central Luzon',
	Liévin: 'Hauts-de-France',
	Libourne: 'Nouvelle-Aquitaine',
	"L'Haÿ-les-Roses": 'Île-de-France',
	'Le Vésinet': 'Île-de-France',
	'Levallois-Perret': 'Île-de-France',
	"Les Sables-d'Olonne": 'Pays de la Loire',
	'Les Pennes-Mirabeau': "Provence-Alpes-Côte d'Azur",
	'Les Pavillons-sous-Bois': 'Île-de-France',
	'Les Olives': "Provence-Alpes-Côte d'Azur",
	'Les Mureaux': 'Île-de-France',
	'Les Lilas': 'Île-de-France',
	'Les Herbiers': 'Pays de la Loire',
	'Les Clayes-sous-Bois': 'Île-de-France',
	'Le Puy-en-Velay': 'Auvergne-Rhône-Alpes',
	'Le Pré-Saint-Gervais': 'Île-de-France',
	'Le Pontet': "Provence-Alpes-Côte d'Azur",
	'Le Plessis-Trévise': 'Île-de-France',
	'Le Plessis-Robinson': 'Île-de-France',
	'Le Petit-Quevilly': 'Normandy',
	'Le Perreux-sur-Marne': 'Île-de-France',
	'Le Pecq': 'Île-de-France',
	Lens: 'Hauts-de-France',
	'Le Mée-sur-Seine': 'Île-de-France',
	'Le Mans': 'Pays de la Loire',
	'Le Kremlin-Bicêtre': 'Île-de-France',
	'Le Havre': 'Normandy',
	'Le Grand-Quevilly': 'Normandy',
	'Le Creusot': 'Bourgogne-Franche-Comté',
	'Le Chesnay': 'Île-de-France',
	'Le Cannet': "Provence-Alpes-Côte d'Azur",
	'Le Bouscat': 'Nouvelle-Aquitaine',
	'Le Blanc-Mesnil': 'Île-de-France',
	Laxou: 'Grand Est',
	'La Valette-du-Var': "Provence-Alpes-Côte d'Azur",
	Lattes: 'Occitanie',
	'La Teste-de-Buch': 'Nouvelle-Aquitaine',
	'La Seyne-sur-Mer': "Provence-Alpes-Côte d'Azur",
	'La Roche-sur-Yon': 'Pays de la Loire',
	'La Rochelle': 'Nouvelle-Aquitaine',
	'La Pomme': "Provence-Alpes-Côte d'Azur",
	Laon: 'Hauts-de-France',
	Lannion: 'Brittany',
	Lanester: 'Brittany',
	Landerneau: 'Brittany',
	Lambersart: 'Hauts-de-France',
	'La Madeleine': 'Hauts-de-France',
	'Lagny-sur-Marne': 'Île-de-France',
	'La Garenne-Colombes': 'Île-de-France',
	'La Garde': "Provence-Alpes-Côte d'Azur",
	'La Flèche': 'Pays de la Loire',
	'La Crau': "Provence-Alpes-Côte d'Azur",
	'La Courneuve': 'Île-de-France',
	'La Ciotat': "Provence-Alpes-Côte d'Azur",
	'La Chapelle-sur-Erdre': 'Pays de la Loire',
	'La Celle-Saint-Cloud': 'Île-de-France',
	'Jouy-le-Moutier': 'Île-de-France',
	'Joué-lès-Tours': 'Centre',
	'Joinville-le-Pont': 'Île-de-France',
	'Ivry-sur-Seine': 'Île-de-France',
	Istres: "Provence-Alpes-Côte d'Azur",
	'Issy-les-Moulineaux': 'Île-de-France',
	Issoire: 'Auvergne-Rhône-Alpes',
	Illzach: 'Grand Est',
	'Illkirch-Graffenstaden': 'Grand Est',
	Hyères: "Provence-Alpes-Côte d'Azur",
	Houilles: 'Île-de-France',
	'Hérouville-Saint-Clair': 'Normandy',
	Herblay: 'Île-de-France',
	'Hénin-Beaumont': 'Hauts-de-France',
	Hem: 'Hauts-de-France',
	Hazebrouck: 'Hauts-de-France',
	Hayange: 'Grand Est',
	Hautmont: 'Hauts-de-France',
	Yutz: 'Grand Est',
	Haubourdin: 'Hauts-de-France',
	Halluin: 'Hauts-de-France',
	Haguenau: 'Grand Est',
	Guyancourt: 'Île-de-France',
	'Gujan-Mestras': 'Nouvelle-Aquitaine',
	Guéret: 'Nouvelle-Aquitaine',
	Guérande: 'Pays de la Loire',
	Grigny: 'Île-de-France',
	Grenoble: 'Auvergne-Rhône-Alpes',
	Grasse: "Provence-Alpes-Côte d'Azur",
	'Grande-Synthe': 'Hauts-de-France',
	Gradignan: 'Nouvelle-Aquitaine',
	Goussainville: 'Île-de-France',
	Gonesse: 'Île-de-France',
	Givors: 'Auvergne-Rhône-Alpes',
	'Gif-sur-Yvette': 'Île-de-France',
	Gien: 'Centre',
	Gentilly: 'Île-de-France',
	Gennevilliers: 'Île-de-France',
	'Garges-lès-Gonesse': 'Île-de-France',
	Gardanne: "Provence-Alpes-Côte d'Azur",
	Garches: 'Île-de-France',
	Gap: "Provence-Alpes-Côte d'Azur",
	Gagny: 'Île-de-France',
	Frontignan: 'Occitanie',
	Fresnes: 'Île-de-France',
	Fréjus: "Provence-Alpes-Côte d'Azur",
	Franconville: 'Île-de-France',
	Fougères: 'Brittany',
	'Fos-sur-Mer': "Provence-Alpes-Côte d'Azur",
	Forbach: 'Grand Est',
	'Fontenay-sous-Bois': 'Île-de-France',
	'Fontenay-le-Comte': 'Pays de la Loire',
	'Fontenay-aux-Roses': 'Île-de-France',
	Fontainebleau: 'Île-de-France',
	Fontaine: 'Auvergne-Rhône-Alpes',
	Floirac: 'Nouvelle-Aquitaine',
	'Fleury-les-Aubrais': 'Centre',
	Flers: 'Normandy',
	Firminy: 'Auvergne-Rhône-Alpes',
	Fécamp: 'Normandy',
	'Faches-Thumesnil': 'Hauts-de-France',
	Eysines: 'Nouvelle-Aquitaine',
	Évry: 'Île-de-France',
	Évreux: 'Normandy',
	Étampes: 'Île-de-France',
	'La Baule-Escoublac': 'Pays de la Loire',
	Ermont: 'Île-de-France',
	Éragny: 'Île-de-France',
	'Équeurdreville-Hainneville': 'Normandy',
	'Épinay-sur-Seine': 'Île-de-France',
	Épinal: 'Grand Est',
	Épernay: 'Grand Est',
	Elbeuf: 'Normandy',
	Élancourt: 'Île-de-France',
	Écully: 'Auvergne-Rhône-Alpes',
	Échirolles: 'Auvergne-Rhône-Alpes',
	Eaubonne: 'Île-de-France',
	Dunkerque: 'Hauts-de-France',
	Dreux: 'Centre',
	Draveil: 'Île-de-France',
	Drancy: 'Île-de-France',
	Draguignan: "Provence-Alpes-Côte d'Azur",
	Douarnenez: 'Brittany',
	Douai: 'Hauts-de-France',
	Domont: 'Île-de-France',
	Dole: 'Bourgogne-Franche-Comté',
	Dijon: 'Bourgogne-Franche-Comté',
	'Digne-les-Bains': "Provence-Alpes-Côte d'Azur",
	'Deuil-la-Barre': 'Île-de-France',
	Denain: 'Hauts-de-France',
	'Décines-Charpieu': 'Auvergne-Rhône-Alpes',
	Dax: 'Nouvelle-Aquitaine',
	'Dammarie-les-Lys': 'Île-de-France',
	Cugnaux: 'Occitanie',
	Croix: 'Hauts-de-France',
	Créteil: 'Île-de-France',
	'Crépy-en-Valois': 'Hauts-de-France',
	Creil: 'Hauts-de-France',
	'Cran-Gevrier': 'Auvergne-Rhône-Alpes',
	"Cournon-d'Auvergne": 'Auvergne-Rhône-Alpes',
	Courbevoie: 'Île-de-France',
	Coulommiers: 'Île-de-France',
	Couëron: 'Pays de la Loire',
	'Coudekerque-Branche': 'Hauts-de-France',
	'Cormeilles-en-Parisis': 'Île-de-France',
	'Corbeil-Essonnes': 'Île-de-France',
	'Conflans-Sainte-Honorine': 'Île-de-France',
	Concarneau: 'Brittany',
	Compiègne: 'Hauts-de-France',
	'Combs-la-Ville': 'Île-de-France',
	'Pontault-Combault': 'Île-de-France',
	Colomiers: 'Occitanie',
	Colombes: 'Île-de-France',
	Colmar: 'Grand Est',
	Cognac: 'Nouvelle-Aquitaine',
	Cluses: 'Auvergne-Rhône-Alpes',
	'Clichy-sous-Bois': 'Île-de-France',
	Clichy: 'Île-de-France',
	'Clermont-Ferrand': 'Auvergne-Rhône-Alpes',
	Clamart: 'Île-de-France',
	Cholet: 'Pays de la Loire',
	'Choisy-le-Roi': 'Île-de-France',
	'Chilly-Mazarin': 'Île-de-France',
	'Chevilly-Larue': 'Île-de-France',
	'Cherbourg-Octeville': 'Normandy',
	Chenôve: 'Bourgogne-Franche-Comté',
	'Chennevières-sur-Marne': 'Île-de-France',
	Chelles: 'Île-de-France',
	Chaville: 'Île-de-France',
	Chaumont: 'Grand Est',
	Chatou: 'Île-de-France',
	Châtillon: 'Île-de-France',
	'Châtenay-Malabry': 'Île-de-France',
	Châtellerault: 'Nouvelle-Aquitaine',
	'Château-Thierry': 'Hauts-de-France',
	Châteauroux: 'Centre',
	Châteaudun: 'Centre',
	Chartres: 'Centre',
	'Charleville-Mézières': 'Grand Est',
	'Charenton-le-Pont': 'Île-de-France',
	'Champs-sur-Marne': 'Île-de-France',
	'Champigny-sur-Marne': 'Île-de-France',
	Chambéry: 'Auvergne-Rhône-Alpes',
	Chamalières: 'Auvergne-Rhône-Alpes',
	'Chalon-sur-Saône': 'Bourgogne-Franche-Comté',
	'Châlons-en-Champagne': 'Grand Est',
	Challans: 'Pays de la Loire',
	Cestas: 'Nouvelle-Aquitaine',
	'Cesson-Sévigné': 'Brittany',
	Cergy: 'Île-de-France',
	Cenon: 'Nouvelle-Aquitaine',
	Cavaillon: "Provence-Alpes-Côte d'Azur",
	Castres: 'Occitanie',
	'Castelnau-le-Lez': 'Occitanie',
	Carvin: 'Hauts-de-France',
	'Carrières-sous-Poissy': 'Île-de-France',
	Carquefou: 'Pays de la Loire',
	Carpentras: "Provence-Alpes-Côte d'Azur",
	Carcassonne: 'Occitanie',
	Canteleu: 'Normandy',
	Cannes: "Provence-Alpes-Côte d'Azur",
	Cambrai: 'Hauts-de-France',
	'Caluire-et-Cuire': 'Auvergne-Rhône-Alpes',
	Calais: 'Hauts-de-France',
	Cahors: 'Occitanie',
	'Cagnes-sur-Mer': "Provence-Alpes-Côte d'Azur",
	Caen: 'Normandy',
	Cachan: 'Île-de-France',
	'Bry-sur-Marne': 'Île-de-France',
	Brunoy: 'Île-de-France',
	'Bruay-la-Buissière': 'Hauts-de-France',
	Bron: 'Auvergne-Rhône-Alpes',
	'Brive-la-Gaillarde': 'Nouvelle-Aquitaine',
	Brignoles: "Provence-Alpes-Côte d'Azur",
	'Brie-Comte-Robert': 'Île-de-France',
	'Brétigny-sur-Orge': 'Île-de-France',
	Bressuire: 'Nouvelle-Aquitaine',
	'Bourg-lès-Valence': 'Auvergne-Rhône-Alpes',
	'Bourg-la-Reine': 'Île-de-France',
	Bourges: 'Centre',
	'Bourg-en-Bresse': 'Auvergne-Rhône-Alpes',
	'Boulogne-sur-Mer': 'Hauts-de-France',
	'Boulogne-Billancourt': 'Île-de-France',
	Bouguenais: 'Pays de la Loire',
	Bordeaux: 'Nouvelle-Aquitaine',
	'Bonneuil-sur-Marne': 'Île-de-France',
	Bondy: 'Île-de-France',
	'Boissy-Saint-Léger': 'Île-de-France',
	'Bois-Colombes': 'Île-de-France',
	Bobigny: 'Île-de-France',
	Blois: 'Centre',
	Blanquefort: 'Nouvelle-Aquitaine',
	Blagnac: 'Occitanie',
	Bischheim: 'Grand Est',
	Biarritz: 'Nouvelle-Aquitaine',
	Bezons: 'Île-de-France',
	Béziers: 'Occitanie',
	Béthune: 'Hauts-de-France',
	Besançon: 'Bourgogne-Franche-Comté',
	Bergerac: 'Nouvelle-Aquitaine',
	Berck: 'Hauts-de-France',
	'Berck-Plage': 'Hauts-de-France',
	Belfort: 'Bourgogne-Franche-Comté',
	Bègles: 'Nouvelle-Aquitaine',
	Beauvais: 'Hauts-de-France',
	Beaune: 'Bourgogne-Franche-Comté',
	Bayonne: 'New Jersey',
	Bastia: 'Corsica',
	'Bar-le-Duc': 'Grand Est',
	Balma: 'Occitanie',
	'Bagnols-sur-Cèze': 'Occitanie',
	Bagnolet: 'Île-de-France',
	Bagneux: 'Île-de-France',
	Avon: 'Ohio',
	Avion: 'Hauts-de-France',
	Avignon: "Provence-Alpes-Côte d'Azur",
	Auxerre: 'Bourgogne-Franche-Comté',
	Autun: 'Bourgogne-Franche-Comté',
	Aurillac: 'Auvergne-Rhône-Alpes',
	'Aulnay-sous-Bois': 'Île-de-France',
	Audincourt: 'Bourgogne-Franche-Comté',
	Auch: 'Occitanie',
	Aubervilliers: 'Île-de-France',
	Aubagne: "Provence-Alpes-Côte d'Azur",
	'Athis-Mons': 'Île-de-France',
	'Asnières-sur-Seine': 'Île-de-France',
	Arras: 'Hauts-de-France',
	Armentières: 'Hauts-de-France',
	Arles: "Provence-Alpes-Côte d'Azur",
	Argenteuil: 'Île-de-France',
	Argentan: 'Normandy',
	Arcueil: 'Île-de-France',
	Antony: 'Île-de-France',
	Antibes: "Provence-Alpes-Côte d'Azur",
	Annonay: 'Auvergne-Rhône-Alpes',
	Annemasse: 'Auvergne-Rhône-Alpes',
	'Annecy-le-Vieux': 'Auvergne-Rhône-Alpes',
	Annecy: 'Auvergne-Rhône-Alpes',
	Angoulême: 'Nouvelle-Aquitaine',
	Anglet: 'Nouvelle-Aquitaine',
	Angers: 'Pays de la Loire',
	Amiens: 'Hauts-de-France',
	Allauch: "Provence-Alpes-Côte d'Azur",
	Alfortville: 'Île-de-France',
	Alès: 'Occitanie',
	Alençon: 'Normandy',
	Albi: 'Occitanie',
	Albertville: 'Alabama',
	Ajaccio: 'Corsica',
	'Aix-les-Bains': 'Auvergne-Rhône-Alpes',
	'Aix-en-Provence': "Provence-Alpes-Côte d'Azur",
	Agen: 'Nouvelle-Aquitaine',
	Agde: 'Occitanie',
	Achères: 'Île-de-France',
	Abbeville: 'Hauts-de-France',
	"Villeneuve-d'Ascq": 'Hauts-de-France',
	'Lyon 02': 'Auvergne-Rhône-Alpes',
	'Lyon 03': 'Auvergne-Rhône-Alpes',
	'Lyon 04': 'Auvergne-Rhône-Alpes',
	'Lyon 05': 'Auvergne-Rhône-Alpes',
	'Lyon 06': 'Auvergne-Rhône-Alpes',
	'Lyon 07': 'Auvergne-Rhône-Alpes',
	'Lyon 08': 'Auvergne-Rhône-Alpes',
	'Lyon 09': 'Auvergne-Rhône-Alpes',
	'Les Ulis': 'Île-de-France',
	'Bourgoin-Jallieu': 'Auvergne-Rhône-Alpes',
	'Lyon 01': 'Auvergne-Rhône-Alpes',
	'Marseille 01': "Provence-Alpes-Côte d'Azur",
	'Marseille 02': "Provence-Alpes-Côte d'Azur",
	'Marseille 03': "Provence-Alpes-Côte d'Azur",
	'Marseille 04': "Provence-Alpes-Côte d'Azur",
	'Marseille 05': "Provence-Alpes-Côte d'Azur",
	'Marseille 06': "Provence-Alpes-Côte d'Azur",
	'Marseille 07': "Provence-Alpes-Côte d'Azur",
	'Marseille 08': "Provence-Alpes-Côte d'Azur",
	'Marseille 10': "Provence-Alpes-Côte d'Azur",
	'Marseille 09': "Provence-Alpes-Côte d'Azur",
	'Marseille 11': "Provence-Alpes-Côte d'Azur",
	'Marseille 12': "Provence-Alpes-Côte d'Azur",
	'Marseille 13': "Provence-Alpes-Côte d'Azur",
	'Marseille 14': "Provence-Alpes-Côte d'Azur",
	'Marseille 15': "Provence-Alpes-Côte d'Azur",
	'Marseille 16': "Provence-Alpes-Côte d'Azur",
	'La Defense': 'Île-de-France',
	'Saint-Quentin-en-Yvelines': 'Île-de-France',
	'Cergy-Pontoise': 'Île-de-France',
	'La Blancarde': "Provence-Alpes-Côte d'Azur",
	'Le Camas': "Provence-Alpes-Côte d'Azur",
	'Saint-Barthélémy': "Provence-Alpes-Côte d'Azur",
	Tchibanga: 'Nyanga',
	'Port-Gentil': 'Ogooué-Maritime',
	Oyem: 'Woleu-Ntem',
	Mouila: 'Ngouni',
	Libreville: 'Estuaire',
	Lambaréné: 'Moyen-Ogooué',
	Koulamoutou: 'Ogooué-Lolo',
	Franceville: 'Haut-Ogooué',
	York: 'Pennsylvania',
	Yeovil: 'England',
	Yeadon: 'England',
	Yate: 'England',
	Yarm: 'England',
	Wrexham: 'Wales',
	Worthing: 'England',
	Worksop: 'England',
	Workington: 'England',
	'Worcester Park': 'England',
	Worcester: 'Western Cape',
	'Wood Green': 'England',
	'Woodford Green': 'England',
	Wombwell: 'England',
	Wolverhampton: 'England',
	Wokingham: 'England',
	Woking: 'England',
	Witney: 'England',
	Witham: 'England',
	Wishaw: 'Scotland',
	Wisbech: 'England',
	Winsford: 'England',
	Winchester: 'Nevada',
	Wimbledon: 'England',
	Wilmslow: 'England',
	Willesden: 'England',
	Willenhall: 'England',
	'Wigston Magna': 'England',
	Wigan: 'England',
	Widnes: 'England',
	Wickford: 'England',
	Whitstable: 'England',
	'Whitley Bay': 'England',
	Whitehaven: 'England',
	Whitefield: 'England',
	Whickham: 'England',
	Weymouth: 'Massachusetts',
	Weybridge: 'England',
	'Weston-super-Mare': 'England',
	'West Molesey': 'England',
	'City of Westminster': 'England',
	Westhoughton: 'England',
	'West Ham': 'England',
	Westbury: 'New York',
	'West Bromwich': 'England',
	'West Bridgford': 'England',
	Wembley: 'England',
	'Welwyn Garden City': 'England',
	Wellington: 'Western Cape',
	Wellingborough: 'England',
	Welling: 'England',
	Wednesfield: 'England',
	Wednesbury: 'England',
	'Wath upon Dearne': 'England',
	Watford: 'England',
	Waterlooville: 'England',
	Washington: 'Utah',
	Warrington: 'England',
	Warminster: 'England',
	Ware: 'England',
	Wantage: 'England',
	'Walton-on-Thames': 'England',
	'Walton-on-the-Naze': 'England',
	Walthamstow: 'England',
	'Waltham Abbey': 'England',
	Walsall: 'England',
	Wallsend: 'England',
	Wallington: 'England',
	Wallasey: 'England',
	Walkden: 'England',
	Wakefield: 'New York',
	Urmston: 'England',
	'Upper Norwood': 'England',
	Uckfield: 'England',
	Tynemouth: 'England',
	Tyldesley: 'England',
	Truro: 'England',
	Trowbridge: 'England',
	Totton: 'England',
	Totteridge: 'England',
	Tottenham: 'England',
	Torquay: 'England',
	Tonbridge: 'England',
	Tiverton: 'England',
	Tipton: 'England',
	Thorne: 'England',
	'Thornaby-on-Tees': 'England',
	Thetford: 'England',
	Thatcham: 'England',
	Tewkesbury: 'England',
	Teignmouth: 'England',
	Taunton: 'Massachusetts',
	Tadley: 'England',
	Swinton: 'England',
	Swindon: 'England',
	Swansea: 'Massachusetts',
	Swanscombe: 'England',
	Swanley: 'England',
	Swadlincote: 'England',
	'Sutton in Ashfield': 'England',
	'Sutton Coldfield': 'England',
	Sutton: 'England',
	Sunderland: 'England',
	'Sunbury-on-Thames': 'England',
	Sudbury: 'Massachusetts',
	Stroud: 'England',
	Strood: 'England',
	Stretford: 'England',
	Streatham: 'England',
	'Stratford-upon-Avon': 'England',
	Stowmarket: 'England',
	'Stourport-on-Severn': 'England',
	Stourbridge: 'England',
	'Stoke-on-Trent': 'England',
	'Stockton-on-Tees': 'England',
	Stockport: 'England',
	Stirling: 'Scotland',
	Stevenage: 'England',
	Stepney: 'England',
	Staveley: 'England',
	'Stanford-le-Hope': 'England',
	Stamford: 'Connecticut',
	Stalybridge: 'England',
	Staines: 'England',
	Stafford: 'Texas',
	Spennymoor: 'England',
	Spalding: 'England',
	'South Shields': 'England',
	Southsea: 'England',
	'South Ockendon': 'England',
	'South Norwood': 'England',
	'South Hayling': 'England',
	'Southend-on-Sea': 'England',
	'South Elmsall': 'England',
	'South Benfleet': 'England',
	Southampton: 'England',
	Southall: 'England',
	Solihull: 'England',
	Slough: 'England',
	Sleaford: 'England',
	Skelmersdale: 'England',
	Skegness: 'England',
	Sittingbourne: 'England',
	Shrewsbury: 'Massachusetts',
	'Shoreham-by-Sea': 'England',
	Shipley: 'England',
	'Shepherds Bush': 'England',
	Sheffield: 'England',
	Sevenoaks: 'England',
	Selby: 'England',
	Seaham: 'England',
	Scunthorpe: 'England',
	Sandown: 'England',
	Sandhurst: 'England',
	Sandbach: 'England',
	Saltash: 'England',
	Salisbury: 'North Carolina',
	Salford: 'England',
	Sale: 'Rabat-Salé-Kénitra',
	'Saint Peters': 'Missouri',
	'Saint Neots': 'England',
	'St Helens': 'England',
	'St Austell': 'England',
	'Saint Andrews': 'South Carolina',
	'Saffron Walden': 'England',
	Ryton: 'England',
	Rutherglen: 'Scotland',
	Rushden: 'England',
	Runcorn: 'England',
	Ruislip: 'England',
	Rugeley: 'England',
	Rugby: 'England',
	Royton: 'England',
	Royston: 'England',
	'Royal Tunbridge Wells': 'England',
	Rottingdean: 'England',
	Rotherham: 'England',
	Romsey: 'England',
	Romford: 'England',
	Roehampton: 'England',
	Rochford: 'England',
	Rochester: 'New York',
	Rochdale: 'England',
	Risca: 'Wales',
	Ripon: 'California',
	Ripley: 'England',
	Rhyl: 'Wales',
	Rhosllanerchrugog: 'Wales',
	Rhondda: 'Wales',
	Renfrew: 'Scotland',
	Reigate: 'England',
	Redruth: 'England',
	Redhill: 'England',
	Redditch: 'England',
	Redcar: 'England',
	Reading: 'Pennsylvania',
	Rayleigh: 'England',
	Rawtenstall: 'England',
	Rawmarsh: 'England',
	Ramsgate: 'England',
	Ramsbottom: 'England',
	Putney: 'England',
	Purley: 'England',
	Pudsey: 'England',
	Prestwich: 'England',
	Prestatyn: 'Wales',
	Prescot: 'England',
	'Poulton-le-Fylde': 'England',
	'Potters Bar': 'England',
	Portsmouth: 'Rhode Island',
	Portslade: 'England',
	Portishead: 'England',
	Porthcawl: 'Wales',
	'Port Glasgow': 'Scotland',
	Portadown: 'Northern Ireland',
	Poole: 'England',
	Pontypridd: 'Wales',
	Pontypool: 'Wales',
	Pontefract: 'England',
	'Ponders End': 'England',
	Plymstock: 'England',
	Plymouth: 'Minnesota',
	Plumstead: 'England',
	Pitsea: 'England',
	Pinner: 'England',
	Peterlee: 'England',
	Peterhead: 'Scotland',
	Penzance: 'England',
	Penrith: 'England',
	Penicuik: 'Scotland',
	Penarth: 'Wales',
	'Palmers Green': 'England',
	Paisley: 'Scotland',
	Paignton: 'England',
	Oxford: 'Ohio',
	Oswestry: 'England',
	Ossett: 'England',
	Orpington: 'England',
	Ormskirk: 'England',
	Omagh: 'Northern Ireland',
	Oldham: 'England',
	Oadby: 'England',
	Nuneaton: 'England',
	Nottingham: 'England',
	Norwich: 'Connecticut',
	Northwich: 'England',
	'North Shields': 'England',
	Northolt: 'England',
	Lancing: 'England',
	Northampton: 'Massachusetts',
	Northallerton: 'England',
	Norbury: 'England',
	Newtownards: 'Northern Ireland',
	Newtownabbey: 'Northern Ireland',
	'Newton Mearns': 'Scotland',
	'Newton-le-Willows': 'England',
	'Newton Aycliffe': 'England',
	'Newton Abbot': 'England',
	Newry: 'Northern Ireland',
	Newquay: 'England',
	'Newport Pagnell': 'England',
	Newport: 'Rhode Island',
	'New Milton': 'England',
	'New Mills': 'England',
	'New Malden': 'England',
	'New Cross': 'England',
	'Newcastle upon Tyne': 'England',
	'Newcastle under Lyme': 'England',
	Newbury: 'England',
	Newburn: 'England',
	'Newark on Trent': 'England',
	Neston: 'England',
	Nelson: 'Nelson',
	Neath: 'Wales',
	Nantwich: 'England',
	Nailsea: 'England',
	Musselburgh: 'Scotland',
	Mountsorrel: 'England',
	Motherwell: 'Scotland',
	Moreton: 'England',
	Morecambe: 'England',
	Morden: 'England',
	Mirfield: 'England',
	'Milton Keynes': 'England',
	Middleton: 'Wisconsin',
	Middlesbrough: 'England',
	Mexborough: 'England',
	'Merthyr Tydfil': 'Wales',
	'Melton Mowbray': 'England',
	Melksham: 'England',
	'Meikle Earnock': 'Scotland',
	Marple: 'England',
	Marlow: 'England',
	'Market Harborough': 'England',
	Margate: 'KwaZulu-Natal',
	March: 'England',
	'Mansfield Woodhouse': 'England',
	Mansfield: 'Ohio',
	Mangotsfield: 'England',
	Manchester: 'New Hampshire',
	Maltby: 'England',
	Maldon: 'England',
	Maidstone: 'England',
	Maidenhead: 'England',
	Maghull: 'England',
	Maesteg: 'Wales',
	Macclesfield: 'England',
	Lymington: 'England',
	Luton: 'England',
	Lowestoft: 'England',
	Louth: 'England',
	Loughborough: 'England',
	Longfield: 'England',
	'Long Eaton': 'England',
	'Londonderry County Borough': 'Northern Ireland',
	Derry: 'New Hampshire',
	Lofthouse: 'England',
	Llanelli: 'Wales',
	Llandudno: 'Wales',
	Livingston: 'New Jersey',
	Littlehampton: 'England',
	Litherland: 'England',
	Lisburn: 'Northern Ireland',
	Limehouse: 'England',
	Lichfield: 'England',
	Leyland: 'England',
	Lewes: 'England',
	'Leighton Buzzard': 'England',
	Leigh: 'England',
	Leicester: 'England',
	Leek: 'Groningen',
	Leeds: 'England',
	Leatherhead: 'England',
	'Royal Leamington Spa': 'England',
	Larne: 'Northern Ireland',
	Larkhall: 'Scotland',
	Lancaster: 'California',
	Kirkintilloch: 'Scotland',
	Kirkcaldy: 'Scotland',
	'Kirkby in Ashfield': 'England',
	Kirkby: 'England',
	Kippax: 'England',
	Kingswood: 'England',
	Kingswinford: 'England',
	'Kingston upon Hull': 'England',
	"King's Lynn": 'England',
	Kilwinning: 'Scotland',
	Kilmarnock: 'Scotland',
	Kidsgrove: 'England',
	Kidlington: 'England',
	Kidderminster: 'England',
	Keynsham: 'England',
	Kettering: 'Ohio',
	Kenilworth: 'England',
	Kendal: 'England',
	Kempston: 'England',
	Keighley: 'England',
	Johnstone: 'Scotland',
	Jarrow: 'England',
	Islington: 'England',
	Isleworth: 'England',
	Irvine: 'California',
	Irlam: 'England',
	Ipswich: 'England',
	Inverness: 'Scotland',
	Ilkeston: 'England',
	Ilford: 'England',
	Hythe: 'England',
	Hyde: 'England',
	Huyton: 'England',
	Huntingdon: 'England',
	Huddersfield: 'England',
	Hucknall: 'England',
	'Hoyland Nether': 'England',
	Hove: 'England',
	Hounslow: 'England',
	'Houghton-Le-Spring': 'England',
	Horwich: 'England',
	Horsham: 'England',
	Horsforth: 'England',
	Horley: 'England',
	Hook: 'England',
	Hoddesdon: 'England',
	Hitchin: 'England',
	Hindley: 'England',
	Hinckley: 'England',
	'High Wycombe': 'England',
	Highbury: 'England',
	'High Blantyre': 'Scotland',
	Heysham: 'England',
	Heswall: 'England',
	Heston: 'England',
	Hertford: 'England',
	'Herne Bay': 'England',
	Hereford: 'Texas',
	'Hemel Hempstead': 'England',
	Hebburn: 'England',
	Heanor: 'England',
	'Hazel Grove': 'England',
	'Haywards Heath': 'England',
	Hayes: 'England',
	Haydock: 'England',
	Hawarden: 'Wales',
	Haverhill: 'Massachusetts',
	Havant: 'England',
	Hatfield: 'England',
	Hastings: 'Nebraska',
	Haslingden: 'England',
	Harwich: 'England',
	Hartley: 'England',
	Hartlepool: 'England',
	Harrow: 'England',
	Harrogate: 'England',
	Harpenden: 'England',
	Harlow: 'England',
	Harlesden: 'England',
	Hanworth: 'England',
	Hampton: 'Virginia',
	Halesowen: 'England',
	Hale: 'England',
	Hailsham: 'England',
	Hadleigh: 'England',
	Guiseley: 'England',
	Guisborough: 'England',
	Guildford: 'England',
	Grove: 'England',
	Greenock: 'Scotland',
	Greenford: 'England',
	'Great Yarmouth': 'England',
	'Great Wyrley': 'England',
	'Great Sankey': 'England',
	'Great Malvern': 'England',
	Grays: 'England',
	Gravesend: 'New York',
	Grantham: 'England',
	Grangemouth: 'Scotland',
	Gosport: 'England',
	Gosforth: 'England',
	Gorseinon: 'Wales',
	Goole: 'England',
	Golborne: 'England',
	Godalming: 'England',
	Gloucester: 'Massachusetts',
	Glossop: 'England',
	Glenrothes: 'Scotland',
	Glasgow: 'Scotland',
	Gillingham: 'England',
	Gelligaer: 'Wales',
	Gateshead: 'England',
	Gainsborough: 'England',
	Fylde: 'England',
	Fulwood: 'England',
	Fulham: 'England',
	Frome: 'England',
	'Frinton-on-Sea': 'England',
	'Friern Barnet': 'England',
	Formby: 'England',
	Folkestone: 'England',
	Flint: 'Michigan',
	Fleetwood: 'England',
	Fleet: 'England',
	Finchley: 'England',
	Feltham: 'England',
	Felling: 'England',
	Felixstowe: 'England',
	Faversham: 'England',
	Farnworth: 'England',
	Farnham: 'England',
	Farnborough: 'England',
	Fareham: 'England',
	Falmouth: 'England',
	Falkirk: 'Scotland',
	Exmouth: 'England',
	Exeter: 'England',
	Evesham: 'England',
	Esher: 'England',
	Epsom: 'Auckland',
	'Enfield Lock': 'England',
	'Enfield Town': 'England',
	Emsworth: 'England',
	Ely: 'England',
	'Ellesmere Port Town': 'England',
	Elland: 'England',
	Elgin: 'Illinois',
	Egham: 'England',
	Edinburgh: 'Scotland',
	Eccles: 'England',
	'Ebbw Vale': 'Wales',
	Retford: 'England',
	'East Molesey': 'England',
	Eastleigh: 'England',
	'East Kilbride': 'Scotland',
	'East Grinstead': 'England',
	'East Dereham': 'England',
	Eastbourne: 'England',
	Durham: 'North Carolina',
	Dunstable: 'England',
	Dunfermline: 'Scotland',
	Dundee: 'KwaZulu-Natal',
	Dumfries: 'Scotland',
	Dumbarton: 'Scotland',
	Dukinfield: 'England',
	Dudley: 'England',
	Droylsden: 'England',
	Dronfield: 'England',
	Droitwich: 'England',
	Dover: 'New Jersey',
	Dorking: 'England',
	Dorchester: 'England',
	Dinnington: 'England',
	Didcot: 'England',
	Dewsbury: 'England',
	Devizes: 'England',
	Derby: 'Kansas',
	Denton: 'Texas',
	Deal: 'England',
	Daventry: 'England',
	Darwen: 'England',
	Darton: 'England',
	Dartford: 'England',
	Darlington: 'England',
	Dalserf: 'Scotland',
	Dagenham: 'England',
	Cwmbran: 'Wales',
	Cumbernauld: 'Scotland',
	Crowthorne: 'England',
	Crowborough: 'England',
	Cricklewood: 'England',
	Crewe: 'England',
	Crawley: 'England',
	Cramlington: 'England',
	Cowley: 'England',
	Cowes: 'England',
	Coventry: 'Rhode Island',
	Coulsdon: 'England',
	Corby: 'England',
	Consett: 'England',
	Conisbrough: 'England',
	Congleton: 'England',
	'Colwyn Bay': 'Wales',
	Colne: 'England',
	Coleraine: 'Northern Ireland',
	Colchester: 'Vermont',
	Coity: 'Wales',
	Cobham: 'England',
	Coatbridge: 'Scotland',
	Coalville: 'England',
	Clydebank: 'Scotland',
	Clydach: 'Wales',
	Clevedon: 'England',
	Cleethorpes: 'England',
	Cleckheaton: 'England',
	'Clacton-on-Sea': 'England',
	Cirencester: 'England',
	Christchurch: 'Canterbury',
	Chorley: 'England',
	Chiswick: 'England',
	Chislehurst: 'England',
	'Chipping Sodbury': 'England',
	Chippenham: 'England',
	Chingford: 'England',
	Chichester: 'England',
	'Chester-le-Street': 'England',
	Chesterfield: 'Missouri',
	Chester: 'Virginia',
	Chessington: 'England',
	Cheshunt: 'England',
	Chesham: 'England',
	Chepstow: 'Wales',
	Chelsea: 'Massachusetts',
	Chelmsford: 'Massachusetts',
	'Cheadle Hulme': 'England',
	Chapeltown: 'England',
	'Chalfont Saint Peter': 'England',
	Catford: 'England',
	Caterham: 'England',
	Castlereagh: 'Northern Ireland',
	Castleford: 'England',
	Carterton: 'England',
	Carshalton: 'England',
	Carrickfergus: 'Northern Ireland',
	Carmarthen: 'Wales',
	Carlisle: 'Pennsylvania',
	Cardiff: 'Wales',
	Canterbury: 'England',
	Cannock: 'England',
	Cambuslang: 'Scotland',
	Camborne: 'England',
	Camberley: 'England',
	Calne: 'England',
	Caerphilly: 'Wales',
	Buxton: 'England',
	Bushey: 'England',
	'Bury St Edmunds': 'England',
	Bury: 'England',
	'Burton upon Trent': 'England',
	Burntwood: 'England',
	Burnley: 'England',
	'Burnham-on-Sea': 'England',
	'Burgess Hill': 'England',
	Buckley: 'Wales',
	Brymbo: 'Wales',
	Broxburn: 'Scotland',
	Brownhills: 'England',
	Brough: 'England',
	Bromsgrove: 'England',
	Broadstairs: 'England',
	Brixham: 'England',
	'Briton Ferry': 'Wales',
	Bristol: 'Connecticut',
	Brighouse: 'England',
	'Brierley Hill': 'England',
	Bridlington: 'England',
	Bridgwater: 'England',
	Bridgend: 'Wales',
	Brentwood: 'California',
	Brent: 'Florida',
	Bredbury: 'England',
	Bramhall: 'England',
	Braintree: 'Massachusetts',
	Bradford: 'England',
	Bracknell: 'England',
	Bow: 'England',
	Bournemouth: 'England',
	Boston: 'Massachusetts',
	Borehamwood: 'England',
	Bootle: 'England',
	Bonnyrigg: 'Scotland',
	Bolton: 'England',
	'Bognor Regis': 'England',
	Blyth: 'England',
	Bloxwich: 'England',
	Bletchley: 'England',
	Blackwall: 'England',
	Blackpool: 'England',
	Blackburn: 'England',
	Bishopstoke: 'England',
	'Bishops Stortford': 'England',
	Bishopbriggs: 'Scotland',
	'Bishop Auckland': 'England',
	Birmingham: 'Michigan',
	Birkenhead: 'England',
	Bingley: 'England',
	Billingham: 'England',
	Billericay: 'England',
	Biggleswade: 'England',
	Bideford: 'England',
	Biddulph: 'England',
	Bicester: 'England',
	'Bexhill-on-Sea': 'England',
	Beverley: 'England',
	Berkhamsted: 'England',
	Bentley: 'England',
	Belper: 'England',
	Bellshill: 'Scotland',
	Belfast: 'Northern Ireland',
	Bedworth: 'England',
	Bedlington: 'England',
	Bedford: 'New Hampshire',
	Beckenham: 'England',
	Bebington: 'England',
	Bearsden: 'Scotland',
	Batley: 'England',
	Bathgate: 'Scotland',
	Bath: 'England',
	Basingstoke: 'England',
	Basildon: 'England',
	Basford: 'England',
	Barry: 'Wales',
	'Barrow in Furness': 'England',
	Barrhead: 'Scotland',
	Barnstaple: 'England',
	Barnsley: 'England',
	Barnet: 'England',
	Barnes: 'England',
	Barking: 'England',
	Banstead: 'England',
	Bangor: 'Maine',
	Banbury: 'England',
	Banbridge: 'Northern Ireland',
	Ballymena: 'Northern Ireland',
	Baildon: 'England',
	Ayr: 'Scotland',
	Aylesbury: 'England',
	Atherton: 'England',
	Aston: 'England',
	'Ashton-under-Lyne': 'England',
	'Ashton in Makerfield': 'England',
	Ashington: 'England',
	Ashford: 'England',
	Ascot: 'England',
	Arnold: 'Missouri',
	Arbroath: 'Scotland',
	Antrim: 'Northern Ireland',
	Andover: 'Minnesota',
	Ampthill: 'England',
	Ammanford: 'Wales',
	Amersham: 'England',
	Altrincham: 'England',
	Alton: 'Texas',
	Alloa: 'Scotland',
	Alfreton: 'England',
	Aldridge: 'England',
	Aldershot: 'England',
	'Adwick le Street': 'England',
	Acton: 'Massachusetts',
	'Acocks Green': 'England',
	Accrington: 'England',
	Abingdon: 'England',
	Aberystwyth: 'Wales',
	Abergele: 'Wales',
	Aberdeen: 'Washington',
	Aberdare: 'Wales',
	Crosby: 'England',
	Tonypandy: 'Wales',
	Blackwood: 'Wales',
	'Camden Town': 'England',
	Telford: 'England',
	Craigavon: 'Northern Ireland',
	Soho: 'England',
	Bayswater: 'England',
	Yateley: 'England',
	Bowthorpe: 'England',
	'Hedge End': 'England',
	'Gerrards Cross': 'England',
	Erskine: 'Scotland',
	Kilburn: 'England',
	'Chalk Farm': 'England',
	'Amersham on the Hill': 'England',
	Shadwell: 'England',
	Battersea: 'England',
	Tooting: 'England',
	'South Croydon': 'England',
	Hornchurch: 'England',
	Surbiton: 'England',
	Ewell: 'England',
	Becontree: 'England',
	Brixton: 'England',
	'Bethnal Green': 'England',
	Failsworth: 'England',
	Radcliffe: 'England',
	Heywood: 'England',
	Longsight: 'England',
	Heavitree: 'England',
	Ferndown: 'England',
	'Canary Wharf': 'England',
	Leytonstone: 'England',
	'Earl Shilton': 'England',
	Bordon: 'England',
	'Lytham St Annes': 'England',
	'Hadley Wood': 'England',
	'Muswell Hill': 'England',
	'Chapel Allerton': 'England',
	Blackheath: 'England',
	'Kempston Hardwick': 'England',
	Mendip: 'England',
	'Lower Earley': 'England',
	'Bartley Green': 'England',
	'Golders Green': 'England',
	'Canvey Island': 'England',
	Earlsfield: 'England',
	Peacehaven: 'England',
	'Letchworth Garden City': 'England',
	Peckham: 'England',
	Shirley: 'New York',
	Rossendale: 'England',
	'Thornton-Cleveleys': 'England',
	Deeside: 'Wales',
	'High Peak': 'England',
	'Hayling Island': 'England',
	'Isle of Lewis': 'Scotland',
	Holloway: 'England',
	Harringay: 'England',
	'Canning Town': 'England',
	'Manor Park': 'England',
	Thamesmead: 'England',
	Brockley: 'England',
	Blantyre: 'Southern Region',
	'Vale of Leven': 'Scotland',
	Viewpark: 'Scotland',
	'Stamford Hill': 'England',
	Burngreave: 'England',
	Archway: 'England',
	Sinfin: 'England',
	'Perry Vale': 'England',
	Furzedown: 'England',
	'Seven Sisters': 'England',
	'Tottenham Hale': 'England',
	'Washwood Heath': 'England',
	"Saint George's": 'Saint George',
	Zugdidi: 'Samegrelo and Zemo Svaneti',
	'Zest’aponi': 'Imereti',
	'Ts’khinvali': 'Shida Kartli',
	"Tqvarch'eli": 'Abkhazia',
	Telavi: 'Kakheti',
	Tbilisi: "T'bilisi",
	Sokhumi: 'Abkhazia',
	'Senak’i': 'Samegrelo and Zemo Svaneti',
	Samtredia: 'Imereti',
	Rustavi: 'Kvemo Kartli',
	'P’ot’i': 'Samegrelo and Zemo Svaneti',
	'Och’amch’ire': 'Abkhazia',
	Marneuli: 'Kvemo Kartli',
	Kutaisi: 'Imereti',
	Kobuleti: 'Achara',
	Khashuri: 'Shida Kartli',
	Gori: 'Shida Kartli',
	Batumi: 'Achara',
	Akhaltsikhe: 'Samtskhe-Javakheti',
	'Stantsiya Novyy Afon': 'Abkhazia',
	'Ts’q’alt’ubo': 'Imereti',
	'Saint-Laurent-du-Maroni': 'Guyane',
	'Rémire-Montjoly': 'Guyane',
	Matoury: 'Guyane',
	Kourou: 'Guyane',
	Cayenne: 'Guyane',
	'Saint Peter Port': 'St Peter Port',
	Yendi: 'Northern',
	Winneba: 'Central',
	Wankyi: 'Bono',
	Wa: 'Upper West',
	'Teshi Old Town': 'Greater Accra',
	Tema: 'Greater Accra',
	Techiman: 'Bono East',
	Tarkwa: 'Western',
	Tamale: 'Northern',
	Takoradi: 'Western',
	Tafo: 'Ashanti',
	Swedru: 'Central',
	Sunyani: 'Bono',
	Suhum: 'Eastern',
	'Shama Junction': 'Western',
	'Sekondi-Takoradi': 'Western',
	Savelugu: 'Northern',
	Saltpond: 'Central',
	Salaga: 'Savannah',
	Prestea: 'Western',
	Kasoa: 'Central',
	'Akim Oda': 'Eastern',
	Obuase: 'Ashanti',
	Nungua: 'Greater Accra',
	Nsawam: 'Eastern',
	Navrongo: 'Upper East',
	Mampong: 'Ashanti',
	'Medina Estates': 'Greater Accra',
	Kumasi: 'Ashanti',
	Kpandu: 'Volta',
	Konongo: 'Ashanti',
	Koforidua: 'Eastern',
	Kintampo: 'Bono East',
	Keta: 'Volta',
	Japekrom: 'Bono',
	Hohoe: 'Volta',
	Ho: 'Volta',
	Gbawe: 'Greater Accra',
	Foso: 'Central',
	Elmina: 'Central',
	Ejura: 'Ashanti',
	Dunkwa: 'Central',
	'Duayaw-Nkwanta': 'Ahafo',
	Dome: 'Greater Accra',
	'Cape Coast': 'Central',
	Bolgatanga: 'Upper East',
	Bibiani: 'Western North',
	Berekum: 'Bono',
	Begoro: 'Eastern',
	Bechem: 'Ahafo',
	Bawku: 'Upper East',
	Axim: 'Western',
	Asamankese: 'Eastern',
	Apam: 'Central',
	Anloga: 'Volta',
	Akwatia: 'Eastern',
	Agogo: 'Ashanti',
	Aflao: 'Volta',
	Atsiaman: 'Greater Accra',
	Accra: 'Greater Accra',
	Aburi: 'Eastern',
	Gibraltar: '',
	Nuuk: 'Sermersooq',
	Sukuta: 'Western',
	Serekunda: 'Banjul',
	Lamin: 'North Bank',
	Farafenni: 'North Bank',
	Brikama: 'Western',
	Banjul: 'Banjul',
	Bakau: 'Banjul',
	Tougué: 'Labe',
	'Tomba kanssa': 'Kankan',
	Télimélé: 'Kindia',
	Siguiri: 'Kankan',
	Pita: 'Mamou',
	Nzérékoré: 'Nzerekore',
	Mamou: 'Mamou',
	Macenta: 'Nzerekore',
	Labé: 'Labe',
	Kissidougou: 'Faranah',
	Kintinian: 'Kankan',
	Kindia: 'Kindia',
	Kankan: 'Kankan',
	Gueckedou: 'Nzerekore',
	Fria: 'Boke',
	Coyah: 'Kindia',
	Conakry: 'Conakry',
	Camayenne: 'Conakry',
	Boké: 'Boke',
	'Sainte-Rose': 'Guadeloupe',
	'Sainte-Anne': 'Guadeloupe',
	'Pointe-à-Pitre': 'Guadeloupe',
	'Petit-Bourg': 'Guadeloupe',
	'Les Abymes': 'Guadeloupe',
	'Le Moule': 'Guadeloupe',
	'Le Gosier': 'Guadeloupe',
	'Capesterre-Belle-Eau': 'Guadeloupe',
	'Basse-Terre': 'Guadeloupe',
	'Baie-Mahault': 'Guadeloupe',
	Ebebiyin: 'Kié-Ntem',
	Malabo: 'Bioko Norte',
	Bata: 'Litoral',
	Voúla: 'Attica',
	Volos: 'Thessaly',
	Výronas: 'Attica',
	Vári: 'Attica',
	Trípoli: 'Peloponnese',
	Tríkala: 'Thessaly',
	Thívai: 'Central Greece',
	Sparta: 'New Jersey',
	Salamína: 'Attica',
	Rethymno: 'Crete',
	Préveza: 'Epirus',
	Pýrgos: 'West Greece',
	Piraeus: 'Attica',
	Petroúpolis: 'Attica',
	Peristéri: 'Attica',
	Pérama: 'Attica',
	Pátra: 'West Greece',
	'Palaió Fáliro': 'Attica',
	Níkaia: 'Attica',
	'Néa Smýrni': 'Attica',
	'Néa Mákri': 'Attica',
	Ílion: 'Attica',
	'Néa Ionía': 'Thessaly',
	'Néa Filadélfeia': 'Attica',
	'Néa Erythraía': 'Attica',
	Moskháton: 'Attica',
	Mytilene: 'North Aegean',
	Melíssia: 'Attica',
	Mégara: 'Attica',
	Artémida: 'Attica',
	Livadeiá: 'Central Greece',
	Lárisa: 'Thessaly',
	Lamía: 'Central Greece',
	Metamórfosi: 'Attica',
	Kos: 'South Aegean',
	Koropí: 'Attica',
	Kórinthos: 'Peloponnese',
	Kifisiá: 'Attica',
	Cholargós: 'Attica',
	Chios: 'North Aegean',
	Chaniá: 'Crete',
	Chalkída: 'Central Greece',
	Khalándrion: 'Attica',
	Chaïdári: 'Attica',
	Keratsíni: 'Attica',
	Kardítsa: 'Thessaly',
	Kamaterón: 'Attica',
	Kallithéa: 'Attica',
	Kalamata: 'Peloponnese',
	Álimos: 'Attica',
	Kaisarianí: 'Attica',
	Irákleio: 'Attica',
	Irákleion: 'Crete',
	Ioánnina: 'Epirus',
	Glyfáda: 'Attica',
	Galátsi: 'Attica',
	Ellinikó: 'Attica',
	Elefsína: 'Attica',
	Dhafní: 'Attica',
	'Agios Dimitrios': 'Attica',
	'Agía Varvára': 'Attica',
	'Agía Paraskeví': 'Attica',
	Athens: 'Alabama',
	Asprópyrgos: 'Attica',
	Argyroúpoli: 'Attica',
	Árta: 'Epirus',
	Árgos: 'Peloponnese',
	'Áno Liósia': 'Attica',
	Maroúsi: 'Attica',
	Amaliáda: 'West Greece',
	Acharnés: 'Attica',
	Aígio: 'West Greece',
	Aigáleo: 'Attica',
	Agrínio: 'West Greece',
	Ródos: 'South Aegean',
	Giannitsá: 'Central Macedonia',
	Xánthi: 'East Macedonia and Thrace',
	Véroia: 'Central Macedonia',
	Thessaloníki: 'Central Macedonia',
	Stavroúpoli: 'Central Macedonia',
	Sykiés: 'Central Macedonia',
	Sérres: 'Central Macedonia',
	Ptolemaḯda: 'West Macedonia',
	Políchni: 'Central Macedonia',
	Pylaía: 'Central Macedonia',
	Peraía: 'Central Macedonia',
	Panórama: 'Central Macedonia',
	Orestiáda: 'East Macedonia and Thrace',
	Oraiókastro: 'Central Macedonia',
	Náousa: 'Central Macedonia',
	Meneméni: 'Central Macedonia',
	Kozáni: 'West Macedonia',
	Komotiní: 'East Macedonia and Thrace',
	Kilkís: 'Central Macedonia',
	Kavála: 'East Macedonia and Thrace',
	Kateríni: 'Central Macedonia',
	Kalamariá: 'Central Macedonia',
	Flórina: 'West Macedonia',
	Évosmos: 'Central Macedonia',
	'Elefthério - Kordelió': 'Central Macedonia',
	Édessa: 'Central Macedonia',
	Dráma: 'East Macedonia and Thrace',
	Alexandroupoli: 'East Macedonia and Thrace',
	Corfu: 'Ionian Islands',
	Vrilissia: 'Attica',
	Gérakas: 'Attica',
	Ilioúpoli: 'Attica',
	Korydallós: 'Attica',
	Zográfos: 'Attica',
	Péfki: 'Attica',
	'Ágioi Anárgyroi': 'Attica',
	'Agios Ioannis Rentis': 'Attica',
	Neapoli: 'Central Macedonia',
	Grytviken: '',
	Zacapa: 'Zacapa',
	Yepocapa: 'Chimaltenango',
	'Villa Canales': 'Guatemala',
	Totonicapán: 'Totonicapán',
	'Tecpán Guatemala': 'Chimaltenango',
	Sumpango: 'Sacatepéquez',
	Soloma: 'Huehuetenango',
	Sololá: 'Sololá',
	'Santiago Sacatepéquez': 'Sacatepéquez',
	'Santiago Atitlán': 'Sololá',
	'Santa María de Jesús': 'Sacatepéquez',
	'Santa Lucía Cotzumalguapa': 'Escuintla',
	'Santa Cruz del Quiché': 'Quiché',
	'Santa Catarina Pinula': 'Guatemala',
	'Santa Bárbara': 'Barinas',
	'San Sebastián': 'Retalhuleu',
	'San Pedro Sacatepéquez': 'San Marcos',
	'San Pedro Carchá': 'Alta Verapaz',
	'San Pedro Ayampuc': 'Guatemala',
	'San Pablo Jocopilas': 'Suchitepeque',
	'San Luis Jilotepeque': 'Jalapa',
	'San Lucas Sacatepéquez': 'Sacatepéquez',
	'San Juan Sacatepéquez': 'Guatemala',
	'San José Pinula': 'Guatemala',
	'San Francisco Zapotitlán': 'Suchitepeque',
	'San Francisco El Alto': 'Totonicapán',
	'San Cristóbal Verapaz': 'Alta Verapaz',
	'San Benito': 'Texas',
	Sanarate: 'El Progreso',
	'San Andrés Xecul': 'Totonicapán',
	'San Andrés Itzapa': 'Chimaltenango',
	Salcajá: 'Quetzaltenango',
	Salamá: 'Baja Verapaz',
	Retalhuleu: 'Retalhuleu',
	Rabinal: 'Baja Verapaz',
	Quetzaltenango: 'Quetzaltenango',
	'Puerto San José': 'Escuintla',
	'Puerto Barrios': 'Izabal Department',
	Tiquisate: 'Escuintla',
	Poptún: 'Petén',
	Petapa: 'Guatemala',
	Patzún: 'Chimaltenango',
	Patzicía: 'Chimaltenango',
	Patulul: 'Suchitepeque',
	Panzos: 'Alta Verapaz',
	Palín: 'Escuintla',
	Ostuncalco: 'Quetzaltenango',
	'Nuevo San Carlos': 'Retalhuleu',
	Nebaj: 'Quiché',
	Nahualá: 'Sololá',
	Momostenango: 'Totonicapán',
	Mixco: 'Guatemala',
	Mazatenango: 'Suchitepeque',
	Masagua: 'Escuintla',
	Malacatán: 'San Marcos',
	Lívingston: 'Izabal Department',
	'La Gomera': 'Escuintla',
	'La Esperanza': 'Quetzaltenango',
	Jutiapa: 'Jutiapa',
	Jocotenango: 'Sacatepéquez',
	Jalpatagua: 'Jutiapa',
	Jalapa: 'Nueva Segovia Department',
	Jacaltenango: 'Huehuetenango',
	Huehuetenango: 'Huehuetenango',
	Guazacapán: 'Santa Rosa Department',
	Gualán: 'Zacapa',
	Fraijanes: 'Guatemala',
	Flores: 'Petén',
	Esquipulas: 'Chiquimula',
	Escuintla: 'Escuintla',
	'El Tejar': 'Chimaltenango',
	Guastatoya: 'El Progreso',
	'El Palmar': 'Quetzaltenango',
	'El Estor': 'Izabal Department',
	'El Asintal': 'Retalhuleu',
	Cuilapa: 'Santa Rosa Department',
	Comitancillo: 'San Marcos',
	Comalapa: 'Chiapas',
	Colomba: 'Quetzaltenango',
	Cobán: 'Alta Verapaz',
	Coatepeque: 'Quetzaltenango',
	'Ciudad Vieja': 'Sacatepéquez',
	'Guatemala City': 'Guatemala',
	Chisec: 'Alta Verapaz',
	Chiquimula: 'Chiquimula',
	Chinautla: 'Guatemala',
	Chimaltenango: 'Chimaltenango',
	Chichicastenango: 'Quiché',
	Chicacao: 'Suchitepeque',
	Cantel: 'Quetzaltenango',
	Cabricán: 'Quetzaltenango',
	Barberena: 'Santa Rosa Department',
	'Asunción Mita': 'Jutiapa',
	'Antigua Guatemala': 'Sacatepéquez',
	Amatitlán: 'Guatemala',
	Alotenango: 'Sacatepéquez',
	'Tamuning-Tumon-Harmon Village': 'Tamuning',
	'Yigo Village': 'Yigo',
	'Guam Government House': 'Hagatna',
	'Dededo Village': 'Dededo',
	Hagåtña: 'Hagatna',
	'Mangilao Village': 'Mangilao',
	Tamuning: 'Tamuning',
	Bissau: 'Bissau',
	Bafatá: 'Bafatá',
	'New Amsterdam': 'East Berbice-Corentyne',
	Linden: 'New Jersey',
	Georgetown: 'Texas',
	'Tsuen Wan': 'Tsuen Wan',
	'Yuen Long Kau Hui': 'Yuen Long',
	'Tuen Mun': 'Tuen Mun',
	'Tsing Yi Town': 'Kwai Tsing',
	'Tseung Kwan O': 'Sai Kung',
	'Tin Shui Wai': 'Yuen Long',
	'Tai Po': 'Tai Po',
	'Sha Tin': 'Sha Tin',
	'Sai Kung': 'Sai Kung',
	'Pok Fu Lam': 'Southern',
	'Ping Shan': 'Yuen Long',
	'Ma On Shan': 'Sha Tin',
	'Kwai Chung': 'Tsuen Wan',
	Kowloon: 'Kowloon City',
	'Hong Kong': 'Central and Western',
	Fanling: 'North',
	'Tung Chung': 'Islands',
	'Puerto Cortez': 'Cortés Department',
	Yoro: 'Yoro Department',
	Tocoa: 'Colón Department',
	Tela: 'Atlántida Department',
	Tegucigalpa: 'Francisco Morazán Department',
	Siguatepeque: 'Comayagua Department',
	'Santa Rosa de Copán': 'Copán Department',
	'San Pedro Sula': 'Cortés Department',
	Potrerillos: 'Cortés Department',
	Olanchito: 'Yoro Department',
	'La Lima': 'Cortés Department',
	'La Ceiba': 'Atlántida Department',
	Juticalpa: 'Olancho Department',
	'El Progreso': 'Yoro Department',
	'El Paraíso': 'El Paraíso Department',
	Danlí: 'El Paraíso Department',
	Comayagua: 'Comayagua Department',
	Cofradía: 'Cortés Department',
	'Ciudad Choluteca': 'Choluteca Department',
	Choloma: 'Cortés Department',
	Zaprešić: 'County of Zagreb',
	Zagreb: 'City of Zagreb',
	Zadar: 'County of Zadar',
	Vukovar: 'Vukovar-Sirmium',
	Vinkovci: 'Vukovar-Sirmium',
	'Velika Gorica': 'County of Zagreb',
	Varaždin: 'County of Varaždin',
	Split: 'Split-Dalmatia',
	Solin: 'Split-Dalmatia',
	'Slavonski Brod': 'Brod-Posavina',
	Požega: 'County of Požega-Slavonia',
	Sisak: 'County of Sisak-Moslavina',
	Šibenik: 'Šibenik-Knin',
	Sesvete: 'City of Zagreb',
	Samobor: 'County of Zagreb',
	Rijeka: 'County of Primorje-Gorski Kotar',
	Pula: 'Istria',
	Osijek: 'County of Osijek-Baranja',
	Koprivnica: 'County of Koprivnica-Križevci',
	Dubrovnik: 'Dubrovnik-Neretva',
	Đakovo: 'County of Osijek-Baranja',
	Čakovec: 'County of Međimurje',
	Bjelovar: 'Bjelovar-Bilogora',
	Karlovac: 'Karlovac',
	Centar: 'City of Zagreb',
	Verrettes: 'Artibonite',
	Thomazeau: 'Ouest',
	'Saint-Marc': 'Artibonite',
	'Port-de-Paix': 'Nord-Ouest',
	'Port-au-Prince': 'Ouest',
	Tigwav: 'Ouest',
	Pétionville: 'Ouest',
	Miragoâne: 'Nippes',
	Lenbe: 'Nord',
	Léogâne: 'Ouest',
	Kenscoff: 'Ouest',
	Jérémie: 'GrandʼAnse',
	Jacmel: 'Sud-Est',
	Hinche: 'Centre',
	Gressier: 'Ouest',
	Grangwav: 'Ouest',
	Gonaïves: 'Artibonite',
	'Fond Parisien': 'Ouest',
	Désarmes: 'Artibonite',
	'Delmas 73': 'Ouest',
	'Croix-des-Bouquets': 'Ouest',
	'Les Cayes': 'Sud',
	Carrefour: 'Ouest',
	'Cap-Haïtien': 'Nord',
	'Ti Port-de-Paix': 'Nord-Ouest',
	Törökszentmiklós: 'Jász-Nagykun-Szolnok',
	Tiszaújváros: 'Borsod-Abaúj-Zemplén',
	Szolnok: 'Jász-Nagykun-Szolnok',
	Szeged: 'Csongrád',
	Szarvas: 'Bekes County',
	Sátoraljaújhely: 'Borsod-Abaúj-Zemplén',
	Püspökladány: 'Hajdú-Bihar',
	Ózd: 'Borsod-Abaúj-Zemplén',
	Orosháza: 'Bekes County',
	Nyíregyháza: 'Szabolcs-Szatmár-Bereg',
	Miskolc: 'Borsod-Abaúj-Zemplén',
	Mezőtúr: 'Jász-Nagykun-Szolnok',
	Mezőkövesd: 'Borsod-Abaúj-Zemplén',
	Mátészalka: 'Szabolcs-Szatmár-Bereg',
	Makó: 'Csongrád',
	Kisvárda: 'Szabolcs-Szatmár-Bereg',
	Kazincbarcika: 'Borsod-Abaúj-Zemplén',
	Karcag: 'Jász-Nagykun-Szolnok',
	Hódmezővásárhely: 'Csongrád',
	Hajdúszoboszló: 'Hajdú-Bihar',
	Hajdúnánás: 'Hajdú-Bihar',
	Hajdúböszörmény: 'Hajdú-Bihar',
	Gyula: 'Bekes County',
	Gyomaendrőd: 'Bekes County',
	Eger: 'Heves',
	Debrecen: 'Hajdú-Bihar',
	Csongrád: 'Csongrád',
	Békéscsaba: 'Bekes County',
	Békés: 'Bekes County',
	Balmazújváros: 'Hajdú-Bihar',
	Abony: 'Pest',
	Zugló: 'Budapest',
	Zalaegerszeg: 'Zala',
	Veszprém: 'Veszprém',
	Veresegyház: 'Pest',
	Vecsés: 'Pest',
	Várpalota: 'Veszprém',
	Vác: 'Pest',
	Tatabánya: 'Komárom-Esztergom',
	Tata: 'Komárom-Esztergom',
	Tapolca: 'Veszprém',
	Szombathely: 'Vas',
	Szigetszentmiklós: 'Pest',
	Szentendre: 'Pest',
	Szekszárd: 'Tolna',
	Székesfehérvár: 'Fejér',
	Százhalombatta: 'Pest',
	Sopron: 'Győr-Moson-Sopron',
	Siófok: 'Somogy',
	Sárvár: 'Vas',
	Salgótarján: 'Nógrád',
	Pécs: 'Baranya',
	Parádsasvár: 'Heves',
	Pápa: 'Veszprém',
	Paks: 'Tolna',
	Oroszlány: 'Komárom-Esztergom',
	Nagykőrös: 'Pest',
	Nagykanizsa: 'Zala',
	Mosonmagyaróvár: 'Győr-Moson-Sopron',
	Monor: 'Pest',
	Mohács: 'Baranya',
	Komló: 'Baranya',
	Komárom: 'Komárom-Esztergom',
	Kőbánya: 'Budapest',
	Kispest: 'Budapest',
	Kiskunhalas: 'Bács-Kiskun',
	Kiskunfélegyháza: 'Bács-Kiskun',
	Kiskőrös: 'Bács-Kiskun',
	Keszthely: 'Zala',
	Kecskemét: 'Bács-Kiskun',
	Kaposvár: 'Somogy',
	Kalocsa: 'Bács-Kiskun',
	Jászberény: 'Jász-Nagykun-Szolnok',
	Hatvan: 'Heves',
	Győr: 'Győr-Moson-Sopron',
	Gyöngyös: 'Heves',
	Gyál: 'Pest',
	Gödöllő: 'Pest',
	Göd: 'Pest',
	Fót: 'Pest',
	Esztergom: 'Komárom-Esztergom',
	Erzsébetváros: 'Budapest',
	Érd: 'Pest',
	Dunaújváros: 'Fejér',
	Dunakeszi: 'Pest',
	Dunaharaszti: 'Pest',
	Dombóvár: 'Tolna',
	Dabas: 'Pest',
	Cegléd: 'Pest',
	Budapest: 'Budapest',
	Budaörs: 'Pest',
	Balassagyarmat: 'Nógrád',
	Baja: 'Bács-Kiskun',
	Angyalföld: 'Budapest',
	Ajka: 'Veszprém',
	'Budapest XII. kerület': 'Budapest',
	'Budapest XI. kerület': 'Budapest',
	'Budapest VIII. kerület': 'Budapest',
	'Budapest VI. kerület': 'Budapest',
	'Budapest XIII. kerület': 'Budapest',
	'Budapest IV. kerület': 'Budapest',
	'Budapest XV. kerület': 'Budapest',
	'Budapest XVI. kerület': 'Budapest',
	'Budapest XIX. kerület': 'Budapest',
	'Budapest XVIII. kerület': 'Budapest',
	'Budapest XXIII. kerület': 'Budapest',
	'Budapest XXII. kerület': 'Budapest',
	'Budapest XXI. kerület': 'Budapest',
	'Budapest XX. kerület': 'Budapest',
	'Budapest XVII. kerület': 'Budapest',
	'Budapest III. kerület': 'Budapest',
	'Budapest II. kerület': 'Budapest',
	'Budapest I. kerület': 'Budapest',
	Józsefváros: 'Budapest',
	Tongging: 'North Sumatra',
	'Teluk Nibung': 'North Sumatra',
	'Teluk Dalam': 'North Sumatra',
	Tebingtinggi: 'North Sumatra',
	Tanjungtiram: 'North Sumatra',
	Tanjungbalai: 'North Sumatra',
	Sunggal: 'North Sumatra',
	Stabat: 'North Sumatra',
	Singkil: 'Aceh',
	Sigli: 'Aceh',
	Sibolga: 'North Sumatra',
	Sabang: 'Aceh',
	Reuleuet: 'Aceh',
	Rantauprapat: 'North Sumatra',
	Percut: 'North Sumatra',
	Perbaungan: 'North Sumatra',
	Pematangsiantar: 'North Sumatra',
	'Pangkalan Brandan': 'North Sumatra',
	Padangsidempuan: 'North Sumatra',
	Meulaboh: 'Aceh',
	Medan: 'North Sumatra',
	Lhokseumawe: 'Aceh',
	Langsa: 'Aceh',
	'Labuhan Deli': 'North Sumatra',
	Kisaran: 'North Sumatra',
	Kabanjahe: 'North Sumatra',
	Gunungsitoli: 'North Sumatra',
	'Deli Tua': 'North Sumatra',
	Blangpidie: 'Aceh',
	Bireun: 'Aceh',
	Binjai: 'North Sumatra',
	Berastagi: 'North Sumatra',
	Belawan: 'North Sumatra',
	Bandar: 'North Sumatra',
	'Banda Aceh': 'Aceh',
	Yogyakarta: 'Yogyakarta',
	Wonosobo: 'Central Java',
	Wonosari: 'Yogyakarta',
	Wonopringgo: 'Central Java',
	Wongsorejo: 'East Java',
	Wiradesa: 'Central Java',
	Weru: 'West Java',
	Weleri: 'Central Java',
	Welahan: 'Central Java',
	Wedi: 'Central Java',
	Watampone: 'South Sulawesi',
	Wangon: 'Central Java',
	Wanaraja: 'West Java',
	Waisai: 'Southwest Papua',
	Waingapu: 'East Nusa Tenggara',
	Ungaran: 'Central Java',
	Makassar: 'South Sulawesi',
	Ubud: 'Bali',
	Tulungagung: 'East Java',
	'Tulangan Utara': 'East Java',
	Tuban: 'East Java',
	Tual: 'Maluku',
	Trucuk: 'Central Java',
	Trenggalek: 'East Java',
	Tondano: 'North Sulawesi',
	Tomohon: 'North Sulawesi',
	Tobelo: 'North Maluku',
	Ternate: 'Calabarzon',
	'Terbanggi Besar': 'Lampung',
	Teminabuan: 'Southwest Papua',
	Tegal: 'Central Java',
	Tayu: 'Central Java',
	Tasikmalaya: 'West Java',
	Tarub: 'Central Java',
	Tarakan: 'North Kalimantan',
	'Tanjung Selor': 'North Kalimantan',
	'Tanjung Pinang': 'Riau Islands',
	'Tanjung Pandan': 'Bangka–Belitung Islands',
	'Bandar Lampung': 'Lampung',
	'Tanjung Balai': 'Riau Islands',
	Tanjungagung: 'South Sumatra',
	Tanjung: 'South Kalimantan',
	Tanggulangin: 'East Java',
	Tangerang: 'Banten',
	Taliwang: 'West Nusa Tenggara',
	Tabanan: 'Bali',
	Surakarta: 'Central Java',
	Surabaya: 'East Java',
	'Sungai Raya': 'West Kalimantan',
	'Sungai Penuh': 'Jambi',
	Sungailiat: 'Bangka–Belitung Islands',
	Sumenep: 'East Java',
	'Sumedang Utara': 'West Java',
	Sumedang: 'West Java',
	Sumberpucung: 'East Java',
	Sumber: 'West Java',
	'Sumbawa Besar': 'West Nusa Tenggara',
	Sokaraja: 'Central Java',
	Sukabumi: 'West Java',
	Srono: 'East Java',
	Srandakan: 'Yogyakarta',
	Sragen: 'Central Java',
	Sorong: 'Southwest Papua',
	Soreang: 'West Java',
	Solok: 'West Sumatra',
	Soko: 'East Java',
	Sofifi: 'North Maluku',
	Soe: 'East Nusa Tenggara',
	Sleman: 'Yogyakarta',
	Slawi: 'Central Java',
	Situbondo: 'East Java',
	Sinjai: 'South Sulawesi',
	Singosari: 'East Java',
	Singojuruh: 'East Java',
	Singkawang: 'West Kalimantan',
	Sengkang: 'South Sulawesi',
	Singaraja: 'Bali',
	Singaparna: 'West Java',
	Simpang: 'Jambi',
	Sijunjung: 'West Sumatra',
	Sidoarjo: 'East Java',
	Sidareja: 'Central Java',
	Sewon: 'Yogyakarta',
	Serpong: 'West Java',
	Serang: 'Banten',
	Sepatan: 'West Java',
	Semarang: 'Central Java',
	Selong: 'West Nusa Tenggara',
	Selogiri: 'Central Java',
	Sawangan: 'West Java',
	Sanana: 'North Maluku',
	Sampit: 'Central Kalimantan',
	Sampang: 'East Java',
	Samarinda: 'East Kalimantan',
	Salatiga: 'Central Java',
	Ruteng: 'East Nusa Tenggara',
	Rengasdengklok: 'West Java',
	Rembangan: 'Central Java',
	Rantepao: 'South Sulawesi',
	Ransiki: 'West Papua',
	Rangkasbitung: 'Banten',
	Randudongkal: 'Central Java',
	Rajapolah: 'West Java',
	Raha: 'Southeast Sulawesi',
	Purwokerto: 'Central Java',
	Purwodadi: 'Central Java',
	Purwakarta: 'West Java',
	Purbalingga: 'Central Java',
	Pundong: 'Yogyakarta',
	Probolinggo: 'East Java',
	Prigen: 'East Java',
	Praya: 'West Nusa Tenggara',
	'Candi Prambanan': 'Central Java',
	Poso: 'Central Sulawesi',
	Pontianak: 'West Kalimantan',
	Ponorogo: 'East Java',
	Polewali: 'West Sulawesi',
	Plumbon: 'West Java',
	Prabumulih: 'South Sumatra',
	Pemangkat: 'West Kalimantan',
	Pemalang: 'Central Java',
	Pelabuhanratu: 'West Java',
	Pekanbaru: 'Riau',
	Pekalongan: 'Central Java',
	Pecangaan: 'Central Java',
	Payakumbuh: 'West Sumatra',
	Pati: 'Central Java',
	Pasuruan: 'East Java',
	Paseh: 'West Java',
	Pasarkemis: 'West Java',
	Parung: 'West Java',
	Pariaman: 'West Sumatra',
	Parepare: 'South Sulawesi',
	Pare: 'East Java',
	Panji: 'East Java',
	Pangkalpinang: 'Bangka–Belitung Islands',
	Pangkalanbuun: 'Central Kalimantan',
	Pandeglang: 'Banten',
	Pandaan: 'East Java',
	Pandak: 'Yogyakarta',
	Panarukan: 'East Java',
	Pamulang: 'West Java',
	Pameungpeuk: 'West Java',
	Pamekasan: 'East Java',
	Pamanukan: 'West Java',
	Palu: 'Central Sulawesi',
	Palopo: 'South Sulawesi',
	Palimanan: 'West Java',
	Palembang: 'South Sumatra',
	Palangkaraya: 'Central Kalimantan',
	Pakisaji: 'East Java',
	'Pagar Alam': 'South Sumatra',
	Padang: 'West Sumatra',
	Paciran: 'East Java',
	Ngunut: 'East Java',
	Ngoro: 'East Java',
	Ngawi: 'East Java',
	Nganjuk: 'East Java',
	Negara: 'Bali',
	Nabire: 'Central Papua',
	Muntok: 'Bangka–Belitung Islands',
	Muntilan: 'Central Java',
	Muncar: 'East Java',
	'Muara Teweh': 'Central Kalimantan',
	'Muara Bungo': 'Jambi',
	Mranggen: 'Central Java',
	Mojokerto: 'East Java',
	Mojoagung: 'East Java',
	Mlonggo: 'Central Java',
	Metro: 'Lampung',
	Mertoyudan: 'Central Java',
	Melati: 'Yogyakarta',
	Maumere: 'East Nusa Tenggara',
	Mataram: 'West Nusa Tenggara',
	Masamba: 'South Sulawesi',
	Martapura: 'South Kalimantan',
	Maros: 'South Sulawesi',
	Margasari: 'Central Java',
	Margahayukencana: 'West Java',
	Manokwari: 'West Papua',
	Manismata: 'West Kalimantan',
	Manggar: 'Bangka–Belitung Islands',
	Mendaha: 'Jambi',
	Manado: 'North Sulawesi',
	Malinau: 'North Kalimantan',
	Malang: 'East Java',
	Majene: 'West Sulawesi',
	Majenang: 'Central Java',
	Majalengka: 'West Java',
	Magelang: 'Central Java',
	Madiun: 'East Java',
	Luwuk: 'Central Sulawesi',
	Lumajang: 'East Java',
	Lubuklinggau: 'South Sumatra',
	'Loa Janan': 'East Kalimantan',
	Lembang: 'West Java',
	Lebaksiu: 'Central Java',
	Lawang: 'East Java',
	Lasem: 'Central Java',
	Lamongan: 'East Java',
	Lahat: 'South Sumatra',
	'Labuan Bajo': 'East Nusa Tenggara',
	Labuan: 'Labuan',
	Kutoarjo: 'Central Java',
	Kuta: 'Bali',
	Kuningan: 'West Java',
	Kudus: 'Central Java',
	'Kuala Tungkal': 'Jambi',
	Kualakapuas: 'Central Kalimantan',
	Kroya: 'Central Java',
	Krian: 'East Java',
	Kresek: 'West Java',
	Kraksaan: 'East Java',
	Kotabumi: 'Lampung',
	Klungkung: 'Bali',
	Klaten: 'Central Java',
	Klangenan: 'West Java',
	Ketanggungan: 'Central Java',
	Kertosono: 'East Java',
	Kepanjen: 'East Java',
	Kendari: 'Southeast Sulawesi',
	Kencong: 'East Java',
	Kefamenanu: 'East Nusa Tenggara',
	Kedungwuni: 'Central Java',
	Kedungwaru: 'East Java',
	Kediri: 'East Java',
	Kebonarun: 'Central Java',
	Kebomas: 'East Java',
	Kawalu: 'West Java',
	Katobu: 'Southeast Sulawesi',
	Karangsembung: 'West Java',
	Amlapura: 'Bali',
	Karanganom: 'Central Java',
	Karangampel: 'West Java',
	Kamal: 'East Java',
	Kalianget: 'East Java',
	Juwana: 'Central Java',
	Jombang: 'East Java',
	Jogonalan: 'Central Java',
	Jepara: 'Central Java',
	Jember: 'East Java',
	Jekulo: 'Central Java',
	Jatiwangi: 'West Java',
	Jatiroto: 'Central Java',
	Jatibarang: 'West Java',
	Jaten: 'Central Java',
	'Jambi City': 'Jambi',
	Jakarta: 'Jakarta',
	Indramayu: 'West Java',
	Grogol: 'Central Java',
	Gresik: 'East Java',
	Gorontalo: 'Gorontalo',
	'Gongdanglegi Kulon': 'East Java',
	Gombong: 'Central Java',
	Godean: 'Yogyakarta',
	Genteng: 'East Java',
	Gedangan: 'East Java',
	Gebog: 'Central Java',
	Gampengrejo: 'East Java',
	'Gambiran Satu': 'East Java',
	Galesong: 'South Sulawesi',
	Fakfak: 'Papua',
	Ende: 'East Nusa Tenggara',
	Dumai: 'Riau',
	Dukuhturi: 'Central Java',
	Driyorejo: 'East Java',
	Dompu: 'West Nusa Tenggara',
	Diwek: 'East Java',
	Depok: 'West Java',
	Denpasar: 'Bali',
	Demak: 'Central Java',
	Delanggu: 'Central Java',
	Dampit: 'East Java',
	Curup: 'Bengkulu',
	Curug: 'Banten',
	Comal: 'Central Java',
	Colomadu: 'Central Java',
	Citeureup: 'West Java',
	Cirebon: 'West Java',
	Ciputat: 'West Java',
	Cimahi: 'West Java',
	Cileunyi: 'West Java',
	Cileungsir: 'West Java',
	Cilacap: 'Central Java',
	Cikarang: 'West Java',
	Cikampek: 'West Java',
	Cicurug: 'West Java',
	Cibinong: 'West Java',
	Cianjur: 'West Java',
	Ciamis: 'West Java',
	Cepu: 'Central Java',
	Ceper: 'Central Java',
	Caringin: 'West Java',
	Ciampea: 'West Java',
	Bulakamba: 'Central Java',
	Bukittinggi: 'West Sumatra',
	Buduran: 'East Java',
	Buaran: 'Central Java',
	Boyolangu: 'East Java',
	Boyolali: 'Central Java',
	Bontang: 'East Kalimantan',
	Bondowoso: 'East Java',
	Bojonegoro: 'East Java',
	Bogor: 'West Java',
	Blora: 'Central Java',
	Blitar: 'East Java',
	Bitung: 'North Sulawesi',
	Bintuni: 'West Papua',
	Bima: 'West Nusa Tenggara',
	Besuki: 'East Java',
	Bengkulu: 'Bengkulu',
	Bekasi: 'West Java',
	Baubau: 'Southeast Sulawesi',
	Baturaja: 'South Sumatra',
	Baturaden: 'Central Java',
	Batu: 'East Java',
	Barabai: 'South Kalimantan',
	Banyuwangi: 'East Java',
	Banyumas: 'Central Java',
	Bantul: 'Yogyakarta',
	Banjarmasin: 'South Kalimantan',
	Banjaran: 'West Java',
	Banjar: 'West Java',
	Bangkalan: 'East Java',
	Bangil: 'East Java',
	Bandung: 'West Java',
	Bambanglipuro: 'Yogyakarta',
	Balung: 'East Java',
	Balikpapan: 'East Kalimantan',
	Balapulang: 'Central Java',
	'Balai Pungut': 'Riau',
	Baki: 'Awdal',
	Baekrajan: 'Central Java',
	Babat: 'East Java',
	Atambua: 'East Nusa Tenggara',
	Astanajapura: 'West Java',
	Arjawinangun: 'West Java',
	Amuntai: 'South Kalimantan',
	Ambon: 'Maluku',
	Ambarawa: 'Central Java',
	Amahai: 'Maluku',
	Agats: 'South Papua',
	Adiwerna: 'Central Java',
	Padalarang: 'West Java',
	'Ciranjang-hilir': 'West Java',
	Cikupa: 'West Java',
	Teluknaga: 'West Java',
	'Gamping Lor': 'Yogyakarta',
	Kasihan: 'Yogyakarta',
	Ngemplak: 'Central Java',
	Kartasura: 'Central Java',
	Gatak: 'Central Java',
	Kupang: 'East Nusa Tenggara',
	Merauke: 'South Papua',
	Jayapura: 'Papua',
	Abepura: 'Papua',
	Batam: 'Riau',
	Seririt: 'Bali',
	Bedugul: 'Bali',
	Tambolaka: 'East Nusa Tenggara',
	'Pekan Bahapal': 'North Sumatra',
	'South Tangerang': 'Banten',
	Masohi: 'Maluku',
	'Amlapura city': 'Bali',
	Wexford: 'Leinster',
	Waterford: 'Connecticut',
	Tralee: 'Munster',
	Tallaght: 'Leinster',
	Swords: 'Leinster',
	Sligo: 'Connacht',
	Rathfarnham: 'Leinster',
	'Droichead Nua': 'Leinster',
	Navan: 'Leinster',
	Naas: 'Leinster',
	'An Muileann gCearr': 'Leinster',
	Malahide: 'Leinster',
	Lucan: 'Leinster',
	Limerick: 'Pennsylvania',
	Letterkenny: 'Ulster',
	Leixlip: 'Leinster',
	Kilkenny: 'Leinster',
	Greystones: 'Leinster',
	Galway: 'Connacht',
	Finglas: 'Leinster',
	Ennis: 'Texas',
	'Dún Laoghaire': 'Leinster',
	Dundalk: 'Maryland',
	Dublin: 'California',
	Drogheda: 'Leinster',
	Douglas: 'Arizona',
	Cork: 'Munster',
	'Cluain Meala': 'Munster',
	Celbridge: 'Leinster',
	Carrigaline: 'Munster',
	Carlow: 'Leinster',
	Bray: 'Leinster',
	Blanchardstown: 'Leinster',
	Ballincollig: 'Munster',
	Balbriggan: 'Leinster',
	Athlone: 'Leinster',
	Sandyford: 'Leinster',
	Donaghmede: 'Leinster',
	'South Dublin': 'Leinster',
	Jerusalem: 'Jerusalem',
	'Modi‘in Makkabbim Re‘ut': 'Central District',
	'Zikhron Ya‘aqov': 'Haifa',
	Safed: 'Northern District',
	'Yoqne‘am ‘Illit': 'Northern District',
	Yirkā: 'Northern District',
	Yehud: 'Central District',
	Yavné: 'Central District',
	Jaffa: 'Tel Aviv',
	Yāfā: 'Northern District',
	'Umm el Faḥm': 'Haifa',
	'Tirat Karmel': 'Haifa',
	Tiberias: 'Northern District',
	'Tel Aviv': 'Tel Aviv',
	'maalot Tarshīhā': 'Northern District',
	Tamra: 'Northern District',
	'Shefar‘am': 'Northern District',
	Sderot: 'Southern District',
	Sakhnīn: 'Northern District',
	'Rosh Ha‘Ayin': 'Central District',
	'Rishon LeTsiyyon': 'Central District',
	Reẖovot: 'Central District',
	Ramla: 'Central District',
	'Ramat HaSharon': 'Tel Aviv',
	'Ramat Gan': 'Tel Aviv',
	"Ra'anana": 'Central District',
	'Qiryat Yam': 'Haifa',
	'Qiryat Tiv‘on': 'Haifa',
	'Qiryat Shmona': 'Northern District',
	'Qiryat Motsqin': 'Haifa',
	'Qiryat Mal’akhi': 'Southern District',
	'Kiryat Gat': 'Southern District',
	'Qiryat Bialik': 'Haifa',
	'Qiryat Ata': 'Haifa',
	Qalansuwa: 'Central District',
	'Petaẖ Tiqva': 'Central District',
	'Or Yehuda': 'Tel Aviv',
	'Or Akiva': 'Haifa',
	Ofaqim: 'Southern District',
	Netivot: 'Southern District',
	Netanya: 'Central District',
	'Ness Ziona': 'Central District',
	Nesher: 'Haifa',
	'Naẕerat ‘Illit': 'Northern District',
	Nazareth: 'Northern District',
	Nahariyya: 'Northern District',
	'Migdal Ha‘Emeq': 'Northern District',
	'Mevasseret Tsiyyon': 'Jerusalem',
	'Majd el Kurūm': 'Northern District',
	Maghār: 'Northern District',
	Lod: 'Central District',
	'Kfar Yona': 'Central District',
	'Kfar Saba': 'Central District',
	'Karmi’el': 'Northern District',
	'Kafr Qāsim': 'Central District',
	'Kafr Qari‘': 'Haifa',
	'Kafr Mandā': 'Northern District',
	'Kafr Kannā': 'Northern District',
	'Judeida Makr': 'Northern District',
	H̱olon: 'Tel Aviv',
	'Hod HaSharon': 'Central District',
	Herzliya: 'Tel Aviv',
	Haifa: 'Haifa',
	Hadera: 'Haifa',
	"Giv'at Shmuel": 'Tel Aviv',
	Givatayim: 'Tel Aviv',
	Gedera: 'Central District',
	'Gan Yavne': 'Central District',
	'Ganei Tikva': 'Central District',
	'Eṭ Ṭīra': 'Central District',
	'Eṭ Ṭaiyiba': 'Central District',
	'Er Reina': 'Northern District',
	Eilat: 'Southern District',
	Dimona: 'Southern District',
	'Daliyat al Karmel': 'Haifa',
	Binyamina: 'Haifa',
	'Bet Shemesh': 'Jerusalem',
	'Bet She’an': 'Northern District',
	'Bnei Brak': 'Tel Aviv',
	'Be’er Ya‘aqov': 'Central District',
	Beersheba: 'Southern District',
	'Bat Yam': 'Tel Aviv',
	'Bāqa el Gharbīya': 'Haifa',
	Ashkelon: 'Southern District',
	Ashdod: 'Southern District',
	'‘Ar‘ara': 'Haifa',
	Arad: 'Arad',
	Acre: 'Northern District',
	Afula: 'Northern District',
	'West Jerusalem': 'Jerusalem',
	Rahat: 'Southern District',
	'Modiin Ilit': 'Jerusalem',
	Ariel: 'Judea and Samaria Area',
	'Tel Sheva‘': 'Southern District',
	'‘Ar‘ara BaNegev': 'Southern District',
	H̱ura: 'Southern District',
	Kuseifa: 'Southern District',
	Shoham: 'Central District',
	'El‘ad': 'Central District',
	'Yehud-Monosson': 'Tel Aviv',
	'Kadima Zoran': 'Central District',
	'Kiryat Ono': 'Tel Aviv',
	"Binyamina-Giv'at Ada": 'Haifa',
	Pūnch: 'Jammu and Kashmir',
	Keelakarai: 'Tamil Nadu',
	Zunheboto: 'Nagaland',
	Zamānia: 'Uttar Pradesh',
	Zaidpur: 'Uttar Pradesh',
	Zahirābād: 'Telangana',
	Yeola: 'Maharashtra',
	Yellāpur: 'Karnataka',
	Yellandu: 'Telangana',
	Yelahanka: 'Karnataka',
	Yavatmāl: 'Maharashtra',
	Yāval: 'Maharashtra',
	Yanam: 'Andhra Pradesh',
	Yamunānagar: 'Haryana',
	Yādgīr: 'Karnataka',
	Wokha: 'Nagaland',
	Wer: 'Rajasthan',
	Wazīrganj: 'Uttar Pradesh',
	Wāshīm: 'Maharashtra',
	Warud: 'Maharashtra',
	Warora: 'Maharashtra',
	'Wāris Alīganj': 'Bihar',
	Wardha: 'Maharashtra',
	Wārāseonī: 'Madhya Pradesh',
	Warangal: 'Telangana',
	Wanparti: 'Telangana',
	Wānkāner: 'Gujarat',
	Wani: 'Maharashtra',
	Walajapet: 'Tamil Nadu',
	Wai: 'Maharashtra',
	Wādi: 'Karnataka',
	Vyāra: 'Gujarat',
	Vuyyūru: 'Andhra Pradesh',
	Vrindāvan: 'Uttar Pradesh',
	Vriddhāchalam: 'Tamil Nadu',
	Vizianagaram: 'Andhra Pradesh',
	Vite: 'Maharashtra',
	Visnagar: 'Gujarat',
	Visakhapatnam: 'Andhra Pradesh',
	Vīsāvadar: 'Gujarat',
	Virudunagar: 'Tamil Nadu',
	'Vadakku Viravanallur': 'Tamil Nadu',
	Virajpet: 'Karnataka',
	Virār: 'Maharashtra',
	Vinukonda: 'Andhra Pradesh',
	Villupuram: 'Tamil Nadu',
	Vikārābād: 'Telangana',
	Vijayawada: 'Andhra Pradesh',
	Vijāpur: 'Gujarat',
	Vidisha: 'Madhya Pradesh',
	Vettūr: 'Kerala',
	Vettaikkaranpudur: 'Tamil Nadu',
	Vetapālem: 'Andhra Pradesh',
	Verāval: 'Gujarat',
	Vepagunta: 'Andhra Pradesh',
	Venkatagiri: 'Andhra Pradesh',
	Vemalwāda: 'Telangana',
	Velur: 'Tamil Nadu',
	Vellore: 'Tamil Nadu',
	Vejalpur: 'Gujarat',
	Vedaraniyam: 'Tamil Nadu',
	Vayalār: 'Kerala',
	Vattalkundu: 'Tamil Nadu',
	Vāsudevanallūr: 'Tamil Nadu',
	Vasind: 'Maharashtra',
	'Vasco da Gama': 'Goa',
	Vasa: 'Gujarat',
	Varkala: 'Kerala',
	Varangaon: 'Maharashtra',
	Varanasi: 'Uttar Pradesh',
	Vaniyambadi: 'Tamil Nadu',
	Vandavāsi: 'Tamil Nadu',
	Valsād: 'Gujarat',
	Valparai: 'Tamil Nadu',
	'Vallabh Vidyanagar': 'Gujarat',
	Valabhīpur: 'Gujarat',
	Vaikam: 'Kerala',
	Vaijāpur: 'Maharashtra',
	Vadodara: 'Gujarat',
	Vadnagar: 'Gujarat',
	Vadlapūdi: 'Andhra Pradesh',
	Vādippatti: 'Tamil Nadu',
	Vadamadurai: 'Tamil Nadu',
	'Vadakku Valliyūr': 'Tamil Nadu',
	Vāda: 'Maharashtra',
	Uttiramerūr: 'Tamil Nadu',
	Uttarkāshi: 'Uttarakhand',
	Uttamapālaiyam: 'Tamil Nadu',
	Utraula: 'Uttar Pradesh',
	Usilampatti: 'Tamil Nadu',
	Usehat: 'Uttar Pradesh',
	Uravakonda: 'Andhra Pradesh',
	Uran: 'Maharashtra',
	Upleta: 'Gujarat',
	'Uppal Kalan': 'Telangana',
	Unnāo: 'Uttar Pradesh',
	Unjha: 'Gujarat',
	Unhel: 'Madhya Pradesh',
	Ūn: 'Uttar Pradesh',
	Un: 'Gujarat',
	Umreth: 'Gujarat',
	Umred: 'Maharashtra',
	Umarkot: 'Sindh',
	Umarkhed: 'Maharashtra',
	Umaria: 'Madhya Pradesh',
	Umarga: 'Maharashtra',
	Ullal: 'Karnataka',
	Ulhasnagar: 'Maharashtra',
	Ujjain: 'Madhya Pradesh',
	Ujhāni: 'Uttar Pradesh',
	Udumalaippettai: 'Tamil Nadu',
	Udupi: 'Karnataka',
	Udhampur: 'Jammu and Kashmir',
	Udgīr: 'Maharashtra',
	Udangudi: 'Tamil Nadu',
	Udalguri: 'Assam',
	Udaipura: 'Madhya Pradesh',
	Udaipur: 'Tripura',
	Ooty: 'Tamil Nadu',
	'Bara Uchāna': 'Haryana',
	Turaiyūr: 'Tamil Nadu',
	Tura: 'Meghalaya',
	Tuni: 'Andhra Pradesh',
	Tūndla: 'Uttar Pradesh',
	Tumsar: 'Maharashtra',
	Tumkūr: 'Karnataka',
	Tulsīpur: 'Lumbini Province',
	Tuljāpur: 'Maharashtra',
	Tufānganj: 'West Bengal',
	Tuensang: 'Nagaland',
	Thiruvananthapuram: 'Kerala',
	Thrissur: 'Kerala',
	Tonk: 'Rajasthan',
	Tondi: 'Tamil Nadu',
	Tohāna: 'Haryana',
	Todaraisingh: 'Rajasthan',
	Todabhim: 'Rajasthan',
	Titlāgarh: 'Odisha',
	Titāgarh: 'West Bengal',
	Tisaiyanvilai: 'Tamil Nadu',
	Tiruvottiyūr: 'Tamil Nadu',
	Cheyyar: 'Tamil Nadu',
	Tiruvannāmalai: 'Tamil Nadu',
	Tiruvallur: 'Tamil Nadu',
	Tiruvalla: 'Kerala',
	Thiruthani: 'Tamil Nadu',
	Tiruttangal: 'Tamil Nadu',
	Tirur: 'Kerala',
	Tiruppuvanam: 'Tamil Nadu',
	Tiruppur: 'Tamil Nadu',
	Tirupparangunram: 'Tamil Nadu',
	Tirupati: 'Andhra Pradesh',
	Tirunelveli: 'Tamil Nadu',
	Tirumala: 'Andhra Pradesh',
	Tirukkoyilur: 'Tamil Nadu',
	Tiruchengode: 'Tamil Nadu',
	Tiruchirappalli: 'Tamil Nadu',
	Tiruchchendur: 'Tamil Nadu',
	Tīrthahalli: 'Karnataka',
	Tiptūr: 'Karnataka',
	Tinsukia: 'Assam',
	Tinnanūr: 'Tamil Nadu',
	Tindivanam: 'Tamil Nadu',
	Tilhar: 'Uttar Pradesh',
	Tīkamgarh: 'Madhya Pradesh',
	Tijāra: 'Rajasthan',
	Thoubāl: 'Manipur',
	Thiruvarur: 'Tamil Nadu',
	Thāsra: 'Gujarat',
	Tharād: 'Gujarat',
	Thanjavur: 'Tamil Nadu',
	Thānesar: 'Haryana',
	Thāne: 'Maharashtra',
	'Thāna Bhawan': 'Uttar Pradesh',
	Thān: 'Gujarat',
	Thākurganj: 'Bihar',
	Thakurdwara: 'Uttar Pradesh',
	Tezpur: 'Assam',
	Terdāl: 'Karnataka',
	Teonthar: 'Madhya Pradesh',
	Thenkasi: 'Tamil Nadu',
	Teni: 'Tamil Nadu',
	Tellicherry: 'Kerala',
	Telhāra: 'Maharashtra',
	Tekkali: 'Andhra Pradesh',
	Tekkalakote: 'Karnataka',
	Tekāri: 'Bihar',
	Tehri: 'Uttarakhand',
	Teghra: 'Bihar',
	Tāsgaon: 'Maharashtra',
	'Tarn Tāran': 'Punjab',
	Tarikere: 'Karnataka',
	Tharangambadi: 'Tamil Nadu',
	Tārānagar: 'Rajasthan',
	Tarāna: 'Madhya Pradesh',
	Tāramangalam: 'Tamil Nadu',
	Tarakeswar: 'West Bengal',
	Tāoru: 'Haryana',
	Tanuku: 'Andhra Pradesh',
	Tāndūr: 'Telangana',
	Tānda: 'Uttar Pradesh',
	Tāndā: 'Uttar Pradesh',
	Tanakpur: 'Uttarakhand',
	Tamlūk: 'West Bengal',
	Tambaram: 'Tamil Nadu',
	Talwāra: 'Punjab',
	'Talwandi Bhai': 'Punjab',
	Taloda: 'Maharashtra',
	Talipparamba: 'Kerala',
	Tālīkota: 'Karnataka',
	Taleigao: 'Goa',
	'Talegaon Dābhāde': 'Maharashtra',
	Tālcher: 'Odisha',
	Talāja: 'Gujarat',
	Tāki: 'West Bengal',
	Takhatpur: 'Chhattisgarh',
	Takhatgarh: 'Rajasthan',
	Tājpur: 'Uttar Pradesh',
	Tādpatri: 'Andhra Pradesh',
	Tādepallegūdem: 'Andhra Pradesh',
	Tādepalle: 'Andhra Pradesh',
	Suriāpet: 'Telangana',
	Suriānwān: 'Uttar Pradesh',
	Surendranagar: 'Gujarat',
	Sūratgarh: 'Rajasthan',
	Surat: 'Gujarat',
	Sūrandai: 'Tamil Nadu',
	Sūrajgarh: 'Rajasthan',
	Supaul: 'Bihar',
	Sunel: 'Rajasthan',
	Sundarnagar: 'Himachal Pradesh',
	Sundargarh: 'Odisha',
	Sunām: 'Punjab',
	Sulya: 'Karnataka',
	Sūlūru: 'Andhra Pradesh',
	Sulur: 'Tamil Nadu',
	Sultanpur: 'Punjab',
	Sultānpur: 'Uttar Pradesh',
	Suket: 'Rajasthan',
	Sūjāngarh: 'Rajasthan',
	Suār: 'Uttar Pradesh',
	Srivilliputhur: 'Tamil Nadu',
	Srīvardhan: 'Maharashtra',
	Srivaikuntam: 'Tamil Nadu',
	Srīsailain: 'Andhra Pradesh',
	Srīrāmnagar: 'Telangana',
	Srīperumbūdūr: 'Tamil Nadu',
	Srīnivāspur: 'Karnataka',
	Srinagar: 'Jammu and Kashmir',
	Srīnagar: 'Uttarakhand',
	'Sri Mādhopur': 'Rajasthan',
	Karanpur: 'Rajasthan',
	Srikakulam: 'Andhra Pradesh',
	'Sri Dūngargarh': 'Rajasthan',
	Soygaon: 'Maharashtra',
	Soron: 'Uttar Pradesh',
	Soro: 'Odisha',
	Sorada: 'Odisha',
	Sopur: 'Jammu and Kashmir',
	Sonīpat: 'Haryana',
	Songadh: 'Gujarat',
	Sonepur: 'Odisha',
	'Rajpur Sonarpur': 'West Bengal',
	Sonāri: 'Assam',
	Sonāmukhi: 'West Bengal',
	Sompeta: 'Andhra Pradesh',
	Someshwar: 'Karnataka',
	Solan: 'Himachal Pradesh',
	Sojītra: 'Gujarat',
	Sojat: 'Rajasthan',
	Sohna: 'Haryana',
	Sohāgpur: 'Madhya Pradesh',
	Siwāna: 'Rajasthan',
	Siwān: 'Bihar',
	Sivakasi: 'Tamil Nadu',
	Sivagiri: 'Tamil Nadu',
	Sivaganga: 'Tamil Nadu',
	Siuri: 'West Bengal',
	Sitārganj: 'Uttarakhand',
	Sītāpur: 'Uttar Pradesh',
	Sītāmarhi: 'Bihar',
	'Siswā Bāzār': 'Uttar Pradesh',
	Sisauli: 'Uttar Pradesh',
	Sirūr: 'Maharashtra',
	Sirumugai: 'Tamil Nadu',
	Siruguppa: 'Karnataka',
	Sirsilla: 'Telangana',
	Sirsi: 'Karnataka',
	Sirsāganj: 'Uttar Pradesh',
	Sirsa: 'Haryana',
	Sironj: 'Madhya Pradesh',
	Sirohi: 'Rajasthan',
	Sīrkāzhi: 'Tamil Nadu',
	Sirhind: 'Punjab',
	Sīra: 'Karnataka',
	Sinnar: 'Sinnār',
	Singur: 'West Bengal',
	Singarāyakonda: 'Andhra Pradesh',
	Singānallūr: 'Tamil Nadu',
	Sindhnūr: 'Karnataka',
	Sindgi: 'Karnataka',
	Shimla: 'Himachal Pradesh',
	Simdega: 'Jharkhand',
	Silvassa: 'Dadra and Nagar Haveli and Daman and Diu',
	Sillod: 'Maharashtra',
	Silchar: 'Assam',
	Silao: 'Guanajuato',
	Sīkar: 'Rajasthan',
	'Sikandra Rao': 'Uttar Pradesh',
	Sikandarpur: 'Uttar Pradesh',
	Sikandarābād: 'Uttar Pradesh',
	Sikka: 'Gujarat',
	Sijua: 'Jharkhand',
	Sihorā: 'Madhya Pradesh',
	Sihor: 'Gujarat',
	Sidlaghatta: 'Karnataka',
	Sidhi: 'Madhya Pradesh',
	Sidhaulī: 'Uttar Pradesh',
	Siddipet: 'Telangana',
	Siddhapur: 'Gujarat',
	Sibsāgar: 'Assam',
	Shyamnagar: 'West Bengal',
	Shujālpur: 'Madhya Pradesh',
	Shrīrangapattana: 'Karnataka',
	Shrīrāmpur: 'West Bengal',
	Shrīgonda: 'Maharashtra',
	Shorāpur: 'Karnataka',
	Shōranūr: 'Kerala',
	Sholinghur: 'Tamil Nadu',
	Solāpur: 'Maharashtra',
	Shivpuri: 'Madhya Pradesh',
	Shīshgarh: 'Uttar Pradesh',
	Shirpur: 'Maharashtra',
	Shirhatti: 'Karnataka',
	Shirdi: 'Maharashtra',
	Shimoga: 'Karnataka',
	Shillong: 'Meghalaya',
	Siliguri: 'West Bengal',
	Shikohābād: 'Uttar Pradesh',
	Shikārpūr: 'Uttar Pradesh',
	Shikārpur: 'Karnataka',
	Shiggaon: 'Karnataka',
	Shertallai: 'Kerala',
	Sherkot: 'Uttar Pradesh',
	Sherghāti: 'Bihar',
	Sheopur: 'Madhya Pradesh',
	Sheohar: 'Bihar',
	Sheoganj: 'Rajasthan',
	Shegaon: 'Maharashtra',
	Shāntipur: 'West Bengal',
	Shamsābād: 'Uttar Pradesh',
	Shāmli: 'Uttar Pradesh',
	Shāmgarh: 'Madhya Pradesh',
	Shājāpur: 'Madhya Pradesh',
	Sheikhpura: 'Bihar',
	Shāhpura: 'Rajasthan',
	Shāhpur: 'Karnataka',
	Shāhjānpur: 'Uttar Pradesh',
	Shāhi: 'Uttar Pradesh',
	Shāhganj: 'Uttar Pradesh',
	Shahdol: 'Madhya Pradesh',
	Shāhāda: 'Maharashtra',
	Shāhābād: 'Karnataka',
	Serchhīp: 'Mizoram',
	Seram: 'Karnataka',
	'Seoni Mālwa': 'Madhya Pradesh',
	Seoni: 'Madhya Pradesh',
	Seondha: 'Madhya Pradesh',
	Seohāra: 'Uttar Pradesh',
	Sendhwa: 'Madhya Pradesh',
	Sehore: 'Madhya Pradesh',
	Secunderabad: 'Telangana',
	Sāyla: 'Gujarat',
	'Sawāi Mādhopur': 'Rajasthan',
	Sāvda: 'Maharashtra',
	Savanūr: 'Karnataka',
	Sāvantvādi: 'Maharashtra',
	Sausar: 'Madhya Pradesh',
	Saundatti: 'Karnataka',
	Sathyamangalam: 'Tamil Nadu',
	Sattur: 'Tamil Nadu',
	Sattenapalle: 'Andhra Pradesh',
	Satna: 'Madhya Pradesh',
	Satara: 'Maharashtra',
	Satānā: 'Maharashtra',
	Sāsvad: 'Maharashtra',
	Sarwār: 'Rajasthan',
	Sarkhej: 'Gujarat',
	Sardulgarh: 'Punjab',
	Sardhana: 'Uttar Pradesh',
	Sardārshahr: 'Rajasthan',
	Sarauli: 'Uttar Pradesh',
	Sārangpur: 'Madhya Pradesh',
	Saraipali: 'Chhattisgarh',
	'Sarāi Mīr': 'Uttar Pradesh',
	'Sarāi Ākil': 'Uttar Pradesh',
	Saoner: 'Maharashtra',
	Sānkrāil: 'West Bengal',
	Sankeshwar: 'Karnataka',
	Sangrūr: 'Punjab',
	Sāngola: 'Maharashtra',
	Sangod: 'Rajasthan',
	Sāngli: 'Maharashtra',
	Sangariā: 'Rajasthan',
	Sangāreddi: 'Telangana',
	Sangamner: 'Maharashtra',
	Sandūr: 'Karnataka',
	Sandīla: 'Uttar Pradesh',
	Sāndi: 'Uttar Pradesh',
	Sancoale: 'Goa',
	Sānchor: 'Rajasthan',
	Sanāwad: 'Madhya Pradesh',
	Sanaur: 'Punjab',
	Sānand: 'Gujarat',
	Samthar: 'Uttar Pradesh',
	Samrāla: 'Punjab',
	Samdari: 'Rajasthan',
	Sāmbhar: 'Rajasthan',
	Sambhal: 'Uttar Pradesh',
	Sambalpur: 'Odisha',
	Sāmba: 'Jammu and Kashmir',
	Samāstipur: 'Bihar',
	Sāmalkot: 'Andhra Pradesh',
	Samālkha: 'Haryana',
	Sālūr: 'Andhra Pradesh',
	Sālūmbar: 'Rajasthan',
	Salem: 'Oregon',
	Salāya: 'Gujarat',
	Saktī: 'Chhattisgarh',
	Sakleshpur: 'Karnataka',
	'Saint Thomas Mount': 'Tamil Nadu',
	Sainthia: 'West Bengal',
	Selu: 'Maharashtra',
	Saiha: 'Mizoram',
	Sāhibganj: 'Jharkhand',
	Sahāwar: 'Uttar Pradesh',
	Sahaswān: 'Uttar Pradesh',
	Sahaspur: 'Uttar Pradesh',
	Saharsa: 'Bihar',
	Sahāranpur: 'Uttar Pradesh',
	Sagauli: 'Bihar',
	Saugor: 'Madhya Pradesh',
	Sāgar: 'Karnataka',
	Safīpur: 'Uttar Pradesh',
	Safidon: 'Haryana',
	Sādri: 'Rajasthan',
	Sadashivpet: 'Telangana',
	Sadalgi: 'Karnataka',
	Sadābād: 'Uttar Pradesh',
	Sabalgarh: 'Madhya Pradesh',
	Rusera: 'Bihar',
	Rura: 'Uttar Pradesh',
	Ropar: 'Punjab',
	Rūdarpur: 'Uttar Pradesh',
	Roorkee: 'Uttarakhand',
	Ron: 'Karnataka',
	Rohtak: 'Haryana',
	Roha: 'Maharashtra',
	Robertsonpet: 'Karnataka',
	Robertsganj: 'Uttar Pradesh',
	Risod: 'Maharashtra',
	Rishra: 'West Bengal',
	Rishīkesh: 'Uttarakhand',
	Rīngas: 'Rajasthan',
	Richha: 'Uttar Pradesh',
	Rewāri: 'Haryana',
	Rewa: 'Madhya Pradesh',
	Revelganj: 'Bihar',
	Repalle: 'Andhra Pradesh',
	Reoti: 'Uttar Pradesh',
	Renukūt: 'Uttar Pradesh',
	Renigunta: 'Andhra Pradesh',
	Remuna: 'Odisha',
	Rehli: 'Madhya Pradesh',
	Rāzām: 'Andhra Pradesh',
	Raybag: 'Karnataka',
	Rāyadrug: 'Andhra Pradesh',
	Rāyachoti: 'Andhra Pradesh',
	Rāya: 'Uttar Pradesh',
	Raxaul: 'Bihar',
	Rāwatsār: 'Rajasthan',
	Rāwatbhāta: 'Rajasthan',
	Rāver: 'Maharashtra',
	Raurkela: 'Odisha',
	Ratnagiri: 'Maharashtra',
	Ratlām: 'Madhya Pradesh',
	Ratia: 'Haryana',
	Rāth: 'Uttar Pradesh',
	Ratanpur: 'Chhattisgarh',
	Ratangarh: 'Rajasthan',
	Rasrā: 'Uttar Pradesh',
	Rasipuram: 'Tamil Nadu',
	Rāpar: 'Gujarat',
	Rānīpur: 'Uttar Pradesh',
	Rānipet: 'Tamil Nadu',
	Rānikhet: 'Uttarakhand',
	Rānīganj: 'West Bengal',
	Rānībennur: 'Karnataka',
	Rānia: 'Haryana',
	Rangia: 'Assam',
	Rangāpāra: 'Assam',
	Ranchi: 'Jharkhand',
	Rānāvāv: 'Gujarat',
	Rānāghāt: 'West Bengal',
	Rāmtek: 'Maharashtra',
	'Rampur Hat': 'West Bengal',
	Rāmpura: 'Madhya Pradesh',
	Rāmpur: 'Uttar Pradesh',
	Rāmnagar: 'Uttar Pradesh',
	Rāmjībanpur: 'West Bengal',
	Rāmgundam: 'Telangana',
	Rāmgarh: 'Jharkhand',
	'Rāmganj Mandi': 'Rajasthan',
	Rameswaram: 'Tamil Nadu',
	Rāmāpuram: 'Andhra Pradesh',
	Ramanathapuram: 'Tamil Nadu',
	Closepet: 'Karnataka',
	Rāmachandrapuram: 'Andhra Pradesh',
	Rājūra: 'Maharashtra',
	Rājula: 'Gujarat',
	Rājsamand: 'Rajasthan',
	Rājpura: 'Punjab',
	Rajpur: 'Madhya Pradesh',
	Rājpīpla: 'Gujarat',
	'Rāj-Nāndgaon': 'Chhattisgarh',
	Rājmahal: 'Jharkhand',
	Rājkot: 'Gujarat',
	Rājgurunagar: 'Maharashtra',
	Rājgīr: 'Bihar',
	Rājgarh: 'Madhya Pradesh',
	Rajaori: 'Jammu and Kashmir',
	Rajapalaiyam: 'Tamil Nadu',
	Rāzampeta: 'Andhra Pradesh',
	Rājaldesar: 'Rajasthan',
	Rājākhera: 'Rajasthan',
	Rājahmundry: 'Andhra Pradesh',
	Rāisinghnagar: 'Rajasthan',
	Raisen: 'Madhya Pradesh',
	Raipur: 'Chhattisgarh',
	Rāikot: 'Punjab',
	Raigarh: 'Chhattisgarh',
	Rāiganj: 'West Bengal',
	Rāichūr: 'Karnataka',
	Rāhuri: 'Maharashtra',
	Rahimatpur: 'Maharashtra',
	Rāhatgarh: 'Madhya Pradesh',
	Raghunathpur: 'West Bengal',
	Rāghogarh: 'Madhya Pradesh',
	Rafiganj: 'Bihar',
	Raebareli: 'Uttar Pradesh',
	Rādhanpur: 'Gujarat',
	Rabkavi: 'Karnataka',
	Kollam: 'Kerala',
	Kasba: 'Bihar',
	Qādiān: 'Punjab',
	Puttūr: 'Karnataka',
	Pushkar: 'Rajasthan',
	Pusad: 'Maharashtra',
	Purwā: 'Uttar Pradesh',
	Puruliya: 'West Bengal',
	Purnia: 'Bihar',
	Pūrna: 'Maharashtra',
	Puri: 'Odisha',
	Pūranpur: 'Uttar Pradesh',
	Pupri: 'Bihar',
	'Punjai Puliyampatti': 'Tamil Nadu',
	Punganūru: 'Andhra Pradesh',
	Pune: 'Maharashtra',
	Pūndri: 'Haryana',
	Punāsa: 'Madhya Pradesh',
	Punalūr: 'Kerala',
	Pūnāhāna: 'Haryana',
	Pulwama: 'Jammu and Kashmir',
	Puliyangudi: 'Tamil Nadu',
	Pulivendla: 'Andhra Pradesh',
	Pulgaon: 'Maharashtra',
	Pukhrāyān: 'Uttar Pradesh',
	Pudukkottai: 'Tamil Nadu',
	Proddatūr: 'Andhra Pradesh',
	Pratāpgarh: 'Rajasthan',
	'Port Blair': 'Andaman and Nicobar',
	Porsa: 'Madhya Pradesh',
	Porbandar: 'Gujarat',
	Poonamalle: 'Tamil Nadu',
	Ponnūru: 'Andhra Pradesh',
	Ponneri: 'Tamil Nadu',
	Ponnāni: 'Kerala',
	Puducherry: 'Puducherry',
	Ponda: 'Goa',
	Polūr: 'Tamil Nadu',
	Pollachi: 'Tamil Nadu',
	Polavaram: 'Andhra Pradesh',
	Polasara: 'Odisha',
	Pokaran: 'Rajasthan',
	Pithorāgarh: 'Uttarakhand',
	Pithāpuram: 'Andhra Pradesh',
	Piro: 'Bihar',
	Piriyāpatna: 'Karnataka',
	Piravam: 'Kerala',
	Pīpri: 'Maharashtra',
	Pipraich: 'Uttar Pradesh',
	Pipili: 'Odisha',
	Pīpār: 'Rajasthan',
	Pinjaur: 'Haryana',
	Pindwāra: 'Rajasthan',
	Pināhat: 'Uttar Pradesh',
	Pimpri: 'Maharashtra',
	Pilkhua: 'Uttar Pradesh',
	Pīlibhīt: 'Uttar Pradesh',
	Pilibangan: 'Rajasthan',
	Pilāni: 'Rajasthan',
	Pihānī: 'Uttar Pradesh',
	Phulpur: 'Uttar Pradesh',
	Phulera: 'Rajasthan',
	Phulbāni: 'Odisha',
	Phirangipuram: 'Andhra Pradesh',
	Phillaur: 'Punjab',
	Phek: 'Manipur',
	Phaphūnd: 'Uttar Pradesh',
	Phaltan: 'Maharashtra',
	Phalodi: 'Rajasthan',
	Phalauda: 'Uttar Pradesh',
	Phagwāra: 'Punjab',
	Petlād: 'Gujarat',
	Perundurai: 'Tamil Nadu',
	Perumpāvūr: 'Kerala',
	Periyanayakkanpalaiyam: 'Tamil Nadu',
	Periyakulam: 'Tamil Nadu',
	Peravurani: 'Tamil Nadu',
	Peranāmpattu: 'Tamil Nadu',
	Perambalur: 'Tamil Nadu',
	Penukonda: 'Andhra Pradesh',
	Penugonda: 'Andhra Pradesh',
	Pennāgaram: 'Tamil Nadu',
	Pennādam: 'Tamil Nadu',
	Pen: 'Maharashtra',
	Pehowa: 'Haryana',
	Peddāpuram: 'Andhra Pradesh',
	Peddapalli: 'Telangana',
	Pedana: 'Andhra Pradesh',
	Payyannūr: 'Kerala',
	Pawāyan: 'Uttar Pradesh',
	Pāvugada: 'Karnataka',
	Pauri: 'Uttarakhand',
	Pawni: 'Maharashtra',
	Pātūr: 'Maharashtra',
	Pattukkottai: 'Tamil Nadu',
	Patti: 'Punjab',
	Patnāgarh: 'Odisha',
	Patna: 'Bihar',
	Patiāla: 'Punjab',
	Pāthri: 'Maharashtra',
	Patharia: 'Madhya Pradesh',
	Pāthardih: 'Jharkhand',
	Pāthardi: 'Maharashtra',
	Pathānkot: 'Punjab',
	Pathanāmthitta: 'Kerala',
	Pathalgaon: 'Chhattisgarh',
	Pataudi: 'Haryana',
	Patancheru: 'Telangana',
	Pātan: 'Bagmati Province',
	Patāmundai: 'Odisha',
	Pāsighāt: 'Arunachal Pradesh',
	Pasān: 'Chhattisgarh',
	Parvatsar: 'Rajasthan',
	Pārvatipuram: 'Andhra Pradesh',
	Partūr: 'Maharashtra',
	Parola: 'Maharashtra',
	'Parli Vaijnāth': 'Maharashtra',
	Parlākimidi: 'Andhra Pradesh',
	Pariyāpuram: 'Kerala',
	Parīchhatgarh: 'Uttar Pradesh',
	Pārdi: 'Gujarat',
	Parbhani: 'Maharashtra',
	'Paravūr Tekkumbhāgam': 'Kerala',
	Parāsia: 'Madhya Pradesh',
	Paramagudi: 'Tamil Nadu',
	'Parādīp Garh': 'Odisha',
	Pāppinisshēri: 'Kerala',
	Papanasam: 'Tamil Nadu',
	'Pāonta Sāhib': 'Himachal Pradesh',
	Panvel: 'Maharashtra',
	Panruti: 'Tamil Nadu',
	Panna: 'Madhya Pradesh',
	Ponmana: 'Kerala',
	Pānīpat: 'Haryana',
	Pānihāti: 'West Bengal',
	Pandua: 'West Bengal',
	Pāndhurnā: 'Madhya Pradesh',
	Pandharpur: 'Maharashtra',
	'French Rocks': 'Karnataka',
	Panaji: 'Goa',
	Panāgar: 'Madhya Pradesh',
	Palwal: 'Haryana',
	Pāloncha: 'Telangana',
	Palani: 'Tamil Nadu',
	Palmaner: 'Andhra Pradesh',
	Pallippatti: 'Tamil Nadu',
	Pallikondai: 'Tamil Nadu',
	Pallāvaram: 'Tamil Nadu',
	Pallappatti: 'Tamil Nadu',
	Palladam: 'Tamil Nadu',
	Pālkonda: 'Andhra Pradesh',
	Pālitāna: 'Gujarat',
	'Paliā Kalān': 'Uttar Pradesh',
	Pāli: 'Madhya Pradesh',
	Palakkad: 'Kerala',
	Pālghar: 'Maharashtra',
	Palera: 'Madhya Pradesh',
	Palāsa: 'Andhra Pradesh',
	Pālanpur: 'Gujarat',
	Pālakollu: 'Andhra Pradesh',
	Pālakkodu: 'Tamil Nadu',
	Pakur: 'Jharkhand',
	Pākāla: 'Andhra Pradesh',
	Paithan: 'Maharashtra',
	Pahāsu: 'Uttar Pradesh',
	Padrauna: 'Uttar Pradesh',
	Padra: 'Gujarat',
	Padmanābhapuram: 'Tamil Nadu',
	Padampur: 'Odisha',
	Padam: 'Ladakh',
	Pachperwa: 'Uttar Pradesh',
	Pāchora: 'Maharashtra',
	Ottappālam: 'Kerala',
	Dharashiv: 'Maharashtra',
	Orai: 'Uttar Pradesh',
	Ongole: 'Andhra Pradesh',
	Okha: 'Sakhalin Oblast',
	Ozar: 'Maharashtra',
	Obra: 'Uttar Pradesh',
	Nūzvīd: 'Andhra Pradesh',
	Nūrpur: 'Uttar Pradesh',
	Nowrangapur: 'Odisha',
	'North Lakhimpur': 'Assam',
	'North Guwāhāti': 'Assam',
	Nongstoin: 'Meghalaya',
	Nokha: 'Rajasthan',
	Nohar: 'Rajasthan',
	Noāmundi: 'Jharkhand',
	Nizāmābād: 'Telangana',
	Nirmāli: 'Bihar',
	Nirmal: 'Telangana',
	Nipāni: 'Maharashtra',
	'Neem ka Thana': 'Rajasthan',
	Nīmbāhera: 'Rajasthan',
	Nimāparha: 'Odisha',
	Nīmāj: 'Rajasthan',
	Nīlokheri: 'Haryana',
	Nīlēshwar: 'Kerala',
	Nilanga: 'Maharashtra',
	Nilakottai: 'Tamil Nadu',
	Nīlgiri: 'Odisha',
	Nihtaur: 'Uttar Pradesh',
	Nidadavole: 'Andhra Pradesh',
	Nichlaul: 'Uttar Pradesh',
	Neyyāttinkara: 'Kerala',
	Neyveli: 'Tamil Nadu',
	'New Delhi': 'Delhi',
	Neral: 'Maharashtra',
	Nepānagar: 'Madhya Pradesh',
	Nellore: 'Andhra Pradesh',
	Nellikkuppam: 'Tamil Nadu',
	Nelamangala: 'Karnataka',
	Nedumangād: 'Kerala',
	Nāyudupet: 'Andhra Pradesh',
	Nayāgarh: 'Odisha',
	Nawanshahr: 'Punjab',
	Nawalgarh: 'Rajasthan',
	Niwai: 'Rajasthan',
	Nawāda: 'Bihar',
	Nāwa: 'Rajasthan',
	Navsari: 'Gujarat',
	Navalgund: 'Karnataka',
	Navadwīp: 'West Bengal',
	Nautanwa: 'Uttar Pradesh',
	Naugachhia: 'Bihar',
	Nattam: 'Tamil Nadu',
	Nāthdwāra: 'Rajasthan',
	Nasrullāhganj: 'Madhya Pradesh',
	Nāsriganj: 'Bihar',
	Nāspur: 'Telangana',
	Nasīrābād: 'Rajasthan',
	Nashik: 'Maharashtra',
	Narwar: 'Madhya Pradesh',
	Narwāna: 'Haryana',
	Narsīpatnam: 'Andhra Pradesh',
	Narsinghgarh: 'Madhya Pradesh',
	Narsimhapur: 'Madhya Pradesh',
	Nārnaund: 'Haryana',
	Nārnaul: 'Haryana',
	Nargund: 'Karnataka',
	Narela: 'Delhi',
	Naregal: 'Karnataka',
	Nārāyanpet: 'Telangana',
	Narāyangarh: 'Haryana',
	Nāravārikuppam: 'Tamil Nadu',
	Naraura: 'Uttar Pradesh',
	Narauli: 'Uttar Pradesh',
	Narasaraopet: 'Andhra Pradesh',
	Narasapur: 'Andhra Pradesh',
	Narasannapeta: 'Andhra Pradesh',
	Naraini: 'Madhya Pradesh',
	Naraina: 'Rajasthan',
	Napāsar: 'Rajasthan',
	Nānpāra: 'Uttar Pradesh',
	Nanjangūd: 'Karnataka',
	'Nāngloi Jāt': 'Delhi',
	Nangal: 'Punjab',
	Nandyāl: 'Andhra Pradesh',
	Nandurbar: 'Maharashtra',
	'Nāndūra Buzurg': 'Maharashtra',
	Nandikotkūr: 'Andhra Pradesh',
	Nandigāma: 'Andhra Pradesh',
	Nāndgaon: 'Maharashtra',
	Nanded: 'Maharashtra',
	Nanauta: 'Uttar Pradesh',
	Nāmrup: 'Assam',
	Nambiyūr: 'Tamil Nadu',
	Nāmakkal: 'Tamil Nadu',
	Nāmagiripettai: 'Tamil Nadu',
	Nalhāti: 'West Bengal',
	Nalgonda: 'Telangana',
	Naldurg: 'Maharashtra',
	Nakūr: 'Uttar Pradesh',
	Naksalbāri: 'West Bengal',
	Nakodar: 'Punjab',
	Najībābād: 'Uttar Pradesh',
	Najafgarh: 'Delhi',
	Nainwa: 'Rajasthan',
	Nainpur: 'Madhya Pradesh',
	'Naini Tāl': 'Uttarakhand',
	Naihāti: 'West Bengal',
	Nahorkatiya: 'Assam',
	Nāhan: 'Himachal Pradesh',
	Nagpur: 'Maharashtra',
	Nāgod: 'Madhya Pradesh',
	Nagīna: 'Uttar Pradesh',
	Nāgercoil: 'Tamil Nadu',
	Nagda: 'Madhya Pradesh',
	Nāgaur: 'Rajasthan',
	'Nāgar Karnūl': 'Telangana',
	Nagari: 'Andhra Pradesh',
	Nagar: 'Rajasthan',
	Negapatam: 'Tamil Nadu',
	Nāgamangala: 'Karnataka',
	Naduvannūr: 'Kerala',
	Nadiād: 'Gujarat',
	Nādbai: 'Rajasthan',
	Nādāpuram: 'Kerala',
	Nābha: 'Punjab',
	Mysore: 'Karnataka',
	Muzaffarpur: 'Bihar',
	Muzaffarnagar: 'Uttar Pradesh',
	Mūvattupula: 'Kerala',
	Muttupet: 'Tamil Nadu',
	Mussoorie: 'Uttarakhand',
	Musiri: 'Tamil Nadu',
	Mushābani: 'Jharkhand',
	Murwāra: 'Madhya Pradesh',
	Murtajāpur: 'Maharashtra',
	Murshidābād: 'West Bengal',
	Murlīganj: 'Bihar',
	Morinda: 'Punjab',
	Murbād: 'Maharashtra',
	Murādnagar: 'Uttar Pradesh',
	Munnar: 'Kerala',
	Monghyr: 'Bihar',
	Mungeli: 'Chhattisgarh',
	Mungaoli: 'Madhya Pradesh',
	Mūndwa: 'Rajasthan',
	Mundra: 'Gujarat',
	Mundgod: 'Karnataka',
	Mundargi: 'Karnataka',
	Multai: 'Madhya Pradesh',
	Mūlki: 'Karnataka',
	Mulgund: 'Karnataka',
	Mulbāgal: 'Karnataka',
	Muluppilagadu: 'Kerala',
	Mūl: 'Maharashtra',
	Muktsar: 'Punjab',
	Mukher: 'Maharashtra',
	Mukeriān: 'Punjab',
	Muhammadābād: 'Uttar Pradesh',
	'Mughal Sarāi': 'Uttar Pradesh',
	Mudkhed: 'Maharashtra',
	Mudhol: 'Karnataka',
	Mudgal: 'Karnataka',
	Muddebihāl: 'Karnataka',
	Mūdbidri: 'Karnataka',
	Mubarakpur: 'Uttar Pradesh',
	Mothīhāri: 'Bihar',
	Morwa: 'Gujarat',
	Morsi: 'Maharashtra',
	Morena: 'Madhya Pradesh',
	Morbi: 'Gujarat',
	Morār: 'Madhya Pradesh',
	Moram: 'Maharashtra',
	Morādābād: 'Uttar Pradesh',
	Mon: 'Nagaland',
	Mokokchūng: 'Nagaland',
	Mokameh: 'Bihar',
	Moirāng: 'Manipur',
	Moga: 'Punjab',
	Modāsa: 'Gujarat',
	Misrikh: 'Uttar Pradesh',
	Miriālgūda: 'Telangana',
	Mīrganj: 'Uttar Pradesh',
	'Mīrānpur Katra': 'Uttar Pradesh',
	Mīrānpur: 'Uttar Pradesh',
	Mīnjūr: 'Tamil Nadu',
	Milak: 'Uttar Pradesh',
	Mihona: 'Madhya Pradesh',
	Mhāsvād: 'Maharashtra',
	Mettur: 'Tamil Nadu',
	Mettupalayam: 'Tamil Nadu',
	Merta: 'Rajasthan',
	Mendarda: 'Gujarat',
	Memāri: 'West Bengal',
	Melur: 'Tamil Nadu',
	Mehndāwal: 'Uttar Pradesh',
	Mehkar: 'Maharashtra',
	Meerut: 'Uttar Pradesh',
	Medinīpur: 'West Bengal',
	Medak: 'Telangana',
	Mayiladuthurai: 'Tamil Nadu',
	'Mayāng Imphāl': 'Manipur',
	Mawāna: 'Uttar Pradesh',
	Mavoor: 'Kerala',
	Māvelikara: 'Kerala',
	Maur: 'Punjab',
	Mauganj: 'Madhya Pradesh',
	Maudaha: 'Uttar Pradesh',
	'Mau Aimma': 'Uttar Pradesh',
	Mau: 'Uttar Pradesh',
	Mattanur: 'Kerala',
	Mathura: 'Uttar Pradesh',
	Mātābhānga: 'West Bengal',
	'Masaurhi Buzurg': 'Bihar',
	Mormugao: 'Goa',
	Mārkāpur: 'Andhra Pradesh',
	Mariāni: 'Assam',
	Mariāhu: 'Uttar Pradesh',
	Marhaura: 'Bihar',
	Margherita: 'Assam',
	Marakkanam: 'Tamil Nadu',
	Marayur: 'Kerala',
	Mārahra: 'Uttar Pradesh',
	Māpuca: 'Goa',
	Mānwat: 'Maharashtra',
	Mānvi: 'Karnataka',
	Manthani: 'Telangana',
	Mānsa: 'Gujarat',
	Manoharpur: 'Rajasthan',
	Mannargudi: 'Tamil Nadu',
	Mannārakkāt: 'Kerala',
	Manmād: 'Maharashtra',
	Mankāchar: 'Meghalaya',
	Majalgaon: 'Maharashtra',
	Manjhanpur: 'Uttar Pradesh',
	Manjeri: 'Kerala',
	Manipal: 'Karnataka',
	Manihāri: 'Bihar',
	Maniar: 'Uttar Pradesh',
	'Mangrūl Pīr': 'Maharashtra',
	Māngrol: 'Gujarat',
	Manglaur: 'Uttarakhand',
	Mangalore: 'Karnataka',
	Mangaldai: 'Assam',
	Mangalagiri: 'Andhra Pradesh',
	Maner: 'Bihar',
	Mandya: 'Karnataka',
	Māndvi: 'Gujarat',
	Mandsaur: 'Madhya Pradesh',
	Mandlā: 'Madhya Pradesh',
	Mandi: 'Himachal Pradesh',
	Mandāwar: 'Uttar Pradesh',
	Mandapeta: 'Andhra Pradesh',
	Mandapam: 'Tamil Nadu',
	Māndalgarh: 'Rajasthan',
	Māndal: 'Rajasthan',
	Mancherāl: 'Telangana',
	Manāwar: 'Madhya Pradesh',
	Mānāvadar: 'Gujarat',
	Manāsa: 'Madhya Pradesh',
	Manapparai: 'Tamil Nadu',
	Manamadurai: 'Tamil Nadu',
	Manali: 'Tamil Nadu',
	Mālvan: 'Maharashtra',
	Mālūr: 'Karnataka',
	Mālpura: 'Rajasthan',
	Malpe: 'Karnataka',
	Mallasamudram: 'Tamil Nadu',
	Malkāpur: 'Maharashtra',
	Malakanagiri: 'Odisha',
	Malīhābād: 'Uttar Pradesh',
	'Māler Kotla': 'Punjab',
	Mālegaon: 'Maharashtra',
	Malavalli: 'Karnataka',
	Malaut: 'Punjab',
	Malappuram: 'Kerala',
	Mākum: 'Assam',
	Maksi: 'Madhya Pradesh',
	Makrāna: 'Rajasthan',
	Mairwa: 'Bihar',
	Mainpuri: 'Uttar Pradesh',
	Maināguri: 'West Bengal',
	Maihar: 'Madhya Pradesh',
	Mahwah: 'New Jersey',
	Mahudha: 'Gujarat',
	Maholi: 'Uttar Pradesh',
	Mahobā: 'Uttar Pradesh',
	Mahmudābād: 'Uttar Pradesh',
	Mahīshādal: 'West Bengal',
	Mahgawān: 'Madhya Pradesh',
	Maheshwar: 'Madhya Pradesh',
	Mahendragarh: 'Haryana',
	Mahemdāvād: 'Gujarat',
	Mahē: 'Kerala',
	Mahbūbnagar: 'Telangana',
	Mahbūbābād: 'Telangana',
	Mahāsamund: 'Chhattisgarh',
	Mahārāganj: 'Uttar Pradesh',
	Mahārājgani: 'Bihar',
	Maham: 'Haryana',
	Mahālingpur: 'Karnataka',
	Mahād: 'Maharashtra',
	Maghar: 'Uttar Pradesh',
	Māgadi: 'Karnataka',
	Madurāntakam: 'Tamil Nadu',
	Madurai: 'Tamil Nadu',
	Madukkūr: 'Tamil Nadu',
	Madukkarai: 'Tamil Nadu',
	Chennai: 'Tamil Nadu',
	Madikeri: 'Karnataka',
	Madhyamgram: 'West Bengal',
	Madhupur: 'Jharkhand',
	Madhugiri: 'Karnataka',
	Madhubani: 'Bihar',
	Madhipura: 'Bihar',
	Madgaon: 'Goa',
	Maddūr: 'Karnataka',
	Madanapalle: 'Andhra Pradesh',
	Machilīpatnam: 'Andhra Pradesh',
	Machhlīshahr: 'Uttar Pradesh',
	Māchhīwāra: 'Punjab',
	Mācherla: 'Andhra Pradesh',
	Lunglei: 'Mizoram',
	Lūnāvāda: 'Gujarat',
	Ludhiāna: 'Punjab',
	Lucknow: 'Uttar Pradesh',
	Luckeesarai: 'Bihar',
	Losal: 'Rajasthan',
	Loni: 'Uttar Pradesh',
	Lonavla: 'Maharashtra',
	Lonar: 'Maharashtra',
	Lohārdagā: 'Jharkhand',
	Lingsugūr: 'Karnataka',
	Limbdi: 'Gujarat',
	Leteri: 'Madhya Pradesh',
	Leh: 'Ladakh',
	'Lāwar Khās': 'Uttar Pradesh',
	Laungowāl: 'Punjab',
	Latur: 'Maharashtra',
	Lāthi: 'Gujarat',
	Lātehār: 'Jharkhand',
	Lar: 'Uttar Pradesh',
	Lālsot: 'Rajasthan',
	Lālpur: 'Gujarat',
	Lalitpur: 'Uttar Pradesh',
	Lalgudi: 'Tamil Nadu',
	Lālgola: 'West Bengal',
	Lālganj: 'Bihar',
	Lakshmeshwar: 'Karnataka',
	Laksar: 'Uttarakhand',
	Lakhyabad: 'West Bengal',
	Lakhnādon: 'Madhya Pradesh',
	Lakhīmpur: 'Uttar Pradesh',
	Lākheri: 'Rajasthan',
	Lāharpur: 'Uttar Pradesh',
	Lahār: 'Madhya Pradesh',
	Lādwa: 'Haryana',
	Lādnūn: 'Rajasthan',
	'Lachhmangarh Sīkar': 'Rajasthan',
	Kuzhithurai: 'Tamil Nadu',
	Koothanallur: 'Tamil Nadu',
	Kuttampuzha: 'Kerala',
	Kutiyāna: 'Gujarat',
	Kutiatodu: 'Kerala',
	Kushtagi: 'Karnataka',
	Kurnool: 'Andhra Pradesh',
	Kurinjippādi: 'Tamil Nadu',
	Kurduvādi: 'Maharashtra',
	Kurandvād: 'Maharashtra',
	Kuppam: 'Andhra Pradesh',
	Kunnamkulam: 'Kerala',
	Kunnamangalam: 'Kerala',
	Kunigal: 'Karnataka',
	Kundla: 'Gujarat',
	Kundgol: 'Karnataka',
	Kundarkhi: 'Uttar Pradesh',
	Kunda: 'Uttar Pradesh',
	Kumta: 'Karnataka',
	Kūmher: 'Rajasthan',
	Kumhāri: 'Chhattisgarh',
	Kumbhrāj: 'Madhya Pradesh',
	Kumbakonam: 'Tamil Nadu',
	Kulu: 'Konya',
	Kulti: 'West Bengal',
	Kulpahār: 'Uttar Pradesh',
	Kulittalai: 'Tamil Nadu',
	Kulgam: 'Jammu and Kashmir',
	Kukshi: 'Madhya Pradesh',
	Kūkatpalli: 'Telangana',
	Kuju: 'Jharkhand',
	Kūdligi: 'Karnataka',
	Kudachi: 'Karnataka',
	Kuchera: 'Rajasthan',
	Kuchāman: 'Rajasthan',
	Kuchaiburi: 'Odisha',
	Krishnarājpet: 'Karnataka',
	Krishnanagar: 'West Bengal',
	Krishnagiri: 'Tamil Nadu',
	Kozhikode: 'Kerala',
	Koynanagar: 'Maharashtra',
	Kovvūr: 'Andhra Pradesh',
	Kovūr: 'Andhra Pradesh',
	Kovilpatti: 'Tamil Nadu',
	Kottūru: 'Karnataka',
	Kottayam: 'Kerala',
	Kottagūdem: 'Telangana',
	Kotputli: 'Rajasthan',
	Kotma: 'Madhya Pradesh',
	Kotdwāra: 'Uttarakhand',
	Kotapārh: 'Chhattisgarh',
	Kotamangalam: 'Kerala',
	Kotagiri: 'Tamil Nadu',
	Kota: 'Rajasthan',
	Kotā: 'Chhattisgarh',
	Kosigi: 'Andhra Pradesh',
	Kosi: 'Uttar Pradesh',
	Kosamba: 'Gujarat',
	Korwai: 'Madhya Pradesh',
	Koregaon: 'Maharashtra',
	Korba: 'Nabeul Governorate',
	Koratla: 'Telangana',
	Korāput: 'Odisha',
	Koppal: 'Karnataka',
	Kopargaon: 'Maharashtra',
	Kopāganj: 'Uttar Pradesh',
	Konnūr: 'Karnataka',
	Konnagar: 'West Bengal',
	Kondapalle: 'Andhra Pradesh',
	Kondagaon: 'Chhattisgarh',
	Konch: 'Uttar Pradesh',
	Konārka: 'Odisha',
	Kolasib: 'Mizoram',
	Kollegāl: 'Karnataka',
	Kolhāpur: 'Maharashtra',
	Kolāras: 'Madhya Pradesh',
	Kolār: 'Karnataka',
	Colachel: 'Tamil Nadu',
	Kokrajhar: 'Assam',
	Kohīma: 'Nagaland',
	Koelwār: 'Bihar',
	Kodungallūr: 'Kerala',
	Kodoli: 'Maharashtra',
	Kodīnar: 'Gujarat',
	Kodarmā: 'Jharkhand',
	Kodār: 'Telangana',
	Kodaikānāl: 'Tamil Nadu',
	'Koch Bihār': 'West Bengal',
	Koāth: 'Bihar',
	'Kizhake Chālakudi': 'Kerala',
	Kithor: 'Uttar Pradesh',
	Kishtwār: 'Jammu and Kashmir',
	Kishangarh: 'Rajasthan',
	Kishanganj: 'Bihar',
	Kīratpur: 'Uttar Pradesh',
	Kiraoli: 'Uttar Pradesh',
	Kinwat: 'Maharashtra',
	Kichha: 'Uttarakhand',
	Khūtār: 'Uttar Pradesh',
	Khurja: 'Uttar Pradesh',
	Khurda: 'Odisha',
	Khurai: 'Madhya Pradesh',
	Khunti: 'Jharkhand',
	Khuldābād: 'Maharashtra',
	Khowai: 'Tripura',
	Khopoli: 'Maharashtra',
	Khirkiyān: 'Madhya Pradesh',
	Khilchipur: 'Madhya Pradesh',
	Khetri: 'Rajasthan',
	Khetia: 'Maharashtra',
	Kheri: 'Uttar Pradesh',
	Kherālu: 'Gujarat',
	Khekra: 'Uttar Pradesh',
	Khedbrahma: 'Gujarat',
	Kheda: 'Gujarat',
	Khātra: 'West Bengal',
	Khatīma: 'Uttarakhand',
	Khātegaon: 'Madhya Pradesh',
	Khatauli: 'Uttar Pradesh',
	Khārupatia: 'Assam',
	Kharsia: 'Chhattisgarh',
	Kharkhauda: 'Haryana',
	Khargone: 'Madhya Pradesh',
	Khardah: 'West Bengal',
	Kharar: 'Punjab',
	Kharakvasla: 'Maharashtra',
	Kharagpur: 'West Bengal',
	Khāpa: 'Maharashtra',
	Khanna: 'Punjab',
	Khandwa: 'Madhya Pradesh',
	Khandela: 'Rajasthan',
	Khānāpur: 'Karnataka',
	Khammam: 'Telangana',
	Khāmgaon: 'Maharashtra',
	Khambhāt: 'Gujarat',
	Khambhāliya: 'Gujarat',
	Khamaria: 'Madhya Pradesh',
	Khalīlābād: 'Uttar Pradesh',
	'Khajuraho Group of Monuments': 'Madhya Pradesh',
	Khairāgarh: 'Chhattisgarh',
	Khairābād: 'Uttar Pradesh',
	Khair: 'Uttar Pradesh',
	Khagaul: 'Bihar',
	Khagaria: 'Bihar',
	Khadki: 'Maharashtra',
	Khada: 'Uttar Pradesh',
	Khāchrod: 'Madhya Pradesh',
	Kesinga: 'Odisha',
	'Keshorai Pātan': 'Rajasthan',
	Keshod: 'Gujarat',
	Kerūr: 'Karnataka',
	Kendrāparha: 'Odisha',
	Kenda: 'West Bengal',
	Kemrī: 'Uttar Pradesh',
	Kekri: 'Rajasthan',
	Kāyankulam: 'Kerala',
	Kayalpattinam: 'Tamil Nadu',
	Kawardha: 'Chhattisgarh',
	Kāvali: 'Andhra Pradesh',
	Kattivākkam: 'Tamil Nadu',
	Kattanam: 'Kerala',
	Kātrās: 'Jharkhand',
	Kātpādi: 'Tamil Nadu',
	Kātoya: 'West Bengal',
	Kātol: 'Maharashtra',
	Katihar: 'Bihar',
	Kathua: 'Jammu and Kashmir',
	Kāthor: 'Gujarat',
	Katghora: 'Chhattisgarh',
	Katangi: 'Madhya Pradesh',
	Kasrāwad: 'Madhya Pradesh',
	Kashipur: 'Uttarakhand',
	Kāsganj: 'Uttar Pradesh',
	Kāsaragod: 'Kerala',
	Karwar: 'Karnataka',
	Karur: 'Tamil Nadu',
	Kartārpur: 'Punjab',
	Kārsiyāng: 'West Bengal',
	'Karol Bāgh': 'Delhi',
	Karnāl: 'Haryana',
	Karmāla: 'Maharashtra',
	Kārkala: 'Karnataka',
	Karjat: 'Maharashtra',
	Karīmnagar: 'Telangana',
	Karīmganj: 'Assam',
	Karhal: 'Uttar Pradesh',
	Karera: 'Madhya Pradesh',
	Kareli: 'Madhya Pradesh',
	Karauli: 'Rajasthan',
	Kāranja: 'Maharashtra',
	Karamsad: 'Gujarat',
	Kāramadai: 'Tamil Nadu',
	Kāraikkudi: 'Tamil Nadu',
	Kāraikāl: 'Puducherry',
	Karād: 'Maharashtra',
	Kapūrthala: 'Punjab',
	Kāpren: 'Rajasthan',
	Kapadvanj: 'Gujarat',
	Kānth: 'Uttar Pradesh',
	Kantābānji: 'Odisha',
	Kānt: 'Uttar Pradesh',
	Kanpur: 'Uttar Pradesh',
	Kannod: 'Madhya Pradesh',
	Kanniyākumāri: 'Tamil Nadu',
	Kannauj: 'Uttar Pradesh',
	Kānnangād: 'Kerala',
	Kannad: 'Maharashtra',
	Kānker: 'Chhattisgarh',
	Kānke: 'Jharkhand',
	Kankauli: 'Maharashtra',
	Kanigiri: 'Andhra Pradesh',
	Kangayam: 'Tamil Nadu',
	Kandukūr: 'Andhra Pradesh',
	Kāndla: 'Gujarat',
	Kāndi: 'West Bengal',
	Kāndhla: 'Uttar Pradesh',
	Kanchipuram: 'Tamil Nadu',
	Kānkānhalli: 'Karnataka',
	Kāmthi: 'Maharashtra',
	Kampli: 'Karnataka',
	Cumbum: 'Andhra Pradesh',
	Kāmārhāti: 'West Bengal',
	Kāmāreddi: 'Telangana',
	Kāman: 'Rajasthan',
	Kamalganj: 'Uttar Pradesh',
	Kāmākhyānagar: 'Odisha',
	Kalyani: 'West Bengal',
	Kalyān: 'Maharashtra',
	Kalugumalai: 'Tamil Nadu',
	Kālpi: 'Uttar Pradesh',
	Kalpatta: 'Kerala',
	Kālol: 'Gujarat',
	Kālna: 'West Bengal',
	Kalmeshwar: 'Maharashtra',
	Kallidaikurichi: 'Tamil Nadu',
	Kallakkurichchi: 'Tamil Nadu',
	Kālka: 'Himachal Pradesh',
	Kāliyāganj: 'West Bengal',
	Kālimpong: 'West Bengal',
	Kalghatgi: 'Karnataka',
	Kālāvad: 'Gujarat',
	Kālānwāli: 'Haryana',
	Kalānaur: 'Haryana',
	Kalamnūri: 'Maharashtra',
	Kalamb: 'Maharashtra',
	Kalakkādu: 'Tamil Nadu',
	Kakrāla: 'Uttar Pradesh',
	Kākori: 'Uttar Pradesh',
	Kākināda: 'Andhra Pradesh',
	Kakdwip: 'West Bengal',
	Kakching: 'Manipur',
	Kaithal: 'Haryana',
	Kairāna: 'Uttar Pradesh',
	Kaimori: 'Madhya Pradesh',
	Kaimganj: 'Uttar Pradesh',
	Kailāshahar: 'Tripura',
	Kailāras: 'Madhya Pradesh',
	Kaikalūr: 'Andhra Pradesh',
	Kāgal: 'Maharashtra',
	Kadūr: 'Karnataka',
	Kadod: 'Gujarat',
	Kadiri: 'Andhra Pradesh',
	Kadi: 'Gujarat',
	Kadayanallur: 'Tamil Nadu',
	Kānchrāpāra: 'West Bengal',
	Kachhwa: 'Uttar Pradesh',
	Kabrāi: 'Uttar Pradesh',
	Junnar: 'Maharashtra',
	Jūnāgarh: 'Chhattisgarh',
	Jūnāgadh: 'Gujarat',
	'Jhumri Telaiya': 'Jharkhand',
	Jalandhar: 'Punjab',
	Jugsālai: 'Jharkhand',
	Jorhāt: 'Assam',
	Jora: 'Madhya Pradesh',
	Jalārpet: 'Tamil Nadu',
	Jogbani: 'Bihar',
	'Jodiya Bandar': 'Gujarat',
	Jodhpur: 'Gujarat',
	Jintūr: 'Maharashtra',
	Jīnd: 'Haryana',
	Jhūsi: 'Uttar Pradesh',
	Jhunjhunūn: 'Rajasthan',
	Jhinjhāna: 'Uttar Pradesh',
	Jhīnjhak: 'Uttar Pradesh',
	Jharsuguda: 'Odisha',
	Jharia: 'Jharkhand',
	Jhārgrām: 'West Bengal',
	Jhānsi: 'Uttar Pradesh',
	Jhanjhārpur: 'Bihar',
	Jhālu: 'Uttar Pradesh',
	Jhālrapātan: 'Rajasthan',
	Jhalidā: 'West Bengal',
	Jhālāwār: 'Rajasthan',
	Jhajjar: 'Haryana',
	'Jhā-Jhā': 'Bihar',
	Jhābua: 'Madhya Pradesh',
	Jewar: 'Uttar Pradesh',
	Jevargi: 'Karnataka',
	Jetpur: 'Gujarat',
	Jeypore: 'Odisha',
	Jainagar: 'Bihar',
	'Jaynagar Majilpur': 'West Bengal',
	Jayamkondacholapuram: 'Tamil Nadu',
	Jāwad: 'Madhya Pradesh',
	Jaunpur: 'Uttar Pradesh',
	Jatāra: 'Madhya Pradesh',
	Jatani: 'Odisha',
	Jaswantnagar: 'Uttar Pradesh',
	Jaspur: 'Uttarakhand',
	Jasidih: 'Jharkhand',
	Jashpurnagar: 'Chhattisgarh',
	Jasdan: 'Gujarat',
	Jarwal: 'Uttar Pradesh',
	Jaorā: 'Madhya Pradesh',
	Jānsath: 'Uttar Pradesh',
	Jānjgīr: 'Chhattisgarh',
	Jangipur: 'West Bengal',
	Jangaon: 'Telangana',
	Jandiāla: 'Punjab',
	Jāmuria: 'West Bengal',
	Jamūī: 'Bihar',
	Jāmtāra: 'Jharkhand',
	Jamshedpur: 'Jharkhand',
	Jamnagar: 'Gujarat',
	Jammu: 'Jammu and Kashmir',
	Jammalamadugu: 'Andhra Pradesh',
	Jamkhandi: 'Karnataka',
	Jambusar: 'Gujarat',
	Jāmai: 'Madhya Pradesh',
	Jāmadoba: 'Jharkhand',
	Jalpāiguri: 'West Bengal',
	Jalor: 'Rajasthan',
	Jālna: 'Maharashtra',
	'Jalgaon Jamod': 'Maharashtra',
	Jalgaon: 'Maharashtra',
	Jaleshwar: 'Province 2',
	Jalesar: 'Uttar Pradesh',
	Jālaun: 'Uttar Pradesh',
	Jalālpur: 'Uttar Pradesh',
	Jalalpore: 'Gujarat',
	Jalālī: 'Uttar Pradesh',
	Jalālābad: 'Uttar Pradesh',
	Jājpur: 'Odisha',
	Jaito: 'Punjab',
	Jaitāran: 'Rajasthan',
	Jaisingpur: 'Maharashtra',
	Jaisalmer: 'Rajasthan',
	Jais: 'Uttar Pradesh',
	Jaipur: 'Rajasthan',
	Jahāzpur: 'Rajasthan',
	Jahāngīrābād: 'Uttar Pradesh',
	Jahānābād: 'Bihar',
	Jagtiāl: 'Telangana',
	Jagraon: 'Punjab',
	Jaggayyapeta: 'Andhra Pradesh',
	Jagdīspur: 'Bihar',
	Jagdīshpur: 'Uttar Pradesh',
	Jagdalpur: 'Chhattisgarh',
	Jagatsinghapur: 'Odisha',
	Jagalūr: 'Karnataka',
	Jagādhri: 'Haryana',
	Jabalpur: 'Madhya Pradesh',
	Itimādpur: 'Uttar Pradesh',
	Itārsi: 'Madhya Pradesh',
	Itānagar: 'Arunachal Pradesh',
	Islāmpur: 'Bihar',
	Islāmnagar: 'Uttar Pradesh',
	Irugūr: 'Tamil Nadu',
	Irinjālakuda: 'Kerala',
	Iringal: 'Kerala',
	'Ingrāj Bāzār': 'West Bengal',
	Indri: 'Haryana',
	Indore: 'Madhya Pradesh',
	Indi: 'Karnataka',
	Indergarh: 'Uttar Pradesh',
	Indāpur: 'Maharashtra',
	Imphāl: 'Manipur',
	Ilkal: 'Karnataka',
	Igatpuri: 'Maharashtra',
	Idappadi: 'Tamil Nadu',
	Ichchāpuram: 'Andhra Pradesh',
	Ichalkaranji: 'Maharashtra',
	Hyderābād: 'Telangana',
	Hadagalli: 'Karnataka',
	Husainābād: 'Jharkhand',
	Hunsūr: 'Karnataka',
	Hungund: 'Karnataka',
	Hukeri: 'Karnataka',
	Hugli: 'West Bengal',
	Hubli: 'Karnataka',
	Howli: 'Assam',
	Hosūr: 'Tamil Nadu',
	Hospet: 'Karnataka',
	Hoskote: 'Karnataka',
	Hoshiārpur: 'Punjab',
	Narmadapuram: 'Madhya Pradesh',
	Hosdurga: 'Karnataka',
	Honnāli: 'Karnataka',
	Honavar: 'Karnataka',
	Homnābād: 'Karnataka',
	'Hole Narsipur': 'Karnataka',
	Holalkere: 'Karnataka',
	Hojāi: 'Assam',
	Hodal: 'Haryana',
	Hisuā: 'Bihar',
	Hisar: 'Haryana',
	Hiriyūr: 'Karnataka',
	Hirekerūr: 'Karnataka',
	Hīrākud: 'Odisha',
	Hinjilikatu: 'Odisha',
	Hingoli: 'Maharashtra',
	Hinganghāt: 'Maharashtra',
	Hindupur: 'Andhra Pradesh',
	Hindoria: 'Madhya Pradesh',
	Hindaun: 'Rajasthan',
	Himatnagar: 'Gujarat',
	Hilsa: 'Bihar',
	Hazāribāgh: 'Jharkhand',
	Hāveri: 'Karnataka',
	Hatta: 'Madhya Pradesh',
	Hāthras: 'Uttar Pradesh',
	Hastināpur: 'Uttar Pradesh',
	Hassan: 'Karnataka',
	Hāsimāra: 'West Bengal',
	Hasanpur: 'Uttar Pradesh',
	Harūr: 'Tamil Nadu',
	Harsūd: 'Madhya Pradesh',
	Harpanahalli: 'Karnataka',
	Harpālpur: 'Madhya Pradesh',
	Hārij: 'Gujarat',
	Harihar: 'Karnataka',
	Haridwar: 'Uttarakhand',
	Hardoī: 'Uttar Pradesh',
	'Harda Khās': 'Madhya Pradesh',
	Hāpur: 'Uttar Pradesh',
	Hāora: 'West Bengal',
	Hanumāngarh: 'Rajasthan',
	Hānsi: 'Haryana',
	Hāngal: 'Karnataka',
	Handiā: 'Uttar Pradesh',
	Hamīrpur: 'Uttar Pradesh',
	Halvad: 'Gujarat',
	Hālol: 'Gujarat',
	Haliyal: 'Karnataka',
	Hālīsahar: 'West Bengal',
	Haldwani: 'Uttarakhand',
	Haldaur: 'Uttar Pradesh',
	Hājo: 'Assam',
	Hājīpur: 'Bihar',
	Hailākāndi: 'Assam',
	Hāflong: 'Assam',
	Hadgāon: 'Maharashtra',
	Hābra: 'West Bengal',
	Gyānpur: 'Uttar Pradesh',
	Gwalior: 'Madhya Pradesh',
	Guskhara: 'West Bengal',
	Guruvāyūr: 'Kerala',
	'Guru Har Sahāi': 'Punjab',
	Gursarāi: 'Uttar Pradesh',
	Gursahāiganj: 'Uttar Pradesh',
	Gurmatkāl: 'Karnataka',
	Gurgaon: 'Haryana',
	Gunupur: 'Odisha',
	Guntur: 'Andhra Pradesh',
	'Guntakal Junction': 'Andhra Pradesh',
	Gunnaur: 'Uttar Pradesh',
	Gundlupēt: 'Karnataka',
	Guna: 'Madhya Pradesh',
	Gummidipundi: 'Tamil Nadu',
	Gumlā: 'Jharkhand',
	Gumia: 'Jharkhand',
	Guledagudda: 'Karnataka',
	Kalaburagi: 'Karnataka',
	Gulāothi: 'Uttar Pradesh',
	Gulābpura: 'Rajasthan',
	Gūduvāncheri: 'Tamil Nadu',
	Gūdūr: 'Andhra Pradesh',
	Gudiyatham: 'Tamil Nadu',
	Gudivāda: 'Andhra Pradesh',
	Gudalur: 'Tamil Nadu',
	Gubbi: 'Karnataka',
	Goyerkāta: 'West Bengal',
	Govardhan: 'Uttar Pradesh',
	Gosāba: 'West Bengal',
	Gorantla: 'Andhra Pradesh',
	Gorakhpur: 'Uttar Pradesh',
	Gobichettipalayam: 'Tamil Nadu',
	Gopālganj: 'Bihar',
	Gondiā: 'Maharashtra',
	Gondal: 'Gujarat',
	'Gondā City': 'Uttar Pradesh',
	Gomoh: 'Jharkhand',
	'Gola Gokarannāth': 'Uttar Pradesh',
	Golāghāt: 'Assam',
	Gokavaram: 'Andhra Pradesh',
	Gokarna: 'Karnataka',
	Gokak: 'Karnataka',
	Gohāna: 'Haryana',
	Gohadi: 'Madhya Pradesh',
	Godhra: 'Gujarat',
	Godda: 'Jharkhand',
	Gobindpur: 'Jharkhand',
	Gobārdānga: 'West Bengal',
	Goālpāra: 'Assam',
	Gīrīdīh: 'Jharkhand',
	Gingee: 'Tamil Nadu',
	Giddarbāha: 'Punjab',
	Giddalūr: 'Andhra Pradesh',
	Ghugus: 'Maharashtra',
	'Ghoti Budrukh': 'Maharashtra',
	Ghosī: 'Uttar Pradesh',
	Ghazīpur: 'Uttar Pradesh',
	Ghāziābād: 'Uttar Pradesh',
	Ghātsīla: 'Jharkhand',
	Ghātanji: 'Maharashtra',
	Ghātampur: 'Uttar Pradesh',
	Ghātāl: 'West Bengal',
	Gharaunda: 'Haryana',
	Gevrai: 'Maharashtra',
	Gaya: 'Kano',
	Gauripur: 'Assam',
	Gauribidanur: 'Karnataka',
	Guwahati: 'Assam',
	Garhwa: 'Jharkhand',
	Garui: 'West Bengal',
	Gariadhar: 'Gujarat',
	Garhshankar: 'Punjab',
	Garhmuktesar: 'Uttar Pradesh',
	Garhākota: 'Madhya Pradesh',
	Gannavaram: 'Andhra Pradesh',
	Gangtok: 'Sikkim',
	Gangolli: 'Karnataka',
	Gangoh: 'Uttar Pradesh',
	Gangāwati: 'Karnataka',
	Gangārāmpur: 'West Bengal',
	Gangāpur: 'Maharashtra',
	Gangānagar: 'Rajasthan',
	Gangākher: 'Maharashtra',
	Gandhinagar: 'Gujarat',
	Gāndhīdhām: 'Gujarat',
	Gandevi: 'Gujarat',
	Gāndarbal: 'Jammu and Kashmir',
	Gajraula: 'Uttar Pradesh',
	Gajendragarh: 'Karnataka',
	Gadwāl: 'Telangana',
	Gadhinglaj: 'Maharashtra',
	Gadhada: 'Gujarat',
	Gādarwāra: 'Madhya Pradesh',
	Gadag: 'Karnataka',
	'Fort Gloster': 'West Bengal',
	Forbesganj: 'Bihar',
	'Fīrozpur Jhirka': 'Haryana',
	Ferozepore: 'Punjab',
	Fīrozābād: 'Uttar Pradesh',
	Ferokh: 'Kerala',
	Fāzilka: 'Punjab',
	Fatwa: 'Bihar',
	'Fatehpur Sīkri': 'Uttar Pradesh',
	Fatehpur: 'Punjab',
	'Fatehgarh Chūriān': 'Punjab',
	'Fatehganj West': 'Uttar Pradesh',
	Fatehābād: 'Uttar Pradesh',
	Farrukhnagar: 'Telangana',
	Farrukhābād: 'Uttar Pradesh',
	Farīdkot: 'Punjab',
	Faridabad: 'Haryana',
	Farakka: 'West Bengal',
	Fālākāta: 'West Bengal',
	Faizpur: 'Maharashtra',
	Fyzābād: 'Uttar Pradesh',
	Etāwah: 'Uttar Pradesh',
	Erraguntla: 'Andhra Pradesh',
	Erode: 'Tamil Nadu',
	Erāttupetta: 'Kerala',
	Erandol: 'Maharashtra',
	Emmiganūr: 'Andhra Pradesh',
	Ellore: 'Andhra Pradesh',
	Elūr: 'Kerala',
	Ellenabad: 'Haryana',
	Elamanchili: 'Andhra Pradesh',
	Egra: 'West Bengal',
	Dwārka: 'Gujarat',
	Durgapur: 'West Bengal',
	Durgāpur: 'Maharashtra',
	Durg: 'Chhattisgarh',
	Dūngarpur: 'Rajasthan',
	'Ganj Dundwāra': 'Uttar Pradesh',
	Dumraon: 'Bihar',
	Dumra: 'Bihar',
	Dumka: 'Jharkhand',
	'Dum Duma': 'Assam',
	'Dam Dam': 'West Bengal',
	Duliajan: 'Assam',
	Dugda: 'Jharkhand',
	Dubrājpur: 'West Bengal',
	Dornakal: 'Telangana',
	Dorāha: 'Punjab',
	Dongargarh: 'Chhattisgarh',
	Dondaicha: 'Maharashtra',
	Dombivli: 'Maharashtra',
	Doddaballapura: 'Karnataka',
	Doda: 'Jammu and Kashmir',
	Diu: 'Dadra and Nagar Haveli and Daman and Diu',
	Dīsa: 'Gujarat',
	Diphu: 'Assam',
	Dīnhāta: 'West Bengal',
	Dindori: 'Madhya Pradesh',
	Dindigul: 'Tamil Nadu',
	Dīnānagar: 'Punjab',
	Dimāpur: 'Nagaland',
	Digras: 'Maharashtra',
	Dīglūr: 'Maharashtra',
	Dighwāra: 'Bihar',
	Digboi: 'Assam',
	Dīg: 'Rajasthan',
	Dīdwāna: 'Rajasthan',
	Dicholi: 'Goa',
	Dibrugarh: 'Assam',
	Dibai: 'Uttar Pradesh',
	'Diamond Harbour': 'West Bengal',
	Dhūri: 'Punjab',
	Dhupgāri: 'West Bengal',
	Dhuliān: 'West Bengal',
	Dhūlia: 'Maharashtra',
	Dhuburi: 'Assam',
	Dhrol: 'Gujarat',
	Dhrāngadhra: 'Gujarat',
	Dhorāji: 'Gujarat',
	Dhone: 'Andhra Pradesh',
	Dholka: 'Gujarat',
	Dhing: 'Assam',
	Dhenkānāl: 'Odisha',
	Dhekiajuli: 'Assam',
	Dhaurahra: 'Uttar Pradesh',
	Dhaulpur: 'Rajasthan',
	Dhārūr: 'Maharashtra',
	Dhāruhera: 'Haryana',
	Dharamsala: 'Himachal Pradesh',
	Dharmavaram: 'Andhra Pradesh',
	Dharmapuri: 'Tamil Nadu',
	Dharmanagar: 'Tripura',
	Dharmadam: 'Kerala',
	Dharmābād: 'Maharashtra',
	Dhāriwāl: 'Punjab',
	Dhāri: 'Gujarat',
	Dharapuram: 'Tamil Nadu',
	Dharangaon: 'Maharashtra',
	Dharampur: 'Gujarat',
	Dhār: 'Madhya Pradesh',
	Dhanera: 'Gujarat',
	Dhandhuka: 'Gujarat',
	Dhanbad: 'Jharkhand',
	Dhanaura: 'Uttar Pradesh',
	Dhanaula: 'Punjab',
	Dhamtari: 'Chhattisgarh',
	Dhāmpur: 'Uttar Pradesh',
	Dhāmnod: 'Madhya Pradesh',
	Dhāka: 'Bihar',
	Dewas: 'Madhya Pradesh',
	Deoli: 'Delhi',
	Devgarh: 'Rajasthan',
	'Devgadh Bāriya': 'Gujarat',
	Devarkonda: 'Telangana',
	Devanhalli: 'Karnataka',
	Devakottai: 'Tamil Nadu',
	'Deūlgaon Rāja': 'Maharashtra',
	Deshnoke: 'Rajasthan',
	Depālpur: 'Madhya Pradesh',
	'Deori Khās': 'Madhya Pradesh',
	Deoria: 'Uttar Pradesh',
	Deoraniān: 'Uttar Pradesh',
	Deolāli: 'Maharashtra',
	Deoghar: 'Jharkhand',
	Deogarh: 'Odisha',
	Deoband: 'Uttar Pradesh',
	Denkanikota: 'Tamil Nadu',
	Delhi: 'Delhi',
	Dehri: 'Bihar',
	'Dehra Dūn': 'Uttarakhand',
	Davangere: 'Karnataka',
	Dausa: 'Rajasthan',
	Daund: 'Maharashtra',
	Daudnagar: 'Bihar',
	Dattāpur: 'Maharashtra',
	Datia: 'Madhya Pradesh',
	Dātāganj: 'Uttar Pradesh',
	Dasūya: 'Punjab',
	Dāsna: 'Uttar Pradesh',
	Daryāpur: 'Maharashtra',
	Dārwha: 'Maharashtra',
	Darsi: 'Andhra Pradesh',
	Dārjiling: 'West Bengal',
	Darbhanga: 'Bihar',
	Dandeli: 'Karnataka',
	Dinapore: 'Bihar',
	Damoh: 'Madhya Pradesh',
	Dāmnagar: 'Gujarat',
	Daman: 'Dadra and Nagar Haveli and Daman and Diu',
	Daltonganj: 'Jharkhand',
	'Dalsingh Sarai': 'Bihar',
	Dalkola: 'West Bengal',
	Dākor: 'Gujarat',
	Dohad: 'Gujarat',
	Dahegām: 'Gujarat',
	Dāhānu: 'Maharashtra',
	Dādri: 'Uttar Pradesh',
	Dabwāli: 'Haryana',
	Dabra: 'Madhya Pradesh',
	Daboh: 'Madhya Pradesh',
	Dabhoi: 'Gujarat',
	Cuttack: 'Odisha',
	Curchorem: 'Goa',
	Cuncolim: 'Goa',
	Kadapa: 'Andhra Pradesh',
	Cuddalore: 'Tamil Nadu',
	Coondapoor: 'Karnataka',
	Colonelganj: 'Uttar Pradesh',
	Colgong: 'Bihar',
	Calangute: 'Goa',
	Coimbatore: 'Tamil Nadu',
	Cochin: 'Kerala',
	'Clement Town': 'Uttarakhand',
	Chūru: 'Rajasthan',
	Churāchāndpur: 'Manipur',
	Chunār: 'Uttar Pradesh',
	Chotila: 'Gujarat',
	Chopda: 'Maharashtra',
	Chodavaram: 'Andhra Pradesh',
	Rampachodavaram: 'Andhra Pradesh',
	Chittūr: 'Kerala',
	Chittaurgarh: 'Rajasthan',
	Chittaranjan: 'West Bengal',
	Chitradurga: 'Karnataka',
	Chītāpur: 'Karnataka',
	Chidawa: 'Rajasthan',
	Chīrāla: 'Andhra Pradesh',
	Chīpurupalle: 'Andhra Pradesh',
	Chiplūn: 'Maharashtra',
	Chintāmani: 'Karnataka',
	'Chinna Salem': 'Tamil Nadu',
	Chinnamanūr: 'Tamil Nadu',
	Chincholi: 'Karnataka',
	Chillupār: 'Uttar Pradesh',
	Chilakalūrupet: 'Andhra Pradesh',
	Chikodi: 'Karnataka',
	Chiknāyakanhalli: 'Karnataka',
	Chikmagalūr: 'Karnataka',
	Chikhli: 'Maharashtra',
	'Chik Ballāpur': 'Karnataka',
	Chidambaram: 'Tamil Nadu',
	Chicholi: 'Maharashtra',
	'Chhoti Sādri': 'Rajasthan',
	'Chhota Udepur': 'Gujarat',
	Chhindwāra: 'Madhya Pradesh',
	Chhibrāmau: 'Uttar Pradesh',
	Chhatarpur: 'Madhya Pradesh',
	Chhātāpur: 'Bihar',
	Chhāta: 'Uttar Pradesh',
	Chharra: 'Uttar Pradesh',
	Chhaprauli: 'Uttar Pradesh',
	Chāpra: 'Bihar',
	Chhāpar: 'Rajasthan',
	Chhala: 'Gujarat',
	Chhabra: 'Rajasthan',
	Chettipālaiyam: 'Tamil Nadu',
	Chetput: 'Tamil Nadu',
	Chennimalai: 'Tamil Nadu',
	Chengannūr: 'Kerala',
	Chengam: 'Tamil Nadu',
	Chengalpattu: 'Tamil Nadu',
	Chetwayi: 'Kerala',
	Chaksu: 'Rajasthan',
	Chatrapur: 'Odisha',
	Chatrā: 'Jharkhand',
	Chās: 'Jharkhand',
	Charthāwal: 'Uttar Pradesh',
	'Charkhi Dādri': 'Haryana',
	Charkhāri: 'Uttar Pradesh',
	Chāpar: 'Assam',
	Channarāyapatna: 'Karnataka',
	Channapatna: 'Karnataka',
	Channagiri: 'Karnataka',
	Changanācheri: 'Kerala',
	Chāndor: 'Maharashtra',
	'Chāndūr Bāzār': 'Maharashtra',
	Chāndūr: 'Maharashtra',
	Chāndur: 'Maharashtra',
	Chānda: 'Maharashtra',
	Chandrakona: 'West Bengal',
	Chāndpur: 'Uttar Pradesh',
	Chandīgarh: 'Chandigarh',
	Chanderi: 'Madhya Pradesh',
	Chanduasi: 'Uttar Pradesh',
	Chandauli: 'Uttar Pradesh',
	Chandannagar: 'West Bengal',
	Chānasma: 'Gujarat',
	Chamrajnagar: 'Karnataka',
	Chāmpa: 'Chhattisgarh',
	Chamba: 'Himachal Pradesh',
	Challapalle: 'Andhra Pradesh',
	Challakere: 'Karnataka',
	Chālisgaon: 'Maharashtra',
	Chalāla: 'Gujarat',
	Chakradharpur: 'Jharkhand',
	Chaklāsi: 'Gujarat',
	Chākia: 'Bihar',
	Chākan: 'Maharashtra',
	Chāībāsa: 'Jharkhand',
	Canning: 'West Bengal',
	Kannur: 'Kerala',
	Kolkata: 'West Bengal',
	Byādgi: 'Karnataka',
	Buxar: 'Bihar',
	Burla: 'Odisha',
	Burhar: 'Madhya Pradesh',
	Burhānpur: 'Madhya Pradesh',
	Būndu: 'Jharkhand',
	Būndi: 'Rajasthan',
	Buldāna: 'Maharashtra',
	Bulandshahr: 'Uttar Pradesh',
	Budhlāda: 'Punjab',
	Budhāna: 'Uttar Pradesh',
	Budaun: 'Uttar Pradesh',
	Brājarājnagar: 'Odisha',
	Brahmapur: 'Odisha',
	Botād: 'Gujarat',
	Borsad: 'Gujarat',
	Borivli: 'Maharashtra',
	Bongaigaon: 'Assam',
	Mumbai: 'Maharashtra',
	Bolpur: 'West Bengal',
	Bokāro: 'Jharkhand',
	Bokajān: 'Assam',
	Boisar: 'Maharashtra',
	Bodināyakkanūr: 'Tamil Nadu',
	'Buddh Gaya': 'Bihar',
	Bodhan: 'Telangana',
	Bobbili: 'Andhra Pradesh',
	Biswān: 'Uttar Pradesh',
	Bissāu: 'Rajasthan',
	Bishnupur: 'West Bengal',
	Bisauli: 'Uttar Pradesh',
	Bīsalpur: 'Uttar Pradesh',
	Birūr: 'Karnataka',
	Bīrpur: 'Bihar',
	Birmitrapur: 'Odisha',
	Binka: 'Odisha',
	Bindki: 'Uttar Pradesh',
	Etāwa: 'Madhya Pradesh',
	Bilthra: 'Uttar Pradesh',
	Bilsi: 'Uttar Pradesh',
	Bilsanda: 'Uttar Pradesh',
	Bilimora: 'Gujarat',
	Bilhaur: 'Uttar Pradesh',
	Bilgrām: 'Uttar Pradesh',
	Bilgi: 'Karnataka',
	Bīlāspur: 'Uttar Pradesh',
	Bilāspur: 'Chhattisgarh',
	Bilāsipāra: 'Assam',
	Bilāri: 'Uttar Pradesh',
	Bilāra: 'Rajasthan',
	Bikramganj: 'Bihar',
	Bīkaner: 'Rajasthan',
	Bijnor: 'Uttar Pradesh',
	Bijbehara: 'Jammu and Kashmir',
	Bijāwar: 'Madhya Pradesh',
	Bijapur: 'Karnataka',
	'Bihār Sharīf': 'Bihar',
	Bihārīganj: 'Bihar',
	Bidhūna: 'Uttar Pradesh',
	Bīdar: 'Karnataka',
	Biaora: 'Madhya Pradesh',
	'Kīl Bhuvanagiri': 'Tamil Nadu',
	Bhusāval: 'Maharashtra',
	Bhūm: 'Maharashtra',
	Bhuj: 'Gujarat',
	Bhudgaon: 'Maharashtra',
	Bhubaneshwar: 'Odisha',
	Bhuban: 'Odisha',
	Bhor: 'Maharashtra',
	Bhopāl: 'Madhya Pradesh',
	Bhongīr: 'Telangana',
	Bhongaon: 'Uttar Pradesh',
	Bhogpur: 'Punjab',
	Bhiwāni: 'Haryana',
	Bhiwandi: 'Maharashtra',
	Bhitarwār: 'Madhya Pradesh',
	Bhīnmāl: 'Rajasthan',
	Bhinga: 'Uttar Pradesh',
	Bhindār: 'Rajasthan',
	Bhind: 'Madhya Pradesh',
	Bhīmunipatnam: 'Andhra Pradesh',
	Bhīmavaram: 'Andhra Pradesh',
	Bhīlwāra: 'Rajasthan',
	Bhilai: 'Chhattisgarh',
	Bhīkhi: 'Punjab',
	Bhikangaon: 'Madhya Pradesh',
	Bhāyāvadar: 'Gujarat',
	Bhayandar: 'Maharashtra',
	Bhawānipatna: 'Odisha',
	Bhawānīgarh: 'Punjab',
	'Bhawāni Mandi': 'Madhya Pradesh',
	Bhavnagar: 'Gujarat',
	Bhavāni: 'Tamil Nadu',
	Bhattiprolu: 'Andhra Pradesh',
	Bhātpāra: 'West Bengal',
	Bhatkal: 'Karnataka',
	Bathinda: 'Punjab',
	Bhātāpāra: 'Chhattisgarh',
	Bhasāwar: 'Rajasthan',
	Bharwāri: 'Uttar Pradesh',
	Bharūch: 'Gujarat',
	Bharthana: 'Uttar Pradesh',
	Bharatpur: 'Bagmati Province',
	Bhānvad: 'Gujarat',
	Bhānpurī: 'Chhattisgarh',
	Bhānpura: 'Madhya Pradesh',
	Bhanjanagar: 'Odisha',
	Bhānder: 'Madhya Pradesh',
	Bhandāra: 'Maharashtra',
	Bhālki: 'Karnataka',
	Bhaisa: 'Telangana',
	Bhainsdehi: 'Madhya Pradesh',
	Bhāgalpur: 'Bihar',
	Bhadreswar: 'West Bengal',
	Bhadrāvati: 'Karnataka',
	Bhadrakh: 'Odisha',
	Bhadrāchalam: 'Telangana',
	Bhādra: 'Rajasthan',
	Bhadohi: 'Uttar Pradesh',
	Bhadaur: 'Punjab',
	Bhādāsar: 'Rajasthan',
	Bhachāu: 'Gujarat',
	Bhabhua: 'Bihar',
	Beypore: 'Kerala',
	Bewar: 'Uttar Pradesh',
	Betūl: 'Madhya Pradesh',
	Bettiah: 'Bihar',
	Betamcherla: 'Andhra Pradesh',
	'Beri Khās': 'Haryana',
	Berasia: 'Madhya Pradesh',
	Beohāri: 'Madhya Pradesh',
	Bemetāra: 'Chhattisgarh',
	Belūr: 'Karnataka',
	Belsand: 'Bihar',
	Belonia: 'Tripura',
	Bellary: 'Karnataka',
	Belagavi: 'Karnataka',
	Beldānga: 'West Bengal',
	Bela: 'Balochistan',
	Behror: 'Rajasthan',
	Behat: 'Uttar Pradesh',
	Begusarai: 'Bihar',
	Begūn: 'Rajasthan',
	Begamganj: 'Madhya Pradesh',
	Bedi: 'Gujarat',
	Beāwar: 'Rajasthan',
	Bāzpur: 'Uttarakhand',
	Bayāna: 'Rajasthan',
	Bawāna: 'Delhi',
	Baud: 'Odisha',
	Batāla: 'Punjab',
	Baswa: 'Rajasthan',
	Bāsudebpur: 'Odisha',
	Bastī: 'Uttar Pradesh',
	Bāsoda: 'Madhya Pradesh',
	Basni: 'Rajasthan',
	Basmat: 'Maharashtra',
	Basi: 'Rajasthan',
	'Basavana Bāgevādi': 'Karnataka',
	Basavakalyān: 'Karnataka',
	Barwāni: 'Madhya Pradesh',
	Barwāla: 'Haryana',
	Bāruni: 'Bihar',
	Baruipur: 'West Bengal',
	Bārsi: 'Maharashtra',
	Barpeta: 'Assam',
	Barpāli: 'Odisha',
	Barnāla: 'Punjab',
	Bārmer: 'Rajasthan',
	'Barki Saria': 'Jharkhand',
	'Barkā Kānā': 'Jharkhand',
	Barjala: 'Tripura',
	'Bari Sādri': 'Rajasthan',
	Bāri: 'Rajasthan',
	Barhiya: 'Bihar',
	Bārh: 'Bihar',
	Bargi: 'Madhya Pradesh',
	Bargarh: 'Odisha',
	Bareilly: 'Uttar Pradesh',
	Bārdoli: 'Gujarat',
	Barddhamān: 'West Bengal',
	'Bar Bigha': 'Bihar',
	Baraut: 'Uttar Pradesh',
	Barauli: 'Bihar',
	Bārāsat: 'West Bengal',
	Baranagar: 'West Bengal',
	Bārān: 'Rajasthan',
	Bāramūla: 'Jammu and Kashmir',
	Bārāmati: 'Maharashtra',
	Bārākpur: 'West Bengal',
	Bāpatla: 'Andhra Pradesh',
	Banūr: 'Punjab',
	Bantvāl: 'Karnataka',
	Bāntva: 'Gujarat',
	Bānswāra: 'Rajasthan',
	Bānswāda: 'Telangana',
	Bānsi: 'Uttar Pradesh',
	Bānsdīh: 'Uttar Pradesh',
	Bānsbāria: 'West Bengal',
	Bannūr: 'Karnataka',
	Banmankhi: 'Bihar',
	Bānkura: 'West Bengal',
	Bānki: 'Odisha',
	Bānka: 'Bihar',
	Bāngarmau: 'Uttar Pradesh',
	Bangarapet: 'Karnataka',
	Bangaon: 'West Bengal',
	Banganapalle: 'Andhra Pradesh',
	Bengaluru: 'Karnataka',
	Banga: 'Punjab',
	Bandipura: 'Jammu and Kashmir',
	Bāndīkūi: 'Rajasthan',
	Bānda: 'Uttar Pradesh',
	Banda: 'Madhya Pradesh',
	Banat: 'Uttar Pradesh',
	Bānapur: 'Odisha',
	'Bāmor Kalān': 'Madhya Pradesh',
	Bālurghāt: 'West Bengal',
	Bālugaon: 'Odisha',
	Balrāmpur: 'Uttar Pradesh',
	Bālotra: 'Rajasthan',
	'Baloda Bāzār': 'Chhattisgarh',
	Balod: 'Chhattisgarh',
	Bāli: 'Rajasthan',
	Ballālpur: 'Maharashtra',
	Balasore: 'Odisha',
	Balarāmpur: 'West Bengal',
	Bālāpur: 'Maharashtra',
	Balāngīr: 'Odisha',
	Bālāghāt: 'Madhya Pradesh',
	Bālāchor: 'Punjab',
	Bakhtiyārpur: 'Bihar',
	'Budge Budge': 'West Bengal',
	Bairāgnia: 'Bihar',
	Byndoor: 'Karnataka',
	'Bail-Hongal': 'Karnataka',
	Baihar: 'Madhya Pradesh',
	Baidyabāti: 'West Bengal',
	Bahraigh: 'Uttar Pradesh',
	Bahjoi: 'Uttar Pradesh',
	Baheri: 'Uttar Pradesh',
	Baharampur: 'West Bengal',
	Bahādurgarh: 'Haryana',
	Bahādurganj: 'Bihar',
	Bāh: 'Uttar Pradesh',
	Bagulā: 'West Bengal',
	Bāghpat: 'Uttar Pradesh',
	Bagdogra: 'West Bengal',
	'Bāgha Purāna': 'Punjab',
	Bāgepalli: 'Karnataka',
	Bagasra: 'Gujarat',
	Bagar: 'Rajasthan',
	Bagalkot: 'Karnataka',
	Bagaha: 'Bihar',
	Badvel: 'Andhra Pradesh',
	Badūria: 'West Bengal',
	Badnāwar: 'Madhya Pradesh',
	Badlapur: 'Maharashtra',
	Bādāmi: 'Karnataka',
	Badagara: 'Kerala',
	'Bada Barabīl': 'Odisha',
	Bachhraon: 'Uttar Pradesh',
	Babrāla: 'Uttar Pradesh',
	Bābra: 'Gujarat',
	Babīna: 'Uttar Pradesh',
	Baberu: 'Uttar Pradesh',
	Bābai: 'Madhya Pradesh',
	Azamgarh: 'Uttar Pradesh',
	Ayodhya: 'Uttar Pradesh',
	Ayakudi: 'Tamil Nadu',
	Avanigadda: 'Andhra Pradesh',
	Avinashi: 'Tamil Nadu',
	Āvadi: 'Tamil Nadu',
	Ausa: 'Maharashtra',
	Aurangābād: 'Bihar',
	'Sambhaji Nagar': 'Maharashtra',
	Auraiya: 'Uttar Pradesh',
	Aurād: 'Karnataka',
	Attur: 'Tamil Nadu',
	Attingal: 'Kerala',
	Attili: 'Andhra Pradesh',
	Atraulī: 'Uttar Pradesh',
	Atmakūr: 'Andhra Pradesh',
	Adirampattinam: 'Tamil Nadu',
	Athni: 'Karnataka',
	Āthagarh: 'Odisha',
	Atarra: 'Uttar Pradesh',
	Āsind: 'Rajasthan',
	Āsika: 'Odisha',
	Asifābād: 'Telangana',
	Ashta: 'Maharashtra',
	Ashoknagar: 'Madhya Pradesh',
	Āsansol: 'West Bengal',
	Āsandh: 'Haryana',
	Ārvi: 'Maharashtra',
	Aruppukkottai: 'Tamil Nadu',
	Arumuganeri: 'Tamil Nadu',
	Arukutti: 'Kerala',
	Arsikere: 'Karnataka',
	Āron: 'Madhya Pradesh',
	Arkalgūd: 'Karnataka',
	Ariyalūr: 'Tamil Nadu',
	Arcot: 'Tamil Nadu',
	Arāria: 'Bihar',
	Arantāngi: 'Tamil Nadu',
	Arni: 'Tamil Nadu',
	Ārangaon: 'Maharashtra',
	Arang: 'Chhattisgarh',
	Arāmbāgh: 'West Bengal',
	Arakkonam: 'Tamil Nadu',
	Aonla: 'Uttar Pradesh',
	Anūpshahr: 'Uttar Pradesh',
	Anūppur: 'Madhya Pradesh',
	Anūpgarh: 'Rajasthan',
	Anta: 'Rajasthan',
	Anshing: 'Maharashtra',
	Annur: 'Tamil Nadu',
	Annigeri: 'Karnataka',
	Ankleshwar: 'Gujarat',
	Anjār: 'Gujarat',
	Anjangaon: 'Maharashtra',
	Anjad: 'Madhya Pradesh',
	Angul: 'Odisha',
	Angamāli: 'Kerala',
	Anekal: 'Karnataka',
	Andol: 'Telangana',
	Anthiyur: 'Tamil Nadu',
	Āndippatti: 'Tamil Nadu',
	Anantnag: 'Jammu and Kashmir',
	Anantapur: 'Andhra Pradesh',
	Anandpur: 'Punjab',
	Anand: 'Gujarat',
	Anakāpalle: 'Andhra Pradesh',
	Anamalais: 'Tamil Nadu',
	Amudālavalasa: 'Andhra Pradesh',
	Āmta: 'West Bengal',
	Amroli: 'Gujarat',
	Amroha: 'Uttar Pradesh',
	Amritsar: 'Punjab',
	Amreli: 'Gujarat',
	Amrāvati: 'Maharashtra',
	Amod: 'Gujarat',
	Āmli: 'Dadra and Nagar Haveli and Daman and Diu',
	Āmlāgora: 'West Bengal',
	Amla: 'Madhya Pradesh',
	Amet: 'Rajasthan',
	Ambur: 'Tamil Nadu',
	Ambikāpur: 'Chhattisgarh',
	Ambattūr: 'Tamil Nadu',
	Ambasamudram: 'Tamil Nadu',
	Ambāla: 'Haryana',
	Ambājogāi: 'Maharashtra',
	Ambāh: 'Madhya Pradesh',
	Ambad: 'Maharashtra',
	Amarpur: 'Bihar',
	Amarpātan: 'Madhya Pradesh',
	Amarnāth: 'Maharashtra',
	Amalner: 'Maharashtra',
	Amalāpuram: 'Andhra Pradesh',
	Alwaye: 'Kerala',
	Alwar: 'Rajasthan',
	Alot: 'Madhya Pradesh',
	Along: 'Arunachal Pradesh',
	Alnāvar: 'Karnataka',
	Almora: 'Uttarakhand',
	Alappuzha: 'Kerala',
	Prayagraj: 'Uttar Pradesh',
	'Alīpur Duār': 'West Bengal',
	Alīpur: 'Delhi',
	Alīgarh: 'Uttar Pradesh',
	Alīganj: 'Uttar Pradesh',
	Alībāg: 'Maharashtra',
	Ālangulam: 'Tamil Nadu',
	Alangāyam: 'Tamil Nadu',
	Alandur: 'Tamil Nadu',
	Alandi: 'Maharashtra',
	Aland: 'Karnataka',
	Akot: 'Maharashtra',
	Akola: 'Maharashtra',
	Aklera: 'Rajasthan',
	Akivīdu: 'Andhra Pradesh',
	Akbarpur: 'Uttar Pradesh',
	Akaltara: 'Chhattisgarh',
	Akalkot: 'Maharashtra',
	Ajra: 'Maharashtra',
	Ajnāla: 'Punjab',
	Ajmer: 'Rajasthan',
	Aizawl: 'Mizoram',
	Ahraura: 'Uttar Pradesh',
	Ahmadpur: 'Maharashtra',
	Ahmadnagar: 'Maharashtra',
	Ahmedabad: 'Gujarat',
	Agra: 'Uttar Pradesh',
	Agartala: 'Tripura',
	Agar: 'Madhya Pradesh',
	Afzalpur: 'Karnataka',
	Afzalgarh: 'Uttar Pradesh',
	Adūr: 'Kerala',
	Ādoni: 'Andhra Pradesh',
	Ādilābād: 'Telangana',
	Addanki: 'Andhra Pradesh',
	Achhnera: 'Uttar Pradesh',
	Achalpur: 'Maharashtra',
	'Ābu Road': 'Rajasthan',
	Ābu: 'Rajasthan',
	Abohar: 'Punjab',
	Abhayāpuri: 'Assam',
	Contai: 'West Bengal',
	Haldia: 'West Bengal',
	Srirāmpur: 'West Bengal',
	Dumjor: 'West Bengal',
	Bankra: 'West Bengal',
	Chakapara: 'West Bengal',
	Mahiari: 'West Bengal',
	Dhulagari: 'West Bengal',
	Pānchla: 'West Bengal',
	Pujali: 'West Bengal',
	Maheshtala: 'West Bengal',
	Monoharpur: 'West Bengal',
	Soyībug: 'Jammu and Kashmir',
	Singāpur: 'Telangana',
	Ghatkesar: 'Telangana',
	Vadigenhalli: 'Karnataka',
	Adampur: 'Punjab',
	Porur: 'Tamil Nadu',
	Madipakkam: 'Tamil Nadu',
	Perungudi: 'Tamil Nadu',
	Madambakkam: 'Tamil Nadu',
	Powai: 'Maharashtra',
	'Navi Mumbai': 'Maharashtra',
	Panchkula: 'Haryana',
	Murudeshwara: 'Karnataka',
	'Shivaji Nagar': 'Maharashtra',
	'Greater Noida': 'Uttar Pradesh',
	Mohali: 'Punjab',
	Pithampur: 'Madhya Pradesh',
	Barbil: 'Odisha',
	Airoli: 'Maharashtra',
	Aluva: 'Kerala',
	Kotkapura: 'Punjab',
	Muvattupuzha: 'Kerala',
	Perumbavoor: 'Kerala',
	Vapi: 'Gujarat',
	Baddi: 'Himachal Pradesh',
	Noida: 'Uttar Pradesh',
	Bhiwadi: 'Rajasthan',
	Mandideep: 'Madhya Pradesh',
	Singrauli: 'Madhya Pradesh',
	Birpara: 'West Bengal',
	Jaigaon: 'West Bengal',
	Akkarampalle: 'Andhra Pradesh',
	Bellampalli: 'Telangana',
	Chemmumiahpet: 'Andhra Pradesh',
	'Gaddi Annaram': 'Telangana',
	Dasnapur: 'Telangana',
	Kanuru: 'Andhra Pradesh',
	'Lal Bahadur Nagar': 'Telangana',
	Malkajgiri: 'Telangana',
	Mandamarri: 'Telangana',
	Chinnachowk: 'Andhra Pradesh',
	Kyathampalle: 'Telangana',
	Gajuwaka: 'Andhra Pradesh',
	Manuguru: 'Telangana',
	Kalyandurg: 'Andhra Pradesh',
	Ponnur: 'Andhra Pradesh',
	Quthbullapur: 'Telangana',
	Ramanayyapeta: 'Andhra Pradesh',
	Palwancha: 'Telangana',
	'Barpeta Road': 'Assam',
	Sathupalli: 'Telangana',
	Yanamalakuduru: 'Andhra Pradesh',
	Morigaon: 'Assam',
	Naharlagun: 'Arunachal Pradesh',
	Serilingampalle: 'Telangana',
	Silapathar: 'Assam',
	'Lumding Railway Colony': 'Assam',
	Aistala: 'West Bengal',
	'Ashoknagar Kalyangarh': 'West Bengal',
	Bahula: 'West Bengal',
	Bhawanipur: 'Bihar',
	Zira: 'Punjab',
	Kumarapalayam: 'Tamil Nadu',
	Ramagundam: 'Telangana',
	Cherpulassery: 'Kerala',
	Kirandul: 'Chhattisgarh',
	Thoothukudi: 'Tamil Nadu',
	Shiraguppi: 'Maharashtra',
	'Gadag-Betageri': 'Karnataka',
	'V.S.K.Valasai (Dindigul-Dist.)': 'Tamil Nadu',
	Neelankarai: 'Tamil Nadu',
	Injambakkam: 'Tamil Nadu',
	Shahbazpur: 'Bihar',
	Kumbalam: 'Kerala',
	Aroor: 'Kerala',
	Kadakkavoor: 'Kerala',
	Kalavoor: 'Kerala',
	Kalamassery: 'Kerala',
	Cherthala: 'Kerala',
	Azhiyūr: 'Kerala',
	Abrama: 'Gujarat',
	Phusro: 'Jharkhand',
	Alangad: 'Kerala',
	Savarkundla: 'Gujarat',
	Malkapur: 'Maharashtra',
	Podaturpet: 'Tamil Nadu',
	Rohini: 'Delhi',
	Mulugu: 'Telangana',
	Kallakurichi: 'Tamil Nadu',
	Amaravati: 'Andhra Pradesh',
	Chirmiri: 'Chhattisgarh',
	'Paramathi Velur': 'Tamil Nadu',
	Kanayannur: 'Kerala',
	Shahuwadi: 'Maharashtra',
	Lalganj: 'Uttar Pradesh',
	Zaxo: 'Duhok',
	'Umm Qaşr': 'Basra',
	'Ţūz Khūrmātū': 'Salah ad Din',
	Tikrīt: 'Salah ad Din',
	Tallkayf: 'Nineveh',
	Sīnah: 'Duhok',
	'Sāmarrā’': 'Salah ad Din',
	'Nāḩīyat Saddat al Hindīyah': 'Bābil',
	Ṟuwandiz: 'Arbīl',
	'Al-Hamdaniya': 'Nineveh',
	Mandalī: 'Diyālá',
	Koysinceq: 'Arbīl',
	Kifrī: 'Diyālá',
	Kirkuk: 'Kirkuk',
	Karbala: 'Karbalāʼ',
	Erbil: 'Arbīl',
	Hīt: 'Al Anbar',
	Ḩalabja: 'Sulaymaniyah',
	Ḩadīthah: 'Al Anbar',
	Soran: 'Karaganda',
	Dihok: 'Duhok',
	Jamjamāl: 'Sulaymaniyah',
	Bayjī: 'Salah ad Din',
	Batifa: 'Duhok',
	Baqubah: 'Diyālá',
	Baynjiwayn: 'Sulaymaniyah',
	Balad: 'Salah ad Din',
	Baghdad: 'Baghdad',
	'Az Zubayr': 'Basra',
	'Aş Şuwayrah': 'Wāsiţ',
	'As Sulaymānīyah': 'Sulaymaniyah',
	'As Samawah': 'Al Muthanná',
	'Nāḩiyat ash Shināfīyah': 'Al Qādisīyah',
	'Ash Shaţrah': 'Dhi Qar',
	'Ash Shāmīyah': 'Al Qādisīyah',
	'Ar Ruţbah': 'Al Anbar',
	'Ar Rumaythah': 'Al Muthanná',
	Ramadi: 'Al Anbar',
	'‘Aqrah': 'Nineveh',
	'An Nu‘mānīyah': 'Wāsiţ',
	Nasiriyah: 'Dhi Qar',
	Najaf: 'An Najaf',
	'‘Anat al Qadīmah': 'Al Anbar',
	'Imam Qasim': 'Bābil',
	'Al Musayyib': 'Bābil',
	'Al Mishkhāb': 'An Najaf',
	'Al Miqdādīyah': 'Diyālá',
	'Al Mawşil al Jadīdah': 'Nineveh',
	Mosul: 'Nineveh',
	'Al Kūt': 'Wāsiţ',
	Kufa: 'An Najaf',
	Khāliş: 'Diyālá',
	'‘Alī al Gharbī': 'Maysan',
	'Al Hindīyah': 'Karbalāʼ',
	'Al Ḩillah': 'Bābil',
	'Al Ḩayy': 'Wāsiţ',
	'Al Hārithah': 'Basra',
	'Nahiyat Ghammas': 'Al Qādisīyah',
	'Nāḩiyat al Fuhūd': 'Dhi Qar',
	'Al Fāw': 'Basra',
	'Al Fallūjah': 'Al Anbar',
	Basrah: 'Basra',
	'Al ‘Azīzīyah': 'Wāsiţ',
	'Al ‘Amārah': 'Maysan',
	'‘Afak': 'Al Qādisīyah',
	'Ad Dujayl': 'Salah ad Din',
	'Ad Dīwānīyah': 'Al Qādisīyah',
	'Abū Ghurayb': 'Baghdad',
	'Al Başrah al Qadīmah': 'Basra',
	Hajiawa: 'Sulaymaniyah',
	Sinjār: 'Nineveh',
	'‘Anah': 'Al Anbar',
	Alvand: 'Zanjan',
	Āzādshahr: 'Golestan',
	Kahrīz: 'Kermānshāh',
	Nūrābād: 'Fars',
	'Īstgāh-e Rāh Āhan-e Garmsār': 'Semnan',
	Qarchak: 'Tehran',
	'Shahre Jadide Andisheh': 'Tehran',
	Khorramdarreh: 'Zanjan',
	'Pādegān-e Manjīl': 'Gīlān',
	Yasuj: 'Kohgiluyeh and Boyer-Ahmad',
	Zarand: 'Kerman',
	Zanjān: 'Zanjan',
	Yazd: 'Yazd',
	Varāmīn: 'Tehran',
	'Torbat-e Ḩeydarīyeh': 'Razavi Khorasan',
	Tonekābon: 'Māzandarān',
	Tehran: 'Tehran',
	Hashtpar: 'Gīlān',
	Tākestān: 'Qazvīn',
	Takāb: 'West Azerbaijan',
	Taft: 'Yazd',
	Tabriz: 'East Azerbaijan',
	Tabas: 'Yazd',
	Surian: 'Fars',
	Sonqor: 'Kermānshāh',
	Sirjan: 'Kerman',
	Shūshtar: 'Khuzestan',
	Shūsh: 'Khuzestan',
	Shīrvān: 'North Khorasan',
	Shiraz: 'Fars',
	'Shahr-e Kord': 'Chaharmahal and Bakhtiari',
	'Shahr-e Bābak': 'Kerman',
	Shādegān: 'Khuzestan',
	Semnan: 'Semnan',
	Semīrom: 'Isfahan',
	Sāveh: 'Markazi',
	Sari: 'Māzandarān',
	Saqqez: 'Kordestān',
	Sanandaj: 'Kordestān',
	Salmās: 'West Azerbaijan',
	'Shāhīn Dezh': 'West Azerbaijan',
	Sabzevar: 'Razavi Khorasan',
	Rūdsar: 'Gīlān',
	'Robāţ Karīm': 'Tehran',
	Rāvar: 'Kerman',
	Rasht: 'Gīlān',
	Rāmshīr: 'Khuzestan',
	Rāmhormoz: 'Khuzestan',
	Rafsanjān: 'Kerman',
	Qūchān: 'Razavi Khorasan',
	Qorveh: 'Kordestān',
	Qom: 'Qom',
	Qeshm: 'Hormozgan',
	Qazvin: 'Qazvīn',
	'Qarah Ẕīā’ od Dīn': 'West Azerbaijan',
	'Farrokh Shahr': 'Chaharmahal and Bakhtiari',
	'Qā’en': 'South Khorasan Province',
	'Sarpol-e Z̄ahāb': 'Kermānshāh',
	Pīshvā: 'Tehran',
	Piranshahr: 'West Azerbaijan',
	Pāveh: 'Kermānshāh',
	Pārsābād: 'Ardabīl',
	Oshnavīyeh: 'West Azerbaijan',
	Orūmīyeh: 'West Azerbaijan',
	Omīdīyeh: 'Khuzestan',
	Nowshahr: 'Māzandarān',
	Neyshābūr: 'Razavi Khorasan',
	Neyrīz: 'Fars',
	Nekā: 'Māzandarān',
	Naz̧arābād: 'Alborz Province',
	Naqadeh: 'West Azerbaijan',
	Nahāvand: 'Hamadān',
	Mohr: 'Fars',
	Mīnāb: 'Hormozgan',
	Mīāndoāb: 'West Azerbaijan',
	Meybod: 'Yazd',
	Mahrīz: 'Yazd',
	'Masjed Soleymān': 'Khuzestan',
	Bardsīr: 'Kerman',
	Mashhad: 'Razavi Khorasan',
	Marvdasht: 'Fars',
	Marīvān: 'Kordestān',
	Marand: 'East Azerbaijan',
	Manjīl: 'Gīlān',
	Malāyer: 'Hamadān',
	Malārd: 'Tehran',
	Mahābād: 'West Azerbaijan',
	Langarūd: 'Gīlān',
	Kūhdasht: 'Lorestan Province',
	Kīsh: 'Hormozgan',
	Khowy: 'West Azerbaijan',
	Khvānsār: 'Isfahan',
	Khorramshahr: 'Khuzestan',
	Khorramabad: 'Lorestan Province',
	Khomeyn: 'Markazi',
	Khalkhāl: 'Ardabīl',
	Kermanshah: 'Kermānshāh',
	Kerman: 'Kerman',
	Kāzerūn: 'Fars',
	Kāshmar: 'Razavi Khorasan',
	Karaj: 'Alborz Province',
	Kangāvar: 'Kermānshāh',
	Kāmyārān: 'Kordestān',
	Kalāleh: 'Golestan',
	Jūybār: 'Māzandarān',
	Javānrūd: 'Kermānshāh',
	Īlām: 'Ilam Province',
	Harsīn: 'Kermānshāh',
	Hamadān: 'Hamadān',
	Ḩājjīābād: 'Hormozgan',
	Gorgān: 'Golestan',
	'Gonbad-e Kāvūs': 'Golestan',
	Gonābād: 'Razavi Khorasan',
	Golpāyegān: 'Isfahan',
	Gerāsh: 'Fars',
	'Bandar-e Genāveh': 'Bushehr',
	Fūman: 'Gīlān',
	Fīrūzābād: 'Fars',
	'Fereydūn Kenār': 'Māzandarān',
	Fasā: 'Fars',
	Fārsān: 'Chaharmahal and Bakhtiari',
	Esfarāyen: 'North Khorasan',
	Shahrud: 'Semnan',
	Dogonbadan: 'Kohgiluyeh and Boyer-Ahmad',
	Delījān: 'Markazi',
	Dehlorān: 'Ilam Province',
	Dehdasht: 'Kohgiluyeh and Boyer-Ahmad',
	Sūsangerd: 'Khuzestan',
	'Darreh Shahr': 'Ilam Province',
	Dārāb: 'Fars',
	Dāmghān: 'Semnan',
	Damāvand: 'Tehran',
	Chenārān: 'Razavi Khorasan',
	Chālūs: 'Māzandarān',
	Bushehr: 'Bushehr',
	Būkān: 'West Azerbaijan',
	Borūjerd: 'Lorestan Province',
	Borūjen: 'Chaharmahal and Bakhtiari',
	Borāzjān: 'Bushehr',
	Bojnūrd: 'North Khorasan',
	Bīrjand: 'South Khorasan Province',
	Bījār: 'Kordestān',
	Bonāb: 'East Azerbaijan',
	Behshahr: 'Māzandarān',
	Behbahān: 'Khuzestan',
	Bardaskan: 'Razavi Khorasan',
	Bāneh: 'Kordestān',
	'Bandar-e Lengeh': 'Hormozgan',
	'Bandar-e Anzalī': 'Gīlān',
	'Bandar Abbas': 'Hormozgan',
	Bam: 'Kerman',
	Bahār: 'Hamadān',
	Bāfq: 'Yazd',
	Bābolsar: 'Māzandarān',
	Bābol: 'Māzandarān',
	Aznā: 'Lorestan Province',
	Hashtrūd: 'East Azerbaijan',
	Āstārā: 'Gīlān',
	'Āstāneh-ye Ashrafīyeh': 'Gīlān',
	Ardestān: 'Isfahan',
	Ardakān: 'Yazd',
	Ardabīl: 'Ardabīl',
	Arāk: 'Markazi',
	Āmol: 'Māzandarān',
	Shahrīār: 'Tehran',
	Alīgūdarz: 'Lorestan Province',
	Aleshtar: 'Lorestan Province',
	Akbarābād: 'Fars',
	'Ajab Shīr': 'East Azerbaijan',
	Ahvaz: 'Khuzestan',
	Ahar: 'East Azerbaijan',
	Aghajari: 'Khuzestan',
	Ābyek: 'Markazi',
	Abhar: 'Zanjan',
	Ābdānān: 'Ilam Province',
	Abadeh: 'Fars',
	Abadan: 'Ahal',
	Pāsārgād: 'Fars',
	Eqbālīyeh: 'Tehran',
	Rehnān: 'Isfahan',
	Qahderījān: 'Isfahan',
	Najafābād: 'Isfahan',
	'Khomeynī Shahr': 'Isfahan',
	'Kelīshād va Sūdarjān': 'Isfahan',
	Falāvarjān: 'Isfahan',
	Isfahan: 'Isfahan',
	Dowlatābād: 'Isfahan',
	'Dorcheh Pīāz': 'Isfahan',
	Abrīsham: 'Isfahan',
	Zābol: 'Sistan and Baluchestan',
	Zahedan: 'Sistan and Baluchestan',
	'Torbat-e Jām': 'Razavi Khorasan',
	Tāybād: 'Razavi Khorasan',
	Sarakhs: 'Razavi Khorasan',
	'Qaşr-e Qand': 'Sistan and Baluchestan',
	Nīkshahr: 'Sistan and Baluchestan',
	Iranshahr: 'Sistan and Baluchestan',
	Chabahar: 'Sistan and Baluchestan',
	Mahdishahr: 'Semnan',
	Boshrūyeh: 'South Khorasan Province',
	'Pasragad Branch': 'Hamadān',
	Akureyri: 'Northeast',
	Reykjavík: 'Capital Region',
	Kópavogur: 'Capital Region',
	Keflavík: 'Southern Peninsula',
	Hafnarfjörður: 'Capital Region',
	Reykjanesbær: 'Southern Peninsula',
	Vittoria: 'Sicily',
	Villabate: 'Sicily',
	'Vibo Valentia': 'Calabria',
	Trapani: 'Sicily',
	'Termini Imerese': 'Sicily',
	Siracusa: 'Sicily',
	Sinnai: 'Sardinia',
	Siderno: 'Calabria',
	Sestu: 'Sardinia',
	Selargius: 'Sardinia',
	Scordia: 'Sicily',
	Scicli: 'Sicily',
	Sciacca: 'Sicily',
	'San Giovanni la Punta': 'Sicily',
	'San Giovanni in Fiore': 'Calabria',
	'San Cataldo': 'Sicily',
	Sambiase: 'Calabria',
	'Rossano Stazione': 'Calabria',
	Rosolini: 'Sicily',
	Ribera: 'Sicily',
	'Reggio Calabria': 'Calabria',
	Ragusa: 'Sicily',
	Quattromiglia: 'Calabria',
	"Quartu Sant'Elena": 'Sardinia',
	Pozzallo: 'Sicily',
	'Porto Empedocle': 'Sicily',
	'Piazza Armerina': 'Sicily',
	Paternò: 'Sicily',
	Partinico: 'Sicily',
	Palmi: 'Calabria',
	'Palma di Montechiaro': 'Sicily',
	Palermo: 'Sicily',
	Palagonia: 'Sicily',
	Pachino: 'Sicily',
	Oristano: 'Sardinia',
	Noto: 'Sicily',
	Niscemi: 'Sicily',
	Nicastro: 'Calabria',
	Monserrato: 'Sardinia',
	Monreale: 'Sicily',
	Modica: 'Sicily',
	Misterbianco: 'Sicily',
	Misilmeri: 'Sicily',
	Milazzo: 'Sicily',
	Messina: 'Sicily',
	'Mazara del Vallo': 'Sicily',
	Mascalucia: 'Sicily',
	Marsala: 'Sicily',
	Licata: 'Sicily',
	Lentini: 'Sicily',
	Iglesias: 'Sardinia',
	'Gioia Tauro': 'Calabria',
	Giarre: 'Sicily',
	Gela: 'Sicily',
	Floridia: 'Sicily',
	Favara: 'Sicily',
	Enna: 'Sicily',
	Crotone: 'Calabria',
	Cosenza: 'Calabria',
	Comiso: 'Sicily',
	Catanzaro: 'Calabria',
	Catania: 'Sicily',
	Castrovillari: 'Calabria',
	Castelvetrano: 'Sicily',
	Casarano: 'Apulia',
	Carini: 'Sicily',
	Carbonia: 'Sardinia',
	Canicattì: 'Sicily',
	Caltanissetta: 'Sicily',
	Caltagirone: 'Sicily',
	Cagliari: 'Sardinia',
	Bronte: 'Sicily',
	Biancavilla: 'Sicily',
	Belpasso: 'Sicily',
	'Barcellona Pozzo di Gotto': 'Sicily',
	Bagheria: 'Sicily',
	Avola: 'Sicily',
	Augusta: 'Maine',
	Assemini: 'Sardinia',
	Alcamo: 'Sicily',
	Agrigento: 'Sicily',
	Adrano: 'Sicily',
	Acireale: 'Sicily',
	'Aci Catena': 'Sicily',
	'Aci Castello': 'Sicily',
	'Zola Predosa': 'Emilia-Romagna',
	Vomero: 'Campania',
	Voghera: 'Lombardy',
	'Vittorio Veneto': 'Veneto',
	Viterbo: 'Lazio',
	Vimodrone: 'Lombardy',
	Vimercate: 'Lombardy',
	'Lancenigo-Villorba': 'Veneto',
	Villaricca: 'Campania',
	'Villafranca di Verona': 'Veneto',
	Vignola: 'Emilia-Romagna',
	Vigevano: 'Lombardy',
	Vicenza: 'Veneto',
	Viareggio: 'Tuscany',
	Viadana: 'Lombardy',
	Verona: 'Veneto',
	Vercelli: 'Piedmont',
	Ventimiglia: 'Liguria',
	Venice: 'California',
	'Venaria Reale': 'Piedmont',
	Velletri: 'Lazio',
	Vasto: 'Abruzzo',
	Varese: 'Lombardy',
	Valenzano: 'Apulia',
	Valenza: 'Piedmont',
	Valdagno: 'Veneto',
	Udine: 'Friuli Venezia Giulia',
	Triggiano: 'Apulia',
	Trieste: 'Friuli Venezia Giulia',
	'Trezzano sul Naviglio': 'Lombardy',
	Treviso: 'Veneto',
	Treviglio: 'Lombardy',
	'Trentola-Ducenta': 'Campania',
	Trento: 'Caraga',
	Trecate: 'Piedmont',
	Trani: 'Apulia',
	Tradate: 'Lombardy',
	Tortona: 'Piedmont',
	Torremaggiore: 'Apulia',
	'Torre del Greco': 'Campania',
	'Torre Annunziata': 'Campania',
	Turin: 'Piedmont',
	Tolentino: 'The Marches',
	Tivoli: 'Lazio',
	Thiene: 'Veneto',
	Terzigno: 'Campania',
	Terracina: 'Lazio',
	Terni: 'Umbria',
	Termoli: 'Molise',
	Terlizzi: 'Apulia',
	Teramo: 'Abruzzo',
	Taranto: 'Apulia',
	Suzzara: 'Lombardy',
	Sulmona: 'Abruzzo',
	Spoleto: 'Umbria',
	Sora: 'Lazio',
	Sondrio: 'Lombardy',
	'Somma Vesuviana': 'Campania',
	Soccavo: 'Campania',
	Siena: 'Tuscany',
	Sezze: 'Lazio',
	Seveso: 'Lombardy',
	'Settimo Torinese': 'Piedmont',
	'Sestri Levante': 'Liguria',
	'Sesto San Giovanni': 'Lombardy',
	'Sesto Fiorentino': 'Tuscany',
	Seriate: 'Lombardy',
	Seregno: 'Lombardy',
	Senigallia: 'The Marches',
	Senago: 'Lombardy',
	Segrate: 'Lombardy',
	Secondigliano: 'Campania',
	Schio: 'Veneto',
	Scandicci: 'Tuscany',
	Scandiano: 'Emilia-Romagna',
	Scafati: 'Campania',
	Savona: 'Liguria',
	Savigliano: 'Piedmont',
	Sava: 'Apulia',
	Sassuolo: 'Emilia-Romagna',
	Sassari: 'Sardinia',
	Sarzana: 'Liguria',
	Saronno: 'Lombardy',
	Sarno: 'Campania',
	'San Vito dei Normanni': 'Apulia',
	'Santeramo in Colle': 'Apulia',
	"Sant'Antonio Abate": 'Campania',
	"Sant'Antimo": 'Campania',
	"Sant'Anastasia": 'Campania',
	'Santa Maria Capua Vetere': 'Campania',
	'San Severo': 'Apulia',
	'San Sebastiano': 'Lombardy',
	'San Salvo': 'Abruzzo',
	'San Remo': 'Liguria',
	'Sannicandro Garganico': 'Apulia',
	'San Miniato': 'Tuscany',
	'San Miniato Basso': 'Tuscany',
	'San Mauro Torinese': 'Piedmont',
	'San Lazzaro': 'Emilia-Romagna',
	'San Giuseppe Vesuviano': 'Campania',
	'San Giuliano Milanese': 'Lombardy',
	'San Giovanni Valdarno': 'Tuscany',
	'San Giovanni Rotondo': 'Apulia',
	'San Giovanni Lupatoto': 'Veneto',
	'San Giovanni in Persiceto': 'Emilia-Romagna',
	'San Giovanni a Teduccio': 'Campania',
	'San Giorgio a Cremano': 'Campania',
	'San Donato Milanese': 'Lombardy',
	'San Donà di Piave': 'Veneto',
	'San Bonifacio': 'Veneto',
	'San Benedetto del Tronto': 'The Marches',
	Saluzzo: 'Piedmont',
	'Salsomaggiore Terme': 'Emilia-Romagna',
	Salerno: 'Campania',
	Sacile: 'Friuli Venezia Giulia',
	'Ruvo di Puglia': 'Apulia',
	Rutigliano: 'Apulia',
	Rozzano: 'Lombardy',
	Rovigo: 'Veneto',
	Rovereto: 'Trentino-Alto Adige',
	'Rosignano Solvay-Castiglioncello': 'Tuscany',
	'Roseto degli Abruzzi': 'Abruzzo',
	'Romano di Lombardia': 'Lombardy',
	Rome: 'New York',
	'Rocca di Papa': 'Lazio',
	Rivoli: 'Piedmont',
	'Riva del Garda': 'Trentino-Alto Adige',
	Rimini: 'Emilia-Romagna',
	Rieti: 'Lazio',
	Riccione: 'Emilia-Romagna',
	Rho: 'Lombardy',
	"Reggio nell'Emilia": 'Emilia-Romagna',
	Ravenna: 'Emilia-Romagna',
	Rapallo: 'Liguria',
	Qualiano: 'Campania',
	Putignano: 'Apulia',
	Prato: 'Tuscany',
	Pozzuoli: 'Campania',
	Potenza: 'Basilicate',
	Posillipo: 'Campania',
	'Porto Torres': 'Sardinia',
	"Porto Sant'Elpidio": 'The Marches',
	'Porto San Giorgio': 'The Marches',
	Portogruaro: 'Veneto',
	'Civitanova Marche': 'The Marches',
	Portici: 'Campania',
	Pordenone: 'Friuli Venezia Giulia',
	Ponticelli: 'Campania',
	Pontedera: 'Tuscany',
	Pompei: 'Campania',
	"Pomigliano d'Arco": 'Campania',
	Pomezia: 'Lazio',
	Poggioreale: 'Campania',
	Poggiomarino: 'Campania',
	Poggibonsi: 'Tuscany',
	Pistoia: 'Tuscany',
	Piscinola: 'Campania',
	Pisa: 'Tuscany',
	Piossasco: 'Piedmont',
	Piombino: 'Tuscany',
	Pioltello: 'Lombardy',
	Pinerolo: 'Piedmont',
	Pietrasanta: 'Tuscany',
	Pianura: 'Campania',
	Piacenza: 'Emilia-Romagna',
	Pescara: 'Abruzzo',
	Pesaro: 'The Marches',
	Perugia: 'Umbria',
	Pavia: 'Lombardy',
	Parma: 'Ohio',
	Parabiago: 'Lombardy',
	'Palo del Colle': 'Apulia',
	"Palazzolo sull'Oglio": 'Lombardy',
	Palagiano: 'Apulia',
	Pagani: 'Campania',
	Padova: 'Veneto',
	'Paderno Dugnano': 'Lombardy',
	Ottaviano: 'Campania',
	Ostuni: 'Apulia',
	Osimo: 'The Marches',
	'Orta Nova': 'Apulia',
	Orbassano: 'Piedmont',
	Olbia: 'Sardinia',
	Oderzo: 'Veneto',
	Nuoro: 'Sardinia',
	'Novi Ligure': 'Piedmont',
	'Novate Milanese': 'Lombardy',
	Novara: 'Piedmont',
	'Nova Milanese': 'Lombardy',
	Noicattaro: 'Apulia',
	Noci: 'Apulia',
	'Nocera Superiore': 'Campania',
	'Nocera Inferiore': 'Campania',
	Nichelino: 'Piedmont',
	Nettuno: 'Lazio',
	Nerviano: 'Lombardy',
	Nardò: 'Apulia',
	Naples: 'Florida',
	'Mugnano di Napoli': 'Campania',
	Muggiò: 'Lombardy',
	Monza: 'Lombardy',
	Montichiari: 'Lombardy',
	Montevarchi: 'Tuscany',
	'Montesilvano Marina': 'Abruzzo',
	Monterotondo: 'Lazio',
	Montemurlo: 'Tuscany',
	'Montecchio Maggiore-Alte Ceccato': 'Veneto',
	'Montecatini-Terme': 'Tuscany',
	Montebelluna: 'Veneto',
	Monselice: 'Veneto',
	Monopoli: 'Apulia',
	Monfalcone: 'Friuli Venezia Giulia',
	Mondragone: 'Campania',
	Mondovì: 'Piedmont',
	Moncalieri: 'Piedmont',
	Molfetta: 'Apulia',
	'Mola di Bari': 'Apulia',
	'Mogliano Veneto': 'Veneto',
	Modugno: 'Apulia',
	Modena: 'Emilia-Romagna',
	Mirano: 'Veneto',
	Mirandola: 'Emilia-Romagna',
	'Mira Taglio': 'Veneto',
	Minturno: 'Lazio',
	Milan: 'Lombardy',
	Miano: 'Campania',
	Mestre: 'Veneto',
	Mesagne: 'Apulia',
	Merano: 'Trentino-Alto Adige',
	Mentana: 'Lazio',
	Melzo: 'Lombardy',
	'Melito di Napoli': 'Campania',
	Melegnano: 'Lombardy',
	Meda: 'Lombardy',
	Matera: 'Basilicate',
	Massarosa: 'Tuscany',
	Massafra: 'Apulia',
	Massa: 'Tuscany',
	'Martina Franca': 'Apulia',
	Marino: 'Lazio',
	'Marina di Massa': 'Tuscany',
	'Marina di Carrara': 'Tuscany',
	Marigliano: 'Campania',
	'Mariano Comense': 'Lombardy',
	Marcianise: 'Campania',
	'Marano di Napoli': 'Campania',
	Mantova: 'Lombardy',
	Manfredonia: 'Apulia',
	Manduria: 'Apulia',
	Malnate: 'Lombardy',
	Magenta: 'Lombardy',
	Maddaloni: 'Campania',
	Macerata: 'The Marches',
	Lucera: 'Apulia',
	Lucca: 'Tuscany',
	Lodi: 'California',
	Livorno: 'Tuscany',
	Lissone: 'Lombardy',
	Limbiate: 'Lombardy',
	'Lido di Ostia': 'Lazio',
	Lido: 'Veneto',
	Legnano: 'Lombardy',
	Legnago: 'Veneto',
	Lecco: 'Lombardy',
	Lecce: 'Apulia',
	'La Spezia': 'Liguria',
	"L'Aquila": 'Abruzzo',
	Lanciano: 'Abruzzo',
	Lainate: 'Lombardy',
	Ladispoli: 'Lazio',
	Ivrea: 'Piedmont',
	Isernia: 'Molise',
	'Ischia Porto': 'Campania',
	Ischia: 'Campania',
	'Pallanza-Intra-Suna': 'Piedmont',
	Imperia: 'Liguria',
	Imola: 'Emilia-Romagna',
	Jesi: 'The Marches',
	Guidonia: 'Lazio',
	'Grumo Nevano': 'Campania',
	Grugliasco: 'Piedmont',
	Grottaglie: 'Apulia',
	Grottaferrata: 'Lazio',
	Grosseto: 'Tuscany',
	'Gravina in Puglia': 'Apulia',
	Gragnano: 'Campania',
	Gorizia: 'Friuli Venezia Giulia',
	Gorgonzola: 'Lombardy',
	Giussano: 'Lombardy',
	Giulianova: 'Abruzzo',
	'Giugliano in Campania': 'Campania',
	Giovinazzo: 'Apulia',
	'Gioia del Colle': 'Apulia',
	Ginosa: 'Apulia',
	Ghedi: 'Lombardy',
	'Genzano di Roma': 'Lazio',
	Genoa: 'Liguria',
	'Garbagnate Milanese': 'Lombardy',
	Gallipoli: 'Apulia',
	Galliate: 'Piedmont',
	Gallarate: 'Lombardy',
	Galatina: 'Apulia',
	Gaeta: 'Lazio',
	Fuorigrotta: 'Campania',
	Frosinone: 'Lazio',
	Frattaminore: 'Campania',
	Frattamaggiore: 'Campania',
	Frascati: 'Lazio',
	'Francavilla Fontana': 'Apulia',
	'Francavilla al Mare': 'Abruzzo',
	Fossano: 'Piedmont',
	Fornacelle: 'Tuscany',
	Formigine: 'Emilia-Romagna',
	Formia: 'Lazio',
	Forlì: 'Emilia-Romagna',
	Forio: 'Campania',
	Fondi: 'Lazio',
	Follonica: 'Tuscany',
	Foligno: 'Umbria',
	Foggia: 'Apulia',
	Fiumicino: 'Lazio',
	Florence: 'Arizona',
	Fiorano: 'Emilia-Romagna',
	Fidenza: 'Emilia-Romagna',
	Ferrara: 'Emilia-Romagna',
	Fermo: 'The Marches',
	Fasano: 'Apulia',
	Fano: 'The Marches',
	'Falconara Marittima': 'The Marches',
	Faenza: 'Emilia-Romagna',
	Fabriano: 'The Marches',
	Este: 'Veneto',
	Ercolano: 'Campania',
	Erba: 'Lombardy',
	Empoli: 'Tuscany',
	Eboli: 'Campania',
	Domodossola: 'Piedmont',
	Desio: 'Lombardy',
	'Desenzano del Garda': 'Lombardy',
	Dalmine: 'Lombardy',
	Cusano: 'Lombardy',
	Cuneo: 'Piedmont',
	Cremona: 'Lombardy',
	Crema: 'Lombardy',
	Corsico: 'Lombardy',
	Correggio: 'Emilia-Romagna',
	Cornaredo: 'Lombardy',
	Cormano: 'Lombardy',
	Cordenons: 'Friuli Venezia Giulia',
	Corato: 'Apulia',
	Copertino: 'Apulia',
	Conversano: 'Apulia',
	Conegliano: 'Veneto',
	Como: 'Lombardy',
	'Cologno Monzese': 'Lombardy',
	Collegno: 'Piedmont',
	Colleferro: 'Lazio',
	"Colle di Val d'Elsa": 'Tuscany',
	Civitavecchia: 'Lazio',
	'Città di Castello': 'Umbria',
	'Cisterna di Latina': 'Lazio',
	Ciriè: 'Piedmont',
	'Cinisello Balsamo': 'Lombardy',
	Ciampino: 'Lazio',
	Chivasso: 'Piedmont',
	Chioggia: 'Veneto',
	Chieti: 'Abruzzo',
	Chieri: 'Piedmont',
	Chiavari: 'Liguria',
	Chiari: 'Lombardy',
	Cesenatico: 'Emilia-Romagna',
	Cesena: 'Emilia-Romagna',
	'Cesano Maderno': 'Lombardy',
	Cervia: 'Emilia-Romagna',
	Cerveteri: 'Lazio',
	'Cernusco sul Naviglio': 'Lombardy',
	Cerignola: 'Apulia',
	Cercola: 'Campania',
	Cento: 'Emilia-Romagna',
	'Ceglie Messapica': 'Apulia',
	Cecina: 'Tuscany',
	'Cava Dè Tirreni': 'Campania',
	Cattolica: 'Emilia-Romagna',
	'Castiglione delle Stiviere': 'Lombardy',
	'Castel Volturno': 'Campania',
	'Castel Maggiore': 'Emilia-Romagna',
	'Castellammare di Stabia': 'Campania',
	'Castelfranco Veneto': 'Veneto',
	'Castelfranco Emilia': 'Emilia-Romagna',
	Cassino: 'Lazio',
	"Cassano d'Adda": 'Lombardy',
	Casoria: 'Campania',
	Caserta: 'Campania',
	Cascina: 'Tuscany',
	Casamassima: 'Apulia',
	'Casalnuovo di Napoli': 'Campania',
	'Casale Monferrato': 'Piedmont',
	'Casalecchio di Reno': 'Emilia-Romagna',
	'Casal di Principe': 'Campania',
	Carugate: 'Lombardy',
	Carrara: 'Tuscany',
	'Carpi Centro': 'Emilia-Romagna',
	Carmagnola: 'Piedmont',
	Cardito: 'Campania',
	'Carate Brianza': 'Lombardy',
	Capua: 'Campania',
	Capannori: 'Tuscany',
	Cantù: 'Lombardy',
	'Canosa di Puglia': 'Apulia',
	Campobasso: 'Molise',
	'Campi Bisenzio': 'Tuscany',
	Camaiore: 'Tuscany',
	Caivano: 'Campania',
	'Busto Arsizio': 'Lombardy',
	Bussolengo: 'Veneto',
	Brusciano: 'Campania',
	Brugherio: 'Lombardy',
	Brindisi: 'Apulia',
	Bresso: 'Lombardy',
	Bressanone: 'Trentino-Alto Adige',
	Brescia: 'Lombardy',
	Bra: 'Piedmont',
	'Bovisio-Masciago': 'Lombardy',
	Boscoreale: 'Campania',
	Borgomanero: 'Piedmont',
	Bolzano: 'Trentino-Alto Adige',
	Bologna: 'Emilia-Romagna',
	Bollate: 'Lombardy',
	Bitonto: 'Apulia',
	Bisceglie: 'Apulia',
	Biella: 'Piedmont',
	Bergamo: 'Lombardy',
	Benevento: 'Campania',
	Belluno: 'Veneto',
	Battipaglia: 'Campania',
	'Bastia umbra': 'Umbria',
	'Bassano del Grappa': 'Veneto',
	Barletta: 'Apulia',
	Bari: 'Apulia',
	Bareggio: 'Lombardy',
	Bagnoli: 'Campania',
	Bacoli: 'Campania',
	Avezzano: 'Abruzzo',
	Aversa: 'Campania',
	Avellino: 'Campania',
	Asti: 'Piedmont',
	'Ascoli Piceno': 'The Marches',
	Arzignano: 'Veneto',
	Arzano: 'Campania',
	Ariccia: 'Lazio',
	Arezzo: 'Tuscany',
	Arese: 'Lombardy',
	Ardea: 'Lazio',
	Arcore: 'Lombardy',
	Aprilia: 'Lazio',
	Aosta: 'Aosta Valley',
	Anzio: 'Lazio',
	Angri: 'Campania',
	Andria: 'Apulia',
	Ancona: 'The Marches',
	Altamura: 'Apulia',
	Alpignano: 'Piedmont',
	Alghero: 'Sardinia',
	Alessandria: 'Piedmont',
	Albignasego: 'Veneto',
	Albenga: 'Liguria',
	'Albano Laziale': 'Lazio',
	Alba: 'Piedmont',
	Agropoli: 'Campania',
	Afragola: 'Campania',
	Adelfia: 'Apulia',
	'Acqui Terme': 'Piedmont',
	'Acquaviva delle Fonti': 'Apulia',
	'Acilia-Castel Fusano-Ostia Antica': 'Lazio',
	Acerra: 'Campania',
	Abbiategrasso: 'Lombardy',
	'Abano Terme': 'Veneto',
	'Spinea-Orgnano': 'Veneto',
	Verbania: 'Piedmont',
	Lumezzane: 'Lombardy',
	'Guidonia Montecelio': 'Lazio',
	'Lamezia Terme': 'Calabria',
	'Caronno Pertusella': 'Lombardy',
	'Cassano Magnago': 'Lombardy',
	'San Felice A Cancello': 'Campania',
	'San Nicola la Strada': 'Campania',
	Quarto: 'Campania',
	'Orta di Atella': 'Campania',
	Casavatore: 'Campania',
	Volla: 'Campania',
	'Gravina di Catania': 'Sicily',
	'Cesano Boscone': 'Lombardy',
	'Tor Lupara': 'Lazio',
	Torvaianica: 'Lazio',
	'Casal Palocco': 'Lazio',
	'Bellaria-Igea Marina': 'Emilia-Romagna',
	Villanova: 'Lazio',
	'Marina di Ardea-Tor San Lorenzo': 'Lazio',
	'San Paolo': 'Apulia',
	Monterusciello: 'Campania',
	'Romano Banco': 'Lombardy',
	'Casa Santa': 'Sicily',
	Arpino: 'Campania',
	'Paolo VI': 'Apulia',
	'Corigliano Scalo': 'Calabria',
	Stella: 'Campania',
	"San Carlo All'Arena": 'Campania',
	'bishopric of Perugia': 'Umbria',
	Chiaia: 'Campania',
	Chiaiano: 'Campania',
	Vicaria: 'Campania',
	'San Ferdinando': 'Campania',
	Pendino: 'Campania',
	Arenella: 'Campania',
	Avvocata: 'Campania',
	Montecalvario: 'Campania',
	Scampia: 'Campania',
	'San Pietro a Patierno': 'Campania',
	'Monte Rosello': 'Sardinia',
	Arcella: 'Veneto',
	"Casal de' Pazzi": 'Lazio',
	'Giardinetti-Tor Vergata': 'Lazio',
	'Casal Bertone': 'Lazio',
	'Saint Helier': 'St Helier',
	'Spanish Town': 'Saint Catherine',
	'Savanna-la-Mar': 'Westmoreland',
	Portmore: 'Saint Catherine',
	'Old Harbour': 'Saint Catherine',
	'New Kingston': 'St. Andrew',
	'Montego Bay': 'St. James',
	'May Pen': 'Clarendon',
	Mandeville: 'Manchester',
	Linstead: 'Saint Catherine',
	'Half Way Tree': 'St. Andrew',
	'Wādī as Sīr': 'Amman',
	'Umm as Summāq': 'Amman',
	Saḩāb: 'Amman',
	Mādabā: 'Madaba',
	"Ma'an": 'Ma’an',
	Kurayyimah: 'Irbid',
	Judita: 'Irbid',
	Jarash: 'Jerash',
	'‘Izrā': 'Karak',
	Irbid: 'Irbid',
	Zarqa: 'Zarqa',
	Aydūn: 'Irbid',
	'Aţ Ţafīlah': 'Tafielah',
	'As Salţ': 'Balqa',
	Safi: 'Marrakesh-Safi',
	'Ar Ramthā': 'Irbid',
	'‘Anjarah': 'Ajlun',
	Amman: 'Amman',
	'Ḩayy al Quwaysimah': 'Amman',
	Mafraq: 'Mafraq',
	'Qīr Moāv': 'Ma’an',
	'Al Jubayhah': 'Amman',
	Aqaba: 'Aqaba',
	'‘Ajlūn': 'Ajlun',
	'Karak City': 'Karak',
	Russeifa: 'Zarqa',
	Rukban: 'Mafraq',
	Shingū: 'Fukuoka',
	Atsugi: 'Kanagawa',
	Akashi: 'Hyōgo',
	Zushi: 'Kanagawa',
	Zama: 'Kanagawa',
	Gero: 'Gifu',
	Yukuhashi: 'Fukuoka',
	Yūki: 'Ibaraki',
	Yugawara: 'Kanagawa',
	Yoshikawa: 'Saitama',
	Yoshii: 'Gunma',
	'Yoshida-kasugachō': 'Niigata',
	Yorii: 'Saitama',
	Yono: 'Saitama',
	Yonago: 'Tottori',
	Yonabaru: 'Okinawa',
	Yokosuka: 'Kanagawa',
	Yokohama: 'Kanagawa',
	Yokkaichi: 'Mie',
	Youkaichi: 'Shiga',
	Yawata: 'Kyoto',
	Honmachi: 'Kumamoto',
	'Yatsuomachi-higashikumisaka': 'Toyama',
	Yasugichō: 'Shimane',
	Yashiro: 'Hyōgo',
	Yashio: 'Saitama',
	Yao: 'Ōsaka',
	Yanai: 'Yamaguchi',
	Yanagawa: 'Fukuoka',
	'Yamazakichō-nakabirose': 'Hyōgo',
	Yamaguchi: 'Yamaguchi',
	Yamaga: 'Kumamoto',
	Yaizu: 'Shizuoka',
	Yaita: 'Tochigi',
	Yabu: 'Hyōgo',
	Wakimachi: 'Tokushima',
	Utsunomiya: 'Tochigi',
	Uto: 'Kumamoto',
	Utazu: 'Kagawa',
	Usuki: 'Oita',
	Ushibukamachi: 'Kumamoto',
	'Ureshinomachi-shimojuku': 'Saga',
	Urayasu: 'Tokyo',
	Uozu: 'Toyama',
	Umi: 'Fukuoka',
	Ujiie: 'Tochigi',
	Uji: 'Kyoto',
	Uenohara: 'Yamanashi',
	'Ueno-ebisumachi': 'Mie',
	'Uekimachi-mōno': 'Kumamoto',
	Ueda: 'Nagano',
	Ube: 'Yamaguchi',
	Tsuyama: 'Okayama',
	Tsushima: 'Aichi',
	Tsurusaki: 'Oita',
	Tsuruoka: 'Yamagata',
	'Tsurugi-asahimachi': 'Ishikawa',
	Tsuruga: 'Fukui',
	Tsuma: 'Miyazaki',
	Tsukumiura: 'Oita',
	Tsukawaki: 'Oita',
	Tsubata: 'Ishikawa',
	Tsubame: 'Niigata',
	Tsu: 'Mie',
	Toyota: 'Aichi',
	Toyoshina: 'Nagano',
	Toyooka: 'Hyōgo',
	Toyonaka: 'Ōsaka',
	Toyokawa: 'Aichi',
	Toyohashi: 'Aichi',
	Toyohama: 'Aichi',
	Toyama: 'Toyama',
	Tottori: 'Tottori',
	Tosu: 'Saga',
	Tondabayashichō: 'Ōsaka',
	Tomioka: 'Gunma',
	Tomigusuku: 'Okinawa',
	Tokyo: 'Tokyo',
	Tokuyama: 'Yamaguchi',
	Tokushima: 'Tokushima',
	Tokorozawa: 'Saitama',
	Tokoname: 'Aichi',
	Toki: 'Gifu',
	Tōkamachi: 'Niigata',
	Togitsu: 'Nagasaki',
	'Tochio-honchō': 'Niigata',
	Tochigi: 'Tochigi',
	Toba: 'Mie',
	Tenri: 'Nara',
	Tennō: 'Akita',
	Tawaramoto: 'Nara',
	Tatsuno: 'Nagano',
	'Tatsunochō-tominaga': 'Hyōgo',
	Tateyama: 'Chiba',
	Tatebayashi: 'Gunma',
	Tarumizu: 'Kagoshima',
	Tarui: 'Gifu',
	'Tanushimarumachi-toyoki': 'Fukuoka',
	Tanuma: 'Tochigi',
	'Nishi-Tokyo-shi': 'Tokyo',
	Tanashichō: 'Tokyo',
	Tanabe: 'Wakayama',
	Tamano: 'Okayama',
	Tamana: 'Kumamoto',
	Tamamura: 'Gunma',
	Taketoyo: 'Aichi',
	Takeo: 'Takeo',
	Takehara: 'Hiroshima',
	Takefu: 'Fukui',
	Takedamachi: 'Oita',
	Takayama: 'Gifu',
	Takatsuki: 'Ōsaka',
	Takasaki: 'Gunma',
	Takarazuka: 'Hyōgo',
	Takaoka: 'Toyama',
	Takanabe: 'Miyazaki',
	Takamatsu: 'Kagawa',
	Takaishi: 'Ōsaka',
	Takahashi: 'Okayama',
	Takahama: 'Aichi',
	'Bungo-Takada-shi': 'Oita',
	Tajimi: 'Gifu',
	Tahara: 'Aichi',
	Tagawa: 'Fukuoka',
	Tadotsu: 'Kagawa',
	Tachiarai: 'Fukuoka',
	Suzuka: 'Mie',
	Suzaka: 'Nagano',
	Suwa: 'Nagano',
	Susaki: 'Kochi',
	Sumoto: 'Hyōgo',
	Sukumo: 'Kochi',
	Suita: 'Ōsaka',
	Suibara: 'Niigata',
	Sugito: 'Saitama',
	'Sueyoshichō-ninokata': 'Kagoshima',
	Sōka: 'Saitama',
	Sōja: 'Okayama',
	Sobue: 'Aichi',
	Shōbu: 'Saitama',
	Shōbara: 'Hiroshima',
	Shizuoka: 'Shizuoka',
	Shirone: 'Niigata',
	Shiraoka: 'Saitama',
	'Shirahamachō-usazakiminami': 'Hyōgo',
	Shirahama: 'Wakayama',
	Shiozawa: 'Niigata',
	Shiojiri: 'Nagano',
	Shinshiro: 'Aichi',
	'Shin’ichi': 'Hiroshima',
	Shimonoseki: 'Yamaguchi',
	Shimodate: 'Ibaraki',
	Shimoda: 'Shizuoka',
	Minato: 'Wakayama',
	Nishishinminato: 'Toyama',
	Shimada: 'Shizuoka',
	Shimabara: 'Nagasaki',
	Shiki: 'Saitama',
	Shido: 'Kagawa',
	Shibushi: 'Kagoshima',
	Shibukawa: 'Gunma',
	Shibata: 'Niigata',
	Seto: 'Aichi',
	'Setakamachi-takayanagi': 'Fukuoka',
	Satsumasendai: 'Kagoshima',
	Satte: 'Saitama',
	Sasebo: 'Nagasaki',
	Sasayama: 'Hyōgo',
	Sasaguri: 'Fukuoka',
	Sano: 'Tochigi',
	Sanjō: 'Niigata',
	Sandachō: 'Hyōgo',
	Sakurai: 'Nara',
	Saku: 'Nagano',
	Sakata: 'Yamagata',
	Sakaki: 'Nagano',
	Sakaiminato: 'Tottori',
	Sakaidechō: 'Kagawa',
	'Sakai-nakajima': 'Gunma',
	Sakai: 'Fukui',
	Sakado: 'Saitama',
	Saiki: 'Oita',
	Sagara: 'Shizuoka',
	Saga: 'Saga',
	Sabae: 'Fukui',
	Ryūō: 'Yamanashi',
	'Ryōtsu-minato': 'Niigata',
	Ōzu: 'Ehime',
	Oyama: 'Tochigi',
	Ōyama: 'Shizuoka',
	Owase: 'Mie',
	Ōtsuki: 'Yamanashi',
	Ōtsu: 'Shiga',
	Ōtake: 'Hiroshima',
	Ōta: 'Gunma',
	Osaka: 'Ōsaka',
	Onomichi: 'Hiroshima',
	Onoda: 'Yamaguchi',
	Ono: 'Hyōgo',
	'Ōno-hara': 'Hiroshima',
	Ōmuta: 'Fukuoka',
	Ōmura: 'Nagasaki',
	Ōme: 'Tokyo',
	'Ōmamachō-ōmama': 'Gunma',
	Ōmachi: 'Nagano',
	'Ōkuchi-shinohara': 'Kagoshima',
	Okegawa: 'Saitama',
	Okazaki: 'Aichi',
	Okayama: 'Okayama',
	Okaya: 'Nagano',
	Ōkawa: 'Fukuoka',
	Ojiya: 'Niigata',
	Ōita: 'Oita',
	Ōiso: 'Kanagawa',
	Ōi: 'Saitama',
	'Ogōri-shimogō': 'Yamaguchi',
	Ogawa: 'Saitama',
	Ōgaki: 'Gifu',
	Odawara: 'Kanagawa',
	'Ōdachō-ōda': 'Shimane',
	Ōbu: 'Aichi',
	Obita: 'Nagasaki',
	Obama: 'Fukui',
	Nyūzen: 'Toyama',
	Numazu: 'Shizuoka',
	Numata: 'Gunma',
	Nonoichi: 'Ishikawa',
	Nōgata: 'Fukuoka',
	Noda: 'Chiba',
	Nobeoka: 'Miyazaki',
	Nishiwaki: 'Hyōgo',
	Nishio: 'Aichi',
	Nishinoomote: 'Kagoshima',
	'Nishinomiya-hama': 'Hyōgo',
	Nirasaki: 'Yamanashi',
	Ninomiya: 'Kanagawa',
	Nikkō: 'Tochigi',
	'Niitsu-honchō': 'Niigata',
	Niimi: 'Okayama',
	Niihama: 'Ehime',
	Niigata: 'Niigata',
	Nichinan: 'Miyazaki',
	Naze: 'Kagoshima',
	'Narutochō-mitsuishi': 'Tokushima',
	'Nara-shi': 'Nara',
	Nanao: 'Ishikawa',
	Namerikawa: 'Toyama',
	Nakatsugawa: 'Gifu',
	Nakatsu: 'Oita',
	Nakanojōmachi: 'Gunma',
	Nakano: 'Tokyo',
	Nakamura: 'Kochi',
	Nakama: 'Fukuoka',
	Naha: 'Okinawa',
	Nagoya: 'Aichi',
	Nago: 'Okinawa',
	Nagasu: 'Kumamoto',
	Nagasaki: 'Nagasaki',
	Nagareyama: 'Chiba',
	Nagaoka: 'Niigata',
	Nagano: 'Nagano',
	Nagahama: 'Shiga',
	Nabari: 'Mie',
	Musashino: 'Tokyo',
	'Muroto-misakicho': 'Kochi',
	Muramatsu: 'Niigata',
	Murakami: 'Niigata',
	Mukō: 'Kyoto',
	Muikamachi: 'Niigata',
	Morohongō: 'Saitama',
	Moriyama: 'Shiga',
	Moriguchi: 'Ōsaka',
	Mori: 'Shizuoka',
	Mizunami: 'Gifu',
	Miyoshi: 'Hiroshima',
	Miyazu: 'Kyoto',
	Miyazaki: 'Miyazaki',
	Miyakonojō: 'Miyazaki',
	Miyata: 'Fukuoka',
	Mitsuke: 'Niigata',
	Mitsukaidō: 'Ibaraki',
	Mitake: 'Gifu',
	Mishima: 'Shizuoka',
	Mino: 'Gifu',
	Minamirinkan: 'Kanagawa',
	Minamata: 'Kumamoto',
	'Minakuchichō-matoba': 'Shiga',
	Mikuni: 'Fukui',
	Miki: 'Hyōgo',
	Mihara: 'Hiroshima',
	Mifune: 'Kumamoto',
	Mibu: 'Tochigi',
	Menuma: 'Saitama',
	Matsutō: 'Ishikawa',
	Matsumoto: 'Nagano',
	Matsue: 'Shimane',
	Matsudo: 'Chiba',
	Matsubase: 'Kumamoto',
	Matsubara: 'Ōsaka',
	Masuda: 'Shimane',
	Maruoka: 'Fukui',
	Kamimaruko: 'Nagano',
	Marugame: 'Kagawa',
	Makurazaki: 'Kagoshima',
	Maki: 'Niigata',
	Maizuru: 'Kyoto',
	Maibara: 'Shiga',
	Maebashi: 'Gunma',
	'Maebaru-chūō': 'Fukuoka',
	Machida: 'Tokyo',
	Kyoto: 'Kyoto',
	Kushimoto: 'Wakayama',
	Kushikino: 'Kagoshima',
	Kusatsu: 'Shiga',
	Kurume: 'Fukuoka',
	Kuroda: 'Aichi',
	Kurihashi: 'Saitama',
	Kure: 'Hiroshima',
	Kurayoshi: 'Tottori',
	Kurashiki: 'Okayama',
	Kumamoto: 'Kumamoto',
	Kumagaya: 'Saitama',
	Kukichūō: 'Saitama',
	Kudamatsu: 'Yamaguchi',
	'Kozakai-chō': 'Aichi',
	Koshigaya: 'Saitama',
	Kōnosu: 'Saitama',
	Kōnan: 'Aichi',
	Komoro: 'Nagano',
	Komono: 'Mie',
	Komatsushimachō: 'Tokushima',
	Komatsu: 'Ishikawa',
	Komaki: 'Aichi',
	Kokubunji: 'Tokyo',
	'Kokubu-matsuki': 'Kagoshima',
	Koga: 'Fukuoka',
	Kōfu: 'Yamanashi',
	'Kodamachō-kodamaminami': 'Saitama',
	Kochi: 'Kochi',
	Kobe: 'Hyōgo',
	Kobayashi: 'Miyazaki',
	Kitsuki: 'Oita',
	Kitakyushu: 'Fukuoka',
	Kitakata: 'Fukushima',
	Kitahama: 'Shiga',
	Kitagata: 'Gifu',
	Kishiwada: 'Ōsaka',
	Kisarazu: 'Chiba',
	Kisai: 'Saitama',
	Kiryū: 'Gunma',
	Kikuchi: 'Kumamoto',
	Kazo: 'Saitama',
	Kawasaki: 'Fukuoka',
	Kawanishi: 'Hyōgo',
	Kawaminami: 'Miyazaki',
	Kawaguchi: 'Saitama',
	Kawagoe: 'Saitama',
	Katsuyama: 'Fukui',
	Kasukabe: 'Saitama',
	Kasugai: 'Aichi',
	Kashiwazaki: 'Niigata',
	Kashiwa: 'Chiba',
	Kashima: 'Saga',
	'Kashihara-shi': 'Nara',
	Kashihara: 'Ōsaka',
	'Kaseda-shirakame': 'Kagoshima',
	Kasaoka: 'Okayama',
	Kasamatsuchō: 'Gifu',
	Kariya: 'Hyōgo',
	Karatsu: 'Saga',
	'Kanzakimachi-kanzaki': 'Saga',
	Kanuma: 'Tochigi',
	Kanoya: 'Kagoshima',
	'Kan’onjichō': 'Kagawa',
	'Kannabechō-yahiro': 'Hiroshima',
	Kanie: 'Aichi',
	Kanekomachi: 'Gunma',
	Kanda: 'Fukuoka',
	Kanazawa: 'Ishikawa',
	Kanaya: 'Shizuoka',
	'Kamojimachō-jōgejima': 'Tokushima',
	'Kamogatachō-kamogata': 'Okayama',
	Kamo: 'Niigata',
	Kamirenjaku: 'Tokyo',
	Kaminokawa: 'Tochigi',
	Kamiichi: 'Toyama',
	Kamigōri: 'Hyōgo',
	Kameyama: 'Mie',
	Kameoka: 'Kyoto',
	'Kameda-honchō': 'Niigata',
	Kamakura: 'Kanagawa',
	'Kakogawachō-honmachi': 'Hyōgo',
	Kakegawa: 'Shizuoka',
	Kakamigahara: 'Gifu',
	'Kashima-shi': 'Ibaraki',
	Kajiki: 'Kagoshima',
	Kaizuka: 'Ōsaka',
	Kainan: 'Wakayama',
	Kagoshima: 'Kagoshima',
	Kadoma: 'Mashonaland West',
	Kadogawa: 'Miyazaki',
	Izumo: 'Shimane',
	Izumisano: 'Ōsaka',
	Izumiōtsu: 'Ōsaka',
	Izumi: 'Kagoshima',
	Iwatsuki: 'Saitama',
	Iwata: 'Shizuoka',
	Iwakura: 'Aichi',
	Iwakuni: 'Yamaguchi',
	Iwai: 'Ibaraki',
	Iwade: 'Wakayama',
	Itsukaichi: 'Tokyo',
	Itoman: 'Okinawa',
	Itoigawa: 'Niigata',
	Itō: 'Shizuoka',
	Itami: 'Hyōgo',
	Ishiki: 'Aichi',
	Ishikawa: 'Fukushima',
	Ishii: 'Tokushima',
	Ishige: 'Ibaraki',
	Ishigaki: 'Okinawa',
	Isesaki: 'Gunma',
	Isehara: 'Kanagawa',
	Ise: 'Mie',
	Isawa: 'Yamanashi',
	Isahaya: 'Nagasaki',
	Inuyama: 'Aichi',
	Ino: 'Kochi',
	Innoshima: 'Hiroshima',
	Inazawa: 'Aichi',
	Ina: 'Nagano',
	'Imarichō-kō': 'Saga',
	Imaichi: 'Tochigi',
	Ikoma: 'Nara',
	Ikeda: 'Ōsaka',
	Ikedachō: 'Tokushima',
	Ijūin: 'Kagoshima',
	Iizuka: 'Fukuoka',
	Iiyama: 'Nagano',
	Iida: 'Nagano',
	Ichinomiya: 'Aichi',
	Ibusuki: 'Kagoshima',
	Ibaraki: 'Ōsaka',
	Ibara: 'Okayama',
	Hotaka: 'Nagano',
	Hōryūji: 'Nara',
	Honjō: 'Saitama',
	'Hondomachi-hondo': 'Kumamoto',
	Hōfu: 'Yamaguchi',
	Hitoyoshi: 'Kumamoto',
	Hita: 'Oita',
	'Hisai-motomachi': 'Mie',
	Hiroshima: 'Hiroshima',
	Hiratsuka: 'Kanagawa',
	Hiratachō: 'Shimane',
	Hirara: 'Okinawa',
	Hirakata: 'Ōsaka',
	Hirado: 'Nagasaki',
	Hino: 'Shiga',
	Himimachi: 'Toyama',
	Himeji: 'Hyōgo',
	Hikone: 'Shiga',
	Hiji: 'Oita',
	'Higashi-ōsaka': 'Ōsaka',
	Hekinan: 'Aichi',
	Hayama: 'Kanagawa',
	Hatsukaichi: 'Hiroshima',
	'Hatogaya-honchō': 'Saitama',
	Hashimoto: 'Wakayama',
	Hanyū: 'Saitama',
	Hannō: 'Saitama',
	Handa: 'Aichi',
	Hamanoichi: 'Kagoshima',
	Hamamatsu: 'Shizuoka',
	Hamakita: 'Shizuoka',
	Hamada: 'Shimane',
	Hakui: 'Ishikawa',
	'Haibara-akanedai': 'Nara',
	Hagi: 'Yamaguchi',
	'Katsuren-haebaru': 'Okinawa',
	Hadano: 'Kanagawa',
	Hachiōji: 'Tokyo',
	Ōmihachiman: 'Shiga',
	Gyōda: 'Saitama',
	Gushikawa: 'Okinawa',
	Gōtsuchō: 'Shimane',
	Gotenba: 'Shizuoka',
	Gosen: 'Niigata',
	Gose: 'Nara',
	Gojō: 'Nara',
	Gōdo: 'Gifu',
	Gobō: 'Wakayama',
	Ginowan: 'Okinawa',
	'Gifu-shi': 'Gifu',
	Gamagōri: 'Aichi',
	Futtsu: 'Chiba',
	Honchō: 'Hokkaido',
	Fukuyama: 'Hiroshima',
	Fukuroi: 'Shizuoka',
	Fukura: 'Hyōgo',
	Fukuoka: 'Fukuoka',
	Fukumitsu: 'Toyama',
	Nishifukuma: 'Fukuoka',
	'Fukui-shi': 'Fukui',
	Fukuechō: 'Nagasaki',
	Fukuchiyama: 'Kyoto',
	'Fukiage-fujimi': 'Saitama',
	Fukayachō: 'Saitama',
	Fujisawa: 'Kanagawa',
	Fujioka: 'Gunma',
	Fujinomiya: 'Shizuoka',
	Fujieda: 'Shizuoka',
	Fuchūchō: 'Hiroshima',
	Enzan: 'Yamanashi',
	Daitō: 'Ōsaka',
	Chōfu: 'Tokyo',
	Chiryū: 'Aichi',
	Chino: 'California',
	'Chikushino-shi': 'Fukuoka',
	Chigasaki: 'Kanagawa',
	Chichibu: 'Saitama',
	Chatan: 'Okinawa',
	Beppu: 'Oita',
	Ayabe: 'Kyoto',
	Atami: 'Shizuoka',
	Ashiya: 'Hyōgo',
	Ashikaga: 'Tochigi',
	Arita: 'Saga',
	Arai: 'Shizuoka',
	Annaka: 'Gunma',
	Anjō: 'Aichi',
	Anan: 'Tokushima',
	Anamizu: 'Ishikawa',
	Amagi: 'Fukuoka',
	Amagasaki: 'Hyōgo',
	Akune: 'Kagoshima',
	Aki: 'Kochi',
	Aioi: 'Hyōgo',
	Ageoshimo: 'Saitama',
	Okinawa: 'Okinawa',
	Kushima: 'Miyazaki',
	Hikari: 'Yamaguchi',
	Nagato: 'Yamaguchi',
	Hasuda: 'Saitama',
	Kamifukuoka: 'Saitama',
	Sayama: 'Saitama',
	Fussa: 'Tokyo',
	Asaka: 'Andijon',
	Wako: 'Saitama',
	Shimotoda: 'Saitama',
	Kimitsu: 'Chiba',
	Miura: 'Kanagawa',
	Wakayama: 'Wakayama',
	Iyo: 'Ehime',
	Uwajima: 'Ehime',
	Saijō: 'Ehime',
	Matsuyama: 'Ehime',
	'Masaki-chō': 'Ehime',
	Kawanoechō: 'Ehime',
	Hōjō: 'Ehime',
	Yuzawa: 'Akita',
	Yotsukaidō: 'Chiba',
	Yonezawa: 'Yamagata',
	Yokote: 'Akita',
	Yōkaichiba: 'Chiba',
	'Yanagawamachi-saiwaichō': 'Fukushima',
	Yamoto: 'Miyagi',
	Yamagata: 'Gifu',
	Yamada: 'Iwate',
	Yachimata: 'Chiba',
	Yabuki: 'Fukushima',
	Watari: 'Miyagi',
	Wakuya: 'Miyagi',
	Ushiku: 'Ibaraki',
	Tsukuba: 'Ibaraki',
	Toride: 'Ibaraki',
	Tōno: 'Iwate',
	Tomobe: 'Ibaraki',
	Tomiya: 'Miyagi',
	Tōgane: 'Chiba',
	Tendō: 'Yamagata',
	Takahata: 'Yamagata',
	Takahagi: 'Ibaraki',
	Sukagawa: 'Fukushima',
	Shizukuishi: 'Iwate',
	Shisui: 'Chiba',
	Shiroishi: 'Miyagi',
	Shiroi: 'Chiba',
	Shiogama: 'Miyagi',
	Shinjō: 'Yamagata',
	Sendai: 'Miyagi',
	Sawara: 'Chiba',
	Sakura: 'Tochigi',
	Sagae: 'Yamagata',
	Ryūgasaki: 'Ibaraki',
	Rifu: 'Miyagi',
	Ōtawara: 'Tochigi',
	Ōmiya: 'Ibaraki',
	Omigawa: 'Chiba',
	Ōmagari: 'Akita',
	Okunoya: 'Ibaraki',
	Ōkawara: 'Miyagi',
	Ōhara: 'Chiba',
	Ōfunato: 'Iwate',
	Obanazawa: 'Yamagata',
	Ōarai: 'Ibaraki',
	Ōami: 'Chiba',
	Nihommatsu: 'Fukushima',
	Narutō: 'Chiba',
	Narita: 'Chiba',
	Namie: 'Fukushima',
	Naka: 'Ibaraki',
	Nagai: 'Yamagata',
	Motomiya: 'Fukushima',
	Moriya: 'Ibaraki',
	Morioka: 'Iwate',
	Mooka: 'Tochigi',
	Mobara: 'Chiba',
	Mizusawa: 'Iwate',
	Miyako: 'Iwate',
	Mito: 'Ibaraki',
	Miharu: 'Fukushima',
	Matsushima: 'Miyagi',
	Mashiko: 'Tochigi',
	Makabe: 'Ibaraki',
	Kuroiso: 'Tochigi',
	Kōriyama: 'Fukushima',
	Kogota: 'Miyagi',
	Kitakami: 'Iwate',
	Kitaibaraki: 'Ibaraki',
	Kamogawa: 'Chiba',
	Katsuura: 'Chiba',
	Katsuta: 'Ibaraki',
	'Katori-shi': 'Chiba',
	Kasama: 'Northern',
	Karasuyama: 'Tochigi',
	Kanegasaki: 'Iwate',
	Kaminoyama: 'Yamagata',
	Kamaishi: 'Iwate',
	Kakuda: 'Miyagi',
	Iwase: 'Ibaraki',
	Iwanuma: 'Miyagi',
	Iwaki: 'Fukushima',
	Itako: 'Ibaraki',
	Ishioka: 'Ibaraki',
	Ishinomaki: 'Miyagi',
	Ichinoseki: 'Iwate',
	Ichihara: 'Chiba',
	Hobaramachi: 'Fukushima',
	Hitachi: 'Ibaraki',
	Higashine: 'Yamagata',
	Hasaki: 'Chiba',
	Hanamaki: 'Iwate',
	Furukawa: 'Miyagi',
	'Funehikimachi-funehiki': 'Fukushima',
	Funaishikawa: 'Ibaraki',
	Fukushima: 'Fukushima',
	Fujishiro: 'Ibaraki',
	Edosaki: 'Ibaraki',
	Date: 'Hokkaido',
	Daigo: 'Ibaraki',
	Chiba: 'Chiba',
	Asahi: 'Chiba',
	Ami: 'Ibaraki',
	Akita: 'Akita',
	Abiko: 'Chiba',
	Motomachi: 'Hokkaido',
	Wakkanai: 'Hokkaido',
	Tomakomai: 'Hokkaido',
	Tōbetsu: 'Hokkaido',
	Mutsu: 'Aomori',
	Takikawa: 'Hokkaido',
	Takanosu: 'Akita',
	Sunagawa: 'Hokkaido',
	'Shizunai-furukawachō': 'Hokkaido',
	Shiraoi: 'Hokkaido',
	'Shimo-furano': 'Hokkaido',
	Minamishibetsuchō: 'Hokkaido',
	Shibetsu: 'Hokkaido',
	Sapporo: 'Hokkaido',
	Rumoi: 'Hokkaido',
	Otofuke: 'Hokkaido',
	Otaru: 'Hokkaido',
	Ōdate: 'Akita',
	Obihiro: 'Hokkaido',
	Noshiro: 'Akita',
	Nemuro: 'Hokkaido',
	Nayoro: 'Hokkaido',
	Namioka: 'Aomori',
	Nakashibetsu: 'Hokkaido',
	Muroran: 'Hokkaido',
	Mombetsu: 'Hokkaido',
	Misawa: 'Aomori',
	Yoichi: 'Hokkaido',
	Makubetsu: 'Hokkaido',
	Kushiro: 'Hokkaido',
	Kuroishi: 'Aomori',
	Kuji: 'Iwate',
	Shimokizukuri: 'Aomori',
	Kitami: 'Hokkaido',
	Kamiiso: 'Hokkaido',
	Iwamizawa: 'Hokkaido',
	Ishikari: 'Hokkaido',
	Kitahiroshima: 'Hokkaido',
	Hirosaki: 'Aomori',
	Hanawa: 'Akita',
	Hakodate: 'Hokkaido',
	Hachinohe: 'Aomori',
	Fukagawa: 'Hokkaido',
	Ebetsu: 'Hokkaido',
	Chitose: 'Hokkaido',
	Bibai: 'Hokkaido',
	Ashibetsu: 'Hokkaido',
	Asahikawa: 'Hokkaido',
	Aomori: 'Aomori',
	Abashiri: 'Hokkaido',
	Goshogawara: 'Aomori',
	Aso: 'Kumamoto',
	'Nanto-shi': 'Toyama',
	Kawage: 'Mie',
	Neyagawa: 'Ōsaka',
	'Hitachi-Naka': 'Ibaraki',
	Chikusei: 'Ibaraki',
	'Hitachi-ota': 'Ibaraki',
	Inashiki: 'Ibaraki',
	Chikuma: 'Nagano',
	Rittō: 'Shiga',
	Takashima: 'Shiga',
	Kōka: 'Shiga',
	Katsuragi: 'Nara',
	Nanto: 'Toyama',
	'Kurobe-shi': 'Toyama',
	Tama: 'Tokyo',
	Tonami: 'Toyama',
	Kaga: 'Ishikawa',
	Matsuura: 'Nagasaki',
	Ōnojō: 'Fukuoka',
	'Minami-Sōma': 'Fukushima',
	Midori: 'Gunma',
	Miyakojima: 'Okinawa',
	Hidaka: 'Saitama',
	Iruma: 'Saitama',
	Sōsa: 'Chiba',
	Sōma: 'Fukushima',
	Kinokawa: 'Wakayama',
	Kumano: 'Mie',
	Nasushiobara: 'Tochigi',
	Shimotsuke: 'Tochigi',
	Inzai: 'Chiba',
	Hachimantai: 'Iwate',
	Ōsaki: 'Miyagi',
	Higashimatsushima: 'Miyagi',
	Kurihara: 'Miyagi',
	Izunokuni: 'Shizuoka',
	Izu: 'Shizuoka',
	Kikugawa: 'Shizuoka',
	Kosai: 'Shizuoka',
	Susono: 'Shizuoka',
	Yurihonjō: 'Akita',
	'Kita-Akita': 'Akita',
	Hirakawa: 'Aomori',
	Towada: 'Aomori',
	Kitanagoya: 'Aichi',
	Nisshin: 'Aichi',
	Chita: 'Zabaykalskiy (Transbaikal) Kray',
	Hashima: 'Gifu',
	Kaizu: 'Gifu',
	Kani: 'Gifu',
	Minokamo: 'Gifu',
	Gujō: 'Gifu',
	Ena: 'Gifu',
	Jōetsu: 'Niigata',
	Minamiuonuma: 'Niigata',
	Sado: 'Niigata',
	Tainai: 'Niigata',
	Uonuma: 'Niigata',
	Habikino: 'Ōsaka',
	Hannan: 'Ōsaka',
	Hokuto: 'Yamanashi',
	Saitama: 'Saitama',
	Higashimurayama: 'Tokyo',
	Fujikawaguchiko: 'Yamanashi',
	Yawatahama: 'Ehime',
	Kirishima: 'Kagoshima',
	Arashiyama: 'Kyoto',
	Dazaifu: 'Fukuoka',
	Amakusa: 'Kumamoto',
	'Kamigyō-ku': 'Kyoto',
	Buzen: 'Fukuoka',
	Kanmaki: 'Nara',
	Sue: 'Fukuoka',
	Fujiyoshida: 'Yamanashi',
	Shonai: 'Yamagata',
	Yomitan: 'Okinawa',
	Matsubushi: 'Saitama',
	'Minami-Alps': 'Yamanashi',
	Arao: 'Kumamoto',
	Miyota: 'Nagano',
	Iga: 'Mie',
	Kuwana: 'Mie',
	Himi: 'Toyama',
	Takizawa: 'Iwate',
	Yasu: 'Shiga',
	Yamato: 'Kanagawa',
	Saito: 'Miyazaki',
	Mitoyo: 'Kagawa',
	Hyūga: 'Miyazaki',
	Hamura: 'Tokyo',
	Fuchū: 'Tokyo',
	Arida: 'Wakayama',
	Katō: 'Hyōgo',
	Tome: 'Miyagi',
	Kai: 'Yamanashi',
	Shirakawa: 'Fukushima',
	Mizuho: 'Gifu',
	Kasai: 'Hyōgo',
	Minamiawaji: 'Hyōgo',
	Shisō: 'Hyōgo',
	Tanba: 'Hyōgo',
	Higashikagawa: 'Kagawa',
	Kōshi: 'Kumamoto',
	Uki: 'Kumamoto',
	Konan: 'Shiga',
	Chūō: 'Yamanashi',
	Fuefuki: 'Yamanashi',
	Kōshū: 'Yamanashi',
	Semboku: 'Akita',
	Agano: 'Niigata',
	Webuye: 'Bungoma',
	Wajir: 'Wajir',
	Voi: 'Taita Taveta',
	Thika: 'Nairobi Area',
	Ruiru: 'Kiambu',
	Rongai: 'Nakuru',
	Pumwani: 'Nairobi Area',
	Ogembo: 'Kisii',
	Nyeri: 'Nyeri',
	Nyahururu: 'Laikipia',
	Narok: 'Narok',
	Nanyuki: 'Laikipia',
	Nakuru: 'Nakuru',
	Naivasha: 'Nakuru',
	Nairobi: 'Nairobi Area',
	Mumias: 'Kakamega',
	Muhoroni: 'Kisumu',
	Moyale: 'Marsabit',
	Mombasa: 'Mombasa',
	Molo: 'Nakuru',
	Migori: 'Migori',
	Meru: 'Meru',
	Mbale: 'Eastern Region',
	Marsabit: 'Marsabit',
	Maralal: 'Samburu',
	Mandera: 'Mandera',
	Malindi: 'Kilifi',
	'Makueni Boma': 'Makueni',
	Machakos: 'Machakos',
	Lugulu: 'Busia',
	Lodwar: 'Turkana',
	Limuru: 'Kiambu',
	Lamu: 'Lamu',
	Kitui: 'Kitui',
	Kitale: 'Trans Nzoia',
	Kisumu: 'Kisumu',
	Kisii: 'Kisii',
	Kilifi: 'Kilifi',
	Kikuyu: 'Kiambu',
	Kiambu: 'Kiambu',
	Kerugoya: 'Kirinyaga',
	Kericho: 'Kericho',
	Karuri: "Murang'A",
	Kapenguria: 'West Pokot',
	Kakamega: 'Kakamega',
	Kajiado: 'Kajiado',
	Kabarnet: 'Baringo',
	Iten: 'Elegeyo-Marakwet',
	Isiolo: 'Isiolo',
	'Homa Bay': 'Homa Bay',
	Garissa: 'Garissa',
	Eldoret: 'Uasin Gishu',
	Busia: 'Eastern Region',
	Bungoma: 'Bungoma',
	'Athi River': 'Machakos',
	Siaya: 'Siaya',
	'Ol Kalou': 'Nyandarua',
	Suluktu: 'Batken',
	Razzakov: 'Batken',
	Balykchy: 'Issyk-Kul',
	Tokmok: 'Chüy',
	'Tash-Kumyr': 'Jalal-Abad',
	Talas: 'Kayseri',
	Suzak: 'Jalal-Abad',
	'Kyzyl-Suu': 'Issyk-Kul',
	Uzgen: 'Osh',
	Osh: 'Osh',
	Naryn: 'Naryn',
	Lebedinovka: 'Chüy',
	'Kyzyl-Kyya': 'Batken',
	'Kochkor-Ata': 'Jalal-Abad',
	'Kara Suu': 'Osh',
	Karakol: 'Issyk-Kul',
	'Kara-Balta': 'Chüy',
	Kant: 'Chüy',
	'Jalal-Abad': 'Jalal-Abad',
	Ivanovka: 'Chüy',
	Iradan: 'Batken',
	Nookat: 'Osh',
	Bishkek: 'Bishkek',
	Belovodskoye: 'Chüy',
	'Bazar-Korgon': 'Jalal-Abad',
	Batken: 'Batken',
	'Ala-Buka': 'Jalal-Abad',
	Toktogul: 'Jalal-Abad',
	'Kara-Kulja': 'Osh',
	'Phnom Penh': 'Phnom Penh',
	'Ta Khmau': 'Kandal',
	'Svay Rieng': 'Svay Rieng',
	Suong: 'Tboung Khmum',
	'Stung Treng': 'Stung Treng',
	Sisophon: 'Banteay Meanchey',
	'Siem Reap': 'Siem Reap',
	'Prey Veng': 'Prey Veng',
	Pursat: 'Pursat',
	'Phumĭ Véal Srê': 'Takeo',
	Samraong: 'Ŏtâr Méanchey',
	'Tbeng Meanchey': 'Preah Vihear',
	'Paoy Paet': 'Banteay Meanchey',
	Pailin: 'Pailin',
	Lumphat: 'Ratanakiri',
	'Koh Kong': 'Koh Kong',
	Kratié: 'Kratie',
	Kampot: 'Kampot',
	'Kampong Thom': 'Kampong Thom',
	'Kampong Speu': 'Kampong Speu',
	Sihanoukville: 'Preah Sihanouk',
	'Kampong Chhnang': 'Kampong Chhnang',
	'Kampong Cham': 'Kampong Cham',
	Banlung: 'Ratanakiri',
	Battambang: 'Battambang',
	'Smach Mean Chey': 'Koh Kong',
	Tarawa: 'Gilbert Islands',
	Moutsamoudou: 'Anjouan',
	Moroni: 'Grande Comore',
	Basseterre: 'Saint George Basseterre',
	'Yŏnan-ŭp': 'Hwanghae-namdo',
	Wŏnsan: 'Kangwŏn-do',
	'T’ongch’ŏn-ŭp': 'Kangwŏn-do',
	'Sŭngho 1-tong': 'Pyongyang',
	'Sunch’ŏn': 'South Pyongan',
	Sunan: 'Pyongyang',
	'Songnim-ni': 'Hwanghae-bukto',
	'Sil-li': 'South Pyongan',
	Sinmak: 'Hwanghae-bukto',
	Sinanju: 'South Pyongan',
	Sariwŏn: 'Hwanghae-bukto',
	'Samho-rodongjagu': 'Hamgyŏng-namdo',
	Pyongyang: 'Pyongyang',
	'P’yŏngsŏng': 'South Pyongan',
	Ongjin: 'Hwanghae-namdo',
	'Namp’o': 'South Pyongan',
	Kusŏng: "P'yŏngan-bukto",
	'Kujang-ŭp': "P'yŏngan-bukto",
	'Kowŏn-ŭp': 'Hamgyŏng-namdo',
	Kosan: 'Kangwŏn-do',
	'Kangdong-ŭp': 'Pyongyang',
	Kaesŏng: 'Hwanghae-namdo',
	'Hwangju-ŭp': 'Hwanghae-bukto',
	Hŭngnam: 'Hamgyŏng-namdo',
	'Hŭkkyo-ri': 'Hwanghae-bukto',
	Hoeyang: 'Kangwŏn-do',
	Hamhŭng: 'Hamgyŏng-namdo',
	Haeju: 'Hwanghae-namdo',
	Chunghwa: 'Pyongyang',
	Chŏngju: "P'yŏngan-bukto",
	Changyŏn: 'Hwanghae-namdo',
	'Chaeryŏng-ŭp': 'Hwanghae-namdo',
	'Ayang-ni': 'Hwanghae-namdo',
	'Anbyŏn-ŭp': 'Kangwŏn-do',
	Anak: 'Hwanghae-bukto',
	'Yuktae-dong': 'Hamgyŏng-namdo',
	Uiju: "P'yŏngan-bukto",
	Sŏnbong: 'Rason',
	Sinŭiju: "P'yŏngan-bukto",
	'Sakchu-ŭp': "P'yŏngan-bukto",
	'Yŏnggwang-ŭp': 'Hamgyŏng-namdo',
	Onsŏng: 'Hambuk',
	Ranam: 'Hambuk',
	Namyang: 'Hambuk',
	Rajin: 'Rason',
	'Musan-ŭp': 'Hambuk',
	'Manp’o': 'Chagang-do',
	Kyŏngsŏng: 'Hambuk',
	Kilju: 'Hambuk',
	'Kapsan-ŭp': 'Yanggang-do',
	Kanggye: 'Chagang-do',
	'Iwŏn-ŭp': 'Hamgyŏng-namdo',
	'Hyesan-dong': 'Yanggang-do',
	Hyesan: 'Yanggang-do',
	Hongwŏn: 'Hamgyŏng-namdo',
	Hoeryŏng: 'Hambuk',
	Chongjin: 'Hambuk',
	Aoji: 'Hambuk',
	Heunghae: 'Gyeongsangbuk-do',
	Yeonil: 'Gyeongsangbuk-do',
	Neietsu: 'Gangwon-do',
	Yeongju: 'Gyeongsangbuk-do',
	Yeonggwang: 'Jeollanam-do',
	Yeongdong: 'North Chungcheong',
	Eisen: 'Gyeongsangbuk-do',
	Yeoju: 'Gyeonggi-do',
	Yesan: 'Chungcheongnam-do',
	Yangsan: 'Gyeongsangnam-do',
	"Yangp'yŏng": 'Gyeonggi-do',
	Yangju: 'Gyeonggi-do',
	Wŏnju: 'Gangwon-do',
	Wanju: 'Jeollabuk-do',
	Waegwan: 'Gyeongsangbuk-do',
	Ulsan: 'Ulsan',
	'Uijeongbu-si': 'Gyeonggi-do',
	Tangjin: 'Chungcheongnam-do',
	'Taesal-li': 'Chungcheongnam-do',
	Daejeon: 'Daejeon',
	Daegu: 'Daegu',
	Boryeong: 'Chungcheongnam-do',
	'T’aebaek': 'Gangwon-do',
	Suwon: 'Gyeonggi-do',
	Suncheon: 'Jeollanam-do',
	Seoul: 'Seoul',
	Seosan: 'Chungcheongnam-do',
	Jenzan: 'Gyeongsangbuk-do',
	Seonghwan: 'Chungcheongnam-do',
	Sokcho: 'Gangwon-do',
	Sangju: 'Gyeongsangbuk-do',
	Santyoku: 'Gangwon-do',
	Buyeo: 'Chungcheongnam-do',
	Busan: 'Busan',
	'Bucheon-si': 'Gyeonggi-do',
	Puan: 'Jeollabuk-do',
	Beolgyo: 'Jeollanam-do',
	Pohang: 'Gyeongsangbuk-do',
	Osan: 'Gyeonggi-do',
	Asan: 'Chungcheongnam-do',
	Okcheon: 'North Chungcheong',
	Kosong: 'Gangwon-do',
	Nonsan: 'Chungcheongnam-do',
	Nangen: 'Jeollabuk-do',
	Naju: 'Jeollanam-do',
	Munsan: 'Gyeonggi-do',
	Mungyeong: 'Gyeongsangbuk-do',
	Muan: 'Jeollanam-do',
	Mokpo: 'Jeollanam-do',
	Miryang: 'Gyeongsangnam-do',
	Masan: 'Gwangju',
	'Gyeongsan-si': 'Gyeongsangbuk-do',
	Gyeongju: 'Gyeongsangbuk-do',
	Kwangyang: 'Jeollanam-do',
	Gwangju: 'Gwangju',
	Kurye: 'Jeollanam-do',
	'Guri-si': 'Gyeonggi-do',
	Kunwi: 'Gyeongsangbuk-do',
	Gunsan: 'Jeollabuk-do',
	Gunpo: 'Gyeonggi-do',
	Kinzan: 'Chungcheongnam-do',
	Gumi: 'Gyeongsangbuk-do',
	'Goyang-si': 'Gyeonggi-do',
	Goseong: 'Gyeongsangnam-do',
	Gongju: 'Chungcheongnam-do',
	Kyosai: 'Gyeongsangnam-do',
	Koesan: 'North Chungcheong',
	"Koch'ang": 'Jeollabuk-do',
	Kimje: 'Jeollabuk-do',
	Kimhae: 'Gyeongsangnam-do',
	Gimcheon: 'Gyeongsangbuk-do',
	Gijang: 'Busan',
	Gapyeong: 'Gyeonggi-do',
	Gangneung: 'Gangwon-do',
	'Ganghwa-gun': 'Incheon',
	Iksan: 'Jeollabuk-do',
	Incheon: 'Incheon',
	Imsil: 'Jeollabuk-do',
	'Icheon-si': 'Gyeonggi-do',
	Hwasun: 'Jeollanam-do',
	'Hwaseong-si': 'Gyeonggi-do',
	Hwacheon: 'Gangwon-do',
	Hongseong: 'Chungcheongnam-do',
	'Hongch’ŏn': 'Gangwon-do',
	Hayang: 'Gyeongsangbuk-do',
	Haenam: 'Jeollanam-do',
	Chungju: 'North Chungcheong',
	Chuncheon: 'Gangwon-do',
	Jumunjin: 'Gangwon-do',
	Jeonju: 'Jeollabuk-do',
	'Cheongsong gun': 'Gyeongsangbuk-do',
	'Cheongju-si': 'North Chungcheong',
	Cheonan: 'Chungcheongnam-do',
	Chinju: 'Gyeongsangnam-do',
	"Chinch'ŏn": 'North Chungcheong',
	'Jinan-gun': 'Jeollabuk-do',
	'Jeju City': 'Jeju-do',
	Changwon: 'Gyeongsangnam-do',
	Changsu: 'Jeollabuk-do',
	'Anyang-si': 'Gyeonggi-do',
	Anseong: 'Gyeonggi-do',
	'Ansan-si': 'Gyeonggi-do',
	Andong: 'Gyeongsangbuk-do',
	Gaigeturi: 'Jeju-do',
	Sinhyeon: 'Gyeongsangnam-do',
	Yeosu: 'Jeollanam-do',
	Yŏnmu: 'Chungcheongnam-do',
	'Donghae City': 'Gangwon-do',
	Pubal: 'Gyeonggi-do',
	'Seongnam-si': 'Gyeonggi-do',
	Hanam: 'Gyeonggi-do',
	Hwado: 'Gyeonggi-do',
	Namyangju: 'Gyeonggi-do',
	Ungsang: 'Gyeongsangnam-do',
	Wabu: 'Gyeonggi-do',
	Naesŏ: 'Gyeongsangnam-do',
	Hwawŏn: 'Daegu',
	Gwangmyeong: 'Gyeonggi-do',
	Sinan: 'Jeollanam-do',
	Seogwipo: 'Jeju-do',
	Changnyeong: 'Gyeongsangnam-do',
	Sejong: 'Sejong-si',
	Jeongeup: 'Jeollabuk-do',
	Yeongam: 'Jeollanam-do',
	'Janūb as Surrah': 'Al Farwaniyah',
	Ḩawallī: 'Hawalli',
	Bayān: 'Hawalli',
	'As Sālimīyah': 'Hawalli',
	'Ar Rumaythīyah': 'Hawalli',
	'Ar Riqqah': 'Al Aḩmadī',
	'Al Manqaf': 'Al Aḩmadī',
	'Al Mahbūlah': 'Al Aḩmadī',
	'Kuwait City': 'Al Asimah',
	'Al Jahrā’': 'Al Jahrāʼ',
	'Al Faḩāḩīl': 'Al Aḩmadī',
	'Al Finţās': 'Al Aḩmadī',
	'Al Farwānīyah': 'Al Farwaniyah',
	'Al Aḩmadī': 'Al Aḩmadī',
	'Ad Dasmah': 'Al Asimah',
	Salwá: 'Hawalli',
	'Ar Rābiyah': 'Al Asimah',
	'Şabāḩ as Sālim': 'Mubārak al Kabīr',
	'West Bay': 'West Bay',
	'George Town': 'Penang',
	Zhanaozen: 'Mangghystaū',
	Shalqar: 'Aqtöbe',
	Shalkar: 'Atyraū',
	Oral: 'Batys Qazaqstan',
	Kandyagash: 'Aqtöbe',
	Qulsary: 'Atyraū',
	Khromtau: 'Aqtöbe',
	Karagandy: 'Karaganda',
	Embi: 'Aqtöbe',
	Balykshi: 'Atyraū',
	Atyrau: 'Atyraū',
	Aktobe: 'Aqtöbe',
	Shevchenko: 'Mangghystaū',
	Aqsay: 'Batys Qazaqstan',
	Zhosaly: 'Qyzylorda',
	Zhezqazghan: 'Ulytau Region',
	Zhitikara: 'Qostanay',
	Zhangatas: 'Zhambyl',
	Novokazalinsk: 'Qyzylorda',
	Taraz: 'Zhambyl',
	Zaysan: 'East Kazakhstan',
	Zhangaqorghan: 'Qyzylorda',
	'Turar Ryskulov': 'South Kazakhstan',
	Ushtobe: 'Jetisu Region',
	Turkestan: 'South Kazakhstan',
	Temirtau: 'Karaganda',
	Tekeli: 'Jetisu Region',
	Tasbuget: 'Qyzylorda',
	Talghar: 'Almaty',
	Taldykorgan: 'Jetisu Region',
	Shymkent: 'Shymkent',
	Shu: 'Zhambyl',
	Shemonaikha: 'East Kazakhstan',
	Shchuchinsk: 'Aqmola',
	Shakhtinsk: 'Karaganda',
	Semey: 'Abai Region',
	Saryaghash: 'South Kazakhstan',
	Sarqant: 'Jetisu Region',
	Rudnyy: 'Qostanay',
	Kyzylorda: 'Qyzylorda',
	Kostanay: 'Qostanay',
	Karatau: 'Zhambyl',
	Konayev: 'Almaty Oblysy',
	Petropavl: 'North Kazakhstan',
	Pavlodar: 'Pavlodar Region',
	Zharkent: 'Jetisu Region',
	'Ust-Kamenogorsk': 'East Kazakhstan',
	Sarykemer: 'Zhambyl',
	Merke: 'Zhambyl',
	Makinsk: 'Aqmola',
	Lisakovsk: 'Qostanay',
	Baikonur: 'Baikonur',
	Ridder: 'East Kazakhstan',
	Lenger: 'South Kazakhstan',
	Kokshetau: 'Aqmola',
	Kentau: 'South Kazakhstan',
	Esik: 'Almaty Oblysy',
	Kalbatau: 'Abai Region',
	'Otegen Batyra': 'Almaty',
	Ekibastuz: 'Pavlodar Region',
	Shiyeli: 'Qyzylorda',
	Shardara: 'South Kazakhstan',
	Burunday: 'Almaty',
	Balqash: 'Karaganda',
	Ayagoz: 'Abai Region',
	Atbasar: 'Aqmola',
	Arys: 'South Kazakhstan',
	Arkalyk: 'Qostanay',
	Astana: 'Astana',
	Almaty: 'Almaty',
	'Akkol’': 'Aqmola',
	Abay: 'Karaganda',
	Stepnogorsk: 'Aqmola',
	Vientiane: 'Vientiane Prefecture',
	'Xam Nua': 'Houaphan',
	Savannakhet: 'Savannahkhét',
	Pakse: 'Champasak',
	'Muang Xay': 'Oudômxai',
	'Vang Vieng': 'Vientiane',
	'Muang Phônsavan': 'Xiangkhoang',
	Pakxan: 'Bolikhamsai',
	Thakhèk: 'Khammouan',
	'Luang Prabang': 'Louangphabang',
	'Ban Houakhoua': 'Bokeo',
	Zahlé: 'Béqaa',
	Tripoli: 'Tripoli',
	Tyre: 'South Governorate',
	Sidon: 'South Governorate',
	'Ra’s Bayrūt': 'Beyrouth',
	Jounieh: 'Mont-Liban',
	Jbaïl: 'Mont-Liban',
	Habboûch: 'Nabatîyé',
	Bcharré: 'Liban-Nord',
	Beirut: 'Beyrouth',
	Baalbek: 'Baalbek-Hermel',
	'En Nâqoûra': 'South Governorate',
	'Nabatîyé et Tahta': 'Nabatîyé',
	Ghazieh: 'South Governorate',
	Castries: 'Castries',
	Vaduz: 'Vaduz',
	Welisara: 'Western',
	Weligama: 'Southern',
	Wattala: 'Western',
	Vavuniya: 'Northern Province',
	Valvedditturai: 'Northern Province',
	Vakarai: 'Eastern Province',
	Trincomalee: 'Eastern Province',
	Ratnapura: 'Sabaragamuwa',
	Puttalam: 'North Western',
	'Point Pedro': 'Northern Province',
	'Pita Kotte': 'Western',
	Peliyagoda: 'Western',
	Panadura: 'Western',
	'Nuwara Eliya': 'Central',
	Negombo: 'Western',
	Mulleriyawa: 'Western',
	'Dehiwala-Mount Lavinia': 'Western',
	Moratuwa: 'Western',
	Matara: 'Southern',
	Matale: 'Central',
	Maharagama: 'Western',
	Kurunegala: 'North Western',
	'Sri Jayewardenepura Kotte': 'Western',
	Kotikawatta: 'Western',
	Kolonnawa: 'Western',
	Kelaniya: 'Western',
	Kegalle: 'Sabaragamuwa',
	Katunayaka: 'Western',
	Kataragama: 'Uva',
	Kandy: 'Central',
	Kandana: 'Western',
	Kalutara: 'Western',
	Kalmunai: 'Eastern Province',
	Jaffna: 'Northern Province',
	'Ja Ela': 'Western',
	Homagama: 'Western',
	Hendala: 'Western',
	Hatton: 'Central',
	'Hanwella Ihala': 'Western',
	Gampola: 'Central',
	Galle: 'Southern',
	'Eravur Town': 'Eastern Province',
	Devinuwara: 'Southern',
	Dambulla: 'Central',
	Chilaw: 'North Western',
	Beruwala: 'Western',
	Bentota: 'Southern',
	Batticaloa: 'Eastern Province',
	'Battaramulla South': 'Western',
	Badulla: 'Uva',
	Anuradhapura: 'North Central',
	Ampara: 'Eastern Province',
	Ambalangoda: 'Southern',
	Zwedru: 'Grand Gedeh',
	'New Yekepa': 'Nimba',
	Voinjama: 'Lofa',
	Monrovia: 'California',
	Kakata: 'Margibi',
	Harper: 'Maryland',
	Greenville: 'Texas',
	Gbarnga: 'Bong',
	Buchanan: 'Grand Bassa',
	Bensonville: 'Montserrado',
	Quthing: 'Quthing',
	'Qacha’s Nek': 'Qachaʼs Nek',
	'Mohale’s Hoek': 'Mohaleʼs Hoek',
	Maseru: 'Maseru',
	Maputsoe: 'Leribe',
	Mafeteng: 'Mafeteng',
	Leribe: 'Leribe',
	'Butha-Buthe': 'Butha-Buthe',
	'Thaba-Tseka': 'Thaba-Tseka',
	Visaginas: 'Utena',
	Vilnius: 'Vilnius',
	Utena: 'Utena',
	Ukmerge: 'Vilnius',
	Telsiai: 'Telsiai',
	Taurage: 'Tauragė County',
	Šiauliai: 'Siauliai',
	Radviliskis: 'Siauliai',
	Plunge: 'Telsiai',
	Panevėžys: 'Panevėžys',
	Palanga: 'Klaipėda County',
	Fabijoniškės: 'Vilnius',
	'Naujoji Vilnia': 'Vilnius',
	Mazeikiai: 'Telsiai',
	Marijampolė: 'Marijampolė County',
	Kretinga: 'Klaipėda County',
	Klaipėda: 'Klaipėda County',
	Kėdainiai: 'Kaunas',
	Kaunas: 'Kaunas',
	Jonava: 'Kaunas',
	Gargždai: 'Klaipėda County',
	Antakalnis: 'Vilnius',
	Alytus: 'Alytus',
	Aleksotas: 'Kaunas',
	'Dainava (Kaunas)': 'Kaunas',
	Naujininkai: 'Vilnius',
	Karoliniškės: 'Vilnius',
	Šilainiai: 'Kaunas',
	Eiguliai: 'Kaunas',
	Pašilaičiai: 'Vilnius',
	Pilaitė: 'Vilnius',
	Justiniškės: 'Vilnius',
	Šeškinė: 'Vilnius',
	Lazdynai: 'Vilnius',
	Vilkpėdė: 'Vilnius',
	Naujamiestis: 'Vilnius',
	Luxembourg: 'Luxembourg',
	'Esch-sur-Alzette': 'Esch-sur-Alzette',
	Dudelange: 'Esch-sur-Alzette',
	Valmiera: 'Valmiera',
	Ventspils: 'Ventspils',
	Tukums: 'Tukums Municipality',
	Salaspils: 'Salaspils Municipality',
	Riga: 'Riga',
	Rēzekne: 'Rēzekne',
	Ogre: 'Ogre',
	Liepāja: 'Liepāja',
	Jūrmala: 'Jūrmala',
	Jelgava: 'Jelgava',
	Jēkabpils: 'Jēkabpils Municipality',
	Daugavpils: 'Daugavpils',
	Dārzciems: 'Alūksne Municipality',
	Cēsis: 'Cēsis Municipality',
	Tobruk: 'Al Buţnān',
	'Qaryat Sulūq': 'Banghāzī',
	Darnah: 'Darnah',
	Benghazi: 'Banghāzī',
	'Az Zuwaytīnah': 'Al Wāḩāt',
	'At Tāj': 'Al Kufrah',
	Tūkrah: 'Al Marj',
	'Al Qubbah': 'Darnah',
	'Al Marj': 'Al Marj',
	'Al Jawf': 'Al Kufrah',
	'Al Bayḑā’': 'Al Jabal al Akhḑar',
	'Al Abyār': 'Al Marj',
	Ajdabiya: 'Al Wāḩāt',
	Zuwārah: 'An Nuqāţ al Khams',
	Zliten: 'Mişrātah',
	Zalţan: 'An Nuqāţ al Khams',
	Yafran: 'Jabal al Gharbi',
	Waddān: 'Al Jufrah',
	Tarhuna: 'Al Marqab',
	'Tājūrā’': 'Tripoli',
	Sirte: 'Surt',
	Şurmān: 'Az Zāwiyah',
	Şabrātah: 'Az Zāwiyah',
	Sabhā: 'Sabhā',
	Nālūt: 'Nālūt',
	Murzuq: 'Murzuq',
	Mizdah: 'Jabal al Gharbi',
	Mişrātah: 'Mişrātah',
	Masallātah: 'Al Marqab',
	Hūn: 'Al Jufrah',
	Zawiya: 'Az Zāwiyah',
	Ghat: 'Ghāt',
	Gharyan: 'Jabal al Gharbi',
	Brak: 'Ash Shāţiʼ',
	'Bani Walid': 'Mişrātah',
	Zintan: 'Jabal al Gharbi',
	'Az Zāwīyah': 'Az Zāwiyah',
	Ubari: 'Wādī al Ḩayāt',
	'Al Ajaylat': 'An Nuqāţ al Khams',
	'Al Khums': 'Al Marqab',
	'Al Jumayl': 'An Nuqāţ al Khams',
	'Al Jadīd': 'Sabhā',
	Smara: 'Laâyoune-Sakia El Hamra',
	'Setti Fatma': 'Marrakesh-Safi',
	Zaïo: 'Oriental',
	Zagora: 'Drâa-Tafilalet',
	Youssoufia: 'Marrakesh-Safi',
	Tiznit: 'Souss-Massa',
	Tirhanimîne: 'Tanger-Tetouan-Al Hoceima',
	Tinghir: 'Drâa-Tafilalet',
	Tiflet: 'Rabat-Salé-Kénitra',
	Tétouan: 'Tanger-Tetouan-Al Hoceima',
	Temara: 'Rabat-Salé-Kénitra',
	Taza: 'Fès-Meknès',
	Taroudant: 'Souss-Massa',
	Taourirt: 'Oriental',
	Taounate: 'Fès-Meknès',
	'Tan-Tan': 'Guelmim-Oued Noun',
	Tangier: 'Tanger-Tetouan-Al Hoceima',
	Tahla: 'Fès-Meknès',
	'Souq Larb’a al Gharb': 'Rabat-Salé-Kénitra',
	'Sidi Yahia El Gharb': 'Rabat-Salé-Kénitra',
	'Sidi Slimane': 'Rabat-Salé-Kénitra',
	'Sidi Qacem': 'Rabat-Salé-Kénitra',
	'Sidi Ifni': 'Souss-Massa',
	'Sidi Bennour': 'Casablanca-Settat',
	Settat: 'Casablanca-Settat',
	Sefrou: 'Fès-Meknès',
	Reçani: 'Drâa-Tafilalet',
	Rabat: 'Rabat-Salé-Kénitra',
	Oulmes: 'Rabat-Salé-Kénitra',
	'Oulad Teïma': 'Souss-Massa',
	'Oujda-Angad': 'Oriental',
	'Oued Zem': 'Béni Mellal-Khénifra',
	Ouezzane: 'Tanger-Tetouan-Al Hoceima',
	Ouarzazat: 'Drâa-Tafilalet',
	Nador: 'Oriental',
	'Al Aaroui': 'Oriental',
	Mohammedia: 'Casablanca-Settat',
	Midelt: 'Béni Mellal-Khénifra',
	Meknès: 'Fès-Meknès',
	'Mechraa Bel Ksiri': 'Rabat-Salé-Kénitra',
	Martil: 'Tanger-Tetouan-Al Hoceima',
	Marrakesh: 'Marrakesh-Safi',
	Larache: 'Tanger-Tetouan-Al Hoceima',
	'Ksar El Kebir': 'Tanger-Tetouan-Al Hoceima',
	Khouribga: 'Béni Mellal-Khénifra',
	Khenifra: 'Béni Mellal-Khénifra',
	Kenitra: 'Rabat-Salé-Kénitra',
	'Kasba Tadla': 'Béni Mellal-Khénifra',
	Jerada: 'Oriental',
	Ifrane: 'Fès-Meknès',
	Guercif: 'Fès-Meknès',
	Guelmim: 'Guelmim-Oued Noun',
	Fnidek: 'Tanger-Tetouan-Al Hoceima',
	'Al Fqih Ben Çalah': 'Béni Mellal-Khénifra',
	'Fès al Bali': 'Fès-Meknès',
	Fès: 'Fès-Meknès',
	Essaouira: 'Marrakesh-Safi',
	Arfoud: 'Drâa-Tafilalet',
	'El Jadid': 'Casablanca-Settat',
	'El Hajeb': 'Fès-Meknès',
	'El Aïoun': 'Oriental',
	Imzouren: 'Tanger-Tetouan-Al Hoceima',
	Chefchaouen: 'Tanger-Tetouan-Al Hoceima',
	Casablanca: 'Casablanca-Settat',
	Bouznika: 'Casablanca-Settat',
	Bouarfa: 'Oriental',
	Berkane: 'Oriental',
	'Beni Mellal': 'Béni Mellal-Khénifra',
	Berrechid: 'Casablanca-Settat',
	Azrou: 'Fès-Meknès',
	Azemmour: 'Casablanca-Settat',
	Asilah: 'Tanger-Tetouan-Al Hoceima',
	Khemisset: 'Rabat-Salé-Kénitra',
	'Al Hoceïma': 'Tanger-Tetouan-Al Hoceima',
	'Aïn Beni Mathar': 'Oriental',
	Ahfir: 'Oriental',
	Agadir: 'Souss-Massa',
	Skhirate: 'Rabat-Salé-Kénitra',
	Boujniba: 'Béni Mellal-Khénifra',
	Errachidia: 'Drâa-Tafilalet',
	'Salé Al Jadida': 'Rabat-Salé-Kénitra',
	'Bni Bouayach': 'Tanger-Tetouan-Al Hoceima',
	'Monte-Carlo': 'Municipality of Monaco',
	Monaco: 'Municipality of Monaco',
	Edineţ: 'Raionul Edineţ',
	Ungheni: 'Ungheni',
	Tiraspol: 'Transnistria',
	Strășeni: 'Strășeni',
	'Bilicenii Vechi': 'Sîngerei',
	Soroca: 'Raionul Soroca',
	Slobozia: 'Ialomița',
	Rîbniţa: 'Transnistria',
	Orhei: 'Orhei',
	Hînceşti: 'Hînceşti',
	Codru: 'Chișinău Municipality',
	Căuşeni: 'Căuşeni',
	Floreşti: 'Floreşti',
	Dubăsari: 'Transnistria',
	Drochia: 'Drochia',
	Comrat: 'Găgăuzia',
	Chisinau: 'Chișinău Municipality',
	'Ceadîr-Lunga': 'Găgăuzia',
	Cahul: 'Cahul',
	Bender: 'Bender Municipality',
	Bălţi: 'Bălţi',
	Podgorica: 'Podgorica',
	Pljevlja: 'Pljevlja',
	Nikšić: 'Opština Nikšić',
	'Herceg Novi': 'Herceg Novi',
	Cetinje: 'Cetinje',
	Budva: 'Budva',
	'Bijelo Polje': 'Bijelo Polje',
	Bar: 'Vinnytsia',
	Marigot: '',
	Toamasina: 'Atsinanana',
	Vondrozo: 'Atsimo-Atsinanana',
	Vohipaho: 'Atsimo-Atsinanana',
	Vohibinany: 'Atsinanana',
	Vavatenina: 'Analanjirofo',
	Vangaindrano: 'Atsimo-Atsinanana',
	Tsiroanomandidy: 'Bongolava',
	Tsiombe: 'Androy',
	Tsaratanana: 'Betsiboka',
	Toliara: 'Atsimo-Andrefana',
	Tôlanaro: 'Anosy',
	Soavinandriana: 'Itasy',
	Soanindrariny: 'Vakinankaratra',
	'Soanierana Ivongo': 'Analanjirofo',
	Sitampiky: 'Boeny',
	Sambava: 'Sava',
	Sakaraha: 'Atsimo-Andrefana',
	Sahavato: 'Vatovavy Region',
	Sadabe: 'Analamanga',
	'Nosy Varika': 'Vatovavy Region',
	Morondava: 'Menabe',
	Moramanga: 'Alaotra Mangoro',
	Miandrivazo: 'Menabe',
	Miandrarivo: 'Vakinankaratra',
	Marovoay: 'Boeny',
	Marolambo: 'Atsinanana',
	Maroantsetra: 'Analanjirofo',
	Manjakandriana: 'Analamanga',
	Mananjary: 'Vatovavy Region',
	Mananara: 'Analanjirofo',
	Manakara: 'Fitovinany Region',
	Maintirano: 'Melaky',
	Mahanoro: 'Atsinanana',
	Mahajanga: 'Boeny',
	Maevatanana: 'Betsiboka',
	Ikongo: 'Fitovinany Region',
	Ikalamavony: 'Upper Matsiatra',
	Ihosy: 'Ihorombe',
	Ifanadiana: 'Vatovavy Region',
	Fianarantsoa: 'Upper Matsiatra',
	'Fenoarivo Be': 'Bongolava',
	'Fenoarivo Atsinanana': 'Analanjirofo',
	Faratsiho: 'Vakinankaratra',
	Farafangana: 'Atsimo-Atsinanana',
	Fandriana: "Amoron'i Mania",
	Betioky: 'Atsimo-Andrefana',
	Betafo: 'Vakinankaratra',
	Beroroha: 'Atsimo-Andrefana',
	'Belo sur Tsiribihina': 'Menabe',
	Beloha: 'Androy',
	Bealanana: 'Sofia',
	Arivonimamo: 'Itasy',
	Antsohimbondrona: 'Diana',
	Antsohihy: 'Sofia',
	Antsiranana: 'Diana',
	Antsirabe: 'Vakinankaratra',
	Antanifotsy: 'Vakinankaratra',
	Antananarivo: 'Analamanga',
	Antalaha: 'Sava',
	Ankazondandy: 'Analamanga',
	Ankazobe: 'Analamanga',
	Ankazoabo: 'Atsimo-Andrefana',
	Anjozorobe: 'Analamanga',
	'Hell-Ville': 'Diana',
	Andilamena: 'Alaotra Mangoro',
	Andapa: 'Sava',
	Ampasimanolotra: 'Atsinanana',
	Amparafaravola: 'Alaotra Mangoro',
	Ampanihy: 'Atsimo-Andrefana',
	Ampahana: 'Sava',
	Ambovombe: 'Androy',
	Ambositra: "Amoron'i Mania",
	Ambohitrolomahitsy: 'Analamanga',
	Amboasary: 'Anosy',
	Amboanjo: 'Fitovinany Region',
	Ambilobe: 'Diana',
	Ambatondrazaka: 'Alaotra Mangoro',
	Ambatolampy: 'Vakinankaratra',
	Ambatofinandrahana: "Amoron'i Mania",
	'Ambato Boeny': 'Boeny',
	Ambarakaraka: 'Diana',
	Ambanja: 'Diana',
	Ambalavao: 'Upper Matsiatra',
	Alarobia: 'Analamanga',
	Majuro: 'Majuro Atoll',
	'RMI Capitol': 'Majuro Atoll',
	Zelino: 'Zhelino',
	Vinica: 'Vinica',
	Veles: 'Veles',
	Tetovo: 'Tetovo',
	Tearce: 'Tearce',
	Studeničani: 'Studenichani',
	Strumica: 'Strumica',
	Struga: 'Struga',
	Shtip: 'Shtip',
	Skopje: 'Grad Skopje',
	Saraj: 'Grad Skopje',
	Resen: 'Resen',
	Radovis: 'Radovish',
	Prilep: 'Prilep',
	Ohrid: 'Ohrid',
	Negotino: 'Negotino',
	Lipkovo: 'Lipkovo',
	Kumanovo: 'Kumanovo',
	'Kriva Palanka': 'Kriva Palanka',
	Kochani: 'Kochani',
	Kičevo: 'Kichevo',
	Kavadarci: 'Kavadarci',
	Kamenjane: 'Bogovinje',
	Gostivar: 'Gostivar',
	Gevgelija: 'Gevgelija',
	'Gjorče Petro': 'Grad Skopje',
	Delcevo: 'Delchevo',
	Debar: 'Debar',
	Brvenica: 'Brvenica',
	Bogovinje: 'Bogovinje',
	Bitola: 'Bitola',
	'Šuto Orizare': 'Grad Skopje',
	Butel: 'Grad Skopje',
	Čair: 'Grad Skopje',
	Ilinden: 'Ilinden',
	'Kisela Voda': 'Grad Skopje',
	'Centar Župa': 'Centar Zhupa',
	Yorosso: 'Sikasso',
	Timbuktu: 'Tombouctou',
	Sikasso: 'Sikasso',
	Ségou: 'Ségou',
	San: 'Ségou',
	Sagalo: 'Kayes',
	Mopti: 'Mopti',
	Markala: 'Ségou',
	Koutiala: 'Sikasso',
	Koulikoro: 'Koulikoro',
	Kolokani: 'Koulikoro',
	'Ké-Macina': 'Ségou',
	Kati: 'Koulikoro',
	Kangaba: 'Koulikoro',
	Gao: 'Gao',
	Djénné: 'Mopti',
	Bougouni: 'Sikasso',
	Banamba: 'Koulikoro',
	Bamako: 'Bamako',
	Bafoulabé: 'Kayes',
	Yenangyaung: 'Magway',
	Nyaungdon: 'Ayeyarwady',
	Yamethin: 'Mandalay',
	Wakema: 'Ayeyarwady',
	Twante: 'Yangon',
	Taungoo: 'Bago',
	Thongwa: 'Yangon',
	Thayetmyo: 'Magway',
	Thaton: 'Mon',
	Tharyarwady: 'Bago',
	Thanatpin: 'Bago',
	Dawei: 'Tanintharyi',
	Taunggyi: 'Shan',
	Taungdwingyi: 'Magway',
	Tachilek: 'Shan',
	Syriam: 'Yangon',
	Sittwe: 'Rakhine',
	Shwebo: 'Sagain',
	Sagaing: 'Sagain',
	Yangon: 'Yangon',
	Pyu: 'Bago',
	Pyinmana: 'Nay Pyi Taw',
	Pyay: 'Bago',
	Pyapon: 'Ayeyarwady',
	Bago: 'Bago',
	Paungde: 'Bago',
	Pakokku: 'Magway',
	'Hpa-An': 'Kayin',
	Nyaunglebin: 'Bago',
	Myitkyina: 'Kachin',
	Myingyan: 'Mandalay',
	Myawadi: 'Kayin',
	Myanaung: 'Ayeyarwady',
	Mudon: 'Mon',
	Mawlamyinegyunn: 'Ayeyarwady',
	Mawlamyine: 'Mon',
	Monywa: 'Sagain',
	Mogok: 'Mandalay',
	Minbu: 'Magway',
	Myeik: 'Tanintharyi',
	Meiktila: 'Mandalay',
	'Pyin Oo Lwin': 'Mandalay',
	Mawlaik: 'Sagain',
	Maubin: 'Ayeyarwady',
	Martaban: 'Mon',
	Mandalay: 'Mandalay',
	Magway: 'Magway',
	Loikaw: 'Kayah',
	Letpandan: 'Bago',
	Lashio: 'Shan',
	Kyaukse: 'Mandalay',
	Kyaikto: 'Mon',
	Kyaiklat: 'Ayeyarwady',
	Kyaikkami: 'Mon',
	'Kēng Tung': 'Shan',
	Kayan: 'Yangon',
	Kawthoung: 'Tanintharyi',
	Kanbe: 'Yangon',
	Hinthada: 'Ayeyarwady',
	Hakha: 'Chin',
	Chauk: 'Magway',
	Bogale: 'Ayeyarwady',
	Bhamo: 'Kachin',
	Pathein: 'Ayeyarwady',
	Myaydo: 'Magway',
	'Nay Pyi Taw': 'Nay Pyi Taw',
	'Kyain Seikgyi Township': 'Kayin',
	Uliastay: 'Dzabkhan',
	Ulaangom: 'Uvs Province',
	Ölgii: 'Bayan-Ölgiy',
	Khovd: 'Hovd',
	Altai: 'Govi-Altai Province',
	'Ulan Bator': 'Ulaanbaatar',
	Tsetserleg: 'Arkhangai Province',
	Sühbaatar: 'Selenge',
	Undurkhaan: 'Hentiy',
	Mörön: 'Khövsgöl Province',
	Hovd: 'Övörhangay',
	Erdenet: 'Orhon',
	Dzuunmod: 'Central Aimak',
	Dzüünharaa: 'Selenge',
	Darhan: 'Darhan Uul',
	Dalandzadgad: 'Ömnögovĭ',
	Saynshand: 'East Gobi Aymag',
	Bulgan: 'Bulgan',
	Bayanhongor: 'Bayanhongor',
	'Baruun-Urt': 'Sühbaatar',
	Arvayheer: 'Övörhangay',
	Zuunmod: 'Central Aimak',
	Saipan: 'Saipan',
	'Saint-Joseph': 'Réunion',
	'Sainte-Marie': 'Réunion',
	'Le Robert': 'Martinique',
	'Le Lamentin': 'Martinique',
	'Le François': 'Martinique',
	'La Trinité': 'Martinique',
	'Fort-de-France': 'Martinique',
	Ducos: 'Martinique',
	Zouerate: 'Tiris Zemmour',
	Tékane: 'Trarza',
	Sélibaby: 'Guidimaka',
	Rosso: 'Trarza',
	Nouakchott: '',
	Nouadhibou: 'Dakhlet Nouadhibou',
	Néma: 'Hodh Ech Chargi',
	Kiffa: 'Assaba',
	Kaédi: 'Gorgol',
	Bogué: 'Brakna',
	Atar: 'Adrar',
	Aleg: 'Brakna',
	'Tevragh Zeina': 'Nouakchott Ouest',
	'Dar Naim': 'Nouakchott Nord',
	Brades: 'Saint Peter',
	Żabbar: 'Ħaż-Żabbar',
	Valletta: 'Valletta',
	Sliema: 'Tas-Sliema',
	'San Pawl il-Baħar': 'Saint Paul’s Bay',
	Qormi: 'Qormi',
	Mosta: 'Il-Mosta',
	Birkirkara: 'Birkirkara',
	Vacoas: 'Plaines Wilhems',
	Triolet: 'Pamplemousses',
	'Saint Pierre': 'Moka',
	'Quatre Bornes': 'Plaines Wilhems',
	'Port Louis': 'Port Louis',
	Mahébourg: 'Grand Port',
	Goodlands: 'Rivière du Rempart',
	Curepipe: 'Plaines Wilhems',
	'Centre de Flacq': 'Flacq',
	'Bel Air Rivière Sèche': 'Flacq',
	'Beau Bassin-Rose Hill': 'Plaines Wilhems',
	Bambous: 'Black River',
	'Le Hochet': 'Pamplemousses',
	Male: 'Kaafu Atoll',
	Karonga: 'Northern Region',
	Zomba: 'Southern Region',
	Salima: 'Central Region',
	Rumphi: 'Northern Region',
	Nsanje: 'Southern Region',
	Nkhotakota: 'Central Region',
	Mzuzu: 'Northern Region',
	Mzimba: 'Northern Region',
	Mulanje: 'Southern Region',
	Mchinji: 'Central Region',
	Mangochi: 'Southern Region',
	Liwonde: 'Southern Region',
	Lilongwe: 'Central Region',
	Kasungu: 'Central Region',
	Dedza: 'Central Region',
	Balaka: 'Southern Region',
	'Ciudad Valles': 'San Luis Potosí',
	Zumpango: 'México',
	'Zumpango del Río': 'Guerrero',
	Zacualtipán: 'Hidalgo',
	Zacatlán: 'Puebla',
	Zacatepec: 'Morelos',
	Yecapixtla: 'Morelos',
	Yautepec: 'Morelos',
	Yajalón: 'Chiapas',
	Xoxocotla: 'Morelos',
	Xonacatlán: 'México',
	Xochitepec: 'Morelos',
	Xochimilco: 'Mexico City',
	'Xicotepec de Juárez': 'Puebla',
	Xico: 'México',
	'Santa Ana Xalmimilulco': 'Puebla',
	'Álvaro Obregón': 'Mexico City',
	Villahermosa: 'Tabasco',
	'Gustavo Adolfo Madero': 'Mexico City',
	Villaflores: 'Chiapas',
	Veracruz: 'Panamá Oeste Province',
	'Venustiano Carranza': 'Mexico City',
	'Valle Hermoso': 'Tamaulipas',
	Umán: 'Yucatán',
	Tuxtla: 'Chiapas',
	'Túxpam de Rodríguez Cano': 'Veracruz',
	Tulum: 'Quintana Roo',
	'Tultitlán de Mariano Escobedo': 'México',
	Tultepec: 'México',
	Tulancingo: 'Hidalgo',
	'Tula de Allende': 'Hidalgo',
	'Santa Rosa Treinta': 'Morelos',
	'San Miguel Topilejo': 'Mexico City',
	Toluca: 'México',
	'San Bernardino Tlaxcalancingo': 'Puebla',
	Tlaquiltenango: 'Morelos',
	'Tlapa de Comonfort': 'Guerrero',
	Tlapacoyan: 'Veracruz',
	Tlalpan: 'Mexico City',
	Tlalnepantla: 'México',
	Tláhuac: 'Mexico City',
	Tizimín: 'Yucatán',
	Tizayuca: 'Hidalgo',
	'Tixtla de Guerrero': 'Guerrero',
	Ticul: 'Yucatán',
	Teziutlan: 'Puebla',
	'Texcoco de Mora': 'México',
	Tequixquiac: 'México',
	Tequisquiapan: 'Querétaro',
	'Cuautitlán Izcalli': 'México',
	Tepotzotlán: 'México',
	'Tepeji del Río de Ocampo': 'Hidalgo',
	Tepeapulco: 'Hidalgo',
	Tepeaca: 'Puebla',
	'Tepatlaxco de Hidalgo': 'Puebla',
	Teopisca: 'Chiapas',
	Teoloyucan: 'México',
	Tenosique: 'Tabasco',
	'Tenango de Arista': 'México',
	Temixco: 'Morelos',
	Teloloapan: 'Guerrero',
	Tecax: 'Yucatán',
	Tehuacán: 'Puebla',
	Tecamachalco: 'Puebla',
	Teapa: 'Tabasco',
	'Taxco de Alarcón': 'Guerrero',
	Tapachula: 'Chiapas',
	Tantoyuca: 'Veracruz',
	Tamuín: 'San Luis Potosí',
	Tampico: 'Tamaulipas',
	Tamazunchale: 'San Luis Potosí',
	Suchiapa: 'Chiapas',
	'Santo Domingo Tehuantepec': 'Oaxaca',
	'Santiago Tuxtla': 'Veracruz',
	Tetla: 'Tlaxcala',
	'Pinotepa Nacional': 'Oaxaca',
	'Santiago Tulantepec': 'Hidalgo',
	Moyotzingo: 'Puebla',
	'Santa María Chimalhuacán': 'México',
	'Santa María Atzompa': 'Oaxaca',
	'Heroica Ciudad de Tlaxiaco': 'Oaxaca',
	'Santa Cruz Xoxocotlán': 'Oaxaca',
	Tecámac: 'México',
	'Santa Ana Chiautempan': 'Tlaxcala',
	Zinacatepec: 'Puebla',
	'San Salvador El Seco': 'Puebla',
	'San Salvador Atenco': 'México',
	'San Pablo de las Salinas': 'México',
	'San Pablo Autopan': 'México',
	Zinacantepec: 'México',
	'San Miguel Coatlinchán': 'México',
	'San Miguel Ajusco': 'Mexico City',
	'San Mateo Atenco': 'México',
	'San Martin Texmelucan de Labastida': 'Puebla',
	Teolocholco: 'Tlaxcala',
	'San Juan Zitlaltepec': 'México',
	'San Juan Ixtayopan': 'Mexico City',
	'San Juan del Río': 'Querétaro',
	Tuxtepec: 'Oaxaca',
	'San Jerónimo': 'México',
	'San Francisco Tlalcilalcalpan': 'México',
	'San Francisco Acuautla': 'México',
	Sanctorum: 'Puebla',
	'San Cristóbal de las Casas': 'Chiapas',
	'San Andrés Tuxtla': 'Veracruz',
	'Salina Cruz': 'Oaxaca',
	Rioverde: 'San Luis Potosí',
	'Ciudad Río Bravo': 'Tamaulipas',
	'Río Blanco': 'Matagalpa Department',
	Reynosa: 'Tamaulipas',
	Reforma: 'Chiapas',
	'Puerto Escondido': 'Oaxaca',
	'Puente de Ixtla': 'Morelos',
	Puebla: 'Baja California',
	'Progreso de Alvaro Obregon': 'Hidalgo',
	Progreso: 'Canelones',
	'Poza Rica de Hidalgo': 'Veracruz',
	Polanco: 'Mexico City',
	'Playa del Carmen': 'Quintana Roo',
	Pijijiapan: 'Chiapas',
	Peto: 'Yucatán',
	Perote: 'Veracruz',
	Paraiso: 'Western Visayas',
	'Papantla de Olarte': 'Veracruz',
	Pánuco: 'Veracruz',
	'Palmarito Tochapan': 'Puebla',
	Palenque: 'Chiapas',
	'Pachuca de Soto': 'Hidalgo',
	'Ozumba de Alzate': 'México',
	'San Pablo Oztotepec': 'Mexico City',
	Oxkutzkab: 'Yucatán',
	Orizaba: 'Veracruz',
	Ometepec: 'Guerrero',
	'Ocozocoautla de Espinosa': 'Chiapas',
	Ocoyoacac: 'México',
	'Ocotlán de Morelos': 'Oaxaca',
	Ocosingo: 'Chiapas',
	Oaxaca: 'Oaxaca',
	'Nuevo Laredo': 'Tamaulipas',
	Nogales: 'Arizona',
	'Nicolás Romero': 'México',
	'Naucalpan de Juárez': 'México',
	Naranjos: 'Veracruz',
	'Nanchital de Lázaro Cárdenas del Río': 'Veracruz',
	Motul: 'Yucatán',
	Motozintla: 'Chiapas',
	Montemorelos: 'Nuevo León',
	'Santiago Momoxpan': 'Puebla',
	'Mixquiahuala de Juarez': 'Hidalgo',
	Misantla: 'Veracruz',
	Miramar: 'Florida',
	Minatitlán: 'Veracruz',
	'Milpa Alta': 'Mexico City',
	'Miahuatlán de Porfirio Díaz': 'Oaxaca',
	Metepec: 'México',
	'Matías Romero': 'Oaxaca',
	'Heroica Matamoros': 'Tamaulipas',
	'Martínez de la Torre': 'Veracruz',
	Mapastepec: 'Chiapas',
	'Magdalena Contreras': 'Mexico City',
	Macuspana: 'Tabasco',
	'Reyes Acozac': 'México',
	'Los Reyes Acaquilpan': 'México',
	'Los Reyes de Juárez': 'Puebla',
	Libres: 'Puebla',
	'Lerma de Villada': 'México',
	'Lerdo de Tejada': 'Veracruz',
	'Las Rosas': 'Chiapas',
	'Las Margaritas': 'Chiapas',
	'Las Choapas': 'Veracruz',
	'Magdalena Atlicpac': 'México',
	'La Isla': 'Veracruz',
	'San Antonio de la Cal': 'Oaxaca',
	Kanasín: 'Yucatán',
	Juchitepec: 'México',
	'Juchitán de Zaragoza': 'Oaxaca',
	'José Cardel': 'Veracruz',
	Jojutla: 'Morelos',
	Jiutepec: 'Morelos',
	'Jáltipan de Morelos': 'Veracruz',
	'Jalpa de Méndez': 'Tabasco',
	Xalatlaco: 'México',
	'Xalapa de Enríquez': 'Veracruz',
	'Izúcar de Matamoros': 'Puebla',
	Izamal: 'Yucatán',
	Ixtepec: 'Oaxaca',
	'Ixtapan de la Sal': 'México',
	Ixtapaluca: 'México',
	Iztapalapa: 'Mexico City',
	Ixtaczoquitlán: 'Veracruz',
	Iztacalco: 'Mexico City',
	Ixmiquilpan: 'Hidalgo',
	'Villa Independencia': 'Veracruz',
	'Iguala de la Independencia': 'Guerrero',
	Hunucmá: 'Yucatán',
	Huixtla: 'Chiapas',
	'Ciudad de Huitzuco': 'Guerrero',
	Huimanguillo: 'Tabasco',
	'Huejutla de Reyes': 'Hidalgo',
	Huejotzingo: 'Puebla',
	Huauchinango: 'Puebla',
	Huatusco: 'Veracruz',
	'Ciudad de Huajuapan de León': 'Oaxaca',
	Frontera: 'Coahuila',
	'Fortín de las Flores': 'Veracruz',
	'Felipe Carrillo Puerto': 'Quintana Roo',
	Escárcega: 'Campeche',
	'Emiliano Zapata': 'México',
	'Ciudad Mante': 'Tamaulipas',
	'Ecatepec de Morelos': 'México',
	Ébano: 'San Luis Potosí',
	Cunduacán: 'Tabasco',
	Cuernavaca: 'Morelos',
	Cuautlancingo: 'Puebla',
	Cuautla: 'Morelos',
	Cuautitlán: 'México',
	'Cuautepec de Hinojosa': 'Hidalgo',
	'Delegación Cuajimalpa de Morelos': 'Mexico City',
	Cozumel: 'Quintana Roo',
	Coyotepec: 'México',
	Coyoacán: 'Mexico City',
	Cosoleacaque: 'Veracruz',
	'Coscomatepec de Bravo': 'Veracruz',
	Cosamaloapan: 'Veracruz',
	Contla: 'Tlaxcala',
	Comitán: 'Chiapas',
	Comalcalco: 'Tabasco',
	Coatzintla: 'Veracruz',
	Coatzacoalcos: 'Veracruz',
	Coatepec: 'Veracruz',
	Coacalco: 'México',
	'Ciudad Victoria': 'Tamaulipas',
	'Ciudad Serdán': 'Puebla',
	'Ciudad Sahagun': 'Hidalgo',
	'Ciudad Nezahualcoyotl': 'México',
	'Ciudad Miguel Alemán': 'Tamaulipas',
	'Ciudad Mendoza': 'Veracruz',
	'Ciudad Madero': 'Tamaulipas',
	'Ciudad Fernández': 'San Luis Potosí',
	'Mexico City': 'Mexico City',
	'Ciudad del Carmen': 'Campeche',
	'Cintalapa de Figueroa': 'Chiapas',
	Cholula: 'Puebla',
	Chilpancingo: 'Guerrero',
	'Chilapa de Álvarez': 'Guerrero',
	Chignahuapan: 'Puebla',
	Chiconcuac: 'México',
	Chicoloapan: 'México',
	'Chichén-Itzá': 'Yucatán',
	'Chiapa de Corzo': 'Chiapas',
	Chetumal: 'Quintana Roo',
	Champotón: 'Campeche',
	Chalco: 'México',
	'Cerro Azul': 'Veracruz',
	Catemaco: 'Veracruz',
	'Carlos A. Carrillo': 'Veracruz',
	'Capulhuac de Mirafuentes': 'México',
	Cancún: 'Quintana Roo',
	Campeche: 'Campeche',
	Calpulalpan: 'Tlaxcala',
	'Cadereyta Jiménez': 'Nuevo León',
	Cadereyta: 'Nuevo León',
	Cacahoatán: 'Chiapas',
	Berriozábal: 'Chiapas',
	Banderilla: 'Veracruz',
	Azcapotzalco: 'Mexico City',
	'Ayutla de los Libres': 'Guerrero',
	Axochiapan: 'Morelos',
	Atlixco: 'Puebla',
	'Atlacomulco de Fabela': 'México',
	'Ciudad López Mateos': 'México',
	Apizaco: 'Tlaxcala',
	Apan: 'Hidalgo',
	'Amozoc de Mota': 'Puebla',
	Amecameca: 'México',
	Alvarado: 'Veracruz',
	Altotonga: 'Veracruz',
	Altepexi: 'Puebla',
	Allende: 'Coahuila',
	Álamo: 'Veracruz',
	Ajalpan: 'Puebla',
	'Agua Dulce': 'Veracruz',
	Actopan: 'Hidalgo',
	'San Lorenzo Acopilco': 'Mexico City',
	Acayucan: 'Veracruz',
	Acatzingo: 'Puebla',
	'Acatlán de Osorio': 'Puebla',
	'Acapulco de Juárez': 'Guerrero',
	Acajete: 'Puebla',
	'San Antonio Acahualco': 'México',
	'Valente Diaz': 'Veracruz',
	'Santiago Tlacotepec': 'México',
	'Tierra Blanca': 'Veracruz',
	'Playas del Rosario': 'Tabasco',
	'Río de Teapa': 'Tabasco',
	Huamantla: 'Tlaxcala',
	'La Magdalena Tlaltelulco': 'Tlaxcala',
	Zacatelco: 'Tlaxcala',
	Tlaxcala: 'Tlaxcala',
	Papalotla: 'Tlaxcala',
	'Villa Vicente Guerrero': 'Tlaxcala',
	Tonalá: 'Jalisco',
	Arriaga: 'Chiapas',
	'San Mateo Huitzilzingo': 'México',
	'San Martín Cuautlalpan': 'México',
	Tulantongo: 'México',
	Tezoyuca: 'México',
	Tepexpan: 'México',
	'Santa María Ajoloapan': 'México',
	'San Andrés Cholula': 'Puebla',
	'Alto Lucero': 'Veracruz',
	'Loma Bonita': 'Oaxaca',
	Isla: 'Veracruz',
	'San Andrés Cuexcontitlán': 'México',
	'San Mateo Otzacatipan': 'México',
	'San Pedro Totoltepec': 'México',
	'Santa María Totoltepec': 'México',
	'San Francisco Cuaxusco': 'México',
	'Benito Juarez': 'Mexico City',
	'Miguel Hidalgo': 'Mexico City',
	Cuauhtémoc: 'Chihuahua',
	Huixquilucan: 'México',
	'Melchor Ocampo': 'México',
	Huilango: 'México',
	'Santiago Teyahualco': 'México',
	'Ojo de Agua': 'México',
	Buenavista: 'Caraga',
	'Miguel Alemán (La Doce)': 'Sonora',
	'Leyva Solano': 'Sinaloa',
	Cihuatlán: 'Jalisco',
	'Lázaro Cárdenas': 'Baja California',
	'Guadalupe Victoria': 'Baja California',
	Zinapécuaro: 'Michoacán',
	'Ixtapa-Zihuatanejo': 'Guerrero',
	Zapotlanejo: 'Jalisco',
	Zapotiltic: 'Jalisco',
	Zapopan: 'Jalisco',
	Zacoalco: 'Jalisco',
	Zacatecas: 'Zacatecas',
	Zacapú: 'Michoacán',
	Yuriria: 'Guanajuato',
	Yurécuaro: 'Michoacán',
	'Villa Hidalgo': 'Jalisco',
	Villagrán: 'Guanajuato',
	'El Pueblito': 'Querétaro',
	García: 'Nuevo León',
	'Ciudad de Villa de Álvarez': 'Colima',
	'Valle de Santiago': 'Guanajuato',
	'Valle de Bravo': 'México',
	Uruapan: 'Michoacán',
	Uriangato: 'Guanajuato',
	Tuzantla: 'Michoacán',
	Tuxpan: 'Jalisco',
	Torreón: 'Coahuila',
	Tlaquepaque: 'Jalisco',
	'Tlajomulco de Zúñiga': 'Jalisco',
	Tijuana: 'Baja California',
	Tesistán: 'Jalisco',
	Tequila: 'Jalisco',
	Tepic: 'Nayarit',
	'Tepatitlán de Morelos': 'Jalisco',
	Tepalcatepec: 'Michoacán',
	Teocaltiche: 'Jalisco',
	'Tejupilco de Hidalgo': 'México',
	'Técpan de Galeana': 'Guerrero',
	Tecomán: 'Colima',
	Tecate: 'Baja California',
	'Tangancícuaro de Arista': 'Michoacán',
	'Tamazula de Gordiano': 'Jalisco',
	Tala: 'Jalisco',
	'Tacámbaro de Codallos': 'Michoacán',
	Sombrerete: 'Zacatecas',
	'Soledad de Graciano Sánchez': 'San Luis Potosí',
	Sayula: 'Jalisco',
	'Santiago Papasquiaro': 'Durango',
	'Santiago Ixcuintla': 'Nayarit',
	'Santa Rosa Jauregui': 'Querétaro',
	'Santa Catarina': 'Nuevo León',
	'Santa Anita': 'Jalisco',
	'San Sebastián el Grande': 'Jalisco',
	'San Nicolás de los Garza': 'Nuevo León',
	'San Miguel el Alto': 'Jalisco',
	'San Miguel de Allende': 'Guanajuato',
	'San Luis Río Colorado': 'Sonora',
	'San Luis Potosí': 'San Luis Potosí',
	'San Luis de la Paz': 'Guanajuato',
	'Cabo San Lucas': 'Baja California Sur',
	'San Juan de los Lagos': 'Jalisco',
	'San José Iturbide': 'Guanajuato',
	'San José del Castillo': 'Jalisco',
	'San José del Cabo': 'Baja California Sur',
	'San Francisco del Rincón': 'Guanajuato',
	'San Francisco de los Romo': 'Aguascalientes',
	'Tlaltenango de Sánchez Román': 'Zacatecas',
	'San Buenaventura': 'México',
	'Nicolás R Casillas': 'Jalisco',
	Salvatierra: 'Guanajuato',
	Saltillo: 'Coahuila',
	'Salinas de Hidalgo': 'San Luis Potosí',
	'Sahuayo de Morelos': 'Michoacán',
	'Ciudad Sabinas Hidalgo': 'Nuevo León',
	Sabinas: 'Coahuila',
	Rosarito: 'Baja California',
	'El Rosario': 'Sinaloa',
	Romita: 'Guanajuato',
	'Rincón de Romos': 'Aguascalientes',
	'Rancho La Gloria': 'Baja California',
	'Ramos Arizpe': 'Coahuila',
	'Santiago de Querétaro': 'Querétaro',
	Puruándiro: 'Michoacán',
	'Purísima de Bustos': 'Guanajuato',
	'Puerto Vallarta': 'Jalisco',
	'Puerto Peñasco': 'Sonora',
	'Piedras Negras': 'Coahuila',
	Petatlán: 'Guerrero',
	'Peribán de Ramos': 'Michoacán',
	Pénjamo: 'Guanajuato',
	Pátzcuaro: 'Michoacán',
	'Parras de la Fuente': 'Coahuila',
	'Paracho de Verduzco': 'Michoacán',
	Palau: 'Coahuila',
	'Pabellón de Arteaga': 'Aguascalientes',
	Ojinaga: 'Chihuahua',
	Ocotlán: 'Jalisco',
	'Nuevo México': 'Jalisco',
	'Nuevo Casas Grandes': 'Chihuahua',
	'Nueva Rosita': 'Coahuila',
	'Nueva Italia de Ruiz': 'Michoacán',
	'Nochistlán de Mejía': 'Zacatecas',
	Navolato: 'Sinaloa',
	Navojoa: 'Sonora',
	Nava: 'Coahuila',
	Moroleón: 'Guanajuato',
	Morelia: 'Michoacán',
	Monterrey: 'Nuevo León',
	Monclova: 'Coahuila',
	Mezcales: 'Nayarit',
	Mexicali: 'Baja California',
	Meoqui: 'Chihuahua',
	'Ciudad Melchor Múzquiz': 'Coahuila',
	Medina: 'Ohio',
	Mazatlán: 'Sinaloa',
	Matehuala: 'San Luis Potosí',
	Matamoros: 'Coahuila',
	Marfil: 'Guanajuato',
	'Maravatío de Ocampo': 'Michoacán',
	'Rodolfo Sánchez Taboada': 'Baja California',
	'Magdalena de Kino': 'Sonora',
	Magdalena: 'Jalisco',
	Madera: 'California',
	'Los Reyes de Salgado': 'Michoacán',
	'Los Mochis': 'Sinaloa',
	Loreto: 'Zacatecas',
	'León de los Aldama': 'Guanajuato',
	'Las Pintas de Arriba': 'Jalisco',
	'La Piedad': 'Michoacán',
	'La Orilla': 'Michoacán',
	'Lagos de Moreno': 'Jalisco',
	'La Cruz': 'Sinaloa',
	'La Barca': 'Jalisco',
	'Juventino Rosas': 'Guanajuato',
	'Ciudad Benito Juárez': 'Nuevo León',
	'Juan Aldama': 'Zacatecas',
	Jocotepec: 'Jalisco',
	'Jiquílpan de Juárez': 'Michoacán',
	'Jerez de García Salinas': 'Zacatecas',
	'Jaral del Progreso': 'Guanajuato',
	Jamay: 'Jalisco',
	Jalostotitlán: 'Jalisco',
	Xalisco: 'Nayarit',
	'Jacona de Plancarte': 'Michoacán',
	'Ixtlán del Río': 'Nayarit',
	Ixtapa: 'Jalisco',
	Irapuato: 'Guanajuato',
	'Huetamo de Núñez': 'Michoacán',
	Huatabampo: 'Sonora',
	'Heróica Zitácuaro': 'Michoacán',
	'Heroica Caborca': 'Sonora',
	Hermosillo: 'Sonora',
	'Heroica Guaymas': 'Sonora',
	Guasave: 'Sinaloa',
	Guanajuato: 'Guanajuato',
	Guamúchil: 'Sinaloa',
	'Ciudad Guadalupe Victoria': 'Durango',
	'Gómez Palacio': 'Durango',
	'Vicente Guerrero': 'Durango',
	'Juan José Ríos': 'Sinaloa',
	'Ciudad General Escobedo': 'Nuevo León',
	'San Pedro Garza García': 'Nuevo León',
	Fresnillo: 'Zacatecas',
	'Francisco I. Madero': 'Coahuila',
	Escuinapa: 'Sinaloa',
	Ensenada: 'Baja California',
	'Encarnación de Díaz': 'Jalisco',
	Empalme: 'Sonora',
	'El Salto': 'Jalisco',
	'Pueblo Nuevo': 'Falcón',
	'El Quince': 'Jalisco',
	'El Grullo': 'Jalisco',
	'Victoria de Durango': 'Durango',
	Culiacán: 'Sinaloa',
	Coyula: 'Jalisco',
	'Villa de Costa Rica': 'Sinaloa',
	Cortazar: 'Guanajuato',
	Compostela: 'Davao',
	Comonfort: 'Guanajuato',
	'Ciudad Obregón': 'Sonora',
	'Ciudad Lerdo': 'Durango',
	'Ciudad Juárez': 'Chihuahua',
	'José Mariano Jiménez': 'Chihuahua',
	'Ciudad Hidalgo': 'Michoacán',
	'Ciudad Guzmán': 'Jalisco',
	'Ciudad Delicias': 'Chihuahua',
	'Ciudad Constitución': 'Baja California Sur',
	Anáhuac: 'Nuevo León',
	'Ciudad Altamirano': 'Guerrero',
	'Ciudad Acuña': 'Coahuila',
	'Ciénega de Flores': 'Nuevo León',
	Chihuahua: 'Chihuahua',
	Chapala: 'Jalisco',
	Celaya: 'Guanajuato',
	Castaños: 'Coahuila',
	Cananea: 'Sonora',
	'Villa Juárez': 'Sinaloa',
	Calvillo: 'Aguascalientes',
	'Víctor Rosales': 'Zacatecas',
	'Autlán de Navarro': 'Jalisco',
	'Atoyac de Álvarez': 'Guerrero',
	'Atotonilco el Alto': 'Jalisco',
	'Ciudad de Armería': 'Colima',
	'Ario de Rosales': 'Michoacán',
	Arcelia: 'Guerrero',
	Arandas: 'Jalisco',
	'Ciudad Apodaca': 'Nuevo León',
	Apatzingán: 'Michoacán',
	'Apaseo el Grande': 'Guanajuato',
	'Apaseo el Alto': 'Guanajuato',
	Ameca: 'Jalisco',
	'Ciudad de Allende': 'Nuevo León',
	Aldama: 'Chihuahua',
	'Ahualulco de Mercado': 'Jalisco',
	Aguascalientes: 'Aguascalientes',
	'Agua Prieta': 'Sonora',
	Acaponeta: 'Nayarit',
	Acámbaro: 'Guanajuato',
	Abasolo: 'Guanajuato',
	Hidalgo: 'Nuevo León',
	'Dolores Hidalgo': 'Guanajuato',
	Guacamayas: 'Michoacán',
	'Ciudad Lázaro Cárdenas': 'Michoacán',
	'San José del Valle': 'Jalisco',
	'Benito Juárez': 'Mexico City',
	'Colonia del Valle': 'Mexico City',
	'Colonia Lindavista': 'México',
	'Colonia Nativitas': 'Mexico City',
	'Las Delicias': 'Baja California',
	'Hacienda Santa Fe': 'Jalisco',
	'Fuentes del Valle': 'México',
	'San Salvador Tizatlalli': 'México',
	'Jardines de la Silla (Jardines)': 'Nuevo León',
	'Santa Teresa': 'México',
	'San Jerónimo Cuatro Vientos': 'México',
	'San Martín Azcatepec': 'México',
	'Fraccionamiento Real Palmas': 'Nuevo León',
	'Pórticos de San Antonio': 'Baja California',
	'Centro Familiar la Soledad': 'Guanajuato',
	'San José Guadalupe Otzacatipan': 'México',
	'Las Pintitas': 'Jalisco',
	'San Antonio Tecómitl': 'Mexico City',
	'Licenciado Benito Juárez': 'Sinaloa',
	'Teotihuacán de Arista': 'México',
	'Jesús del Monte': 'México',
	'San Jorge Pueblo Nuevo': 'México',
	'Manuel Ojinaga': 'Chihuahua',
	'Terrazas del Valle': 'Baja California',
	'La Ermita': 'Guanajuato',
	'Lomas del Sur': 'Jalisco',
	'Parque Industrial Ciudad Mitras': 'Nuevo León',
	'Mitras Poniente': 'Nuevo León',
	'Villa del Prado 2da Sección': 'Baja California',
	'Tres de Mayo': 'Morelos',
	'Don Antonio': 'Hidalgo',
	'Casa Blanca': 'Puebla',
	'La Providencia Siglo XXI': 'Hidalgo',
	'Ampliación San Mateo': 'México',
	'Fraccionamiento Ciudad Olmeca': 'Veracruz',
	'San Rafael Tlanalapan': 'Puebla',
	'Tecámac de Felipe Villanueva': 'México',
	Venceremos: 'Querétaro',
	'Alborada Jaltenco': 'México',
	Crucecita: 'Oaxaca',
	'San Jose de los Olvera': 'Querétaro',
	Zacapu: 'Michoacán',
	'Colonia del Sol': 'Baja California Sur',
	'Tres Valles': 'Veracruz',
	Kuah: 'Kedah',
	Jerantut: 'Pahang',
	Raub: 'Pahang',
	'Batu Pahat': 'Johor',
	'Parit Raja': 'Johor',
	'Pekan Nenas': 'Johor',
	'Pontian Kechil': 'Johor',
	'Kampung Pasir Gudang Baru': 'Johor',
	'Kota Tinggi': 'Johor',
	'Taman Senai': 'Johor',
	Kulai: 'Johor',
	Skudai: 'Johor',
	'Johor Bahru': 'Johor',
	Kluang: 'Johor',
	'Yong Peng': 'Johor',
	Mersing: 'Johor',
	Segamat: 'Johor',
	Tangkak: 'Johor',
	Muar: 'Johor',
	Bakri: 'Johor',
	Labis: 'Johor',
	'Kuala Selangor': 'Selangor',
	'Batang Berjuntai': 'Selangor',
	'Batu Arang': 'Selangor',
	'Shah Alam': 'Selangor',
	Klang: 'Selangor',
	Cukai: 'Terengganu',
	'Kuala Lipis': 'Pahang',
	Papar: 'Sabah',
	'Kota Kinabalu': 'Sabah',
	Donggongon: 'Sabah',
	Putatan: 'Sabah',
	Kinarut: 'Sabah',
	Ranau: 'Sabah',
	Semporna: 'Sabah',
	Beaufort: 'Sabah',
	'Lahad Datu': 'Sabah',
	Sandakan: 'Sabah',
	Keningau: 'Sabah',
	Tawau: 'Sabah',
	Paka: 'Terengganu',
	Kertih: 'Terengganu',
	Kulim: 'Kedah',
	'Bagan Serai': 'Perak',
	Bedong: 'Kedah',
	'Simpang Empat': 'Perak',
	'Kuala Kangsar': 'Perak',
	Ipoh: 'Perak',
	'Gua Musang': 'Kelantan',
	'Kuala Terengganu': 'Terengganu',
	Marang: 'Terengganu',
	Tampin: 'Negeri Sembilan',
	'Alor Gajah': 'Melaka',
	'Kampung Ayer Keroh': 'Melaka',
	'Sungai Udang': 'Melaka',
	Malacca: 'Melaka',
	'Pelabuhan Klang': 'Selangor',
	Banting: 'Selangor',
	Jenjarum: 'Selangor',
	Semenyih: 'Selangor',
	Seremban: 'Negeri Sembilan',
	'Port Dickson': 'Negeri Sembilan',
	Sepang: 'Selangor',
	Bahau: 'Negeri Sembilan',
	'Kuala Pilah': 'Negeri Sembilan',
	Pekan: 'Pahang',
	Mentekab: 'Pahang',
	Temerluh: 'Pahang',
	'Batu Feringgi': 'Penang',
	Butterworth: 'Eastern Cape',
	Perai: 'Penang',
	'Bukit Mertajam': 'Penang',
	'Nibong Tebal': 'Penang',
	'Parit Buntar': 'Perak',
	'Tasek Glugor': 'Penang',
	Serendah: 'Selangor',
	Rawang: 'Selangor',
	'Petaling Jaya': 'Selangor',
	'Kuala Lumpur': 'Kuala Lumpur',
	Ampang: 'Kuala Lumpur',
	'Sabak Bernam': 'Selangor',
	'Sungai Besar': 'Selangor',
	Kuantan: 'Pahang',
	'Batu Gajah': 'Perak',
	Kampar: 'Perak',
	'Tapah Road': 'Perak',
	Bidur: 'Perak',
	Mukah: 'Sarawak',
	Lumut: 'Perak',
	'Teluk Intan': 'Perak',
	Gurun: 'Kedah',
	'Sungai Petani': 'Kedah',
	'Kepala Batas': 'Penang',
	'Tanah Merah': 'Kelantan',
	Kuching: 'Sarawak',
	Simanggang: 'Sarawak',
	Sarikei: 'Sarawak',
	Sibu: 'Sarawak',
	Kangar: 'Perlis',
	Jitra: 'Kedah',
	'Kuala Kedah': 'Kedah',
	'Alor Setar': 'Kedah',
	'Pasir Mas': 'Kelantan',
	'Kota Bharu': 'Kelantan',
	Kudat: 'Sabah',
	Kapit: 'Sarawak',
	Bintulu: 'Sarawak',
	Lawas: 'Sarawak',
	Limbang: 'Sarawak',
	Marudi: 'Sarawak',
	Miri: 'Sarawak',
	'Ulu Tiram': 'Johor',
	'Tanjung Tokong': 'Penang',
	'Tanjung Sepat': 'Selangor',
	'Permatang Kuching': 'Penang',
	Peringat: 'Kelantan',
	'Ladang Seri Kundang': 'Selangor',
	'Pantai Remis': 'Perak',
	Kuang: 'Selangor',
	'Klebang Besar': 'Melaka',
	'Kampung Tanjung Karang': 'Selangor',
	'Kampung Sungai Ara': 'Penang',
	'Kampung Simpang Renggam': 'Johor',
	'Kampong Pangkal Kalong': 'Kelantan',
	'Kampong Masjid Tanah': 'Melaka',
	'Kampong Kadok': 'Kelantan',
	'Kampong Dungun': 'Perak',
	'Kampung Bukit Baharu': 'Melaka',
	'Kampung Baru Subang': 'Selangor',
	'Kampung Baharu Nilai': 'Negeri Sembilan',
	'Kampong Baharu Balakong': 'Selangor',
	'Kampung Ayer Molek': 'Melaka',
	'Bukit Rambai': 'Melaka',
	'Bentong Town': 'Pahang',
	'Batu Berendam': 'Melaka',
	Putrajaya: 'Putrajaya',
	'Bandar Labuan': 'Sabah',
	'Subang Jaya': 'Selangor',
	'Putra Heights': 'Selangor',
	'Xai-Xai': 'Gaza',
	Dondo: 'Sofala',
	Chinde: 'Zambézia',
	Macia: 'Gaza',
	Tete: 'Tete',
	'Ressano Garcia': 'Maputo Province',
	Quelimane: 'Zambézia',
	Pemba: 'Cabo Delgado',
	Nampula: 'Nampula',
	Nacala: 'Nampula',
	Montepuez: 'Cabo Delgado',
	Mocímboa: 'Cabo Delgado',
	Mozambique: 'Nampula',
	Maxixe: 'Inhambane',
	Matola: 'Maputo Province',
	Maputo: 'Maputo City',
	Mandimba: 'Niassa',
	Lichinga: 'Niassa',
	Inhambane: 'Inhambane',
	Cuamba: 'Niassa',
	Chokwé: 'Gaza',
	Chimoio: 'Manica',
	Chibuto: 'Gaza',
	Beira: 'Sofala',
	'António Enes': 'Nampula',
	Mutuáli: 'Nampula',
	'Katima Mulilo': 'Zambezi',
	Windhoek: 'Khomas',
	Swakopmund: 'Erongo',
	Rundu: 'Kavango East',
	Rehoboth: 'Hardap',
	Otjiwarongo: 'Otjozondjupa',
	Oshakati: 'Oshana',
	Okahandja: 'Otjozondjupa',
	Lüderitz: 'Karas',
	Keetmanshoop: 'Karas',
	Katutura: 'Khomas',
	Grootfontein: 'Otjozondjupa',
	Gobabis: 'Omaheke',
	'Walvis Bay': 'Erongo',
	Nouméa: 'South Province',
	'Mont-Dore': 'South Province',
	Dumbéa: 'South Province',
	'Birni N Konni': 'Tahoua',
	Zinder: 'Zinder',
	Tillabéri: 'Tillabéri',
	Tibiri: 'Maradi',
	Tessaoua: 'Maradi',
	Téra: 'Tillabéri',
	Tanout: 'Zinder',
	Tahoua: 'Tahoua',
	Niamey: 'Niamey',
	Nguigmi: 'Diffa',
	Mirriah: 'Zinder',
	Mayahi: 'Maradi',
	Matamey: 'Zinder',
	Maradi: 'Maradi',
	Magaria: 'Zinder',
	Madaoua: 'Tahoua',
	Illéla: 'Tahoua',
	Dosso: 'Dosso',
	Dogondoutchi: 'Dosso',
	Diffa: 'Diffa',
	Dakoro: 'Maradi',
	Ayorou: 'Tillabéri',
	Alaghsas: 'Agadez',
	Agadez: 'Agadez',
	Zuru: 'Kebbi',
	Zungeru: 'Niger',
	Zaria: 'Kaduna',
	Yola: 'Adamawa',
	Yenagoa: 'Bayelsa',
	Wukari: 'Taraba',
	Wudil: 'Kano',
	Warri: 'Delta',
	Uyo: 'Akwa Ibom',
	Uromi: 'Edo',
	Umuahia: 'Abia',
	Ughelli: 'Delta',
	Ugep: 'Cross River',
	Uga: 'Anambra',
	Ubiaja: 'Edo',
	Tegina: 'Niger',
	Tambuwal: 'Sokoto',
	'Talata Mafara': 'Zamfara',
	Takum: 'Benue',
	Suleja: 'Niger',
	Sokoto: 'Sokoto',
	Soba: 'Kaduna',
	Saki: 'Crimea',
	Shagamu: 'Ogun',
	Sapele: 'Delta',
	Rijau: 'Niger',
	Rano: 'Kano',
	Potiskum: 'Yobe',
	'Port Harcourt': 'Rivers',
	Pindiga: 'Gombe',
	Patigi: 'Kwara',
	Pankshin: 'Plateau',
	Ozubulu: 'Anambra',
	Oyo: 'Oyo',
	Oyan: 'Osun',
	Owo: 'Ondo',
	Owerri: 'Imo',
	Otukpa: 'Benue',
	'Otan Ayegbaju': 'Osun',
	Osogbo: 'Osun',
	'Orita-Eruwa': 'Oyo',
	Onitsha: 'Anambra',
	Ondo: 'Ondo',
	Olupona: 'Osun',
	Okuta: 'Kwara',
	Okrika: 'Rivers',
	Okigwe: 'Imo',
	Okene: 'Kogi',
	'Oke Mesi': 'Osun',
	'Oke Ila': 'Ekiti',
	'Ohafia-Ifigh': 'Abia',
	'Ogwashi-Uku': 'Delta',
	Oguta: 'Imo',
	Ogoja: 'Cross River',
	Ogbomoso: 'Oyo',
	Ogaminana: 'Kogi',
	Offa: 'Kwara',
	Ode: 'Ondo',
	Obudu: 'Cross River',
	Obonoma: 'Rivers',
	Numan: 'Adamawa',
	Nsukka: 'Enugu',
	Nnewi: 'Anambra',
	Nkwerre: 'Imo',
	Nkpor: 'Anambra',
	Nguru: 'Yobe',
	Nasarawa: 'Nassarawa',
	Nafada: 'Gombe',
	Mubi: 'Adamawa',
	Moriki: 'Zamfara',
	Monguno: 'Borno',
	Mokwa: 'Niger',
	Modakeke: 'Osun',
	Minna: 'Niger',
	Marte: 'Borno',
	Malumfashi: 'Katsina',
	Makurdi: 'Benue',
	Makoko: 'Lagos',
	Maiduguri: 'Borno',
	Magumeri: 'Borno',
	Lokoja: 'Kogi',
	Lere: 'Kaduna',
	Lekki: 'Lagos',
	Lapai: 'Niger',
	Lalupon: 'Oyo',
	Lagos: 'Faro',
	Lafiagi: 'Kwara',
	Lafia: 'Nassarawa',
	Kwale: 'Delta',
	Kumo: 'Gombe',
	Kumagunnam: 'Yobe',
	Kukawa: 'Borno',
	Kuje: 'FCT',
	Kontagora: 'Niger',
	Kiyawa: 'Jigawa',
	Kisi: 'Oyo',
	Keffi: 'Nassarawa',
	'Kaura Namoda': 'Zamfara',
	'Katsina-Ala': 'Benue',
	Katsina: 'Katsina',
	Kari: 'Bauchi',
	Kano: 'Kano',
	Kamba: 'Kebbi',
	Kaiama: 'Kwara',
	Kagoro: 'Kaduna',
	Kafanchan: 'Kaduna',
	Kaduna: 'Kaduna',
	Kachia: 'Kaduna',
	Kabba: 'Kogi',
	Jos: 'Plateau',
	Jimeta: 'Adamawa',
	Jega: 'Kebbi',
	Jebba: 'Kwara',
	Jalingo: 'Taraba',
	Iwo: 'Osun',
	Isieke: 'Ebonyi',
	'Ise-Ekiti': 'Ekiti',
	'Isanlu-Itedoijowa': 'Kogi',
	Ipoti: 'Ekiti',
	Iperu: 'Ogun',
	Inisa: 'Osun',
	Ilorin: 'Kwara',
	Ilobu: 'Osun',
	Illela: 'Sokoto',
	Ilesa: 'Osun',
	Ilaro: 'Ogun',
	'Ila Orangun': 'Osun',
	'Ikot Ekpene': 'Akwa Ibom',
	Ikom: 'Cross River',
	Ikirun: 'Osun',
	Ikire: 'Osun',
	'Ikere-Ekiti': 'Ekiti',
	Ikeja: 'Lagos',
	'Ijero-Ekiti': 'Ekiti',
	'Ijebu-Ode': 'Ogun',
	'Ijebu-Jesa': 'Osun',
	'Ijebu-Igbo': 'Ogun',
	Ihiala: 'Anambra',
	'Igede-Ekiti': 'Ekiti',
	'Igbo-Ukwu': 'Anambra',
	Igbor: 'Benue',
	'Igbo-Ora': 'Oyo',
	Igboho: 'Oyo',
	Igbeti: 'Oyo',
	'Igbara-Odo': 'Ekiti',
	Ifo: 'Ogun',
	Idanre: 'Ondo',
	Idah: 'Kogi',
	Ibeto: 'Niger',
	Ibadan: 'Oyo',
	Hadejia: 'Jigawa',
	Gwoza: 'Borno',
	Gwarzo: 'Kano',
	Gwaram: 'Jigawa',
	Gwadabawa: 'Sokoto',
	Gusau: 'Zamfara',
	Gummi: 'Zamfara',
	Gumel: 'Jigawa',
	Gombi: 'Adamawa',
	Gombe: 'Gombe',
	Gembu: 'Taraba',
	Geidam: 'Yobe',
	Gbongan: 'Osun',
	Gashua: 'Yobe',
	Garko: 'Gombe',
	Ganye: 'Adamawa',
	Gamboru: 'Borno',
	Funtua: 'Katsina',
	Fiditi: 'Oyo',
	'Ezza-Ohu': 'Ebonyi',
	'Esuk Oron': 'Akwa Ibom',
	Epe: 'Gelderland',
	'Enugu-Ukwu': 'Anambra',
	'Enugu-Ezike': 'Enugu',
	Enugu: 'Enugu',
	'Emure-Ekiti': 'Ekiti',
	Elele: 'Rivers',
	Ekpoma: 'Edo',
	Eket: 'Akwa Ibom',
	Ejigbo: 'Osun',
	'Eha Amufu': 'Enugu',
	Egbe: 'Kogi',
	'Efon-Alaaye': 'Ekiti',
	Effium: 'Ebonyi',
	'Ebute Ikorodu': 'Lagos',
	'Dutsen Wai': 'Kaduna',
	Dutse: 'Jigawa',
	Dukku: 'Gombe',
	Doma: 'Nassarawa',
	Dikwa: 'Borno',
	Deba: 'Gombe',
	Daura: 'Yobe',
	Darazo: 'Bauchi',
	Damboa: 'Borno',
	Damaturu: 'Yobe',
	Calabar: 'Cross River',
	Burutu: 'Delta',
	Bukuru: 'Plateau',
	Buguma: 'Rivers',
	Bonny: 'Rivers',
	'Bode Saadu': 'Kwara',
	Biu: 'Borno',
	'Birnin Kudu': 'Jigawa',
	'Birnin Kebbi': 'Kebbi',
	'Sofo-Birnin-Gwari': 'Kaduna',
	Biliri: 'Gombe',
	Bida: 'Niger',
	'Benin City': 'Edo',
	Bende: 'Abia',
	Beli: 'Taraba',
	Bauchi: 'Bauchi',
	Baro: 'Niger',
	Bama: 'Borno',
	Badagry: 'Lagos',
	Babana: 'Niger',
	Azare: 'Bauchi',
	Awka: 'Anambra',
	Awgu: 'Enugu',
	Auchi: 'Edo',
	Atani: 'Anambra',
	Asaba: 'Delta',
	Argungu: 'Kebbi',
	'Aramoko-Ekiti': 'Ekiti',
	Apomu: 'Osun',
	Anchau: 'Kaduna',
	Amaigbo: 'Abia',
	Akwanga: 'Nassarawa',
	Akure: 'Ondo',
	Aku: 'Enugu',
	Ajaokuta: 'Kogi',
	Agulu: 'Anambra',
	Agbor: 'Delta',
	Afikpo: 'Ebonyi',
	'Ado-Odo': 'Ogun',
	'Ado-Ekiti': 'Ekiti',
	Abuja: 'FCT',
	Abeokuta: 'Ogun',
	Abakaliki: 'Ebonyi',
	Aba: 'Abia',
	'Degema Hulk': 'Rivers',
	Chakwama: 'Jigawa',
	Tipitapa: 'Managua Department',
	Somoto: 'Madriz Department',
	Somotillo: 'Chinandega',
	Siuna: 'North Caribbean Coast',
	'San Rafael del Sur': 'Managua Department',
	Rivas: 'Rivas',
	Rama: 'South Caribbean Coast',
	'Puerto Cabezas': 'North Caribbean Coast',
	Ocotal: 'Nueva Segovia Department',
	'Nueva Guinea': 'South Caribbean Coast',
	Nandaime: 'Granada Department',
	Nagarote: 'León Department',
	Matagalpa: 'Matagalpa Department',
	Masaya: 'Masaya Department',
	Masatepe: 'Masaya Department',
	Managua: 'Managua Department',
	'La Paz Centro': 'León Department',
	Juigalpa: 'Chontales Department',
	Jinotepe: 'Carazo Department',
	Jinotega: 'Jinotega Department',
	Estelí: 'Estelí Department',
	'El Viejo': 'Chinandega',
	'El Crucero': 'Managua Department',
	Diriamba: 'Carazo Department',
	Chinandega: 'Chinandega',
	Chichigalpa: 'Chinandega',
	Camoapa: 'Boaco Department',
	Boaco: 'Boaco Department',
	Bluefields: 'South Caribbean Coast',
	'Ciudad Sandino': 'Managua Department',
	Zwolle: 'Overijssel',
	Zutphen: 'Gelderland',
	Zoetermeer: 'South Holland',
	Zevenaar: 'Gelderland',
	Zeist: 'Utrecht',
	Zeewolde: 'Flevoland',
	Zandvoort: 'North Holland',
	Zaltbommel: 'Gelderland',
	Zaanstad: 'North Holland',
	Zaandam: 'North Holland',
	Woerden: 'Utrecht',
	Wisch: 'Gelderland',
	Winterswijk: 'Gelderland',
	Winschoten: 'Groningen',
	'Wijk bij Duurstede': 'Utrecht',
	Wijchen: 'Gelderland',
	Wierden: 'Overijssel',
	Weesp: 'North Holland',
	Weert: 'Limburg',
	Wassenaar: 'South Holland',
	Wageningen: 'Gelderland',
	Waddinxveen: 'South Holland',
	Waalwijk: 'North Brabant',
	Vught: 'North Brabant',
	Voorschoten: 'South Holland',
	Voorburg: 'South Holland',
	Volendam: 'North Holland',
	Vlissingen: 'Zeeland',
	Vlaardingen: 'South Holland',
	Vianen: 'Utrecht',
	Venray: 'Limburg',
	Venlo: 'Limburg',
	'Velsen-Zuid': 'North Holland',
	Velp: 'Gelderland',
	Veghel: 'North Brabant',
	Veenendaal: 'Utrecht',
	Veendam: 'Groningen',
	Valkenswaard: 'North Brabant',
	Utrecht: 'Utrecht',
	Urk: 'Flevoland',
	Uithoorn: 'North Holland',
	Uden: 'North Brabant',
	Tubbergen: 'Overijssel',
	Tongelre: 'North Brabant',
	Tilburg: 'North Brabant',
	Tiel: 'Gelderland',
	Terneuzen: 'Zeeland',
	Tegelen: 'Limburg',
	Steenwijk: 'Overijssel',
	Steenbergen: 'North Brabant',
	Staphorst: 'Overijssel',
	Stadskanaal: 'Groningen',
	Spijkenisse: 'South Holland',
	Sneek: 'Friesland',
	Sliedrecht: 'South Holland',
	Sittard: 'Limburg',
	'Sint-Oedenrode': 'North Brabant',
	's-Hertogenbosch': 'North Brabant',
	's-Gravenzande': 'South Holland',
	'The Hague': 'South Holland',
	Schijndel: 'North Brabant',
	Schiedam: 'South Holland',
	Schiebroek: 'South Holland',
	Scheveningen: 'South Holland',
	Sassenheim: 'South Holland',
	Rotterdam: 'New York',
	Roosendaal: 'North Brabant',
	Roermond: 'Limburg',
	Rijswijk: 'South Holland',
	Rijen: 'North Brabant',
	Ridderkerk: 'South Holland',
	Rhoon: 'South Holland',
	Rhenen: 'Utrecht',
	Raalte: 'Overijssel',
	Putten: 'Gelderland',
	Purmerend: 'North Holland',
	Pijnacker: 'South Holland',
	Papendrecht: 'South Holland',
	'Oud-Beijerland': 'South Holland',
	Oss: 'North Brabant',
	Oosterhout: 'North Brabant',
	Ommoord: 'South Holland',
	Oldenzaal: 'Overijssel',
	Oisterwijk: 'North Brabant',
	Oegstgeest: 'South Holland',
	Nunspeet: 'Gelderland',
	Nuenen: 'North Brabant',
	'Noordwijk-Binnen': 'South Holland',
	Nijmegen: 'Gelderland',
	Nijkerk: 'Gelderland',
	Nieuwegein: 'Utrecht',
	Naarden: 'North Holland',
	Naaldwijk: 'South Holland',
	Mijdrecht: 'Utrecht',
	Middelharnis: 'South Holland',
	Middelburg: 'Mpumalanga',
	Merenwijk: 'South Holland',
	Meppel: 'Drenthe',
	Medemblik: 'North Holland',
	Maastricht: 'Limburg',
	Maassluis: 'South Holland',
	Maarssen: 'Utrecht',
	Losser: 'Overijssel',
	'Loon op Zand': 'North Brabant',
	Lisse: 'South Holland',
	Lindenholt: 'Gelderland',
	Lichtenvoorde: 'Gelderland',
	Leusden: 'Utrecht',
	Lelystad: 'Flevoland',
	Leiderdorp: 'South Holland',
	Leiden: 'South Holland',
	Leeuwarden: 'Friesland',
	Leerdam: 'Utrecht',
	'Krimpen aan den IJssel': 'South Holland',
	Korrewegwijk: 'Groningen',
	Kerkrade: 'Limburg',
	'Katwijk aan Zee': 'South Holland',
	Kampen: 'Overijssel',
	IJsselstein: 'Utrecht',
	IJmuiden: 'North Holland',
	Huizen: 'North Holland',
	Hoorn: 'North Holland',
	Hoogvliet: 'South Holland',
	Hoogezand: 'Groningen',
	Hoogeveen: 'Drenthe',
	Hoofddorp: 'North Holland',
	'Hoge Vucht': 'North Brabant',
	Hoensbroek: 'Limburg',
	Hilversum: 'North Holland',
	Hillegom: 'South Holland',
	Hengelo: 'Overijssel',
	'Hendrik-Ido-Ambacht': 'South Holland',
	Helmond: 'North Brabant',
	Hellevoetsluis: 'South Holland',
	Heiloo: 'North Holland',
	Heerlen: 'Limburg',
	Heerhugowaard: 'North Holland',
	Heerenveen: 'Friesland',
	Heemstede: 'North Holland',
	Heemskerk: 'North Holland',
	Harlingen: 'Texas',
	Harenkarspel: 'North Holland',
	Harderwijk: 'Gelderland',
	Hardenberg: 'Overijssel',
	Haarlem: 'North Holland',
	Haaksbergen: 'Overijssel',
	'Groot IJsselmonde': 'South Holland',
	Groningen: 'Groningen',
	Groesbeek: 'Gelderland',
	Gouda: 'South Holland',
	Gorinchem: 'South Holland',
	Goirle: 'North Brabant',
	Goes: 'Zeeland',
	Gennep: 'Limburg',
	Gemert: 'North Brabant',
	Geleen: 'Limburg',
	Geldrop: 'North Brabant',
	Geldermalsen: 'Gelderland',
	Geertruidenberg: 'North Brabant',
	Enschede: 'Overijssel',
	Enkhuizen: 'North Holland',
	Emmeloord: 'Flevoland',
	Elst: 'Gelderland',
	Elburg: 'Gelderland',
	Eindhoven: 'North Brabant',
	Eibergen: 'Gelderland',
	Eersel: 'North Brabant',
	Ede: 'Gelderland',
	Duiven: 'Gelderland',
	Dronten: 'Flevoland',
	'Driebergen-Rijsenburg': 'Utrecht',
	Drachten: 'Friesland',
	Dordrecht: 'South Holland',
	Dongen: 'North Brabant',
	Doetinchem: 'Gelderland',
	Diemen: 'North Holland',
	Deventer: 'Overijssel',
	'Den Helder': 'North Holland',
	'De Meern': 'Utrecht',
	Delfzijl: 'Groningen',
	Delft: 'South Holland',
	Dalfsen: 'Overijssel',
	Culemborg: 'Gelderland',
	Cranendonck: 'North Brabant',
	Castricum: 'North Holland',
	'Capelle aan den IJssel': 'South Holland',
	Bussum: 'North Holland',
	Brunssum: 'Limburg',
	Brummen: 'Gelderland',
	Breda: 'North Brabant',
	Boxtel: 'North Brabant',
	Boskoop: 'South Holland',
	Borssele: 'Zeeland',
	Borne: 'Overijssel',
	Bodegraven: 'South Holland',
	Beverwijk: 'North Holland',
	Beuningen: 'Gelderland',
	Best: 'North Brabant',
	Bergschenhoek: 'South Holland',
	Bergeijk: 'North Brabant',
	'Bergen op Zoom': 'North Brabant',
	Bergen: 'Vestland',
	Benthuizen: 'South Holland',
	Barneveld: 'Gelderland',
	Barendrecht: 'South Holland',
	Baarn: 'Utrecht',
	Assendelft: 'North Holland',
	Assen: 'Drenthe',
	Arnhem: 'Gelderland',
	Apeldoorn: 'Gelderland',
	Amsterdam: 'New York',
	Amstelveen: 'North Holland',
	Amersfoort: 'Utrecht',
	'Alphen aan den Rijn': 'South Holland',
	'Almere Stad': 'Flevoland',
	Almelo: 'Overijssel',
	Alkmaar: 'North Holland',
	Alblasserdam: 'South Holland',
	Aalten: 'Gelderland',
	Aalsmeer: 'North Holland',
	'Amsterdam-Zuidoost': 'North Holland',
	'Berkel en Rodenrijs': 'South Holland',
	Ypenburg: 'South Holland',
	Alta: 'Troms og Finnmark',
	Trondheim: 'Trøndelag',
	Tromsø: 'Troms og Finnmark',
	Tønsberg: 'Vestfold og Telemark',
	Steinkjer: 'Trøndelag',
	Stavanger: 'Rogaland',
	Skien: 'Vestfold og Telemark',
	Sarpsborg: 'Viken',
	Sandnes: 'Rogaland',
	Sandefjord: 'Vestfold og Telemark',
	Porsgrunn: 'Vestfold og Telemark',
	Oslo: 'Oslo',
	Nesttun: 'Vestland',
	Moss: 'Viken',
	Molde: 'Møre og Romsdal',
	'Mo i Rana': 'Nordland',
	Lillestrøm: 'Viken',
	Lillehammer: 'Innlandet',
	Levanger: 'Trøndelag',
	Larvik: 'Vestfold og Telemark',
	Kristiansund: 'Møre og Romsdal',
	Kristiansand: 'Agder',
	Kongsvinger: 'Innlandet',
	Kongsberg: 'Viken',
	Horten: 'Vestfold og Telemark',
	Haugesund: 'Rogaland',
	Harstad: 'Troms og Finnmark',
	Hamar: 'Innlandet',
	Halden: 'Viken',
	Grimstad: 'Agder',
	Gjøvik: 'Innlandet',
	Fredrikstad: 'Viken',
	Drammen: 'Viken',
	Bodø: 'Nordland',
	Asker: 'Viken',
	Arendal: 'Agder',
	Ålesund: 'Møre og Romsdal',
	Ytrebygda: 'Vestland',
	Wāliṅ: 'Province 4',
	Ṭikāpur: 'Sudurpashchim Pradesh',
	Tānsen: 'Lumbini Province',
	Siraha: 'Province 2',
	Rājbirāj: 'Province 2',
	Pokhara: 'Province 4',
	Panauti̇̄: 'Bagmati Province',
	Malaṅgawā: 'Province 2',
	Mahendranagar: 'Sudurpashchim Pradesh',
	Lahān: 'Province 2',
	Kirtipur: 'Bagmati Province',
	Khā̃dbāri̇̄: 'Province 1',
	Kathmandu: 'Bagmati Province',
	Janakpur: 'Province 2',
	Īṭahari̇̄: 'Province 1',
	Ilām: 'Province 1',
	Hetauda: 'Bagmati Province',
	Gulariyā: 'Lumbini Province',
	Gaur: 'Province 2',
	Dhulikhel: 'Bagmati Province',
	Dharān: 'Province 1',
	Dhankutā: 'Province 1',
	Dhangaḍhi̇̄: 'Sudurpashchim Pradesh',
	Dārchulā: 'Sudurpashchim Pradesh',
	Dailekh: 'Karnali Pradesh',
	Dadeldhurā: 'Sudurpashchim Pradesh',
	Butwāl: 'Lumbini Province',
	Birgañj: 'Province 2',
	Biratnagar: 'Province 1',
	Siddharthanagar: 'Lumbini Province',
	Bhadrapur: 'Province 1',
	Banepā: 'Bagmati Province',
	Bāglung: 'Province 4',
	Birendranagar: 'Karnali Pradesh',
	Dipayal: 'Sudurpashchim Pradesh',
	Nepalgunj: 'Lumbini Province',
	Panauti: 'Bagmati Province',
	Inaruwa: 'Province 1',
	Triyuga: 'Province 1',
	'Madhyapur Thimi': 'Bagmati Province',
	Yaren: 'Yaren',
	Alofi: '',
	Whanganui: 'Manawatu-Wanganui',
	Wainuiomata: 'Wellington',
	Timaru: 'Canterbury',
	Taupo: 'Waikato',
	Stoke: 'Nelson',
	Rolleston: 'Canterbury',
	'Pukekohe East': 'Auckland',
	Porirua: 'Wellington',
	Paraparaumu: 'Wellington',
	'Palmerston North': 'Manawatu-Wanganui',
	'North Shore': 'Auckland',
	'New Plymouth': 'Taranaki',
	Napier: "Hawke's Bay",
	'Manukau City': 'Auckland',
	Mangere: 'Auckland',
	'Lower Hutt': 'Wellington',
	Karori: 'Wellington',
	Invercargill: 'Southland',
	Dunedin: 'Florida',
	Avondale: 'Arizona',
	Auckland: 'Auckland',
	Levin: 'Manawatu-Wanganui',
	Gisborne: 'Gisborne',
	Masterton: 'Wellington',
	Tauranga: 'Bay of Plenty',
	Papakura: 'Auckland',
	Whakatane: 'Bay of Plenty',
	Ashburton: 'Canterbury',
	Rangiora: 'Canterbury',
	Whangarei: 'Northland',
	Takanini: 'Auckland',
	'Mangere East': 'Auckland',
	Papatoetoe: 'Auckland',
	Otahuhu: 'Auckland',
	Manurewa: 'Auckland',
	Otara: 'Auckland',
	'Blockhouse Bay': 'Auckland',
	'New Lynn': 'Auckland',
	Remuera: 'Auckland',
	'Massey East': 'Auckland',
	'Te Atatu South': 'Auckland',
	Massey: 'Auckland',
	Rotorua: 'Bay of Plenty',
	Blenheim: 'Marlborough',
	'Upper Hutt': 'Wellington',
	'Te Aro': 'Wellington',
	'Linton Military Camp': 'Manawatu-Wanganui',
	Sur: 'Southeastern Governorate',
	Sohar: 'Al Batinah North',
	'Sufālat Samā’il': 'Ad Dakhiliyah',
	Shināş: 'Al Batinah North',
	Şalālah: 'Dhofar',
	Şaḩam: 'Al Batinah North',
	Nizwá: 'Ad Dakhiliyah',
	Muscat: 'Muscat',
	Khasab: 'Musandam Governorate',
	Izkī: 'Ad Dakhiliyah',
	'‘Ibrī': 'Ad Dhahirah',
	'Ibrā’': 'Northeastern Governorate',
	Bidbid: 'Ad Dakhiliyah',
	Bawshar: 'Muscat',
	'Barkā’': 'Al Batinah South',
	'Bahlā’': 'Ad Dakhiliyah',
	Badīyah: 'Northeastern Governorate',
	'As Suwayq': 'Al Batinah North',
	Seeb: 'Muscat',
	Rustaq: 'Al Batinah South',
	Liwá: 'Al Batinah North',
	'Al Khābūrah': 'Al Batinah North',
	'Al Buraymī': 'Al Buraimi',
	Adam: 'Ad Dakhiliyah',
	Yanqul: 'Ad Dhahirah',
	'Oman Smart Future City': 'Al Batinah South',
	Tocumen: 'Panamá',
	'Santiago de Veraguas': 'Veraguas',
	'San Miguelito': 'Panamá',
	Sabanitas: 'Colón',
	'Río Abajo': 'Panamá',
	Penonomé: 'Coclé',
	Pedregal: 'Chiriquí Province',
	'Parque Lefevre': 'Panamá',
	Panamá: 'Panamá',
	'Las Cumbres': 'Panamá',
	'La Concepción': 'Zulia',
	'La Chorrera': 'Panamá Oeste Province',
	'La Cabima': 'Panamá',
	'Juan Díaz': 'Panamá',
	'El Chorrillo': 'Panamá',
	David: 'Chiriquí Province',
	Curundú: 'Panamá',
	Chilibre: 'Panamá',
	Changuinola: 'Bocas del Toro Province',
	Cativá: 'Colón',
	Arraiján: 'Panamá Oeste Province',
	Ancón: 'Panamá',
	'Alcalde Díaz': 'Panamá',
	'Nuevo Belén': 'Panamá',
	Yurimaguas: 'Loreto',
	Virú: 'La Libertad',
	Uchiza: 'San Martín',
	Tumbes: 'Tumbes',
	Trujillo: 'Trujillo',
	Tocache: 'San Martín',
	'Tingo María': 'Huanuco',
	'Tambo Grande': 'Piura',
	Talara: 'Piura',
	Sullana: 'Piura',
	Sechura: 'Piura',
	'Santiago de Cao': 'La Libertad',
	'San Pedro de Lloc': 'La Libertad',
	Saña: 'Lambayeque',
	Rioja: 'San Martín',
	Querecotillo: 'Piura',
	Pucallpa: 'Ucayali',
	Piura: 'Piura',
	Pimentel: 'Lambayeque',
	Picsi: 'Lambayeque',
	Pátapo: 'Lambayeque',
	Paita: 'Piura',
	Paiján: 'La Libertad',
	Pacasmayo: 'La Libertad',
	Moyobamba: 'San Martín',
	Monsefú: 'Lambayeque',
	Moche: 'La Libertad',
	Marcavelica: 'Piura',
	Laredo: 'Texas',
	'La Peca': 'Amazonas',
	Lambayeque: 'Lambayeque',
	Juanjuí: 'San Martín',
	Iquitos: 'Loreto',
	Huaraz: 'Ancash',
	Huánuco: 'Huanuco',
	Huamachuco: 'La Libertad',
	Ferreñafe: 'Lambayeque',
	Coishco: 'Ancash',
	Chulucanas: 'Piura',
	Chongoyape: 'Lambayeque',
	Chocope: 'La Libertad',
	Chimbote: 'Ancash',
	Chiclayo: 'Lambayeque',
	Chepén: 'La Libertad',
	Chachapoyas: 'Amazonas',
	Catacaos: 'Piura',
	Cajamarca: 'Cajamarca',
	Bellavista: 'San Martín',
	'Bagua Grande': 'Amazonas',
	'La Breita': 'Piura',
	Zarumilla: 'Tumbes',
	Belen: 'Hatay',
	Yunguyo: 'Puno',
	Yauri: 'Cusco',
	Yanacancha: 'Pasco',
	Tarma: 'Junin',
	Tambopata: 'Madre de Dios',
	Tacna: 'Tacna',
	'Santiago de Surco': 'Lima region',
	Sicuani: 'Cusco',
	Satipo: 'Junin',
	'San Vicente de Cañete': 'Lima region',
	'Santa Ana': 'Táchira',
	'San Clemente': 'California',
	Puno: 'Puno',
	'Puerto Maldonado': 'Madre de Dios',
	Pisco: 'Ica',
	Paramonga: 'Lima region',
	'Nuevo Imperial': 'Lima region',
	Nazca: 'Ica',
	Moquegua: 'Moquegua',
	Mollendo: 'Arequipa',
	'Minas de Marcona': 'Ica',
	Mala: 'Lima region',
	Lima: 'Ohio',
	'La Oroya': 'Junin',
	Juliaca: 'Puno',
	Jauja: 'Junin',
	Imperial: 'California',
	Ilo: 'Moquegua',
	Ilave: 'Puno',
	Ica: 'Ica',
	Huaura: 'Lima region',
	Huarmey: 'Ancash',
	Huaral: 'Lima region',
	Huanta: 'Ayacucho',
	Huancayo: 'Junin',
	Huancavelica: 'Huancavelica',
	Hualmay: 'Lima region',
	Huacho: 'Lima region',
	Cusco: 'Cusco',
	Chosica: 'Lima region',
	'Chincha Alta': 'Ica',
	Chaupimarca: 'Pasco',
	Chancay: 'Lima region',
	'Cerro de Pasco': 'Pasco',
	Camaná: 'Arequipa',
	Callao: 'Callao',
	Barranca: 'Lima region',
	Ayaviri: 'Puno',
	Ayacucho: 'Ayacucho',
	Arequipa: 'Arequipa',
	Andahuaylas: 'Apurímac',
	Abancay: 'Apurímac',
	'Jacobo Hunter': 'Arequipa',
	'Santa Anita - Los Ficus': 'Lima region',
	'San Martin': 'Piura',
	'San Francisco De Borja': 'Lima region',
	'Ciudad Satelite': 'Arequipa',
	Chilca: 'Junin',
	'Jesus Maria': 'Lima region',
	Wanchaq: 'Cusco',
	'Cono Norte': 'Arequipa',
	'Carmen De La Legua Reynoso': 'Callao',
	'La Banda': 'San Martín',
	'El Pedregal': 'Arequipa',
	'Selva Alegre': 'Arequipa',
	Breña: 'Lima region',
	Punaauia: 'Îles du Vent',
	Papeete: 'Îles du Vent',
	Faaa: 'Îles du Vent',
	Wewak: 'East Sepik',
	'Port Moresby': 'National Capital',
	Popondetta: 'Northern Province',
	'Mount Hagen': 'Western Highlands',
	Mendi: 'Southern Highlands',
	Madang: 'Madang',
	Lae: 'Morobe',
	Kokopo: 'East New Britain',
	Kimbe: 'West New Britain',
	Goroka: 'Eastern Highlands',
	Daru: 'Western Province',
	Bulolo: 'Morobe',
	Arawa: 'Bougainville',
	Zamboanga: 'Zamboanga Peninsula',
	Wao: 'Autonomous Region in Muslim Mindanao',
	Virac: 'Bicol',
	Vigan: 'Ilocos',
	Victorias: 'Western Visayas',
	Veruela: 'Caraga',
	'NIA Valencia': 'Northern Mindanao',
	Urdaneta: 'Ilocos',
	Ualog: 'Western Visayas',
	Tupi: 'Soccsksargen',
	Tunasan: 'Calabarzon',
	Tuguegarao: 'Cagayan Valley',
	Tiwi: 'Bicol',
	Tinongan: 'Western Visayas',
	Teresa: 'Calabarzon',
	Telabastagan: 'Central Luzon',
	Taytay: 'Calabarzon',
	Tayabas: 'Calabarzon',
	'Tarlac City': 'Central Luzon',
	Tanza: 'Metro Manila',
	Tanjay: 'Central Visayas',
	Tangub: 'Northern Mindanao',
	Tandag: 'Caraga',
	Tanay: 'Calabarzon',
	Tanauan: 'Eastern Visayas',
	Taloc: 'Western Visayas',
	Talisay: 'Central Visayas',
	Talavera: 'Central Luzon',
	Talacogon: 'Caraga',
	'Magugpo Poblacion': 'Davao',
	Taguig: 'Metro Manila',
	Tagudin: 'Ilocos',
	Tagoloan: 'Northern Mindanao',
	'Tagbilaran City': 'Central Visayas',
	Tagaytay: 'Calabarzon',
	Tagas: 'Bicol',
	Tacurong: 'Soccsksargen',
	Tacloban: 'Eastern Visayas',
	Tabuk: 'Tabuk Region',
	Tabaco: 'Bicol',
	Taal: 'Calabarzon',
	Surigao: 'Caraga',
	Surallah: 'Soccsksargen',
	Sumag: 'Western Visayas',
	Subic: 'Central Luzon',
	Suay: 'Western Visayas',
	Sorsogon: 'Bicol',
	Solano: 'Cagayan Valley',
	Solana: 'Cagayan Valley',
	Sitangkai: 'Autonomous Region in Muslim Mindanao',
	Sipalay: 'Western Visayas',
	Silang: 'Calabarzon',
	Sibulan: 'Central Visayas',
	Sexmoan: 'Central Luzon',
	'Lake Sebu': 'Soccsksargen',
	Sariaya: 'Calabarzon',
	'Santo Tomas': 'Calabarzon',
	Santol: 'Central Luzon',
	'Santa Catalina': 'Central Visayas',
	'Santa Barbara': 'California',
	'San Simon': 'Central Luzon',
	'San Pascual': 'Calabarzon',
	'San Narciso': 'Central Luzon',
	'San Mateo': 'Aragua',
	'San Mariano': 'Davao',
	'San Marcelino': 'Central Luzon',
	'San Leonardo': 'Central Luzon',
	'San Jose del Monte': 'Central Luzon',
	'San Jose': 'California',
	'San Ildefonso': 'Central Luzon',
	Sampaloc: 'Calabarzon',
	Samal: 'Davao',
	Sagay: 'Western Visayas',
	Sablayan: 'Mimaropa',
	Roxas: 'Mimaropa',
	'Roxas City': 'Western Visayas',
	Romblon: 'Mimaropa',
	Rizal: 'Central Luzon',
	Recodo: 'Zamboanga Peninsula',
	Ramos: 'Central Luzon',
	Ramon: 'Cagayan Valley',
	Quiapo: 'Metro Manila',
	'Quezon City': 'Metro Manila',
	Quezon: 'Northern Mindanao',
	Pulupandan: 'Western Visayas',
	'Pulong Santa Cruz': 'Calabarzon',
	Pulilan: 'Central Luzon',
	'Puerto Princesa': 'Mimaropa',
	'Port Area': 'Metro Manila',
	Porac: 'Central Luzon',
	Polomolok: 'Soccsksargen',
	Polangui: 'Bicol',
	Plaridel: 'Central Luzon',
	Pio: 'Central Luzon',
	Pinamungahan: 'Central Visayas',
	Pinamalayan: 'Mimaropa',
	Pililla: 'Calabarzon',
	Pila: 'Calabarzon',
	Peñaranda: 'Central Luzon',
	Patuto: 'Calabarzon',
	Passi: 'Western Visayas',
	Pasay: 'Metro Manila',
	Parang: 'Autonomous Region in Muslim Mindanao',
	'Paranaque City': 'Metro Manila',
	Papaya: 'Central Luzon',
	Paombong: 'Central Luzon',
	Pantubig: 'Central Luzon',
	Paniqui: 'Central Luzon',
	Pangil: 'Calabarzon',
	Pandi: 'Central Luzon',
	Pandacaqui: 'Central Luzon',
	Panalanoy: 'Eastern Visayas',
	Panabo: 'Davao',
	Palo: 'Eastern Visayas',
	'Palayan City': 'Central Luzon',
	Pagbilao: 'Calabarzon',
	Pagaluñgan: 'Autonomous Region in Muslim Mindanao',
	Pagadian: 'Zamboanga Peninsula',
	Paete: 'Calabarzon',
	Pacol: 'Western Visayas',
	Paco: 'Metro Manila',
	'Ozamiz City': 'Northern Mindanao',
	Oroquieta: 'Northern Mindanao',
	Ormoc: 'Eastern Visayas',
	Orion: 'Central Luzon',
	Orani: 'Central Luzon',
	Olongapo: 'Central Luzon',
	Obando: 'Central Luzon',
	Noveleta: 'Calabarzon',
	Norzagaray: 'Central Luzon',
	'New Corella': 'Davao',
	Navotas: 'Metro Manila',
	Nasugbu: 'Calabarzon',
	Narra: 'Mimaropa',
	Naic: 'Calabarzon',
	Nagcarlan: 'Calabarzon',
	Naga: 'Central Visayas',
	Nabunturan: 'Davao',
	Nabua: 'Bicol',
	Muricay: 'Zamboanga Peninsula',
	Muñoz: 'Central Luzon',
	Morong: 'Calabarzon',
	Rodriguez: 'Calabarzon',
	Monkayo: 'Davao',
	Molave: 'Zamboanga Peninsula',
	Minglanilla: 'Central Visayas',
	Midsayap: 'Soccsksargen',
	Meycauayan: 'Central Luzon',
	Mexico: 'Central Luzon',
	'Mendez-Nuñez': 'Calabarzon',
	Mauban: 'Calabarzon',
	Mati: 'Davao',
	Masinloc: 'Central Luzon',
	Masbate: 'Bicol',
	Masantol: 'Central Luzon',
	Mariveles: 'Central Luzon',
	Marilao: 'Central Luzon',
	Mariano: 'Northern Mindanao',
	'Marawi City': 'Autonomous Region in Muslim Mindanao',
	Maramag: 'Northern Mindanao',
	Maragondon: 'Calabarzon',
	Mantampay: 'Northern Mindanao',
	Mansilingan: 'Western Visayas',
	Mansalay: 'Mimaropa',
	'Manolo Fortich': 'Northern Mindanao',
	Manila: 'Metro Manila',
	'Manibaug Pasig': 'Central Luzon',
	Mangaldan: 'Ilocos',
	'Mandaue City': 'Central Visayas',
	'Mandaluyong City': 'Metro Manila',
	Mankayan: 'Cordillera',
	Manay: 'Davao',
	Manapla: 'Western Visayas',
	Manaoag: 'Ilocos',
	Mamburao: 'Mimaropa',
	Mamatid: 'Calabarzon',
	Malvar: 'Calabarzon',
	Maluso: 'Autonomous Region in Muslim Mindanao',
	Maluñgun: 'Soccsksargen',
	Malolos: 'Central Luzon',
	Malita: 'Davao',
	Malilipot: 'Bicol',
	Malaybalay: 'Northern Mindanao',
	Malapatan: 'Soccsksargen',
	Malanday: 'Calabarzon',
	'Malabanban Norte': 'Calabarzon',
	'Makati City': 'Metro Manila',
	Mahayag: 'Zamboanga Peninsula',
	Magsaysay: 'Davao',
	Magarao: 'Bicol',
	Maganoy: 'Autonomous Region in Muslim Mindanao',
	Magalang: 'Central Luzon',
	'Mabalacat City': 'Central Luzon',
	Maasin: 'Eastern Visayas',
	Maao: 'Western Visayas',
	Lupon: 'Davao',
	Lumbang: 'Calabarzon',
	Lucban: 'Calabarzon',
	'Los Baños': 'Calabarzon',
	Lopez: 'Calabarzon',
	'Loma de Gato': 'Central Luzon',
	Loboc: 'Central Visayas',
	'Lipa City': 'Calabarzon',
	Lingayen: 'Ilocos',
	Liloan: 'Central Visayas',
	Lilio: 'Calabarzon',
	Libon: 'Bicol',
	Libertad: 'Caraga',
	Legaspi: 'Bicol',
	Laur: 'Central Luzon',
	'La Trinidad': 'Cordillera',
	'Las Piñas': 'Metro Manila',
	'Lapu-Lapu City': 'Central Visayas',
	Laoang: 'Eastern Visayas',
	Laoag: 'Ilocos',
	Laguilayan: 'Soccsksargen',
	'La Castellana': 'Western Visayas',
	'La Carlota': 'Western Visayas',
	Labo: 'Bicol',
	Koronadal: 'Soccsksargen',
	Kidapawan: 'Soccsksargen',
	Kawit: 'Calabarzon',
	'Kalibo (poblacion)': 'Western Visayas',
	Kabankalan: 'Western Visayas',
	Kabacan: 'Soccsksargen',
	'Jose Pañganiban': 'Bicol',
	Jolo: 'Autonomous Region in Muslim Mindanao',
	Jasaan: 'Northern Mindanao',
	Jalajala: 'Calabarzon',
	Jagna: 'Central Visayas',
	Jaen: 'Central Luzon',
	Itogon: 'Cordillera',
	Isulan: 'Soccsksargen',
	Isabela: 'Western Visayas',
	'City of Isabela': 'Autonomous Region in Muslim Mindanao',
	Irosin: 'Bicol',
	'Iriga City': 'Bicol',
	Ipil: 'Zamboanga Peninsula',
	Indang: 'Calabarzon',
	Imus: 'Calabarzon',
	Iloilo: 'Western Visayas',
	'Iligan City': 'Soccsksargen',
	Iligan: 'Northern Mindanao',
	Ilagan: 'Cagayan Valley',
	Iba: 'Central Luzon',
	Hinigaran: 'Western Visayas',
	Himamaylan: 'Western Visayas',
	Hermosa: 'Central Luzon',
	Hagonoy: 'Central Luzon',
	Guyong: 'Central Luzon',
	Gumaca: 'Calabarzon',
	'Guiset East': 'Ilocos',
	Guimba: 'Central Luzon',
	Guihulñgan: 'Central Visayas',
	Guiguinto: 'Central Luzon',
	Goa: 'Bicol',
	Glan: 'Soccsksargen',
	Gerona: 'Central Luzon',
	'General Trias': 'Calabarzon',
	'General Tinio': 'Central Luzon',
	'General Santos': 'Soccsksargen',
	'General Mamerto Natividad': 'Central Luzon',
	Gapan: 'Central Luzon',
	Escalante: 'Western Visayas',
	Saravia: 'Western Visayas',
	Dumaguete: 'Central Visayas',
	'Don Carlos': 'Northern Mindanao',
	Domalanoan: 'Ilocos',
	Dologon: 'Northern Mindanao',
	Dipolog: 'Zamboanga Peninsula',
	Dinalupihan: 'Central Luzon',
	Digos: 'Davao',
	Diadi: 'Cagayan Valley',
	'Del Pilar': 'Central Luzon',
	Davao: 'Davao',
	Dasmariñas: 'Calabarzon',
	Dapitan: 'Zamboanga Peninsula',
	Danao: 'Central Visayas',
	Dagupan: 'Ilocos',
	Daet: 'Bicol',
	Cotabato: 'Autonomous Region in Muslim Mindanao',
	Cordova: 'Tennessee',
	Consolacion: 'Central Visayas',
	Concepcion: 'Central Luzon',
	'Concepcion Ibaba': 'Calabarzon',
	'Cebu City': 'Central Visayas',
	'Cavite City': 'Calabarzon',
	Catbalogan: 'Eastern Visayas',
	Catarman: 'Eastern Visayas',
	Catanauan: 'Calabarzon',
	Castillejos: 'Central Luzon',
	Cogan: 'Central Visayas',
	Carigara: 'Eastern Visayas',
	Cardona: 'Calabarzon',
	Carcar: 'Central Visayas',
	Capas: 'Central Luzon',
	Canlaon: 'Central Visayas',
	Candaba: 'Central Luzon',
	Camiling: 'Central Luzon',
	Calumpit: 'Central Luzon',
	Calumpang: 'Metro Manila',
	'Caloocan City': 'Metro Manila',
	'Calbayog City': 'Eastern Visayas',
	Calauan: 'Calabarzon',
	Calauag: 'Calabarzon',
	Calatagan: 'Calabarzon',
	Calasiao: 'Ilocos',
	Calapan: 'Mimaropa',
	Calamba: 'Calabarzon',
	Calaca: 'Calabarzon',
	Calabanga: 'Bicol',
	Cainta: 'Calabarzon',
	'Cagayan de Oro': 'Northern Mindanao',
	Cabuyao: 'Calabarzon',
	Cabiao: 'Central Luzon',
	Cabayangan: 'Davao',
	'Cabanatuan City': 'Central Luzon',
	Cabagan: 'Cagayan Valley',
	Cabadbaran: 'Caraga',
	Butuan: 'Caraga',
	Bustos: 'Central Luzon',
	Boroon: 'Northern Mindanao',
	Bunawan: 'Caraga',
	Buluan: 'Autonomous Region in Muslim Mindanao',
	Bulaon: 'Central Luzon',
	Bulan: 'Bicol',
	Bulacan: 'Central Luzon',
	Buhi: 'Bicol',
	Bugo: 'Northern Mindanao',
	Budta: 'Autonomous Region in Muslim Mindanao',
	Botolan: 'Central Luzon',
	Borongan: 'Eastern Visayas',
	Bongao: 'Autonomous Region in Muslim Mindanao',
	Bongabon: 'Central Luzon',
	Bocaue: 'Central Luzon',
	Bislig: 'Caraga',
	Binonga: 'Western Visayas',
	Binondo: 'Metro Manila',
	Binmaley: 'Ilocos',
	Binangonan: 'Calabarzon',
	Biñan: 'Calabarzon',
	Binalbagan: 'Western Visayas',
	'Bignay Uno': 'Calabarzon',
	'Upper Bicutan': 'Metro Manila',
	'Bel Air': 'Metro Manila',
	Bayugan: 'Caraga',
	Bayombong: 'Cagayan Valley',
	Baybay: 'Eastern Visayas',
	Bayawan: 'Central Visayas',
	Bayambang: 'Ilocos',
	Bay: 'Calabarzon',
	Bauang: 'Ilocos',
	Bauan: 'Calabarzon',
	Bato: 'Bicol',
	Batangas: 'Calabarzon',
	'Batac City': 'Ilocos',
	Baras: 'Calabarzon',
	Bantayan: 'Central Visayas',
	Bansalan: 'Davao',
	Bañga: 'Soccsksargen',
	Bambang: 'Cagayan Valley',
	Baliuag: 'Central Luzon',
	Balayan: 'Calabarzon',
	Balanga: 'Central Luzon',
	Balamban: 'Central Visayas',
	Balagtas: 'Central Luzon',
	Bais: 'Central Visayas',
	'Bah-Bah': 'Caraga',
	Baguio: 'Cordillera',
	'Bago City': 'Western Visayas',
	'Baggabag B': 'Cagayan Valley',
	Bacoor: 'Calabarzon',
	'Bacolod City': 'Western Visayas',
	Baclaran: 'Calabarzon',
	'Babo-Pangulo': 'Central Luzon',
	Baao: 'Bicol',
	Atimonan: 'Calabarzon',
	Asia: 'Western Visayas',
	Aringay: 'Ilocos',
	Arayat: 'Central Luzon',
	Aparri: 'Cagayan Valley',
	Apalit: 'Central Luzon',
	Antipolo: 'Calabarzon',
	Angono: 'Calabarzon',
	'Angeles City': 'Central Luzon',
	Angat: 'Central Luzon',
	Amadeo: 'Calabarzon',
	Alicia: 'Cagayan Valley',
	Aliaga: 'Central Luzon',
	Alaminos: 'Calabarzon',
	Alabel: 'Soccsksargen',
	Agoo: 'Ilocos',
	Abuyog: 'Eastern Visayas',
	Abucay: 'Central Luzon',
	'Bagong Pagasa': 'Calabarzon',
	Malingao: 'Soccsksargen',
	'Bagong Silangan': 'Metro Manila',
	'Pasig City': 'Metro Manila',
	Pandan: 'Western Visayas',
	'Ayala Alabang': 'Metro Manila',
	'Banco Filipino Homes': 'Metro Manila',
	Apas: 'Central Visayas',
	'Chuhar Jamali': 'Sindh',
	Rawalakot: 'Azad Kashmir',
	'Pir Jo Goth': 'Sindh',
	'Khairpur Mir’s': 'Sindh',
	Zhob: 'Balochistan',
	Zaida: 'Khyber Pakhtunkhwa',
	'Zahir Pir': 'Punjab',
	Zafarwal: 'Punjab',
	Yazman: 'Punjab',
	Wazirabad: 'Punjab',
	'Chak Five Hundred Seventy-five': 'Punjab',
	Warah: 'Sindh',
	Vihari: 'Punjab',
	Utmanzai: 'Khyber Pakhtunkhwa',
	Uthal: 'Balochistan',
	'Usta Muhammad': 'Balochistan',
	Ubauro: 'Sindh',
	Turbat: 'Balochistan',
	Tordher: 'Khyber Pakhtunkhwa',
	Topi: 'Khyber Pakhtunkhwa',
	'Toba Tek Singh': 'Punjab',
	Thul: 'Sindh',
	Thatta: 'Sindh',
	'Tharu Shah': 'Sindh',
	Taunsa: 'Punjab',
	Tank: 'Khyber Pakhtunkhwa',
	Tangi: 'Khyber Pakhtunkhwa',
	'Tando Muhammad Khan': 'Sindh',
	'Tando Jam': 'Sindh',
	'Tando Allahyar': 'Sindh',
	'Tando Adam': 'Sindh',
	Tandlianwala: 'Punjab',
	Talhar: 'Sindh',
	Talamba: 'Punjab',
	Talagang: 'Punjab',
	Thal: 'Khyber Pakhtunkhwa',
	Swabi: 'Khyber Pakhtunkhwa',
	Surkhpur: 'Punjab',
	Sukkur: 'Sindh',
	'Sukheke Mandi': 'Punjab',
	Sodhri: 'Punjab',
	'Sita Road': 'Sindh',
	Sinjhoro: 'Sindh',
	Sillanwali: 'Punjab',
	Sibi: 'Balochistan',
	Sialkot: 'Punjab',
	Shujaabad: 'Punjab',
	Shorkot: 'Khyber Pakhtunkhwa',
	Shikarpur: 'Sindh',
	Shekhupura: 'Punjab',
	'Sharqpur Sharif': 'Punjab',
	Shakargarh: 'Punjab',
	'Shahr Sultan': 'Punjab',
	'Shahpur Chakar': 'Sindh',
	Shahkot: 'Punjab',
	Shahdadpur: 'Sindh',
	'Shahdad Kot': 'Sindh',
	Shabqadar: 'Khyber Pakhtunkhwa',
	Sehwan: 'Sindh',
	Sargodha: 'Punjab',
	'Sarai Sidhu': 'Punjab',
	'Sarai Naurang': 'Khyber Pakhtunkhwa',
	'Sarai Alamgir': 'Punjab',
	'Sangla Hill': 'Punjab',
	Sanghar: 'Sindh',
	Sambrial: 'Punjab',
	Sakrand: 'Sindh',
	Sahiwal: 'Punjab',
	Saddiqabad: 'Punjab',
	Rohri: 'Sindh',
	'Renala Khurd': 'Punjab',
	Rawalpindi: 'Punjab',
	Ratodero: 'Sindh',
	Ranipur: 'Sindh',
	Rajanpur: 'Punjab',
	'Raja Jang': 'Punjab',
	Raiwind: 'Punjab',
	'Rahim Yar Khan': 'Punjab',
	'Goth Radhan': 'Sindh',
	'Chenab Nagar': 'Punjab',
	Quetta: 'Balochistan',
	Kambar: 'Sindh',
	'Qadirpur Ran': 'Punjab',
	Pishin: 'Balochistan',
	'Pir Mahal': 'Punjab',
	'Pindi Gheb': 'Punjab',
	'Pindi Bhattian': 'Punjab',
	'Pind Dadan Khan': 'Punjab',
	Phalia: 'Punjab',
	Peshawar: 'Khyber Pakhtunkhwa',
	Pattoki: 'Punjab',
	Pasrur: 'Punjab',
	Pasni: 'Balochistan',
	'Pano Aqil': 'Sindh',
	Pakpattan: 'Punjab',
	Paharpur: 'Khyber Pakhtunkhwa',
	'Pad Idan': 'Sindh',
	Pabbi: 'Khyber Pakhtunkhwa',
	Okara: 'Punjab',
	Nushki: 'Balochistan',
	'New Mirpur': 'Azad Kashmir',
	Nawabshah: 'Sindh',
	'Naushahro Firoz': 'Sindh',
	'Naushahra Virkan': 'Punjab',
	Naudero: 'Sindh',
	Nasirabad: 'Sindh',
	Narowal: 'Punjab',
	'Narang Mandi': 'Punjab',
	Naukot: 'Sindh',
	'Nankana Sahib': 'Punjab',
	Muzaffargarh: 'Punjab',
	Muzaffarābād: 'Azad Kashmir',
	Mustafabad: 'Punjab',
	Murree: 'Punjab',
	Muridke: 'Punjab',
	Multan: 'Punjab',
	Moro: 'Sindh',
	Mithi: 'Sindh',
	'Mitha Tiwana': 'Punjab',
	'Mirpur Mathelo': 'Sindh',
	'Mirpur Khas': 'Sindh',
	Mingora: 'Khyber Pakhtunkhwa',
	Minchianabad: 'Punjab',
	Mianwali: 'Punjab',
	'Mian Channun': 'Punjab',
	Mehrabpur: 'Balochistan',
	Mehar: 'Sindh',
	Matli: 'Sindh',
	Matiari: 'Sindh',
	Mastung: 'Balochistan',
	Mardan: 'Khyber Pakhtunkhwa',
	Mansehra: 'Khyber Pakhtunkhwa',
	Mangla: 'Punjab',
	'Mandi Bahauddin': 'Punjab',
	Mananwala: 'Punjab',
	'Mamu Kanjan': 'Punjab',
	'Malir Cantonment': 'Sindh',
	Malakwal: 'Punjab',
	Mailsi: 'Punjab',
	Mach: 'Balochistan',
	Loralai: 'Balochistan',
	Lodhran: 'Punjab',
	Layyah: 'Punjab',
	Larkana: 'Sindh',
	Lalian: 'Punjab',
	'Lala Musa': 'Punjab',
	Lakki: 'Khyber Pakhtunkhwa',
	Lahore: 'Punjab',
	'Ladhewala Waraich': 'Punjab',
	Lachi: 'Khyber Pakhtunkhwa',
	Kunri: 'Sindh',
	Kunjah: 'Punjab',
	Kundian: 'Punjab',
	Kulachi: 'Khyber Pakhtunkhwa',
	'Kot Samaba': 'Punjab',
	Kotri: 'Sindh',
	'Kot Radha Kishan': 'Punjab',
	'Kot Mumin': 'Punjab',
	'Kot Malik Barkhurdar': 'Balochistan',
	'Kotli Loharan': 'Punjab',
	Kotli: 'Azad Kashmir',
	'Kot Ghulam Muhammad': 'Punjab',
	'Kot Diji': 'Sindh',
	'Kot Addu': 'Punjab',
	Kohat: 'Khyber Pakhtunkhwa',
	Khuzdar: 'Balochistan',
	Khushāb: 'Punjab',
	Khurrianwala: 'Punjab',
	Khewra: 'Punjab',
	Kharian: 'Punjab',
	Kharan: 'Balochistan',
	Khanpur: 'Punjab',
	'Khanpur Mahar': 'Sindh',
	Khangarh: 'Punjab',
	'Khangah Dogran': 'Punjab',
	Khalabat: 'Khyber Pakhtunkhwa',
	'Khairpur Nathan Shah': 'Sindh',
	'Khairpur Tamewah': 'Punjab',
	Khairpur: 'Sindh',
	Kasur: 'Punjab',
	Kashmor: 'Sindh',
	Karor: 'Punjab',
	Karachi: 'Sindh',
	Kanganpur: 'Punjab',
	Kandiaro: 'Sindh',
	Kandhkot: 'Sindh',
	Kamra: 'Punjab',
	Kamoke: 'Punjab',
	'Chak One Hundred Twenty Nine Left': 'Punjab',
	'Kamar Mushani': 'Punjab',
	Kamalia: 'Punjab',
	'Kalur Kot': 'Punjab',
	'Kallar Kahar': 'Punjab',
	'Kaleke Mandi': 'Punjab',
	Kalat: 'Balochistan',
	Kalabagh: 'Punjab',
	Kahuta: 'Punjab',
	'Kahror Pakka': 'Punjab',
	'Kahna Nau': 'Punjab',
	Kabirwala: 'Punjab',
	Johi: 'Sindh',
	Jiwani: 'Balochistan',
	'Chak Jhumra': 'Punjab',
	Jhol: 'Sindh',
	Jhelum: 'Punjab',
	Jhawarian: 'Punjab',
	'Jhang Sadr': 'Punjab',
	Jauharabad: 'Punjab',
	'Jatoi Shimali': 'Punjab',
	Jaranwala: 'Punjab',
	Jand: 'Punjab',
	Jampur: 'Punjab',
	'Jalalpur Pirwala': 'Punjab',
	'Jalalpur Jattan': 'Punjab',
	'Jahanian Shah': 'Punjab',
	Jacobabad: 'Sindh',
	Islamabad: 'Islamabad',
	Hyderabad: 'Sindh',
	'Hujra Shah Muqim': 'Punjab',
	Hingorja: 'Sindh',
	'Hazro City': 'Punjab',
	Havelian: 'Khyber Pakhtunkhwa',
	'Haveli Lakha': 'Punjab',
	Hasilpur: 'Punjab',
	'Chak Thirty-one -Eleven Left': 'Punjab',
	Harunabad: 'Punjab',
	Harnoli: 'Punjab',
	Haripur: 'Khyber Pakhtunkhwa',
	Hala: 'Sindh',
	Hafizabad: 'Punjab',
	Hadali: 'Punjab',
	Gwadar: 'Balochistan',
	Gujrat: 'Punjab',
	Gujranwala: 'Punjab',
	'Gujar Khan': 'Punjab',
	Gojra: 'Punjab',
	Ghotki: 'Sindh',
	Ghauspur: 'Sindh',
	Gharo: 'Sindh',
	'Garh Maharaja': 'Punjab',
	Gambat: 'Sindh',
	'Fort Abbas': 'Punjab',
	Fazilpur: 'Punjab',
	Faruka: 'Punjab',
	Faqirwali: 'Punjab',
	Faisalabad: 'Punjab',
	Eminabad: 'Punjab',
	Dunyapur: 'Punjab',
	'Dunga Bunga': 'Punjab',
	Dullewala: 'Punjab',
	'Upper Dir': 'Khyber Pakhtunkhwa',
	Dipalpur: 'Punjab',
	Dinga: 'Punjab',
	Dijkot: 'Punjab',
	Digri: 'Sindh',
	'Dhoro Naro': 'Sindh',
	Dhanot: 'Punjab',
	'Dera Ismail Khan': 'Khyber Pakhtunkhwa',
	'Dera Ghazi Khan': 'Punjab',
	'Dera Bugti': 'Balochistan',
	Daur: 'Sindh',
	'Daud Khel': 'Punjab',
	'Daska Kalan': 'Punjab',
	'Darya Khan': 'Punjab',
	Dajal: 'Punjab',
	Dadu: 'Sindh',
	Dadhar: 'Balochistan',
	Chunian: 'Punjab',
	'Chuhar Kana': 'Punjab',
	Chhor: 'Sindh',
	'Choa Saidan Shah': 'Punjab',
	Chishtian: 'Punjab',
	Chiniot: 'Punjab',
	Chichawatni: 'Punjab',
	Chawinda: 'Punjab',
	Charsadda: 'Khyber Pakhtunkhwa',
	Chaman: 'Balochistan',
	Chakwal: 'Punjab',
	'Chak Azam Sahu': 'Punjab',
	Burewala: 'Punjab',
	Bhopalwala: 'Punjab',
	'Bhit Shah': 'Sindh',
	Bhimber: 'Azad Kashmir',
	Bhera: 'Punjab',
	Bhawana: 'Punjab',
	Bhan: 'Sindh',
	Bhalwal: 'Punjab',
	Bhakkar: 'Punjab',
	'Mianke Mor': 'Punjab',
	'Bat Khela': 'Khyber Pakhtunkhwa',
	Battagram: 'Khyber Pakhtunkhwa',
	Basirpur: 'Punjab',
	Bannu: 'Khyber Pakhtunkhwa',
	Bahawalpur: 'Punjab',
	Bahawalnagar: 'Punjab',
	Badin: 'Sindh',
	Baddomalhi: 'Punjab',
	'Attock City': 'Punjab',
	Arifwala: 'Punjab',
	'Aman Garh': 'Khyber Pakhtunkhwa',
	Alipur: 'Punjab',
	Akora: 'Khyber Pakhtunkhwa',
	'Ahmadpur East': 'Punjab',
	Abbottabad: 'Khyber Pakhtunkhwa',
	'Nowshera Cantonment': 'Khyber Pakhtunkhwa',
	'Ahmadpur Sial': 'Punjab',
	'New Badah': 'Sindh',
	'Setharja Old': 'Sindh',
	'Sook Kalan': 'Punjab',
	'Risalpur Cantonment': 'Khyber Pakhtunkhwa',
	'Malakwal City': 'Punjab',
	Żyrardów: 'Mazovia',
	Żoliborz: 'Mazovia',
	Zielonka: 'Mazovia',
	Zamość: 'Lublin',
	Zambrów: 'Podlasie',
	Ząbki: 'Mazovia',
	Wyszków: 'Mazovia',
	Wołomin: 'Mazovia',
	Wola: 'Mazovia',
	Włochy: 'Mazovia',
	Wieliczka: 'Lesser Poland',
	Wesoła: 'Mazovia',
	Wawer: 'Mazovia',
	Warsaw: 'Mazovia',
	Ursus: 'Mazovia',
	'Tomaszów Mazowiecki': 'Łódź Voivodeship',
	'Tomaszów Lubelski': 'Lublin',
	Tarnów: 'Lesser Poland',
	Tarnobrzeg: 'Subcarpathia',
	Targówek: 'Mazovia',
	Szczytno: 'Warmia-Masuria',
	Świdnik: 'Lublin',
	Suwałki: 'Podlasie',
	Sulejówek: 'Mazovia',
	Staszów: 'Świętokrzyskie',
	Starachowice: 'Świętokrzyskie',
	'Stalowa Wola': 'Subcarpathia',
	Śródmieście: 'Mazovia',
	'Sokołów Podlaski': 'Mazovia',
	Sokółka: 'Podlasie',
	Sochaczew: 'Mazovia',
	Skierniewice: 'Łódź Voivodeship',
	'Skarżysko-Kamienna': 'Świętokrzyskie',
	Siemiatycze: 'Podlasie',
	Siedlce: 'Mazovia',
	Sanok: 'Subcarpathia',
	Sandomierz: 'Świętokrzyskie',
	Rzeszów: 'Subcarpathia',
	Ropczyce: 'Subcarpathia',
	Rembertów: 'Mazovia',
	'Rawa Mazowiecka': 'Łódź Voivodeship',
	'Radzyń Podlaski': 'Lublin',
	Radom: 'Mazovia',
	Pułtusk: 'Mazovia',
	Puławy: 'Lublin',
	Przeworsk: 'Subcarpathia',
	Przemyśl: 'Subcarpathia',
	Przasnysz: 'Mazovia',
	Pruszków: 'Mazovia',
	Płońsk: 'Mazovia',
	Pisz: 'Warmia-Masuria',
	Pionki: 'Mazovia',
	Piastów: 'Mazovia',
	Piaseczno: 'Mazovia',
	Otwock: 'Mazovia',
	'Ostrów Mazowiecka': 'Mazovia',
	'Ostrowiec Świętokrzyski': 'Świętokrzyskie',
	Ostrołęka: 'Mazovia',
	Opoczno: 'Łódź Voivodeship',
	Olsztyn: 'Warmia-Masuria',
	Olecko: 'Warmia-Masuria',
	Ochota: 'Mazovia',
	'Nowy Targ': 'Lesser Poland',
	'Nowy Sącz': 'Lesser Poland',
	'Nowy Dwór Mazowiecki': 'Mazovia',
	Nisko: 'Subcarpathia',
	Mrągowo: 'Warmia-Masuria',
	Mokotów: 'Mazovia',
	Mława: 'Mazovia',
	'Mińsk Mazowiecki': 'Mazovia',
	Milanówek: 'Mazovia',
	Mielec: 'Subcarpathia',
	'Międzyrzec Podlaski': 'Lublin',
	Marki: 'Mazovia',
	Łuków: 'Lublin',
	Lublin: 'Lublin',
	Lubartów: 'Lublin',
	Łomża: 'Podlasie',
	Łomianki: 'Mazovia',
	'Lidzbark Warmiński': 'Warmia-Masuria',
	Legionowo: 'Mazovia',
	Łęczna: 'Lublin',
	Łapy: 'Podlasie',
	Łańcut: 'Subcarpathia',
	Krosno: 'Subcarpathia',
	Krasnystaw: 'Lublin',
	Kraśnik: 'Lublin',
	Kozienice: 'Mazovia',
	'Konstancin-Jeziorna': 'Mazovia',
	Końskie: 'Świętokrzyskie',
	Kobyłka: 'Mazovia',
	Kielce: 'Świętokrzyskie',
	Kętrzyn: 'Warmia-Masuria',
	Kabaty: 'Mazovia',
	Józefów: 'Mazovia',
	Jędrzejów: 'Świętokrzyskie',
	Jasło: 'Subcarpathia',
	Jarosław: 'Subcarpathia',
	Hrubieszów: 'Lublin',
	Hajnówka: 'Podlasie',
	'Grodzisk Mazowiecki': 'Mazovia',
	Grajewo: 'Podlasie',
	Gorlice: 'Lesser Poland',
	Giżycko: 'Warmia-Masuria',
	Gierłoż: 'Warmia-Masuria',
	Garwolin: 'Mazovia',
	Ełk: 'Warmia-Masuria',
	Działdowo: 'Warmia-Masuria',
	Dęblin: 'Lublin',
	Dębica: 'Subcarpathia',
	Ciechanów: 'Mazovia',
	Chełm: 'Lublin',
	'Busko-Zdrój': 'Świętokrzyskie',
	Brzesko: 'Lesser Poland',
	Bochnia: 'Lesser Poland',
	Biłgoraj: 'Lublin',
	'Bielsk Podlaski': 'Podlasie',
	Bielany: 'Mazovia',
	Białystok: 'Podlasie',
	Białołeka: 'Mazovia',
	'Biała Podlaska': 'Lublin',
	Bemowo: 'Mazovia',
	Bartoszyce: 'Warmia-Masuria',
	Augustów: 'Podlasie',
	Żywiec: 'Silesia',
	Żory: 'Silesia',
	Złotów: 'Greater Poland',
	Złotoryja: 'Lower Silesia',
	'Zielona Góra': 'Lubusz',
	Zgorzelec: 'Lower Silesia',
	Zgierz: 'Łódź Voivodeship',
	'Zduńska Wola': 'Łódź Voivodeship',
	Zawiercie: 'Silesia',
	Żary: 'Lubusz',
	Zakopane: 'Lesser Poland',
	Żagań: 'Lubusz',
	Zabrze: 'Silesia',
	'Ząbkowice Śląskie': 'Lower Silesia',
	Września: 'Greater Poland',
	Wrocław: 'Lower Silesia',
	'Wodzisław Śląski': 'Silesia',
	Włocławek: 'Kujawsko-Pomorskie',
	Wieluń: 'Łódź Voivodeship',
	Wejherowo: 'Pomerania',
	Wałcz: 'West Pomerania',
	Wałbrzych: 'Lower Silesia',
	Wągrowiec: 'Greater Poland',
	Wadowice: 'Lesser Poland',
	Ustroń: 'Silesia',
	Ustka: 'Pomerania',
	Tychy: 'Silesia',
	Turek: 'Greater Poland',
	Trzebinia: 'Lesser Poland',
	Trzcianka: 'Greater Poland',
	Toruń: 'Kujawsko-Pomorskie',
	Tczew: 'Pomerania',
	'Tarnowskie Góry': 'Silesia',
	Szczecinek: 'West Pomerania',
	Szczecin: 'West Pomerania',
	Szamotuły: 'Greater Poland',
	Świnoujście: 'West Pomerania',
	Świętochłowice: 'Silesia',
	Świebodzin: 'Lubusz',
	Świecie: 'Kujawsko-Pomorskie',
	Świebodzice: 'Lower Silesia',
	Świdwin: 'West Pomerania',
	Świdnica: 'Lower Silesia',
	Swarzędz: 'Greater Poland',
	Sulechów: 'Lubusz',
	'Strzelce Opolskie': 'Opole Voivodeship',
	Strzegom: 'Lower Silesia',
	'Starogard Gdański': 'Pomerania',
	Stargard: 'West Pomerania',
	'Środa Wielkopolska': 'Greater Poland',
	Śrem: 'Greater Poland',
	Sosnowiec: 'Silesia',
	Sopot: 'Pomerania',
	'Solec Kujawski': 'Kujawsko-Pomorskie',
	Słupsk: 'Pomerania',
	Słubice: 'Lubusz',
	Skawina: 'Lesser Poland',
	Sierpc: 'Mazovia',
	Sieradz: 'Łódź Voivodeship',
	'Siemianowice Śląskie': 'Silesia',
	Rypin: 'Kujawsko-Pomorskie',
	Rydułtowy: 'Silesia',
	Rybnik: 'Silesia',
	Rumia: 'Pomerania',
	'Ruda Śląska': 'Silesia',
	Reda: 'Pomerania',
	Rawicz: 'Greater Poland',
	Radzionków: 'Silesia',
	Radomsko: 'Łódź Voivodeship',
	Radlin: 'Silesia',
	Racibórz: 'Silesia',
	Pyskowice: 'Silesia',
	Pszczyna: 'Silesia',
	'Pruszcz Gdański': 'Pomerania',
	Prudnik: 'Opole Voivodeship',
	Poznań: 'Greater Poland',
	Polkowice: 'Lower Silesia',
	Police: 'West Pomerania',
	Płock: 'Mazovia',
	Pleszew: 'Greater Poland',
	'Piotrków Trybunalski': 'Łódź Voivodeship',
	Piła: 'Greater Poland',
	'Piekary Śląskie': 'Silesia',
	Pabianice: 'Łódź Voivodeship',
	Ozorków: 'Łódź Voivodeship',
	Oświęcim: 'Lesser Poland',
	'Ostrów Wielkopolski': 'Greater Poland',
	Ostróda: 'Warmia-Masuria',
	Orzesze: 'Silesia',
	Opole: 'Opole Voivodeship',
	Olkusz: 'Lesser Poland',
	Oleśnica: 'Lower Silesia',
	Oława: 'Lower Silesia',
	Oborniki: 'Greater Poland',
	Nysa: 'Opole Voivodeship',
	'Nowy Tomyśl': 'Greater Poland',
	Nowogard: 'West Pomerania',
	'Nowa Sól': 'Lubusz',
	'Nowa Ruda': 'Lower Silesia',
	Namysłów: 'Opole Voivodeship',
	'Nakło nad Notecią': 'Kujawsko-Pomorskie',
	Myszków: 'Silesia',
	Mysłowice: 'Silesia',
	Myślenice: 'Lesser Poland',
	Mikołów: 'Silesia',
	Międzyrzecz: 'Lubusz',
	Malbork: 'Pomerania',
	Luboń: 'Greater Poland',
	Lubliniec: 'Silesia',
	Lubin: 'Lower Silesia',
	Lubań: 'Lower Silesia',
	Łowicz: 'Łódź Voivodeship',
	Łódź: 'Łódź Voivodeship',
	Libiąż: 'Lesser Poland',
	Leszno: 'Greater Poland',
	Legnica: 'Lower Silesia',
	Lędziny: 'Silesia',
	Łęczyca: 'Łódź Voivodeship',
	Lębork: 'Pomerania',
	'Łaziska Górne': 'Silesia',
	Łask: 'Łódź Voivodeship',
	Kwidzyn: 'Pomerania',
	Kutno: 'Łódź Voivodeship',
	Krotoszyn: 'Greater Poland',
	Krapkowice: 'Opole Voivodeship',
	Kraków: 'Lesser Poland',
	Koszalin: 'West Pomerania',
	'Kostrzyn nad Odrą': 'Lubusz',
	Kościerzyna: 'Pomerania',
	Kościan: 'Greater Poland',
	'Konstantynów Łódzki': 'Łódź Voivodeship',
	Konin: 'Greater Poland',
	Kołobrzeg: 'West Pomerania',
	Koło: 'Greater Poland',
	Knurów: 'Silesia',
	Kluczbork: 'Opole Voivodeship',
	Kłodzko: 'Lower Silesia',
	Kęty: 'Lesser Poland',
	'Kędzierzyn-Koźle': 'Opole Voivodeship',
	Katowice: 'Silesia',
	Kartuzy: 'Pomerania',
	'Kamienna Góra': 'Lower Silesia',
	Kalisz: 'Greater Poland',
	'Jelenia Góra': 'Lower Silesia',
	Jelcz: 'Lower Silesia',
	Jaworzno: 'Silesia',
	Jawor: 'Lower Silesia',
	'Jastrzębie Zdrój': 'Silesia',
	Jarocin: 'Greater Poland',
	Inowrocław: 'Kujawsko-Pomorskie',
	Iława: 'Warmia-Masuria',
	Gubin: 'Lubusz',
	Gryfino: 'West Pomerania',
	Gryfice: 'West Pomerania',
	Grudziądz: 'Kujawsko-Pomorskie',
	Gostynin: 'Mazovia',
	Gostyń: 'Greater Poland',
	'Gorzów Wielkopolski': 'Lubusz',
	Goleniów: 'West Pomerania',
	Gniezno: 'Greater Poland',
	Głuchołazy: 'Opole Voivodeship',
	Głowno: 'Łódź Voivodeship',
	Głogów: 'Lower Silesia',
	Gliwice: 'Silesia',
	Gdynia: 'Pomerania',
	Gdańsk: 'Pomerania',
	Fordon: 'Kujawsko-Pomorskie',
	Elbląg: 'Warmia-Masuria',
	Dzierżoniów: 'Lower Silesia',
	'Dąbrowa Górnicza': 'Silesia',
	Częstochowa: 'Silesia',
	'Czerwionka-Leszczyny': 'Silesia',
	Czeladź: 'Silesia',
	'Czechowice-Dziedzice': 'Silesia',
	Cieszyn: 'Silesia',
	Chrzanów: 'Lesser Poland',
	Choszczno: 'West Pomerania',
	Chorzów: 'Silesia',
	Chojnice: 'Pomerania',
	Chodzież: 'Greater Poland',
	Chełmża: 'Kujawsko-Pomorskie',
	Chełmno: 'Kujawsko-Pomorskie',
	Bytów: 'Pomerania',
	Bytom: 'Silesia',
	Bydgoszcz: 'Kujawsko-Pomorskie',
	Brzeg: 'Opole Voivodeship',
	Brodnica: 'Kujawsko-Pomorskie',
	Braniewo: 'Warmia-Masuria',
	Bolesławiec: 'Lower Silesia',
	'Boguszów-Gorce': 'Lower Silesia',
	Bogatynia: 'Lower Silesia',
	Bieruń: 'Silesia',
	'Bielsko-Biala': 'Silesia',
	Bielawa: 'Lower Silesia',
	Białogard: 'West Pomerania',
	Bełchatów: 'Łódź Voivodeship',
	Będzin: 'Silesia',
	Andrychów: 'Lesser Poland',
	'Aleksandrów Łódzki': 'Łódź Voivodeship',
	Ursynów: 'Mazovia',
	'Praga Północ': 'Mazovia',
	'Praga Południe': 'Mazovia',
	'Jelcz Laskowice': 'Lower Silesia',
	'Osiedle Kosmonautów': 'Lower Silesia',
	'Rejon placu Grunwaldzkiego': 'Lower Silesia',
	'Rejon ulicy Traugutta': 'Lower Silesia',
	Kozanów: 'Lower Silesia',
	'Rejon placu Świętego Macieja': 'Lower Silesia',
	'Różanka-Polanka': 'Lower Silesia',
	'Saint-Pierre': 'Réunion',
	Adamstown: '',
	Aguadilla: 'Aguadilla',
	Arecibo: 'Arecibo',
	Barceloneta: 'Barceloneta',
	Bayamón: 'Bayamón',
	Caguas: 'Caguas',
	Cataño: 'Cataño',
	Cayey: 'Cayey',
	Fajardo: 'Fajardo',
	Guayama: 'Guayama',
	Guaynabo: 'Guaynabo',
	Humacao: 'Humacao',
	Levittown: 'Pennsylvania',
	Mayagüez: 'Mayagüez',
	Ponce: 'Ponce',
	'Trujillo Alto': 'Trujillo Alto',
	'Vega Baja': 'Vega Baja',
	Yauco: 'Yauco',
	Rafaḩ: 'Gaza Strip',
	'An Nuşayrāt': 'Gaza Strip',
	'Khān Yūnis': 'Gaza Strip',
	Jabālyā: 'Gaza Strip',
	Gaza: 'Gaza Strip',
	'Dayr al Balaḩ': 'Gaza Strip',
	'Bayt Lāhyā': 'Gaza Strip',
	'Bayt Ḩānūn': 'Gaza Strip',
	'Banī Suhaylā': 'Gaza Strip',
	'Al Burayj': 'Gaza Strip',
	'‘Abasān al Kabīrah': 'Gaza Strip',
	Yaţţā: 'West Bank',
	Ţūlkarm: 'West Bank',
	Ţūbās: 'West Bank',
	'Surif City': 'West Bank',
	'Sa‘īr': 'West Bank',
	Ramallah: 'West Bank',
	Qalqīlyah: 'West Bank',
	Qabāţīyah: 'West Bank',
	Nablus: 'West Bank',
	'Ma‘ale Adummim': 'West Bank',
	Janīn: 'West Bank',
	Idhnā: 'West Bank',
	Ḩalḩūl: 'West Bank',
	Dūrā: 'West Bank',
	Bethlehem: 'Orange Free State',
	'Bayt Jālā': 'West Bank',
	Battir: 'West Bank',
	'Banī Na‘īm': 'West Bank',
	Balāţah: 'West Bank',
	'Az̧ Z̧āhirīyah': 'West Bank',
	'‘Aţārūt': 'West Bank',
	'As Samū‘': 'West Bank',
	'Ar Rām wa Ḑāḩiyat al Barīd': 'West Bank',
	Jericho: 'West Bank',
	'Al Yāmūn': 'West Bank',
	Hebron: 'West Bank',
	'Al Bīrah': 'West Bank',
	'Al ‘Ayzarīyah': 'West Bank',
	'Old City': 'West Bank',
	'Al Qarārah': 'Gaza Strip',
	'Az Zuwāydah': 'Gaza Strip',
	'East Jerusalem': 'West Bank',
	'Vila Franca de Xira': 'Lisbon',
	Vialonga: 'Lisbon',
	'Torres Vedras': 'Lisbon',
	'Torres Novas': 'Santarém',
	Tomar: 'Santarém',
	Tavira: 'Faro',
	Sintra: 'Lisbon',
	Sesimbra: 'Setúbal',
	Setúbal: 'Setúbal',
	'São Martinho': 'Madeira',
	'São João da Talha': 'Lisbon',
	'São Domingos de Rana': 'Lisbon',
	'Santa Iria da Azóia': 'Lisbon',
	Sacavém: 'Lisbon',
	'Rio de Mouro': 'Lisbon',
	Ramada: 'Lisbon',
	Queluz: 'Lisbon',
	Quarteira: 'Faro',
	'Póvoa de Santa Iria': 'Lisbon',
	Portimão: 'Faro',
	Portalegre: 'Portalegre',
	Pontinha: 'Lisbon',
	'Pinhal Novo': 'Setúbal',
	Peniche: 'Leiria',
	Parede: 'Lisbon',
	Palmela: 'Setúbal',
	'Paço de Arcos': 'Lisbon',
	Olivais: 'Lisbon',
	Olhão: 'Faro',
	Odivelas: 'Lisbon',
	'Moscavide e Portela': 'Lisbon',
	'Monte Estoril': 'Lisbon',
	Monsanto: 'Santarém',
	Moita: 'Setúbal',
	Massamá: 'Lisbon',
	'Marinha Grande': 'Leiria',
	Loures: 'Lisbon',
	Loulé: 'Faro',
	Lisbon: 'Lisbon',
	'Linda-a-Velha': 'Lisbon',
	Leiria: 'Leiria',
	Lavradio: 'Setúbal',
	Laranjeiro: 'Setúbal',
	Funchal: 'Madeira',
	Faro: 'Faro',
	Évora: 'Évora',
	Estoril: 'Lisbon',
	Entroncamento: 'Santarém',
	Corroios: 'Setúbal',
	'Charneca de Caparica': 'Setúbal',
	'Castelo Branco': 'Castelo Branco',
	Cascais: 'Lisbon',
	Carnaxide: 'Lisbon',
	Carcavelos: 'Lisbon',
	Caparica: 'Setúbal',
	Caniço: 'Madeira',
	Camarate: 'Lisbon',
	'Câmara de Lobos': 'Madeira',
	'Caldas da Rainha': 'Leiria',
	Cacém: 'Lisbon',
	Benfica: 'Lisbon',
	Belas: 'Lisbon',
	Beja: 'Beja',
	Barreiro: 'Setúbal',
	Arrentela: 'Setúbal',
	Amora: 'Setúbal',
	Amadora: 'Lisbon',
	Almada: 'Setúbal',
	Algueirão: 'Lisbon',
	Algés: 'Lisbon',
	Alcobaça: 'Leiria',
	Alcabideche: 'Lisbon',
	Albufeira: 'Faro',
	'Vila Real': 'Vila Real',
	'Vilar de Andorinho': 'Porto',
	'Vila Nova de Gaia': 'Porto',
	'Vila do Conde': 'Porto',
	'Viana do Castelo': 'Viana do Castelo',
	Valongo: 'Porto',
	Trofa: 'Porto',
	Sequeira: 'Guarda',
	'Senhora da Hora': 'Porto',
	'São Pedro da Cova': 'Porto',
	'São Mamede de Infesta': 'Porto',
	'São João da Madeira': 'Aveiro',
	'Santo António dos Olivais': 'Coimbra',
	'Rio Tinto': 'Porto',
	'Póvoa de Varzim': 'Porto',
	Porto: 'Porto',
	'Ponte de Lima': 'Viana do Castelo',
	Pedroso: 'Porto',
	Paredes: 'Porto',
	Ovar: 'Aveiro',
	'Oliveira do Douro': 'Porto',
	Monção: 'Viana do Castelo',
	Matosinhos: 'Porto',
	Maia: 'Porto',
	'Leça do Bailio': 'Porto',
	'Leça da Palmeira': 'Porto',
	Ílhavo: 'Aveiro',
	Guimarães: 'Braga',
	Guarda: 'Guarda',
	Gondomar: 'Porto',
	'Figueira da Foz': 'Coimbra',
	Felgueiras: 'Porto',
	Feira: 'Aveiro',
	Fânzeres: 'Porto',
	Fafe: 'Braga',
	Esposende: 'Braga',
	Espinho: 'Aveiro',
	Ermesinde: 'Porto',
	Custoias: 'Guarda',
	Covilhã: 'Castelo Branco',
	'Condeixa-a-Nova': 'Coimbra',
	Coimbra: 'Coimbra',
	Chaves: 'Vila Real',
	Canidelo: 'Porto',
	'Cabeceiras de Basto': 'Braga',
	Braga: 'Braga',
	Bougado: 'Porto',
	Barcelos: 'Braga',
	Baião: 'Porto',
	'Baguim do Monte': 'Porto',
	Aveiro: 'Aveiro',
	'Arcos de Valdevez': 'Viana do Castelo',
	Alfena: 'Porto',
	'Águas Santas': 'Porto',
	'Quinta Do Conde': 'Setúbal',
	Alfragide: 'Lisbon',
	Alvalade: 'Lisbon',
	'Ponta Delgada': 'Azores',
	Azeitão: 'Setúbal',
	Ngerulmud: 'Melekeok',
	'Villa Hayes': 'Presidente Hayes',
	'Villa Elisa': 'Central',
	'San Juan Bautista': 'Nueva Esparta',
	'Presidente Franco': 'Alto Paraná',
	'Pedro Juan Caballero': 'Amambay',
	Nemby: 'Central',
	Limpio: 'Central',
	Lambaré: 'Central',
	Itauguá: 'Central',
	Itá: 'Central',
	'Fernando de la Mora': 'Central',
	Encarnación: 'Itapúa',
	'Coronel Oviedo': 'Caaguazú',
	'Mariano Roque Alonso': 'Central',
	'Ciudad del Este': 'Alto Paraná',
	Capiatá: 'Central',
	Caazapá: 'Caazapá',
	Caaguazú: 'Caaguazú',
	Caacupé: 'Cordillera',
	Asunción: 'Asunción',
	Filadelfia: 'Boquerón',
	'Umm Şalāl Muḩammad': 'Baladīyat Umm Şalāl',
	'Ar Rayyān': 'Baladīyat ar Rayyān',
	'Al Wakrah': 'Al Wakrah',
	'Al Khawr': 'Al Khor',
	Doha: 'Baladīyat ad Dawḩah',
	'Saint-Paul': 'Réunion',
	'Sainte-Suzanne': 'Réunion',
	'Saint-Benoît': 'Réunion',
	'Saint-André': 'Réunion',
	'Le Tampon': 'Réunion',
	'Le Port': 'Réunion',
	'La Possession': 'Réunion',
	'Piton Saint-Leu': 'Réunion',
	Zimnicea: 'Teleorman',
	Zărnești: 'Brașov',
	Zalău: 'Sălaj',
	Vulcan: 'Hunedoara',
	Voluntari: 'Ilfov',
	'Vişeu de Sus': 'Maramureş',
	'Vatra Dornei': 'Suceava',
	Vaslui: 'Vaslui',
	Urziceni: 'Ialomița',
	'Turnu Măgurele': 'Teleorman',
	Turda: 'Cluj',
	Tulcea: 'Tulcea',
	Târnăveni: 'Mureș',
	'Târgu Secuiesc': 'Covasna',
	'Târgu Neamţ': 'Neamț',
	'Târgu-Mureş': 'Mureș',
	'Târgu Jiu': 'Gorj',
	Târgovişte: 'Dâmbovița',
	Timişoara: 'Timiș',
	Tecuci: 'Galați',
	Suceava: 'Suceava',
	Slatina: 'Olt',
	'Şimleu Silvaniei': 'Sălaj',
	'Sighetu Marmaţiei': 'Maramureş',
	Sibiu: 'Sibiu',
	'Sfântu Gheorghe': 'Covasna',
	Sebeş: 'Alba',
	'Satu Mare': 'Satu Mare',
	Salonta: 'Bihor',
	Săcele: 'Brașov',
	'Roșiorii de Vede': 'Teleorman',
	Roman: 'Neamț',
	Râşnov: 'Brașov',
	'Râmnicu Vâlcea': 'Vâlcea',
	'Râmnicu Sărat': 'Buzău',
	Reşiţa: 'Caraș-Severin',
	'Reghin-Sat': 'Mureș',
	Rădăuți: 'Suceava',
	'Popeşti-Leordeni': 'Ilfov',
	Ploieşti: 'Prahova',
	Piteşti: 'Arges',
	'Piatra Neamţ': 'Neamț',
	Petroşani: 'Hunedoara',
	Petrila: 'Hunedoara',
	Pantelimon: 'Ilfov',
	Orăştie: 'Hunedoara',
	Oradea: 'Bihor',
	Olteniţa: 'Călărași',
	'Odorheiu Secuiesc': 'Harghita',
	'Ocna Mureş': 'Alba',
	Năvodari: 'Constanța',
	Motru: 'Gorj',
	Moreni: 'Dâmbovița',
	Moineşti: 'Bacău',
	Mizil: 'Prahova',
	'Miercurea-Ciuc': 'Harghita',
	Mediaş: 'Sibiu',
	Medgidia: 'Constanța',
	Mangalia: 'Constanța',
	Lupeni: 'Hunedoara',
	Lugoj: 'Timiș',
	Luduş: 'Mureș',
	Iaşi: 'Iași',
	Huşi: 'Vaslui',
	Hunedoara: 'Hunedoara',
	'Gura Humorului': 'Suceava',
	Giurgiu: 'Giurgiu',
	Gherla: 'Cluj',
	Gheorgheni: 'Harghita',
	Galaţi: 'Galați',
	Găeşti: 'Dâmbovița',
	Focșani: 'Vrancea',
	Filiaşi: 'Dolj',
	'Fetești-Gară': 'Ialomița',
	Feteşti: 'Ialomița',
	Fălticeni: 'Suceava',
	Făgăraș: 'Brașov',
	'Drobeta-Turnu Severin': 'Mehedinți',
	Drăgăşani: 'Vâlcea',
	Dorohoi: 'Botoșani',
	Deva: 'Hunedoara',
	Dej: 'Cluj',
	'Curtea de Argeş': 'Arges',
	Cugir: 'Alba',
	Craiova: 'Dolj',
	Corabia: 'Olt',
	Constanţa: 'Constanța',
	Comăneşti: 'Bacău',
	Codlea: 'Brașov',
	'Cluj-Napoca': 'Cluj',
	Cisnădie: 'Sibiu',
	'Câmpulung Moldovenesc': 'Suceava',
	Câmpina: 'Prahova',
	'Câmpia Turzii': 'Cluj',
	Cernavodă: 'Constanța',
	Carei: 'Satu Mare',
	Caransebeş: 'Caraș-Severin',
	Caracal: 'Olt',
	Calafat: 'Dolj',
	Buzău: 'Buzău',
	Buhuşi: 'Bacău',
	Buftea: 'Ilfov',
	Bucharest: 'București',
	Breaza: 'Prahova',
	Braşov: 'Brașov',
	Brăila: 'Brăila',
	Brad: 'Hunedoara',
	Botoşani: 'Botoșani',
	Borşa: 'Maramureş',
	Bocşa: 'Caraș-Severin',
	Blaj: 'Alba',
	Bistriţa: 'Bistrița-Năsăud',
	Bârlad: 'Vaslui',
	Balş: 'Olt',
	Băileşti: 'Dolj',
	Băicoi: 'Prahova',
	'Baia Sprie': 'Maramureş',
	'Baia Mare': 'Maramureş',
	Bacău: 'Bacău',
	'Alba Iulia': 'Alba',
	Aiud: 'Alba',
	Adjud: 'Vrancea',
	Paşcani: 'Iași',
	Mioveni: 'Arges',
	Sighișoara: 'Mureș',
	'Sector 1': 'București',
	'Sector 2': 'București',
	'Sector 3': 'București',
	'Sector 4': 'București',
	'Sector 5': 'București',
	'Sector 6': 'București',
	Zrenjanin: 'Vojvodina',
	Zemun: 'Central Serbia',
	Zaječar: 'Central Serbia',
	Vršac: 'Vojvodina',
	Vranje: 'Central Serbia',
	Trstenik: 'Central Serbia',
	'Stara Pazova': 'Vojvodina',
	Sremčica: 'Central Serbia',
	'Smederevska Palanka': 'Central Serbia',
	Smederevo: 'Central Serbia',
	Senta: 'Vojvodina',
	Prokuplje: 'Central Serbia',
	Požarevac: 'Central Serbia',
	Pirot: 'Central Serbia',
	Pančevo: 'Vojvodina',
	Obrenovac: 'Central Serbia',
	'Novi Pazar': 'Central Serbia',
	'Nova Pazova': 'Vojvodina',
	Niš: 'Central Serbia',
	Negotin: 'Central Serbia',
	Leskovac: 'Central Serbia',
	Lazarevac: 'Central Serbia',
	Kruševac: 'Central Serbia',
	Kraljevo: 'Central Serbia',
	Kragujevac: 'Central Serbia',
	Kikinda: 'Vojvodina',
	Jagodina: 'Central Serbia',
	Inđija: 'Vojvodina',
	'Gornji Milanovac': 'Central Serbia',
	Ćuprija: 'Central Serbia',
	Čačak: 'Central Serbia',
	Bor: 'Niğde Province',
	Belgrade: 'Central Serbia',
	Bečej: 'Vojvodina',
	Aranđelovac: 'Central Serbia',
	Vrbas: 'Vojvodina',
	Valjevo: 'Central Serbia',
	Užice: 'Central Serbia',
	Subotica: 'Vojvodina',
	'Sremska Mitrovica': 'Vojvodina',
	Sombor: 'Vojvodina',
	Šabac: 'Central Serbia',
	Ruma: 'Vojvodina',
	'Novi Sad': 'Vojvodina',
	'Bačka Topola': 'Vojvodina',
	'Bačka Palanka': 'Vojvodina',
	Apatin: 'Vojvodina',
	Knjazevac: 'Central Serbia',
	Udomlya: 'Tver Oblast',
	Sosnovka: 'St.-Petersburg',
	Sasovo: 'Ryazan Oblast',
	Zyuzino: 'Moscow',
	Zyablikovo: 'Moscow',
	Zverevo: 'Rostov',
	Zvenigorod: 'Moscow Oblast',
	Zlatoust: 'Chelyabinsk',
	Zimovniki: 'Rostov',
	Zhulebino: 'Moscow',
	Zhukovsky: 'Moscow Oblast',
	Zhukovka: 'Bryansk Oblast',
	Zhirnovsk: 'Volgograd Oblast',
	Zhigulevsk: 'Samara Oblast',
	Zherdevka: 'Tambov Oblast',
	Zheleznovodsk: 'Stavropol Kray',
	Zheleznogorsk: 'Krasnoyarsk Krai',
	Zheleznodorozhnyy: 'Moscow Oblast',
	Zernograd: 'Rostov',
	Zelenokumsk: 'Stavropol Kray',
	Zelenogradsk: 'Kaliningrad Oblast',
	Zelenograd: 'Moscow',
	Zelenodolsk: 'Tatarstan Republic',
	Zelenchukskaya: 'Karachayevo-Cherkesiya Republic',
	'Zavolzh’ye': 'Nizhny Novgorod Oblast',
	'Zavety Il’icha': 'Moscow Oblast',
	Zarechnyy: 'Sverdlovsk Oblast',
	Zaraysk: 'Moscow Oblast',
	Zapolyarnyy: 'Murmansk',
	'Zamoskvorech’ye': 'Moscow',
	Zainsk: 'Tatarstan Republic',
	'Yur’yev-Pol’skiy': 'Vladimir Oblast',
	'Yoshkar-Ola': 'Mariy-El Republic',
	Yeysk: 'Krasnodar Krai',
	Yessentukskaya: 'Stavropol Kray',
	Yessentuki: 'Stavropol Kray',
	Yershov: 'Saratov Oblast',
	Yemva: 'Komi',
	Yelizavetinskaya: 'Krasnodar Krai',
	Yelets: 'Lipetsk Oblast',
	'Yelan’': 'Volgograd Oblast',
	Yelabuga: 'Tatarstan Republic',
	'Yegor’yevsk': 'Moscow Oblast',
	Yegorlykskaya: 'Rostov',
	Yefremov: 'Tula Oblast',
	Yasnyy: 'Orenburg Oblast',
	Yasnogorsk: 'Tula Oblast',
	Yasenevo: 'Moscow',
	Yartsevo: 'Smolensk Oblast',
	Yaroslavl: 'Yaroslavl Oblast',
	Yaransk: 'Kirov Oblast',
	Yanaul: 'Bashkortostan Republic',
	Yagry: 'Arkhangelskaya',
	Yablonovskiy: 'Adygeya Republic',
	'Vyshniy Volochëk': 'Tver Oblast',
	Vyselki: 'Krasnodar Krai',
	Vyksa: 'Nizhny Novgorod Oblast',
	'Vykhino-Zhulebino': 'Moscow',
	Vyborg: "Leningradskaya Oblast'",
	Vyazniki: 'Vladimir Oblast',
	'Vyaz’ma': 'Smolensk Oblast',
	'Vyatskiye Polyany': 'Kirov Oblast',
	Vsevolozhsk: "Leningradskaya Oblast'",
	Votkinsk: 'Udmurtiya Republic',
	Vostryakovo: 'Moscow Oblast',
	Voskresensk: 'Moscow Oblast',
	Voronezh: 'Voronezh Oblast',
	'Altuf’yevskiy': 'Moscow',
	'Vorob’yovo': 'Moscow',
	Volzhsky: 'Volgograd Oblast',
	Volzhsk: 'Mariy-El Republic',
	'Vol’sk': 'Saratov Oblast',
	Volokolamsk: 'Moscow Oblast',
	Vologda: 'Vologda Oblast',
	Volkhov: "Leningradskaya Oblast'",
	Nagornyy: 'Moscow',
	Volgorechensk: 'Kostroma Oblast',
	Volgograd: 'Volgograd Oblast',
	Volgodonsk: 'Rostov',
	Novovladykino: 'Moscow',
	Vladimir: 'Vladimir Oblast',
	Vladikavkaz: 'North Ossetia–Alania',
	Vidnoye: 'Moscow Oblast',
	Vichuga: 'Ivanovo Oblast',
	Veshnyaki: 'Moscow',
	Vereshchagino: 'Perm Krai',
	Venëv: 'Tula Oblast',
	'Vel’sk': 'Arkhangelskaya',
	'Velikiy Ustyug': 'Vologda Oblast',
	'Velikiye Luki': 'Pskov Oblast',
	Vatutino: 'Moscow',
	Valuyki: 'Belgorod Oblast',
	Valday: 'Novgorod Oblast',
	Vagonoremont: 'Moscow',
	Uzlovaya: 'Tula Oblast',
	Uvarovo: 'Tambov Oblast',
	Uva: 'Udmurtiya Republic',
	'Ust’-Labinsk': 'Krasnodar Krai',
	'Ust’-Katav': 'Chelyabinsk',
	'Ust’-Dzheguta': 'Karachayevo-Cherkesiya Republic',
	'Usman’': 'Lipetsk Oblast',
	Uryupinsk: 'Volgograd Oblast',
	'Urus-Martan': 'Chechnya',
	Uritsk: 'St.-Petersburg',
	Unecha: 'Bryansk Oblast',
	Ulyanovsk: 'Ulyanovsk',
	Ukhta: 'Komi',
	Uglich: 'Yaroslavl Oblast',
	Ufa: 'Bashkortostan Republic',
	Uchkeken: 'Karachayevo-Cherkesiya Republic',
	Uchaly: 'Bashkortostan Republic',
	Tyrnyauz: 'Kabardino-Balkariya Republic',
	Tver: 'Tver Oblast',
	Tuymazy: 'Bashkortostan Republic',
	Tutayev: 'Yaroslavl Oblast',
	Tula: 'Tula Oblast',
	Tuchkovo: 'Moscow Oblast',
	Tuapse: 'Krasnodar Krai',
	Tsimlyansk: 'Rostov',
	Trubchevsk: 'Bryansk Oblast',
	Troparëvo: 'Moscow',
	Troitskaya: 'Ingushetiya Republic',
	Troitsk: 'Chelyabinsk',
	Tosno: "Leningradskaya Oblast'",
	Torzhok: 'Tver Oblast',
	Tomilino: 'Moscow Oblast',
	Tolyatti: 'Samara Oblast',
	Timashyovsk: 'Krasnodar Krai',
	Tikhvin: "Leningradskaya Oblast'",
	Tikhoretsk: 'Krasnodar Krai',
	Teykovo: 'Ivanovo Oblast',
	Terek: 'Kabardino-Balkariya Republic',
	'Tyoply Stan': 'Moscow',
	Temryuk: 'Krasnodar Krai',
	'Tekstil’shchiki': 'Moscow',
	Tbilisskaya: 'Krasnodar Krai',
	Tambov: 'Tambov Oblast',
	'Agidel’': 'Bashkortostan Republic',
	Taganrog: 'Rostov',
	Taganskiy: 'Moscow',
	Syzran: 'Samara Oblast',
	Syktyvkar: 'Komi',
	Svobody: 'Stavropol Kray',
	Sviblovo: 'Moscow',
	Svetogorsk: "Leningradskaya Oblast'",
	Svetlyy: 'Kaliningrad Oblast',
	Svetlograd: 'Stavropol Kray',
	Suvorovskaya: 'Stavropol Kray',
	Suvorov: 'Tula Oblast',
	Surovikino: 'Volgograd Oblast',
	Surkhakhi: 'Ingushetiya Republic',
	Sukhinichi: 'Kaluga Oblast',
	Stupino: 'Moscow Oblast',
	Strunino: 'Vladimir Oblast',
	Stroitel: 'Belgorod Oblast',
	Strogino: 'Moscow',
	Sterlitamak: 'Bashkortostan Republic',
	'Stavropol’': 'Stavropol Kray',
	'Staryy Oskol': 'Belgorod Oblast',
	Staroshcherbinovskaya: 'Krasnodar Krai',
	Starominskaya: 'Krasnodar Krai',
	Starodub: 'Bryansk Oblast',
	'Staraya Russa': 'Novgorod Oblast',
	'Staraya Kupavna': 'Moscow Oblast',
	'Staraya Derevnya': 'St.-Petersburg',
	Sovetsk: 'Kaliningrad Oblast',
	'Sosnovyy Bor': "Leningradskaya Oblast'",
	'Sosnovaya Polyana': 'St.-Petersburg',
	Sosnogorsk: 'Komi',
	Sortavala: 'Karelia',
	Sorochinsk: 'Orenburg Oblast',
	Solntsevo: 'Moscow',
	Solnechnogorsk: 'Moscow Oblast',
	'Sol’-Iletsk': 'Orenburg Oblast',
	Solikamsk: 'Perm Krai',
	'Sokol’niki': 'Moscow',
	Sokol: 'Vologda Oblast',
	Sofrino: 'Moscow Oblast',
	Sochi: 'Krasnodar Krai',
	Sobinka: 'Vladimir Oblast',
	Smolensk: 'Smolensk Oblast',
	Slobodskoy: 'Kirov Oblast',
	Slobodka: 'Moscow',
	'Slavyansk-na-Kubani': 'Krasnodar Krai',
	Slantsy: "Leningradskaya Oblast'",
	Skopin: 'Ryazan Oblast',
	Skhodnya: 'Moscow Oblast',
	Sim: 'Chelyabinsk',
	Sibay: 'Bashkortostan Republic',
	Shuya: 'Ivanovo Oblast',
	Shushary: 'St.-Petersburg',
	Shumerlya: 'Chuvashia',
	Mikhaylovsk: 'Stavropol Kray',
	Shilovo: 'Ryazan Oblast',
	Sheksna: 'Vologda Oblast',
	Shebekino: 'Belgorod Oblast',
	Shchukino: 'Moscow',
	Shchigry: 'Kursk Oblast',
	Shcherbinka: 'Moscow',
	Shchyolkovo: 'Moscow Oblast',
	Shchëkino: 'Tula Oblast',
	Shatura: 'Moscow Oblast',
	'Shar’ya': 'Kostroma Oblast',
	Shali: 'Chechnya',
	'Shakhun’ya': 'Nizhny Novgorod Oblast',
	Shakhty: 'Rostov',
	Severskaya: 'Krasnodar Krai',
	'Severo-Zadonsk': 'Tula Oblast',
	'Severoural’sk': 'Sverdlovsk Oblast',
	Severomorsk: 'Murmansk',
	Severodvinsk: 'Arkhangelskaya',
	Severnyy: 'Moscow',
	'Setun’': 'Moscow',
	Sestroretsk: 'St.-Petersburg',
	Sertolovo: "Leningradskaya Oblast'",
	Serpukhov: 'Moscow Oblast',
	'Sergiyev Posad': 'Moscow Oblast',
	Sergach: 'Nizhny Novgorod Oblast',
	Serdobsk: 'Penza Oblast',
	Semiluki: 'Voronezh Oblast',
	Semikarakorsk: 'Rostov',
	Semënovskoye: 'Moscow Oblast',
	Semënov: 'Nizhny Novgorod Oblast',
	'Sel’tso': 'Bryansk Oblast',
	Segezha: 'Karelia',
	Satka: 'Chelyabinsk',
	Sarov: 'Nizhny Novgorod Oblast',
	Saratov: 'Saratov Oblast',
	Sarapul: 'Udmurtiya Republic',
	Saransk: 'Mordoviya Republic',
	Saraktash: 'Orenburg Oblast',
	'Saint Petersburg': 'St.-Petersburg',
	Samara: 'Samara Oblast',
	'Sal’sk': 'Rostov',
	Salavat: 'Bashkortostan Republic',
	Safonovo: 'Smolensk Oblast',
	Rzhev: 'Tver Oblast',
	Rybnoye: 'Ryazan Oblast',
	Rybinsk: 'Yaroslavl Oblast',
	Rybatskoye: "Leningradskaya Oblast'",
	Ryazhsk: 'Ryazan Oblast',
	'Ryazan’': 'Ryazan Oblast',
	Ruzayevka: 'Mordoviya Republic',
	Rublëvo: 'Moscow',
	Rtishchevo: 'Saratov Oblast',
	'Rostov-na-Donu': 'Rostov',
	Rostov: 'Yaroslavl Oblast',
	Rostokino: 'Moscow',
	'Rossosh’': 'Voronezh Oblast',
	'Roslavl’': 'Smolensk Oblast',
	'Roshal’': 'Moscow Oblast',
	Rodniki: 'Ivanovo Oblast',
	Revda: 'Sverdlovsk Oblast',
	Reutov: 'Moscow Oblast',
	Razumnoye: 'Belgorod Oblast',
	Rayevskiy: 'Bashkortostan Republic',
	Rasskazovo: 'Tambov Oblast',
	Ramenskoye: 'Moscow Oblast',
	Ramenki: 'Moscow',
	Pyatigorsk: 'Stavropol Kray',
	Pushkin: 'St.-Petersburg',
	Pushchino: 'Moscow Oblast',
	Pugachev: 'Saratov Oblast',
	Pskov: 'Pskov Oblast',
	Protvino: 'Moscow Oblast',
	Proletarsk: 'Rostov',
	Prokhladnyy: 'Kabardino-Balkariya Republic',
	Priyutovo: 'Bashkortostan Republic',
	Privolzhskiy: 'Saratov Oblast',
	Privolzhsk: 'Ivanovo Oblast',
	Priozërsk: "Leningradskaya Oblast'",
	'Primorsko-Akhtarsk': 'Krasnodar Krai',
	Pridonskoy: 'Voronezh Oblast',
	Povorino: 'Voronezh Oblast',
	Kotlovka: 'Moscow',
	Annino: 'Moscow Oblast',
	'Polyarnyye Zori': 'Murmansk',
	Polyarnyy: 'Murmansk',
	'Pokrovskoye-Streshnëvo': 'Moscow',
	Pokrov: 'Dnipropetrovsk',
	Pokhvistnevo: 'Samara Oblast',
	'Podporozh’ye': "Leningradskaya Oblast'",
	Podolsk: 'Moscow Oblast',
	Pochep: 'Bryansk Oblast',
	Ryazanskiy: 'Moscow',
	Plavsk: 'Tula Oblast',
	Pikalëvo: "Leningradskaya Oblast'",
	Petushki: 'Vladimir Oblast',
	Petrozavodsk: 'Karelia',
	Petrovskaya: 'Moscow Oblast',
	Petrovsk: 'Saratov Oblast',
	Petrodvorets: 'St.-Petersburg',
	Peterhof: 'St.-Petersburg',
	Pestovo: 'Novgorod Oblast',
	Pervouralsk: 'Sverdlovsk Oblast',
	Perm: 'Perm Krai',
	'Pereslavl’-Zalesskiy': 'Yaroslavl Oblast',
	'Novo-Peredelkino': 'Moscow',
	Penza: 'Penza Oblast',
	Pechora: 'Komi',
	'Pavlovskiy Posad': 'Moscow Oblast',
	Pavlovskaya: 'Krasnodar Krai',
	Pavlovsk: 'Voronezh Oblast',
	Pashkovskiy: 'Krasnodar Krai',
	Pallasovka: 'Volgograd Oblast',
	Ozëry: 'Moscow Oblast',
	Ozerki: 'St.-Petersburg',
	Otradnyy: 'Samara Oblast',
	Otradnoye: "Leningradskaya Oblast'",
	Otradnaya: 'Krasnodar Krai',
	Ostrogozhsk: 'Voronezh Oblast',
	Ostashkov: 'Tver Oblast',
	Ostankinskiy: 'Moscow',
	Osa: 'Perm Krai',
	Orsk: 'Orenburg Oblast',
	Orlovskiy: 'Rostov',
	Orenburg: 'Orenburg Oblast',
	Orël: 'Oryol oblast',
	'Orekhovo-Zuyevo': 'Moscow Oblast',
	'Orekhovo-Borisovo Severnoye': 'Moscow',
	Sunzha: 'Ingushetiya Republic',
	Onega: 'Arkhangelskaya',
	Omutninsk: 'Kirov Oblast',
	Olenegorsk: 'Murmansk',
	'Tsotsin-Yurt': 'Chechnya',
	Oktyabrsky: 'Bashkortostan Republic',
	'Oktyabr’sk': 'Samara Oblast',
	Odintsovo: 'Moscow Oblast',
	Ochër: 'Perm Krai',
	'Ochakovo-Matveyevskoye': 'Moscow',
	Obninsk: 'Kaluga Oblast',
	Nytva: 'Perm Krai',
	Nyandoma: 'Arkhangelskaya',
	Nurlat: 'Tatarstan Republic',
	'Novy Oskol': 'Belgorod Oblast',
	'Novyye Kuz’minki': 'Moscow',
	'Novyye Cherëmushki': 'Moscow',
	Novozybkov: 'Bryansk Oblast',
	Novovoronezh: 'Voronezh Oblast',
	Novouzensk: 'Saratov Oblast',
	'Novoul’yanovsk': 'Ulyanovsk',
	Novotroitsk: 'Orenburg Oblast',
	Novotitarovskaya: 'Krasnodar Krai',
	Novoshakhtinsk: 'Rostov',
	Novorossiysk: 'Krasnodar Krai',
	Novopokrovskaya: 'Krasnodar Krai',
	Novopavlovsk: 'Stavropol Kray',
	Novomoskovsk: 'Dnipropetrovsk',
	Novomichurinsk: 'Ryazan Oblast',
	'Novokuz’minki': 'Moscow',
	Novokuybyshevsk: 'Samara Oblast',
	Novokubansk: 'Krasnodar Krai',
	Novokhovrino: 'Moscow',
	Novogireyevo: 'Moscow',
	Novodvinsk: 'Arkhangelskaya',
	Novocherkassk: 'Rostov',
	Novocheboksarsk: 'Chuvashia',
	Novoanninskiy: 'Volgograd Oblast',
	Novoaleksandrovsk: 'Stavropol Kray',
	'Velikiy Novgorod': 'Novgorod Oblast',
	'Novaya Usman’': 'Voronezh Oblast',
	'Novaya Derevnya': 'St.-Petersburg',
	Noginsk: 'Moscow Oblast',
	'Nizhnyaya Tura': 'Sverdlovsk Oblast',
	'Nizhny Tagil': 'Sverdlovsk Oblast',
	'Nizhniy Novgorod': 'Nizhny Novgorod Oblast',
	'Nizhniy Lomov': 'Penza Oblast',
	Nizhnekamsk: 'Tatarstan Republic',
	Nikulino: 'Moscow',
	'Nikol’skoye': "Leningradskaya Oblast'",
	'Nikol’sk': 'Penza Oblast',
	Nikolayevsk: 'Volgograd Oblast',
	Nikel: 'Murmansk',
	Nezlobnaya: 'Stavropol Kray',
	Nevinnomyssk: 'Stavropol Kray',
	'Nevel’': 'Pskov Oblast',
	Nesterovskaya: 'Ingushetiya Republic',
	Nerekhta: 'Kostroma Oblast',
	Nelidovo: 'Tver Oblast',
	Neftekamsk: 'Bashkortostan Republic',
	Neftegorsk: 'Samara Oblast',
	'Nazran’': 'Ingushetiya Republic',
	"Nar'yan-Mar": 'Nenets',
	Nartkala: 'Kabardino-Balkariya Republic',
	'Naro-Fominsk': 'Moscow Oblast',
	Nalchik: 'Kabardino-Balkariya Republic',
	Nakhabino: 'Moscow Oblast',
	'Naberezhnyye Chelny': 'Tatarstan Republic',
	Mytishchi: 'Moscow Oblast',
	Murom: 'Vladimir Oblast',
	Murmansk: 'Murmansk',
	Mtsensk: 'Oryol oblast',
	Mozhga: 'Udmurtiya Republic',
	Mozhaysk: 'Moscow Oblast',
	Mozdok: 'North Ossetia–Alania',
	Mostovskoy: 'Krasnodar Krai',
	Moscow: 'Idaho',
	Morshansk: 'Tambov Oblast',
	Morozovsk: 'Rostov',
	Monino: 'Moscow Oblast',
	Monchegorsk: 'Murmansk',
	'Mineralnye Vody': 'Stavropol Kray',
	Millerovo: 'Rostov',
	Mikhaylovka: 'Volgograd Oblast',
	Mikhalkovo: 'Moscow',
	Michurinsk: 'Tambov Oblast',
	Metallostroy: 'St.-Petersburg',
	Menzelinsk: 'Tatarstan Republic',
	Mendeleyevsk: 'Tatarstan Republic',
	Meleuz: 'Bashkortostan Republic',
	Melenki: 'Vladimir Oblast',
	'Medvezh’yegorsk': 'Karelia',
	Medvedovskaya: 'Krasnodar Krai',
	Medvedevo: 'Mariy-El Republic',
	Mednogorsk: 'Orenburg Oblast',
	Maykop: 'Adygeya Republic',
	Matveyevskoye: 'Moscow',
	Marks: 'Saratov Oblast',
	'Mar’ino': 'Moscow',
	Manturovo: 'Kostroma Oblast',
	Yaroslavskiy: 'Moscow',
	Maloyaroslavets: 'Kaluga Oblast',
	Malgobek: 'Ingushetiya Republic',
	Malakhovka: 'Moscow Oblast',
	Makhachkala: 'Dagestan',
	Magnitogorsk: 'Chelyabinsk',
	Lyudinovo: 'Kaluga Oblast',
	Lyublino: 'Moscow',
	Lyubertsy: 'Moscow Oblast',
	Lytkarino: 'Moscow Oblast',
	'Lys’va': 'Perm Krai',
	Lyskovo: 'Nizhny Novgorod Oblast',
	Luzhniki: 'Moscow',
	Lukhovitsy: 'Moscow Oblast',
	Luga: "Leningradskaya Oblast'",
	'Losino-Petrovskiy': 'Moscow Oblast',
	Lomonosov: 'St.-Petersburg',
	'Lodeynoye Pole': "Leningradskaya Oblast'",
	Lobnya: 'Moscow Oblast',
	Livny: 'Oryol oblast',
	Liski: 'Voronezh Oblast',
	Lipetsk: 'Lipetsk Oblast',
	'Likino-Dulevo': 'Moscow Oblast',
	Likhobory: 'Moscow',
	Lianozovo: 'Moscow',
	'L’govskiy': 'Kursk Oblast',
	'Komendantsky aerodrom': 'St.-Petersburg',
	Lermontov: 'Stavropol Kray',
	Leonovo: 'Moscow',
	'Leninskiye Gory': 'Moscow Oblast',
	Leninsk: 'Volgograd Oblast',
	Leninogorsk: 'Tatarstan Republic',
	Tsaritsyno: 'Moscow',
	Leningradskaya: 'Krasnodar Krai',
	Yubileyny: 'Moscow Oblast',
	Lefortovo: 'Moscow',
	'Lebedyan’': 'Lipetsk Oblast',
	Lazarevskoye: 'Krasnodar Krai',
	Lakinsk: 'Vladimir Oblast',
	Labinsk: 'Krasnodar Krai',
	Dugulubgey: 'Kabardino-Balkariya Republic',
	Kuznetsk: 'Penza Oblast',
	'Kuz’minki': 'Moscow',
	Kuvandyk: 'Orenburg Oblast',
	Kuskovo: 'Moscow Oblast',
	Kushva: 'Sverdlovsk Oblast',
	Kushchyovskaya: 'Krasnodar Krai',
	Kusa: 'Chelyabinsk',
	'Kur’yanovo': 'Moscow',
	Kursk: 'Kursk Oblast',
	Kurovskoye: 'Moscow Oblast',
	Kurganinsk: 'Krasnodar Krai',
	Kurchatov: 'Kursk Oblast',
	Kurchaloy: 'Chechnya',
	Kupchino: 'St.-Petersburg',
	Kungur: 'Perm Krai',
	Kumertau: 'Bashkortostan Republic',
	Kulebaki: 'Nizhny Novgorod Oblast',
	Kukmor: 'Tatarstan Republic',
	Kudymkar: 'Perm Krai',
	Kudrovo: "Leningradskaya Oblast'",
	Kudepsta: 'Krasnodar Krai',
	Kubinka: 'Moscow Oblast',
	Kstovo: 'Nizhny Novgorod Oblast',
	Krymsk: 'Krasnodar Krai',
	Kropotkin: 'Krasnodar Krai',
	Kronstadt: 'St.-Petersburg',
	'Krasnyy Sulin': 'Rostov',
	'Biryulëvo Zapadnoye': 'Moscow Oblast',
	'Krasnoye Selo': 'St.-Petersburg',
	Krasnovishersk: 'Perm Krai',
	Krasnoufimsk: 'Sverdlovsk Oblast',
	Krasnokamsk: 'Perm Krai',
	Krasnogvardeyskoye: 'Stavropol Kray',
	Krasnogorsk: 'Moscow Oblast',
	Krasnodar: 'Krasnodar Krai',
	Krasnoarmeyskaya: 'Krasnodar Krai',
	Krasnoarmeysk: 'Saratov Oblast',
	Presnenskiy: 'Moscow',
	'Koz’modem’yansk': 'Mariy-El Republic',
	Kozhukhovo: 'Moscow Oblast',
	Kozeyevo: 'Moscow',
	'Kozel’sk': 'Kaluga Oblast',
	Kovylkino: 'Mordoviya Republic',
	Kovrov: 'Vladimir Oblast',
	Kovdor: 'Murmansk',
	Kotovsk: 'Tambov Oblast',
	Kotovo: 'Volgograd Oblast',
	Kotlas: 'Arkhangelskaya',
	'Kotel’nikovo': 'Volgograd Oblast',
	'Kotel’niki': 'Moscow Oblast',
	'Kotel’nich': 'Kirov Oblast',
	Kostroma: 'Kostroma Oblast',
	Kostomuksha: 'Karelia',
	'Kosaya Gora': 'Tula Oblast',
	Koryazhma: 'Arkhangelskaya',
	Korenovsk: 'Krasnodar Krai',
	Konstantinovsk: 'Rostov',
	Kondopoga: 'Karelia',
	Konakovo: 'Tver Oblast',
	Kommunar: "Leningradskaya Oblast'",
	Kolpino: 'St.-Petersburg',
	Kolomyagi: 'St.-Petersburg',
	Kolomna: 'Moscow Oblast',
	Kolomenskoye: 'Moscow',
	'Kol’chugino': 'Vladimir Oblast',
	Kokhma: 'Ivanovo Oblast',
	Klintsy: 'Bryansk Oblast',
	Klin: 'Moscow Oblast',
	Klimovsk: 'Moscow Oblast',
	Kizlyar: 'Dagestan',
	Kizilyurt: 'Dagestan',
	Kizel: 'Perm Krai',
	Kislovodsk: 'Stavropol Kray',
	Kirzhach: 'Vladimir Oblast',
	Kirsanov: 'Tambov Oblast',
	Kirovsk: "Leningradskaya Oblast'",
	'Kirovo-Chepetsk': 'Kirov Oblast',
	Kirov: 'Kaluga Oblast',
	Kirishi: "Leningradskaya Oblast'",
	Kireyevsk: 'Tula Oblast',
	Kingisepp: "Leningradskaya Oblast'",
	Kineshma: 'Ivanovo Oblast',
	'Kinel’-Cherkassy': 'Samara Oblast',
	'Kinel’': 'Samara Oblast',
	Kimry: 'Tver Oblast',
	Kimovsk: 'Tula Oblast',
	"Khot'kovo": 'Moscow Oblast',
	Khosta: 'Krasnodar Krai',
	'Khoroshëvo-Mnevniki': 'Moscow Oblast',
	Kholmskiy: 'Krasnodar Krai',
	Khimki: 'Moscow Oblast',
	Khasavyurt: 'Dagestan',
	Kharabali: 'Astrakhan Oblast',
	Khadyzhensk: 'Krasnodar Krai',
	Kazan: 'Ankara',
	'Katav-Ivanovsk': 'Chelyabinsk',
	Kastanayevo: 'Moscow Oblast',
	Kaspiysk: 'Dagestan',
	Kasimov: 'Ryazan Oblast',
	Kashira: 'Moscow Oblast',
	Kashin: 'Tver Oblast',
	Karachev: 'Bryansk Oblast',
	Karachayevsk: 'Karachayevo-Cherkesiya Republic',
	Karabulak: 'Ingushetiya Republic',
	Karabanovo: 'Vladimir Oblast',
	Kapotnya: 'Moscow',
	Kantyshevo: 'Ingushetiya Republic',
	Kanevskaya: 'Krasnodar Krai',
	Kandalaksha: 'Murmansk',
	Kanash: 'Chuvashia',
	Kamyzyak: 'Astrakhan Oblast',
	Kamyshin: 'Volgograd Oblast',
	'Kamensk-Shakhtinsky': 'Rostov',
	Kaluga: 'Kaluga Oblast',
	Kalininsk: 'Saratov Oblast',
	Korolev: 'Moscow Oblast',
	Kaliningrad: 'Kaliningrad Oblast',
	'Kalach-na-Donu': 'Volgograd Oblast',
	Kalach: 'Voronezh Oblast',
	Kachkanar: 'Sverdlovsk Oblast',
	Kabanovo: 'Moscow Oblast',
	'Izobil’nyy': 'Stavropol Kray',
	Izmaylovo: 'Moscow Oblast',
	Izhevsk: 'Udmurtiya Republic',
	Izberbash: 'Dagestan',
	Ivanteyevka: 'Moscow Oblast',
	Ivanovskoye: 'Moscow',
	Ivanovo: 'Ivanovo Oblast',
	Istra: 'Moscow Oblast',
	Ishimbay: 'Bashkortostan Republic',
	Ipatovo: 'Stavropol Kray',
	Inza: 'Ulyanovsk',
	Inozemtsevo: 'Stavropol Kray',
	'Il’skiy': 'Krasnodar Krai',
	Igra: 'Udmurtiya Republic',
	'Gus’-Khrustal’nyy': 'Vladimir Oblast',
	Gusev: 'Kaliningrad Oblast',
	'Gul’kevichi': 'Krasnodar Krai',
	Gukovo: 'Rostov',
	Gudermes: 'Chechnya',
	Gubkin: 'Belgorod Oblast',
	Gubakha: 'Perm Krai',
	Gryazovets: 'Vologda Oblast',
	Gryazi: 'Lipetsk Oblast',
	Groznyy: 'Chechnya',
	Gribanovskiy: 'Voronezh Oblast',
	Grazhdanka: 'St.-Petersburg',
	'Goryachiy Klyuch': 'Krasnodar Krai',
	Goryachevodskiy: 'Stavropol Kray',
	Gorodishche: 'Volgograd Oblast',
	Gorodets: 'Nizhny Novgorod Oblast',
	Gorelovo: 'St.-Petersburg',
	'Gol’yanovo': 'Moscow',
	Golitsyno: 'Moscow Oblast',
	Glazov: 'Udmurtiya Republic',
	Giaginskaya: 'Adygeya Republic',
	Georgiyevsk: 'Stavropol Kray',
	Gelendzhik: 'Krasnodar Krai',
	Gay: 'Orenburg Oblast',
	'Gavrilov-Yam': 'Yaroslavl Oblast',
	Gatchina: "Leningradskaya Oblast'",
	Galich: 'Kostroma Oblast',
	Gagarin: 'Smolensk Oblast',
	Furmanov: 'Ivanovo Oblast',
	Fryazino: 'Moscow Oblast',
	Fryazevo: 'Moscow Oblast',
	Frolovo: 'Volgograd Oblast',
	Fili: 'Moscow Oblast',
	Ezhva: 'Komi',
	Engels: 'Saratov Oblast',
	Enem: 'Adygeya Republic',
	Elista: 'Kalmykiya Republic',
	Elektrougli: 'Moscow Oblast',
	'Elektrostal’': 'Moscow Oblast',
	Elektrogorsk: 'Moscow Oblast',
	Ekazhevo: 'Ingushetiya Republic',
	Dzerzhinskiy: 'Moscow Oblast',
	Dzerzhinsk: 'Nizhny Novgorod Oblast',
	Dyurtyuli: 'Bashkortostan Republic',
	'Dyat’kovo': 'Bryansk Oblast',
	Dubovka: 'Volgograd Oblast',
	Dubna: 'Moscow Oblast',
	Dorogomilovo: 'Moscow Oblast',
	Donskoye: 'Stavropol Kray',
	Donskoy: 'Tula Oblast',
	Donetsk: 'Donetsk',
	Domodedovo: 'Moscow Oblast',
	Dolgoprudnyy: 'Moscow Oblast',
	Dobryanka: 'Perm Krai',
	Dmitrov: 'Moscow Oblast',
	Divnoye: 'Stavropol Kray',
	Dinskaya: 'Krasnodar Krai',
	Desnogorsk: 'Smolensk Oblast',
	Derbent: 'Dagestan',
	Dedovsk: 'Moscow Oblast',
	Davydkovo: 'Moscow',
	Davlekanovo: 'Bashkortostan Republic',
	Dankov: 'Lipetsk Oblast',
	Danilov: 'Yaroslavl Oblast',
	'Dagestanskiye Ogni': 'Dagestan',
	Dachnoye: 'St.-Petersburg',
	Chusovoy: 'Perm Krai',
	Chudovo: 'Novgorod Oblast',
	'Chistopol’': 'Tatarstan Republic',
	Chishmy: 'Bashkortostan Republic',
	Chernyanka: 'Belgorod Oblast',
	Chernyakhovsk: 'Kaliningrad Oblast',
	Chernushka: 'Perm Krai',
	Chernogolovka: 'Moscow Oblast',
	Cherkessk: 'Karachayevo-Cherkesiya Republic',
	Cherepovets: 'Vologda Oblast',
	Cherëmushki: 'Moscow',
	Chekhov: 'Moscow Oblast',
	Chegem: 'Kabardino-Balkariya Republic',
	Cheboksary: 'Chuvashia',
	Chaykovskiy: 'Perm Krai',
	Chapayevsk: 'Samara Oblast',
	Buzuluk: 'Orenburg Oblast',
	Buynaksk: 'Dagestan',
	Buy: 'Kostroma Oblast',
	Buturlinovka: 'Voronezh Oblast',
	Buinsk: 'Tatarstan Republic',
	Buguruslan: 'Orenburg Oblast',
	Bugulma: 'Tatarstan Republic',
	Budyonnovsk: 'Stavropol Kray',
	Bryukhovetskaya: 'Krasnodar Krai',
	Bryansk: 'Bryansk Oblast',
	Bronnitsy: 'Moscow Oblast',
	Brateyevo: 'Moscow',
	Borovichi: 'Novgorod Oblast',
	Borisoglebsk: 'Voronezh Oblast',
	'Bol’shaya Setun’': 'Moscow Oblast',
	Bologoye: 'Tver Oblast',
	Boksitogorsk: "Leningradskaya Oblast'",
	Boguchar: 'Voronezh Oblast',
	Bogorodskoye: 'Moscow',
	Bogorodsk: 'Nizhny Novgorod Oblast',
	Bogoroditsk: 'Tula Oblast',
	Bobrov: 'Voronezh Oblast',
	Blagoveshchensk: 'Amur Oblast',
	Blagodarnyy: 'Stavropol Kray',
	Biryulëvo: 'Moscow',
	Birsk: 'Bashkortostan Republic',
	Bibirevo: 'Moscow',
	Bezhetsk: 'Tver Oblast',
	Bezenchuk: 'Samara Oblast',
	Beslan: 'North Ossetia–Alania',
	Berezniki: 'Perm Krai',
	Beloretsk: 'Bashkortostan Republic',
	Belorechensk: 'Krasnodar Krai',
	Beloozyorskiy: 'Moscow Oblast',
	Belgorod: 'Belgorod Oblast',
	Belëv: 'Tula Oblast',
	Belebey: 'Bashkortostan Republic',
	'Belaya Kalitva': 'Rostov',
	'Belaya Glina': 'Krasnodar Krai',
	Baymak: 'Bashkortostan Republic',
	Bavly: 'Tatarstan Republic',
	Bataysk: 'Rostov',
	Barysh: 'Ulyanovsk',
	Balezino: 'Udmurtiya Republic',
	Balashov: 'Saratov Oblast',
	Balashikha: 'Moscow Oblast',
	Balakovo: 'Saratov Oblast',
	'Novaya Balakhna': 'Nizhny Novgorod Oblast',
	Balabanovo: 'Kaluga Oblast',
	Baksan: 'Kabardino-Balkariya Republic',
	Bakal: 'Chelyabinsk',
	Bagayevskaya: 'Rostov',
	Babushkin: 'Moscow',
	Azov: 'Rostov',
	Avtury: 'Chechnya',
	Avtovo: 'St.-Petersburg',
	Atkarsk: 'Saratov Oblast',
	Astrakhan: 'Astrakhan Oblast',
	Asha: 'Chelyabinsk',
	Arzgir: 'Stavropol Kray',
	Arzamas: 'Nizhny Novgorod Oblast',
	Arsk: 'Tatarstan Republic',
	'Arkhangel’sk': 'Arkhangelskaya',
	Argun: 'Chechnya',
	Ardon: 'North Ossetia–Alania',
	Apsheronsk: 'Krasnodar Krai',
	Aprelevka: 'Moscow Oblast',
	Apatity: 'Murmansk',
	Anna: 'Voronezh Oblast',
	Andreyevskoye: 'Moscow Oblast',
	Anapa: 'Krasnodar Krai',
	'Amin’yevo': 'Moscow',
	'Al’met’yevsk': 'Tatarstan Republic',
	Aleksin: 'Tula Oblast',
	Alekseyevka: 'Moscow Oblast',
	Alexeyevka: 'Belgorod Oblast',
	Aleksandrovskoye: 'Stavropol Kray',
	Aleksandrovsk: 'Perm Krai',
	Aleksandrov: 'Vladimir Oblast',
	'Alatyr’': 'Chuvashia',
	Levoberezhnyy: 'Moscow Oblast',
	Aksay: 'Rostov',
	Akhtyrskiy: 'Krasnodar Krai',
	Akhtubinsk: 'Astrakhan Oblast',
	Agryz: 'Tatarstan Republic',
	Afipskiy: 'Krasnodar Krai',
	Adler: 'Krasnodar Krai',
	'Achkhoy-Martan': 'Chechnya',
	Abinsk: 'Krasnodar Krai',
	Abdulino: 'Orenburg Oblast',
	'Vasil’yevo': 'Tatarstan Republic',
	'Ryl’sk': 'Kursk Oblast',
	Neftekumsk: 'Stavropol Kray',
	Alagir: 'North Ossetia–Alania',
	Persianovka: 'Rostov',
	Dagomys: 'Krasnodar Krai',
	Pavlovo: 'Nizhny Novgorod Oblast',
	Belidzhi: 'Dagestan',
	Lesnoy: 'Sverdlovsk Oblast',
	Trëkhgornyy: 'Chelyabinsk',
	Mirnyy: 'Arkhangelskaya',
	Znamensk: 'Astrakhan Oblast',
	Kochubeyevskoye: 'Stavropol Kray',
	Vnukovo: 'Moscow',
	Moskovskiy: 'Khatlon',
	Usinsk: 'Komi',
	Obukhovo: 'St.-Petersburg',
	'Staryy Malgobek': 'Ingushetiya Republic',
	Zavodoukovsk: 'Tyumen Oblast',
	Zarinsk: 'Altai Krai',
	Yuzhnyy: 'Altai Krai',
	'Yuzhnoural’sk': 'Chelyabinsk',
	Yurga: 'Kuzbass',
	Yeniseysk: 'Krasnoyarsk Krai',
	Yemanzhelinsk: 'Chelyabinsk',
	Yekaterinburg: 'Sverdlovsk Oblast',
	Yashkino: 'Kuzbass',
	Yarovoye: 'Altai Krai',
	Yalutorovsk: 'Tyumen Oblast',
	Vorkuta: 'Komi',
	Vorgashor: 'Komi',
	'Verkhnyaya Salda': 'Sverdlovsk Oblast',
	'Verkhnyaya Pyshma': 'Sverdlovsk Oblast',
	'Verkhniy Ufaley': 'Chelyabinsk',
	Uzhur: 'Krasnoyarsk Krai',
	Zelenogorsk: 'Krasnoyarsk Krai',
	Uray: 'Khanty-Mansia',
	Tyumen: 'Tyumen Oblast',
	Turinsk: 'Sverdlovsk Oblast',
	Topki: 'Kuzbass',
	Tomsk: 'Tomsk Oblast',
	Toguchin: 'Novosibirsk Oblast',
	Tobolsk: 'Tyumen Oblast',
	Tayshet: 'Irkutsk Oblast',
	Tayga: 'Kuzbass',
	Tavda: 'Sverdlovsk Oblast',
	Tatarsk: 'Novosibirsk Oblast',
	Tashtagol: 'Kuzbass',
	'Tarko-Sale': 'Yamalo-Nenets',
	Tara: 'Omsk Oblast',
	Talnakh: 'Krasnoyarsk Krai',
	'Tal’menka': 'Altai Krai',
	Talitsa: 'Sverdlovsk Oblast',
	'Sysert’': 'Sverdlovsk Oblast',
	Suzun: 'Novosibirsk Oblast',
	Surgut: 'Khanty-Mansia',
	'Sukhoy Log': 'Sverdlovsk Oblast',
	Strezhevoy: 'Tomsk Oblast',
	Sredneuralsk: 'Sverdlovsk Oblast',
	Sovetskiy: 'Khanty-Mansia',
	Sosnovoborsk: 'Krasnoyarsk Krai',
	Slavgorod: 'Altai Krai',
	Shushenskoye: 'Krasnoyarsk Krai',
	Shumikha: 'Kurgan Oblast',
	Sharypovo: 'Krasnoyarsk Krai',
	Shadrinsk: 'Kurgan Oblast',
	Serov: 'Sverdlovsk Oblast',
	Sayanogorsk: 'Khakasiya Republic',
	Salekhard: 'Yamalo-Nenets',
	Rubtsovsk: 'Altai Krai',
	Rezh: 'Sverdlovsk Oblast',
	Reftinskiy: 'Sverdlovsk Oblast',
	Promyshlennaya: 'Kuzbass',
	'Prokop’yevsk': 'Kuzbass',
	Poykovskiy: 'Khanty-Mansia',
	Polysayevo: 'Kuzbass',
	Polevskoy: 'Sverdlovsk Oblast',
	Plast: 'Chelyabinsk',
	Osinniki: 'Kuzbass',
	Omsk: 'Omsk Oblast',
	'Ob’': 'Novosibirsk Oblast',
	Nyagan: 'Khanty-Mansia',
	Noyabrsk: 'Yamalo-Nenets',
	'Novyy Urengoy': 'Yamalo-Nenets',
	Novosilikatnyy: 'Altai Krai',
	Novosibirsk: 'Novosibirsk Oblast',
	Novokuznetsk: 'Kuzbass',
	Novoaltaysk: 'Altai Krai',
	Norilsk: 'Krasnoyarsk Krai',
	'Nizhnyaya Salda': 'Sverdlovsk Oblast',
	Nizhnevartovsk: 'Khanty-Mansia',
	Nizhneudinsk: 'Irkutsk Oblast',
	'Nev’yansk': 'Sverdlovsk Oblast',
	Nefteyugansk: 'Khanty-Mansia',
	Nazarovo: 'Krasnoyarsk Krai',
	Nadym: 'Yamalo-Nenets',
	Myski: 'Kuzbass',
	Minusinsk: 'Krasnoyarsk Krai',
	Miass: 'Chelyabinsk',
	Mezhdurechensk: 'Kuzbass',
	Megion: 'Khanty-Mansia',
	Mayma: 'Altai',
	Mariinsk: 'Kuzbass',
	Linëvo: 'Novosibirsk Oblast',
	Lesosibirsk: 'Krasnoyarsk Krai',
	'Leninsk-Kuznetsky': 'Kuzbass',
	Labytnangi: 'Yamalo-Nenets',
	Kyzyl: 'Republic of Tyva',
	Kyshtym: 'Chelyabinsk',
	Kuybyshev: 'Novosibirsk Oblast',
	Kurtamysh: 'Kurgan Oblast',
	Kurgan: 'Kurgan Oblast',
	Kupino: 'Novosibirsk Oblast',
	Kulunda: 'Altai Krai',
	Krasnoyarsk: 'Krasnoyarsk Krai',
	'Krasnoural’sk': 'Sverdlovsk Oblast',
	Krasnoturinsk: 'Sverdlovsk Oblast',
	Krasnoobsk: 'Novosibirsk Oblast',
	Korkino: 'Chelyabinsk',
	Kopeysk: 'Chelyabinsk',
	Yugorsk: 'Khanty-Mansia',
	Kolpashevo: 'Tomsk Oblast',
	Kodinsk: 'Krasnoyarsk Krai',
	Kochenëvo: 'Novosibirsk Oblast',
	Kiselëvsk: 'Kuzbass',
	Kirovgrad: 'Sverdlovsk Oblast',
	'Khanty-Mansiysk': 'Khanty-Mansia',
	Kemerovo: 'Kuzbass',
	Kedrovka: 'Kuzbass',
	Kayyerkan: 'Krasnoyarsk Krai',
	Kataysk: 'Kurgan Oblast',
	Kasli: 'Chelyabinsk',
	Kartaly: 'Chelyabinsk',
	Karpinsk: 'Sverdlovsk Oblast',
	Karasuk: 'Novosibirsk Oblast',
	Karabash: 'Chelyabinsk',
	Kansk: 'Krasnoyarsk Krai',
	Kamyshlov: 'Sverdlovsk Oblast',
	'Kamensk-Ural’skiy': 'Sverdlovsk Oblast',
	'Kamen’-na-Obi': 'Altai Krai',
	Kaltan: 'Kuzbass',
	Kalachinsk: 'Omsk Oblast',
	'Ivdel’': 'Sverdlovsk Oblast',
	Iskitim: 'Novosibirsk Oblast',
	Ishim: 'Tyumen Oblast',
	Irbit: 'Sverdlovsk Oblast',
	Ilanskiy: 'Krasnoyarsk Krai',
	'Gur’yevsk': 'Kuzbass',
	Gornyak: 'Altai Krai',
	'Gorno-Altaysk': 'Altai',
	Dudinka: 'Krasnoyarsk Krai',
	Divnogorsk: 'Krasnoyarsk Krai',
	Degtyarsk: 'Sverdlovsk Oblast',
	Chunskiy: 'Irkutsk Oblast',
	Chernogorsk: 'Khakasiya Republic',
	Cherepanovo: 'Novosibirsk Oblast',
	Chelyabinsk: 'Chelyabinsk',
	'Chebarkul’': 'Chelyabinsk',
	Borovskiy: 'Tyumen Oblast',
	Borodino: 'Krasnoyarsk Krai',
	Bolotnoye: 'Novosibirsk Oblast',
	Bogotol: 'Krasnoyarsk Krai',
	Bogdanovich: 'Sverdlovsk Oblast',
	Biysk: 'Altai Krai',
	Beryozovsky: 'Sverdlovsk Oblast',
	Berëzovskiy: 'Kuzbass',
	Berëzovka: 'Krasnoyarsk Krai',
	Berdsk: 'Novosibirsk Oblast',
	Beloyarskiy: 'Khanty-Mansia',
	Belovo: 'Kuzbass',
	Barnaul: 'Altai Krai',
	Barabinsk: 'Novosibirsk Oblast',
	Asino: 'Tomsk Oblast',
	Asbest: 'Sverdlovsk Oblast',
	Artëmovskiy: 'Sverdlovsk Oblast',
	Aramil: 'Sverdlovsk Oblast',
	'Anzhero-Sudzhensk': 'Kuzbass',
	Aleysk: 'Altai Krai',
	Alapayevsk: 'Sverdlovsk Oblast',
	Akademgorodok: 'Novosibirsk Oblast',
	Achinsk: 'Krasnoyarsk Krai',
	Abaza: 'Khakasiya Republic',
	Abakan: 'Khakasiya Republic',
	Snezhinsk: 'Chelyabinsk',
	Ozersk: 'Chelyabinsk',
	'Novoural’sk': 'Sverdlovsk Oblast',
	Seversk: 'Tomsk Oblast',
	Gubkinskiy: 'Yamalo-Nenets',
	Raduzhny: 'Khanty-Mansia',
	Muravlenko: 'Yamalo-Nenets',
	Lyantor: 'Khanty-Mansia',
	Zima: 'Irkutsk Oblast',
	'Zheleznogorsk-Ilimskiy': 'Irkutsk Oblast',
	Zeya: 'Amur Oblast',
	Yakutsk: 'Sakha',
	Vyazemskiy: 'Khabarovsk',
	'Vrangel’': 'Primorye',
	Vladivostok: 'Primorye',
	Vikhorevka: 'Irkutsk Oblast',
	'Ust’-Kut': 'Irkutsk Oblast',
	'Ust’-Ilimsk': 'Irkutsk Oblast',
	Ussuriysk: 'Primorye',
	'Usol’ye-Sibirskoye': 'Irkutsk Oblast',
	'Ulan-Ude': 'Buryatiya Republic',
	Udachny: 'Sakha',
	Tynda: 'Amur Oblast',
	Tulun: 'Irkutsk Oblast',
	Trudovoye: 'Primorye',
	Fokino: 'Primorye',
	Svobodnyy: 'Amur Oblast',
	'Spassk-Dal’niy': 'Primorye',
	Slyudyanka: 'Irkutsk Oblast',
	Shimanovsk: 'Amur Oblast',
	Shelekhov: 'Irkutsk Oblast',
	'Severobaykal’sk': 'Buryatiya Republic',
	Raychikhinsk: 'Amur Oblast',
	'Petrovsk-Zabaykal’skiy': 'Zabaykalskiy (Transbaikal) Kray',
	Partizansk: 'Primorye',
	Neryungri: 'Sakha',
	Nerchinsk: 'Zabaykalskiy (Transbaikal) Kray',
	Nakhodka: 'Primorye',
	Mirny: 'Sakha',
	Luchegorsk: 'Primorye',
	Lesozavodsk: 'Primorye',
	Lensk: 'Sakha',
	Kyakhta: 'Buryatiya Republic',
	Krasnokamensk: 'Zabaykalskiy (Transbaikal) Kray',
	'Komsomolsk-on-Amur': 'Khabarovsk',
	Khabarovsk: 'Khabarovsk',
	Kavalerovo: 'Primorye',
	Irkutsk: 'Irkutsk Oblast',
	Gusinoozyorsk: 'Buryatiya Republic',
	Dalnerechensk: 'Primorye',
	Cheremkhovo: 'Irkutsk Oblast',
	Borzya: 'Zabaykalskiy (Transbaikal) Kray',
	'Bol’shoy Kamen’': 'Primorye',
	Bodaybo: 'Irkutsk Oblast',
	Birobidzhan: 'Jewish Autonomous Oblast',
	Bikin: 'Khabarovsk',
	Belogorsk: 'Amur Oblast',
	'Baykal’sk': 'Irkutsk Oblast',
	Aykhal: 'Sakha',
	Artëm: 'Primorye',
	'Arsen’yev': 'Primorye',
	Angarsk: 'Irkutsk Oblast',
	Amursk: 'Khabarovsk',
	Aldan: 'Sakha',
	Aginskoye: 'Zabaykalskiy (Transbaikal) Kray',
	Dalnegorsk: 'Primorye',
	Bratsk: 'Irkutsk Oblast',
	Sayansk: 'Irkutsk Oblast',
	'Khabarovsk Vtoroy': 'Khabarovsk',
	Markova: 'Irkutsk Oblast',
	Vilyuchinsk: 'Kamchatka',
	'Yuzhno-Sakhalinsk': 'Sakhalin Oblast',
	Yelizovo: 'Kamchatka',
	Vanino: 'Khabarovsk',
	'Sovetskaya Gavan’': 'Khabarovsk',
	Poronaysk: 'Sakhalin Oblast',
	'Petropavlovsk-Kamchatsky': 'Kamchatka',
	'Nikolayevsk-on-Amure': 'Khabarovsk',
	'Nevel’sk': 'Sakhalin Oblast',
	Magadan: 'Magadan Oblast',
	Korsakov: 'Sakhalin Oblast',
	Kholmsk: 'Sakhalin Oblast',
	Anadyr: 'Chukotka',
	Baltiysk: 'Kaliningrad Oblast',
	Isakogorka: 'Arkhangelskaya',
	Krasnoznamensk: 'Moscow Oblast',
	'Chertanovo Yuzhnoye': 'Moscow',
	'Zagor’ye': 'Moscow',
	'Orekhovo-Borisovo': 'Moscow',
	Metrogorodok: 'Moscow',
	Kogalym: 'Khanty-Mansia',
	'Pyt-Yakh': 'Khanty-Mansia',
	Langepas: 'Khanty-Mansia',
	Zarya: 'Moscow Oblast',
	Raduzhnyy: 'Vladimir Oblast',
	Nizhnesortymskiy: 'Khanty-Mansia',
	Pokachi: 'Khanty-Mansia',
	Izluchinsk: 'Khanty-Mansia',
	'Krasnaya Glinka': 'Orenburg Oblast',
	Kurortnyy: "Leningradskaya Oblast'",
	'Chernaya Rechka': "Leningradskaya Oblast'",
	Untolovo: "Leningradskaya Oblast'",
	Petrogradka: 'St.-Petersburg',
	"Vasyl'evsky Ostrov": 'St.-Petersburg',
	Parnas: "Leningradskaya Oblast'",
	Kalininskiy: 'St.-Petersburg',
	Krasnogvargeisky: 'St.-Petersburg',
	Admiralteisky: 'St.-Petersburg',
	'Krestovskiy ostrov': "Leningradskaya Oblast'",
	Akademicheskoe: "Leningradskaya Oblast'",
	Finlyandskiy: "Leningradskaya Oblast'",
	Centralniy: 'St.-Petersburg',
	Svetlanovskiy: "Leningradskaya Oblast'",
	Sampsonievskiy: "Leningradskaya Oblast'",
	'Vostochnoe Degunino': 'Moscow',
	Dzerzhinsky: 'Moscow Oblast',
	Obruchevo: 'Moscow',
	Fedorovskiy: 'Khanty-Mansia',
	"Mezgor'e": 'Bashkortostan Republic',
	Rwamagana: 'Eastern Province',
	Musanze: 'Northern Province',
	Nzega: 'Tabora',
	Kigali: 'Kigali',
	Kibuye: 'Western Province',
	Kibungo: 'Eastern Province',
	Gitarama: 'Southern Province',
	Cyangugu: 'Western Province',
	Gisenyi: 'Western Province',
	Byumba: 'Northern Province',
	Butare: 'Southern Province',
	Yanbu: 'Medina Region',
	Umluj: 'Tabuk Region',
	Turaif: 'Northern Borders Region',
	Turabah: 'Mecca Region',
	Tārūt: 'Eastern Province',
	Ţubarjal: 'Al Jawf Region',
	Sulţānah: 'Medina Region',
	Sayhāt: 'Eastern Province',
	Şāmitah: 'Jazan Region',
	Sakakah: 'Al Jawf Region',
	Şafwá: 'Eastern Province',
	Şabyā: 'Jazan Region',
	Raḩīmah: 'Eastern Province',
	Rābigh: 'Mecca Region',
	'Qal‘at Bīshah': 'Asir Region',
	Najrān: 'Najran Region',
	Mecca: 'Mecca Region',
	'Khamis Mushait': 'Asir Region',
	Jizan: 'Jazan Region',
	Jeddah: 'Mecca Region',
	"Ha'il": "Ha'il Region",
	'Hafar Al-Batin': 'Eastern Province',
	Duba: 'Tabuk Region',
	Buraydah: 'Al-Qassim Region',
	Abqaiq: 'Eastern Province',
	'Badr Ḩunayn': 'Medina Region',
	'Az Zulfī': 'Riyadh Region',
	Dhahran: 'Eastern Province',
	'Aţ Ţaraf': 'Eastern Province',
	'Ta’if': 'Mecca Region',
	'As Sulayyil': 'Riyadh Region',
	Riyadh: 'Riyadh Region',
	'Ar Rass': 'Al-Qassim Region',
	Arar: 'Northern Borders Region',
	'An Nimas': 'Asir Region',
	Qurayyat: 'Al Jawf Region',
	'Al Wajh': 'Tabuk Region',
	'Al-`Ula': 'Medina Region',
	Qaisumah: 'Eastern Province',
	'Al Qaţīf': 'Eastern Province',
	'Al Munayzilah': 'Eastern Province',
	'Al Mubarraz': 'Eastern Province',
	'Al Mithnab': 'Al-Qassim Region',
	Khobar: 'Eastern Province',
	'Al Kharj': 'Riyadh Region',
	'Al Khafjī': 'Eastern Province',
	'Al Jumūm': 'Mecca Region',
	'Al Jubayl': 'Eastern Province',
	'Al Hufūf': 'Eastern Province',
	'Al Bukayrīyah': 'Al-Qassim Region',
	'Al Baţţālīyah': 'Eastern Province',
	'Al Bahah': 'Al Bahah Region',
	Afif: 'Riyadh Region',
	'Ad Dilam': 'Riyadh Region',
	'Ad Dawādimī': 'Riyadh Region',
	Dammam: 'Eastern Province',
	'Abū ‘Arīsh': 'Jazan Region',
	Abha: 'Asir Region',
	'Ash Shafā': 'Mecca Region',
	'Sabt Alalayah': 'Asir Region',
	Bariq: 'Asir Region',
	Honiara: 'Honiara',
	Zalingei: 'Central Darfur',
	'Wad Medani': 'Al Jazīrah',
	'Umm Ruwaba': 'North Kordofan',
	Omdurman: 'Khartoum',
	Tokār: 'Red Sea',
	Tandaltī: 'White Nile',
	Singa: 'Sinnār',
	Shendi: 'River Nile',
	Sawākin: 'Red Sea',
	Rabak: 'White Nile',
	Nyala: 'Southern Darfur',
	Maiurno: 'Sinnār',
	Kosti: 'White Nile',
	Kuraymah: 'Northern State',
	Kināna: 'Sinnār',
	Kassala: 'Kassala',
	Kadugli: 'Southern Kordofan',
	Doka: 'Al Qaḑārif',
	Dilling: 'Southern Kordofan',
	'Port Sudan': 'Red Sea',
	Berber: 'River Nile',
	Bārah: 'North Kordofan',
	Atbara: 'River Nile',
	'As Sūkī': 'Sinnār',
	'Ar Ruseris': 'Blue Nile',
	'Ar Rahad': 'North Kordofan',
	'An Nuhūd': 'West Kordofan State',
	'El Obeid': 'North Kordofan',
	'Al Qiţena': 'White Nile',
	'Al Qadarif': 'Al Qaḑārif',
	'Al Mijlad': 'West Kordofan State',
	'Al Manāqil': 'Al Jazīrah',
	Khartoum: 'Khartoum',
	Geneina: 'Western Darfur',
	'Al Hilāliyya': 'Al Jazīrah',
	'Al Ḩawātah': 'Al Qaḑārif',
	'Al Hasaheisa': 'Al Jazīrah',
	'El Fasher': 'Northern Darfur',
	'El Bauga': 'River Nile',
	'Ad Douiem': 'White Nile',
	'El Daein': 'Eastern Darfur',
	'Ad Dindar': 'Sinnār',
	'Ed Damer': 'River Nile',
	'Ad-Damazin': 'Blue Nile',
	'Abū Zabad': 'West Kordofan State',
	'Abu Jibeha': 'Southern Kordofan',
	Gereida: 'Southern Darfur',
	Umeå: 'Västerbotten',
	Skellefteå: 'Västerbotten',
	Piteå: 'Norrbotten',
	Luleå: 'Norrbotten',
	Kiruna: 'Norrbotten',
	Boden: 'Norrbotten',
	Ystad: 'Skåne',
	Visby: 'Gotland',
	Växjö: 'Kronoberg',
	'Västra Frölunda': 'Västra Götaland',
	Västervik: 'Kalmar',
	Västerås: 'Västmanland',
	Vasastan: 'Stockholm',
	Värnamo: 'Jönköping',
	Varberg: 'Halland',
	Vänersborg: 'Västra Götaland',
	Vallentuna: 'Stockholm',
	Uppsala: 'Uppsala',
	'Upplands Väsby': 'Stockholm',
	Uddevalla: 'Västra Götaland',
	Tumba: 'Stockholm',
	Tullinge: 'Stockholm',
	Trollhättan: 'Västra Götaland',
	Trelleborg: 'Skåne',
	Täby: 'Stockholm',
	Sundsvall: 'Västernorrland',
	Sundbyberg: 'Stockholm',
	Stockholm: 'Stockholm',
	Staffanstorp: 'Skåne',
	Solna: 'Stockholm',
	Sollentuna: 'Stockholm',
	Södertälje: 'Stockholm',
	Södermalm: 'Stockholm',
	Skövde: 'Västra Götaland',
	Sandviken: 'Gävleborg',
	Råsunda: 'Stockholm',
	Partille: 'Västra Götaland',
	Östersund: 'Jämtland',
	Östermalm: 'Stockholm',
	Oskarshamn: 'Kalmar',
	Örnsköldsvik: 'Västernorrland',
	Örebro: 'Örebro',
	Nyköping: 'Södermanland',
	Norrtälje: 'Stockholm',
	Norrköping: 'Östergötland',
	Nässjö: 'Jönköping',
	Nacka: 'Stockholm',
	Motala: 'Östergötland',
	Mölnlycke: 'Västra Götaland',
	Mölndal: 'Västra Götaland',
	Märsta: 'Stockholm',
	Mariestad: 'Västra Götaland',
	Malmö: 'Skåne',
	Lund: 'Skåne',
	Ludvika: 'Dalarna',
	Ljungby: 'Kronoberg',
	Linköping: 'Östergötland',
	Lidköping: 'Västra Götaland',
	Lidingö: 'Stockholm',
	Lerum: 'Västra Götaland',
	Landskrona: 'Skåne',
	Kungsholmen: 'Stockholm',
	Kungsbacka: 'Halland',
	Kungälv: 'Västra Götaland',
	Kumla: 'Örebro',
	Kristinehamn: 'Värmland',
	Kristianstad: 'Skåne',
	Köping: 'Västmanland',
	Kinna: 'Västra Götaland',
	Katrineholm: 'Södermanland',
	Karlstad: 'Värmland',
	Karlskrona: 'Blekinge',
	Karlskoga: 'Örebro',
	Karlshamn: 'Blekinge',
	Kalmar: 'Kalmar',
	Jönköping: 'Jönköping',
	Jakobsberg: 'Stockholm',
	Huskvarna: 'Jönköping',
	Hudiksvall: 'Gävleborg',
	Huddinge: 'Stockholm',
	Helsingborg: 'Skåne',
	Hässleholm: 'Skåne',
	Härnösand: 'Västernorrland',
	Haninge: 'Stockholm',
	Halmstad: 'Halland',
	Gustavsberg: 'Stockholm',
	Göteborg: 'Västra Götaland',
	Gävle: 'Gävleborg',
	'Gamla Uppsala': 'Uppsala',
	Falun: 'Dalarna',
	Falköping: 'Västra Götaland',
	Falkenberg: 'Halland',
	Eslöv: 'Skåne',
	Eskilstuna: 'Södermanland',
	Enköping: 'Uppsala',
	Bromma: 'Stockholm',
	Borlänge: 'Dalarna',
	Borås: 'Västra Götaland',
	Boo: 'Stockholm',
	Björlanda: 'Västra Götaland',
	Årsta: 'Stockholm',
	Ängelholm: 'Skåne',
	Alingsås: 'Västra Götaland',
	Åkersberga: 'Stockholm',
	Majorna: 'Västra Götaland',
	Tensta: 'Stockholm',
	Singapore: '',
	Geylang: '',
	Woodlands: '',
	'Marine Parade': '',
	'Queenstown Estate': '',
	Jamestown: 'New York',
	Trbovlje: 'Trbovlje',
	Velenje: 'Velenje',
	Ptuj: 'Ptuj',
	'Novo Mesto': 'Novo Mesto',
	Maribor: 'Maribor',
	Ljubljana: 'Ljubljana',
	Kranj: 'Kranj',
	Koper: 'Koper-Capodistria',
	Celje: 'Celje',
	Longyearbyen: 'Svalbard',
	'Vranov nad Topľou': 'Prešovský kraj',
	Trebišov: 'Košický kraj',
	'Stará Ľubovňa': 'Prešovský kraj',
	'Spišská Nová Ves': 'Košický kraj',
	Snina: 'Prešovský kraj',
	Rožňava: 'Košický kraj',
	'Rimavská Sobota': 'Banskobystrický kraj',
	Prešov: 'Prešovský kraj',
	Poprad: 'Prešovský kraj',
	Michalovce: 'Košický kraj',
	Košice: 'Košický kraj',
	Kežmarok: 'Prešovský kraj',
	Humenné: 'Prešovský kraj',
	Bardejov: 'Prešovský kraj',
	Zvolen: 'Banskobystrický kraj',
	'Zlaté Moravce': 'Nitriansky kraj',
	Žilina: 'Žilinský kraj',
	'Žiar nad Hronom': 'Banskobystrický kraj',
	Trnava: 'Trnava',
	Trenčín: 'Trenčiansky kraj',
	Stupava: 'Bratislavský Kraj',
	'Staré Mesto': 'Bratislavský Kraj',
	Skalica: 'Trnava',
	Senica: 'Trnava',
	Šaľa: 'Nitriansky kraj',
	Ružomberok: 'Žilinský kraj',
	Púchov: 'Trenčiansky kraj',
	Prievidza: 'Trenčiansky kraj',
	'Považská Bystrica': 'Trenčiansky kraj',
	Piešťany: 'Trnava',
	Pezinok: 'Bratislavský Kraj',
	Partizánske: 'Trenčiansky kraj',
	'Nové Zámky': 'Nitriansky kraj',
	'Nové Mesto nad Váhom': 'Trenčiansky kraj',
	Nitra: 'Nitriansky kraj',
	Martin: 'Žilinský kraj',
	Malacky: 'Bratislavský Kraj',
	Lučenec: 'Banskobystrický kraj',
	'Liptovský Mikuláš': 'Žilinský kraj',
	Levice: 'Nitriansky kraj',
	'Kysucké Nové Mesto': 'Žilinský kraj',
	Komárno: 'Nitriansky kraj',
	Hlohovec: 'Trnava',
	Handlová: 'Trenčiansky kraj',
	Galanta: 'Trnava',
	'Dunajská Streda': 'Trnava',
	'Dubnica nad Váhom': 'Trenčiansky kraj',
	'Dolný Kubín': 'Žilinský kraj',
	Čadca: 'Žilinský kraj',
	Brezno: 'Banskobystrický kraj',
	Bratislava: 'Bratislavský Kraj',
	'Banská Bystrica': 'Banskobystrický kraj',
	'Bánovce nad Bebravou': 'Trenčiansky kraj',
	Segbwema: 'Eastern Province',
	'Port Loko': 'North West',
	Makeni: 'Northern Province',
	Lunsar: 'Northern Province',
	Koidu: 'Eastern Province',
	Kenema: 'Eastern Province',
	Kabala: 'Northern Province',
	Freetown: 'Western Area',
	Bumpe: 'Southern Province',
	Bo: 'Southern Province',
	Goderich: 'Western Area',
	'San Marino': 'San Marino',
	Ziguinchor: 'Ziguinchor',
	Vélingara: 'Kolda',
	Tiébo: 'Diourbel',
	'Thiès Nones': 'Thiès',
	Thiès: 'Thiès',
	Tambacounda: 'Tambacounda',
	Sédhiou: 'Sédhiou',
	'Richard-Toll': 'Saint-Louis',
	Pout: 'Thiès',
	Pourham: 'Fatick',
	Pikine: 'Dakar',
	'Nioro du Rip': 'Kaolack',
	Nguékhokh: 'Thiès',
	'Ndibène Dahra': 'Louga',
	Mékhé: 'Thiès',
	Mbaké: 'Diourbel',
	Matam: 'Matam',
	Louga: 'Louga',
	Kolda: 'Kolda',
	Kédougou: 'Kédougou',
	Kayar: 'Thiès',
	Kaolack: 'Kaolack',
	Kaffrine: 'Kaffrine',
	'Joal-Fadiout': 'Thiès',
	Guinguinéo: 'Fatick',
	Dara: 'Louga',
	Dakar: 'Dakar',
	Bignona: 'Ziguinchor',
	'N’diareme limamoulaye': 'Dakar',
	Wanlaweyn: 'Lower Shabeelle',
	Qoryooley: 'Lower Shabeelle',
	Qandala: 'Bari',
	Mogadishu: 'Banaadir',
	Marka: 'Lower Shabeelle',
	Luuq: 'Gedo',
	Kismayo: 'Lower Juba',
	Jilib: 'Middle Juba',
	Jawhar: 'Middle Shabele',
	Jamaame: 'Lower Juba',
	Hargeysa: 'Woqooyi Galbeed',
	Garoowe: 'Nugaal',
	Gaalkacyo: 'Mudug',
	Eyl: 'Nugaal',
	Diinsoor: 'Bay',
	Ceerigaabo: 'Sanaag',
	Ceeldheer: 'Galguduud',
	Cabudwaaq: 'Galguduud',
	Buurhakaba: 'Bay',
	Buulobarde: 'Hiiraan',
	Burao: 'Togdheer',
	Bosaso: 'Bari',
	Berbera: 'Woqooyi Galbeed',
	Beledweyne: 'Hiiraan',
	Baidoa: 'Bay',
	Baardheere: 'Gedo',
	Afgooye: 'Lower Shabeelle',
	Balanbale: 'Galguduud',
	Laascaanood: 'Sool',
	Saaxo: 'Mudug',
	Dhabad: 'Galguduud',
	'Beled Hawo': 'Gedo',
	Paramaribo: 'Paramaribo',
	Lelydorp: 'Wanica',
	Yei: 'Central Equatoria',
	Yambio: 'Western Equatoria',
	Winejok: 'Northern Bahr al Ghazal',
	Wau: 'Western Bahr al Ghazal',
	Aweil: 'Northern Bahr al Ghazal',
	Torit: 'Eastern Equatoria',
	Tonj: 'Warrap',
	Rumbek: 'Lakes',
	Malakal: 'Upper Nile',
	Kuacjok: 'Warrap',
	'Kafia Kingi': 'Western Bahr al Ghazal',
	Juba: 'Central Equatoria',
	Gogrial: 'Warrap',
	Pajok: 'Eastern Equatoria',
	'São Tomé': 'São Tomé Island',
	Zacatecoluca: 'La Paz',
	Usulután: 'Usulután',
	Soyapango: 'San Salvador',
	Sonzacate: 'Sonsonate',
	Sonsonate: 'Sonsonate',
	Sensuntepeque: 'Cabañas',
	'Santiago de María': 'Usulután',
	'San Salvador': 'San Salvador',
	'San Rafael Oriente': 'San Miguel',
	Quezaltepeque: 'La Libertad',
	'Puerto El Triunfo': 'Usulután',
	'Santa Tecla': 'La Libertad',
	Metapán: 'Santa Ana',
	Mejicanos: 'San Salvador',
	Izalco: 'Sonsonate',
	Ilopango: 'San Salvador',
	Delgado: 'San Salvador',
	Cuscatancingo: 'San Salvador',
	Cojutepeque: 'Cuscatlán',
	Chalchuapa: 'Santa Ana',
	Chalatenango: 'Chalatenango',
	Ayutuxtepeque: 'San Salvador',
	Apopa: 'San Salvador',
	'Antiguo Cuscatlán': 'La Libertad',
	Ahuachapán: 'Ahuachapán',
	Acajutla: 'Sonsonate',
	Philipsburg: '',
	Yabrūd: 'Rif-dimashq',
	'Ţayyibat al Imām': 'Hama',
	Ţarţūs: 'Tartus',
	'Tall Rif‘at': 'Aleppo',
	Tallkalakh: 'Homs',
	Tallbīsah: 'Homs',
	Ţafas: 'Daraa',
	Tadmur: 'Homs',
	Tādif: 'Aleppo',
	Şūrān: 'Hama',
	Subaykhān: 'Deir ez-Zor',
	'Shaykh Miskīn': 'Daraa',
	Saraqib: 'Idlib',
	Salqīn: 'Idlib',
	'As Salamīyah': 'Hama',
	Şāfītā: 'Tartus',
	Qaţanā: 'Rif-dimashq',
	Jāsim: 'Daraa',
	Qārah: 'Rif-dimashq',
	Kabbasin: 'Aleppo',
	Nubl: 'Aleppo',
	Maşyāf: 'Hama',
	Manbij: 'Aleppo',
	'Ma‘arratmişrīn': 'Idlib',
	'Khān Shaykhūn': 'Idlib',
	'Kafr Zaytā': 'Hama',
	'Kafr Takhārīm': 'Idlib',
	Kafranbel: 'Idlib',
	'Kafr Lāhā': 'Homs',
	'Jisr ash Shughūr': 'Idlib',
	Jayrūd: 'Rif-dimashq',
	Jarābulus: 'Aleppo',
	Jablah: 'Latakia',
	'‘Irbīn': 'Rif-dimashq',
	Inkhil: 'Daraa',
	Idlib: 'Idlib',
	Homs: 'Homs',
	Ḩarastā: 'Rif-dimashq',
	Ḩamāh: 'Hama',
	Ḩalfāyā: 'Hama',
	Aleppo: 'Aleppo',
	Dūmā: 'Rif-dimashq',
	Damascus: 'Maryland',
	'Dayr Ḩāfir': 'Aleppo',
	'Deir ez-Zor': 'Deir ez-Zor',
	'Ad Darbāsīyah': 'Al-Hasakah',
	Dārayyā: 'Rif-dimashq',
	'Dar‘ā': 'Daraa',
	Binnish: 'Idlib',
	Batabo: 'Aleppo',
	Bāniyās: 'Tartus',
	'Az Zabadānī': 'Rif-dimashq',
	'I‘zāz': 'Aleppo',
	'‘Ayn al ‘Arab': 'Aleppo',
	'At Tall': 'Rif-dimashq',
	'Ath Thawrah': 'Ar-Raqqah',
	'As-Suwayda': 'As-Suwayda',
	'Aş Şanamayn': 'Daraa',
	'As Safīrah': 'Aleppo',
	'Ar Rastan': 'Homs',
	'Ar Raqqah': 'Ar-Raqqah',
	'An Nabk': 'Rif-dimashq',
	'Al Quţayfah': 'Rif-dimashq',
	'Al Qunayţirah': 'Quneitra',
	'Al Qaryatayn': 'Homs',
	'Al Mayādīn': 'Deir ez-Zor',
	Latakia: 'Latakia',
	'Al Kiswah': 'Rif-dimashq',
	'Al Ḩasakah': 'Al-Hasakah',
	'Al Ḩirāk': 'Daraa',
	'Al Bāb': 'Aleppo',
	'‘Afrīn': 'Aleppo',
	'Ad Dānā': 'Idlib',
	'Ālbū Kamāl': 'Deir ez-Zor',
	Hajīn: 'Deir ez-Zor',
	Mbabane: 'Hhohho',
	Manzini: 'Manzini',
	Lobamba: 'Hhohho',
	'Cockburn Town': '',
	Fada: 'Ennedi-Ouest',
	'Am-Timan': 'Salamat',
	Abéché: 'Ouadaï',
	Sarh: 'Moyen-Chari',
	Pala: 'Mayo-Kebbi Ouest',
	'Oum Hadjer': 'Batha',
	"N'Djamena": 'N’Djaména',
	Moussoro: 'Barh el Gazel',
	Moundou: 'Logone Occidental',
	Mongo: 'Guéra',
	'Mboursou Léré': 'Mayo-Kebbi Ouest',
	Massakory: 'Hadjer-Lamis',
	Massaguet: 'Hadjer-Lamis',
	Laï: 'Tandjilé',
	Kyabé: 'Moyen-Chari',
	Koumra: 'Mandoul',
	Kelo: 'Tandjilé',
	Dourbali: 'Chari-Baguirmi',
	Doba: 'Logone Oriental',
	Bongor: 'Mayo-Kebbi Est',
	Bitkine: 'Guéra',
	Benoy: 'Logone Occidental',
	Ati: 'Batha',
	'Port-aux-Français': 'Kerguelen',
	Vogan: 'Maritime',
	Tsévié: 'Maritime',
	Tchamba: 'Centrale',
	Sotouboua: 'Centrale',
	Sokodé: 'Centrale',
	Notsé: 'Plateaux',
	Niamtougou: 'Kara',
	Mango: 'Savanes',
	Lomé: 'Maritime',
	Kpalimé: 'Plateaux',
	Kara: 'Kara',
	Dapaong: 'Savanes',
	Bassar: 'Kara',
	Bafilo: 'Kara',
	Badou: 'Plateaux',
	Atakpamé: 'Plateaux',
	Aného: 'Maritime',
	'Ban Talat Yai': 'Phuket',
	'Ban Talat Nua': 'Phuket',
	'Sam Roi Yot': 'Prachuap Khiri Khan',
	Phetchaburi: 'Phetchaburi',
	Trang: 'Trang',
	'Thung Song': 'Nakhon Si Thammarat',
	Thoen: 'Lampang',
	'Thap Than': 'Uthai Thani',
	'Tha Muang': 'Kanchanaburi',
	'Tha Maka': 'Kanchanaburi',
	Tak: 'Tak',
	'Surat Thani': 'Surat Thani',
	Sukhothai: 'Sukhothai',
	'Si Satchanalai': 'Sukhothai',
	Sawankhalok: 'Sukhothai',
	'San Pa Tong': 'Chiang Mai',
	'San Kamphaeng': 'Chiang Mai',
	'Ron Phibun': 'Nakhon Si Thammarat',
	Ratchaburi: 'Ratchaburi',
	Ranong: 'Ranong',
	'Pran Buri': 'Prachuap Khiri Khan',
	'Prachuap Khiri Khan': 'Prachuap Khiri Khan',
	'Tha Kham': 'Surat Thani',
	Phuket: 'Phuket',
	Photharam: 'Ratchaburi',
	Phayao: 'Phayao',
	'Pa Sang': 'Lamphun',
	'Nakhon Si Thammarat': 'Nakhon Si Thammarat',
	'Mae Sot': 'Tak',
	'Mae Sai': 'Chiang Rai',
	'Mae Ramat': 'Tak',
	'Mae Chan': 'Chiang Rai',
	'Lat Yao': 'Nakhon Sawan',
	'Lang Suan': 'Chumphon',
	Lamphun: 'Lamphun',
	Lampang: 'Lampang',
	'Kui Buri': 'Prachuap Khiri Khan',
	Krabi: 'Krabi',
	'Khao Yoi': 'Phetchaburi',
	'Khanu Woralaksaburi': 'Kamphaeng Phet',
	Kathu: 'Phuket',
	Kanchanaburi: 'Kanchanaburi',
	'Kamphaeng Phet': 'Kamphaeng Phet',
	'Huai Yot': 'Trang',
	'Hua Hin': 'Prachuap Khiri Khan',
	'Hang Dong': 'Chiang Mai',
	'Dok Kham Tai': 'Phayao',
	'Damnoen Saduak': 'Ratchaburi',
	Chumphon: 'Chumphon',
	'Chom Bueng': 'Ratchaburi',
	'Chiang Rai': 'Chiang Rai',
	'Chiang Mai': 'Chiang Mai',
	'Cha-am': 'Phetchaburi',
	'Bo Phloi': 'Kanchanaburi',
	'Ban Tak': 'Tak',
	'Ko Samui': 'Surat Thani',
	'Ban Pong': 'Ratchaburi',
	'Ban Na San': 'Surat Thani',
	'Ban Na': 'Sukhothai',
	'Bang Saphan': 'Prachuap Khiri Khan',
	'Bang Phae': 'Ratchaburi',
	'Nong Kung Si': 'Kalasin',
	'Ban Nong Wua So': 'Udon Thani',
	'Ban Mai': 'Songkhla',
	'Ban Huai Thalaeng': 'Nakhon Ratchasima',
	'Ban Khlong Bang Sao Thong': 'Samut Prakan',
	'Na Klang': 'Nong Bua Lam Phu',
	Yasothon: 'Yasothon',
	Yaring: 'Pattani',
	Yala: 'Yala',
	'Wiset Chaichan': 'Ang Thong',
	'Wichian Buri': 'Phetchabun',
	'Warin Chamrap': 'Ubon Ratchathani',
	'Wang Saphung': 'Loei',
	'Wang Noi': 'Phra Nakhon Si Ayutthaya',
	'Wang Nam Yen': 'Sa Kaeo',
	Uttaradit: 'Uttaradit',
	'Uthai Thani': 'Uthai Thani',
	'Udon Thani': 'Udon Thani',
	'Ubon Ratchathani': 'Ubon Ratchathani',
	Trat: 'Trat',
	'Tha Yang': 'Phetchaburi',
	'Tha Ruea': 'Phra Nakhon Si Ayutthaya',
	'Thap Khlo': 'Phichit',
	'Tha Mai': 'Chanthaburi',
	'Tha Bo': 'Nong Khai',
	'Taphan Hin': 'Phichit',
	'Tak Bai': 'Narathiwat',
	Surin: 'Surin',
	'Suphan Buri': 'Suphanburi',
	'Su-ngai Kolok': 'Narathiwat',
	Songkhla: 'Songkhla',
	'Si Sa Ket': 'Si Sa Ket',
	'Si Racha': 'Chon Buri',
	'Sing Buri': 'Sing Buri',
	Seka: 'Bueng Kan',
	'Sawang Daen Din': 'Sakon Nakhon',
	Satun: 'Satun',
	Sattahip: 'Chon Buri',
	Saraburi: 'Saraburi',
	'Samut Songkhram': 'Samut Songkhram',
	'Samut Sakhon': 'Samut Sakhon',
	'Samut Prakan': 'Samut Prakan',
	'Sam Phran': 'Nakhon Pathom',
	'Sakon Nakhon': 'Sakon Nakhon',
	'Sa Kaeo': 'Sa Kaeo',
	Sadao: 'Songkhla',
	'Roi Et': 'Roi Et',
	Rayong: 'Rayong',
	Ranot: 'Songkhla',
	'Ra-ngae': 'Narathiwat',
	'Prakhon Chai': 'Buriram',
	'Prachin Buri': 'Prachin Buri',
	'Phu Kradueng': 'Loei',
	'Phu Khiao': 'Chaiyaphum',
	'Phra Pradaeng': 'Samut Prakan',
	'Phra Phutthabat': 'Saraburi',
	'Phra Nakhon Si Ayutthaya': 'Phra Nakhon Si Ayutthaya',
	Phrae: 'Phrae',
	'Phon Charoen': 'Nong Khai',
	Phitsanulok: 'Phitsanulok',
	Phichit: 'Phichit',
	'Phibun Mangsahan': 'Ubon Ratchathani',
	Phetchabun: 'Phetchabun',
	Phatthalung: 'Phatthalung',
	'Phasi Charoen': 'Bangkok',
	'Phan Thong': 'Chon Buri',
	'Phanom Sarakham': 'Chachoengsao',
	'Phanat Nikhom': 'Chon Buri',
	'Phak Hai': 'Phra Nakhon Si Ayutthaya',
	Pattani: 'Pattani',
	'Pathum Thani': 'Pathum Thani',
	'Pak Phanang': 'Nakhon Si Thammarat',
	'Pak Kret': 'Nonthaburi',
	'Pak Chong': 'Nakhon Ratchasima',
	'Mueang Nonthaburi': 'Nonthaburi',
	'Non Sung': 'Nakhon Ratchasima',
	'Nong Phai': 'Phetchabun',
	'Nong Khai': 'Nong Khai',
	'Nong Khae': 'Saraburi',
	'Nong Bua Lamphu': 'Nong Bua Lam Phu',
	Narathiwat: 'Narathiwat',
	'Nang Rong': 'Buriram',
	Nan: 'Nan',
	'Nam Som': 'Udon Thani',
	'Nakhon Sawan': 'Nakhon Sawan',
	'Nakhon Ratchasima': 'Nakhon Ratchasima',
	'Nakhon Phanom': 'Nakhon Phanom',
	'Nakhon Pathom': 'Nakhon Pathom',
	'Nakhon Nayok': 'Nakhon Nayok',
	'Nakhon Luang': 'Phra Nakhon Si Ayutthaya',
	Mukdahan: 'Mukdahan',
	Makkasan: 'Bangkok',
	'Maha Sarakham': 'Maha Sarakham',
	'Lop Buri': 'Lopburi',
	'Lom Sak': 'Phetchabun',
	Loei: 'Loei',
	'Laem Sing': 'Chanthaburi',
	'Kut Chap': 'Udon Thani',
	Kuchinarai: 'Kalasin',
	Bangkok: 'Bangkok',
	'Krathum Baen': 'Samut Sakhon',
	Klaeng: 'Rayong',
	'Khon Kaen': 'Khon Kaen',
	'Khon Buri': 'Nakhon Ratchasima',
	'Khlong Luang': 'Pathum Thani',
	'Khao Wong': 'Kalasin',
	'Kaset Wisai': 'Roi Et',
	'Kaset Sombun': 'Chaiyaphum',
	Kantharalak: 'Si Sa Ket',
	Kamalasai: 'Kalasin',
	Kalasin: 'Kalasin',
	'Kaeng Khoi': 'Saraburi',
	'Kaeng Khro': 'Chaiyaphum',
	'Kabin Buri': 'Prachin Buri',
	'Hat Yai': 'Songkhla',
	'Det Udom': 'Ubon Ratchathani',
	'Den Chai': 'Phrae',
	'Dan Khun Thot': 'Nakhon Ratchasima',
	'Chum Phae': 'Khon Kaen',
	'Chon Daen': 'Phetchabun',
	'Chon Buri': 'Chon Buri',
	'Chok Chai': 'Nakhon Ratchasima',
	Chanthaburi: 'Chanthaburi',
	Chaiyaphum: 'Chaiyaphum',
	'Chai Nat': 'Chai Nat',
	'Chai Badan': 'Lopburi',
	Chachoengsao: 'Chachoengsao',
	'Buri Ram': 'Buriram',
	'Bua Yai': 'Nakhon Ratchasima',
	Betong: 'Yala',
	'Ban Selaphum': 'Roi Et',
	'Ban Phe': 'Rayong',
	Pattaya: 'Chon Buri',
	'Ban Phan Don': 'Udon Thani',
	'Ban Phai': 'Khon Kaen',
	'Ban Phaeo': 'Samut Sakhon',
	'Ban Mo': 'Saraburi',
	'Ban Lam Luk Ka': 'Pathum Thani',
	'Bang Rakam': 'Phitsanulok',
	'Bang Racham': 'Sing Buri',
	'Bang Pakong': 'Chachoengsao',
	'Bang Pa-in': 'Phra Nakhon Si Ayutthaya',
	'Bang Mun Nak': 'Phichit',
	'Bang Len': 'Nakhon Pathom',
	'Bang Lamung': 'Chon Buri',
	'Bang Kruai': 'Nonthaburi',
	'Bang Krathum': 'Phitsanulok',
	'Bang Bua Thong': 'Nonthaburi',
	'Bang Ban': 'Phra Nakhon Si Ayutthaya',
	'Ban Dung': 'Udon Thani',
	'Ban Chang': 'Rayong',
	'Ban Bueng': 'Chon Buri',
	'Ban Talat Bueng': 'Chon Buri',
	'Ban Bang Kadi Pathum Thani': 'Phra Nakhon Si Ayutthaya',
	'Bang Bo District': 'Samut Prakan',
	Aranyaprathet: 'Sa Kaeo',
	'Amnat Charoen': 'Amnat Charoen',
	'Amphoe Sikhiu': 'Nakhon Ratchasima',
	Wichit: 'Phuket',
	'Ban Chalong': 'Phuket',
	'Ban Ratsada': 'Phuket',
	Yovon: 'Khatlon',
	Hulbuk: 'Khatlon',
	Vakhsh: 'Khatlon',
	Istaravshan: 'Sughd',
	Bokhtar: 'Khatlon',
	Panjakent: 'Sughd',
	Farkhor: 'Khatlon',
	Vahdat: 'Republican Subordination',
	Norak: 'Khatlon',
	Kŭlob: 'Khatlon',
	Kolkhozobod: 'Khatlon',
	Khorugh: 'Gorno-Badakhshan',
	Ishqoshim: 'Gorno-Badakhshan',
	Hisor: 'Republican Subordination',
	Dushanbe: 'Dushanbe',
	Danghara: 'Khatlon',
	Chubek: 'Khatlon',
	Boshkengash: 'Dushanbe',
	Tursunzoda: 'Republican Subordination',
	Taboshar: 'Sughd',
	Proletar: 'Sughd',
	Khujand: 'Sughd',
	Qayroqqum: 'Sughd',
	Konibodom: 'Sughd',
	Isfara: 'Sughd',
	Buston: 'Sughd',
	Suai: 'Cova Lima',
	Maubara: 'Liquiçá',
	Maliana: 'Bobonaro',
	Likisá: 'Liquiçá',
	Dili: 'Díli',
	Baukau: 'Baucau',
	Aileu: 'Aileu',
	Lospalos: 'Lautém',
	Venilale: 'Baucau',
	Balkanabat: 'Balkan',
	Kaka: 'Ahal',
	Gumdag: 'Balkan',
	Bereket: 'Balkan',
	Baharly: 'Ahal',
	Ashgabat: 'Ashgabat',
	Änew: 'Ahal',
	Yylanly: 'Daşoguz',
	Tagta: 'Daşoguz',
	Türkmenbaşy: 'Balkan',
	Köneürgench: 'Daşoguz',
	Boldumsaz: 'Daşoguz',
	Daşoguz: 'Daşoguz',
	Yolöten: 'Mary',
	Tejen: 'Ahal',
	Seydi: 'Mary',
	Saýat: 'Lebap',
	Mary: 'Mary',
	Kerki: 'Lebap',
	Gowurdak: 'Lebap',
	Türkmenabat: 'Lebap',
	Bayramaly: 'Mary',
	Gazojak: 'Lebap',
	Zaghouan: 'Zaghouan Governorate',
	'Oued Lill': 'Manouba',
	Tunis: 'Tunis Governorate',
	Tozeur: 'Tozeur Governorate',
	Tataouine: 'Tataouine',
	Thala: 'Kasserine Governorate',
	Takelsa: 'Nabeul Governorate',
	Tajerouine: 'Kef Governorate',
	Sousse: 'Sousse Governorate',
	Siliana: 'Siliana Governorate',
	'Sidi Bouzid': 'Sidi Bouzid Governorate',
	Skanes: 'Monastir Governorate',
	Sfax: 'Sfax Governorate',
	'La Sebala du Mornag': 'Ben Arous Governorate',
	Radès: 'Ben Arous Governorate',
	'Ksour Essaf': 'Mahdia Governorate',
	Kélibia: 'Nabeul Governorate',
	Kebili: 'Kebili Governorate',
	'Ksar Hellal': 'Monastir Governorate',
	Carthage: 'Tunis Governorate',
	'El Fahs': 'Zaghouan Governorate',
	'Galaat el Andeless': 'Ariana Governorate',
	Gafsa: 'Gafsa',
	Gabès: 'Gabès Governorate',
	Nefta: 'Tozeur Governorate',
	Nabeul: 'Nabeul Governorate',
	Midoun: 'Medenine Governorate',
	Mateur: 'Bizerte Governorate',
	Msaken: 'Sousse Governorate',
	'Menzel Jemil': 'Bizerte Governorate',
	'Mennzel Bou Zelfa': 'Nabeul Governorate',
	'Menzel Bourguiba': 'Bizerte Governorate',
	'Menzel Abderhaman': 'Bizerte Governorate',
	Manouba: 'Manouba',
	'Medjez el Bab': 'Béja Governorate',
	Medenine: 'Medenine Governorate',
	Jendouba: 'Jendouba Governorate',
	Zarzis: 'Medenine Governorate',
	Djemmal: 'Monastir Governorate',
	'Houmt El Souk': 'Medenine Governorate',
	'Hammam Sousse': 'Sousse Governorate',
	'Hammam-Lif': 'Ben Arous Governorate',
	'La Goulette': 'Tunis Governorate',
	Ghardimaou: 'Jendouba Governorate',
	Douz: 'Kebili Governorate',
	'Douar Tindja': 'Bizerte Governorate',
	'Dar Chabanne': 'Nabeul Governorate',
	'Ben Arous': 'Ben Arous Governorate',
	Bizerte: 'Bizerte Governorate',
	'Beni Khiar': 'Nabeul Governorate',
	Béja: 'Béja Governorate',
	Zouila: 'Mahdia Governorate',
	Chebba: 'Mahdia Governorate',
	Ariana: 'Ariana Governorate',
	'Ar Rudayyif': 'Gafsa',
	Ouardenine: 'Monastir Governorate',
	Kairouan: 'Kairouan',
	Kasserine: 'Kasserine Governorate',
	Gremda: 'Sfax Governorate',
	Monastir: 'Monastir Governorate',
	'La Mohammedia': 'Tunis Governorate',
	Metlaoui: 'Gafsa',
	'Al Marsá': 'Tunis Governorate',
	Mahdia: 'Mahdia Governorate',
	'El Kef': 'Kef Governorate',
	'El Jem': 'Mahdia Governorate',
	'El Hamma': 'Gabès Governorate',
	Bekalta: 'Monastir Governorate',
	'El Alia': 'Bizerte Governorate',
	Akouda: 'Sousse Governorate',
	Douane: 'Nabeul Governorate',
	Ezzouhour: 'Sousse Governorate',
	'Nuku‘alofa': 'Tongatapu',
	Yüksekova: 'Hakkâri',
	Yozgat: 'Yozgat',
	Yeşilli: 'Mardin',
	Erzin: 'Hatay',
	Yerköy: 'Yozgat',
	Didim: 'Aydın',
	Yatağan: 'Muğla',
	Yalvaç: 'Isparta',
	Yahyalı: 'Kayseri',
	Viranşehir: 'Şanlıurfa',
	Varto: 'Muş',
	Van: 'Van',
	Cimin: 'Erzincan',
	Uşak: 'Uşak',
	Urla: 'İzmir Province',
	Ürgüp: 'Nevşehir Province',
	Şanlıurfa: 'Şanlıurfa',
	Turgutlu: 'Manisa',
	Tunceli: 'Tunceli',
	Torbalı: 'İzmir Province',
	Tire: 'İzmir Province',
	Tekirova: 'Antalya',
	Tavşanlı: 'Kütahya',
	Tatvan: 'Bitlis',
	Tarsus: 'Mersin',
	Susurluk: 'Balıkesir',
	Suruç: 'Şanlıurfa',
	Sorgun: 'Yozgat',
	Soma: 'Manisa',
	Solhan: 'Bingöl',
	Söke: 'Aydın',
	Siverek: 'Şanlıurfa',
	Sivas: 'Sivas',
	Şırnak: 'Şırnak',
	Simav: 'Kütahya',
	Silvan: 'Diyarbakır Province',
	Silopi: 'Şırnak',
	Silifke: 'Mersin',
	Siirt: 'Siirt',
	Seydişehir: 'Konya',
	Serinyol: 'Hatay',
	Serik: 'Antalya',
	Şereflikoçhisar: 'Ankara',
	Senirkent: 'Isparta',
	Şemdinli: 'Hakkâri',
	Selçuk: 'İzmir Province',
	Seferihisar: 'İzmir Province',
	Şarkışla: 'Sivas',
	Şarkîkaraağaç: 'Isparta',
	Sarayköy: 'Denizli',
	Sandıklı: 'Afyonkarahisar Province',
	Salihli: 'Manisa',
	Reyhanlı: 'Hatay',
	Polatlı: 'Ankara',
	Pazarcık: 'Kahramanmaraş',
	Patnos: 'Ağrı',
	Pasinler: 'Erzurum',
	Osmaniye: 'Osmaniye',
	Ortaköy: 'Aksaray',
	Ortaca: 'Muğla',
	Ödemiş: 'İzmir Province',
	Nusaybin: 'Mardin',
	Nizip: 'Gaziantep',
	Niğde: 'Niğde Province',
	Nevşehir: 'Nevşehir Province',
	Nazilli: 'Aydın',
	Mut: 'Mersin',
	Muş: 'Muş',
	Muğla: 'Muğla',
	Mucur: 'Kırşehir',
	Milas: 'Muğla',
	Midyat: 'Mardin',
	Mersin: 'Mersin',
	Menemen: 'İzmir Province',
	Marmaris: 'Muğla',
	Mardin: 'Mardin',
	Manisa: 'Manisa',
	Manavgat: 'Antalya',
	Malazgirt: 'Muş',
	Malatya: 'Malatya',
	Mahmutlar: 'Antalya',
	Lice: 'Diyarbakır Province',
	Kütahya: 'Kütahya',
	Kuşadası: 'Aydın',
	Kurtalan: 'Siirt',
	Beykonak: 'Antalya',
	Kulp: 'Diyarbakır Province',
	Kula: 'Manisa',
	Kozluk: 'Batman',
	Kozan: 'Adana',
	Kovancılar: 'Elazığ',
	Korkuteli: 'Antalya',
	Konya: 'Konya',
	Kızıltepe: 'Mardin',
	Serinhisar: 'Denizli',
	Kırşehir: 'Kırşehir',
	Kırkağaç: 'Manisa',
	Kırıkkale: 'Kırıkkale',
	Kırıkhan: 'Hatay',
	Kilis: 'Kilis',
	Keskin: 'Kırıkkale',
	Kemer: 'Antalya',
	Kemalpaşa: 'İzmir Province',
	Kayseri: 'Kayseri',
	Karapınar: 'Konya',
	Karaman: 'Karaman',
	Ağrı: 'Ağrı',
	Karakoçan: 'Elazığ',
	Karaçoban: 'Erzurum',
	Kaman: 'Kırşehir',
	Kâhta: 'Adıyaman Province',
	Kahramanmaraş: 'Kahramanmaraş',
	Kadirli: 'Osmaniye',
	Kadınhanı: 'Konya',
	İzmir: 'İzmir Province',
	Isparta: 'Isparta',
	İskenderun: 'Hatay',
	İncirliova: 'Aydın',
	İmamoğlu: 'Adana',
	Ilgın: 'Konya',
	Iğdır: 'Iğdır',
	İdil: 'Şırnak',
	Hizan: 'Bitlis',
	Hınıs: 'Erzurum',
	Hilvan: 'Şanlıurfa',
	Hadim: 'Konya',
	Hacılar: 'Kayseri',
	Güroymak: 'Bitlis',
	Gölbaşı: 'Adıyaman Province',
	Göksun: 'Kahramanmaraş',
	Genç: 'Bingöl',
	Gemerek: 'Sivas',
	Gediz: 'Kütahya',
	Gazipaşa: 'Antalya',
	Gaziantep: 'Gaziantep',
	Foça: 'İzmir Province',
	Fethiye: 'Muğla',
	Ezine: 'Canakkale',
	Eskişehir: 'Eskişehir',
	Erzurum: 'Erzurum',
	Erzincan: 'Erzincan',
	Ermenek: 'Karaman',
	Ergani: 'Diyarbakır Province',
	Ereğli: 'Zonguldak',
	Erdemli: 'Mersin',
	Erciş: 'Van',
	Emirdağ: 'Afyonkarahisar Province',
	Emet: 'Kütahya',
	Elmalı: 'Antalya',
	Elmadağ: 'Ankara',
	Eleşkirt: 'Ağrı',
	Elbistan: 'Kahramanmaraş',
	Elazığ: 'Elazığ',
	Eğirdir: 'Isparta',
	Edremit: 'Balıkesir',
	Dursunbey: 'Balıkesir',
	Dörtyol: 'Hatay',
	Doğubayazıt: 'Ağrı',
	Diyarbakır: 'Diyarbakır Province',
	Diyadin: 'Ağrı',
	Dinar: 'Afyonkarahisar Province',
	Develi: 'Kayseri',
	Denizli: 'Denizli',
	Demirci: 'Manisa',
	Dargeçit: 'Mardin',
	Darende: 'Malatya',
	Çumra: 'Konya',
	Menderes: 'İzmir Province',
	Hakkâri: 'Hakkâri',
	Cizre: 'Şırnak',
	Çine: 'Aydın',
	Ceylanpınar: 'Şanlıurfa',
	Ceyhan: 'Adana',
	Çeşme: 'İzmir Province',
	Çermik: 'Diyarbakır Province',
	Çay: 'Afyonkarahisar Province',
	Çağlayancerit: 'Kahramanmaraş',
	Burhaniye: 'Balıkesir',
	Burdur: 'Burdur',
	Bulanık: 'Muş',
	Bucak: 'Burdur',
	Bozyazı: 'Mersin',
	Bozüyük: 'Bilecik',
	Bozova: 'Şanlıurfa',
	Bolvadin: 'Afyonkarahisar Province',
	Bodrum: 'Muğla',
	Bitlis: 'Bitlis',
	Bismil: 'Diyarbakır Province',
	Birecik: 'Şanlıurfa',
	Bingöl: 'Bingöl',
	Bigadiç: 'Balıkesir',
	Beyşehir: 'Konya',
	Besni: 'Adıyaman Province',
	Bergama: 'İzmir Province',
	Belek: 'Antalya',
	Bayındır: 'İzmir Province',
	Batman: 'Batman',
	Baskil: 'Elazığ',
	Banaz: 'Uşak',
	Balıkesir: 'Balıkesir',
	Ayvalık: 'Balıkesir',
	Aydın: 'Aydın',
	Aşkale: 'Erzurum',
	Antalya: 'Antalya',
	Antakya: 'Hatay',
	Ankara: 'Ankara',
	Anamur: 'Mersin',
	Aliağa: 'İzmir Province',
	Alaşehir: 'Manisa',
	Alanya: 'Antalya',
	Akşehir: 'Konya',
	Aksaray: 'Aksaray',
	Akhisar: 'Manisa',
	Akdağmadeni: 'Yozgat',
	Akçakale: 'Şanlıurfa',
	Ahlat: 'Bitlis',
	Afyonkarahisar: 'Afyonkarahisar Province',
	Afşin: 'Kahramanmaraş',
	Adıyaman: 'Adıyaman Province',
	Adilcevaz: 'Bitlis',
	Adana: 'Adana',
	Denizciler: 'Hatay',
	Batikent: 'Ankara',
	Dalaman: 'Muğla',
	Zonguldak: 'Zonguldak',
	Zile: 'Tokat',
	Zeytinburnu: 'Istanbul',
	Yomra: 'Trabzon',
	Yenişehir: 'Bursa Province',
	Körfez: 'Kocaeli',
	Yalova: 'Yalova',
	Yakuplu: 'Istanbul',
	Vezirköprü: 'Samsun',
	Vakfıkebir: 'Trabzon',
	Uzunköprü: 'Edirne',
	Üsküdar: 'Istanbul',
	Ünye: 'Ordu',
	Umraniye: 'Istanbul',
	Turhal: 'Tokat',
	Trabzon: 'Trabzon',
	Tosya: 'Kastamonu',
	Tokat: 'Tokat',
	Tirebolu: 'Giresun',
	Terme: 'Samsun',
	Tepecik: 'Istanbul',
	Tekkeköy: 'Samsun',
	Tekirdağ: 'Tekirdağ',
	Taşova: 'Amasya',
	Taşköprü: 'Kastamonu',
	Suşehri: 'Sivas',
	Sürmene: 'Trabzon',
	Sungurlu: 'Çorum',
	Suluova: 'Amasya',
	Şişli: 'Istanbul',
	Silivri: 'Istanbul',
	'Şebin Karahisar': 'Giresun',
	Sarıkamış: 'Kars Province',
	Sapanca: 'Sakarya',
	Samsun: 'Samsun',
	Safranbolu: 'Karabük Province',
	Rize: 'Rize Province',
	Osmaneli: 'Bilecik',
	Osmancık: 'Çorum',
	Orhangazi: 'Bursa Province',
	Ordu: 'Ordu',
	Oltu: 'Erzurum',
	Of: 'Trabzon',
	Niksar: 'Tokat',
	Nallıhan: 'Ankara',
	Mustafakemalpaşa: 'Bursa Province',
	Mudanya: 'Bursa Province',
	Mimarsinan: 'Istanbul',
	Merzifon: 'Amasya',
	Maltepe: 'Istanbul',
	Malkara: 'Tekirdağ',
	Lüleburgaz: 'Kırklareli',
	Kumru: 'Ordu',
	Korgan: 'Ordu',
	Kocaali: 'Sakarya',
	Kızılcahamam: 'Ankara',
	Kırklareli: 'Kırklareli',
	Kestel: 'Bursa Province',
	Keşan: 'Edirne',
	Kelkit: 'Gümüşhane Province',
	Kavaklı: 'Istanbul',
	Kastamonu: 'Kastamonu',
	Kars: 'Kars Province',
	Karasu: 'Sakarya',
	'Karasu Mahallesi': 'Sakarya',
	Karamürsel: 'Kocaeli',
	Karacabey: 'Bursa Province',
	Karabük: 'Karabük Province',
	Kağızman: 'Kars Province',
	İznik: 'Bursa Province',
	İzmit: 'Kocaeli',
	Istanbul: 'Istanbul',
	İskilip: 'Çorum',
	İnegol: 'Bursa Province',
	Horasan: 'Erzurum',
	Hopa: 'Artvin',
	Hendek: 'Sakarya',
	Hayrabolu: 'Tekirdağ',
	Havza: 'Samsun',
	Gürsu: 'Bursa Province',
	Gürpınar: 'Istanbul',
	Gürgentepe: 'Ordu',
	Gümüşhane: 'Gümüşhane Province',
	Görele: 'Giresun',
	Gönen: 'Balıkesir',
	Gölcük: 'Kocaeli',
	Giresun: 'Giresun',
	Geyve: 'Sakarya',
	Gerede: 'Bolu',
	Gemlik: 'Bursa Province',
	Gelibolu: 'Canakkale',
	Gebze: 'Kocaeli',
	Ferizli: 'Sakarya',
	Fatsa: 'Ordu',
	Esenyurt: 'Istanbul',
	Esenler: 'Istanbul',
	Erdek: 'Balıkesir',
	Erbaa: 'Tokat',
	Eminönü: 'Istanbul',
	Edirne: 'Edirne',
	Düzce: 'Düzce',
	Devrek: 'Zonguldak',
	Derince: 'Kocaeli',
	Çubuk: 'Ankara',
	Çorum: 'Çorum',
	Çorlu: 'Tekirdağ',
	Çerkezköy: 'Tekirdağ',
	Çerkeş: 'Çankırı',
	Çayeli: 'Rize Province',
	Çaycuma: 'Zonguldak',
	Çatalca: 'Istanbul',
	Çarşamba: 'Samsun',
	Çankırı: 'Çankırı',
	Çanakkale: 'Canakkale',
	Çan: 'Canakkale',
	Bursa: 'Bursa Province',
	Bulancak: 'Giresun',
	Boyabat: 'Sinop',
	Bolu: 'Bolu',
	Bilecik: 'Bilecik',
	Biga: 'Canakkale',
	Beypazarı: 'Ankara',
	Beşikdüzü: 'Trabzon',
	Bayburt: 'Bayburt Province',
	Bartın: 'Bartın',
	Bandırma: 'Balıkesir',
	Bağcılar: 'Istanbul',
	Bafra: 'Samsun',
	Babaeski: 'Kırklareli',
	Artvin: 'Artvin',
	Arsin: 'Trabzon',
	Arnavutköy: 'Istanbul',
	Arhavi: 'Artvin',
	Ardeşen: 'Rize Province',
	Ardahan: 'Ardahan',
	Araklı: 'Trabzon',
	Amasya: 'Amasya',
	Alaplı: 'Zonguldak',
	Alaca: 'Çorum',
	Akyurt: 'Ankara',
	Akyazı: 'Sakarya',
	Akçakoca: 'Düzce',
	Akçaabat: 'Trabzon',
	Adapazarı: 'Sakarya',
	Espiye: 'Giresun',
	'Merter Keresteciler': 'Istanbul',
	'güngören merter': 'Istanbul',
	Turgutreis: 'Muğla',
	Sarigerme: 'Muğla',
	Ataşehir: 'Istanbul',
	Başakşehir: 'Istanbul',
	Beylikdüzü: 'Istanbul',
	Büyükçekmece: 'Istanbul',
	Çankaya: 'Ankara',
	Bahçelievler: 'Istanbul',
	Sultangazi: 'Istanbul',
	Sultanbeyli: 'Istanbul',
	Sancaktepe: 'Istanbul',
	Karabağlar: 'İzmir Province',
	Muratpaşa: 'Antalya',
	Merkezefendi: 'Denizli',
	Tunapuna: 'Tunapuna/Piarco',
	'Sangre Grande': 'Sangre Grande',
	'Port of Spain': 'Port of Spain',
	'Point Fortin': 'Point Fortin',
	Paradise: 'Nevada',
	'Mon Repos': 'San Fernando',
	Marabella: 'San Fernando',
	Laventille: 'San Juan/Laventille',
	Chaguanas: 'Chaguanas',
	Arima: 'Borough of Arima',
	Funafuti: 'Funafuti',
	Douliu: 'Taiwan',
	Yongkang: 'Taiwan',
	Yujing: 'Taiwan',
	Yuanlin: 'Taiwan',
	Yingge: 'Taipei',
	Wufeng: 'Taiwan',
	Donggang: 'Taiwan',
	Taipei: 'Taiwan',
	Tainan: 'Taiwan',
	Taichung: 'Taiwan',
	Daxi: 'Taiwan',
	Shulin: 'Taipei',
	Sanxia: 'Taipei',
	Sanzhi: 'Taipei',
	Puli: 'Taiwan',
	Bade: 'Taiwan',
	Neihu: 'Taiwan',
	Nantou: 'Taiwan',
	Magong: 'Taiwan',
	Lugu: 'Taiwan',
	Kaohsiung: 'Takao',
	'Hualien City': 'Taiwan',
	Hsinchu: 'Taiwan',
	Xizhi: 'Taipei',
	Hengchun: 'Taiwan',
	Keelung: 'Taiwan',
	'Taoyuan City': 'Taiwan',
	'Zhongxing New Village': 'Taiwan',
	Zanzibar: 'Zanzibar Urban/West',
	Wete: 'Pemba North',
	Vwawa: 'Songwe',
	Vikindu: 'Pwani',
	Uyovu: 'Geita',
	Uvinza: 'Kigoma',
	Ushirombo: 'Geita',
	Usevia: 'Katavi',
	Usagara: 'Mwanza',
	'Usa River': 'Arusha',
	Urambo: 'Tabora',
	Tunduma: 'Songwe',
	Tumbi: 'Tabora',
	Tukuyu: 'Mbeya',
	Tinde: 'Shinyanga',
	Tarime: 'Mara',
	Tanga: 'Tanga',
	Tabora: 'Tabora',
	Sumbawanga: 'Rukwa',
	Songwa: 'Shinyanga',
	Somanda: 'Simiyu',
	Sokoni: 'Zanzibar Central/South',
	Sirari: 'Mara',
	Singida: 'Singida',
	Sikonge: 'Tabora',
	Shinyanga: 'Shinyanga',
	Shelui: 'Singida',
	Sepuka: 'Singida',
	Same: 'Kilimanjaro',
	Rulenge: 'Kagera',
	Rujewa: 'Mbeya',
	Puma: 'Singida',
	'Old Shinyanga': 'Shinyanga',
	Nyamuswa: 'Mara',
	Nyalikungu: 'Simiyu',
	Nyakabindi: 'Simiyu',
	Nsunga: 'Kagera',
	Nshamba: 'Kagera',
	Njombe: 'Njombe',
	Nguruka: 'Kigoma',
	Ngudu: 'Mwanza',
	Ngerengere: 'Morogoro',
	Ngara: 'Kagera',
	Nangwa: 'Manyara',
	Namanyere: 'Rukwa',
	Mwanza: 'Mwanza',
	Mwadui: 'Shinyanga',
	Mvomero: 'Pwani',
	Musoma: 'Mara',
	Muriti: 'Mara',
	Mungaa: 'Singida',
	Muheza: 'Tanga',
	Mugumu: 'Mara',
	Mtwango: 'Njombe',
	'Mto wa Mbu': 'Arusha',
	Mtinko: 'Singida',
	Msowero: 'Morogoro',
	Mpwapwa: 'Dodoma',
	Mpanda: 'Katavi',
	Moshi: 'Kilimanjaro',
	Morogoro: 'Morogoro',
	Mlowo: 'Songwe',
	Mlimba: 'Morogoro',
	Mlangali: 'Njombe',
	Mlandizi: 'Pwani',
	Mlalo: 'Tanga',
	Mkuranga: 'Pwani',
	Mwandiga: 'Kigoma',
	Misungwi: 'Mwanza',
	Mikumi: 'Morogoro',
	Mhango: 'Shinyanga',
	Mgandu: 'Singida',
	Mbuguni: 'Arusha',
	Mbeya: 'Mbeya',
	Mazinde: 'Tanga',
	Matui: 'Tanga',
	Matai: 'Rukwa',
	Maswa: 'Simiyu',
	Masumbwe: 'Geita',
	Maramba: 'Tanga',
	Malinyi: 'Morogoro',
	Malampaka: 'Simiyu',
	Makuyuni: 'Tanga',
	Makumbako: 'Njombe',
	Mahanje: 'Ruvuma',
	Kihangara: 'Mwanza',
	Magugu: 'Manyara',
	Magomeni: 'Dar es Salaam',
	Magole: 'Morogoro',
	Mafinga: 'Iringa',
	Mabama: 'Tabora',
	Lushoto: 'Tanga',
	Lugoba: 'Pwani',
	Liwale: 'Lindi',
	Lembeni: 'Kilimanjaro',
	Lalago: 'Simiyu',
	Laela: 'Rukwa',
	Kyela: 'Mbeya',
	Kondoa: 'Dodoma',
	Kiwira: 'Mbeya',
	Kishapu: 'Shinyanga',
	Kisesa: 'Simiyu',
	Kirando: 'Rukwa',
	Kiomboi: 'Singida',
	Kingori: 'Arusha',
	Kilosa: 'Morogoro',
	Kigoma: 'Kigoma',
	Kidodi: 'Morogoro',
	Kidatu: 'Morogoro',
	Kibondo: 'Kigoma',
	Kibiti: 'Pwani',
	Kibara: 'Mara',
	Kibakwe: 'Dodoma',
	Kibaha: 'Pwani',
	Katumba: 'Mbeya',
	Katoro: 'Geita',
	Katerero: 'Kagera',
	Kasulu: 'Kigoma',
	Kasamwa: 'Geita',
	Kiratu: 'Arusha',
	Kamachumu: 'Kagera',
	Kakonko: 'Kigoma',
	Kahama: 'Shinyanga',
	Kabanga: 'Kagera',
	Izazi: 'Iringa',
	Itigi: 'Singida',
	Isaka: 'Shinyanga',
	Iringa: 'Iringa',
	Ipinda: 'Mbeya',
	Ilula: 'Iringa',
	Ilongero: 'Singida',
	Ilembula: 'Njombe',
	Ikungi: 'Singida',
	Igurusi: 'Mbeya',
	Igunga: 'Tabora',
	Igugunu: 'Singida',
	Ifakara: 'Morogoro',
	Hedaru: 'Kilimanjaro',
	Geita: 'Geita',
	Geiro: 'Morogoro',
	Galappo: 'Manyara',
	Dongobesh: 'Manyara',
	Dodoma: 'Dodoma',
	'Dar es Salaam': 'Dar es Salaam',
	Dareda: 'Manyara',
	Chimala: 'Mbeya',
	Chato: 'Geita',
	Chanika: 'Tanga',
	Chalinze: 'Pwani',
	Chala: 'Rukwa',
	'Chake Chake': 'Pemba South',
	Butiama: 'Mara',
	Buseresere: 'Geita',
	Bungu: 'Pwani',
	Bunda: 'Mara',
	Bukoba: 'Kagera',
	Bugarama: 'Kagera',
	Biharamulo: 'Kagera',
	Basotu: 'Manyara',
	Bashanet: 'Manyara',
	Bariadi: 'Simiyu',
	Bagamoyo: 'Pwani',
	Babati: 'Manyara',
	Arusha: 'Arusha',
	Tingi: 'Ruvuma',
	Tandahimba: 'Mtwara',
	Songea: 'Ruvuma',
	Nyangao: 'Lindi',
	'Newala Kisimani': 'Mtwara',
	Nanyamba: 'Mtwara',
	Nangomba: 'Mtwara',
	Nanganga: 'Mtwara',
	Nachingwea: 'Lindi',
	Mtwara: 'Mtwara',
	Mbinga: 'Ruvuma',
	Matiri: 'Ruvuma',
	Masasi: 'Mtwara',
	Maposeni: 'Ruvuma',
	Lukuledi: 'Mtwara',
	Luchingu: 'Mtwara',
	Lindi: 'Lindi',
	Kitama: 'Mtwara',
	Kigonsera: 'Ruvuma',
	Merelani: 'Arusha',
	Lebedyn: 'Sumy',
	Druzhkivka: 'Donetsk',
	Zvenihorodka: 'Cherkasy',
	Zuhres: 'Donetsk',
	Zolotonosha: 'Cherkasy',
	Zolochiv: 'Lviv',
	Znamyanka: 'Kirovohrad',
	Zhytomyr: 'Zhytomyr',
	Zhmerynka: 'Vinnytsia',
	'Zhovti Vody': 'Dnipropetrovsk',
	Zdolbuniv: 'Rivne',
	Zaporizhzhya: 'Zaporizhzhia',
	Yuzhne: 'Odessa',
	Yevpatoriya: 'Crimea',
	Yenakiieve: 'Donetsk',
	Yasynuvata: 'Donetsk',
	Yalta: 'Crimea',
	Yahotyn: 'Kiev',
	Vyshhorod: 'Kiev',
	Vynohradiv: 'Transcarpathia',
	Voznesensk: 'Mykolaiv',
	Volochysk: 'Khmelnytskyi',
	Volnovakha: 'Donetsk',
	Vilnohirsk: 'Dnipropetrovsk',
	Vovchansk: 'Kharkiv',
	'Volodymyr-Volynskyi': 'Volyn',
	Vyshneve: 'Kiev',
	Vinnytsya: 'Vinnytsia',
	Vilnyansk: 'Zaporizhzhia',
	Verkhnodniprovsk: 'Dnipropetrovsk',
	Vatutine: 'Cherkasy',
	Vasylkiv: 'Kiev',
	Uzhgorod: 'Transcarpathia',
	Uman: 'Cherkasy',
	Tulchyn: 'Vinnytsia',
	Oleshky: 'Kherson',
	Truskavets: 'Lviv',
	Trostianets: 'Sumy',
	Chystyakove: 'Donetsk',
	Tokmak: 'Zaporizhzhia',
	Ternivka: 'Dnipropetrovsk',
	Ternopil: 'Ternopil',
	Sievierodonetsk: 'Luhansk',
	Svitlovodsk: 'Kirovohrad',
	Dovzhansk: 'Luhansk',
	Svatove: 'Luhansk',
	Svalyava: 'Transcarpathia',
	Sumy: 'Sumy',
	Sudak: 'Crimea',
	Stryi: 'Lviv',
	Stebnyk: 'Lviv',
	Starokostyantyniv: 'Khmelnytskyi',
	Starobilsk: 'Luhansk',
	Kadiyivka: 'Luhansk',
	Sokal: 'Lviv',
	Snizhne: 'Donetsk',
	Smila: 'Cherkasy',
	Sloviansk: 'Donetsk',
	Slavuta: 'Khmelnytskyi',
	Skvyra: 'Kiev',
	Skadovsk: 'Kherson',
	Synelnykove: 'Dnipropetrovsk',
	Simferopol: 'Crimea',
	Shpola: 'Cherkasy',
	Shostka: 'Sumy',
	Shepetivka: 'Khmelnytskyi',
	Shakhtarsk: 'Donetsk',
	Sevastopol: 'Sevastopol City',
	Selydove: 'Donetsk',
	Sarny: 'Rivne',
	Sambir: 'Lviv',
	Rubizhne: 'Luhansk',
	Rozdilna: 'Odessa',
	Kurakhove: 'Donetsk',
	Rovenky: 'Luhansk',
	Romny: 'Sumy',
	Rivne: 'Rivne',
	Rakhiv: 'Transcarpathia',
	Radomyshl: 'Zhytomyr',
	Pyatykhatky: 'Dnipropetrovsk',
	Pryluky: 'Chernihiv',
	Popasna: 'Luhansk',
	Poltava: 'Poltava',
	Polonne: 'Khmelnytskyi',
	Polohy: 'Zaporizhzhia',
	Pidhorodne: 'Dnipropetrovsk',
	Pervomaiskyi: 'Kharkiv',
	Pervomaisk: 'Luhansk',
	Pervomaysk: 'Mykolaiv',
	Pershotravensk: 'Dnipropetrovsk',
	Pereiaslav: 'Kiev',
	Perevalsk: 'Luhansk',
	Pavlohrad: 'Dnipropetrovsk',
	Ovruch: 'Zhytomyr',
	Oleksandriya: 'Kirovohrad',
	Odesa: 'Odessa',
	Obukhiv: 'Kiev',
	Novovolynsk: 'Volyn',
	Novoukrayinka: 'Kirovohrad',
	Zviahel: 'Zhytomyr',
	'Nova Kakhovka': 'Kherson',
	Nizhyn: 'Chernihiv',
	Nikopol: 'Dnipropetrovsk',
	Netishyn: 'Khmelnytskyi',
	Nadvirna: 'Ivano-Frankivsk',
	Mykolayiv: 'Mykolaiv',
	Mukachevo: 'Transcarpathia',
	Mostyska: 'Lviv',
	Molodohvardiisk: 'Luhansk',
	'Mohyliv-Podilskyy': 'Vinnytsia',
	Myrhorod: 'Poltava',
	Merefa: 'Kharkiv',
	Melitopol: 'Zaporizhzhia',
	Mariupol: 'Donetsk',
	Marhanets: 'Dnipropetrovsk',
	Malyn: 'Zhytomyr',
	Makiivka: 'Donetsk',
	Lyubotyn: 'Kharkiv',
	Lviv: 'Lviv',
	Lutuhyne: 'Luhansk',
	Lutsk: 'Volyn',
	Luhansk: 'Luhansk',
	Lubny: 'Poltava',
	Lozova: 'Kharkiv',
	Lysychansk: 'Luhansk',
	Ladyzhyn: 'Vinnytsia',
	Kyiv: 'Kyiv City',
	Kurakhovo: 'Donetsk',
	Kupiansk: 'Kharkiv',
	'Kryvyy Rih': 'Dnipropetrovsk',
	Krolevets: 'Sumy',
	Kreminna: 'Luhansk',
	Kremenets: 'Ternopil',
	Kremenchuk: 'Poltava',
	Khrustalnyi: 'Luhansk',
	Lyman: 'Donetsk',
	'Yany Kapu': 'Crimea',
	Krasnohrad: 'Kharkiv',
	Sorokyne: 'Luhansk',
	Pokrovsk: 'Donetsk',
	Krasyliv: 'Khmelnytskyi',
	Kramatorsk: 'Donetsk',
	Kivsharivka: 'Kharkiv',
	Kovel: 'Volyn',
	Kotsiubynske: 'Kyiv City',
	Podilsk: 'Odessa',
	Kostopil: 'Rivne',
	'Korsun-Shevchenkivskyy': 'Cherkasy',
	Korostyshiv: 'Zhytomyr',
	Korosten: 'Zhytomyr',
	Kostiantynivka: 'Donetsk',
	Konotop: 'Sumy',
	'Slobozhans’ke': 'Kharkiv',
	Kolomyia: 'Ivano-Frankivsk',
	Khrestivka: 'Donetsk',
	Holubivka: 'Luhansk',
	Kropyvnytskyy: 'Kirovohrad',
	Kiliya: 'Odessa',
	Khust: 'Transcarpathia',
	Khmelnytskyi: 'Khmelnytskyi',
	Khmilnyk: 'Vinnytsia',
	Kherson: 'Kherson',
	Khartsyzk: 'Donetsk',
	Kharkiv: 'Kharkiv',
	Kerch: 'Crimea',
	Kozyatyn: 'Vinnytsia',
	Kaniv: 'Cherkasy',
	'Kamianets-Podilskyi': 'Khmelnytskyi',
	Kalush: 'Ivano-Frankivsk',
	Kalynivka: 'Vinnytsia',
	Kakhovka: 'Kherson',
	Izium: 'Kharkiv',
	Iziaslav: 'Khmelnytskyi',
	Izmail: 'Odessa',
	'Ivano-Frankivsk': 'Ivano-Frankivsk',
	Irpin: 'Kiev',
	'Ilovays’k': 'Donetsk',
	'Chornomors’k': 'Odessa',
	Horodok: 'Lviv',
	Horlivka: 'Donetsk',
	Hlukhiv: 'Sumy',
	Hostomel: 'Kiev',
	Henichesk: 'Kherson',
	Haysyn: 'Vinnytsia',
	Hadyach: 'Poltava',
	Feodosiya: 'Crimea',
	Fastiv: 'Kiev',
	Enerhodar: 'Zaporizhzhia',
	Dzhankoi: 'Crimea',
	Toretsk: 'Donetsk',
	Dunayivtsi: 'Khmelnytskyi',
	Dubno: 'Rivne',
	Drohobych: 'Lviv',
	Dolyna: 'Ivano-Frankivsk',
	Dolynska: 'Kirovohrad',
	Dokuchaievsk: 'Donetsk',
	Dobropillia: 'Donetsk',
	Dnipro: 'Dnipropetrovsk',
	Kamyanske: 'Dnipropetrovsk',
	Dniprorudne: 'Zaporizhzhia',
	Myrnohrad: 'Donetsk',
	Derhachi: 'Kharkiv',
	Debaltseve: 'Donetsk',
	Chuhuiv: 'Kharkiv',
	Chortkiv: 'Ternopil',
	Chervonopartyzansk: 'Luhansk',
	Chervonohrad: 'Lviv',
	Chernivtsi: 'Chernivtsi',
	Chernihiv: 'Chernihiv',
	Cherkasy: 'Cherkasy',
	Bucha: 'Kiev',
	Brianka: 'Luhansk',
	Brovary: 'Kiev',
	Brody: 'Lviv',
	Boiarka: 'Kiev',
	Boryspil: 'Kiev',
	Boryslav: 'Lviv',
	Bolhrad: 'Odessa',
	Bohuslav: 'Kiev',
	Bohodukhiv: 'Kharkiv',
	'Bilhorod-Dnistrovskyi': 'Odessa',
	'Bila Tserkva': 'Kiev',
	Berezhany: 'Ternopil',
	Berezan: 'Kiev',
	Berehove: 'Transcarpathia',
	Berdychiv: 'Zhytomyr',
	Berdyansk: 'Zaporizhzhia',
	Bilopillia: 'Sumy',
	Bilohirsk: 'Crimea',
	Balta: 'Odessa',
	Balakliia: 'Kharkiv',
	Balaklava: 'Sevastopol City',
	Bakhmach: 'Chernihiv',
	Bakhchysarai: 'Crimea',
	Avdiivka: 'Donetsk',
	Bakhmut: 'Donetsk',
	Armyansk: 'Crimea',
	Apostolove: 'Dnipropetrovsk',
	Antratsyt: 'Luhansk',
	Amvrosiivka: 'Donetsk',
	Alushta: 'Crimea',
	Alchevsk: 'Luhansk',
	Okhtyrka: 'Sumy',
	'Horishni Plavni': 'Poltava',
	Slavutych: 'Kiev',
	Yuzhnoukrayinsk: 'Mykolaiv',
	Varash: 'Rivne',
	"Novoyavorivs'k": 'Lviv',
	Reni: 'Odessa',
	Yumbe: 'Northern Region',
	Wobulenzi: 'Central Region',
	Wakiso: 'Central Region',
	Tororo: 'Eastern Region',
	Soroti: 'Eastern Region',
	Pallisa: 'Eastern Region',
	Paidha: 'Northern Region',
	Nyachera: 'Western Region',
	Ntungamo: 'Western Region',
	Njeru: 'Central Region',
	Nebbi: 'Northern Region',
	Namasuba: 'Central Region',
	Mukono: 'Central Region',
	Mubende: 'Central Region',
	Moyo: 'Northern Region',
	Mityana: 'Central Region',
	Mbarara: 'Western Region',
	Masindi: 'Western Region',
	Masaka: 'Central Region',
	Luwero: 'Central Region',
	Lugazi: 'Central Region',
	Lira: 'Northern Region',
	Kyenjojo: 'Western Region',
	Kotido: 'Northern Region',
	Kitgum: 'Northern Region',
	Kireka: 'Central Region',
	Kayunga: 'Central Region',
	Kasese: 'Western Region',
	Kamwenge: 'Western Region',
	Kampala: 'Central Region',
	Kabale: 'Western Region',
	Jinja: 'Eastern Region',
	Iganga: 'Eastern Region',
	Ibanda: 'Western Region',
	Hoima: 'Western Region',
	'Fort Portal': 'Western Region',
	Entebbe: 'Central Region',
	Bwizibwera: 'Western Region',
	Buwenge: 'Eastern Region',
	Busembatia: 'Eastern Region',
	Bundibugyo: 'Western Region',
	Bugiri: 'Eastern Region',
	Arua: 'Northern Region',
	Apac: 'Northern Region',
	Adjumani: 'Northern Region',
	'Fort Hunt': 'Virginia',
	Bessemer: 'Alabama',
	Paducah: 'Kentucky',
	'Center Point': 'Alabama',
	Cullman: 'Alabama',
	Daphne: 'Alabama',
	Decatur: 'Illinois',
	Dothan: 'Alabama',
	'East Florence': 'Alabama',
	Enterprise: 'Nevada',
	Fairhope: 'Alabama',
	Foley: 'Alabama',
	Gadsden: 'Alabama',
	Helena: 'Montana',
	Homewood: 'Illinois',
	Hoover: 'Alabama',
	Hueytown: 'Alabama',
	Madison: 'Wisconsin',
	Millbrook: 'Alabama',
	Mobile: 'Alabama',
	Montgomery: 'Illinois',
	'Mountain Brook': 'Alabama',
	Northport: 'Alabama',
	Opelika: 'Alabama',
	Pelham: 'Alabama',
	'Phenix City': 'Alabama',
	Prattville: 'Alabama',
	Prichard: 'Alabama',
	Selma: 'California',
	Talladega: 'Alabama',
	'Tillmans Corner': 'Alabama',
	Troy: 'Ohio',
	Trussville: 'Alabama',
	Tuscaloosa: 'Alabama',
	'Vestavia Hills': 'Alabama',
	Benton: 'Arkansas',
	Bentonville: 'Arkansas',
	Bryant: 'Arkansas',
	Cabot: 'Arkansas',
	Conway: 'South Carolina',
	'El Dorado': 'Bolívar',
	Fayetteville: 'North Carolina',
	'Fort Smith': 'Arkansas',
	'Hot Springs': 'Arkansas',
	Jacksonville: 'North Carolina',
	Jonesboro: 'Arkansas',
	'Little Rock': 'Arkansas',
	Maumelle: 'Arkansas',
	'North Little Rock': 'Arkansas',
	Paragould: 'Arkansas',
	'Pine Bluff': 'Arkansas',
	Rogers: 'Arkansas',
	Russellville: 'Arkansas',
	Searcy: 'Arkansas',
	'Siloam Springs': 'Arkansas',
	Springdale: 'Arkansas',
	Texarkana: 'Texas',
	'Van Buren': 'Arkansas',
	'West Memphis': 'Arkansas',
	'Adams Morgan': 'Washington, D.C.',
	Shaw: 'Washington, D.C.',
	Bear: 'Delaware',
	Middletown: 'Rhode Island',
	Newark: 'California',
	Wilmington: 'Massachusetts',
	Allapattah: 'Florida',
	'Altamonte Springs': 'Florida',
	Apopka: 'Florida',
	Auburndale: 'Florida',
	Aventura: 'Florida',
	Bartow: 'Florida',
	'Bayonet Point': 'Florida',
	'Bayshore Gardens': 'Florida',
	'Belle Glade': 'Florida',
	Bellview: 'Florida',
	Bloomingdale: 'Illinois',
	'Boca Del Mar': 'Florida',
	'Boca Raton': 'Florida',
	'Bonita Springs': 'Florida',
	'Boynton Beach': 'Florida',
	Bradenton: 'Florida',
	Brownsville: 'New York',
	'Buenaventura Lakes': 'Florida',
	Cantonment: 'Florida',
	'Cape Coral': 'Florida',
	'Carol City': 'Florida',
	Carrollwood: 'Florida',
	'Carrollwood Village': 'Florida',
	Casselberry: 'Florida',
	'Citrus Park': 'Florida',
	Clearwater: 'Florida',
	Clermont: 'Florida',
	Cocoa: 'Florida',
	'Coconut Creek': 'Florida',
	'Coconut Grove': 'Florida',
	'Cooper City': 'Florida',
	'Coral Gables': 'Florida',
	'Coral Springs': 'Florida',
	'Coral Terrace': 'Florida',
	'Country Walk': 'Florida',
	'Country Club': 'Florida',
	Crestview: 'Florida',
	Cutler: 'Florida',
	'Cutler Ridge': 'Florida',
	'Dania Beach': 'Florida',
	Davie: 'Florida',
	'Daytona Beach': 'Florida',
	DeLand: 'Florida',
	DeBary: 'Florida',
	'Deerfield Beach': 'Florida',
	'Delray Beach': 'Florida',
	Deltona: 'Florida',
	Doral: 'Florida',
	'East Lake': 'Florida',
	'East Naples': 'Florida',
	'East Pensacola Heights': 'Florida',
	Edgewater: 'Illinois',
	'Egypt Lake-Leto': 'Florida',
	Ensley: 'Florida',
	Estero: 'Florida',
	Eustis: 'Florida',
	'Ferry Pass': 'Florida',
	Flagami: 'Florida',
	'Fleming Island': 'Florida',
	'Florida Ridge': 'Florida',
	'Fort Lauderdale': 'Florida',
	'Fort Myers': 'Florida',
	'Fort Pierce': 'Florida',
	'Fort Walton Beach': 'Florida',
	Fountainebleau: 'Florida',
	'Fruit Cove': 'Florida',
	Gainesville: 'Texas',
	'Glenvar Heights': 'Florida',
	'Golden Gate': 'Florida',
	'Golden Glades': 'Florida',
	'Greenacres City': 'Florida',
	'Haines City': 'Florida',
	'Hallandale Beach': 'Florida',
	Hialeah: 'Florida',
	'Hialeah Gardens': 'Florida',
	Holiday: 'Florida',
	Hollywood: 'California',
	Homestead: 'Florida',
	Immokalee: 'Florida',
	Iona: 'Florida',
	'Ives Estates': 'Florida',
	'Jacksonville Beach': 'Florida',
	'Jasmine Estates': 'Florida',
	Jupiter: 'Florida',
	'Kendale Lakes': 'Florida',
	Kendall: 'Florida',
	'Key West': 'Florida',
	Keystone: 'Florida',
	Kissimmee: 'Florida',
	'Lake Butler': 'Florida',
	'Lake Magdalene': 'Florida',
	'Lake Mary': 'Florida',
	'Lake Wales': 'Florida',
	'Lake Worth': 'Florida',
	'Lake Worth Corridor': 'Florida',
	Lakeland: 'Florida',
	Lakeside: 'California',
	"Land O' Lakes": 'Florida',
	Largo: 'Florida',
	'Lauderdale Lakes': 'Florida',
	Lauderhill: 'Florida',
	Lealman: 'Florida',
	Leesburg: 'Virginia',
	'Lehigh Acres': 'Florida',
	'Leisure City': 'Florida',
	Lutz: 'Florida',
	'Lynn Haven': 'Florida',
	'Marco Island': 'Florida',
	'Meadow Woods': 'Florida',
	'Merritt Island': 'Florida',
	Miami: 'Florida',
	'Miami Beach': 'Florida',
	'Miami Gardens': 'Florida',
	'Miami Lakes': 'Florida',
	'Myrtle Grove': 'Florida',
	Navarre: 'Florida',
	'New Port Richey': 'Florida',
	'New Smyrna Beach': 'Florida',
	Norland: 'Florida',
	'North Fort Myers': 'Florida',
	'North Lauderdale': 'Florida',
	'North Miami': 'Florida',
	'North Miami Beach': 'Florida',
	'North Port': 'Florida',
	'Oak Ridge': 'Tennessee',
	'Oakland Park': 'Florida',
	Ocala: 'Florida',
	Ocoee: 'Florida',
	Ojus: 'Florida',
	'Opa-locka': 'Florida',
	Orlando: 'Florida',
	'Ormond Beach': 'Florida',
	Pace: 'Florida',
	'Palm Bay': 'Florida',
	'Palm Beach Gardens': 'Florida',
	'Palm City': 'Florida',
	'Palm Coast': 'Florida',
	'Palm Harbor': 'Florida',
	'Palm Springs': 'California',
	'Palm Valley': 'Florida',
	'Palmetto Bay': 'Florida',
	'Panama City': 'Florida',
	Parkland: 'Washington',
	'Pembroke Pines': 'Florida',
	Pensacola: 'Florida',
	'Pine Hills': 'Florida',
	Pinecrest: 'Florida',
	'Pinellas Park': 'Florida',
	Pinewood: 'Florida',
	'Plant City': 'Florida',
	Plantation: 'Florida',
	Poinciana: 'Florida',
	'Pompano Beach': 'Florida',
	'Ponte Vedra Beach': 'Florida',
	'Port Charlotte': 'Florida',
	'Port Orange': 'Florida',
	'Port Saint Lucie': 'Florida',
	Princeton: 'New Jersey',
	'Punta Gorda': 'Florida',
	'Punta Gorda Isles': 'Florida',
	'Richmond West': 'Florida',
	Riverview: 'Florida',
	'Riviera Beach': 'Florida',
	Rockledge: 'Florida',
	'Royal Palm Beach': 'Florida',
	Ruskin: 'Florida',
	'Safety Harbor': 'Florida',
	'Saint Cloud': 'Minnesota',
	'St. Petersburg': 'Florida',
	'San Carlos Park': 'Florida',
	Sanford: 'Maine',
	'Santa Rosa Beach': 'Florida',
	Sarasota: 'Florida',
	Sebastian: 'Florida',
	Seminole: 'Florida',
	'South Bradenton': 'Florida',
	'South Miami Heights': 'Florida',
	Southchase: 'Florida',
	'Spring Hill': 'Tennessee',
	Stuart: 'Florida',
	'Sun City Center': 'Florida',
	'Sunny Isles Beach': 'Florida',
	Sunrise: 'Florida',
	Sunset: 'Florida',
	Sweetwater: 'Florida',
	Tallahassee: 'Florida',
	Tamarac: 'Florida',
	Tamiami: 'Florida',
	Tampa: 'Florida',
	'Tarpon Springs': 'Florida',
	Tavares: 'Florida',
	'Temple Terrace': 'Florida',
	'The Crossings': 'Florida',
	'The Hammocks': 'Florida',
	'The Villages': 'Florida',
	'Three Lakes': 'Florida',
	Titusville: 'Florida',
	"Town 'n' Country": 'Florida',
	'University Park': 'Texas',
	Valrico: 'Florida',
	'Vero Beach': 'Florida',
	'Wekiwa Springs': 'Florida',
	'Wesley Chapel': 'Florida',
	Westchase: 'Florida',
	'West Hollywood': 'California',
	'West Little River': 'Florida',
	'West Melbourne': 'Florida',
	'West Palm Beach': 'Florida',
	'West Park': 'Florida',
	'West Pensacola': 'Florida',
	'West and East Lealman': 'Florida',
	Westchester: 'Illinois',
	'Winter Garden': 'Florida',
	'Winter Haven': 'Florida',
	'Winter Park': 'Florida',
	'Winter Springs': 'Florida',
	Wright: 'Florida',
	Acworth: 'Georgia',
	Alpharetta: 'Georgia',
	Americus: 'Georgia',
	Atlanta: 'Georgia',
	'Belvedere Park': 'Georgia',
	Brookhaven: 'Georgia',
	Calhoun: 'Georgia',
	Canton: 'Ohio',
	Carrollton: 'Texas',
	Cartersville: 'Georgia',
	Chamblee: 'Georgia',
	Columbus: 'Nebraska',
	Conyers: 'Georgia',
	Dalton: 'Georgia',
	Douglasville: 'Georgia',
	Duluth: 'Minnesota',
	Dunwoody: 'Georgia',
	'East Point': 'Georgia',
	Evans: 'Colorado',
	'Forest Park': 'Ohio',
	Griffin: 'Georgia',
	Hinesville: 'Georgia',
	Kennesaw: 'Georgia',
	Kingsland: 'Georgia',
	LaGrange: 'Georgia',
	Lawrenceville: 'Georgia',
	'Lithia Springs': 'Georgia',
	Mableton: 'Georgia',
	Macon: 'Georgia',
	Marietta: 'Georgia',
	Martinez: 'California',
	McDonough: 'Georgia',
	Milledgeville: 'Georgia',
	Newnan: 'Georgia',
	Norcross: 'Georgia',
	'North Atlanta': 'Georgia',
	'North Decatur': 'Georgia',
	'North Druid Hills': 'Georgia',
	'Peachtree City': 'Georgia',
	'Peachtree Corners': 'Georgia',
	Perry: 'Georgia',
	Pooler: 'Georgia',
	Redan: 'Georgia',
	Riverdale: 'Georgia',
	Roswell: 'New Mexico',
	'St. Marys': 'Georgia',
	'Sandy Springs': 'Georgia',
	Savannah: 'Georgia',
	Smyrna: 'Tennessee',
	Snellville: 'Georgia',
	Statesboro: 'Georgia',
	Stockbridge: 'Georgia',
	'Sugar Hill': 'Georgia',
	Suwanee: 'Georgia',
	Thomasville: 'North Carolina',
	Tifton: 'Georgia',
	Tucker: 'Georgia',
	'Union City': 'California',
	Valdosta: 'Georgia',
	'Warner Robins': 'Georgia',
	'Wilmington Island': 'Georgia',
	Winder: 'Georgia',
	Carbondale: 'Illinois',
	Charleston: 'West Virginia',
	Collinsville: 'Illinois',
	'East Saint Louis': 'Illinois',
	Edwardsville: 'Illinois',
	'Fairview Heights': 'Illinois',
	Godfrey: 'Illinois',
	'Granite City': 'Illinois',
	Marion: 'Ohio',
	Mattoon: 'Illinois',
	'Mount Vernon': 'Washington',
	"O'Fallon": 'Missouri',
	Quincy: 'Massachusetts',
	Springfield: 'Oregon',
	'Upper Alton': 'Illinois',
	Bloomington: 'California',
	'Broad Ripple': 'Indiana',
	Brownsburg: 'Indiana',
	Carmel: 'Indiana',
	Clarksville: 'Tennessee',
	Evansville: 'Indiana',
	Fishers: 'Indiana',
	Greenfield: 'California',
	Greenwood: 'South Carolina',
	Indianapolis: 'Indiana',
	Jasper: 'Indiana',
	Jeffersonville: 'Indiana',
	Lawrence: 'Massachusetts',
	'New Albany': 'Indiana',
	'New Castle': 'Pennsylvania',
	Plainfield: 'New Jersey',
	Seymour: 'Connecticut',
	Shelbyville: 'Tennessee',
	'Terre Haute': 'Indiana',
	Zionsville: 'Indiana',
	Emporia: 'Kansas',
	Gardner: 'Massachusetts',
	'Great Bend': 'Kansas',
	Hays: 'Kansas',
	Hutchinson: 'Kansas',
	'Junction City': 'Kansas',
	'Kansas City': 'Missouri',
	Leavenworth: 'Kansas',
	Leawood: 'Kansas',
	Lenexa: 'Kansas',
	Manhattan: 'New York',
	Newton: 'Massachusetts',
	Olathe: 'Kansas',
	'Overland Park': 'Kansas',
	Pittsburg: 'California',
	'Prairie Village': 'Kansas',
	Salina: 'Kansas',
	Shawnee: 'Oklahoma',
	Topeka: 'Kansas',
	Wichita: 'Kansas',
	Ashland: 'Oregon',
	'Bowling Green': 'Ohio',
	Covington: 'Washington',
	Danville: 'California',
	Elizabethtown: 'Kentucky',
	Erlanger: 'Kentucky',
	'Fern Creek': 'Kentucky',
	'Fort Thomas': 'Kentucky',
	Frankfort: 'Indiana',
	Henderson: 'Nevada',
	Highview: 'Kentucky',
	Hopkinsville: 'Kentucky',
	Independence: 'Missouri',
	Ironville: 'Kentucky',
	Jeffersontown: 'Kentucky',
	Lexington: 'Massachusetts',
	'Lexington-Fayette': 'Kentucky',
	Louisville: 'Colorado',
	Madisonville: 'Kentucky',
	Meads: 'Kentucky',
	Murray: 'Utah',
	Newburg: 'Kentucky',
	Nicholasville: 'Kentucky',
	Okolona: 'Kentucky',
	Owensboro: 'Kentucky',
	'Pleasure Ridge Park': 'Kentucky',
	Radcliff: 'Kentucky',
	'Saint Matthews': 'Kentucky',
	Shively: 'Kentucky',
	'Valley Station': 'Kentucky',
	'Baton Rouge': 'Louisiana',
	'Bayou Cane': 'Louisiana',
	'Bossier City': 'Louisiana',
	Central: 'Louisiana',
	Chalmette: 'Louisiana',
	Estelle: 'Louisiana',
	Gretna: 'Louisiana',
	Hammond: 'Indiana',
	Harvey: 'Illinois',
	Houma: 'Louisiana',
	Kenner: 'Louisiana',
	Lafayette: 'Colorado',
	'Lake Charles': 'Louisiana',
	Laplace: 'Louisiana',
	Marrero: 'Louisiana',
	Metairie: 'Louisiana',
	'Metairie Terrace': 'Louisiana',
	Monroe: 'Washington',
	Natchitoches: 'Louisiana',
	'New Iberia': 'Louisiana',
	'New Orleans': 'Louisiana',
	Opelousas: 'Louisiana',
	Prairieville: 'Louisiana',
	Ruston: 'Louisiana',
	Shenandoah: 'Louisiana',
	Shreveport: 'Louisiana',
	Slidell: 'Louisiana',
	Sulphur: 'Louisiana',
	Terrytown: 'Louisiana',
	Zachary: 'Louisiana',
	Adelphi: 'Maryland',
	Annapolis: 'Maryland',
	Arbutus: 'Maryland',
	'Aspen Hill': 'Maryland',
	Baltimore: 'Maryland',
	'Ballenger Creek': 'Maryland',
	Beltsville: 'Maryland',
	Bethesda: 'Maryland',
	Bowie: 'Maryland',
	Calverton: 'Maryland',
	'Camp Springs': 'Maryland',
	Carney: 'Maryland',
	Catonsville: 'Maryland',
	Chillum: 'Maryland',
	Cloverly: 'Maryland',
	Clinton: 'Utah',
	Cockeysville: 'Maryland',
	'College Park': 'Maryland',
	Columbia: 'Tennessee',
	Crofton: 'Maryland',
	Cumberland: 'Rhode Island',
	'East Riverdale': 'Maryland',
	Easton: 'Pennsylvania',
	Edgewood: 'Maryland',
	Eldersburg: 'Maryland',
	Elkton: 'Maryland',
	Elkridge: 'Maryland',
	'Ellicott City': 'Maryland',
	Essex: 'Maryland',
	Fairland: 'Maryland',
	Ferndale: 'Michigan',
	'Fort Washington': 'Maryland',
	Frederick: 'Maryland',
	Gaithersburg: 'Maryland',
	Germantown: 'Wisconsin',
	Glassmanor: 'Maryland',
	'Glen Burnie': 'Maryland',
	'Green Haven': 'Maryland',
	Greenbelt: 'Maryland',
	'Gwynn Oak': 'Maryland',
	Hagerstown: 'Maryland',
	Hanover: 'Massachusetts',
	'Hillcrest Heights': 'Maryland',
	'Hunt Valley': 'Maryland',
	Hyattsville: 'Maryland',
	Ilchester: 'Maryland',
	'Lake Shore': 'Maryland',
	Landover: 'Maryland',
	'Langley Park': 'Maryland',
	Laurel: 'Virginia',
	Lochearn: 'Maryland',
	'Maryland City': 'Maryland',
	'Middle River': 'Maryland',
	'Milford Mill': 'Maryland',
	'Montgomery Village': 'Maryland',
	'North Bel Air': 'Maryland',
	'North Bethesda': 'Maryland',
	'North Potomac': 'Maryland',
	Odenton: 'Maryland',
	Olney: 'Maryland',
	'Owings Mills': 'Maryland',
	'Oxon Hill': 'Maryland',
	Parkville: 'Maryland',
	Parole: 'Maryland',
	Pasadena: 'California',
	'Perry Hall': 'Maryland',
	Pikesville: 'Maryland',
	Potomac: 'Maryland',
	Randallstown: 'Maryland',
	Redland: 'Maryland',
	Reisterstown: 'Maryland',
	Rockville: 'Maryland',
	Rosedale: 'New York',
	Rossville: 'New York',
	'Saint Charles': 'Missouri',
	Scaggsville: 'Maryland',
	Seabrook: 'Maryland',
	Severn: 'Maryland',
	'Severna Park': 'Maryland',
	'Silver Spring': 'Maryland',
	'South Bel Air': 'Maryland',
	'South Gate': 'California',
	'South Laurel': 'Maryland',
	Suitland: 'Maryland',
	'Takoma Park': 'Maryland',
	Towson: 'Maryland',
	Waldorf: 'Maryland',
	'West Elkridge': 'Maryland',
	Westminster: 'Colorado',
	Wheaton: 'Illinois',
	'White Oak': 'Ohio',
	Woodlawn: 'Illinois',
	Affton: 'Missouri',
	Ballwin: 'Missouri',
	Belton: 'Texas',
	'Blue Springs': 'Missouri',
	'Cape Girardeau': 'Missouri',
	Concord: 'California',
	'Creve Coeur': 'Missouri',
	'East Independence': 'Missouri',
	Farmington: 'Utah',
	Ferguson: 'Missouri',
	Florissant: 'Missouri',
	Grandview: 'Missouri',
	Hannibal: 'Missouri',
	Hazelwood: 'Missouri',
	'Jefferson City': 'Missouri',
	Joplin: 'Missouri',
	Kirkwood: 'Missouri',
	"Lee's Summit": 'Missouri',
	Lemay: 'Missouri',
	Liberty: 'Missouri',
	'Maryland Heights': 'Missouri',
	Mehlville: 'Missouri',
	Nixa: 'Missouri',
	Overland: 'Missouri',
	Ozark: 'Missouri',
	'Poplar Bluff': 'Missouri',
	Raymore: 'Missouri',
	Raytown: 'Missouri',
	Republic: 'Missouri',
	Rolla: 'Missouri',
	'Saint Joseph': 'Missouri',
	'St. Louis': 'Missouri',
	Sedalia: 'Missouri',
	Sikeston: 'Missouri',
	'Spanish Lake': 'Missouri',
	'University City': 'Missouri',
	Warrensburg: 'Missouri',
	'Webster Groves': 'Missouri',
	Wentzville: 'Missouri',
	Wildwood: 'Missouri',
	Biloxi: 'Mississippi',
	Clarksdale: 'Mississippi',
	Gautier: 'Mississippi',
	Gulfport: 'Mississippi',
	Hattiesburg: 'Mississippi',
	Hernando: 'Mississippi',
	'Horn Lake': 'Mississippi',
	Jackson: 'Michigan',
	'Long Beach': 'California',
	Meridian: 'Idaho',
	Natchez: 'Mississippi',
	'Ocean Springs': 'Mississippi',
	'Olive Branch': 'Mississippi',
	Pascagoula: 'Mississippi',
	Pearl: 'Mississippi',
	Ridgeland: 'Mississippi',
	Southaven: 'Mississippi',
	Starkville: 'Mississippi',
	Tupelo: 'Mississippi',
	Vicksburg: 'Mississippi',
	'West Gulfport': 'Mississippi',
	Albemarle: 'North Carolina',
	Apex: 'North Carolina',
	Asheboro: 'North Carolina',
	Asheville: 'North Carolina',
	Boone: 'North Carolina',
	Carrboro: 'North Carolina',
	Cary: 'Illinois',
	'Chapel Hill': 'North Carolina',
	Charlotte: 'North Carolina',
	Clemmons: 'North Carolina',
	Cornelius: 'North Carolina',
	Eden: 'North Carolina',
	'Elizabeth City': 'North Carolina',
	'Fuquay-Varina': 'North Carolina',
	Garner: 'North Carolina',
	Gastonia: 'North Carolina',
	Goldsboro: 'North Carolina',
	Greensboro: 'North Carolina',
	Havelock: 'North Carolina',
	Hickory: 'North Carolina',
	'High Point': 'North Carolina',
	'Holly Springs': 'North Carolina',
	'Hope Mills': 'North Carolina',
	Huntersville: 'North Carolina',
	'Indian Trail': 'North Carolina',
	Kannapolis: 'North Carolina',
	Kernersville: 'North Carolina',
	Kinston: 'North Carolina',
	Laurinburg: 'North Carolina',
	Leland: 'North Carolina',
	Lenoir: 'North Carolina',
	Lumberton: 'North Carolina',
	Matthews: 'North Carolina',
	'Mint Hill': 'North Carolina',
	Mooresville: 'North Carolina',
	Morganton: 'North Carolina',
	Morrisville: 'North Carolina',
	'New Bern': 'North Carolina',
	Pinehurst: 'North Carolina',
	Raleigh: 'North Carolina',
	'Roanoke Rapids': 'North Carolina',
	'Rocky Mount': 'North Carolina',
	Shelby: 'Michigan',
	Stallings: 'North Carolina',
	Statesville: 'North Carolina',
	'Wake Forest': 'North Carolina',
	'West Raleigh': 'North Carolina',
	Wilson: 'North Carolina',
	'Winston-Salem': 'North Carolina',
	'Atlantic City': 'New Jersey',
	Bayville: 'New Jersey',
	Bridgeton: 'New Jersey',
	Camden: 'New Jersey',
	'Cherry Hill': 'Virginia',
	Glassboro: 'New Jersey',
	Lindenwold: 'New Jersey',
	'Maple Shade': 'New Jersey',
	Millville: 'New Jersey',
	'Mount Laurel': 'New Jersey',
	'Ocean Acres': 'New Jersey',
	Pennsauken: 'New Jersey',
	Pleasantville: 'New Jersey',
	Sewell: 'New Jersey',
	Sicklerville: 'New Jersey',
	'South Vineland': 'New Jersey',
	'Toms River': 'New Jersey',
	Vincentown: 'New Jersey',
	Vineland: 'New Jersey',
	Williamstown: 'New Jersey',
	Beavercreek: 'Ohio',
	Centerville: 'Utah',
	Cincinnati: 'Ohio',
	Dayton: 'Ohio',
	Fairborn: 'Ohio',
	'Grove City': 'Ohio',
	'Huber Heights': 'Ohio',
	Lebanon: 'Oregon',
	Mason: 'Ohio',
	Miamisburg: 'Ohio',
	Norwood: 'Massachusetts',
	Pataskala: 'Ohio',
	Pickerington: 'Ohio',
	Reynoldsburg: 'Ohio',
	Riverside: 'California',
	Springboro: 'Ohio',
	Trotwood: 'Ohio',
	'Upper Arlington': 'Ohio',
	Vandalia: 'Ohio',
	Whitehall: 'Ohio',
	Xenia: 'Ohio',
	Zanesville: 'Ohio',
	Ada: 'Oklahoma',
	Altus: 'Oklahoma',
	Ardmore: 'Oklahoma',
	Bartlesville: 'Oklahoma',
	Bethany: 'Oregon',
	Bixby: 'Oklahoma',
	'Broken Arrow': 'Oklahoma',
	Chickasha: 'Oklahoma',
	Claremore: 'Oklahoma',
	'Del City': 'Oklahoma',
	Durant: 'Oklahoma',
	Edmond: 'Oklahoma',
	'El Reno': 'Oklahoma',
	Enid: 'Oklahoma',
	Jenks: 'Oklahoma',
	Lawton: 'Oklahoma',
	McAlester: 'Oklahoma',
	'Midwest City': 'Oklahoma',
	Moore: 'Oklahoma',
	Muskogee: 'Oklahoma',
	Mustang: 'Oklahoma',
	Norman: 'Oklahoma',
	'Oklahoma City': 'Oklahoma',
	Owasso: 'Oklahoma',
	'Ponca City': 'Oklahoma',
	'Sand Springs': 'Oklahoma',
	Sapulpa: 'Oklahoma',
	Stillwater: 'Minnesota',
	Tahlequah: 'Oklahoma',
	Tulsa: 'Oklahoma',
	Yukon: 'Oklahoma',
	Chambersburg: 'Pennsylvania',
	'Drexel Hill': 'Pennsylvania',
	Pennsport: 'Pennsylvania',
	Philadelphia: 'Pennsylvania',
	'West Chester': 'Pennsylvania',
	Wharton: 'Pennsylvania',
	Whitman: 'Pennsylvania',
	Aiken: 'South Carolina',
	Anderson: 'Indiana',
	Bluffton: 'South Carolina',
	Clemson: 'South Carolina',
	Easley: 'South Carolina',
	'Goose Creek': 'South Carolina',
	Greer: 'South Carolina',
	Hanahan: 'South Carolina',
	'Hilton Head Island': 'South Carolina',
	'Hilton Head': 'South Carolina',
	Mauldin: 'South Carolina',
	'Mount Pleasant': 'Wisconsin',
	'Myrtle Beach': 'South Carolina',
	'North Augusta': 'South Carolina',
	'North Charleston': 'South Carolina',
	'North Myrtle Beach': 'South Carolina',
	'Rock Hill': 'South Carolina',
	'Seven Oaks': 'South Carolina',
	Simpsonville: 'South Carolina',
	Socastee: 'South Carolina',
	Spartanburg: 'South Carolina',
	Summerville: 'South Carolina',
	Sumter: 'South Carolina',
	Taylors: 'South Carolina',
	'Wade Hampton': 'South Carolina',
	'West Columbia': 'South Carolina',
	Bartlett: 'Illinois',
	'Brentwood Estates': 'Tennessee',
	Chattanooga: 'Tennessee',
	Cleveland: 'Ohio',
	Collierville: 'Tennessee',
	Cookeville: 'Tennessee',
	Dickson: 'Tennessee',
	Dyersburg: 'Tennessee',
	'East Brainerd': 'Tennessee',
	'East Chattanooga': 'Tennessee',
	'East Ridge': 'Tennessee',
	Ellendale: 'Tennessee',
	Farragut: 'Tennessee',
	Franklin: 'Wisconsin',
	Gallatin: 'Tennessee',
	Goodlettsville: 'Tennessee',
	Greeneville: 'Tennessee',
	Hendersonville: 'Tennessee',
	Hermitage: 'Pennsylvania',
	'Johnson City': 'Tennessee',
	Kingsport: 'Tennessee',
	Knoxville: 'Tennessee',
	'La Vergne': 'Tennessee',
	Maryville: 'Tennessee',
	Memphis: 'Tennessee',
	Morristown: 'New Jersey',
	'Mount Juliet': 'Tennessee',
	Murfreesboro: 'Tennessee',
	Nashville: 'Tennessee',
	'New South Memphis': 'Tennessee',
	Sevierville: 'Tennessee',
	Tullahoma: 'Tennessee',
	Abilene: 'Texas',
	Addison: 'Illinois',
	Alamo: 'Texas',
	Aldine: 'Texas',
	Alice: 'Texas',
	Alief: 'Texas',
	Alvin: 'Texas',
	Angleton: 'Texas',
	Arlington: 'Washington',
	Atascocita: 'Texas',
	Austin: 'Minnesota',
	'Balch Springs': 'Texas',
	Baytown: 'Texas',
	Beaumont: 'California',
	Bellaire: 'Texas',
	Benbrook: 'Texas',
	Brenham: 'Texas',
	Brownwood: 'Texas',
	'Brushy Creek': 'Texas',
	Bryan: 'Texas',
	Burleson: 'Texas',
	'Canyon Lake': 'Texas',
	'Cedar Hill': 'Texas',
	'Cedar Park': 'Texas',
	Channelview: 'Texas',
	Cibolo: 'Texas',
	'Cinco Ranch': 'Texas',
	Cleburne: 'Texas',
	Cloverleaf: 'Texas',
	'College Station': 'Texas',
	Colleyville: 'Texas',
	Conroe: 'Texas',
	Converse: 'Texas',
	Coppell: 'Texas',
	'Copperas Cove': 'Texas',
	Corinth: 'Texas',
	'Corpus Christi': 'Texas',
	Corsicana: 'Texas',
	Cypress: 'California',
	Dallas: 'Oregon',
	DeSoto: 'Texas',
	Denison: 'Texas',
	Dickinson: 'North Dakota',
	Donna: 'Texas',
	Duncanville: 'Texas',
	Edinburg: 'Texas',
	Euless: 'Texas',
	'Farmers Branch': 'Texas',
	'Flower Mound': 'Texas',
	Forney: 'Texas',
	'Fort Worth': 'Texas',
	Friendswood: 'Texas',
	Frisco: 'Texas',
	Galveston: 'Texas',
	Garland: 'Texas',
	Gatesville: 'Texas',
	'Grand Prairie': 'Texas',
	Grapevine: 'Texas',
	Groves: 'Texas',
	'Haltom City': 'Texas',
	'Harker Heights': 'Texas',
	'Highland Village': 'Texas',
	Houston: 'Texas',
	Humble: 'Texas',
	Hurst: 'Texas',
	Hutto: 'Texas',
	Irving: 'Texas',
	Jollyville: 'Texas',
	Katy: 'Texas',
	Keller: 'Texas',
	Kerrville: 'Texas',
	Killeen: 'Texas',
	Kingsville: 'Texas',
	Kyle: 'Texas',
	'La Marque': 'Texas',
	'La Porte': 'Indiana',
	'Lake Jackson': 'Texas',
	'League City': 'Texas',
	Leander: 'Texas',
	Lewisville: 'Texas',
	'Little Elm': 'Texas',
	'Live Oak': 'California',
	Longview: 'Washington',
	Lufkin: 'Texas',
	Marshall: 'Texas',
	McAllen: 'Texas',
	McKinney: 'Texas',
	Mesquite: 'Nevada',
	Midlothian: 'Texas',
	'Mission Bend': 'Texas',
	'Missouri City': 'Texas',
	Murphy: 'Texas',
	Nacogdoches: 'Texas',
	Nederland: 'Texas',
	'New Braunfels': 'Texas',
	'New Territory': 'Texas',
	'North Richland Hills': 'Texas',
	Palestine: 'Texas',
	Pearland: 'Texas',
	'Pecan Grove': 'Texas',
	Pflugerville: 'Texas',
	Pharr: 'Texas',
	Plano: 'Texas',
	'Port Arthur': 'Texas',
	Portland: 'Oregon',
	Prosper: 'Texas',
	Richardson: 'Texas',
	Rockwall: 'Texas',
	Rosenberg: 'Texas',
	'Round Rock': 'Texas',
	Rowlett: 'Texas',
	Sachse: 'Texas',
	Saginaw: 'Michigan',
	Schertz: 'Texas',
	Seagoville: 'Texas',
	Seguin: 'Texas',
	Sherman: 'Texas',
	'South Houston': 'Texas',
	Southlake: 'Texas',
	Spring: 'Texas',
	Stephenville: 'Texas',
	'Sugar Land': 'Texas',
	'Sulphur Springs': 'Texas',
	Taylor: 'Michigan',
	Temple: 'Texas',
	Terrell: 'Texas',
	'Texas City': 'Texas',
	'The Colony': 'Texas',
	'The Woodlands': 'Texas',
	Tyler: 'Texas',
	'Universal City': 'California',
	Uvalde: 'Texas',
	Waco: 'Texas',
	Watauga: 'Texas',
	Waxahachie: 'Texas',
	Weatherford: 'Texas',
	Weslaco: 'Texas',
	'West University Place': 'Texas',
	'White Settlement': 'Texas',
	'Wichita Falls': 'Texas',
	Wylie: 'Texas',
	Annandale: 'Virginia',
	Ashburn: 'Illinois',
	'Baileys Crossroads': 'Virginia',
	Blacksburg: 'Virginia',
	'Bon Air': 'Virginia',
	Buckhall: 'Virginia',
	Burke: 'Virginia',
	'Cave Spring': 'Virginia',
	Centreville: 'Virginia',
	Chantilly: 'Virginia',
	Charlottesville: 'Virginia',
	Chesapeake: 'Virginia',
	Christiansburg: 'Virginia',
	'Colonial Heights': 'Virginia',
	Culpeper: 'Virginia',
	'Dale City': 'Virginia',
	'East Hampton': 'Virginia',
	Fairfax: 'Virginia',
	Franconia: 'Virginia',
	Fredericksburg: 'Virginia',
	'Front Royal': 'Virginia',
	'Great Falls': 'Montana',
	Harrisonburg: 'Virginia',
	Herndon: 'Virginia',
	'Highland Springs': 'Virginia',
	Hopewell: 'Virginia',
	'Hybla Valley': 'Virginia',
	Idylwood: 'Virginia',
	'Lake Ridge': 'Virginia',
	Lincolnia: 'Virginia',
	'Linton Hall': 'Virginia',
	Lorton: 'Virginia',
	Lynchburg: 'Virginia',
	Manassas: 'Virginia',
	'Manassas Park': 'Virginia',
	McLean: 'Virginia',
	Mechanicsville: 'Virginia',
	Merrifield: 'Virginia',
	Montclair: 'California',
	'Newport News': 'Virginia',
	Norfolk: 'Nebraska',
	Oakton: 'Virginia',
	Petersburg: 'Virginia',
	'Portsmouth Heights': 'Virginia',
	Radford: 'Virginia',
	Reston: 'Virginia',
	Roanoke: 'Virginia',
	'Rose Hill': 'Virginia',
	'Short Pump': 'Virginia',
	'South Riding': 'Virginia',
	'South Suffolk': 'Virginia',
	Staunton: 'Virginia',
	Sterling: 'Illinois',
	Sudley: 'Virginia',
	Suffolk: 'Virginia',
	Tuckahoe: 'Virginia',
	'Tysons Corner': 'Virginia',
	'Virginia Beach': 'Virginia',
	Waynesboro: 'Virginia',
	'West Lynchburg': 'Virginia',
	'West Springfield': 'Massachusetts',
	Williamsburg: 'New York',
	'Wolf Trap': 'Virginia',
	Beckley: 'West Virginia',
	Clarksburg: 'West Virginia',
	Fairmont: 'West Virginia',
	Huntington: 'New York',
	Martinsburg: 'West Virginia',
	Morgantown: 'West Virginia',
	Parkersburg: 'West Virginia',
	Sherwood: 'Oregon',
	Chillicothe: 'Ohio',
	'Bay City': 'Michigan',
	Alabaster: 'Alabama',
	Anniston: 'Alabama',
	'Glendale Heights': 'Illinois',
	'North Andover': 'Massachusetts',
	Revere: 'Massachusetts',
	Danbury: 'Connecticut',
	Inkster: 'Michigan',
	'Cleveland Heights': 'Ohio',
	'East Hartford': 'Connecticut',
	'East Haven': 'Connecticut',
	'East Norwalk': 'Connecticut',
	Enfield: 'Connecticut',
	Glastonbury: 'Connecticut',
	Guilford: 'Connecticut',
	Hamden: 'Connecticut',
	Hartford: 'Connecticut',
	'Killingly Center': 'Connecticut',
	Ledyard: 'Connecticut',
	'Mansfield City': 'Connecticut',
	Meriden: 'Connecticut',
	Milford: 'Massachusetts',
	'Montville Center': 'Connecticut',
	Naugatuck: 'Connecticut',
	'New Britain': 'Connecticut',
	'New Canaan': 'Connecticut',
	'New Haven': 'Indiana',
	'New London': 'Connecticut',
	Newington: 'Connecticut',
	'North Haven': 'Connecticut',
	'North Stamford': 'Connecticut',
	Norwalk: 'California',
	Plainville: 'Connecticut',
	Shelton: 'Connecticut',
	'South Windsor': 'Connecticut',
	Southbury: 'Connecticut',
	Storrs: 'Connecticut',
	Torrington: 'Connecticut',
	Trumbull: 'Connecticut',
	Wallingford: 'Connecticut',
	Waterbury: 'Connecticut',
	'West Hartford': 'Connecticut',
	'West Haven': 'Connecticut',
	'West Torrington': 'Connecticut',
	Westport: 'Connecticut',
	Wethersfield: 'Connecticut',
	Willimantic: 'Connecticut',
	Wilton: 'Connecticut',
	Windham: 'Connecticut',
	Wolcott: 'Connecticut',
	Altoona: 'Pennsylvania',
	Ames: 'Iowa',
	Ankeny: 'Iowa',
	Bettendorf: 'Iowa',
	'Cedar Falls': 'Iowa',
	'Cedar Rapids': 'Iowa',
	Clive: 'Iowa',
	Coralville: 'Iowa',
	'Council Bluffs': 'Iowa',
	Davenport: 'Iowa',
	'Des Moines': 'Washington',
	Dubuque: 'Iowa',
	'Fort Dodge': 'Iowa',
	Indianola: 'Iowa',
	'Iowa City': 'Iowa',
	Johnston: 'Rhode Island',
	Marshalltown: 'Iowa',
	'Mason City': 'Iowa',
	Muscatine: 'Iowa',
	'North Liberty': 'Iowa',
	Ottumwa: 'Iowa',
	'Sioux City': 'Iowa',
	Urbandale: 'Iowa',
	Waukee: 'Iowa',
	'West Des Moines': 'Iowa',
	'Albany Park': 'Illinois',
	Algonquin: 'Illinois',
	Alsip: 'Illinois',
	'Arlington Heights': 'Illinois',
	Batavia: 'New York',
	Bellwood: 'Illinois',
	'Belmont Cragin': 'Illinois',
	Belvidere: 'Illinois',
	Bensenville: 'Illinois',
	Berwyn: 'Illinois',
	'Blue Island': 'Illinois',
	Bolingbrook: 'Illinois',
	Bourbonnais: 'Illinois',
	Bradley: 'Illinois',
	Bridgeport: 'Connecticut',
	Bridgeview: 'Illinois',
	'Brighton Park': 'Illinois',
	Brookfield: 'Wisconsin',
	'Buffalo Grove': 'Illinois',
	Burbank: 'California',
	'Calumet City': 'Illinois',
	'Carol Stream': 'Illinois',
	Carpentersville: 'Illinois',
	Champaign: 'Illinois',
	Chicago: 'Illinois',
	'Chicago Heights': 'Illinois',
	'Chicago Lawn': 'Illinois',
	Cicero: 'New York',
	'Country Club Hills': 'Illinois',
	'Crest Hill': 'Illinois',
	'Crystal Lake': 'Illinois',
	DeKalb: 'Illinois',
	Deerfield: 'Illinois',
	'Des Plaines': 'Illinois',
	Dixon: 'California',
	Dolton: 'Illinois',
	'Downers Grove': 'Illinois',
	'East Garfield Park': 'Illinois',
	'East Moline': 'Illinois',
	'East Peoria': 'Illinois',
	'Elk Grove Village': 'Illinois',
	Elmhurst: 'New York',
	'Elmwood Park': 'New Jersey',
	Englewood: 'Colorado',
	Evanston: 'Illinois',
	'Evergreen Park': 'Illinois',
	'Franklin Park': 'Illinois',
	'Gage Park': 'Illinois',
	Galesburg: 'Illinois',
	Geneva: 'Illinois',
	'Glen Ellyn': 'Illinois',
	Glenview: 'Illinois',
	'Goodings Grove': 'Illinois',
	'Grand Boulevard': 'Illinois',
	Grayslake: 'Illinois',
	Gurnee: 'Illinois',
	'Hanover Park': 'Illinois',
	'Highland Park': 'Illinois',
	Hinsdale: 'Illinois',
	'Hoffman Estates': 'Illinois',
	'Homer Glen': 'Illinois',
	Huntley: 'Illinois',
	'Hyde Park': 'Illinois',
	'Irving Park': 'Illinois',
	Joliet: 'Illinois',
	Kankakee: 'Illinois',
	Kenwood: 'Illinois',
	'La Grange': 'Illinois',
	'Lake Forest': 'California',
	'Lake Zurich': 'Illinois',
	'Lake in the Hills': 'Illinois',
	Lansing: 'Michigan',
	Lemont: 'Illinois',
	Libertyville: 'Illinois',
	'Lincoln Park': 'Michigan',
	'Lincoln Square': 'Illinois',
	Lisle: 'Illinois',
	Lockport: 'New York',
	'Logan Square': 'Illinois',
	Lombard: 'Illinois',
	'Loves Park': 'Illinois',
	'Lower West Side': 'Illinois',
	'Machesney Park': 'Illinois',
	Macomb: 'Illinois',
	Matteson: 'Illinois',
	Maywood: 'California',
	McHenry: 'Illinois',
	'McKinley Park': 'Illinois',
	'Melrose Park': 'Illinois',
	Mokena: 'Illinois',
	Moline: 'Illinois',
	'Morgan Park': 'Illinois',
	Morton: 'Illinois',
	'Morton Grove': 'Illinois',
	'Mount Greenwood': 'Illinois',
	'Mount Prospect': 'Illinois',
	Mundelein: 'Illinois',
	Naperville: 'Illinois',
	'Near North Side': 'Illinois',
	'Near South Side': 'Illinois',
	'New City': 'New York',
	'New Lenox': 'Illinois',
	Niles: 'Ohio',
	Normal: 'Illinois',
	'North Aurora': 'Illinois',
	'North Center': 'Illinois',
	'North Chicago': 'Illinois',
	'North Lawndale': 'Illinois',
	'North Peoria': 'Illinois',
	Northbrook: 'Illinois',
	'Oak Forest': 'Illinois',
	'Oak Lawn': 'Illinois',
	'Oak Park': 'Michigan',
	'Orland Park': 'Illinois',
	Oswego: 'New York',
	Palatine: 'Illinois',
	'Palos Hills': 'Illinois',
	'Park Forest': 'Illinois',
	'Park Ridge': 'Illinois',
	Pekin: 'Illinois',
	Peoria: 'Arizona',
	'Portage Park': 'Illinois',
	'Prospect Heights': 'Illinois',
	'Rock Island': 'Illinois',
	Rockford: 'Illinois',
	'Rogers Park': 'Illinois',
	'Rolling Meadows': 'Illinois',
	Romeoville: 'Illinois',
	Roselle: 'New Jersey',
	'Round Lake': 'Illinois',
	'Round Lake Beach': 'Illinois',
	'St. Charles': 'Maryland',
	Schaumburg: 'Illinois',
	Shorewood: 'Illinois',
	Skokie: 'Illinois',
	'South Chicago': 'Illinois',
	'South Elgin': 'Illinois',
	'South Holland': 'Illinois',
	'South Lawndale': 'Illinois',
	'South Shore': 'Illinois',
	Streamwood: 'Illinois',
	Sycamore: 'Illinois',
	'Tinley Park': 'Illinois',
	Uptown: 'Illinois',
	Urbana: 'Illinois',
	'Vernon Hills': 'Illinois',
	'Villa Park': 'Illinois',
	Wasco: 'California',
	Waukegan: 'Illinois',
	'West Chicago': 'Illinois',
	'West Elsdon': 'Illinois',
	'West Englewood': 'Illinois',
	'West Garfield Park': 'Illinois',
	'West Lawn': 'Illinois',
	'West Ridge': 'Illinois',
	'West Town': 'Illinois',
	Westmont: 'California',
	Wheeling: 'West Virginia',
	Wilmette: 'Illinois',
	Woodridge: 'Illinois',
	Yorkville: 'Illinois',
	Zion: 'Illinois',
	Crawfordsville: 'Indiana',
	'Crown Point': 'Indiana',
	Dyer: 'Indiana',
	'East Chicago': 'Indiana',
	Elkhart: 'Indiana',
	'Fort Wayne': 'Indiana',
	Gary: 'Indiana',
	Goshen: 'Indiana',
	Granger: 'Indiana',
	Highland: 'Utah',
	Kokomo: 'Indiana',
	LaPorte: 'Indiana',
	Logansport: 'Indiana',
	Merrillville: 'Indiana',
	'Michigan City': 'Indiana',
	Mishawaka: 'Indiana',
	Muncie: 'Indiana',
	Noblesville: 'Indiana',
	Portage: 'Michigan',
	Schererville: 'Indiana',
	'South Bend': 'Indiana',
	Valparaiso: 'Indiana',
	'West Lafayette': 'Indiana',
	Westfield: 'New Jersey',
	Abington: 'Pennsylvania',
	Agawam: 'Massachusetts',
	Amesbury: 'Massachusetts',
	Amherst: 'New York',
	'Amherst Center': 'Massachusetts',
	Attleboro: 'Massachusetts',
	Barnstable: 'Massachusetts',
	Belmont: 'California',
	Beverly: 'Massachusetts',
	'Beverly Cove': 'Massachusetts',
	Billerica: 'Massachusetts',
	Brockton: 'Massachusetts',
	Brookline: 'Massachusetts',
	Chicopee: 'Massachusetts',
	Danvers: 'Massachusetts',
	Dedham: 'Massachusetts',
	Dracut: 'Massachusetts',
	Duxbury: 'Massachusetts',
	'East Longmeadow': 'Massachusetts',
	Easthampton: 'Massachusetts',
	Everett: 'Washington',
	Fairhaven: 'Massachusetts',
	'Fall River': 'Massachusetts',
	Fitchburg: 'Wisconsin',
	Framingham: 'Massachusetts',
	'Framingham Center': 'Massachusetts',
	Grafton: 'Massachusetts',
	Holden: 'Massachusetts',
	Holyoke: 'Massachusetts',
	'Jamaica Plain': 'Massachusetts',
	Leominster: 'Massachusetts',
	Longmeadow: 'Massachusetts',
	Lowell: 'Massachusetts',
	Ludlow: 'Massachusetts',
	Lynn: 'Massachusetts',
	Malden: 'Massachusetts',
	Marblehead: 'Massachusetts',
	Marlborough: 'Massachusetts',
	Medford: 'Oregon',
	Melrose: 'New York',
	Methuen: 'Massachusetts',
	Middleborough: 'Massachusetts',
	Natick: 'Massachusetts',
	Needham: 'Massachusetts',
	'New Bedford': 'Massachusetts',
	Newburyport: 'Massachusetts',
	'North Chicopee': 'Massachusetts',
	Norton: 'Mashonaland West',
	Palmer: 'Massachusetts',
	Peabody: 'Massachusetts',
	Pittsfield: 'Massachusetts',
	Randolph: 'New Jersey',
	Rockland: 'Massachusetts',
	Saugus: 'Massachusetts',
	Somerset: 'New Jersey',
	Somerville: 'Massachusetts',
	'South Boston': 'Massachusetts',
	'South Hadley': 'Massachusetts',
	'South Peabody': 'Massachusetts',
	Southbridge: 'Massachusetts',
	Stoneham: 'Massachusetts',
	Stoughton: 'Massachusetts',
	Tewksbury: 'Massachusetts',
	Waltham: 'Massachusetts',
	Watertown: 'Wisconsin',
	Wellesley: 'Massachusetts',
	Westford: 'Massachusetts',
	Winthrop: 'Massachusetts',
	Yarmouth: 'Massachusetts',
	Biddeford: 'Maine',
	Lewiston: 'Idaho',
	Saco: 'Maine',
	'South Portland': 'Maine',
	'South Portland Gardens': 'Maine',
	Waterville: 'Maine',
	'West Scarborough': 'Maine',
	Westbrook: 'Maine',
	Adrian: 'Michigan',
	'Allen Park': 'Michigan',
	Allendale: 'Michigan',
	'Ann Arbor': 'Michigan',
	'Auburn Hills': 'Michigan',
	'Battle Creek': 'Michigan',
	Berkley: 'Michigan',
	Burton: 'Michigan',
	'Clinton Township': 'Michigan',
	Dearborn: 'Michigan',
	'Dearborn Heights': 'Michigan',
	Detroit: 'Michigan',
	'East Lansing': 'Michigan',
	Eastpointe: 'Michigan',
	'Farmington Hills': 'Michigan',
	'Forest Hills': 'New York',
	'Garden City': 'Kansas',
	'Grand Rapids': 'Michigan',
	Grandville: 'Michigan',
	'Grosse Pointe Woods': 'Michigan',
	Hamtramck: 'Michigan',
	Haslett: 'Michigan',
	'Hazel Park': 'Michigan',
	Holland: 'Michigan',
	Holt: 'Michigan',
	Jenison: 'Michigan',
	Kalamazoo: 'Michigan',
	Kentwood: 'Michigan',
	Livonia: 'Michigan',
	'Madison Heights': 'Michigan',
	Marquette: 'Michigan',
	'Mount Clemens': 'Michigan',
	Muskegon: 'Michigan',
	'Norton Shores': 'Michigan',
	Novi: 'Michigan',
	Okemos: 'Michigan',
	Pontiac: 'Michigan',
	'Port Huron': 'Michigan',
	Redford: 'Michigan',
	'Rochester Hills': 'Michigan',
	Romulus: 'Michigan',
	Roseville: 'California',
	'Royal Oak': 'Michigan',
	Southfield: 'Michigan',
	Southgate: 'Michigan',
	'Saint Clair Shores': 'Michigan',
	'Sterling Heights': 'Michigan',
	'Traverse City': 'Michigan',
	Trenton: 'New Jersey',
	Walker: 'Michigan',
	Warren: 'Ohio',
	Waverly: 'Michigan',
	Wayne: 'Pennsylvania',
	Westland: 'Michigan',
	Wyandotte: 'Michigan',
	Wyoming: 'Michigan',
	Ypsilanti: 'Michigan',
	'Albert Lea': 'Minnesota',
	Anoka: 'Minnesota',
	'Apple Valley': 'California',
	Blaine: 'Minnesota',
	'Brooklyn Center': 'Minnesota',
	'Brooklyn Park': 'Minnesota',
	Buffalo: 'New York',
	Burnsville: 'Minnesota',
	Champlin: 'Minnesota',
	Chanhassen: 'Minnesota',
	Chaska: 'Minnesota',
	'Columbia Heights': 'Minnesota',
	'Coon Rapids': 'Minnesota',
	'Cottage Grove': 'Minnesota',
	Crystal: 'Minnesota',
	Eagan: 'Minnesota',
	'Eden Prairie': 'Minnesota',
	Edina: 'Minnesota',
	'Elk River': 'Minnesota',
	Faribault: 'Minnesota',
	Fridley: 'Minnesota',
	'Golden Valley': 'Minnesota',
	'Ham Lake': 'Minnesota',
	Hibbing: 'Minnesota',
	Hopkins: 'Minnesota',
	'Inver Grove Heights': 'Minnesota',
	Lakeville: 'Minnesota',
	'Lino Lakes': 'Minnesota',
	Mankato: 'Minnesota',
	'Maple Grove': 'Minnesota',
	Maplewood: 'New Jersey',
	Minneapolis: 'Minnesota',
	Minnetonka: 'Minnesota',
	'Minnetonka Mills': 'Minnesota',
	Moorhead: 'Minnesota',
	'New Brighton': 'Minnesota',
	'New Hope': 'Minnesota',
	Northfield: 'Minnesota',
	Oakdale: 'California',
	Otsego: 'Minnesota',
	Owatonna: 'Minnesota',
	'Prior Lake': 'Minnesota',
	Ramsey: 'New Jersey',
	'Red Wing': 'Minnesota',
	Richfield: 'Minnesota',
	Rosemount: 'Minnesota',
	'Saint Louis Park': 'Minnesota',
	'Saint Michael': 'Minnesota',
	'Saint Paul': 'Minnesota',
	Sartell: 'Minnesota',
	Savage: 'Minnesota',
	Shakopee: 'Minnesota',
	Shoreview: 'Minnesota',
	'South Saint Paul': 'Minnesota',
	'West Coon Rapids': 'Minnesota',
	'West Saint Paul': 'Minnesota',
	'White Bear Lake': 'Minnesota',
	Willmar: 'Minnesota',
	Winona: 'Minnesota',
	Woodbury: 'Minnesota',
	Kirksville: 'Missouri',
	Fargo: 'North Dakota',
	'Grand Forks': 'North Dakota',
	'West Fargo': 'North Dakota',
	Bellevue: 'Washington',
	Fremont: 'California',
	'Grand Island': 'New York',
	Kearney: 'Nebraska',
	'La Vista': 'Nebraska',
	Omaha: 'Nebraska',
	Papillion: 'Nebraska',
	'Derry Village': 'New Hampshire',
	'East Concord': 'New Hampshire',
	Keene: 'New Hampshire',
	Laconia: 'New Hampshire',
	Merrimack: 'New Hampshire',
	Nashua: 'New Hampshire',
	'Asbury Park': 'New Jersey',
	Avenel: 'New Jersey',
	'Basking Ridge': 'New Jersey',
	Bergenfield: 'New Jersey',
	Bloomfield: 'Connecticut',
	Carteret: 'New Jersey',
	'Cliffside Park': 'New Jersey',
	Clifton: 'Colorado',
	Colonia: 'New Jersey',
	Cranford: 'New Jersey',
	Denville: 'New Jersey',
	Dumont: 'New Jersey',
	'East Brunswick': 'New Jersey',
	'East Orange': 'New Jersey',
	Edison: 'New Jersey',
	Elizabeth: 'New Jersey',
	Ewing: 'New Jersey',
	'Fair Lawn': 'New Jersey',
	Fords: 'New Jersey',
	'Fort Lee': 'New Jersey',
	Garfield: 'New Jersey',
	Hackensack: 'New Jersey',
	Harrison: 'New York',
	Hawthorne: 'California',
	Hillsborough: 'New Jersey',
	'Hopatcong Hills': 'New Jersey',
	Irvington: 'New Jersey',
	Iselin: 'New Jersey',
	'Jersey City': 'New Jersey',
	Kearny: 'New Jersey',
	Lakewood: 'Washington',
	'Long Branch': 'New Jersey',
	Lyndhurst: 'New Jersey',
	Marlboro: 'New Jersey',
	'New Brunswick': 'New Jersey',
	'New Milford': 'New Jersey',
	'North Arlington': 'New Jersey',
	'North Bergen': 'New Jersey',
	'North Plainfield': 'New Jersey',
	Nutley: 'New Jersey',
	'Old Bridge': 'New Jersey',
	'Palisades Park': 'New Jersey',
	Paramus: 'New Jersey',
	Parsippany: 'New Jersey',
	Passaic: 'New Jersey',
	Paterson: 'New Jersey',
	'Perth Amboy': 'New Jersey',
	Piscataway: 'New Jersey',
	'Point Pleasant': 'New Jersey',
	Rahway: 'New Jersey',
	Ridgewood: 'New York',
	Rutherford: 'New Jersey',
	Sayreville: 'New Jersey',
	'Sayreville Junction': 'New Jersey',
	'Scotch Plains': 'New Jersey',
	Secaucus: 'New Jersey',
	'South Old Bridge': 'New Jersey',
	'South Orange': 'New Jersey',
	'South Plainfield': 'New Jersey',
	'South River': 'New Jersey',
	Summit: 'New Jersey',
	Teaneck: 'New Jersey',
	'Tinton Falls': 'New Jersey',
	Union: 'New Jersey',
	Voorhees: 'New Jersey',
	'West Milford': 'New Jersey',
	'West New York': 'New Jersey',
	'West Orange': 'New Jersey',
	Willingboro: 'New Jersey',
	Woodbridge: 'New Jersey',
	Wyckoff: 'New Jersey',
	Astoria: 'New York',
	Baldwin: 'Pennsylvania',
	'Bath Beach': 'New York',
	'Bay Shore': 'New York',
	Baychester: 'New York',
	Bayside: 'California',
	Bellmore: 'New York',
	Bensonhurst: 'New York',
	Bethpage: 'New York',
	Binghamton: 'New York',
	'Borough Park': 'New York',
	'Brighton Beach': 'New York',
	'The Bronx': 'New York',
	Brooklyn: 'New York',
	'Brooklyn Heights': 'New York',
	Bushwick: 'New York',
	'Cambria Heights': 'New York',
	Canarsie: 'New York',
	Centereach: 'New York',
	'Central Islip': 'New York',
	Cheektowaga: 'New York',
	Chinatown: 'California',
	Clay: 'New York',
	'Clifton Park': 'New York',
	Cohoes: 'New York',
	'College Point': 'New York',
	Commack: 'New York',
	'Coney Island': 'New York',
	Copiague: 'New York',
	Coram: 'New York',
	Corona: 'California',
	Cortland: 'New York',
	'Cypress Hills': 'New York',
	Depew: 'New York',
	'Dix Hills': 'New York',
	'Dyker Heights': 'New York',
	'East Amherst': 'New York',
	'East Elmhurst': 'New York',
	'East Flatbush': 'New York',
	'East Meadow': 'New York',
	'East Massapequa': 'New York',
	'East New York': 'New York',
	'East Northport': 'New York',
	'East Patchogue': 'New York',
	'East Setauket': 'New York',
	'East Tremont': 'New York',
	'East Village': 'New York',
	Eastchester: 'New York',
	Eggertsville: 'New York',
	Elmira: 'New York',
	Elmont: 'New York',
	'Emerson Hill': 'New York',
	'Far Rockaway': 'New York',
	Farmingville: 'New York',
	Flatbush: 'New York',
	Flatlands: 'New York',
	'Floral Park': 'New York',
	Fordham: 'New York',
	'Fort Hamilton': 'New York',
	'Franklin Square': 'New York',
	'Fresh Meadows': 'New York',
	'Glen Cove': 'New York',
	Glendale: 'California',
	Gloversville: 'New York',
	Graniteville: 'New York',
	'Great Kills': 'New York',
	Greenburgh: 'New York',
	Greenpoint: 'New York',
	Harlem: 'New York',
	Hauppauge: 'New York',
	"Hell's Kitchen": 'New York',
	Hempstead: 'New York',
	Henrietta: 'New York',
	Hicksville: 'New York',
	Holbrook: 'New York',
	Hollis: 'New York',
	Holtsville: 'New York',
	'Howard Beach': 'New York',
	'Huntington Station': 'New York',
	'Hunts Point': 'New York',
	Irondequoit: 'New York',
	Islip: 'New York',
	Ithaca: 'New York',
	'Jackson Heights': 'New York',
	Jamaica: 'New York',
	Kenmore: 'Washington',
	'Kew Gardens': 'New York',
	'Kings Bridge': 'New York',
	'Kings Park': 'New York',
	'Kiryas Joel': 'New York',
	Lackawanna: 'New York',
	'Lake Ronkonkoma': 'New York',
	Latham: 'New York',
	Laurelton: 'New York',
	Lindenhurst: 'New York',
	'Long Island City': 'New York',
	Lynbrook: 'New York',
	Mamaroneck: 'New York',
	'Mariners Harbor': 'New York',
	Maspeth: 'New York',
	Massapequa: 'New York',
	'Massapequa Park': 'New York',
	Mastic: 'New York',
	Melville: 'New York',
	Merrick: 'New York',
	'Middle Village': 'New York',
	Mineola: 'New York',
	Monsey: 'New York',
	'Morris Heights': 'New York',
	Morrisania: 'New York',
	'Mott Haven': 'New York',
	Nanuet: 'New York',
	'New Rochelle': 'New York',
	'New Springville': 'New York',
	'New York City': 'New York',
	Newburgh: 'New York',
	'North Amityville': 'New York',
	'North Babylon': 'New York',
	'North Bay Shore': 'New York',
	'North Bellmore': 'New York',
	'North Massapequa': 'New York',
	'North Tonawanda': 'New York',
	'North Valley Stream': 'New York',
	Oceanside: 'California',
	Ossining: 'New York',
	'Ozone Park': 'New York',
	'Park Slope': 'New York',
	Parkchester: 'New York',
	'Pearl River': 'New York',
	Peekskill: 'New York',
	Plainview: 'Texas',
	Plattsburgh: 'New York',
	'Port Chester': 'New York',
	'Port Richmond': 'New York',
	'Port Washington': 'New York',
	Poughkeepsie: 'New York',
	'Queens Village': 'New York',
	Queens: 'New York',
	Queensbury: 'New York',
	'Rego Park': 'New York',
	'Rockville Centre': 'New York',
	Ronkonkoma: 'New York',
	Roosevelt: 'New York',
	Rye: 'New York',
	'Saratoga Springs': 'Utah',
	Sayville: 'New York',
	Scarsdale: 'New York',
	Schenectady: 'New York',
	Selden: 'New York',
	'Sheepshead Bay': 'New York',
	Smithtown: 'New York',
	'South Ozone Park': 'New York',
	'Springfield Gardens': 'New York',
	'Spring Valley': 'Nevada',
	'Staten Island': 'New York',
	Sunnyside: 'Washington',
	Syosset: 'New York',
	Syracuse: 'Utah',
	'Terrace Heights': 'New York',
	'Throgs Neck': 'New York',
	Tremont: 'New York',
	Uniondale: 'New York',
	Unionport: 'New York',
	'University Heights': 'New York',
	Utica: 'New York',
	'Valley Stream': 'New York',
	'Van Nest': 'New York',
	Vestal: 'New York',
	Wantagh: 'New York',
	'Washington Heights': 'New York',
	'West Albany': 'New York',
	'West Babylon': 'New York',
	'West Hempstead': 'New York',
	'West Islip': 'New York',
	'West Seneca': 'New York',
	'White Plains': 'New York',
	Whitestone: 'New York',
	Woodhaven: 'New York',
	Woodmere: 'New York',
	Woodrow: 'New York',
	Woodside: 'New York',
	Yonkers: 'New York',
	Akron: 'Ohio',
	Alliance: 'Ohio',
	Ashtabula: 'Ohio',
	Austintown: 'Ohio',
	'Avon Center': 'Ohio',
	'Avon Lake': 'Ohio',
	Barberton: 'Mpumalanga',
	'Bay Village': 'Ohio',
	Berea: 'Ohio',
	Boardman: 'Ohio',
	'Broadview Heights': 'Ohio',
	'Brook Park': 'Ohio',
	Collinwood: 'Ohio',
	'Cuyahoga Falls': 'Ohio',
	Defiance: 'Ohio',
	Delaware: 'Ohio',
	'East Cleveland': 'Ohio',
	Eastlake: 'Ohio',
	Elyria: 'Ohio',
	Euclid: 'Ohio',
	'Fairview Park': 'Ohio',
	Findlay: 'Ohio',
	Gahanna: 'Ohio',
	'Garfield Heights': 'Ohio',
	Glenville: 'Ohio',
	Green: 'Ohio',
	Hilliard: 'Ohio',
	Hough: 'Ohio',
	Hudson: 'Ohio',
	Kent: 'Washington',
	Lorain: 'Ohio',
	'Maple Heights': 'Ohio',
	Marysville: 'Washington',
	Massillon: 'Ohio',
	'Mayfield Heights': 'Ohio',
	Mentor: 'Ohio',
	'Middleburg Heights': 'Ohio',
	'New Philadelphia': 'Ohio',
	'North Canton': 'Ohio',
	'North Olmsted': 'Ohio',
	'North Ridgeville': 'Ohio',
	'North Royalton': 'Ohio',
	Oregon: 'Ohio',
	Painesville: 'Ohio',
	'Parma Heights': 'Ohio',
	Perrysburg: 'Ohio',
	Piqua: 'Ohio',
	'Rocky River': 'Ohio',
	Sandusky: 'Ohio',
	'Shaker Heights': 'Ohio',
	Sidney: 'Ohio',
	Solon: 'Ohio',
	'South Euclid': 'Ohio',
	Steubenville: 'Ohio',
	Stow: 'Ohio',
	Streetsboro: 'Ohio',
	Strongsville: 'Ohio',
	Sylvania: 'Ohio',
	Tallmadge: 'Ohio',
	Tiffin: 'Ohio',
	Twinsburg: 'Ohio',
	Wadsworth: 'Ohio',
	Westerville: 'Ohio',
	Westlake: 'Ohio',
	Willoughby: 'Ohio',
	Wooster: 'Ohio',
	Youngstown: 'Ohio',
	Allentown: 'Pennsylvania',
	'Allison Park': 'Pennsylvania',
	'Back Mountain': 'Pennsylvania',
	Bensalem: 'Pennsylvania',
	'Bethel Park': 'Pennsylvania',
	Erie: 'Colorado',
	Harrisburg: 'Pennsylvania',
	Hazleton: 'Pennsylvania',
	Johnstown: 'Pennsylvania',
	'King of Prussia': 'Pennsylvania',
	Lansdale: 'Pennsylvania',
	McKeesport: 'Pennsylvania',
	Monroeville: 'Pennsylvania',
	'Mount Lebanon': 'Pennsylvania',
	Murrysville: 'Pennsylvania',
	'Nicetown-Tioga': 'Pennsylvania',
	Norristown: 'Pennsylvania',
	'Penn Hills': 'Pennsylvania',
	Phoenixville: 'Pennsylvania',
	Pittsburgh: 'Pennsylvania',
	Plum: 'Pennsylvania',
	Pottstown: 'Pennsylvania',
	Radnor: 'Pennsylvania',
	Scranton: 'Pennsylvania',
	'State College': 'Pennsylvania',
	'Upper Saint Clair': 'Pennsylvania',
	'West Mifflin': 'Pennsylvania',
	'Whitehall Township': 'Pennsylvania',
	'Wilkes-Barre': 'Pennsylvania',
	Wilkinsburg: 'Pennsylvania',
	Williamsport: 'Pennsylvania',
	'Willow Grove': 'Pennsylvania',
	Barrington: 'Rhode Island',
	'Central Falls': 'Rhode Island',
	Cranston: 'Rhode Island',
	'East Providence': 'Rhode Island',
	Narragansett: 'Rhode Island',
	'North Kingstown': 'Rhode Island',
	'North Providence': 'Rhode Island',
	Pawtucket: 'Rhode Island',
	Providence: 'Rhode Island',
	Smithfield: 'Rhode Island',
	'West Warwick': 'Rhode Island',
	Westerly: 'Rhode Island',
	Woonsocket: 'Rhode Island',
	Brookings: 'South Dakota',
	Mitchell: 'South Dakota',
	'Sioux Falls': 'South Dakota',
	Rutland: 'Vermont',
	'South Burlington': 'Vermont',
	Appleton: 'Wisconsin',
	Ashwaubenon: 'Wisconsin',
	'Beaver Dam': 'Wisconsin',
	Beloit: 'Wisconsin',
	Caledonia: 'Wisconsin',
	Cudahy: 'California',
	'De Pere': 'Wisconsin',
	'Eau Claire': 'Wisconsin',
	'Fond du Lac': 'Wisconsin',
	'Green Bay': 'Wisconsin',
	Howard: 'Wisconsin',
	Janesville: 'Wisconsin',
	Kaukauna: 'Wisconsin',
	Kenosha: 'Wisconsin',
	'La Crosse': 'Wisconsin',
	Manitowoc: 'Wisconsin',
	Marshfield: 'Wisconsin',
	Menasha: 'Wisconsin',
	'Menomonee Falls': 'Wisconsin',
	Menomonie: 'Wisconsin',
	Mequon: 'Wisconsin',
	Milwaukee: 'Wisconsin',
	Muskego: 'Wisconsin',
	Neenah: 'Wisconsin',
	'New Berlin': 'Wisconsin',
	'North La Crosse': 'Wisconsin',
	'Oak Creek': 'Wisconsin',
	Oconomowoc: 'Wisconsin',
	Onalaska: 'Wisconsin',
	Oshkosh: 'Wisconsin',
	'Pleasant Prairie': 'Wisconsin',
	Racine: 'Wisconsin',
	'River Falls': 'Wisconsin',
	Sheboygan: 'Wisconsin',
	'South Milwaukee': 'Wisconsin',
	'Stevens Point': 'Wisconsin',
	'Sun Prairie': 'Wisconsin',
	Superior: 'Wisconsin',
	Waukesha: 'Wisconsin',
	Wausau: 'Wisconsin',
	Wauwatosa: 'Wisconsin',
	'West Allis': 'Wisconsin',
	'West Bend': 'Wisconsin',
	'Wisconsin Rapids': 'Wisconsin',
	Weirton: 'West Virginia',
	'Weirton Heights': 'West Virginia',
	Ansonia: 'Connecticut',
	Branford: 'Connecticut',
	Cheshire: 'Connecticut',
	Fillmore: 'California',
	Buckeye: 'Arizona',
	'Bullhead City': 'Arizona',
	'Casa Grande': 'Arizona',
	'Casas Adobes': 'Arizona',
	'Catalina Foothills': 'Arizona',
	Chandler: 'Arizona',
	'Deer Valley': 'Arizona',
	'Drexel Heights': 'Arizona',
	'El Mirage': 'Arizona',
	Eloy: 'Arizona',
	Flagstaff: 'Arizona',
	'Flowing Wells': 'Arizona',
	'Fortuna Foothills': 'Arizona',
	'Fountain Hills': 'Arizona',
	Gilbert: 'Arizona',
	Goodyear: 'Arizona',
	'Green Valley': 'Arizona',
	Kingman: 'Arizona',
	'Lake Havasu City': 'Arizona',
	Marana: 'Arizona',
	Maricopa: 'Arizona',
	Maryvale: 'Arizona',
	Mesa: 'Arizona',
	'Oro Valley': 'Arizona',
	Payson: 'Utah',
	Phoenix: 'Arizona',
	Prescott: 'Arizona',
	'Prescott Valley': 'Arizona',
	'Queen Creek': 'Arizona',
	'Rio Rico': 'Arizona',
	Sahuarita: 'Arizona',
	Scottsdale: 'Arizona',
	'Sierra Vista': 'Arizona',
	Somerton: 'Arizona',
	'Sun City': 'California',
	'Sun City West': 'Arizona',
	Surprise: 'Arizona',
	'Tanque Verde': 'Arizona',
	Tempe: 'Arizona',
	'Tempe Junction': 'Arizona',
	Tucson: 'Arizona',
	Yuma: 'Arizona',
	Adelanto: 'California',
	Agoura: 'California',
	'Agoura Hills': 'California',
	Alameda: 'California',
	Alhambra: 'Arizona',
	'Aliso Viejo': 'California',
	Altadena: 'California',
	'Alum Rock': 'California',
	'American Canyon': 'California',
	Anaheim: 'California',
	Antelope: 'California',
	Antioch: 'California',
	Arcadia: 'California',
	'Arroyo Grande': 'California',
	Artesia: 'California',
	Arvin: 'California',
	Atascadero: 'California',
	Atwater: 'California',
	'Avocado Heights': 'California',
	Azusa: 'California',
	Bakersfield: 'California',
	'Baldwin Park': 'California',
	Banning: 'California',
	Barstow: 'California',
	'Barstow Heights': 'California',
	'Bay Point': 'California',
	Bell: 'California',
	'Bell Gardens': 'California',
	Bellflower: 'California',
	Benicia: 'California',
	Berkeley: 'California',
	'Beverly Hills': 'California',
	Blythe: 'California',
	Bostonia: 'California',
	'Boyle Heights': 'California',
	Brawley: 'California',
	Brea: 'California',
	'Buena Park': 'California',
	Burlingame: 'California',
	Calabasas: 'California',
	Calexico: 'California',
	Camarillo: 'California',
	'Cameron Park': 'California',
	Campbell: 'California',
	'Canoga Park': 'California',
	'Canyon Country': 'California',
	Carlsbad: 'New Mexico',
	Carmichael: 'California',
	Carson: 'California',
	Castaic: 'California',
	'Castro Valley': 'California',
	'Cathedral City': 'California',
	Cerritos: 'California',
	Chatsworth: 'California',
	Chico: 'California',
	'Chino Hills': 'California',
	Chowchilla: 'California',
	'Chula Vista': 'California',
	'Citrus Heights': 'California',
	Claremont: 'California',
	Clearlake: 'California',
	Clovis: 'New Mexico',
	Coachella: 'California',
	Coalinga: 'California',
	Colton: 'California',
	Compton: 'California',
	Corcoran: 'California',
	Coronado: 'California',
	'Costa Mesa': 'California',
	Covina: 'California',
	'Culver City': 'California',
	Cupertino: 'California',
	'Daly City': 'California',
	'Dana Point': 'California',
	Davis: 'California',
	Delano: 'California',
	'Desert Hot Springs': 'California',
	'Diamond Bar': 'California',
	Dinuba: 'California',
	Downey: 'California',
	Duarte: 'California',
	'East Rancho Dominguez': 'California',
	'East Hemet': 'California',
	'East Los Angeles': 'California',
	'East Palo Alto': 'California',
	'El Cajon': 'California',
	'El Centro': 'California',
	'El Dorado Hills': 'California',
	'El Segundo': 'California',
	'Elk Grove': 'California',
	Encinitas: 'California',
	Encino: 'California',
	Escondido: 'California',
	'Fair Oaks': 'California',
	Fallbrook: 'California',
	Florin: 'California',
	Folsom: 'California',
	'Foothill Farms': 'California',
	'Foster City': 'California',
	'Fountain Valley': 'California',
	Fullerton: 'California',
	Galt: 'California',
	'Garden Grove': 'California',
	Gardena: 'California',
	Gilroy: 'California',
	'Glen Avon': 'California',
	Glendora: 'California',
	Goleta: 'California',
	'Granite Bay': 'California',
	'Hacienda Heights': 'California',
	Hanford: 'California',
	Hayward: 'California',
	Hemet: 'California',
	Hercules: 'California',
	'Hermosa Beach': 'California',
	Hesperia: 'California',
	Hollister: 'California',
	'Huntington Beach': 'California',
	'Huntington Park': 'California',
	'Imperial Beach': 'California',
	Indio: 'California',
	Inglewood: 'California',
	'Isla Vista': 'California',
	Koreatown: 'California',
	'La Cañada Flintridge': 'California',
	'La Habra': 'California',
	'La Jolla': 'California',
	'La Mirada': 'California',
	'La Palma': 'California',
	'La Presa': 'California',
	'La Puente': 'California',
	'La Quinta': 'California',
	'La Verne': 'California',
	'Ladera Ranch': 'California',
	'Laguna Beach': 'California',
	'Laguna Hills': 'California',
	'Laguna Niguel': 'California',
	'Laguna Woods': 'California',
	'Lake Elsinore': 'California',
	Lamont: 'California',
	Lathrop: 'California',
	Lawndale: 'California',
	'Lemon Grove': 'California',
	Lemoore: 'California',
	Lennox: 'California',
	Linda: 'California',
	Livermore: 'California',
	'Loma Linda': 'California',
	Lomita: 'California',
	Lompoc: 'California',
	'Los Altos': 'California',
	'Los Angeles': 'California',
	'Los Banos': 'California',
	'Los Gatos': 'California',
	Lynwood: 'California',
	'Manhattan Beach': 'California',
	Manteca: 'California',
	Marina: 'California',
	'Mead Valley': 'California',
	Menifee: 'California',
	'Menlo Park': 'California',
	Merced: 'California',
	Millbrae: 'California',
	Milpitas: 'California',
	'Jurupa Valley': 'California',
	'Mira Mesa': 'California',
	'Mission District': 'California',
	'Mission Viejo': 'California',
	Modesto: 'California',
	Montebello: 'California',
	Monterey: 'California',
	'Monterey Park': 'California',
	Moorpark: 'California',
	Moraga: 'California',
	'Moreno Valley': 'California',
	'Morgan Hill': 'California',
	'Mountain View': 'California',
	Murrieta: 'California',
	Napa: 'California',
	'National City': 'California',
	'Newport Beach': 'California',
	Nipomo: 'California',
	Norco: 'California',
	'North Highlands': 'California',
	'North Hollywood': 'California',
	Northridge: 'California',
	Novato: 'California',
	Oakland: 'California',
	Oakley: 'California',
	Oildale: 'California',
	Ontario: 'California',
	Orangevale: 'California',
	Orcutt: 'California',
	Orinda: 'California',
	Oroville: 'California',
	Oxnard: 'California',
	Pacifica: 'California',
	'Pacific Grove': 'California',
	'Palm Desert': 'California',
	Palmdale: 'California',
	'Palo Alto': 'California',
	Paramount: 'California',
	Parlier: 'California',
	'Paso Robles': 'California',
	Patterson: 'California',
	Perris: 'California',
	Petaluma: 'California',
	'Pico Rivera': 'California',
	Pinole: 'California',
	Placentia: 'California',
	'Pleasant Hill': 'California',
	Pleasanton: 'California',
	Pomona: 'California',
	'Port Hueneme': 'California',
	Porterville: 'California',
	Poway: 'California',
	Prunedale: 'California',
	Ramona: 'California',
	'Rancho Cordova': 'California',
	'Rancho Cucamonga': 'California',
	'Rancho Mirage': 'California',
	'Rancho Palos Verdes': 'California',
	'Rancho Penasquitos': 'California',
	'Rancho San Diego': 'California',
	'Rancho Santa Margarita': 'California',
	Redlands: 'California',
	'Redondo Beach': 'California',
	'Redwood City': 'California',
	Reedley: 'California',
	Rialto: 'California',
	Ridgecrest: 'California',
	'Rio Linda': 'California',
	Riverbank: 'California',
	Rocklin: 'California',
	'Rohnert Park': 'California',
	Rosamond: 'California',
	Rosemead: 'California',
	Rosemont: 'California',
	'Rowland Heights': 'California',
	Rubidoux: 'California',
	'San Bernardino': 'California',
	'San Bruno': 'California',
	'San Dimas': 'California',
	'San Gabriel': 'California',
	'San Juan Capistrano': 'California',
	'San Leandro': 'California',
	'San Luis Obispo': 'California',
	'San Ramon': 'California',
	Sanger: 'California',
	'Santa Clarita': 'California',
	'Santa Fe Springs': 'California',
	'Santa Monica': 'California',
	'Santa Paula': 'California',
	Santee: 'California',
	Saratoga: 'California',
	'Seal Beach': 'California',
	Seaside: 'California',
	'North Hills': 'California',
	Shafter: 'California',
	'Sherman Oaks': 'California',
	'Simi Valley': 'California',
	'South El Monte': 'California',
	'South Lake Tahoe': 'California',
	'South Pasadena': 'California',
	'South San Francisco': 'California',
	'South San Jose Hills': 'California',
	'South Whittier': 'California',
	'South Yuba City': 'California',
	Stanton: 'California',
	Stockton: 'California',
	'Studio City': 'California',
	Suisun: 'California',
	Sunland: 'California',
	Sunnyvale: 'California',
	Temecula: 'California',
	'Temple City': 'California',
	'Thousand Oaks': 'California',
	Torrance: 'California',
	Tracy: 'California',
	Truckee: 'California',
	Tujunga: 'California',
	Tulare: 'California',
	Turlock: 'California',
	Tustin: 'California',
	'North Tustin': 'California',
	'Twentynine Palms': 'California',
	Ukiah: 'California',
	Upland: 'California',
	Vacaville: 'California',
	Valinda: 'California',
	Vallejo: 'California',
	'Van Nuys': 'California',
	Ventura: 'California',
	Victorville: 'California',
	Vincent: 'California',
	Visalia: 'California',
	Vista: 'California',
	Walnut: 'California',
	'Walnut Creek': 'California',
	'Walnut Park': 'California',
	Watsonville: 'California',
	'West Carson': 'California',
	'West Covina': 'California',
	'West Puente Valley': 'California',
	'West Sacramento': 'California',
	Whittier: 'California',
	Wildomar: 'California',
	Willowbrook: 'California',
	'Winter Gardens': 'California',
	Woodland: 'California',
	'Woodland Hills': 'California',
	'Yorba Linda': 'California',
	'Yuba City': 'California',
	Yucaipa: 'California',
	'Yucca Valley': 'California',
	Arvada: 'Colorado',
	Broomfield: 'Colorado',
	'Cañon City': 'Colorado',
	'Castle Rock': 'Colorado',
	Castlewood: 'Colorado',
	Centennial: 'Colorado',
	'Cimarron Hills': 'Colorado',
	'Colorado Springs': 'Colorado',
	Columbine: 'Colorado',
	'Commerce City': 'Colorado',
	Denver: 'Colorado',
	Fountain: 'Colorado',
	Golden: 'Colorado',
	'Grand Junction': 'Colorado',
	'Greenwood Village': 'Colorado',
	'Highlands Ranch': 'Colorado',
	'Ken Caryl': 'Colorado',
	Littleton: 'Colorado',
	Montrose: 'Colorado',
	Northglenn: 'Colorado',
	Parker: 'Colorado',
	Pueblo: 'Colorado',
	'Pueblo West': 'Colorado',
	Sherrelwood: 'Colorado',
	Southglenn: 'Colorado',
	Thornton: 'Colorado',
	'Wheat Ridge': 'Colorado',
	'Dodge City': 'Kansas',
	Liberal: 'Kansas',
	Alamogordo: 'New Mexico',
	Albuquerque: 'New Mexico',
	Gallup: 'New Mexico',
	Hobbs: 'New Mexico',
	'Las Cruces': 'New Mexico',
	'Los Lunas': 'New Mexico',
	'Rio Rancho': 'New Mexico',
	'South Valley': 'New Mexico',
	'Sunland Park': 'New Mexico',
	'Boulder City': 'Nevada',
	'Carson City': 'Nevada',
	Fernley: 'Nevada',
	'Las Vegas': 'Cojedes',
	'North Las Vegas': 'Nevada',
	Pahrump: 'Nevada',
	Reno: 'Nevada',
	'Spanish Springs': 'Nevada',
	Sparks: 'Nevada',
	'Sun Valley': 'Nevada',
	'Sunrise Manor': 'Nevada',
	Whitney: 'Nevada',
	Amarillo: 'Texas',
	'Big Spring': 'Texas',
	'Del Rio': 'Texas',
	Dumas: 'Texas',
	'Eagle Pass': 'Texas',
	'El Paso': 'Texas',
	'Horizon City': 'Texas',
	Lubbock: 'Texas',
	Odessa: 'Texas',
	Pampa: 'Texas',
	'San Angelo': 'Texas',
	'Socorro Mission Number 1 Colonia': 'Texas',
	'West Odessa': 'Texas',
	'Cedar City': 'Utah',
	Hurricane: 'Utah',
	'Saint George': 'Utah',
	Anthem: 'Arizona',
	'Apache Junction': 'Arizona',
	'Ahwatukee Foothills': 'Arizona',
	Juneau: 'Alaska',
	Arcata: 'California',
	Eureka: 'California',
	McKinleyville: 'California',
	Redding: 'California',
	Susanville: 'California',
	Boulder: 'Colorado',
	'Fort Collins': 'Colorado',
	Greeley: 'Colorado',
	Longmont: 'Colorado',
	Loveland: 'Colorado',
	Boise: 'Idaho',
	Caldwell: 'Idaho',
	"Coeur d'Alene": 'Idaho',
	Eagle: 'Idaho',
	'Idaho Falls': 'Idaho',
	Kuna: 'Idaho',
	'Lewiston Orchards': 'Idaho',
	Nampa: 'Idaho',
	Pocatello: 'Idaho',
	'Post Falls': 'Idaho',
	Rexburg: 'Idaho',
	'Twin Falls': 'Idaho',
	Billings: 'Montana',
	Bozeman: 'Montana',
	Butte: 'Montana',
	Kalispell: 'Montana',
	Missoula: 'Montana',
	Bismarck: 'North Dakota',
	Mandan: 'North Dakota',
	Minot: 'North Dakota',
	Williston: 'North Dakota',
	'North Platte': 'Nebraska',
	Elko: 'Nevada',
	Aloha: 'Oregon',
	Altamont: 'Oregon',
	Beaverton: 'Oregon',
	Bend: 'Oregon',
	Canby: 'Oregon',
	'Central Point': 'Oregon',
	'Coos Bay': 'Oregon',
	Corvallis: 'Oregon',
	Eugene: 'Oregon',
	'Forest Grove': 'Oregon',
	'Four Corners': 'Florida',
	'Grants Pass': 'Oregon',
	Gresham: 'Oregon',
	'Happy Valley': 'Oregon',
	Hayesville: 'Oregon',
	Hermiston: 'Oregon',
	Hillsboro: 'Oregon',
	Keizer: 'Oregon',
	'Klamath Falls': 'Oregon',
	'Lake Oswego': 'Oregon',
	Lents: 'Oregon',
	McMinnville: 'Oregon',
	Milwaukie: 'Oregon',
	Newberg: 'Oregon',
	'Oak Grove': 'Oregon',
	'Oregon City': 'Oregon',
	Pendleton: 'Oregon',
	Redmond: 'Washington',
	Roseburg: 'Oregon',
	'The Dalles': 'Oregon',
	Tigard: 'Oregon',
	Troutdale: 'Oregon',
	Tualatin: 'Oregon',
	'West Linn': 'Oregon',
	Wilsonville: 'Oregon',
	Woodburn: 'Oregon',
	'Rapid City': 'South Dakota',
	Bountiful: 'Utah',
	'Brigham City': 'Utah',
	Clearfield: 'Utah',
	'Cottonwood Heights': 'Utah',
	Draper: 'Utah',
	'Eagle Mountain': 'Utah',
	'East Millcreek': 'Utah',
	Herriman: 'Utah',
	Holladay: 'Utah',
	Kaysville: 'Utah',
	Kearns: 'Utah',
	Layton: 'Utah',
	Lehi: 'Utah',
	Logan: 'Utah',
	Magna: 'Utah',
	Midvale: 'Utah',
	Millcreek: 'Utah',
	'North Ogden': 'Utah',
	'North Salt Lake': 'Utah',
	Ogden: 'Utah',
	Orem: 'Utah',
	'Pleasant Grove': 'Utah',
	Provo: 'Utah',
	Riverton: 'Utah',
	Roy: 'Utah',
	'Salt Lake City': 'Utah',
	Sandy: 'Utah',
	'Sandy Hills': 'Utah',
	'South Jordan Heights': 'Utah',
	'South Jordan': 'Utah',
	'South Ogden': 'Utah',
	'South Salt Lake': 'Utah',
	'Spanish Fork': 'Utah',
	Springville: 'Utah',
	Taylorsville: 'Utah',
	Tooele: 'Utah',
	'West Jordan': 'Utah',
	'West Valley City': 'Utah',
	Anacortes: 'Washington',
	'Battle Ground': 'Washington',
	Bellingham: 'Washington',
	'Bonney Lake': 'Washington',
	Bothell: 'Washington',
	Bremerton: 'Washington',
	Burien: 'Washington',
	Centralia: 'Washington',
	'Cottage Lake': 'Washington',
	Edmonds: 'Washington',
	Ellensburg: 'Washington',
	Fairwood: 'Washington',
	'Federal Way': 'Washington',
	'Five Corners': 'Washington',
	Frederickson: 'Washington',
	Graham: 'Washington',
	'Hazel Dell': 'Washington',
	Issaquah: 'Washington',
	Kennewick: 'Washington',
	Lacey: 'Washington',
	'Lake Stevens': 'Washington',
	Lynnwood: 'Washington',
	'Maple Valley': 'Washington',
	'Martha Lake': 'Washington',
	'Mercer Island': 'Washington',
	'Mill Creek': 'Washington',
	'Moses Lake': 'Washington',
	'Mountlake Terrace': 'Washington',
	Mukilteo: 'Washington',
	'North Creek': 'Washington',
	'Oak Harbor': 'Washington',
	Olympia: 'Washington',
	Opportunity: 'Washington',
	Orchards: 'Washington',
	Pasco: 'Washington',
	'Port Angeles': 'Washington',
	Pullman: 'Washington',
	Puyallup: 'Washington',
	Renton: 'Washington',
	Richland: 'Washington',
	'Salmon Creek': 'Washington',
	Sammamish: 'Washington',
	SeaTac: 'Washington',
	Seattle: 'Washington',
	Shoreline: 'Washington',
	Silverdale: 'Washington',
	'South Hill': 'Washington',
	Spanaway: 'Washington',
	Spokane: 'Washington',
	'Spokane Valley': 'Washington',
	Tacoma: 'Washington',
	Tukwila: 'Washington',
	Tumwater: 'Washington',
	'University Place': 'Washington',
	'Walla Walla': 'Washington',
	Washougal: 'Washington',
	Wenatchee: 'Washington',
	'West Lake Sammamish': 'Washington',
	'West Lake Stevens': 'Washington',
	'Bainbridge Island': 'Washington',
	Yakima: 'Washington',
	Casper: 'Wyoming',
	Cheyenne: 'Wyoming',
	Gillette: 'Wyoming',
	Laramie: 'Wyoming',
	'Rock Springs': 'Wyoming',
	Sheridan: 'Wyoming',
	'American Fork': 'Utah',
	Kahului: 'Hawaii',
	Kailua: 'Hawaii',
	Kaneohe: 'Hawaii',
	Kīhei: 'Hawaii',
	'Makakilo City': 'Hawaii',
	'Mililani Town': 'Hawaii',
	'Pearl City': 'Hawaii',
	Wahiawā: 'Hawaii',
	Wailuku: 'Hawaii',
	Waipahu: 'Hawaii',
	'‘Ewa Gentry': 'Hawaii',
	Hilo: 'Hawaii',
	Honolulu: 'Hawaii',
	'Eagle River': 'Alaska',
	Fairbanks: 'Alaska',
	Anchorage: 'Alaska',
	Badger: 'Alaska',
	'Johns Creek': 'Georgia',
	'Cutler Bay': 'Florida',
	'Morningside Heights': 'New York',
	'East Harlem': 'New York',
	Alafaya: 'Florida',
	Millburn: 'New Jersey',
	Briarwood: 'New York',
	'Stevenson Ranch': 'California',
	'Fort Liberty': 'North Carolina',
	Kapolei: 'Hawaii',
	'City of Milford (balance)': 'Connecticut',
	'City of Sammamish': 'Washington',
	'Silver Firs': 'Washington',
	Vineyard: 'California',
	'Gramercy Park': 'New York',
	'Kew Gardens Hills': 'New York',
	'Wallingford Center': 'Connecticut',
	'North Attleborough Center': 'Massachusetts',
	'Bel Air North': 'Maryland',
	'Bel Air South': 'Maryland',
	'Mercerville-Hamilton Square': 'New Jersey',
	'Oxon Hill-Glassmanor': 'Maryland',
	'Gates-North Gates': 'New York',
	'Greater Upper Marlboro': 'Maryland',
	'West Falls Church': 'Virginia',
	'Lanham-Seabrook': 'Maryland',
	'Lutherville-Timonium': 'Maryland',
	'Setauket-East Setauket': 'New York',
	'Suitland-Silver Hill': 'Maryland',
	'Fort Leonard Wood': 'Missouri',
	'Saginaw Township North': 'Michigan',
	'West Bloomfield Township': 'Michigan',
	'East Lake-Orient Park': 'Florida',
	'Greater Northdale': 'Florida',
	'Candler-McAfee': 'Georgia',
	'Sandalfoot Cove': 'Florida',
	University: 'Florida',
	'Vero Beach South': 'Florida',
	'Kendall West': 'Florida',
	'Palm River-Clair Mel': 'Florida',
	'Arden-Arcade': 'California',
	'Bryn Mawr-Skyway': 'Washington',
	'Casa de Oro-Mount Helix': 'California',
	'Florence-Graham': 'California',
	'Fort Hood': 'Texas',
	'Inglewood-Finn Hill': 'Washington',
	'La Crescenta-Montrose': 'California',
	'East Hill-Meridian': 'Washington',
	'Picnic Point-North Lynnwood': 'Washington',
	'Security-Widefield': 'Colorado',
	'Union Hill-Novelty Hill': 'Washington',
	'West Whittier-Los Nietos': 'California',
	'Summerlin South': 'Nevada',
	Makakilo: 'Hawaii',
	'Schofield Barracks': 'Hawaii',
	'Tri-Cities': 'Washington',
	'San Tan Valley': 'Arizona',
	Midway: 'Florida',
	'Oakleaf Plantation': 'Florida',
	'East Honolulu': 'Hawaii',
	'Old Jamestown': 'Missouri',
	'Bothell West': 'Washington',
	Eastmont: 'Washington',
	'Mill Creek East': 'Washington',
	'Enchanted Hills': 'New Mexico',
	'West Hills': 'California',
	'Detroit-Shoreway': 'Ohio',
	'Clark-Fulton': 'Ohio',
	'The Acreage': 'Florida',
	Eastvale: 'California',
	'Oak Hill': 'Virginia',
	Bridgewater: 'New Jersey',
	'Warren Township': 'New Jersey',
	'Fairfield Heights': 'Indiana',
	'Chicago Loop': 'Illinois',
	'Greater Grand Crossing': 'Illinois',
	'Auburn Gresham': 'Illinois',
	'Financial District': 'New York',
	'Sunset Park': 'New York',
	'South Kingstown': 'Rhode Island',
	'Noe Valley': 'California',
	'Visitacion Valley': 'California',
	Northdale: 'Florida',
	Meadowbrook: 'Virginia',
	'Hot Springs National Park': 'Arkansas',
	Dixiana: 'Alabama',
	'Cranberry Township': 'Pennsylvania',
	'Center City': 'Pennsylvania',
	'Silver Lake': 'California',
	'Echo Park': 'California',
	'St. Johns': 'Florida',
	Stonecrest: 'Georgia',
	'Cortlandt Manor': 'New York',
	'Valley Glen': 'California',
	Encanto: 'Arizona',
	'Central City': 'Arizona',
	'South Fulton': 'Georgia',
	Young: 'Río Negro Department',
	'Treinta y Tres': 'Treinta y Tres Department',
	Tacuarembó: 'Tacuarembó Department',
	'San José de Mayo': 'San José Department',
	Rocha: 'Rocha Department',
	Paysandú: 'Paysandú Department',
	'Paso de Carrasco': 'Canelones',
	Pando: 'Canelones',
	Montevideo: 'Montevideo Department',
	Melo: 'Cerro Largo',
	Maldonado: 'Maldonado',
	'Las Piedras': 'Canelones',
	'Fray Bentos': 'Río Negro Department',
	Durazno: 'Durazno Department',
	'Delta del Tigre': 'San José Department',
	'Colonia del Sacramento': 'Colonia',
	Carmelo: 'Colonia',
	Canelones: 'Canelones',
	Artigas: 'Artigas',
	'Barros Blancos': 'Canelones',
	Nukus: 'Karakalpakstan',
	Qŭnghirot: 'Karakalpakstan',
	'Xo‘jayli Shahri': 'Karakalpakstan',
	'Oltinko‘l': 'Karakalpakstan',
	'Zomin Shaharchasi': 'Jizzax',
	'Urgut Shahri': 'Samarqand',
	Tirmiz: 'Surxondaryo',
	'Sho‘rchi': 'Surxondaryo',
	Shahrisabz: 'Qashqadaryo',
	Samarkand: 'Samarqand',
	Qarshi: 'Qashqadaryo',
	Muborak: 'Qashqadaryo',
	Kitob: 'Qashqadaryo',
	'Kattaqo’rg’on Shahri': 'Samarqand',
	Koson: 'Qashqadaryo',
	'Karakul’': 'Bukhara',
	'Kogon Shahri': 'Bukhara',
	'G‘uzor': 'Qashqadaryo',
	'Galaosiyo Shahri': 'Bukhara',
	'Juma Shahri': 'Samarqand',
	Denov: 'Surxondaryo',
	Chiroqchi: 'Qashqadaryo',
	Chelak: 'Samarqand',
	'Bulung’ur Shahri': 'Samarqand',
	Bukhara: 'Bukhara',
	Beshkent: 'Qashqadaryo',
	Boysun: 'Surxondaryo',
	Oqtosh: 'Samarqand',
	Zafar: 'Toshkent',
	Yaypan: 'Fergana',
	Yangiyŭl: 'Toshkent',
	Yangiyer: 'Sirdaryo Region',
	Yangirabot: 'Navoiy',
	'Yangiqo‘rg‘on': 'Namangan',
	Yangiobod: 'Toshkent',
	'Vobkent Shahri': 'Bukhara',
	Uychi: 'Namangan',
	Urganch: 'Xorazm',
	Dashtobod: 'Jizzax',
	'Uchqŭrghon Shahri': 'Namangan',
	Tŭytepa: 'Toshkent',
	'To‘rqao‘rg‘on': 'Namangan',
	Toshloq: 'Fergana',
	Tashkent: 'Tashkent',
	Toshbuloq: 'Namangan',
	Sirdaryo: 'Sirdaryo Region',
	Shovot: 'Xorazm',
	'Shofirkon Shahri': 'Bukhara',
	Salor: 'Toshkent',
	'Qo’shko’pir': 'Xorazm',
	'Qo‘qon': 'Fergana',
	Piskent: 'Toshkent',
	'Payshamba Shahri': 'Samarqand',
	Parkent: 'Toshkent',
	Pop: 'Namangan',
	'Paxtakor Shahri': 'Jizzax',
	Olmaliq: 'Toshkent',
	Ohangaron: 'Toshkent',
	Nurota: 'Navoiy',
	'Novyy Turtkul’': 'Karakalpakstan',
	Navoiy: 'Navoiy',
	Namangan: 'Namangan',
	'Marg‘ilon': 'Fergana',
	Manghit: 'Karakalpakstan',
	Quvasoy: 'Fergana',
	'Qo‘rg‘ontepa': 'Andijon',
	Kirguli: 'Fergana',
	Qibray: 'Toshkent',
	Xonobod: 'Andijon',
	'Xo‘jaobod': 'Andijon',
	Xiva: 'Xorazm',
	Haqqulobod: 'Namangan',
	Kosonsoy: 'Namangan',
	Jizzax: 'Jizzax',
	Iskandar: 'Toshkent',
	Qorasuv: 'Andijon',
	Hazorasp: 'Xorazm',
	Gurlan: 'Xorazm',
	Guliston: 'Sirdaryo Region',
	'G’ijduvon Shahri': 'Bukhara',
	'G‘azalkent': 'Toshkent',
	'Gagarin Shahri': 'Jizzax',
	Fergana: 'Fergana',
	Jalolquduq: 'Andijon',
	'Do’stlik Shahri': 'Jizzax',
	Druzhba: 'Xorazm',
	Dardoq: 'Andijon',
	Chust: 'Namangan',
	Chirchiq: 'Toshkent',
	Chinoz: 'Toshkent',
	Chortoq: 'Namangan',
	'Bo‘ka': 'Toshkent',
	Beshariq: 'Fergana',
	Beruniy: 'Karakalpakstan',
	Bektemir: 'Tashkent',
	Bekobod: 'Toshkent',
	Angren: 'Toshkent',
	Andijon: 'Andijon',
	Oltiariq: 'Fergana',
	Oyim: 'Andijon',
	Quva: 'Fergana',
	Sultonobod: 'Andijon',
	'Vatican City': '',
	Kingstown: 'Saint George',
	'Kingstown Park': 'Saint George',
	'La Asunción': 'Nueva Esparta',
	Anaco: 'Anzoátegui',
	'Alto Barinas': 'Barinas',
	Zaraza: 'Guárico',
	Yumare: 'Yaracuy',
	Yaritagua: 'Yaracuy',
	'Villa de Cura': 'Aragua',
	'Villa Bruzual': 'Portuguesa',
	'Valle de La Pascua': 'Guárico',
	Valera: 'Trujillo',
	Ureña: 'Táchira',
	Urachiche: 'Yaracuy',
	Upata: 'Bolívar',
	Turmero: 'Aragua',
	Tumeremo: 'Bolívar',
	Tucupita: 'Delta Amacuro',
	Tucupido: 'Guárico',
	Tucaní: 'Mérida',
	Tucacas: 'Falcón',
	Tovar: 'Mérida',
	Tinaquillo: 'Cojedes',
	Tinaco: 'Cojedes',
	Timotes: 'Mérida',
	'Tía Juana': 'Zulia',
	Temblador: 'Monagas',
	Táriba: 'Táchira',
	Tabay: 'Mérida',
	Socopó: 'Barinas',
	'Santa Teresa del Tuy': 'Miranda',
	'Santa Elena de Uairén': 'Bolívar',
	'Santa Cruz de Mora': 'Mérida',
	'Santa Cruz de Mara': 'Zulia',
	'San Rafael de Onoto': 'Portuguesa',
	'San Juan de los Morros': 'Guárico',
	'San José de Barlovento': 'Miranda',
	'San José de Guanipa': 'Anzoátegui',
	'San Joaquín': 'Carabobo',
	'San Carlos del Zulia': 'Zulia',
	Sanare: 'Lara',
	'San Antonio del Táchira': 'Táchira',
	'San Antonio de Los Altos': 'Miranda',
	'Sabana de Parra': 'Yaracuy',
	Rubio: 'Táchira',
	'La Villa del Rosario': 'Zulia',
	'Río Chico': 'Miranda',
	Quíbor: 'Lara',
	'Punto Fijo': 'Falcón',
	'Punta de Piedras': 'Nueva Esparta',
	'Punta de Mata': 'Monagas',
	'Punta Cardón': 'Falcón',
	'Puerto Píritu': 'Anzoátegui',
	'Puerto La Cruz': 'Anzoátegui',
	'Puerto Cumarebo': 'Falcón',
	'Puerto Cabello': 'Carabobo',
	'Puerto Ayacucho': 'Amazonas',
	Porlamar: 'Nueva Esparta',
	Píritu: 'Portuguesa',
	Petare: 'Miranda',
	Pariaguán: 'Anzoátegui',
	Pampatar: 'Nueva Esparta',
	Pampanito: 'Trujillo',
	Pampán: 'Trujillo',
	'Palo Negro': 'Aragua',
	Ospino: 'Portuguesa',
	'Ocumare del Tuy': 'Miranda',
	Obispos: 'Barinas',
	'Nueva Bolivia': 'Mérida',
	Nirgua: 'Yaracuy',
	Motatán: 'Trujillo',
	Montalbán: 'Carabobo',
	Michelena: 'Táchira',
	'Mene Grande': 'Zulia',
	'Mene de Mauroa': 'Falcón',
	Mariara: 'Carabobo',
	Maracay: 'Aragua',
	Maracaibo: 'Zulia',
	Mamporal: 'Miranda',
	Maiquetía: 'Vargas',
	Machiques: 'Zulia',
	'Los Teques': 'Miranda',
	'Los Rastrojos': 'Lara',
	'Los Dos Caminos': 'Miranda',
	'La Victoria': 'Aragua',
	'La Vela de Coro': 'Falcón',
	'Las Tejerías': 'Aragua',
	'La Paragua': 'Bolívar',
	Lagunillas: 'Mérida',
	'La Guaira': 'Vargas',
	'La Azulita': 'Mérida',
	Judibana: 'Falcón',
	'Juan Griego': 'Nueva Esparta',
	Higuerote: 'Miranda',
	Güiria: 'Sucre',
	Güigüe: 'Carabobo',
	Guatire: 'Miranda',
	Guasipati: 'Bolívar',
	Guarenas: 'Miranda',
	Guanarito: 'Portuguesa',
	Guanare: 'Portuguesa',
	Guama: 'Yaracuy',
	Guacara: 'Carabobo',
	Escuque: 'Trujillo',
	Encontrados: 'Zulia',
	'El Vigía': 'Mérida',
	'El Tocuyo': 'Lara',
	'El Tigre': 'Anzoátegui',
	'El Sombrero': 'Guárico',
	'El Pao': 'Cojedes',
	Elorza: 'Apure',
	Lecherías: 'Anzoátegui',
	'El Limón': 'Aragua',
	'El Hatillo': 'Miranda',
	Ejido: 'Mérida',
	Duaca: 'Lara',
	Dabajuro: 'Falcón',
	Cumanacoa: 'Sucre',
	Cumaná: 'Sucre',
	Cúa: 'Miranda',
	Coro: 'Falcón',
	Coloncito: 'Táchira',
	Cocorote: 'Yaracuy',
	Clarines: 'Anzoátegui',
	'Ciudad Piar': 'Bolívar',
	'Ciudad Ojeda': 'Zulia',
	'Ciudad Guayana': 'Bolívar',
	'Ciudad Bolivia': 'Barinas',
	Chivacoa: 'Yaracuy',
	Charallave: 'Miranda',
	Chaguaramas: 'Guárico',
	Chacao: 'Miranda',
	Tacarigua: 'Carabobo',
	Caucagüito: 'Miranda',
	Caucagua: 'Miranda',
	'Catia La Mar': 'Vargas',
	'Casigua El Cubo': 'Zulia',
	Casanay: 'Sucre',
	Carúpano: 'Sucre',
	Carora: 'Lara',
	Caripito: 'Monagas',
	Cariaco: 'Sucre',
	Caracas: 'Distrito Federal',
	Caraballeda: 'Vargas',
	Cantaura: 'Anzoátegui',
	Camaguán: 'Guárico',
	Calabozo: 'Guárico',
	'Caja Seca': 'Zulia',
	Caicara: 'Monagas',
	'Caicara del Orinoco': 'Bolívar',
	Cagua: 'Aragua',
	Cabudare: 'Lara',
	Cabimas: 'Zulia',
	Boraure: 'Yaracuy',
	Boconoito: 'Portuguesa',
	Boconó: 'Trujillo',
	Biruaca: 'Apure',
	Bejuma: 'Carabobo',
	Baruta: 'Miranda',
	Barquisimeto: 'Lara',
	Barinitas: 'Barinas',
	Barinas: 'Barinas',
	Bailadores: 'Mérida',
	Aroa: 'Yaracuy',
	Araya: 'Sucre',
	Araure: 'Portuguesa',
	'Aragua de Barcelona': 'Anzoátegui',
	'Altagracia de Orituco': 'Guárico',
	'Los Puertos de Altagracia': 'Zulia',
	Achaguas: 'Apure',
	Acarigua: 'Portuguesa',
	'San Josecito': 'Táchira',
	Maturín: 'Monagas',
	'La Fría': 'Táchira',
	'La Grita': 'Táchira',
	'Capacho Nuevo': 'Táchira',
	'El Cafetal': 'Miranda',
	'La Dolorita': 'Miranda',
	Guasdualito: 'Apure',
	'San Fernando de Apure': 'Apure',
	'Santa Cruz de los Taques': 'Falcón',
	'Road Town': '',
	'Charlotte Amalie': 'Saint Thomas Island',
	'Saint Croix': 'Saint Croix Island',
	'Yên Vinh': 'Nghệ An',
	'Yên Bái': 'Yên Bái',
	'Xuân Mai': 'Hanoi',
	'Vũng Tàu': 'Bà Rịa-Vũng Tàu',
	'Vị Thanh': 'Hậu Giang',
	'Vĩnh Yên': 'Vĩnh Phúc',
	'Vĩnh Long': 'Vĩnh Long',
	'Vĩnh Châu': 'Sóc Trăng',
	Vinh: 'Nghệ An',
	'Việt Trì': 'Phú Thọ',
	'Thành Phố Uông Bí': 'Quảng Ninh',
	'Tuy Hòa': 'Phú Yên',
	'Tuyên Quang': 'Tuyên Quang',
	'Trà Vinh': 'Trà Vinh',
	'Thủ Dầu Một': 'Bình Dương',
	'Thốt Nốt': 'Can Tho',
	'Thới Lai': 'Can Tho',
	'Ho Chi Minh City': 'Ho Chi Minh',
	'Thanh Hóa': 'Thanh Hóa',
	'Thái Nguyên': 'Thái Nguyên',
	'Thái Bình': 'Thái Bình',
	'Tây Ninh': 'Tây Ninh Province',
	'Tân An': 'Long An',
	'Tam Kỳ': 'Quảng Nam',
	'Sơn Tây': 'Hanoi',
	'Sơn La': 'Sơn La',
	'Sông Cầu': 'Phú Yên',
	'Sóc Trăng': 'Sóc Trăng',
	'Sa Dec': 'Đồng Tháp',
	'Rạch Giá': 'Kiến Giang',
	'Qui Nhon': 'Bình Định',
	'Quảng Ngãi': 'Quảng Ngãi Province',
	Pleiku: 'Gia Lai',
	'Phủ Lý': 'Hà Nam',
	'Phú Khương': 'Tây Ninh Province',
	'Phong Điền': 'Can Tho',
	'Phan Thiết': 'Bình Thuận',
	'Phan Rang-Tháp Chàm': 'Ninh Thuận',
	'Ô Môn': 'Can Tho',
	'Ninh Hòa': 'Khánh Hòa',
	'Ninh Bình': 'Ninh Bình',
	'Nha Trang': 'Khánh Hòa',
	'Nhà Bè': 'Ho Chi Minh',
	'Nghĩa Lộ': 'Yên Bái',
	'Nam Định': 'Nam Định',
	'Mỹ Tho': 'Tiền Giang',
	'Mường Lay': 'Ðiện Biên',
	'Móng Cái': 'Quảng Ninh',
	'Long Xuyên': 'An Giang',
	'Lào Cai': 'Lào Cai',
	'Lạng Sơn': 'Lạng Sơn',
	'La Gi': 'Bình Thuận',
	'Kon Tum': 'Kon Tum',
	'Hưng Yên': 'Hưng Yên',
	Huế: 'Thừa Thiên-Huế',
	'Hạ Long': 'Quảng Ninh',
	'Hoi An': 'Quảng Nam',
	'Hòa Bình': 'Hòa Bình',
	'Hà Tĩnh': 'Hà Tĩnh',
	'Hà Tiên': 'Kiến Giang',
	Hanoi: 'Hanoi',
	Haiphong: 'Haiphong',
	'Hải Dương': 'Hải Dương',
	'Hà Giang': 'Hà Giang',
	'Hà Đông': 'Hanoi',
	'Gò Công': 'Tiền Giang',
	'Gia Nghĩa': 'Ðắk Nông',
	'Đưc Trọng': 'Lâm Đồng',
	'Đồng Xoài': 'Bình Phước',
	'Đồng Hới': 'Quảng Bình',
	'Ðông Hà': 'Quảng Trị',
	'Dien Bien Phu': 'Ðiện Biên',
	'Dĩ An': 'Bình Dương',
	'Da Nang': 'Da Nang',
	'Ðà Lạt': 'Lâm Đồng',
	'Đại Lộc': 'Quảng Nam',
	'Củ Chi': 'Ho Chi Minh',
	'Cờ Đỏ': 'Can Tho',
	'Cổ Lóa': 'Hanoi',
	'Chí Linh': 'Hải Dương',
	'Châu Đốc': 'An Giang',
	'Cát Bà': 'Haiphong',
	'Cao Lãnh': 'Đồng Tháp',
	'Cao Bằng': 'Cao Bằng',
	'Cần Thơ': 'Can Tho',
	'Cần Giờ': 'Ho Chi Minh',
	'Cần Giuộc': 'Long An',
	'Cam Ranh': 'Khánh Hòa',
	'Cẩm Phả Mines': 'Quảng Ninh',
	'Cẩm Phả': 'Quảng Ninh',
	'Cà Mau': 'Cà Mau',
	'Cái Răng': 'Can Tho',
	'Buôn Ma Thuột': 'Đắk Lắk',
	'Buôn Hồ': 'Đắk Lắk',
	'Bình Thủy': 'Can Tho',
	'Bỉm Sơn': 'Thanh Hóa',
	'Biên Hòa': 'Đồng Nai',
	'Bến Tre': 'Bến Tre',
	'Bảo Lộc': 'Lâm Đồng',
	'Bắc Ninh': 'Bắc Ninh',
	'Bạc Liêu': 'Bạc Liêu',
	'Bắc Giang': 'Bắc Giang',
	'Bắc Kạn': 'Bắc Kạn',
	'Cung Kiệm': 'Bắc Ninh',
	'Bình Minh': 'Vĩnh Long',
	'Ea Drăng': 'Đắk Lắk',
	'Đinh Văn': 'Lâm Đồng',
	'Vĩnh Thạnh': 'Can Tho',
	'Thị Trấn Tân Thành': 'Đồng Tháp',
	'Quận Đức Thịnh': 'Đồng Tháp',
	'Quận Đức Thành': 'Đồng Tháp',
	'Huyện Lâm Hà': 'Lâm Đồng',
	'Phú Quốc': 'Kiến Giang',
	'thị xã Quảng Trị': 'Quảng Trị',
	'Quang Minh': 'Hanoi',
	'Thuận An': 'Bình Dương',
	'Port-Vila': 'Shefa',
	'Mata-Utu': 'Uvea',
	Apia: 'Tuamasaga',
	Zvečan: 'Mitrovica',
	Vushtrri: 'Mitrovica',
	Vitina: 'Gjilan',
	Ferizaj: 'Ferizaj',
	'Suva Reka': 'Prizren',
	Shtime: 'Ferizaj',
	Prizren: 'Prizren',
	Pristina: 'Pristina',
	Podujeva: 'Pristina',
	Peć: 'Pec',
	Orahovac: 'Gjakova',
	Llazicë: 'Prizren',
	Leposaviq: 'Mitrovica',
	Mitrovicë: 'Mitrovica',
	'Kosovo Polje': 'Pristina',
	Istok: 'Pec',
	Gjilan: 'Gjilan',
	Glogovac: 'Pristina',
	Dragash: 'Prizren',
	Deçan: 'Gjakova',
	Gjakovë: 'Gjakova',
	Zinjibār: 'Abyan Governorate',
	Zabīd: 'Al Hudaydah',
	Yarīm: 'Ibb Governorate',
	Taiz: 'Ta‘izz',
	Sayyān: 'Sanaa Governorate',
	Sanaa: 'Amanat Alasimah',
	Saḩar: 'Sanaa Governorate',
	"Sa'dah": 'Şa‘dah',
	"Ma'rib": 'Ma’rib',
	Laḩij: 'Laḩij',
	Ibb: 'Ibb Governorate',
	Ḩajjah: 'Ḩajjah',
	'Dhī as Sufāl': 'Ibb Governorate',
	Dhamār: 'Dhamār',
	'Bayt al Faqīh': 'Al Hudaydah',
	Bājil: 'Al Hudaydah',
	Ataq: 'Shabwah',
	'‘Amrān': 'Omran',
	Mukalla: 'Muhafazat Hadramaout',
	'Al Ḩudaydah': 'Al Hudaydah',
	'Al Ḩazm': 'Al Jawf',
	'Al Bayda': 'Al Bayda',
	Aden: 'Aden',
	Mamoudzou: 'Mamoudzou',
	Dzaoudzi: 'Dzaoudzi',
	Koungou: 'Koungou',
	Roodepoort: 'Gauteng',
	Zeerust: 'North-West',
	Wolmaransstad: 'North-West',
	'White River': 'Mpumalanga',
	Emalahleni: 'Mpumalanga',
	Westonaria: 'Gauteng',
	Wesselsbron: 'Orange Free State',
	Welkom: 'Orange Free State',
	Warrenton: 'Northern Cape',
	'Bela Bela': 'Limpopo',
	Vryheid: 'KwaZulu-Natal',
	Vryburg: 'North-West',
	Volksrust: 'Mpumalanga',
	Virginia: 'Orange Free State',
	Viljoenskroon: 'Orange Free State',
	Vereeniging: 'Gauteng',
	Vanderbijlpark: 'Gauteng',
	Upington: 'Northern Cape',
	Mthatha: 'Eastern Cape',
	eMkhomazi: 'KwaZulu-Natal',
	Ulundi: 'KwaZulu-Natal',
	Uitenhage: 'Eastern Cape',
	Tzaneen: 'Limpopo',
	Thohoyandou: 'Limpopo',
	Theunissen: 'Orange Free State',
	'Thaba Nchu': 'Orange Free State',
	Tembisa: 'Gauteng',
	Stutterheim: 'Eastern Cape',
	Stilfontein: 'North-West',
	KwaDukuza: 'KwaZulu-Natal',
	Standerton: 'Mpumalanga',
	Springs: 'Gauteng',
	Soweto: 'Gauteng',
	'Somerset East': 'Eastern Cape',
	Siyabuswa: 'Mpumalanga',
	Senekal: 'Orange Free State',
	Secunda: 'Mpumalanga',
	Scottburgh: 'KwaZulu-Natal',
	'Schweizer-Reneke': 'North-West',
	Sasolburg: 'Orange Free State',
	Rustenburg: 'North-West',
	Riversdale: 'Western Cape',
	'Richards Bay': 'KwaZulu-Natal',
	Reitz: 'Orange Free State',
	Randfontein: 'Gauteng',
	Randburg: 'Gauteng',
	Queenstown: 'Eastern Cape',
	Queensdale: 'Eastern Cape',
	Pretoria: 'Gauteng',
	Mokopane: 'Limpopo',
	Potchefstroom: 'North-West',
	Postmasburg: 'Northern Cape',
	'Port Shepstone': 'KwaZulu-Natal',
	'Port Elizabeth': 'Eastern Cape',
	'Port Alfred': 'Eastern Cape',
	'Plettenberg Bay': 'Western Cape',
	Pinetown: 'KwaZulu-Natal',
	'Piet Retief': 'Mpumalanga',
	Polokwane: 'Limpopo',
	Pietermaritzburg: 'KwaZulu-Natal',
	Phuthaditjhaba: 'Orange Free State',
	Phalaborwa: 'Limpopo',
	Parys: 'Orange Free State',
	Pampierstad: 'Northern Cape',
	Oudtshoorn: 'Western Cape',
	Modimolle: 'Limpopo',
	Nkowakowa: 'Limpopo',
	Nigel: 'Gauteng',
	Nelspruit: 'Mpumalanga',
	Mpumalanga: 'KwaZulu-Natal',
	Mpophomeni: 'KwaZulu-Natal',
	'Mossel Bay': 'Western Cape',
	Montagu: 'Western Cape',
	Mondlo: 'KwaZulu-Natal',
	Mmabatho: 'North-West',
	Mabopane: 'Gauteng',
	Lydenburg: 'Mpumalanga',
	'Louis Trichardt': 'Limpopo',
	Lichtenburg: 'North-West',
	Lebowakgomo: 'Limpopo',
	'Lady Frere': 'Eastern Cape',
	Ladybrand: 'Orange Free State',
	Kutloanong: 'Orange Free State',
	Kruisfontein: 'Eastern Cape',
	Krugersdorp: 'Gauteng',
	Kroonstad: 'Orange Free State',
	Kriel: 'Mpumalanga',
	Komatipoort: 'Mpumalanga',
	Kokstad: 'KwaZulu-Natal',
	Knysna: 'Western Cape',
	Klerksdorp: 'North-West',
	Kimberley: 'Northern Cape',
	Johannesburg: 'Gauteng',
	Howick: 'KwaZulu-Natal',
	Hennenman: 'Orange Free State',
	Hendrina: 'Mpumalanga',
	Heilbron: 'Orange Free State',
	Harrismith: 'Orange Free State',
	Grahamstown: 'Eastern Cape',
	'Graaff-Reinet': 'Eastern Cape',
	Giyani: 'Limpopo',
	George: 'Western Cape',
	'Ga-Rankuwa': 'North-West',
	'Fort Beaufort': 'Eastern Cape',
	Fochville: 'North-West',
	eSikhaleni: 'KwaZulu-Natal',
	Ermelo: 'Mpumalanga',
	Empangeni: 'KwaZulu-Natal',
	eMbalenhle: 'Mpumalanga',
	Edenvale: 'Gauteng',
	'East London': 'Eastern Cape',
	Durban: 'KwaZulu-Natal',
	Duiwelskloof: 'Limpopo',
	Driefontein: 'Mpumalanga',
	Delmas: 'Mpumalanga',
	'De Aar': 'Northern Cape',
	Cullinan: 'Gauteng',
	Cradock: 'Eastern Cape',
	Christiana: 'North-West',
	Carletonville: 'Gauteng',
	Bronkhorstspruit: 'Gauteng',
	Brits: 'North-West',
	Brakpan: 'Gauteng',
	Botshabelo: 'Orange Free State',
	Bothaville: 'Orange Free State',
	Boksburg: 'Gauteng',
	Bloemhof: 'North-West',
	Bloemfontein: 'Orange Free State',
	Bhisho: 'Eastern Cape',
	Bethal: 'Mpumalanga',
	Benoni: 'Gauteng',
	'Beaufort West': 'Western Cape',
	Ballito: 'KwaZulu-Natal',
	Balfour: 'Mpumalanga',
	Allanridge: 'Orange Free State',
	'Aliwal North': 'Eastern Cape',
	Alberton: 'Gauteng',
	Ekangala: 'Gauteng',
	Midrand: 'Gauteng',
	Centurion: 'Gauteng',
	Vredenburg: 'Western Cape',
	Stellenbosch: 'Western Cape',
	Saldanha: 'Western Cape',
	Robertson: 'Western Cape',
	Paarl: 'Western Cape',
	Malmesbury: 'Western Cape',
	Lansdowne: 'Western Cape',
	Kraaifontein: 'Western Cape',
	Hermanus: 'Western Cape',
	Grabouw: 'Western Cape',
	'Cape Town': 'Western Cape',
	Atlantis: 'Western Cape',
	Rondebosch: 'Western Cape',
	Midstream: 'Gauteng',
	Retreat: 'Western Cape',
	Diepsloot: 'Gauteng',
	Riverlea: 'Gauteng',
	Nchelenge: 'Luapula',
	Mbala: 'Northern',
	Kawambwa: 'Luapula',
	Siavonga: 'Southern',
	Sesheke: 'Western',
	Samfya: 'Luapula',
	Petauke: 'Eastern',
	Ndola: 'Copperbelt',
	Mumbwa: 'Central',
	Mufulira: 'Copperbelt',
	Mpika: 'Muchinga',
	Monze: 'Southern',
	Mongu: 'Western',
	Mazabuka: 'Southern',
	Mansa: 'Luapula',
	Lusaka: 'Lusaka',
	Luanshya: 'Copperbelt',
	Livingstone: 'Southern',
	Kitwe: 'Copperbelt',
	'Kapiri Mposhi': 'Central',
	Kansanshi: 'North-Western',
	Kalulushi: 'Copperbelt',
	Kafue: 'Lusaka',
	Kabwe: 'Central',
	Choma: 'Southern',
	Chipata: 'Eastern',
	Chingola: 'Copperbelt',
	Chililabombwe: 'Copperbelt',
	Zvishavane: 'Masvingo',
	'Victoria Falls': 'Matabeleland North',
	Shurugwi: 'Midlands',
	Ruwa: 'Mashonaland East',
	Rusape: 'Manicaland',
	Redcliff: 'Midlands',
	Mutare: 'Manicaland',
	Masvingo: 'Masvingo',
	Marondera: 'Mashonaland East',
	Kwekwe: 'Midlands',
	Karoi: 'Mashonaland West',
	Kariba: 'Mashonaland West',
	Hwange: 'Matabeleland North',
	Harare: 'Harare',
	Gweru: 'Midlands',
	Gwanda: 'Matabeleland South',
	Gokwe: 'Midlands',
	Chiredzi: 'Masvingo',
	Chipinge: 'Manicaland',
	Chinhoyi: 'Mashonaland West',
	Chegutu: 'Mashonaland West',
	Bulawayo: 'Bulawayo',
	Bindura: 'Mashonaland Central',
	Beitbridge: 'Matabeleland South',
	Epworth: 'Harare',
	Chitungwiza: 'Harare',
	'Bondi Beach': 'New South Wales',
	Bondi: 'New South Wales',
	'Bondi Junction': 'New South Wales',
	'Queens Park': 'New South Wales',
	'Double Bay': 'New South Wales',
}
export default cityToState
