import { Card, Badge } from 'antd'
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs';

const ChatDetailCard = ({  id}) => {
	const [propertyDetails, setPropertyDetails] = useState({}); // Store property details by message ID
console.log(propertyDetails , 'propertyDetails')
	useEffect(() => {
		const propertyInfo = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}request/requestById/${id}`,
					{
						method: 'GET',
						headers: { 'Content-Type': 'application/json;charset=utf-8' },
					}
				);
				const resp = await response.json();
				setPropertyDetails(resp)
				return resp;
			} catch (error) {
				console.error('Error fetching property info:', error);
				return null;
			}

		}
		propertyInfo()
	},[id])
	return (
		<Card className='rounded-xl relative shadow-lg  overflow-hidden border border-gray-200 w-1/2 max-w-sm sm:max-w-md md:max-w-lg  h-80'>
			{/* Status Badge */}
			<div className='absolute top-8 left-8'>
				<p className='py-2 px-4 bg-green-200 rounded-3xl'>{propertyDetails?.status}</p>
			</div>

			{/* Image */}
			<div className='w-full h-36 overflow-hidden'>
				<img src={propertyDetails?.image} alt='Room' className='w-full h-full object-cover' />
			</div>

			{/* Content */}
			<div className='p-4 h-36 overflow-hidden'>
				{/* Title */}
				<h6 className='font-semibold text-sm sm:text-lg leading-tight mb-2 truncate'>{propertyDetails?.title}</h6>

				{/* Date & Location */}
				<div className='flex items-center  text-xs sm:text-sm space-x-2'>
					<CalendarOutlined />
					<span>{`${dayjs(propertyDetails?.dateRange?.startDate).format('DD/MM/YYYY')} to ${dayjs(propertyDetails?.dateRange?.endDate).format('DD/MM/YYYY')}`
}</span>
				</div>
				<div className='flex items-center  text-xs sm:text-sm space-x-2 mt-1'>
					<EnvironmentOutlined />
					<span>{propertyDetails?.location}</span>
				</div>

				{/* Total & Price */}
				<div className='flex justify-between items-center mt-4'>
					<span className=' font-medium'>Total</span>
					<span className='text-sm sm:text-lg font-semibold '>{propertyDetails?.price}</span>
				</div>
			</div>
		</Card>
	)
}

export default ChatDetailCard
