import { Navigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useStateCallback from '../../hooks/useStateCallback'

const ValidatedRoute = (props) => {
	const { redirect } = props
	const [loading, setLoading] = useState(true)
	const [paymentMethodId, setPaymentMethodId] = useStateCallback(null)

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('user'))
		if (userData) {
			setPaymentMethodId(userData?.paymentMethodId, () => setLoading(false))
		} else {
			setLoading(false)
		}
	}, []) // eslint-disable-line






	const userDataNew = JSON.parse(localStorage.getItem('user'))
	// Assuming userDataCreatedAt is a valid date object or string representing a date
	const userCreatedAt = new Date(userDataNew?.createdAt);

	// Create a new date that is one month later than userDataCreatedAt
	const oneMonthLaterDate = new Date(userCreatedAt);
	oneMonthLaterDate.setMonth(oneMonthLaterDate.getMonth() + 1);

	// Compare the new date with the current date
	const currentDate = new Date();

//  Ignoring the payment method logic so its always redirect to desired page irrespective of free 1st month trial or not.
	if (oneMonthLaterDate > currentDate) {
		console.log("Free 1st month is valid, Date is ",oneMonthLaterDate);
		return loading ? <div>LOADING...</div> : paymentMethodId || 1==1 ? <>{props.children}</> : <Navigate to={redirect} replace />
	} else if (oneMonthLaterDate < currentDate) {
		console.log("Free 1st month Passed, Date is ",oneMonthLaterDate);
		return loading ? <div>LOADING...</div> : paymentMethodId || 1==1 ? <>{props.children}</> : <Navigate to={redirect} replace />
	} else {
		console.log("LAST ELSE CASE");
		return loading ? <div>LOADING...</div> : paymentMethodId || 1==1 ? <>{props.children}</> : <Navigate to={redirect} replace />
	}





	// return loading ? <div>LOADING...</div> : paymentMethodId ? <>{props.children}</> : <Navigate to={redirect} replace />
}

export default ValidatedRoute
