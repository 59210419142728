import { atom } from 'recoil'
export const userAtom = atom({ default: null, key: 'userAtom' })
export const listingsAtom = atom({ default: [], key: 'listingsAtom' })
export const chatsAtom = atom({ default: [], key: 'chatsAtom' })
export const requestsAtom = atom({
	default: {},
	key: 'requestsAtom',
})

export const chatCountAtom = atom({
	default: 0,
	key: 'chatCountAtom',
})
export const buttonEnabledState = atom({
	key: 'buttonEnabledState', // unique ID
	default: false, // initial state, e.g., button is disabled by default
})

export const selectedCityAtom = atom({
	key: 'selectedCity', // unique ID
	default: '', // default value
})

export const selectedCountryAtom = atom({
	key: 'selectedCountry', // unique ID
	default: '', // default value
})
export const selectedDateRangeAtom = atom({
	key: 'selectedDateRange', // unique ID for the atom
	default: null, // default value (no date range selected initially)
})

export const usersAtom = atom({ default: [], key: 'usersAtom' })
export const countriesAtom = atom({ default: [], key: 'countriesAtom' })
export const citiesAtom = atom({ default: [], key: 'citiesAtom' })
