import { useState, useEffect, useCallback } from 'react'

const useFetch = (url, method = 'GET', body = null, headers = {}) => {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [shouldRefetch, setShouldRefetch] = useState(false)

	// Memoized fetch function to avoid re-creation on each render
	const fetchData = useCallback(async () => {
		setLoading(true)
		setError(null)

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`, {
				method,
				headers: {
					'Content-Type': 'application/json',
					...headers,
				},
				body: body ? JSON.stringify(body) : null,
			})

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			const result = await response.json()
			setData(result)
		} catch (err) {
			setError(err.message)
		} finally {
			setLoading(false)
			setShouldRefetch(false) // Reset refetch trigger
		}
	}, [])

	// Refetch function, memoized so it doesn't re-create
	const refetch = useCallback(() => setShouldRefetch(true), [])

	useEffect(() => {
		fetchData()
	}, [fetchData, shouldRefetch])

	return { data, loading, error, refetch }
}

export default useFetch
