const countryStates = {
	Afghanistan: [
		'Nimroz',
		'Bamyan',
		'Takhar',
		'Herat',
		'Jowzjan',
		'Ghowr',
		'Sar-e Pol Province',
		'Samangan',
		'Kunduz',
		'Baghlan',
		'Kabul',
		'Faryab',
		'Laghman',
		'Balkh',
		'Helmand',
		'Khowst',
		'Kandahar',
		'Nangarhar',
		'Parwan',
		'Badghis',
		'Ghazni',
		'Paktia',
		'Badakhshan',
		'Farah',
		'Logar',
		'Kunar',
		'Panjshir',
	],
	'Aland Islands': ['Mariehamns stad'],
	Albania: [
		'Vlorë County',
		'Korçë County',
		'Kukës County',
		'Gjirokastër County',
		'Elbasan County',
		'Dibër County',
		'Tirana',
		'Shkodër County',
		'Fier County',
		'Lezhë County',
		'Durrës County',
		'Berat County',
	],
	Algeria: [
		'Boumerdes',
		'Biskra',
		'Algiers',
		'Relizane',
		'Touggourt',
		'Tlemcen',
		'Tizi Ouzou',
		'Tissemsilt',
		'Tipaza',
		'Tindouf',
		'Timimoun',
		'Béjaïa',
		'Tiaret',
		'Mila',
		'Tébessa',
		'Batna',
		'Tamanrasset',
		'Skikda',
		'Bouira',
		'Blida',
		'Souk Ahras',
		'Mascara',
		'Ouled Djellal',
		'Oran',
		'Sidi Bel Abbès',
		'El Mghair',
		'Chlef',
		"M'Sila",
		'Sétif',
		'Saida',
		'Ouargla',
		'El Oued',
		'Adrar',
		'Bordj Bou Arréridj',
		'Oum el Bouaghi',
		'Mostaganem',
		'Ghardaia',
		'Djelfa',
		'Medea',
		'Laghouat',
		'Khenchela',
		'Aïn Defla',
		'Jijel',
		'Guelma',
		'Aïn Témouchent',
		'Constantine',
		'El Tarf',
		'Annaba',
		'El Menia',
		'El Bayadh',
		'Béchar',
		'Naama',
		'In Salah',
	],
	'American Samoa': ['Eastern District'],
	Andorra: ['Escaldes-Engordany', 'Andorra la Vella'],
	Angola: [
		'Lunda Sul',
		'Luanda Norte',
		'Moxico',
		'Uíge',
		'Zaire',
		'Cuanza Norte',
		'Malanje',
		'Luanda',
		'Bengo',
		'Cabinda',
		'Kwanza Sul',
		'Namibe',
		'Cuando Cobango',
		'Huíla',
		'Huambo',
		'Benguela',
		'Bíe',
	],
	Anguilla: ['The Valley'],
	'Antigua and Barbuda': ['Saint John'],
	Argentina: [
		'Buenos Aires',
		'Misiones',
		'Buenos Aires F.D.',
		'Santa Fe',
		'Entre Rios',
		'Corrientes',
		'Chaco',
		'Formosa',
		'Neuquen',
		'Tucuman',
		'Rio Negro',
		'San Juan',
		'Cordoba',
		'Tierra del Fuego',
		'Chubut',
		'Santiago del Estero',
		'Salta',
		'La Pampa',
		'Jujuy',
		'Mendoza',
		'San Luis',
		'Catamarca',
		'Santa Cruz',
		'La Rioja',
	],
	Armenia: ['Syunik', 'Ararat', 'Yerevan', 'Armavir', 'Lori', 'Gegharkunik', 'Kotayk', 'Shirak', 'Aragatsotn'],
	Aruba: [''],
	Australia: [
		'Western Australia',
		'Queensland',
		'South Australia',
		'Northern Territory',
		'Victoria',
		'New South Wales',
		'Tasmania',
		'Australian Capital Territory',
	],
	Austria: ['Tyrol', 'Lower Austria', 'Vienna', 'Styria', 'Upper Austria', 'Carinthia', 'Salzburg', 'Vorarlberg'],
	Azerbaijan: [
		'Xankǝndi',
		'Saatlı',
		'Shusha',
		'Salyan',
		'Neftçala',
		'Nakhichevan',
		'Lerik',
		'Lənkəran',
		'İmişli',
		'Füzuli',
		'Jalilabad',
		'Bilǝsuvar',
		'Beyləqan',
		'Astara',
		'Shirvan',
		'Ağdam',
		'Zaqatala',
		'Baki',
		'Yevlax City',
		'Goygol Rayon',
		'Xaçmaz',
		'Ucar',
		'Tovuz',
		'Tǝrtǝr',
		'Sumqayit',
		'Şǝmkir',
		'Şamaxı',
		'Shaki City',
		'Sabirabad',
		'Qusar',
		'Quba',
		'Hacıqabul',
		'Qazax',
		'Mingǝcevir',
		'Kürdǝmir',
		'Abşeron',
		'İsmayıllı',
		'Göyçay',
		'Gǝncǝ',
		'Shabran',
		'Barda',
		'Ağsu',
		'Ağdaş',
		'Ağcabǝdi',
	],
	Bahamas: ['New Providence', 'Freeport'],
	Bahrain: ['Manama', 'Southern Governorate', 'Northern', 'Muharraq'],
	Bangladesh: ['Rangpur Division', 'Chittagong', 'Dhaka', 'Sylhet', 'Mymensingh Division', 'Rajshahi Division', 'Khulna', 'Barisāl'],
	Barbados: ['Saint Michael'],
	Belarus: ['Minsk', 'Gomel Oblast', 'Vitebsk', 'Grodnenskaya', 'Brest', 'Mogilev', 'Minsk City'],
	Belgium: ['Flanders', 'Brussels Capital', 'Wallonia'],
	Belize: ['Belize', 'Cayo', 'Orange Walk District'],
	Benin: ['Borgou', 'Atakora', 'Collines', 'Plateau', 'Ouémé', 'Atlantique', 'Alibori', 'Mono', 'Kouffo', 'Donga', 'Zou', 'Littoral'],
	Bermuda: ['Hamilton city'],
	Bhutan: ['Thimphu District', 'Punakha', 'Chukha', 'Tsirang District'],
	Bolivia: [
		'Tarija Department',
		'Santa Cruz Department',
		'Cochabamba',
		'Potosí Department',
		'La Paz Department',
		'Beni Department',
		'Chuquisaca Department',
		'Oruro',
		'Pando',
	],
	'Bonaire, Saint Eustatius and Saba ': ['Bonaire'],
	'Bosnia and Herzegovina': ['Federation of B&H', 'Srpska', 'Brčko'],
	Botswana: ['Central', 'Kweneng', 'Selibe Phikwe', 'South-East', 'Ngwaketsi', 'Kgatleng', 'North-West', 'Lobatse', 'Gaborone', 'City of Francistown'],
	Brazil: [
		'Maranhão',
		'Pernambuco',
		'Pará',
		'Paraíba',
		'Ceará',
		'Alagoas',
		'Piauí',
		'Rio Grande do Norte',
		'Amapá',
		'Bahia',
		'Amazonas',
		'Tocantins',
		'Santa Catarina',
		'São Paulo',
		'Rio de Janeiro',
		'Espírito Santo',
		'Minas Gerais',
		'Rio Grande do Sul',
		'Paraná',
		'Mato Grosso',
		'Goiás',
		'Mato Grosso do Sul',
		'Sergipe',
		'Federal District',
		'Acre',
		'Rondônia',
		'Roraima',
	],
	'British Virgin Islands': [''],
	Brunei: ['Tutong', 'Belait', 'Brunei-Muara District'],
	Bulgaria: [
		'Yambol',
		'Vratsa',
		'Vidin',
		'Pazardzhik',
		'Veliko Tarnovo',
		'Varna',
		'Targovishte',
		'Lovech',
		'Dobrich',
		'Haskovo',
		'Stara Zagora',
		'Kyustendil',
		'Sofia-Capital',
		'Smolyan',
		'Sliven',
		'Silistra',
		'Shumen',
		'Gabrovo',
		'Blagoevgrad',
		'Sofia',
		'Ruse',
		'Razgrad',
		'Plovdiv',
		'Pleven',
		'Pernik',
		'Montana',
		'Kardzhali',
		'Burgas',
	],
	'Burkina Faso': [
		'Plateau-Central',
		'Nord',
		'Boucle du Mouhoun',
		'Centre-Est',
		'Centre-Ouest',
		'Centre-Sud',
		'Centre',
		'Centre-Nord',
		'Hauts-Bassins',
		'Est',
		'Sud-Ouest',
		'Sahel',
		'Cascades',
	],
	Burundi: ['Makamba', 'Bururi', 'Bujumbura Mairie', 'Muramvya', 'Gitega', 'Ruyigi', 'Ngozi', 'Kayanza', 'Muyinga', 'Rutana'],
	'Cabo Verde': ['Sal', 'Praia', 'São Vicente', 'Santa Catarina do Fogo'],
	Cambodia: [
		'Phnom Penh',
		'Kandal',
		'Takeo',
		'Svay Rieng',
		'Tboung Khmum',
		'Stung Treng',
		'Banteay Meanchey',
		'Siem Reap',
		'Prey Veng',
		'Pursat',
		'Ŏtâr Méanchey',
		'Preah Vihear',
		'Pailin',
		'Ratanakiri',
		'Koh Kong',
		'Kratie',
		'Kampot',
		'Kampong Thom',
		'Kampong Speu',
		'Preah Sihanouk',
		'Kampong Chhnang',
		'Kampong Cham',
		'Battambang',
	],
	Cameroon: ['Centre', 'Far North', 'North-West', 'West', 'South-West', 'Adamaoua', 'North', 'South', 'Littoral', 'East'],
	Canada: [
		'British Columbia',
		'Ontario',
		'Alberta',
		'Quebec',
		'Manitoba',
		'Prince Edward Island',
		'Newfoundland and Labrador',
		'Nova Scotia',
		'New Brunswick',
		'Saskatchewan',
		'Yukon',
		'Northwest Territories',
	],
	'Cayman Islands': ['West Bay', 'George Town'],
	'Central African Republic': [
		'Basse-Kotto',
		'Ouaka',
		'Haute-Kotto',
		'Mbomou',
		'Kémo',
		'Ouham-Pendé',
		'Sangha-Mbaéré',
		'Lobaye',
		'Nana-Grébizi',
		"Ombella-M'Poko",
		'Mambéré-Kadéï',
		'Nana-Mambéré',
		'Ouham',
		'Bangui',
	],
	Chad: [
		'Ennedi-Ouest',
		'Salamat',
		'Ouadaï',
		'Moyen-Chari',
		'Mayo-Kebbi Ouest',
		'Batha',
		'N’Djaména',
		'Barh el Gazel',
		'Logone Occidental',
		'Guéra',
		'Hadjer-Lamis',
		'Kanem',
		'Tandjilé',
		'Mandoul',
		'Chari-Baguirmi',
		'Logone Oriental',
		'Mayo-Kebbi Est',
	],
	Chile: [
		'Valparaíso',
		'Araucanía',
		'Atacama',
		'Los Ríos Region',
		'Biobío',
		'Antofagasta',
		'Maule Region',
		'Santiago Metropolitan',
		"O'Higgins Region",
		'Ñuble',
		'Region of Magallanes',
		'Los Lagos Region',
		'Aysén',
		'Tarapacá',
		'Coquimbo Region',
		'Arica y Parinacota',
	],
	China: [
		'Yunnan',
		'Xinjiang',
		'Tibet',
		'Gansu',
		'Jiangsu',
		'Hainan',
		'Guizhou',
		'Shandong',
		'Shaanxi',
		'Sichuan',
		'Chongqing',
		'Hunan',
		'Henan',
		'Shanghai',
		'Zhejiang',
		'Liaoning',
		'Hubei',
		'Hebei',
		'Guangdong',
		'Fujian',
		'Shanxi',
		'Guangxi',
		'Jiangxi',
		'Anhui',
		'Ningxia Hui Autonomous Region',
		'Tianjin',
		'Qinghai',
		'Inner Mongolia',
		'Beijing',
		'Jilin',
		'Heilongjiang',
	],
	'Christmas Island': [''],
	'Cocos Islands': [''],
	Colombia: [
		'Cundinamarca',
		'Valle del Cauca',
		'Antioquia',
		'Casanare Department',
		'Meta',
		'Norte de Santander Department',
		'La Guajira Department',
		'Caldas Department',
		'Santander',
		'Cesar',
		'Bolívar',
		'Nariño',
		'Boyacá',
		'Sucre',
		'Cauca',
		'Córdoba',
		'Huila',
		'Departamento de Arauca',
		'Chocó',
		'Atlántico',
		'Magdalena',
		'Risaralda',
		'San Andres y Providencia',
		'Tolima',
		'Quindío',
		'Caquetá',
		'Putumayo',
		'Vichada',
		'Vaupés',
		'Amazonas',
		'Bogota D.C.',
		'Guaviare',
	],
	Comoros: ['Anjouan', 'Grande Comore'],
	'Cook Islands': ['Rarotonga'],
	'Costa Rica': [
		'Cartago Province',
		'San José',
		'Limón Province',
		'Heredia Province',
		'Alajuela Province',
		'Puntarenas Province',
		'Guanacaste Province',
	],
	Croatia: [
		'County of Zagreb',
		'City of Zagreb',
		'County of Zadar',
		'Vukovar-Sirmium',
		'County of Varaždin',
		'Split-Dalmatia',
		'Brod-Posavina',
		'County of Požega-Slavonia',
		'County of Sisak-Moslavina',
		'Šibenik-Knin',
		'County of Primorje-Gorski Kotar',
		'Istria',
		'County of Osijek-Baranja',
		'County of Koprivnica-Križevci',
		'Dubrovnik-Neretva',
		'County of Međimurje',
		'Bjelovar-Bilogora',
		'Karlovac',
	],
	Cuba: [
		'Granma Province',
		'Sancti Spíritus Province',
		'Pinar del Río',
		'Camagüey',
		'Ciego de Ávila Province',
		'Matanzas Province',
		'Holguín Province',
		'Villa Clara Province',
		'Havana',
		'Santiago de Cuba',
		'Mayabeque',
		'Artemisa',
		'Cienfuegos Province',
		'Guantánamo Province',
		'Las Tunas',
		'Isla de la Juventud',
	],
	Curacao: [''],
	Cyprus: ['Ammochostos', 'Nicosia', 'Pafos', 'Limassol', 'Larnaka', 'Keryneia'],
	Czechia: [
		'Královéhradecký kraj',
		'South Moravian',
		'Zlín',
		'Vysočina',
		'Ústecký kraj',
		'Pardubický',
		'Moravskoslezský',
		'Jihočeský kraj',
		'Olomoucký',
		'Karlovarský kraj',
		'Central Bohemia',
		'Prague',
		'Plzeň Region',
		'Liberecký kraj',
	],
	'Democratic Republic of the Congo': [
		'Tshopo',
		'Haut-Uele',
		'South Kivu',
		'Kasai',
		'Nord Kivu',
		'Lomami',
		'Kasaï-Oriental',
		'Sankuru',
		'Mongala',
		'Tanganyika',
		'Maniema',
		'Kasai-Central',
		'Haut-Lomami',
		'Nord-Ubangi',
		'Bas-Uele',
		'Ituri',
		'Tshuapa',
		'Haut-Katanga',
		'Lualaba',
		'Bas-Congo',
		'Mai-Ndombe',
		'Équateur',
		'Kwilu',
		'Sud-Ubangi',
		'Kinshasa',
		'Kwango',
	],
	Denmark: ['Zealand', 'Central Jutland', 'South Denmark', 'Capital Region', 'North Denmark'],
	Djibouti: ['Tadjourah', 'Obock', 'Djibouti', 'Ali Sabieh'],
	Dominica: ['Saint George'],
	'Dominican Republic': [
		'Nacional',
		'Santiago',
		'San Cristóbal',
		'El Seíbo',
		'Barahona',
		'San Pedro de Macorís',
		'San Juan',
		'San José de Ocoa',
		'Duarte',
		'Monte Cristi',
		'Puerto Plata',
		'La Altagracia',
		'Hermanas Mirabal',
		'Santiago Rodríguez',
		'Monte Plata',
		'Baoruco',
		'María Trinidad Sánchez',
		'Espaillat',
		'Valverde',
		'La Romana',
		'La Vega',
		'Hato Mayor',
		'Dajabón',
		'Sánchez Ramírez',
		'Monseñor Nouel',
		'Santo Domingo',
		'Peravia',
		'Azua',
	],
	Ecuador: [
		'Zamora-Chinchipe',
		'Guayas',
		'Los Ríos',
		'Carchi',
		'Napo',
		'Manabí',
		'Santo Domingo de los Tsáchilas',
		'El Oro',
		'Santa Elena',
		'Esmeraldas',
		'Chimborazo',
		'Pichincha',
		'Pastaza',
		'Cotopaxi',
		'Orellana',
		'Tungurahua',
		'Imbabura',
		'Morona-Santiago',
		'Loja',
		'Cañar',
		'Sucumbios',
		'Bolívar',
		'Azuay',
	],
	Egypt: [
		'Gharbia',
		'Qalyubia',
		'Sohag',
		'Faiyum',
		'Dakahlia',
		'Monufia',
		'Beni Suweif',
		'Kafr el-Sheikh',
		'Matruh',
		'Minya',
		'Beheira',
		'Red Sea',
		'Qena',
		'Sharqia',
		'Asyut',
		'Giza',
		'Cairo',
		'Aswan',
		'Damietta',
		'Port Said',
		'Suez',
		'Luxor',
		'New Valley',
		'Ismailia',
		'Alexandria',
		'North Sinai',
	],
	'El Salvador': [
		'La Paz',
		'Usulután',
		'San Salvador',
		'Sonsonate',
		'Cabañas',
		'San Vicente',
		'Santa Ana',
		'San Miguel',
		'Cuscatlán',
		'Morazán',
		'La Libertad',
		'La Unión',
		'Chalatenango',
		'Ahuachapán',
	],
	'Equatorial Guinea': ['Kié-Ntem', 'Bioko Norte', 'Litoral'],
	Eritrea: ['Northern Red Sea', 'Anseba', 'Gash-Barka', 'Maekel', 'Southern Red Sea', 'Debub'],
	Estonia: ['Viljandimaa', 'Tartu', 'Harjumaa', 'Pärnumaa', 'Ida-Virumaa'],
	Eswatini: ['Hhohho', 'Manzini'],
	Ethiopia: ['Somali', 'Oromiya', 'SNNPR', 'Amhara', 'Tigray', 'Harari', 'Gambela', 'Āfar', 'Dire Dawa', 'Bīnshangul Gumuz', 'Addis Ababa'],
	'Falkland Islands': [''],
	'Faroe Islands': ['Streymoy'],
	Fiji: ['Central', 'Western', 'Northern'],
	Finland: [
		'Pirkanmaa',
		'Uusimaa',
		'North Savo',
		'Ostrobothnia',
		'Southwest Finland',
		'Lapland',
		'South Ostrobothnia',
		'South Savo',
		'Kanta-Häme',
		'Satakunta',
		'North Ostrobothnia',
		'Central Finland',
		'South Karelia',
		'Paijat-Hame',
		'Kymenlaakso',
		'Central Ostrobothnia',
		'Kainuu',
		'North Karelia',
	],
	France: [
		'Île-de-France',
		'Grand Est',
		'Hauts-de-France',
		'Auvergne-Rhône-Alpes',
		"Provence-Alpes-Côte d'Azur",
		'Brittany',
		'Nouvelle-Aquitaine',
		'Centre',
		'Bourgogne-Franche-Comté',
		'Pays de la Loire',
		'Normandy',
		'Occitanie',
		'Corsica',
	],
	'French Guiana': ['Guyane'],
	'French Polynesia': ['Îles du Vent'],
	'French Southern Territories': ['Kerguelen'],
	Gabon: ['Nyanga', 'Ogooué-Maritime', 'Woleu-Ntem', 'Ngouni', 'Haut-Ogooué', 'Estuaire', 'Moyen-Ogooué', 'Ogooué-Lolo'],
	Gambia: ['Western', 'Banjul', 'North Bank'],
	Georgia: ['Samegrelo and Zemo Svaneti', 'Imereti', 'Shida Kartli', 'Abkhazia', 'Kakheti', "T'bilisi", 'Kvemo Kartli', 'Achara', 'Samtskhe-Javakheti'],
	Germany: [
		'Saxony',
		'Rheinland-Pfalz',
		'North Rhine-Westphalia',
		'Brandenburg',
		'Bavaria',
		'Saxony-Anhalt',
		'Berlin',
		'Lower Saxony',
		'Hesse',
		'Mecklenburg-Vorpommern',
		'Hamburg',
		'Baden-Wurttemberg',
		'Thuringia',
		'Schleswig-Holstein',
		'Saarland',
		'Bremen',
	],
	Ghana: [
		'Northern',
		'Central',
		'Bono',
		'Upper West',
		'Greater Accra',
		'Bono East',
		'Western',
		'Ashanti',
		'Eastern',
		'Savannah',
		'Upper East',
		'Volta',
		'Ahafo',
		'Western North',
	],
	Gibraltar: [''],
	Greece: [
		'Attica',
		'Thessaly',
		'Peloponnese',
		'Central Greece',
		'Crete',
		'Epirus',
		'West Greece',
		'North Aegean',
		'South Aegean',
		'Central Macedonia',
		'East Macedonia and Thrace',
		'West Macedonia',
		'Ionian Islands',
	],
	Greenland: ['Sermersooq'],
	Grenada: ['Saint George'],
	Guadeloupe: ['Guadeloupe'],
	Guam: ['Tamuning', 'Yigo', 'Hagatna', 'Dededo', 'Mangilao'],
	Guatemala: [
		'Chimaltenango',
		'Zacapa',
		'Guatemala',
		'Totonicapán',
		'Sacatepéquez',
		'Huehuetenango',
		'Sololá',
		'Escuintla',
		'Quiché',
		'Suchitepeque',
		'Retalhuleu',
		'San Marcos',
		'Alta Verapaz',
		'Jalapa',
		'Petén',
		'El Progreso',
		'Quetzaltenango',
		'Baja Verapaz',
		'Izabal Department',
		'Jutiapa',
		'Santa Rosa Department',
		'Chiquimula',
	],
	Guernsey: ['St Peter Port'],
	Guinea: ['Labe', 'Kankan', 'Kindia', 'Mamou', 'Nzerekore', 'Faranah', 'Boke', 'Conakry'],
	'Guinea-Bissau': ['Bissau', 'Bafatá'],
	Guyana: ['East Berbice-Corentyne', 'Upper Demerara-Berbice', 'Demerara-Mahaica'],
	Haiti: ['Artibonite', 'Ouest', 'Nord', 'Nord-Ouest', 'Nippes', 'GrandʼAnse', 'Sud-Est', 'Centre', 'Sud'],
	Honduras: [
		'Cortés Department',
		'Yoro Department',
		'Colón Department',
		'Atlántida Department',
		'Francisco Morazán Department',
		'Comayagua Department',
		'Copán Department',
		'Santa Bárbara Department',
		'Valle Department',
		'La Paz Department',
		'Olancho Department',
		'El Paraíso Department',
		'Choluteca Department',
	],
	'Hong Kong': [
		'Tsuen Wan',
		'Yuen Long',
		'Tuen Mun',
		'Kwai Tsing',
		'Sai Kung',
		'Tai Po',
		'Sha Tin',
		'Southern',
		'Kowloon City',
		'Central and Western',
		'North',
		'Islands',
	],
	Hungary: [
		'Jász-Nagykun-Szolnok',
		'Borsod-Abaúj-Zemplén',
		'Csongrád',
		'Bekes County',
		'Hajdú-Bihar',
		'Szabolcs-Szatmár-Bereg',
		'Heves',
		'Pest',
		'Budapest',
		'Zala',
		'Veszprém',
		'Komárom-Esztergom',
		'Vas',
		'Tolna',
		'Fejér',
		'Győr-Moson-Sopron',
		'Somogy',
		'Nógrád',
		'Baranya',
		'Bács-Kiskun',
	],
	Iceland: ['Northeast', 'Capital Region', 'Southern Peninsula'],
	India: [
		'Jammu and Kashmir',
		'Tamil Nadu',
		'Nagaland',
		'Uttar Pradesh',
		'Telangana',
		'Maharashtra',
		'Karnataka',
		'Andhra Pradesh',
		'Haryana',
		'Rajasthan',
		'Bihar',
		'Madhya Pradesh',
		'Gujarat',
		'Kerala',
		'Goa',
		'Uttarakhand',
		'Himachal Pradesh',
		'Chhattisgarh',
		'Assam',
		'Tripura',
		'Meghalaya',
		'West Bengal',
		'Odisha',
		'Manipur',
		'Punjab',
		'Jharkhand',
		'Dadra and Nagar Haveli and Daman and Diu',
		'Mizoram',
		'Andaman and Nicobar',
		'Puducherry',
		'Arunachal Pradesh',
		'Ladakh',
		'Delhi',
		'Sikkim',
		'Chandigarh',
	],
	Indonesia: [
		'North Sumatra',
		'Aceh',
		'Yogyakarta',
		'Central Java',
		'East Java',
		'West Java',
		'South Sulawesi',
		'Southwest Papua',
		'East Nusa Tenggara',
		'Bali',
		'Maluku',
		'North Sulawesi',
		'North Maluku',
		'Lampung',
		'North Kalimantan',
		'Riau Islands',
		'Bangka–Belitung Islands',
		'South Sumatra',
		'South Kalimantan',
		'Banten',
		'West Nusa Tenggara',
		'West Kalimantan',
		'Jambi',
		'West Sumatra',
		'Central Kalimantan',
		'East Kalimantan',
		'West Papua',
		'Southeast Sulawesi',
		'Central Sulawesi',
		'West Sulawesi',
		'Riau',
		'Central Papua',
		'Jakarta',
		'Gorontalo',
		'Papua',
		'Bengkulu',
		'South Papua',
	],
	Iran: [
		'Qazvīn',
		'Hamadān',
		'Kermānshāh',
		'Lorestan Province',
		'Semnan',
		'Tehran',
		'Zanjan',
		'Gīlān',
		'Kohgiluyeh and Boyer-Ahmad',
		'Golestan',
		'Kerman',
		'Yazd',
		'Razavi Khorasan',
		'Māzandarān',
		'West Azerbaijan',
		'East Azerbaijan',
		'Fars',
		'Khuzestan',
		'North Khorasan',
		'Chaharmahal and Bakhtiari',
		'Isfahan',
		'Markazi',
		'Kordestān',
		'Qom',
		'Hormozgan',
		'South Khorasan Province',
		'Ardabīl',
		'Alborz Province',
		'Ilam Province',
		'Bushehr',
		'Sistan and Baluchestan',
	],
	Iraq: [
		'Duhok',
		'Basra',
		'Salah ad Din',
		'Nineveh',
		'Bābil',
		'Arbīl',
		'Diyālá',
		'Kirkuk',
		'Karbalāʼ',
		'Al Anbar',
		'Sulaymaniyah',
		'Baghdad',
		'Wāsiţ',
		'Al Muthanná',
		'Al Qādisīyah',
		'Dhi Qar',
		'An Najaf',
		'Maysan',
	],
	Ireland: ['Leinster', 'Munster', 'Connacht', 'Ulster'],
	'Isle of Man': ['Douglas'],
	Israel: ['Jerusalem', 'Central District', 'Haifa', 'Northern District', 'Tel Aviv', 'Southern District', 'Judea and Samaria Area'],
	Italy: [
		'Sicily',
		'Calabria',
		'Sardinia',
		'Apulia',
		'Emilia-Romagna',
		'Campania',
		'Lombardy',
		'Veneto',
		'Lazio',
		'Tuscany',
		'Piedmont',
		'Liguria',
		'Abruzzo',
		'Friuli Venezia Giulia',
		'Trentino-Alto Adige',
		'The Marches',
		'Umbria',
		'Molise',
		'Basilicate',
		'Aosta Valley',
	],
	'Ivory Coast': [
		'Sassandra-Marahoué',
		'Lacs',
		'Woroba',
		'Savanes',
		'Lagunes',
		'Zanzan',
		'Bas-Sassandra',
		'Vallée du Bandama',
		'Gôh-Djiboua',
		'Denguélé',
		'Montagnes',
		'Comoé',
		'Abidjan',
	],
	Jamaica: ['Saint Catherine', 'Westmoreland', 'St. Andrew', 'St. James', 'Clarendon', 'Manchester', 'Kingston'],
	Japan: [
		'Wakayama',
		'Kanagawa',
		'Hyōgo',
		'Gifu',
		'Fukuoka',
		'Ibaraki',
		'Saitama',
		'Gunma',
		'Niigata',
		'Tottori',
		'Okinawa',
		'Mie',
		'Shiga',
		'Kyoto',
		'Kumamoto',
		'Toyama',
		'Shimane',
		'Ōsaka',
		'Yamaguchi',
		'Shizuoka',
		'Tochigi',
		'Tokushima',
		'Kagawa',
		'Oita',
		'Saga',
		'Tokyo',
		'Yamanashi',
		'Nagano',
		'Okayama',
		'Aichi',
		'Yamagata',
		'Ishikawa',
		'Fukui',
		'Miyazaki',
		'Nagasaki',
		'Nara',
		'Akita',
		'Chiba',
		'Kagoshima',
		'Hiroshima',
		'Kochi',
		'Ehime',
		'Fukushima',
		'Miyagi',
		'Iwate',
		'Hokkaido',
		'Aomori',
	],
	Jersey: ['St Helier'],
	Jordan: ['Amman', 'Madaba', 'Ma’an', 'Irbid', 'Jerash', 'Karak', 'Zarqa', 'Tafielah', 'Balqa', 'Ajlun', 'Mafraq', 'Aqaba'],
	Kazakhstan: [
		'Mangghystaū',
		'Aqtöbe',
		'Atyraū',
		'Batys Qazaqstan',
		'Karaganda',
		'East Kazakhstan',
		'Qyzylorda',
		'Ulytau Region',
		'Qostanay',
		'Zhambyl',
		'South Kazakhstan',
		'Jetisu Region',
		'Almaty',
		'Shymkent',
		'Aqmola',
		'Abai Region',
		'Almaty Oblysy',
		'North Kazakhstan',
		'Pavlodar Region',
		'Baikonur',
		'Astana',
	],
	Kenya: [
		'Bungoma',
		'Wajir',
		'Taita Taveta',
		'Nairobi Area',
		'Kiambu',
		'Nakuru',
		'Kisii',
		'Nyeri',
		'Laikipia',
		'Narok',
		'Kakamega',
		'Kisumu',
		'Marsabit',
		'Mombasa',
		'Migori',
		'Meru',
		'Vihiga',
		'Samburu',
		'Mandera',
		'Kilifi',
		'Makueni',
		'Machakos',
		'Busia',
		'Turkana',
		'Lamu',
		'Kitui',
		'Trans Nzoia',
		'Kirinyaga',
		'Kericho',
		"Murang'A",
		'West Pokot',
		'Kajiado',
		'Baringo',
		'Elegeyo-Marakwet',
		'Isiolo',
		'Homa Bay',
		'Garissa',
		'Embu',
		'Uasin Gishu',
		'Siaya',
		'Nyandarua',
	],
	Kiribati: ['Gilbert Islands'],
	Kosovo: ['Mitrovica', 'Gjilan', 'Ferizaj', 'Prizren', 'Pristina', 'Pec', 'Gjakova'],
	Kuwait: ['Al Farwaniyah', 'Hawalli', 'Al Aḩmadī', 'Al Asimah', 'Al Jahrāʼ', 'Mubārak al Kabīr'],
	Kyrgyzstan: ['Batken', 'Issyk-Kul', 'Chüy', 'Jalal-Abad', 'Talas', 'Osh', 'Naryn', 'Bishkek'],
	Laos: [
		'Vientiane Prefecture',
		'Houaphan',
		'Savannahkhét',
		'Champasak',
		'Oudômxai',
		'Vientiane',
		'Xiangkhoang',
		'Bolikhamsai',
		'Khammouan',
		'Louangphabang',
		'Bokeo',
	],
	Latvia: [
		'Valmiera',
		'Ventspils',
		'Tukums Municipality',
		'Salaspils Municipality',
		'Riga',
		'Rēzekne',
		'Ogre',
		'Liepāja',
		'Jūrmala',
		'Jelgava',
		'Jēkabpils Municipality',
		'Daugavpils',
		'Alūksne Municipality',
		'Cēsis Municipality',
	],
	Lebanon: ['Béqaa', 'Liban-Nord', 'South Governorate', 'Beyrouth', 'Mont-Liban', 'Nabatîyé', 'Baalbek-Hermel'],
	Lesotho: ['Quthing', 'Qachaʼs Nek', 'Mohaleʼs Hoek', 'Maseru', 'Leribe', 'Mafeteng', 'Butha-Buthe', 'Thaba-Tseka'],
	Liberia: ['Grand Gedeh', 'Nimba', 'Lofa', 'Montserrado', 'Margibi', 'Maryland', 'Sinoe', 'Bong', 'Grand Bassa'],
	Libya: [
		'Al Buţnān',
		'Banghāzī',
		'Darnah',
		'Al Wāḩāt',
		'Al Kufrah',
		'Al Marj',
		'Al Jabal al Akhḑar',
		'An Nuqāţ al Khams',
		'Mişrātah',
		'Jabal al Gharbi',
		'Al Jufrah',
		'Al Marqab',
		'Tripoli',
		'Surt',
		'Az Zāwiyah',
		'Sabhā',
		'Nālūt',
		'Murzuq',
		'Ghāt',
		'Ash Shāţiʼ',
		'Wādī al Ḩayāt',
	],
	Liechtenstein: ['Vaduz'],
	Lithuania: ['Utena', 'Vilnius', 'Telsiai', 'Tauragė County', 'Siauliai', 'Panevėžys', 'Klaipėda County', 'Marijampolė County', 'Kaunas', 'Alytus'],
	Luxembourg: ['Luxembourg', 'Esch-sur-Alzette'],
	Macao: [''],
	Madagascar: [
		'Atsinanana',
		'Atsimo-Atsinanana',
		'Analanjirofo',
		'Bongolava',
		'Androy',
		'Betsiboka',
		'Atsimo-Andrefana',
		'Anosy',
		'Itasy',
		'Vakinankaratra',
		'Boeny',
		'Sava',
		'Vatovavy Region',
		'Analamanga',
		'Menabe',
		'Alaotra Mangoro',
		'Fitovinany Region',
		'Melaky',
		'Upper Matsiatra',
		'Ihorombe',
		"Amoron'i Mania",
		'Sofia',
		'Diana',
	],
	Malawi: ['Northern Region', 'Southern Region', 'Central Region'],
	Malaysia: [
		'Kedah',
		'Pahang',
		'Johor',
		'Selangor',
		'Terengganu',
		'Sabah',
		'Labuan',
		'Perak',
		'Kelantan',
		'Negeri Sembilan',
		'Melaka',
		'Penang',
		'Kuala Lumpur',
		'Sarawak',
		'Perlis',
		'Putrajaya',
	],
	Maldives: ['Kaafu Atoll'],
	Mali: ['Sikasso', 'Tombouctou', 'Ségou', 'Kayes', 'Mopti', 'Koulikoro', 'Gao', 'Bamako'],
	Malta: ['Ħaż-Żabbar', 'Valletta', 'Tas-Sliema', 'Saint Paul’s Bay', 'Qormi', 'Il-Mosta', 'Birkirkara'],
	'Marshall Islands': ['Majuro Atoll'],
	Martinique: ['Martinique'],
	Mauritania: [
		'Tiris Zemmour',
		'Trarza',
		'Guidimaka',
		'',
		'Dakhlet Nouadhibou',
		'Hodh Ech Chargi',
		'Assaba',
		'Gorgol',
		'Brakna',
		'Adrar',
		'Nouakchott Ouest',
		'Nouakchott Nord',
	],
	Mauritius: ['Plaines Wilhems', 'Pamplemousses', 'Moka', 'Port Louis', 'Grand Port', 'Rivière du Rempart', 'Flacq', 'Black River'],
	Mayotte: ['Mamoudzou', 'Dzaoudzi', 'Koungou'],
	Mexico: [
		'Tamaulipas',
		'San Luis Potosí',
		'México',
		'Guerrero',
		'Hidalgo',
		'Puebla',
		'Morelos',
		'Chiapas',
		'Mexico City',
		'Veracruz',
		'Tabasco',
		'Yucatán',
		'Quintana Roo',
		'Querétaro',
		'Oaxaca',
		'Tlaxcala',
		'Nuevo León',
		'Campeche',
		'Sonora',
		'Sinaloa',
		'Jalisco',
		'Baja California',
		'Michoacán',
		'Zacatecas',
		'Guanajuato',
		'Coahuila',
		'Colima',
		'Nayarit',
		'Durango',
		'Baja California Sur',
		'Aguascalientes',
		'Chihuahua',
	],
	Micronesia: ['Pohnpei'],
	Moldova: [
		'Raionul Edineţ',
		'Ungheni',
		'Transnistria',
		'Strășeni',
		'Sîngerei',
		'Raionul Soroca',
		'Orhei',
		'Hînceşti',
		'Chișinău Municipality',
		'Căuşeni',
		'Floreşti',
		'Drochia',
		'Găgăuzia',
		'Cahul',
		'Bender Municipality',
		'Bălţi',
	],
	Monaco: ['Municipality of Monaco'],
	Mongolia: [
		'Dzabkhan',
		'Uvs Province',
		'Bayan-Ölgiy',
		'Hovd',
		'Govi-Altai Province',
		'Ulaanbaatar',
		'Arkhangai Province',
		'Selenge',
		'Hentiy',
		'Khövsgöl Province',
		'Övörhangay',
		'Orhon',
		'Central Aimak',
		'Darhan Uul',
		'Ömnögovĭ',
		'East Gobi Aymag',
		'Bulgan',
		'Bayanhongor',
		'Sühbaatar',
	],
	Montenegro: ['Podgorica', 'Pljevlja', 'Opština Nikšić', 'Herceg Novi', 'Cetinje', 'Budva', 'Bijelo Polje', 'Bar'],
	Montserrat: ['Saint Anthony', 'Saint Peter'],
	Morocco: [
		'Laâyoune-Sakia El Hamra',
		'Marrakesh-Safi',
		'Oriental',
		'Drâa-Tafilalet',
		'Souss-Massa',
		'Tanger-Tetouan-Al Hoceima',
		'Rabat-Salé-Kénitra',
		'Fès-Meknès',
		'Guelmim-Oued Noun',
		'Casablanca-Settat',
		'Béni Mellal-Khénifra',
	],
	Mozambique: ['Gaza', 'Sofala', 'Zambézia', 'Tete', 'Maputo Province', 'Cabo Delgado', 'Nampula', 'Inhambane', 'Maputo City', 'Niassa', 'Manica'],
	Myanmar: [
		'Magway',
		'Ayeyarwady',
		'Mandalay',
		'Yangon',
		'Bago',
		'Mon',
		'Tanintharyi',
		'Shan',
		'Rakhine',
		'Sagain',
		'Nay Pyi Taw',
		'Kayin',
		'Kachin',
		'Kayah',
		'Chin',
	],
	Namibia: ['Zambezi', 'Khomas', 'Erongo', 'Kavango East', 'Hardap', 'Otjozondjupa', 'Oshana', 'Karas', 'Omaheke'],
	Nauru: ['Yaren'],
	Nepal: ['Province 4', 'Lumbini Province', 'Sudurpashchim Pradesh', 'Province 2', 'Bagmati Province', 'Province 1', 'Karnali Pradesh'],
	Netherlands: [
		'Overijssel',
		'South Holland',
		'Gelderland',
		'Utrecht',
		'Flevoland',
		'North Holland',
		'Groningen',
		'Limburg',
		'North Brabant',
		'Zeeland',
		'Friesland',
		'Drenthe',
	],
	'New Caledonia': ['South Province'],
	'New Zealand': [
		'Wellington',
		'Manawatu-Wanganui',
		'Canterbury',
		'Waikato',
		'Nelson',
		'Tasman',
		'Auckland',
		'Taranaki',
		"Hawke's Bay",
		'Southland',
		'Otago',
		'Gisborne',
		'Bay of Plenty',
		'Northland',
		'Marlborough',
	],
	Nicaragua: [
		'Managua Department',
		'Madriz Department',
		'Chinandega',
		'North Caribbean Coast',
		'Carazo Department',
		'Rivas',
		'Matagalpa Department',
		'South Caribbean Coast',
		'Nueva Segovia Department',
		'Granada Department',
		'León Department',
		'Masaya Department',
		'Chontales Department',
		'Jinotega Department',
		'Estelí Department',
		'Boaco Department',
	],
	Niger: ['Tahoua', 'Zinder', 'Tillabéri', 'Maradi', 'Niamey', 'Diffa', 'Dosso', 'Agadez'],
	Nigeria: [
		'Kebbi',
		'Niger',
		'Kaduna',
		'Adamawa',
		'Bayelsa',
		'Taraba',
		'Kano',
		'Delta',
		'Nassarawa',
		'Akwa Ibom',
		'Edo',
		'Abia',
		'Cross River',
		'Anambra',
		'Sokoto',
		'Zamfara',
		'Benue',
		'Oyo',
		'Ogun',
		'Yobe',
		'Rivers',
		'Gombe',
		'Kwara',
		'Plateau',
		'Osun',
		'Ondo',
		'Imo',
		'Kogi',
		'Ekiti',
		'Enugu',
		'Borno',
		'Katsina',
		'Lagos',
		'FCT',
		'Jigawa',
		'Bauchi',
		'Ebonyi',
	],
	Niue: [''],
	'Norfolk Island': [''],
	'North Korea': [
		'Hwanghae-namdo',
		'Kangwŏn-do',
		'Pyongyang',
		'South Pyongan',
		'Hwanghae-bukto',
		'Hamgyŏng-namdo',
		"P'yŏngan-bukto",
		'Rason',
		'Hambuk',
		'Chagang-do',
		'Yanggang-do',
	],
	'North Macedonia': [
		'Zhelino',
		'Vinica',
		'Veles',
		'Tetovo',
		'Tearce',
		'Studenichani',
		'Strumica',
		'Struga',
		'Shtip',
		'Grad Skopje',
		'Resen',
		'Radovish',
		'Prilep',
		'Ohrid',
		'Vrapchishte',
		'Negotino',
		'Lipkovo',
		'Kumanovo',
		'Kriva Palanka',
		'Kochani',
		'Kichevo',
		'Kavadarci',
		'Bogovinje',
		'Gostivar',
		'Gevgelija',
		'Delchevo',
		'Debar',
		'Brvenica',
		'Bitola',
		'Ilinden',
		'Centar Zhupa',
	],
	'Northern Mariana Islands': ['Saipan'],
	Norway: [
		'Troms og Finnmark',
		'Trøndelag',
		'Vestfold og Telemark',
		'Rogaland',
		'Viken',
		'Oslo',
		'Vestland',
		'Møre og Romsdal',
		'Nordland',
		'Innlandet',
		'Agder',
	],
	Oman: [
		'Southeastern Governorate',
		'Al Batinah North',
		'Ad Dakhiliyah',
		'Dhofar',
		'Muscat',
		'Musandam Governorate',
		'Ad Dhahirah',
		'Northeastern Governorate',
		'Al Batinah South',
		'Al Buraimi',
	],
	Pakistan: ['Sindh', 'Azad Kashmir', 'Balochistan', 'Khyber Pakhtunkhwa', 'Punjab', 'Islamabad'],
	Palau: ['Melekeok'],
	'Palestinian Territory': ['Gaza Strip', 'West Bank'],
	Panama: ['Panamá Oeste Province', 'Panamá', 'Veraguas', 'Colón', 'Coclé', 'Chiriquí Province', 'Bocas del Toro Province'],
	'Papua New Guinea': [
		'East Sepik',
		'National Capital',
		'Northern Province',
		'Western Highlands',
		'Southern Highlands',
		'Madang',
		'Morobe',
		'East New Britain',
		'West New Britain',
		'Eastern Highlands',
		'Western Province',
		'Bougainville',
	],
	Paraguay: [
		'Guairá',
		'Presidente Hayes',
		'Central',
		'Misiones',
		'Alto Paraná',
		'Ñeembucú',
		'Amambay',
		'Itapúa',
		'Caaguazú',
		'Concepción',
		'Caazapá',
		'Cordillera',
		'Asunción',
		'Boquerón',
	],
	Peru: [
		'Loreto',
		'La Libertad',
		'San Martín',
		'Tumbes',
		'Huanuco',
		'Piura',
		'Lambayeque',
		'Ucayali',
		'Amazonas',
		'Cajamarca',
		'Ancash',
		'Puno',
		'Cusco',
		'Junin',
		'Madre de Dios',
		'Tacna',
		'Lima region',
		'Ica',
		'Moquegua',
		'Arequipa',
		'Lima',
		'Ayacucho',
		'Huancavelica',
		'Pasco',
		'Callao',
		'Apurímac',
	],
	Philippines: [
		'Zamboanga Peninsula',
		'Autonomous Region in Muslim Mindanao',
		'Bicol',
		'Ilocos',
		'Western Visayas',
		'Calabarzon',
		'Caraga',
		'Northern Mindanao',
		'Soccsksargen',
		'Cagayan Valley',
		'Central Visayas',
		'Central Luzon',
		'Metro Manila',
		'Eastern Visayas',
		'Davao',
		'Cordillera',
		'Mimaropa',
	],
	Pitcairn: [''],
	Poland: [
		'Mazovia',
		'Lublin',
		'Podlasie',
		'Lesser Poland',
		'Łódź Voivodeship',
		'Subcarpathia',
		'Warmia-Masuria',
		'Świętokrzyskie',
		'Silesia',
		'Greater Poland',
		'Lower Silesia',
		'Lubusz',
		'Kujawsko-Pomorskie',
		'Pomerania',
		'West Pomerania',
		'Opole Voivodeship',
	],
	Portugal: [
		'Lisbon',
		'Santarém',
		'Faro',
		'Setúbal',
		'Madeira',
		'Portalegre',
		'Leiria',
		'Évora',
		'Castelo Branco',
		'Beja',
		'Viseu',
		'Vila Real',
		'Porto',
		'Viana do Castelo',
		'Guarda',
		'Aveiro',
		'Coimbra',
		'Braga',
		'Bragança',
		'Azores',
	],
	'Puerto Rico': [
		'Aguadilla',
		'Arecibo',
		'Barceloneta',
		'Bayamón',
		'Caguas',
		'Toa Baja',
		'Carolina',
		'Cataño',
		'Cayey',
		'Fajardo',
		'Guayama',
		'Guaynabo',
		'Humacao',
		'Manatí',
		'Mayagüez',
		'Ponce',
		'San Juan',
		'Trujillo Alto',
		'Vega Baja',
		'Yauco',
	],
	Qatar: ['Baladīyat Umm Şalāl', 'Baladīyat ar Rayyān', 'Al Wakrah', 'Al Khor', 'Baladīyat ad Dawḩah'],
	'Republic of the Congo': ['Lékoumou', 'Pointe-Noire', 'Cuvette', 'Sangha', 'Niari', 'Bouenza', 'Likouala', 'Plateaux', 'Brazzaville'],
	Reunion: ['Réunion'],
	Romania: [
		'Teleorman',
		'Brașov',
		'Sălaj',
		'Hunedoara',
		'Ilfov',
		'Maramureş',
		'Suceava',
		'Vaslui',
		'Ialomița',
		'Cluj',
		'Tulcea',
		'Mureș',
		'Covasna',
		'Neamț',
		'Gorj',
		'Dâmbovița',
		'Timiș',
		'Galați',
		'Olt',
		'Sibiu',
		'Alba',
		'Satu Mare',
		'Bihor',
		'Vâlcea',
		'Buzău',
		'Caraș-Severin',
		'Prahova',
		'Arges',
		'Călărași',
		'Harghita',
		'Constanța',
		'Bacău',
		'Iași',
		'Giurgiu',
		'Vrancea',
		'Dolj',
		'Mehedinți',
		'Botoșani',
		'București',
		'Brăila',
		'Bistrița-Năsăud',
		'Arad',
	],
	Russia: [
		'Tver Oblast',
		'St.-Petersburg',
		'Ryazan Oblast',
		'Moscow',
		'Rostov',
		'Moscow Oblast',
		'Chelyabinsk',
		'Bryansk Oblast',
		'Volgograd Oblast',
		'Samara Oblast',
		'Tambov Oblast',
		'Stavropol Kray',
		'Kursk Oblast',
		'Kaliningrad Oblast',
		'Tatarstan Republic',
		'Karachayevo-Cherkesiya Republic',
		'Nizhny Novgorod Oblast',
		'Penza Oblast',
		'Murmansk',
		'Vladimir Oblast',
		'Mariy-El Republic',
		'Krasnodar Krai',
		'Saratov Oblast',
		'Komi',
		'Lipetsk Oblast',
		'Tula Oblast',
		'Orenburg Oblast',
		'Smolensk Oblast',
		'Yaroslavl Oblast',
		'Kirov Oblast',
		'Bashkortostan Republic',
		'Arkhangelskaya',
		'Adygeya Republic',
		"Leningradskaya Oblast'",
		'Udmurtiya Republic',
		'Voronezh Oblast',
		'Vologda Oblast',
		'Kostroma Oblast',
		'North Ossetia–Alania',
		'Ivanovo Oblast',
		'Perm Krai',
		'Pskov Oblast',
		'Belgorod Oblast',
		'Novgorod Oblast',
		'Chechnya',
		'Ulyanovsk',
		'Kabardino-Balkariya Republic',
		'Ingushetiya Republic',
		'Kaluga Oblast',
		'Karelia',
		'Chuvashia',
		'Sverdlovsk Oblast',
		'Mordoviya Republic',
		'Oryol oblast',
		'Nenets',
		'Dagestan',
		'Astrakhan Oblast',
		'Kalmykiya Republic',
		'Tyumen Oblast',
		'Altai Krai',
		'Kuzbass',
		'Krasnoyarsk Krai',
		'Khanty-Mansia',
		'Tomsk Oblast',
		'Novosibirsk Oblast',
		'Irkutsk Oblast',
		'Yamalo-Nenets',
		'Omsk Oblast',
		'Kurgan Oblast',
		'Khakasiya Republic',
		'Altai',
		'Republic of Tyva',
		'Amur Oblast',
		'Sakha',
		'Khabarovsk',
		'Primorye',
		'Buryatiya Republic',
		'Zabaykalskiy (Transbaikal) Kray',
		'Jewish Autonomous Oblast',
		'Kamchatka',
		'Sakhalin Oblast',
		'Magadan Oblast',
		'Chukotka',
	],
	Rwanda: ['Eastern Province', 'Northern Province', 'Southern Province', 'Kigali', 'Western Province'],
	'Saint Barthelemy': [''],
	'Saint Helena': ['Saint Helena'],
	'Saint Kitts and Nevis': ['Saint George Basseterre'],
	'Saint Lucia': ['Castries'],
	'Saint Martin': [''],
	'Saint Pierre and Miquelon': ['Saint-Pierre'],
	'Saint Vincent and the Grenadines': ['Saint George'],
	Samoa: ['Tuamasaga'],
	'San Marino': ['San Marino'],
	'Sao Tome and Principe': ['São Tomé Island'],
	'Saudi Arabia': [
		'Medina Region',
		'Tabuk Region',
		'Northern Borders Region',
		'Mecca Region',
		'Eastern Province',
		'Al Jawf Region',
		'Jazan Region',
		'Asir Region',
		'Najran Region',
		"Ha'il Region",
		'Al-Qassim Region',
		'Riyadh Region',
		'Al Bahah Region',
	],
	Senegal: [
		'Ziguinchor',
		'Kolda',
		'Diourbel',
		'Thiès',
		'Tambacounda',
		'Sédhiou',
		'Saint-Louis',
		'Fatick',
		'Dakar',
		'Kaolack',
		'Louga',
		'Matam',
		'Kédougou',
		'Kaffrine',
	],
	Serbia: ['Vojvodina', 'Central Serbia'],
	Seychelles: ['English River'],
	'Sierra Leone': ['Western Area', 'Eastern Province', 'North West', 'Northern Province', 'Southern Province'],
	Singapore: [''],
	'Sint Maarten': [''],
	Slovakia: [
		'Prešovský kraj',
		'Košický kraj',
		'Banskobystrický kraj',
		'Nitriansky kraj',
		'Žilinský kraj',
		'Trnava',
		'Trenčiansky kraj',
		'Bratislavský Kraj',
	],
	Slovenia: ['Trbovlje', 'Velenje', 'Ptuj', 'Novo Mesto', 'Maribor', 'Ljubljana', 'Kranj', 'Koper-Capodistria', 'Celje'],
	'Solomon Islands': ['Honiara'],
	Somalia: [
		'Lower Shabeelle',
		'Bari',
		'Banaadir',
		'Gedo',
		'Lower Juba',
		'Middle Juba',
		'Middle Shabele',
		'Woqooyi Galbeed',
		'Nugaal',
		'Mudug',
		'Bay',
		'Sanaag',
		'Galguduud',
		'Hiiraan',
		'Togdheer',
		'Sool',
		'Awdal',
	],
	'South Africa': [
		'Gauteng',
		'North-West',
		'Mpumalanga',
		'Orange Free State',
		'Northern Cape',
		'Limpopo',
		'KwaZulu-Natal',
		'Eastern Cape',
		'Western Cape',
	],
	'South Georgia and the South Sandwich Islands': [''],
	'South Korea': [
		'Gyeongsangbuk-do',
		'Gangwon-do',
		'Jeollanam-do',
		'North Chungcheong',
		'Gyeonggi-do',
		'Chungcheongnam-do',
		'Gyeongsangnam-do',
		'Jeollabuk-do',
		'Ulsan',
		'Daejeon',
		'Daegu',
		'Seoul',
		'Busan',
		'Gwangju',
		'Incheon',
		'Jeju-do',
		'Sejong-si',
	],
	'South Sudan': [
		'Central Equatoria',
		'Western Equatoria',
		'Northern Bahr al Ghazal',
		'Western Bahr al Ghazal',
		'Eastern Equatoria',
		'Warrap',
		'Lakes',
		'Upper Nile',
		'Unity',
		'Jonglei',
	],
	Spain: [
		'Andalusia',
		'Extremadura',
		'Murcia',
		'Valencia',
		'Castille-La Mancha',
		'Canary Islands',
		'Balearic Islands',
		'Melilla',
		'Basque Country',
		'Aragon',
		'Castille and León',
		'Galicia',
		'Madrid',
		'Catalonia',
		'Navarre',
		'Cantabria',
		'Asturias',
		'La Rioja',
		'Ceuta',
	],
	'Sri Lanka': ['Western', 'Southern', 'Northern Province', 'Eastern Province', 'Sabaragamuwa', 'North Western', 'Central', 'Uva', 'North Central'],
	Sudan: [
		'Central Darfur',
		'Al Jazīrah',
		'North Kordofan',
		'Khartoum',
		'Red Sea',
		'White Nile',
		'Sinnār',
		'River Nile',
		'Southern Darfur',
		'Northern State',
		'Kassala',
		'Southern Kordofan',
		'Al Qaḑārif',
		'Blue Nile',
		'West Kordofan State',
		'Western Darfur',
		'Northern Darfur',
		'Eastern Darfur',
	],
	Suriname: ['Paramaribo', 'Wanica'],
	'Svalbard and Jan Mayen': ['Svalbard'],
	Sweden: [
		'Västerbotten',
		'Norrbotten',
		'Skåne',
		'Gotland',
		'Kronoberg',
		'Västra Götaland',
		'Kalmar',
		'Västmanland',
		'Stockholm',
		'Jönköping',
		'Halland',
		'Uppsala',
		'Västernorrland',
		'Gävleborg',
		'Jämtland',
		'Örebro',
		'Södermanland',
		'Östergötland',
		'Dalarna',
		'Värmland',
		'Blekinge',
	],
	Switzerland: [
		'Zurich',
		'Zug',
		'Vaud',
		'Saint Gallen',
		'Aargau',
		'Geneva',
		'Bern',
		'Valais',
		'Schaffhausen',
		'Solothurn',
		'Neuchâtel',
		'Basel-Landschaft',
		'Lucerne',
		'Ticino',
		'Thurgau',
		'Appenzell Ausserrhoden',
		'Fribourg',
		'Grisons',
		'Basel-City',
	],
	Syria: [
		'Rif-dimashq',
		'Hama',
		'Tartus',
		'Aleppo',
		'Homs',
		'Daraa',
		'Deir ez-Zor',
		'Idlib',
		'Latakia',
		'Dimashq',
		'Al-Hasakah',
		'Ar-Raqqah',
		'As-Suwayda',
		'Quneitra',
	],
	Taiwan: ['Taiwan', 'Taipei', 'Takao', 'Fukien'],
	Tajikistan: ['Khatlon', 'Sughd', 'Republican Subordination', 'Gorno-Badakhshan', 'Dushanbe'],
	Tanzania: [
		'Zanzibar Urban/West',
		'Pemba North',
		'Songwe',
		'Pwani',
		'Geita',
		'Kigoma',
		'Katavi',
		'Mwanza',
		'Arusha',
		'Tabora',
		'Mbeya',
		'Shinyanga',
		'Mara',
		'Tanga',
		'Rukwa',
		'Simiyu',
		'Zanzibar Central/South',
		'Singida',
		'Kilimanjaro',
		'Kagera',
		'Njombe',
		'Morogoro',
		'Manyara',
		'Dodoma',
		'Ruvuma',
		'Dar es Salaam',
		'Iringa',
		'Lindi',
		'Pemba South',
		'Mtwara',
	],
	Thailand: [
		'Phuket',
		'Prachuap Khiri Khan',
		'Phetchaburi',
		'Trang',
		'Nakhon Si Thammarat',
		'Lampang',
		'Uthai Thani',
		'Kanchanaburi',
		'Tak',
		'Surat Thani',
		'Sukhothai',
		'Chiang Mai',
		'Ratchaburi',
		'Ranong',
		'Phayao',
		'Lamphun',
		'Chiang Rai',
		'Nakhon Sawan',
		'Chumphon',
		'Krabi',
		'Kamphaeng Phet',
		'Kalasin',
		'Udon Thani',
		'Songkhla',
		'Nakhon Ratchasima',
		'Samut Prakan',
		'Nong Bua Lam Phu',
		'Yasothon',
		'Pattani',
		'Yala',
		'Ang Thong',
		'Phetchabun',
		'Ubon Ratchathani',
		'Loei',
		'Phra Nakhon Si Ayutthaya',
		'Sa Kaeo',
		'Uttaradit',
		'Trat',
		'Phichit',
		'Chanthaburi',
		'Nong Khai',
		'Narathiwat',
		'Surin',
		'Suphanburi',
		'Si Sa Ket',
		'Chon Buri',
		'Sing Buri',
		'Bueng Kan',
		'Sakon Nakhon',
		'Satun',
		'Saraburi',
		'Samut Songkhram',
		'Samut Sakhon',
		'Nakhon Pathom',
		'Roi Et',
		'Rayong',
		'Buriram',
		'Prachin Buri',
		'Chaiyaphum',
		'Phrae',
		'Phitsanulok',
		'Phatthalung',
		'Bangkok',
		'Chachoengsao',
		'Pathum Thani',
		'Nonthaburi',
		'Nan',
		'Nakhon Phanom',
		'Nakhon Nayok',
		'Mukdahan',
		'Maha Sarakham',
		'Lopburi',
		'Khon Kaen',
		'Chai Nat',
		'Amnat Charoen',
	],
	'Timor Leste': ['Cova Lima', 'Liquiçá', 'Bobonaro', 'Díli', 'Baucau', 'Aileu', 'Lautém'],
	Togo: ['Maritime', 'Centrale', 'Plateaux', 'Kara', 'Savanes'],
	Tonga: ['Tongatapu'],
	'Trinidad and Tobago': [
		'Tunapuna/Piarco',
		'Tobago',
		'Sangre Grande',
		'San Fernando',
		'Mayaro',
		'Port of Spain',
		'Point Fortin',
		'San Juan/Laventille',
		'Chaguanas',
		'Borough of Arima',
	],
	Tunisia: [
		'Zaghouan Governorate',
		'Manouba',
		'Tunis Governorate',
		'Tozeur Governorate',
		'Tataouine',
		'Kasserine Governorate',
		'Nabeul Governorate',
		'Kef Governorate',
		'Sousse Governorate',
		'Siliana Governorate',
		'Sidi Bouzid Governorate',
		'Monastir Governorate',
		'Sfax Governorate',
		'Ben Arous Governorate',
		'Mahdia Governorate',
		'Kebili Governorate',
		'Ariana Governorate',
		'Gafsa',
		'Gabès Governorate',
		'Medenine Governorate',
		'Bizerte Governorate',
		'Béja Governorate',
		'Jendouba Governorate',
		'Kairouan',
	],
	Turkey: [
		'Hakkâri',
		'Yozgat',
		'Mardin',
		'Hatay',
		'Aydın',
		'Muğla',
		'Isparta',
		'Kayseri',
		'Şanlıurfa',
		'Muş',
		'Van',
		'Erzincan',
		'Uşak',
		'İzmir Province',
		'Nevşehir Province',
		'Manisa',
		'Tunceli',
		'Antalya',
		'Kütahya',
		'Bitlis',
		'Mersin',
		'Balıkesir',
		'Bingöl',
		'Sivas',
		'Şırnak',
		'Diyarbakır Province',
		'Siirt',
		'Konya',
		'Ankara',
		'Denizli',
		'Afyonkarahisar Province',
		'Kahramanmaraş',
		'Ağrı',
		'Erzurum',
		'Osmaniye',
		'Aksaray',
		'Gaziantep',
		'Niğde Province',
		'Kırşehir',
		'Malatya',
		'Batman',
		'Adana',
		'Elazığ',
		'Kırıkkale',
		'Kilis',
		'Karaman',
		'Adıyaman Province',
		'Iğdır',
		'Canakkale',
		'Eskişehir',
		'Burdur',
		'Bilecik',
		'Zonguldak',
		'Tokat',
		'Istanbul',
		'Trabzon',
		'Bursa Province',
		'Kocaeli',
		'Yalova',
		'Samsun',
		'Edirne',
		'Ordu',
		'Kastamonu',
		'Giresun',
		'Tekirdağ',
		'Amasya',
		'Çorum',
		'Sinop',
		'Kars Province',
		'Sakarya',
		'Karabük Province',
		'Rize Province',
		'Kırklareli',
		'Gümüşhane Province',
		'Artvin',
		'Bolu',
		'Düzce',
		'Çankırı',
		'Bayburt Province',
		'Bartın',
		'Ardahan',
	],
	Turkmenistan: ['Balkan', 'Ahal', 'Ashgabat', 'Daşoguz', 'Mary', 'Lebap'],
	'Turks and Caicos Islands': [''],
	Tuvalu: ['Funafuti'],
	'U.S. Virgin Islands': ['Saint Thomas Island', 'Saint Croix Island'],
	Uganda: ['Northern Region', 'Central Region', 'Eastern Region', 'Western Region'],
	Ukraine: [
		'Sumy',
		'Donetsk',
		'Cherkasy',
		'Lviv',
		'Kirovohrad',
		'Zhytomyr',
		'Vinnytsia',
		'Dnipropetrovsk',
		'Rivne',
		'Zaporizhzhia',
		'Odessa',
		'Crimea',
		'Kiev',
		'Transcarpathia',
		'Mykolaiv',
		'Khmelnytskyi',
		'Kharkiv',
		'Volyn',
		'Kherson',
		'Ternopil',
		'Luhansk',
		'Sevastopol City',
		'Chernihiv',
		'Poltava',
		'Ivano-Frankivsk',
		'Kyiv City',
		'Chernivtsi',
	],
	'United Arab Emirates': ['Imārat Umm al Qaywayn', 'Raʼs al Khaymah', 'Abu Dhabi', 'Sharjah', 'Dubai', 'Fujairah', 'Ajman'],
	'United Kingdom': ['England', 'Wales', 'Scotland', 'Northern Ireland'],
	'United States': [
		'Virginia',
		'Alabama',
		'Kentucky',
		'Tennessee',
		'Arkansas',
		'Washington, D.C.',
		'Delaware',
		'Florida',
		'Georgia',
		'Illinois',
		'Indiana',
		'Kansas',
		'Louisiana',
		'Maryland',
		'Missouri',
		'Mississippi',
		'North Carolina',
		'New Jersey',
		'Ohio',
		'Oklahoma',
		'Pennsylvania',
		'South Carolina',
		'Texas',
		'West Virginia',
		'Massachusetts',
		'Connecticut',
		'Michigan',
		'Iowa',
		'Maine',
		'Minnesota',
		'North Dakota',
		'Nebraska',
		'New Hampshire',
		'New York',
		'Rhode Island',
		'South Dakota',
		'Vermont',
		'Wisconsin',
		'California',
		'Arizona',
		'Colorado',
		'New Mexico',
		'Nevada',
		'Utah',
		'Alaska',
		'Idaho',
		'Montana',
		'Oregon',
		'Washington',
		'Wyoming',
		'Hawaii',
	],
	Uruguay: [
		'Río Negro Department',
		'Flores Department',
		'Treinta y Tres Department',
		'Tacuarembó Department',
		'Canelones',
		'San José Department',
		'Maldonado',
		'Salto Department',
		'Rocha Department',
		'Rivera Department',
		'Paysandú Department',
		'Montevideo Department',
		'Lavalleja',
		'Soriano',
		'Cerro Largo',
		'Florida',
		'Durazno Department',
		'Colonia',
		'Artigas',
	],
	Uzbekistan: [
		'Karakalpakstan',
		'Jizzax',
		'Samarqand',
		'Surxondaryo',
		'Qashqadaryo',
		'Bukhara',
		'Toshkent',
		'Fergana',
		'Sirdaryo Region',
		'Navoiy',
		'Namangan',
		'Xorazm',
		'Tashkent',
		'Andijon',
	],
	Vanuatu: ['Shefa'],
	Vatican: [''],
	Venezuela: [
		'Nueva Esparta',
		'Anzoátegui',
		'Barinas',
		'Guárico',
		'Yaracuy',
		'Aragua',
		'Portuguesa',
		'Trujillo',
		'Carabobo',
		'Táchira',
		'Bolívar',
		'Delta Amacuro',
		'Mérida',
		'Falcón',
		'Cojedes',
		'Zulia',
		'Monagas',
		'Miranda',
		'Lara',
		'Amazonas',
		'Vargas',
		'Sucre',
		'Apure',
		'Distrito Federal',
	],
	Vietnam: [
		'Nghệ An',
		'Yên Bái',
		'Hanoi',
		'Bà Rịa-Vũng Tàu',
		'Hậu Giang',
		'Vĩnh Phúc',
		'Vĩnh Long',
		'Sóc Trăng',
		'Phú Thọ',
		'Quảng Ninh',
		'Phú Yên',
		'Tuyên Quang',
		'Trà Vinh',
		'Bình Dương',
		'Can Tho',
		'Ho Chi Minh',
		'Thanh Hóa',
		'Thái Nguyên',
		'Thái Bình',
		'Tây Ninh Province',
		'Long An',
		'Quảng Nam',
		'Sơn La',
		'Đồng Tháp',
		'Kiến Giang',
		'Bình Định',
		'Quảng Ngãi Province',
		'Gia Lai',
		'Hà Nam',
		'Bình Thuận',
		'Ninh Thuận',
		'Khánh Hòa',
		'Ninh Bình',
		'Nam Định',
		'Tiền Giang',
		'Ðiện Biên',
		'An Giang',
		'Lào Cai',
		'Lạng Sơn',
		'Kon Tum',
		'Hưng Yên',
		'Thừa Thiên-Huế',
		'Hòa Bình',
		'Hà Tĩnh',
		'Haiphong',
		'Hải Dương',
		'Hà Giang',
		'Ðắk Nông',
		'Lâm Đồng',
		'Bình Phước',
		'Quảng Bình',
		'Quảng Trị',
		'Da Nang',
		'Cao Bằng',
		'Cà Mau',
		'Đắk Lắk',
		'Đồng Nai',
		'Bến Tre',
		'Bắc Ninh',
		'Bạc Liêu',
		'Bắc Giang',
		'Bắc Kạn',
	],
	'Wallis and Futuna': ['Uvea'],
	'Western Sahara': [''],
	Yemen: [
		'Abyan Governorate',
		'Al Hudaydah',
		'Ibb Governorate',
		'Ta‘izz',
		'Sanaa Governorate',
		'Amanat Alasimah',
		'Şa‘dah',
		'Ma’rib',
		'Laḩij',
		'Ḩajjah',
		'Dhamār',
		'Shabwah',
		'Omran',
		'Muhafazat Hadramaout',
		'Al Jawf',
		'Al Bayda',
		'Aden',
	],
	Zambia: ['Luapula', 'Northern', 'Southern', 'Western', 'Eastern', 'Copperbelt', 'Central', 'Muchinga', 'Lusaka', 'North-Western'],
	Zimbabwe: [
		'Masvingo',
		'Matabeleland North',
		'Midlands',
		'Mashonaland East',
		'Manicaland',
		'Mashonaland West',
		'Harare',
		'Matabeleland South',
		'Bulawayo',
		'Mashonaland Central',
	],
}

export default countryStates
