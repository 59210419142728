import { Footer, Form, Loader } from '@components'
import NoImage from '../../assets/images/icon-no-image.svg'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import Icon from '@mdi/react'
import { mdiFolderSearchOutline, mdiMapMarkerOutline } from '@mdi/js'
import { BedroomSizeEnums, PaymentTypeEnums } from '../newListing/helpers/enums'
import { searchPropertiesSelector } from '../listing/helpers/selectors'
import { countriesAtom, selectedCityAtom, selectedCountryAtom, selectedDateRangeAtom } from '../../recoil/atoms'
import CountryEnum from '../../helpers/countries'

const SearchPage = () => {
	let parts = []
	let partsTwo = []
	const navigator = useNavigate()
	const id = JSON.parse(localStorage.getItem('user'))?.id
	const selectedCity = useRecoilValue(selectedCityAtom)
	const selectedCountry = useRecoilValue(selectedCountryAtom)
	const selectedDateRange = useRecoilValue(selectedDateRangeAtom)

	console.log(selectedDateRange?.[0], 'selectedDateRange')
	const { form, loading } = useOutletContext()
	const values = Form.useWatch(undefined, form)
	// const data = useRecoilValue(
	// 	searchPropertiesSelector({
	// 		id,
	// 		dateRange: [selectedDateRange?.[0]?.format(), selectedDateRange?.[1]?.format()],
	// 		location: { city: selectedCity, country: selectedCountry },
	// 		type: 'swap',
	// 	})
	// )

	const data = useRecoilValue(
		searchPropertiesSelector({
			id,
			dateRange: [values?.dateRange?.[0]?.format(), values?.dateRange?.[1]?.format()],
			location: { city: values?.city, country: values?.country },
			// type: values?.type,
			type: "sublease",
		})
	)

	return (
		<>
			<div className='min-h-[70vh] sm:w-[981px] max-md:px-8 py-8 h-fit bg-white mx-auto'>
				<p className='text-[#333333] font-[700] text-lg pb-6'>Search Results</p>
				{data && data?.length > 0 ? (
					<div className='flex flex-wrap'>
						{data.map((listing, index) => {
							return (
								<div
									className={`py-4 sm:w-[311px] max-md:w-full px-4  ${
										index !== 0 && index % 3 === 2 ? '' : 'sm:mr-6 max-md:mr-0'
									} mb-10 rounded-lg border border-solid border-[#dedede] hover:cursor-pointer`}
									onClick={() => {
										const resetToMidnight = form.getFieldValue(['dateRange'])?.map(dateStr => {
											const date = new Date(dateStr);
											date.setUTCHours(0, 0, 0, 0); 
											return date.toISOString();
										  });
										localStorage.setItem('searchDate', JSON.stringify(resetToMidnight) || ['', ''])
										localStorage.setItem('searchType', JSON.stringify('sublease'))
										localStorage.setItem('location', JSON.stringify({ city: form.getFieldValue(['city']), country: form.getFieldValue(['country']) }))

										navigator(`/listing/${listing.property._id}`)
									}}
								>
									<img
										src={listing.property.pictures[0] && !listing.property.pictures[0].includes('example.com') ? listing.property.pictures[0] : NoImage}
										alt=''
										className='h-[306px] w-[279px] rounded-lg object-cover'
									/>
									<div className='py-4 '>
										<p className='text-[#333333] text-lg font-[700] break-normal'>{listing.property.title || listing.property.description}</p>
										<div className='flex flex-row items-center space-x-4 py-4'>
											<div className='flex flex-row space-x-2 items-center'>
												<img style={{ width: '16px', height: '16px' }} src={BedroomSizeEnums.noOfBedroom.icon} alt={'bedrooms'} />
												<p className='text-[#333333] font-[600] text-sm leading-[16.1px]'>
													{listing.property.noOfBedroom}&nbsp;{parseInt(listing.property.noOfBedroom) > 1 ? 'Bedrooms' : 'Bedroom'}
												</p>
											</div>
											<div className='flex flex-row space-x-2 items-center'>
												<img style={{ width: '16px', height: '16px' }} src={BedroomSizeEnums.noOfBathroom.icon} alt={'bathrooms'} />
												<p className='text-[#333333] font-[600] text-sm leading-[16.1px]'>
													{listing.property.noOfBathroom}&nbsp;{parseInt(listing.property.noOfBathroom) > 1 ? 'Bathrooms' : 'Bathroom'}
												</p>
											</div>
										</div>

										<div className='flex flex-row items-center'>
											{/* <div className='flex flex-row space-x-2 items-center'> */}

											<p className='text-[#333333] font-[600] text-sm leading-[16.1px]'>
												<span className='text-[#333333] font-[700] text-sm leading-[16.1px]'>Available</span>
											</p>
											{/* </div> */}
										</div>
										<div style={{ display: 'none' }}>
											{(parts = listing.associatedListings[0]?.availableDates[0]?.startDate.substring(0, 10).split('-'))}
											{
												(partsTwo = listing.associatedListings[0]?.availableDates[listing.associatedListings[0]?.availableDates.length - 1]?.endDate
													.substring(0, 10)
													.split('-'))
											}
										</div>
										<div className='flex flex-row items-center space-x-4 py-4'>
											<div className='flex flex-row space-x-2 items-center'>
												<p className='text-[#333333] font-[600] text-sm leading-[16.1px]'>
													<span className='text-[#333333] font-[700] text-sm leading-[16.1px]'>From:</span>&nbsp; {parts[2] + '-' + parts[1] + '-' + parts[0]} &nbsp;&nbsp;{' '}
													<span className='text-[#333333] font-[700] text-sm leading-[16.1px]'>To:</span>&nbsp; {partsTwo[2] + '-' + partsTwo[1] + '-' + partsTwo[0]}
												</p>
											</div>
										</div>

										<div className='flex flex-row items-center justify-between'>
											<div className='flex flex-row space-x-2 items-center'>
												<Icon path={mdiMapMarkerOutline} size={0.7} className='text-[#333333]' />
												{listing.location && <p className='text-[#333333] font-[700] text-sm leading-[16.1px]'>{listing.location.city}</p>}
											</div>
											<div>
												<div className='flex flex-row space-x-2 items-center'>
													{listing?.asscocitedListings?.[0]?.listingType === 'sublease' && (
														<p className='text-[#333333] font-[700] text-sm leading-[16.1px]'>
															${listing?.asscocitedListings?.[0]?.cost}/{PaymentTypeEnums[listing?.asscocitedListings?.[0]?.timePeriod]?.text}
														</p>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				) : loading ? (
					<div className='flex flex-col justify-center items-center h-full'>
						<div className='my-auto align-middle'>
							<Loader />
						</div>
					</div>
				) : (
					<div className='flex flex-col justify-center items-center h-full py-10'>
						<div className='my-auto align-middle items-center flex flex-col'>
							<Icon path={mdiFolderSearchOutline} size={5} className='text-[#919191]' />
							<p className='text-[#919191] font-[500] text-lg'>No Properties Found</p>
						</div>
					</div>
				)}
			</div>
			<Footer />
		</>
	)
}

export default SearchPage
