import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { Button, message, Input } from 'antd'

// Custom styling options for CardElement
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
	hidePostalCode: true,
}

function AddNewCard({ customerId, refetch }) {
	const stripe = useStripe()
	const elements = useElements()
	const [cardError, setCardError] = useState('') // State for card validation error
	const [loading, setLoading] = useState(false)
	const [cardholderName, setCardholderName] = useState('') // State for the cardholder name

	const handleSubmit = async (event) => {
		// Prevent form submission from causing page refresh
		event.preventDefault()

		if (!stripe || !elements) {
			message.error('Stripe.js has not loaded yet.')
			return
		}

		// Get the card element
		const card = elements.getElement(CardElement)
		if (!card) {
			setCardError('Please enter your card details.')
			return
		}

		// Clear any previous errors
		setCardError('')

		// Create the payment method with Stripe
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: card,
			// billing_details: {
			// 	name: cardholderName, // Pass the cardholder's name to the billing details
			// },
		})

		// Handle Stripe errors
		if (error) {
			console.error('[PaymentMethod Error]', error)
			message.error(error.message)
			setCardError(error.message) // Display error below CardElement
		} else {
			console.log('[PaymentMethod]', paymentMethod)

			// Trigger API call directly
			setLoading(true)
			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}stripe/addCard/${customerId}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
				})

				const result = await response.json()

				if (!response.ok) {
					throw new Error(result.message || 'Failed to add card')
				}

				// Handle success
				message.success('Card added successfully!')
				refetch()
				card.clear()
			} catch (error) {
				console.error('Error:', error)
				message.error("Couldn't add card. Please try again.")
			} finally {
				setLoading(false)
			}
		}
	}

	return (
		<form onSubmit={(e) => handleSubmit(e)} className='w-full max-w-md  p-4 bg-white rounded-lg shadow-md space-y-4 mb-4'>
			{/* Cardholder's name input */}
			{/* <label className='block text-sm font-medium text-gray-700'>
				Cardholder's Name
				<Input
					type='text'
					placeholder='Enter the name on your card'
					value={cardholderName}
					onChange={(e) => setCardholderName(e.target.value)}
					className='w-full mt-2'
				/>
			</label> */}

			{/* Card details */}
			<label className='block text-sm font-medium text-gray-700'>
				Card details
				<div className='mt-2 p-2 border border-gray-300 rounded-lg'>
					<CardElement options={CARD_ELEMENT_OPTIONS} className='w-full' />
				</div>
				{cardError && <p className='text-red-500 text-sm mt-1'>{cardError}</p>}
			</label>

			{/* Submit button */}
			<div className='flex justify-end'>
				<Button
					htmlType='submit'
					onClick={(e) => {
						e.preventDefault() // Prevent default behavior here as well
						console.log('Button clicked') // Check if the button is clicked
						handleSubmit(e) // Manually trigger the form submission handler
					}}
					disabled={!stripe || loading}
					loading={loading}
					className='flex btn-primary items-center'
				>
					{loading ? 'Processing...' : 'Add Card'}
				</Button>
			</div>
		</form>
	)
}

export default AddNewCard
